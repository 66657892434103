import gql from "graphql-tag";

export default gql`
  mutation updateWelcomeCall($input: UpdateWelcomeCallInput!) {
    updateWelcomeCall(input: $input) {
      welcomeCall {
        id
      }
    }
  }
`;
