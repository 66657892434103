import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ReactHelmet from "react-helmet";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import Logo from "../../assets/nozani-central-logo.png";
import { StandardCard } from "../common/standard-card";
import Text from "../common/styled/text";

const LogoImage = styled.img`
  max-height: 50px;
  padding-bottom: 15px;
`;

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

class DoNotTrackDetector extends Component {
  state = {
    isDoNotTrack: !!navigator.doNotTrack
  };

  timer = null;

  componentDidMount() {
    this.timer = setInterval(this.checkDoNotTrack, 3000);
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  checkDoNotTrack = () => {
    this.setState({
      isDoNotTrack: !!navigator.doNotTrack
    });
  };

  render() {
    const { children } = this.props;
    const { isDoNotTrack } = this.state;
    if (isDoNotTrack) {
      return (
        <Fragment>
          <ReactHelmet>
            <title>Do Not Track Enabled | Nozani Central</title>
          </ReactHelmet>
          <CenteredContainer>
            <div>
              <Text>
                <LogoImage src={Logo} alt="Nozani" />
              </Text>
              <StandardCard>
                <Text>
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    style={{ marginRight: ".5em" }}
                  />
                  Do Not Track must be disabled to use Nozani Central.
                </Text>
              </StandardCard>
              <Text.HeaderLarge>
                Instructions to Disable &quot;Do Not Track&quot;
              </Text.HeaderLarge>
              <div>
                <ol>
                  <li>
                    Go to Chrome Settings (type{" "}
                    <strong>chrome://settings</strong> in your URL bar)
                  </li>
                  <li>
                    Scroll to the bottom of the page and click{" "}
                    <strong>Advanced</strong>
                  </li>
                  <li>
                    Under &quot;Private and security&quot; make sure the &quot;
                    <strong>
                      Send a &quot;Do Not Track&quot; request with your browsing
                      traffic
                    </strong>
                    &quot; option is <strong>unselected</strong>
                  </li>
                  <li>
                    This page will automatically refresh with Nozani Central
                  </li>
                </ol>
              </div>
            </div>
          </CenteredContainer>
        </Fragment>
      );
    }
    return children;
  }
}

DoNotTrackDetector.propTypes = {
  children: PropTypes.node.isRequired
};

export default DoNotTrackDetector;
