import React, { Component, Fragment } from "react";
import { Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { StandardCard } from "../../common/standard-card";
import FilesTable from "./files-table";
import UploadButton from "./upload-button";
import {
  CreateCustomerFileComponent,
  CustomerFilesComponent
} from "../../../generated/graphql";
import Loader from "../../common/loader";

interface Props {
  customerId: number;
}
interface State {}

class FilesContainer extends Component<Props, State> {
  state = {};

  render() {
    const { customerId } = this.props;
    return (
      <StandardCard title="Files">
        <CustomerFilesComponent variables={{ customerId }}>
          {({ data, loading, error, refetch }) => {
            if (loading) {
              return <Loader />;
            }
            if (error) {
              return error.message;
            }
            return (
              <Fragment>
                <CreateCustomerFileComponent>
                  {createCustomerFileMutation => (
                    <UploadButton
                      customerId={customerId}
                      createCustomerFileMutation={createCustomerFileMutation}
                      onFileAdded={() => refetch()}
                    />
                  )}
                </CreateCustomerFileComponent>
                <FilesTable
                  customerFiles={
                    data && data.customer && data.customer.customerFiles
                      ? data.customer.customerFiles.items
                      : []
                  }
                />
              </Fragment>
            );
          }}
        </CustomerFilesComponent>
      </StandardCard>
    );
  }
}

export default FilesContainer;
