import React from "react";
import { Card, CardContent } from "@material-ui/core";

const Activity = () => (
  <Card>
    <CardContent>Activity support will be coming soon.</CardContent>
  </Card>
);

export default Activity;
