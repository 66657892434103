import gql from "graphql-tag";

export default gql`
  query {
    productCategories {
      items {
        id
        name
        customerProducts {
          id
          name
        }
      }
    }
  }
`;
