import React, { FunctionComponent } from "react";

import Workspace from "./workspace";
import Loader from "../../common/loader";
import ErrorDisplay from "../../common/error-display";
import {
  SelectTaskTask,
  SelectWelcomeCallComponent,
  CreateNoteComponent
} from "../../../generated/graphql";

interface Props {
  task: SelectTaskTask;
  onClose: () => any;
  onComplete: () => any;
}

const CustomerSkuAssignmentContainer: FunctionComponent<Props> = ({
  task,
  ...rest
}) => (
  <CreateNoteComponent>
    {createNoteMutation => {
      if (!task || !task.customer) {
        return null;
      }
      return (
        <SelectWelcomeCallComponent
          variables={{ customerId: task.customer.id }}
          fetchPolicy="network-only"
        >
          {({ data, loading, error }) => {
            if (loading) {
              return <Loader />;
            }
            if (error) {
              return <ErrorDisplay error={error} />;
            }
            if (!data) {
              return null;
            }

            return (
              <Workspace
                task={task}
                {...rest}
                createNoteMutation={createNoteMutation}
              />
            );
          }}
        </SelectWelcomeCallComponent>
      );
    }}
  </CreateNoteComponent>
);

export default CustomerSkuAssignmentContainer;
