import React, { Component } from "react";
import {
  Button,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Switch
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faTimes,
  faPen,
  faCamera
} from "@fortawesome/free-solid-svg-icons";
import { Formik, FormikActions } from "formik";
import { QueryResult, MutationFn } from "react-apollo";
import MarkdownEditor from "../../common/markdown-editor";
import AppIcon from "../../common/app-icon";
import { ChildWorkspaceCommonProps } from "../task-workspace/workspace";
import { TableHeaderCell } from "../../common/table-header-cell";
import {
  SelectPhotoBatchReviewTaskWorkspaceQuery,
  SelectPhotoBatchReviewTaskWorkspaceVariables,
  CreatePhotographyReviewItemsMutation,
  CreatePhotographyReviewItemsVariables
} from "../../../generated/graphql";
import Loader from "../../common/loader";
import ErrorDisplay from "../../common/error-display";
import Alert from "../../common/styled/alert-error";
import { transformGraphQLErrorForFormik } from "../../../utilities/form-helpers";
import BatchReviewIntsruction from "./batch-review-instruction";
import Text from "../../common/styled/text";

interface Props extends ChildWorkspaceCommonProps {
  queryResult: QueryResult<
    SelectPhotoBatchReviewTaskWorkspaceQuery,
    SelectPhotoBatchReviewTaskWorkspaceVariables
  >;
  createPhotographyReviewItemsMutation: MutationFn<
    CreatePhotographyReviewItemsMutation,
    CreatePhotographyReviewItemsVariables
  >;
}

interface State {
  isPhotoApproved: boolean;
  isEditingApproved: boolean;
  comments: string;
}

interface FormValues {
  global?: string;
  customerProductBatchId: number;
  items: {
    customerProductId: number;
    isPhotoApproved: boolean;
    isEditingApproved: boolean;
    comments: string;
  }[];
}

class Workspace extends Component<Props, State> {
  state = {
    comments: "",
    isPhotoApproved: false,
    isEditingApproved: false
  };

  onCommentChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ comments: e.target.value });
  };

  onPhotoApprovedChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    this.setState({ isPhotoApproved: checked });
  };

  onEditingApprovedChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    this.setState({ isEditingApproved: checked });
  };

  onSubmit = async (
    values: FormValues,
    formikActions: FormikActions<FormValues>
  ) => {
    const { onComplete } = this.props;
    try {
      const { createPhotographyReviewItemsMutation, task } = this.props;
      await createPhotographyReviewItemsMutation({
        variables: {
          input: {
            reviewTaskId: task.id,
            customerProductBatchId: values.customerProductBatchId,
            items: values.items.map(item => ({
              customerProductId: item.customerProductId,
              needsReshoot: !item.isPhotoApproved,
              needsEditing: !item.isEditingApproved,
              comments: item.comments
            }))
          }
        }
      });
      const itemsNeedingRevision = values.items.filter(
        item => !item.isEditingApproved || !item.isPhotoApproved
      );
      await onComplete(
        itemsNeedingRevision.length === 0,
        itemsNeedingRevision.length === 0
          ? undefined
          : "Not all customer products were approved."
      );
    } catch (e) {
      formikActions.setErrors(transformGraphQLErrorForFormik(e));
      formikActions.setSubmitting(false);
    }
  };

  render() {
    const { onClose, queryResult } = this.props;
    const { comments, isEditingApproved, isPhotoApproved } = this.state;
    if (queryResult.loading) {
      return <Loader />;
    }
    if (queryResult.error) {
      return <ErrorDisplay error={queryResult.error} />;
    }
    if (!queryResult.data || !queryResult.data.customerProductBatch) {
      return <Alert message="Something went wrong." />;
    }
    const { customerProducts } = queryResult.data.customerProductBatch;
    return (
      <Formik
        onSubmit={this.onSubmit}
        initialValues={{
          customerProductBatchId: queryResult.data.customerProductBatch.id,
          items: customerProducts.map(cp => ({
            customerProductId: cp.id,
            isPhotoApproved: false,
            isEditingApproved: false,
            comments: ""
          }))
        }}
        render={({
          values,
          errors,
          isSubmitting,
          handleChange,
          setFieldValue,
          handleSubmit
        }) => (
          <div>
            {errors.global && <Alert message={errors.global} />}
            <Text.HeaderXSmall>Instructions:</Text.HeaderXSmall>
            <BatchReviewIntsruction />
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeaderCell>Product</TableHeaderCell>
                  <TableHeaderCell>SKU</TableHeaderCell>
                  <TableHeaderCell>Approvals</TableHeaderCell>
                  <TableHeaderCell>Comments</TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customerProducts.map((customerProduct, index) => (
                  <TableRow key={customerProduct.id}>
                    <TableCell>{customerProduct.product.name}</TableCell>
                    <TableCell>
                      {customerProduct.customerSku
                        ? customerProduct.customerSku.sku
                        : "Unknown"}
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          color: values.items[index].isPhotoApproved
                            ? "green"
                            : "red"
                        }}
                      >
                        <AppIcon icon={faCamera} standardRightMargin />
                        <Switch
                          onChange={(e: any, checked) =>
                            setFieldValue(
                              `items[${index}].isPhotoApproved`,
                              checked
                            )
                          }
                          checked={values.items[index].isPhotoApproved}
                        />
                      </div>
                      <div
                        style={{
                          color: values.items[index].isEditingApproved
                            ? "green"
                            : "red"
                        }}
                      >
                        <AppIcon icon={faPen} standardRightMargin />
                        <Switch
                          onChange={(e: any, checked) =>
                            setFieldValue(
                              `items[${index}].isEditingApproved`,
                              checked
                            )
                          }
                          checked={values.items[index].isEditingApproved}
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      <MarkdownEditor
                        id={`items[${index}].comments`}
                        name={`items[${index}].comments`}
                        value={values.items[index].comments}
                        onChange={handleChange}
                        error={(errors as any)[`items[${index}].comments`]}
                        helperText={(errors as any)[`items[${index}].comments`]}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <div style={{ marginTop: "4em" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
                size="large"
                disabled={isSubmitting}
              >
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{ marginRight: ".5em" }}
                />
                Complete
              </Button>
              <Button
                onClick={onClose}
                size="large"
                style={{ marginLeft: ".5em" }}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{ marginRight: ".5em" }}
                />
                Close
              </Button>
            </div>
          </div>
        )}
      />
    );
  }
}

export default Workspace;
