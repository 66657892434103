import React, { Component, Fragment } from "react";
import moment from "moment";

/* Material UI */
import {
  Grid,
  DialogContent,
  DialogTitle,
  Dialog,
  List,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";

/* GraphQL */
import {
  SelectRoadmapCustomerProducts,
  DeleteCustomerProductMutation,
  DeleteCustomerProductVariables,
  SelectRoadmapVariables,
  SelectRoadmapQuery,
  SelectRoadmapCustomerProductBatches
} from "../../../../generated/graphql";

/* Common Components */
import EditableDisplayField from "../../../common/editable-display-field";
import { TableHeaderCell } from "../../../common/table-header-cell";
import DangerButton from "../../../common/styled/danger-button";
import { MutationFn } from "react-apollo";
import ConfirmDeleteDialog from "../../../common/confirm-delete-dialog";
import { transformGraphQLErrorForFormik } from "../../../../utilities/form-helpers";
import { withSnackbar, InjectedNotistackProps } from "notistack";
import { ApolloQueryResult } from "apollo-client";

interface Props extends InjectedNotistackProps {
  onCancel: () => void;
  customerProductBatch: SelectRoadmapCustomerProductBatches;
  customerProducts: SelectRoadmapCustomerProducts[];
  deleteCustomerProductMutation: MutationFn<
    DeleteCustomerProductMutation,
    DeleteCustomerProductVariables
  >;
  onDeleteCustomerProduct: (
    customerProduct: SelectRoadmapCustomerProducts
  ) => Promise<null | undefined>;
  refetchCustomerProjects: (
    variables?: SelectRoadmapVariables | undefined
  ) => Promise<ApolloQueryResult<SelectRoadmapQuery>>;
}
interface State {
  customerProduct: SelectRoadmapCustomerProducts | null;
  isConfirmDeleteDialog: boolean;
}

class ProductBatchDialog extends Component<Props, State> {
  state = {
    customerProduct: null,
    isConfirmDeleteDialog: false
  };

  onConfirmDeleteDialog = (customerProduct: SelectRoadmapCustomerProducts) => {
    this.setState({ customerProduct, isConfirmDeleteDialog: true });
  };

  onCancelConfirmDeleteDialog = () => {
    this.setState({ isConfirmDeleteDialog: false });
  };

  onDeleteCustomerProductConfirmed = async () => {
    const { customerProduct } = this.state;
    const {
      deleteCustomerProductMutation,
      enqueueSnackbar,
      refetchCustomerProjects,
      customerProductBatch,
      onCancel
    } = this.props;

    if (!customerProduct) {
      return;
    }

    try {
      await deleteCustomerProductMutation({
        variables: {
          input: {
            customerProductId: (customerProduct as SelectRoadmapCustomerProducts)
              .id
          }
        }
      });
      enqueueSnackbar(
        "The Customer Product was removed from the Product Batch successfully!",
        {
          variant: "success"
        }
      );
      refetchCustomerProjects();
      this.setState({ isConfirmDeleteDialog: false }, () => {
        if (customerProductBatch.customerProducts.length === 1) {
          onCancel();
        }
      });
    } catch (e) {
      const error = transformGraphQLErrorForFormik(e);
      if (error.global) {
        enqueueSnackbar(error.global, { variant: "error" });
      }
      this.setState({ isConfirmDeleteDialog: false });
    }
  };

  render() {
    const { isConfirmDeleteDialog } = this.state;
    const { onCancel, customerProductBatch, customerProducts } = this.props;
    return (
      <Dialog fullWidth open onClose={onCancel} maxWidth="lg">
        <DialogTitle>Batch Details</DialogTitle>
        <DialogContent>
          {isConfirmDeleteDialog && (
            <ConfirmDeleteDialog
              onConfirmDelete={this.onDeleteCustomerProductConfirmed}
              onDeleteCancel={this.onCancelConfirmDeleteDialog}
              deleteMessage="Product from the Product Batch"
            />
          )}
          <Grid container spacing={16}>
            <Grid item lg={4}>
              <List dense>
                <EditableDisplayField
                  label="ID"
                  value={customerProductBatch.id}
                />
                <EditableDisplayField
                  label="Start Date"
                  value={moment(customerProductBatch.startDate).format(
                    "M/D/YYYY"
                  )}
                />
              </List>
            </Grid>
            <Grid item lg={8}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeaderCell>ID</TableHeaderCell>
                    <TableHeaderCell>Product</TableHeaderCell>
                    <TableHeaderCell>SKU</TableHeaderCell>
                    <TableHeaderCell>Amount</TableHeaderCell>
                    <TableHeaderCell>Status</TableHeaderCell>
                    <TableHeaderCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customerProducts.map(customerProduct => (
                    <TableRow>
                      <TableCell>{customerProduct.id}</TableCell>
                      <TableCell>{customerProduct.product.name}</TableCell>
                      <TableCell>
                        {customerProduct.customerSku
                          ? customerProduct.customerSku.sku
                          : "-"}
                      </TableCell>
                      <TableCell>
                        ${customerProduct.amount.toFixed(2)}
                      </TableCell>
                      <TableCell>{customerProduct.workflowStatus}</TableCell>
                      <TableCell>
                        {customerProduct.isDeletable && (
                          <DangerButton
                            size="small"
                            type="text"
                            onClick={() =>
                              this.onConfirmDeleteDialog(customerProduct)
                            }
                          >
                            Remove
                          </DangerButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withSnackbar(ProductBatchDialog);
