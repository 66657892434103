import React, { Component } from "react";
import PropTypes from "prop-types";
import { Mutation, Query } from "react-apollo";
import { debounce } from "lodash";

import USERS_QUERY from "./users-query";
import UPDATE_CUSTOMER_MUTATION from "./update-customer-mutation";
import Workspace from "./workspace";
import ErrorDisplay from "../../common/error-display";

class AccountManagerAssignmentContainer extends Component {
  state = {
    search: "",
    searchText: ""
  };

  onSearchUpdated = searchText => {
    this.setState({ searchText });
    this.onSearchChanged(searchText);
  };

  onSearchChanged = debounce(search => {
    this.setState({ search });
  }, 250);

  render() {
    const { search, searchText } = this.state;
    return (
      <Mutation mutation={UPDATE_CUSTOMER_MUTATION}>
        {updateCustomerMutation => (
          <Query
            query={USERS_QUERY}
            variables={{ search }}
            fetchPolicy="network-only"
          >
            {({ data, error }) => {
              if (error) {
                return <ErrorDisplay error={error} />;
              }
              return (
                <Workspace
                  {...this.props}
                  users={
                    data && data.users && data.users.items
                      ? data.users.items
                      : []
                  }
                  search={searchText}
                  onSearchUpdated={this.onSearchUpdated}
                  updateCustomerMutation={updateCustomerMutation}
                />
              );
            }}
          </Query>
        )}
      </Mutation>
    );
  }
}

AccountManagerAssignmentContainer.propTypes = {
  task: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired
};

export default AccountManagerAssignmentContainer;
