import React, { FunctionComponent, useState } from "react";

import { withSnackbar, InjectedNotistackProps } from "notistack";
import TaskNotesDialog from "./task-notes-dialog";
import {
  CreateNoteComponent,
  TaskNotesComponent
} from "../../generated/graphql";

interface Props extends InjectedNotistackProps {
  customerId: number;
  taskId: number;
  onCancel: () => void;
  allowNewNotes: boolean;
}

const TaskNotesDialogContainer: FunctionComponent<Props> = (props: Props) => {
  const { taskId, customerId } = props;

  const [limit, setLimit] = useState<number>(20);
  const [offset, setOffset] = useState<number>(0);

  const onNextPage = () => {
    setOffset(offset + limit);
  };

  const onPreviousPage = () => {
    if (offset <= limit) {
      setOffset(0);
    } else {
      setOffset(offset - limit);
    }
  };

  return (
    <TaskNotesComponent variables={{ taskId, limit, offset }}>
      {({ data, loading, error, refetch }) => {
        if (loading) {
          return null;
        }
        const { enqueueSnackbar } = props;
        if (error) {
          enqueueSnackbar("Something went wrong trying to load notes.");
          return null;
        }
        if (!data || !data.task || !data.task.notes || !data.task.notes.items) {
          enqueueSnackbar("Something went wrong trying to load notes.");
          return null;
        }
        const {
          task: {
            notes: { items, totalCount }
          }
        } = data;
        return (
          <CreateNoteComponent>
            {createNoteMutation => (
              <TaskNotesDialog
                customerId={customerId}
                notes={items}
                createNoteMutation={createNoteMutation}
                refetchNotes={refetch}
                onNextPage={onNextPage}
                onPreviousPage={onPreviousPage}
                count={totalCount || 0}
                limit={limit}
                offset={offset}
                {...props}
              />
            )}
          </CreateNoteComponent>
        );
      }}
    </TaskNotesComponent>
  );
};

export default withSnackbar(TaskNotesDialogContainer);
