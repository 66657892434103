import React from "react";
import ReactDOM from "react-dom";
import { init } from "./utilities/date-helpers";

import App from "./components/app";

init();

console.log(`Nozani Central: ${process.env.REACT_APP_VERSION}`); // eslint-disable-line no-console
ReactDOM.render(<App />, document.getElementById("root"));
