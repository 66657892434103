import React, { FunctionComponent } from "react";

import WorkspaceDisplay from "./workspace-display";
import {
  GetCustomerCustomer,
  StrategyTasksComponent,
  UpdateStrategyTaskComponent,
  CreateStrategyTaskMutationComponent
} from "../../../generated/graphql";

interface Props {
  customer: GetCustomerCustomer;
}

const Workspace: FunctionComponent<Props> = ({ customer }) => (
  <CreateStrategyTaskMutationComponent>
    {createStrategyTaskMutation => (
      <UpdateStrategyTaskComponent>
        {updateStrategyTaskMutation => (
          <StrategyTasksComponent
            variables={{ customerId: customer.id }}
            fetchPolicy="network-only"
          >
            {strategyTaskResult => (
              <WorkspaceDisplay
                customer={customer}
                strategyTaskResult={strategyTaskResult}
                createStrategyTaskMutation={createStrategyTaskMutation}
                updateStrategyTaskMutation={updateStrategyTaskMutation}
              />
            )}
          </StrategyTasksComponent>
        )}
      </UpdateStrategyTaskComponent>
    )}
  </CreateStrategyTaskMutationComponent>
);

export default Workspace;
