import React, { FunctionComponent } from "react";

/* GraphQL & Apollo Imports */
import {
  CreateNoteReminderComponent,
  CustomerNotesNoteReminders,
  DeleteNoteReminderComponent,
  UpdateNoteReminderComponent,
  CreateStrategyTaskComponent,
  StrategyTasksVariables,
  StrategyTasksQuery
} from "../../../../generated/graphql";
import { ApolloQueryResult } from "apollo-client";

/* Child Components */
import NoteRemindersList from "./note-reminders-list";
interface Props {
  customerId: number;
  noteId: number;
  reminders: CustomerNotesNoteReminders[] | undefined;
  isAddReminder: boolean;
  resetIsAddReminder: () => void;
  refetchNoteData?: () => void;
  strategyRefetch: (
    variables?: StrategyTasksVariables | undefined
  ) => Promise<ApolloQueryResult<StrategyTasksQuery>>;
}

const NoteReminderContainer: FunctionComponent<Props> = ({
  customerId,
  noteId,
  reminders,
  isAddReminder,
  resetIsAddReminder,
  refetchNoteData,
  strategyRefetch
}) => (
  <DeleteNoteReminderComponent>
    {deleteNotReminderMutation => (
      <UpdateNoteReminderComponent>
        {updateNoteReminderMutation => (
          <CreateStrategyTaskComponent>
            {createStrategyTaskMutation => (
              <CreateNoteReminderComponent>
                {createNoteReminderMutation => (
                  <NoteRemindersList
                    customerId={customerId}
                    noteId={noteId}
                    reminders={reminders}
                    isAddReminder={isAddReminder}
                    resetIsAddReminder={resetIsAddReminder}
                    refetchNoteData={refetchNoteData}
                    createStrategyTaskMutation={createStrategyTaskMutation}
                    createNoteReminderMutation={createNoteReminderMutation}
                    deleteNotReminderMutation={deleteNotReminderMutation}
                    updateNoteReminderMutation={updateNoteReminderMutation}
                    strategyRefetch={strategyRefetch}
                  />
                )}
              </CreateNoteReminderComponent>
            )}
          </CreateStrategyTaskComponent>
        )}
      </UpdateNoteReminderComponent>
    )}
  </DeleteNoteReminderComponent>
);

export default NoteReminderContainer;
