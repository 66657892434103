import React, { Fragment } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Button, InputAdornment } from "@material-ui/core";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import AppIcon from "./app-icon";
import { withSnackbar, InjectedNotistackProps } from "notistack";

interface Props extends InjectedNotistackProps {
  fieldName: string;
  displayText?: string;
  textToCopy: string;
}

/* CopyButton is a reusable component for copying text to a user's clipboard. See Seller Central Information card under Amazon Accounts tab for example. */

const CopyButton = (props: Props) => (
  <Fragment>
    {props.displayText}
    <CopyToClipboard text={props.textToCopy}>
      <Button
        variant="text"
        color="secondary"
        size="small"
        style={{ paddingTop: "0" }}
        onClick={() =>
          props.enqueueSnackbar(`${props.fieldName} was copied successfully!`, {
            variant: "success"
          })
        }
      >
        <InputAdornment position="end">
          <AppIcon icon={faCopy} standardRightMargin fixedWidth />
        </InputAdornment>
        Copy
      </Button>
    </CopyToClipboard>
  </Fragment>
);

export default withSnackbar(CopyButton);
