import * as React from "react";

import { SelectTaskTask, TaskStatusEnum } from "../../generated/graphql";

/* Font Awesome Icons */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faComment, faTasks } from "@fortawesome/free-solid-svg-icons";

/* Components */
import {
  Button,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Divider
} from "@material-ui/core";
import PageLayout from "../common/page-layout";
import TaskDetails from "./task-details";
import SkuDetails from "./sku-details";
import { StandardCard } from "../common/standard-card";
import AlertSuccess from "../../components/common/styled/alert-succes";
import TaskNotesModalContainer from "../task-notes-modal";

interface TaskCompletedProps {
  customerId: number;
  task: SelectTaskTask;
  showTaskNotes: () => void;
  closeTaskNotes: () => void;
  onClose: () => Promise<void>;
  onStillWorking: () => void;
  onNotWorking: () => void;
  isNotesModalVisible: boolean;
}

const TaskCompleted: React.SFC<TaskCompletedProps> = ({
  task,
  showTaskNotes,
  closeTaskNotes,
  onClose,
  isNotesModalVisible,
  customerId
}) => (
  <React.Fragment>
    {isNotesModalVisible && (
      <TaskNotesModalContainer
        customerId={customerId}
        taskId={task.id}
        onCancel={closeTaskNotes}
        allowNewNotes={
          task.statusId !== TaskStatusEnum.Cancelled &&
          task.statusId !== TaskStatusEnum.Completed
        }
      />
    )}
    <PageLayout pageTitle="Task Workspace">
      <Grid container spacing={16}>
        <Grid item xs={12} lg={7}>
          <StandardCard title={task.title ? task.title : task.taskType.name}>
            <div style={{ marginBottom: "1em" }}>
              <Button onClick={showTaskNotes}>
                <FontAwesomeIcon
                  icon={faComment}
                  style={{ marginRight: ".5em" }}
                />
                {task.notes ? task.notes.totalCount : 0} notes
              </Button>
            </div>
            <AlertSuccess message="This task has been completed!" />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={onClose}
                size="large"
                style={{ marginLeft: ".5em" }}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{ marginRight: ".5em" }}
                />
                Close
              </Button>
            </div>
          </StandardCard>
        </Grid>
        <Grid item xs={false} lg={5}>
          <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary>
              <span>
                <FontAwesomeIcon
                  icon={faTasks}
                  style={{ marginRight: ".5em" }}
                />
                Task Information
              </span>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <TaskDetails task={task} />
              {task.customerProduct && task.customerProduct.customerSku && (
                <React.Fragment>
                  <Divider />
                  <SkuDetails customerSku={task.customerProduct.customerSku} />
                </React.Fragment>
              )}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
      </Grid>
    </PageLayout>
  </React.Fragment>
);

export default TaskCompleted;
