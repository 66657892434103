import React, { FunctionComponent } from "react";

/* Constants */
import { REPORTS } from "../../constants/reports";

/* Material UI */
import {
  Grid,
  CardActionArea,
  CardContent,
  Typography,
  Card
} from "@material-ui/core";

/* Common Components */
import AppLink from "../common/app-link";

const ReportsList: FunctionComponent = () => {
  return (
    <Grid container spacing={16}>
      <Grid item xs={12} lg={6}>
        {REPORTS.map(report => (
          <Card style={{ marginBottom: "1rem" }}>
            <AppLink to={`/reports/${report.path}`}>
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="subtitle1">
                    <strong>{report.name}</strong>
                  </Typography>
                  <Typography variant="subtitle2">
                    {report.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </AppLink>
          </Card>
        ))}
      </Grid>
    </Grid>
  );
};

export default ReportsList;
