import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { ApolloProvider } from "react-apollo";
import { MuiThemeProvider, CssBaseline } from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { SnackbarProvider } from "notistack";

/* ApolloClientProvider is the Apollo Client configuration passed into the ApolloProvider component */
import ApolloClientProvider from "./core/apollo-client-provider";
/* GoogleAnalyticsProvider HOC that provides page tracking metrics to google analytics */
import GoogleAnalyticsProvider from "./core/google-analytics-provider";
/* AuthManagaer is HOC that provides the Authentication barrier to any protected routes/pages */
import AuthManager from "./core/auth-manager";
/* NotFound is a catch all view when url requests don't match any routes */
import NotFound from "./not-found";
/* ErrorBoundary catches any JavaScript errors anywhere in it's child component tree and logs those errors, and displays a fallback UI */
import ErrorBoundary from "./core/error-boundary";
/* AmplitudeProvider creates an amplitude instance with the current user/viewer */
import AmplitudeProvider from "./core/amplitude-provider";
/* AppUpdateMonitor uses pusher to listen for any updates to the app that have been pushed to production. It automatically re-renders the app without the user having to refresh the app manually */
import AppUpdateMonitor from "./core/app-update-monitor";
/* DoNotTrackDetector detects for users whose brower's setting have  "Do Not Track" enabled. Provides instructions on how to disable the setting in Chrome. */
import DoNotTrackDetector from "./core/do-not-track-detector";

/* Application Pages/Views */
import BillingPage from "./billing";
import CustomersPage from "./customers";
import DashboardPage from "./dashboard";
import CustomerPage from "./customer/customer-with-data";
import TaskPage from "./task";
import TasksPage from "./tasks";
import PageTracker from "./core/page-tracker";
import RetainerBudgetPage from "./pricing-tool";
import UsersContainer from "./users";
import UserContainer from "./user";
import BatchWorkspace from "./task/batch";
import InventoryContainer from "./inventory";
import ReportsList from "./reports";
import CustomersEndingIn30DaysReportContainer from "./reports/customers-ending-in-30-days";
import CustomersNoAssignedAccountManagerReportContainer from "./reports/customers-no-assigned-account-manager";
import AccountManagementOverviewReportContainer from "./reports/account-management-overview";
import TeamsContainer from "./teams";
import TeamContainer from "./team";
import AdminContainer from "./admin";
import HelpContainer from "./help";

/* Material UI theme configuration */
import theme from "./core/mui-theme";
import BatchContainer from "./batch";
import RollbarPersonTracker from "./core/rollbar-person-tracker";

const App = () => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ApolloProvider client={ApolloClientProvider.client}>
          <Router>
            <ErrorBoundary>
              <DoNotTrackDetector>
                <AuthManager>
                  <RollbarPersonTracker />
                  <AppUpdateMonitor>
                    <SnackbarProvider
                      maxSnack={3}
                      autoHideDuration={5000}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                      }}
                    >
                      <GoogleAnalyticsProvider>
                        <AmplitudeProvider>
                          <PageTracker />
                          <Switch>
                            <Route
                              path="/billing"
                              exact
                              render={() => <BillingPage />}
                            />
                            <Route
                              path="/customers"
                              exact
                              render={() => <CustomersPage />}
                            />
                            <Route
                              path="/users/:userId/:activeSection?"
                              exact
                              component={UserContainer}
                            />
                            <Route
                              path="/users"
                              exact
                              component={UsersContainer}
                            />
                            <Route
                              path="/customers/:customerId/:activeSection?"
                              render={props => <CustomerPage {...props} />}
                            />
                            <Route
                              path="/dashboard"
                              exact
                              render={() => <DashboardPage />}
                            />
                            <Route path="/tasks" exact component={TasksPage} />
                            <Route
                              path="/tasks/batch"
                              exact
                              component={BatchWorkspace}
                            />
                            <Route
                              path="/batches/:batchId"
                              exact
                              render={({ match: { params } }) => (
                                <BatchContainer batchId={params.batchId} />
                              )}
                            />
                            <Route
                              path="/tasks/:taskId"
                              exact
                              render={({ match: { params } }) => (
                                <TaskPage taskId={params.taskId} />
                              )}
                            />
                            <Route
                              path="/pricing-tool"
                              exact
                              component={RetainerBudgetPage}
                            />
                            <Route
                              path="/inventory"
                              exact
                              component={InventoryContainer}
                            />
                            <Route
                              path="/reports"
                              exact
                              component={ReportsList}
                            />
                            <Route
                              path="/reports/customers-ending-in-30-days"
                              exact
                              component={CustomersEndingIn30DaysReportContainer}
                            />
                            <Route
                              path="/reports/customers-no-assigned-account-manager"
                              exact
                              component={
                                CustomersNoAssignedAccountManagerReportContainer
                              }
                            />
                            <Route
                              path="/reports/account-management-overview"
                              exact
                              component={
                                AccountManagementOverviewReportContainer
                              }
                            />
                            <Route
                              path="/teams"
                              exact
                              component={TeamsContainer}
                            />
                            <Route
                              path="/teams/:teamId"
                              exact
                              render={({ match: { params } }) => (
                                <TeamContainer teamId={params.teamId} />
                              )}
                            />
                            <Route
                              path="/login"
                              exact
                              render={() => <DashboardPage />}
                            />
                            <Route
                              path="/"
                              exact
                              render={() => <Redirect to="/dashboard" />}
                            />
                            <Route
                              path="/admin"
                              exact
                              render={() => <AdminContainer />}
                            />
                            <Route
                              path="/help"
                              exact
                              render={() => <HelpContainer />}
                            />
                            <Route path="*" render={() => <NotFound />} />
                          </Switch>
                        </AmplitudeProvider>
                      </GoogleAnalyticsProvider>
                    </SnackbarProvider>
                  </AppUpdateMonitor>
                </AuthManager>
              </DoNotTrackDetector>
            </ErrorBoundary>
          </Router>
        </ApolloProvider>
      </MuiPickersUtilsProvider>
    </CssBaseline>
  </MuiThemeProvider>
);

export default App;
