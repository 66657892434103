import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@material-ui/core";

class Loader extends Component {
  state = {
    showLoader: false
  };

  componentDidMount() {
    const { delay } = this.props;
    this.timeout = setTimeout(() => {
      this.setState({ showLoader: true });
    }, delay);
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  render() {
    const { large, message } = this.props;
    const { showLoader } = this.state;
    if (!showLoader) {
      return null;
    }
    return (
      <Typography variant={large ? "h4" : "body1"}>
        <FontAwesomeIcon
          icon={faCircleNotch}
          spin
          style={{ marginRight: ".5em" }}
        />
        {message}
      </Typography>
    );
  }
}

Loader.defaultProps = {
  large: false,
  message: "",
  delay: 250
};

Loader.propTypes = {
  large: PropTypes.bool,
  message: PropTypes.string,
  delay: PropTypes.number
};

export default Loader;
