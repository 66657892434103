import React from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import { has } from "lodash";

import PROJECTS_PRODUCT_SELECT_QUERY from "./select-projects-products-query";
import ProjectsTable from "./projects-table";
import Loader from "../../common/loader";

const Projects = ({ customer }) => (
  <Query
    query={PROJECTS_PRODUCT_SELECT_QUERY}
    variables={{ customerId: customer.id }}
    fetchPolicy="network-only"
  >
    {({ data, loading, error }) => {
      if (loading) {
        return <Loader message="Loading..." />;
      }
      if (error || !data) {
        return `Error: ${error}`;
      }
      return (
        <ProjectsTable
          customer={customer}
          projects={
            has(data, "customer.customerProjects")
              ? data.customer.customerProjects
              : []
          }
        />
      );
    }}
  </Query>
);

Projects.propTypes = {
  customer: PropTypes.shape({}).isRequired
};

export default Projects;
