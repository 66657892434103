import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { QueryResult, MutationFn } from "react-apollo";

/* GraphQL */
import {
  SelectCustomerUsersQuery,
  SelectCustomerUsersVariables,
  SelectCustomerUsersPhoneNumbers,
  DeleteUserPhoneNumberMutation,
  DeleteUserPhoneNumberVariables,
  SelectCustomerUsersEmailAddresses,
  DeleteUserEmailAddressMutation,
  DeleteUserEmailAddressVariables
} from "../../../generated/graphql";

/* Material UI */
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Button,
  Grid
} from "@material-ui/core";

/* Font Awesome */
import {
  faTrash,
  faCheckCircle,
  faPlus
} from "@fortawesome/free-solid-svg-icons";

/* Common Components */
import { TableHeaderCell } from "../../common/table-header-cell";
import Loader from "../../common/loader";
import ErrorDisplay from "../../common/error-display";
import Alert from "../../common/styled/alert-info";
import AppIcon from "../../common/app-icon";

/* Styled Components */

const TableCellText = styled.div`
  white-space: nowrap;
  padding: 6px 0;
`;
interface Props {
  queryResult: QueryResult<
    SelectCustomerUsersQuery,
    SelectCustomerUsersVariables
  >;
  onAddUserPhoneNumber: (userId: number) => void;
  onEditUserPhoneNumber: (
    userPhoneNumber: SelectCustomerUsersPhoneNumbers
  ) => void;
  deleteUserPhoneNumberMutation: MutationFn<
    DeleteUserPhoneNumberMutation,
    DeleteUserPhoneNumberVariables
  >;
  onUserPhoneNumberDeleted: () => any;
  onAddUserEmailAddress: (userId: number) => void;
  onEditUserEmailAddress: (
    userEmailAddress: SelectCustomerUsersEmailAddresses
  ) => void;
  deleteUserEmailAddressMutation: MutationFn<
    DeleteUserEmailAddressMutation,
    DeleteUserEmailAddressVariables
  >;
  onUserEmailAddressDeleted: () => any;
  onEditCustomerUser: (
    customerUserId: number,
    firstName: string,
    lastName: string,
    title: string,
    notes: string
  ) => void;
}

interface State {}

class CustomerUsersTable extends Component<Props, State> {
  state = {};

  onDeleteUserPhoneNumber = async (userPhoneNumberId: number) => {
    const {
      deleteUserPhoneNumberMutation,
      onUserPhoneNumberDeleted
    } = this.props;
    try {
      await deleteUserPhoneNumberMutation({
        variables: { userPhoneNumberId }
      });
      onUserPhoneNumberDeleted();
    } catch (e) {}
  };

  onDeleteUserEmailAddress = async (userEmailAddressId: number) => {
    const {
      deleteUserEmailAddressMutation,
      onUserEmailAddressDeleted
    } = this.props;
    try {
      await deleteUserEmailAddressMutation({
        variables: { userEmailAddressId }
      });
      onUserEmailAddressDeleted();
    } catch (e) {}
  };

  onCloseError = () => {
    this.setState({ errorMessage: null });
  };

  render() {
    const {
      queryResult,
      onAddUserPhoneNumber,
      onEditUserPhoneNumber,
      onAddUserEmailAddress,
      onEditUserEmailAddress,
      onEditCustomerUser
    } = this.props;
    if (queryResult.loading) {
      return <Loader />;
    }
    if (queryResult.error) {
      return <ErrorDisplay error={queryResult.error} />;
    }
    if (!queryResult.data || !queryResult.data.customer) {
      return null;
    }

    const { customerUsers } = queryResult.data.customer;
    if (customerUsers.length === 0) {
      return <Alert message="No customer users." />;
    }

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell>Name</TableHeaderCell>
            <TableHeaderCell>Email(s)</TableHeaderCell>
            <TableHeaderCell>Phone Number(s)</TableHeaderCell>
            <TableHeaderCell>Title</TableHeaderCell>
            <TableHeaderCell>Notes</TableHeaderCell>
            <TableHeaderCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {customerUsers &&
            customerUsers.map(customerUser => (
              <TableRow>
                <TableCell>
                  {!customerUser.user.fullName && <span>-</span>}
                  <TableCellText>{customerUser.user.fullName}</TableCellText>
                </TableCell>
                <TableCell>
                  <TableCellText style={{ whiteSpace: "normal" }}>
                    {customerUser.user.emailAddresses.length === 0 && (
                      <span>-</span>
                    )}
                    {customerUser.user.emailAddresses.map(emailAddress => (
                      <Fragment>
                        <Grid container>
                          <Grid
                            style={{
                              padding: "0.375rem",
                              whiteSpace: "nowrap"
                            }}
                          >
                            <strong>{emailAddress.label}</strong>:{" "}
                            {emailAddress.email}
                            {emailAddress.isPrimary ? (
                              <AppIcon
                                icon={faCheckCircle}
                                color="green"
                                standardLeftMargin
                                title="Primary Email Address"
                              />
                            ) : (
                              ""
                            )}
                          </Grid>
                          <Grid style={{ whiteSpace: "nowrap" }}>
                            <Button
                              size="small"
                              color="primary"
                              onClick={() =>
                                onEditUserEmailAddress(emailAddress)
                              }
                              style={{ marginLeft: ".5em" }}
                            >
                              Edit
                            </Button>
                            <Button
                              size="small"
                              onClick={() =>
                                this.onDeleteUserEmailAddress(emailAddress.id)
                              }
                              style={{ marginLeft: ".5em" }}
                            >
                              <AppIcon icon={faTrash} />
                            </Button>
                          </Grid>
                        </Grid>
                      </Fragment>
                    ))}
                  </TableCellText>
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => onAddUserEmailAddress(customerUser.user.id)}
                  >
                    <AppIcon icon={faPlus} standardRightMargin />
                    Add
                  </Button>
                </TableCell>
                <TableCell>
                  <TableCellText style={{ whiteSpace: "normal" }}>
                    {customerUser.user.phoneNumbers.length === 0 && (
                      <span>-</span>
                    )}
                    {customerUser.user.phoneNumbers.map(phoneNumber => (
                      <Fragment>
                        <Grid container>
                          <Grid
                            style={{
                              padding: "0.375rem",
                              whiteSpace: "nowrap"
                            }}
                          >
                            <strong>{phoneNumber.label}</strong>:{" "}
                            {phoneNumber.phoneNumber.replace(
                              /(\d{3})(\d{3})(\d{4})/,
                              "($1) $2-$3"
                            )}
                            {phoneNumber.isPrimary ? (
                              <span style={{ color: "green" }}>
                                <AppIcon
                                  icon={faCheckCircle}
                                  standardLeftMargin
                                  title="Primary Phone Number"
                                />
                              </span>
                            ) : (
                              ""
                            )}
                          </Grid>
                          <Grid style={{ whiteSpace: "nowrap" }}>
                            <Button
                              size="small"
                              color="primary"
                              onClick={() => onEditUserPhoneNumber(phoneNumber)}
                              style={{ marginLeft: ".5em" }}
                            >
                              Edit
                            </Button>
                            <Button
                              size="small"
                              onClick={() =>
                                this.onDeleteUserPhoneNumber(phoneNumber.id)
                              }
                              style={{ marginLeft: ".5em" }}
                            >
                              <AppIcon icon={faTrash} />
                            </Button>
                          </Grid>
                        </Grid>
                      </Fragment>
                    ))}
                  </TableCellText>
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => onAddUserPhoneNumber(customerUser.user.id)}
                  >
                    <AppIcon icon={faPlus} standardRightMargin />
                    Add
                  </Button>
                </TableCell>
                <TableCell>
                  {!customerUser.title && <span>-</span>}
                  <TableCellText>{customerUser.title}</TableCellText>
                </TableCell>
                <TableCell style={{ width: "25%" }}>
                  {!customerUser.notes && <span>-</span>}
                  <TableCellText style={{ whiteSpace: "normal" }}>
                    {customerUser.notes}
                  </TableCellText>
                </TableCell>
                <TableCell>
                  <Button
                    size="small"
                    color="primary"
                    onClick={() =>
                      onEditCustomerUser(
                        customerUser.id,
                        customerUser.user.firstName,
                        customerUser.user.lastName,
                        customerUser.title ? customerUser.title : "",
                        customerUser.notes ? customerUser.notes : ""
                      )
                    }
                    style={{ marginLeft: ".5em" }}
                  >
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          <TableRow />
        </TableBody>
      </Table>
    );
  }
}

export default CustomerUsersTable;
