import React, { FunctionComponent } from "react";

/* Material UI */
import { Grid } from "@material-ui/core";

/* Child Components */
import SkuDetailsOverview from "./sku-details-overview";
import VariationSkusTable from "./variation-skus-table";

/* Graphql & Apollo Imports */
import {
  SelectCustomerSkuCustomerSku,
  SelectCustomerSkuVariables,
  SelectCustomerSkuQuery,
  UpdateCustomerSkuDetailsMutation,
  UpdateCustomerSkuDetailsVariables,
  GetCustomerCustomer
} from "../../../../generated/graphql";
import { ApolloQueryResult } from "apollo-client";
import { MutationFn } from "react-apollo";

interface Props {
  customer: GetCustomerCustomer;
  customerSku: SelectCustomerSkuCustomerSku;
  refetch: (
    variables?: SelectCustomerSkuVariables | undefined
  ) => Promise<ApolloQueryResult<SelectCustomerSkuQuery>>;
  updateCustomerSkuDetailsMutation: MutationFn<
    UpdateCustomerSkuDetailsMutation,
    UpdateCustomerSkuDetailsVariables
  >;
}

const SkuDetails: FunctionComponent<Props> = props => {
  const {
    refetch,
    customer,
    customerSku,
    updateCustomerSkuDetailsMutation
  } = props;

  return (
    <Grid container spacing={16}>
      <Grid item md={6}>
        <SkuDetailsOverview
          customer={customer}
          refetch={refetch}
          customerSku={customerSku}
          updateCustomerSkuDetailsMutation={updateCustomerSkuDetailsMutation}
        />
      </Grid>
      {customerSku.children && customerSku.children.length > 0 && (
        <Grid item md={6}>
          <VariationSkusTable customer={customer} customerSku={customerSku} />
        </Grid>
      )}
    </Grid>
  );
};

export default SkuDetails;
