import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Avatar
} from "@material-ui/core";

import PageLayout from "../common/page-layout";
import AppLink from "../common/app-link";
import { StandardCard } from "../common/standard-card";
import { TableHeaderCell } from "../common/table-header-cell";

class Users extends Component {
  state = {};

  render() {
    const { users, onSearchChanged } = this.props;
    return (
      <PageLayout pageTitle="Users">
        <StandardCard title="Users">
          <div>
            <TextField
              onChange={e => onSearchChanged(e.target.value)}
              size="large"
              autoFocus
              variant="outlined"
              fullWidth
            />
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeaderCell>Name</TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users &&
                users.map(user => (
                  <TableRow key={user.id}>
                    <TableCell>
                      <AppLink to={`/users/${user.id}`}>
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <Avatar
                            src={user.profileImageUrl}
                            style={{ marginRight: ".5em" }}
                          />
                          {user.fullName}
                        </span>
                      </AppLink>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </StandardCard>
      </PageLayout>
    );
  }
}

Users.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSearchChanged: PropTypes.func.isRequired
};

export default Users;
