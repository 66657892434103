import styled from "styled-components";

export default styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  p {
    margin-top: 0.8rem;
  }
`;
