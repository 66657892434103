export type Maybe<T> = T | null;

export interface CustomerSkuSort {
  direction: SortDirection;

  field: CustomerSkuSortField;
}

export interface DateQuery {
  eq?: Maybe<Date>;

  gt?: Maybe<Date>;

  gte?: Maybe<Date>;

  lt?: Maybe<Date>;

  lte?: Maybe<Date>;
}

export interface CustomerProjectSort {
  direction: SortDirection;

  field: CustomerProjectSortField;
}

export interface CustomerSort {
  direction: SortDirection;

  field: CustomerSortField;
}

export interface InventoryItemsSort {
  direction: SortDirection;

  field: InventoryItemsSortField;
}

export interface TaskSort {
  direction: SortDirection;

  field: TaskSortField;
}

export interface UserSort {
  direction: SortDirection;

  field: UserSortField;
}

export interface AddUserToGroupInput {
  userId: number;

  groupId: string;
}

export interface AdminOpsInput {
  ensureBatchWorkflow?: Maybe<EnsureBatchWorkflowInput>;

  ensureWorkflow?: Maybe<EnsureWorkflowInput>;

  pipedriveSyncDeal?: Maybe<PipedriveSyncDeal>;

  pipedriveSyncDeals?: Maybe<PipedriveSyncDeals>;

  pipedriveSyncUsers?: Maybe<boolean>;

  startCustomerProductBatch?: Maybe<StartCustomerProductBatchInput>;

  startCustomerProject?: Maybe<StartCustomerProjectInput>;

  testSlackNotification?: Maybe<TestSlackNotificationInput>;

  bambooHRUsersSync?: Maybe<boolean>;

  migrateUsers?: Maybe<boolean>;

  googleSyncUsers?: Maybe<boolean>;

  customerProjectsCompletionCheck?: Maybe<boolean>;

  customerSkusFixStalledSync?: Maybe<boolean>;
}

export interface EnsureBatchWorkflowInput {
  customerProductBatchId: number;
}

export interface EnsureWorkflowInput {
  customerProductId: number;
}

export interface PipedriveSyncDeal {
  customerId: number;
}

export interface PipedriveSyncDeals {
  shouldSync: boolean;
}

export interface StartCustomerProductBatchInput {
  customerProductBatchId: number;
}

export interface StartCustomerProjectInput {
  customerProjectId: number;
}

export interface TestSlackNotificationInput {
  targetUserId: number;

  message: string;
}

export interface CreateCustomerAmazonAccountInput {
  customerId: number;

  email: string;

  password: string;

  totpSecret?: Maybe<string>;
}

export interface CreateCustomerAmazonAccountRevisionInput {
  customerAmazonAccountId: number;

  email: string;

  password: string;

  totpSecret?: Maybe<string>;
}

export interface CreateCustomerContractInput {
  customerId: number;

  startDate: Date;

  projects: CreateCustomerContractProjectInput[];

  notes?: Maybe<string>;
}

export interface CreateCustomerContractProjectInput {
  name: string;

  startDate: Date;

  amount: number;

  notes?: Maybe<string>;
}

export interface CreateCustomerFileInput {
  customerId: number;

  name: string;

  sizeInBytes: number;

  mimeType: string;
}

export interface CreateCustomerMwsCredentialInput {
  customerId: number;

  name: string;

  isPrimary: boolean;

  sellerId: string;

  authToken: string;
}

export interface CreateCustomerMwsCredentialRevisionInput {
  customerMwsCredentialId: number;

  name: string;

  sellerId: string;

  authToken: string;
}

export interface CreateCustomerProductBatchInput {
  customerId: number;

  customerProjectId: number;

  startDate: Date;

  items?: Maybe<CreateCustomerProductBatchItemInput[]>;
}

export interface CreateCustomerProductBatchItemInput {
  productId: string;

  customerSkuId?: Maybe<number>;

  discount?: Maybe<number>;

  amount?: Maybe<number>;
}

export interface CreateCustomerProductInput {
  customerId: number;

  customerProjectId: number;

  customerContractId?: Maybe<number>;

  customerSkuId?: Maybe<number>;

  productId: string;

  discount?: Maybe<number>;

  amount?: Maybe<number>;

  startDate: Date;
}

export interface CreateCustomerProjectInput {
  customerId: number;

  name: string;

  customerContractId?: Maybe<number>;

  amount: number;

  startDate: Date;

  notes?: Maybe<string>;

  includesStrategy: boolean;
}

export interface CreateCustomerSkuInput {
  sku: string;

  title?: Maybe<string>;

  customerId: number;

  asin?: Maybe<string>;

  upc?: Maybe<string>;

  parentCustomerSkuId?: Maybe<number>;

  skuType?: Maybe<string>;
}

export interface CreateCustomerSkuSyncRequestInput {
  customerId: number;
}

export interface CreateCustomerStarInput {
  customerId: number;
}

export interface CreateCustomerUserInput {
  customerId: number;

  firstName: string;

  lastName: string;

  email: string;

  title?: Maybe<string>;

  notes?: Maybe<string>;
}

export interface CreateCustomerInput {
  name: string;

  partnerId: number;
}

export interface CreateInfographicReviewItemsInput {
  customerProductBatchId: number;

  reviewTaskId: number;

  items?: Maybe<CreateInfographicReviewItemInput[]>;
}

export interface CreateInfographicReviewItemInput {
  customerProductId: number;

  needsRevision: boolean;

  comments?: Maybe<string>;
}

export interface CreateInventoryItemInput {
  customerId: number;

  customerSkuId?: Maybe<number>;

  description?: Maybe<string>;

  receivedAt: DateTime;

  locationId: string;

  storageLocation?: Maybe<string>;

  conditionId: string;

  quantity: number;
}

export interface CreateNoteInput {
  entityType: NoteEntityType;

  entityId: string;

  content: string;

  originalNoteId?: Maybe<number>;
}

export interface CreateNoteReminderInput {
  noteId: number;

  content: string;
}

export interface CreatePhotographyReviewItemsInput {
  customerProductBatchId: number;

  reviewTaskId: number;

  items?: Maybe<CreatePhotographyReviewItemInput[]>;
}

export interface CreatePhotographyReviewItemInput {
  customerProductId: number;

  needsReshoot: boolean;

  needsEditing: boolean;

  comments?: Maybe<string>;
}

export interface CreateStrategyTaskInput {
  customerId: number;

  title: string;

  dueDate?: Maybe<DateTime>;
}

export interface CreateTaskInput {
  assignedUserId?: Maybe<number>;

  customerId: number;

  title: string;

  dueDate?: Maybe<DateTime>;

  effectiveDate?: Maybe<DateTime>;
}

export interface CreateTeamInput {
  name: string;

  leadUserId: number;

  department: string;
}

export interface CreateTeamUserInput {
  teamId: number;

  userId: number;
}

export interface CreateUserEmailAddressInput {
  userId: number;

  email: string;

  label: string;

  isPrimary: boolean;
}

export interface CreateUserPhoneNumberInput {
  userId: number;

  phoneNumber: string;

  label: string;

  isPrimary: boolean;
}

export interface DeleteCustomerContractInput {
  customerContractId: number;
}

export interface DeleteCustomerMwsCredentialInput {
  customerMwsCredentialId: number;
}

export interface DeleteCustomerProductInput {
  customerProductId: number;
}

export interface DeleteCustomerProductBatchInput {
  customerProductBatchId: number;
}

export interface DeleteCustomerProjectInput {
  customerProjectId: number;
}

export interface DeleteCustomerStarInput {
  customerId: number;
}

export interface DeleteCustomerUserInput {
  customerUserId: number;
}

export interface DeleteInventoryItemInput {
  inventoryItemId: number;
}

export interface DeleteNoteInput {
  noteId: number;
}

export interface DeleteNoteReminderInput {
  noteReminderId: number;
}

export interface DeleteTeamInput {
  teamId: number;
}

export interface DeleteTeamUserInput {
  teamUserId: number;
}

export interface DeleteTaskInput {
  taskId: number;
}

export interface RecordTaskWorkInput {
  taskId: number;

  secondsRecorded: number;
}

export interface RemoveUserFromGroupInput {
  userId: number;

  groupId: string;
}

export interface UpdateCustomerProductInput {
  customerProductId: number;

  startDate?: Maybe<Date>;

  amount?: Maybe<number>;

  customerSkuId?: Maybe<number>;
}

export interface UpdateCustomerProjectInput {
  customerProjectId: number;

  name?: Maybe<string>;

  amount?: Maybe<number>;

  startDate?: Maybe<Date>;

  statusId?: Maybe<string>;

  saleReferenceNumber?: Maybe<string>;
}

export interface UpdateCustomerSkuInput {
  customerSkuId: number;

  sku?: Maybe<string>;

  asin?: Maybe<string>;

  upc?: Maybe<string>;

  title?: Maybe<string>;

  syncStatus?: Maybe<string>;

  skuType?: Maybe<string>;

  parentCustomerSkuId?: Maybe<number>;
}

export interface UpdateCustomerInput {
  customerId: number;

  name?: Maybe<string>;

  url?: Maybe<string>;

  accountManagerUserId?: Maybe<number>;

  contactName?: Maybe<string>;

  contactPhone?: Maybe<string>;

  contactEmail?: Maybe<string>;

  additionalNames?: Maybe<string>;

  location?: Maybe<string>;

  leadSource?: Maybe<string>;

  hasSellerAccount?: Maybe<boolean>;

  hasVendorAccount?: Maybe<boolean>;

  pipedriveDealId?: Maybe<number>;

  happinessLevel?: Maybe<string>;
}

export interface UpdateCustomerUserInput {
  customerUserId: number;

  firstName?: Maybe<string>;

  lastName?: Maybe<string>;

  title?: Maybe<string>;

  notes?: Maybe<string>;
}

export interface UpdateInventoryItemInput {
  inventoryItemId: number;

  statusId?: Maybe<string>;

  description?: Maybe<string>;

  sentAt?: Maybe<DateTime>;

  locationId?: Maybe<string>;

  storageLocation?: Maybe<string>;

  conditionId?: Maybe<string>;
}

export interface UpdateNoteReminderInput {
  noteReminderId: number;

  content?: Maybe<string>;

  dueAt?: Maybe<DateTime>;

  completedAt?: Maybe<DateTime>;
}

export interface UpdateTaskInput {
  taskId: number;

  assignedUserId?: Maybe<number>;

  taskTypeId?: Maybe<string>;

  title?: Maybe<string>;

  dueDate?: Maybe<DateTime>;

  statusId?: Maybe<string>;

  isApproved?: Maybe<boolean>;

  approvalComment?: Maybe<string>;
}

export interface UpdateTeamInput {
  teamId: number;

  name?: Maybe<string>;

  leadUserId?: Maybe<number>;

  department?: Maybe<string>;
}

export interface UpdateUserInput {
  userId: number;

  pipedriveStageId?: Maybe<number>;
}

export interface UpdateUserEmailAddressInput {
  userEmailAddressId: number;

  email?: Maybe<string>;

  label?: Maybe<string>;

  isPrimary?: Maybe<boolean>;
}

export interface UpdateUserPhoneNumberInput {
  userPhoneNumberId: number;

  phoneNumber?: Maybe<string>;

  label?: Maybe<string>;

  isPrimary?: Maybe<boolean>;
}

export interface UpdateWelcomeCallInput {
  welcomeCallId: number;

  scheduledAt: DateTime;
}

export enum SortDirection {
  Asc = "asc",
  Desc = "desc"
}

export enum CustomerSkuSortField {
  Sku = "sku",
  Title = "title"
}

export enum CustomerProjectStatusEnum {
  Awaitingbilling = "awaitingbilling",
  Cancelled = "cancelled",
  Completed = "completed",
  Inprogress = "inprogress",
  Scheduled = "scheduled"
}

export enum CustomerProjectSortField {
  StartDate = "startDate"
}

export enum CustomerSortField {
  Name = "name"
}

export enum InventoryItemsSortField {
  Id = "id",
  CustomerId = "customerId"
}

export enum TaskStatusEnum {
  Assigned = "assigned",
  Available = "available",
  Blocked = "blocked",
  Cancelled = "cancelled",
  Completed = "completed",
  Scheduled = "scheduled"
}

export enum TaskSortField {
  Urgent = "urgent",
  DueDate = "dueDate",
  TaskType = "taskType"
}

export enum UserSortField {
  FirstName = "firstName",
  LastName = "lastName",
  Email = "email"
}

export enum NoteEntityType {
  Customer = "CUSTOMER",
  CustomerProject = "CUSTOMER_PROJECT",
  CustomerProduct = "CUSTOMER_PRODUCT",
  CustomerProductBatch = "CUSTOMER_PRODUCT_BATCH",
  Task = "TASK"
}

/** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
export type Date = any;

/** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
export type DateTime = any;

/** A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
export type Time = any;

// ====================================================
// Documents
// ====================================================

export type AdminVariables = {
  input: AdminOpsInput;
};

export type AdminMutation = {
  __typename?: "Mutation";

  admin: Maybe<string>;
};

export type GetCustomerProductBatchVariables = {
  customerProductBatchId: number;
};

export type GetCustomerProductBatchQuery = {
  __typename?: "Query";

  viewer: Maybe<GetCustomerProductBatchViewer>;

  customerProductBatch: Maybe<GetCustomerProductBatchCustomerProductBatch>;
};

export type GetCustomerProductBatchViewer = {
  __typename?: "User";

  id: number;
};

export type GetCustomerProductBatchCustomerProductBatch = {
  __typename?: "CustomerProductBatch";

  id: number;

  startDate: Date;

  tasks: GetCustomerProductBatchTasks[];

  customerProducts: GetCustomerProductBatchCustomerProducts[];
};

export type GetCustomerProductBatchTasks = {
  __typename?: "Task";

  id: number;

  title: string;

  status: GetCustomerProductBatchStatus;

  taskType: GetCustomerProductBatchTaskType;

  assignedUser: Maybe<GetCustomerProductBatchAssignedUser>;
};

export type GetCustomerProductBatchStatus = {
  __typename?: "TaskStatus";

  id: string;

  name: string;
};

export type GetCustomerProductBatchTaskType = {
  __typename?: "TaskType";

  id: string;

  name: string;
};

export type GetCustomerProductBatchAssignedUser = {
  __typename?: "User";

  id: number;

  fullName: string;
};

export type GetCustomerProductBatchCustomerProducts = {
  __typename?: "CustomerProduct";

  id: number;

  startDate: Date;

  customerSku: Maybe<GetCustomerProductBatchCustomerSku>;

  status: GetCustomerProductBatch_Status;

  product: GetCustomerProductBatchProduct;

  tasks: GetCustomerProductBatch_Tasks[];
};

export type GetCustomerProductBatchCustomerSku = {
  __typename?: "CustomerSku";

  id: number;

  title: Maybe<string>;

  sku: string;
};

export type GetCustomerProductBatch_Status = {
  __typename?: "CustomerProductStatus";

  id: string;

  name: string;
};

export type GetCustomerProductBatchProduct = {
  __typename?: "Product";

  id: string;

  name: string;
};

export type GetCustomerProductBatch_Tasks = {
  __typename?: "Task";

  id: number;

  title: string;

  status: GetCustomerProductBatch__Status;

  taskType: GetCustomerProductBatch_TaskType;

  assignedUser: Maybe<GetCustomerProductBatch_AssignedUser>;
};

export type GetCustomerProductBatch__Status = {
  __typename?: "TaskStatus";

  id: string;

  name: string;
};

export type GetCustomerProductBatch_TaskType = {
  __typename?: "TaskType";

  id: string;

  name: string;
};

export type GetCustomerProductBatch_AssignedUser = {
  __typename?: "User";

  id: number;

  fullName: string;
};

export type UpdateTaskInBatchVariables = {
  input: UpdateTaskInput;
};

export type UpdateTaskInBatchMutation = {
  __typename?: "Mutation";

  updateTask: UpdateTaskInBatchUpdateTask;
};

export type UpdateTaskInBatchUpdateTask = {
  __typename?: "UpdateTaskResponse";

  task: UpdateTaskInBatchTask;
};

export type UpdateTaskInBatchTask = {
  __typename?: "Task";

  id: number;

  title: string;

  status: UpdateTaskInBatchStatus;

  taskType: UpdateTaskInBatchTaskType;

  assignedUser: Maybe<UpdateTaskInBatchAssignedUser>;
};

export type UpdateTaskInBatchStatus = {
  __typename?: "TaskStatus";

  id: string;

  name: string;
};

export type UpdateTaskInBatchTaskType = {
  __typename?: "TaskType";

  id: string;

  name: string;
};

export type UpdateTaskInBatchAssignedUser = {
  __typename?: "User";

  id: number;

  fullName: string;
};

export type SelectCustomerProjectsVariables = {
  upcomingStartDate?: Maybe<Date>;
  upcomingEndDate?: Maybe<Date>;
};

export type SelectCustomerProjectsQuery = {
  __typename?: "Query";

  viewer: Maybe<SelectCustomerProjectsViewer>;

  currentProjects: SelectCustomerProjectsCurrentProjects;

  upcomingProjects: SelectCustomerProjectsUpcomingProjects;
};

export type SelectCustomerProjectsViewer = {
  __typename?: "User";

  id: number;

  roles: string[];
};

export type SelectCustomerProjectsCurrentProjects = {
  __typename?: "CustomerProjectConnection";

  items: SelectCustomerProjectsItems[];
};

export type SelectCustomerProjectsItems = {
  __typename?: "CustomerProject";

  id: number;

  name: Maybe<string>;

  startDate: Date;

  statusId: string;

  amount: number;

  customer: SelectCustomerProjectsCustomer;

  customerContract: Maybe<SelectCustomerProjectsCustomerContract>;
};

export type SelectCustomerProjectsCustomer = {
  __typename?: "Customer";

  id: number;

  name: string;
};

export type SelectCustomerProjectsCustomerContract = {
  __typename?: "CustomerContract";

  id: number;
};

export type SelectCustomerProjectsUpcomingProjects = {
  __typename?: "CustomerProjectConnection";

  items: SelectCustomerProjects_Items[];
};

export type SelectCustomerProjects_Items = {
  __typename?: "CustomerProject";

  id: number;

  name: Maybe<string>;

  startDate: Date;

  statusId: string;

  amount: number;

  customer: SelectCustomerProjects_Customer;

  customerContract: Maybe<SelectCustomerProjects_CustomerContract>;
};

export type SelectCustomerProjects_Customer = {
  __typename?: "Customer";

  id: number;

  name: string;
};

export type SelectCustomerProjects_CustomerContract = {
  __typename?: "CustomerContract";

  id: number;
};

export type UpdateCustomerProjectMutationVariables = {
  input: UpdateCustomerProjectInput;
};

export type UpdateCustomerProjectMutationMutation = {
  __typename?: "Mutation";

  updateCustomerProject: UpdateCustomerProjectMutationUpdateCustomerProject;
};

export type UpdateCustomerProjectMutationUpdateCustomerProject = {
  __typename?: "UpdateCustomerProjectResponse";

  customerProject: UpdateCustomerProjectMutationCustomerProject;
};

export type UpdateCustomerProjectMutationCustomerProject = {
  __typename?: "CustomerProject";

  id: number;
};

export type GetCustomerForCustomerSelectVariables = {
  customerId: number;
};

export type GetCustomerForCustomerSelectQuery = {
  __typename?: "Query";

  customer: Maybe<GetCustomerForCustomerSelectCustomer>;
};

export type GetCustomerForCustomerSelectCustomer = {
  __typename?: "Customer";

  id: number;

  name: string;
};

export type GetCustomersForCustomerSelectVariables = {
  search?: Maybe<string>;
};

export type GetCustomersForCustomerSelectQuery = {
  __typename?: "Query";

  customers: GetCustomersForCustomerSelectCustomers;
};

export type GetCustomersForCustomerSelectCustomers = {
  __typename?: "CustomerConnection";

  items: GetCustomersForCustomerSelectItems[];
};

export type GetCustomersForCustomerSelectItems = {
  __typename?: "Customer";

  id: number;

  name: string;
};

export type GetCustomerSkuForCustomerSkuSelectVariables = {
  customerSkuId: number;
};

export type GetCustomerSkuForCustomerSkuSelectQuery = {
  __typename?: "Query";

  customerSku: Maybe<GetCustomerSkuForCustomerSkuSelectCustomerSku>;
};

export type GetCustomerSkuForCustomerSkuSelectCustomerSku = {
  __typename?: "CustomerSku";

  id: number;

  sku: string;

  title: Maybe<string>;
};

export type GetCustomerSkusForCustomerSkuSelectVariables = {
  customerId: number;
  search?: Maybe<string>;
};

export type GetCustomerSkusForCustomerSkuSelectQuery = {
  __typename?: "Query";

  customer: Maybe<GetCustomerSkusForCustomerSkuSelectCustomer>;
};

export type GetCustomerSkusForCustomerSkuSelectCustomer = {
  __typename?: "Customer";

  id: number;

  customerSkus: GetCustomerSkusForCustomerSkuSelectCustomerSkus;
};

export type GetCustomerSkusForCustomerSkuSelectCustomerSkus = {
  __typename?: "CustomerSkuConnection";

  items: GetCustomerSkusForCustomerSkuSelectItems[];
};

export type GetCustomerSkusForCustomerSkuSelectItems = {
  __typename?: "CustomerSku";

  id: number;

  sku: string;

  title: Maybe<string>;

  parent: Maybe<GetCustomerSkusForCustomerSkuSelectParent>;
};

export type GetCustomerSkusForCustomerSkuSelectParent = {
  __typename?: "CustomerSku";

  id: number;

  sku: string;

  title: Maybe<string>;
};

export type CreateNoteVariables = {
  input: CreateNoteInput;
};

export type CreateNoteMutation = {
  __typename?: "Mutation";

  createNote: CreateNoteCreateNote;
};

export type CreateNoteCreateNote = {
  __typename?: "CreateNoteResponse";

  note: Maybe<CreateNoteNote>;
};

export type CreateNoteNote = {
  __typename?: "Note";

  id: number;
};

export type GetMarkdownEditorViewerVariables = {};

export type GetMarkdownEditorViewerQuery = {
  __typename?: "Query";

  viewer: Maybe<GetMarkdownEditorViewerViewer>;
};

export type GetMarkdownEditorViewerViewer = {
  __typename?: "User";

  id: number;

  fullName: string;
};

export type DeleteNoteVariables = {
  input: DeleteNoteInput;
};

export type DeleteNoteMutation = {
  __typename?: "Mutation";

  deleteNote: DeleteNoteDeleteNote;
};

export type DeleteNoteDeleteNote = {
  __typename?: "DeleteNoteResponse";

  wasSuccessful: boolean;
};

export type CreateNoteReminderVariables = {
  input: CreateNoteReminderInput;
};

export type CreateNoteReminderMutation = {
  __typename?: "Mutation";

  createNoteReminder: CreateNoteReminderCreateNoteReminder;
};

export type CreateNoteReminderCreateNoteReminder = {
  __typename?: "CreateNoteReminderResponse";

  noteReminder: Maybe<CreateNoteReminderNoteReminder>;
};

export type CreateNoteReminderNoteReminder = {
  __typename?: "NoteReminder";

  noteId: number;

  content: string;
};

export type CreateStrategyTaskVariables = {
  input: CreateStrategyTaskInput;
};

export type CreateStrategyTaskMutation = {
  __typename?: "Mutation";

  createStrategyTask: CreateStrategyTaskCreateStrategyTask;
};

export type CreateStrategyTaskCreateStrategyTask = {
  __typename?: "CreateStrategyTaskResponse";

  task: Maybe<CreateStrategyTaskTask>;
};

export type CreateStrategyTaskTask = {
  __typename?: "Task";

  id: number;

  title: string;

  createdAt: DateTime;
};

export type DeleteNoteReminderVariables = {
  input: DeleteNoteReminderInput;
};

export type DeleteNoteReminderMutation = {
  __typename?: "Mutation";

  deleteNoteReminder: DeleteNoteReminderDeleteNoteReminder;
};

export type DeleteNoteReminderDeleteNoteReminder = {
  __typename?: "DeleteNoteReminderResponse";

  wasSuccessful: boolean;
};

export type UpdateNoteReminderVariables = {
  input: UpdateNoteReminderInput;
};

export type UpdateNoteReminderMutation = {
  __typename?: "Mutation";

  updateNoteReminder: UpdateNoteReminderUpdateNoteReminder;
};

export type UpdateNoteReminderUpdateNoteReminder = {
  __typename?: "UpdateNoteReminderResponse";

  noteReminder: Maybe<UpdateNoteReminderNoteReminder>;
};

export type UpdateNoteReminderNoteReminder = {
  __typename?: "NoteReminder";

  id: number;
};

export type CreateCustomerStarVariables = {
  input: CreateCustomerStarInput;
};

export type CreateCustomerStarMutation = {
  __typename?: "Mutation";

  createCustomerStar: CreateCustomerStarCreateCustomerStar;
};

export type CreateCustomerStarCreateCustomerStar = {
  __typename?: "CreateCustomerStarResponse";

  customerStar: Maybe<CreateCustomerStarCustomerStar>;
};

export type CreateCustomerStarCustomerStar = {
  __typename?: "CustomerStar";

  userId: number;

  createdAt: DateTime;
};

export type DeleteCustomerStarVariables = {
  input: DeleteCustomerStarInput;
};

export type DeleteCustomerStarMutation = {
  __typename?: "Mutation";

  deleteCustomerStar: DeleteCustomerStarDeleteCustomerStar;
};

export type DeleteCustomerStarDeleteCustomerStar = {
  __typename?: "DeleteCustomerStarResponse";

  wasSuccessful: boolean;
};

export type GetUserForUserSelectVariables = {
  userId: number;
};

export type GetUserForUserSelectQuery = {
  __typename?: "Query";

  user: Maybe<GetUserForUserSelectUser>;
};

export type GetUserForUserSelectUser = {
  __typename?: "User";

  id: number;

  fullName: string;
};

export type GetUsersForUserSelectVariables = {
  search?: Maybe<string>;
  roles?: Maybe<string[]>;
};

export type GetUsersForUserSelectQuery = {
  __typename?: "Query";

  users: GetUsersForUserSelectUsers;
};

export type GetUsersForUserSelectUsers = {
  __typename?: "UserConnection";

  items: Maybe<GetUsersForUserSelectItems[]>;
};

export type GetUsersForUserSelectItems = {
  __typename?: "User";

  id: number;

  fullName: string;

  email: string;

  profileImageUrl: Maybe<string>;
};

export type CreateCustomerMwsCredentialRevisionVariables = {
  input: CreateCustomerMwsCredentialRevisionInput;
};

export type CreateCustomerMwsCredentialRevisionMutation = {
  __typename?: "Mutation";

  createCustomerMwsCredentialRevision: CreateCustomerMwsCredentialRevisionCreateCustomerMwsCredentialRevision;
};

export type CreateCustomerMwsCredentialRevisionCreateCustomerMwsCredentialRevision = {
  __typename?: "CreateCustomerMwsCredentialRevisionResponse";

  customerMwsCredential: Maybe<
    CreateCustomerMwsCredentialRevisionCustomerMwsCredential
  >;
};

export type CreateCustomerMwsCredentialRevisionCustomerMwsCredential = {
  __typename?: "CustomerMwsCredential";

  id: number;
};

export type CreateCustomerMwsCredentialVariables = {
  input: CreateCustomerMwsCredentialInput;
};

export type CreateCustomerMwsCredentialMutation = {
  __typename?: "Mutation";

  createCustomerMwsCredential: CreateCustomerMwsCredentialCreateCustomerMwsCredential;
};

export type CreateCustomerMwsCredentialCreateCustomerMwsCredential = {
  __typename?: "CreateCustomerMwsCredentialResponse";

  customerMwsCredential: Maybe<
    CreateCustomerMwsCredentialCustomerMwsCredential
  >;
};

export type CreateCustomerMwsCredentialCustomerMwsCredential = {
  __typename?: "CustomerMwsCredential";

  id: number;
};

export type DeleteCustomerMwsCredentialVariables = {
  input: DeleteCustomerMwsCredentialInput;
};

export type DeleteCustomerMwsCredentialMutation = {
  __typename?: "Mutation";

  deleteCustomerMwsCredential: DeleteCustomerMwsCredentialDeleteCustomerMwsCredential;
};

export type DeleteCustomerMwsCredentialDeleteCustomerMwsCredential = {
  __typename?: "DeleteCustomerMwsCredentialResponse";

  wasSuccessful: boolean;
};

export type SelectCustomerMwsCredentialsVariables = {
  customerId: number;
};

export type SelectCustomerMwsCredentialsQuery = {
  __typename?: "Query";

  customer: Maybe<SelectCustomerMwsCredentialsCustomer>;
};

export type SelectCustomerMwsCredentialsCustomer = {
  __typename?: "Customer";

  id: number;

  customerMwsCredentials: SelectCustomerMwsCredentialsCustomerMwsCredentials[];
};

export type SelectCustomerMwsCredentialsCustomerMwsCredentials = {
  __typename?: "CustomerMwsCredential";

  id: number;

  name: string;

  sellerId: string;

  authToken: string;

  isPrimary: boolean;

  invalidAt: Maybe<DateTime>;
};

export type CreateCustomerAmazonAccountRevisionVariables = {
  input: CreateCustomerAmazonAccountRevisionInput;
};

export type CreateCustomerAmazonAccountRevisionMutation = {
  __typename?: "Mutation";

  createCustomerAmazonAccountRevision: CreateCustomerAmazonAccountRevisionCreateCustomerAmazonAccountRevision;
};

export type CreateCustomerAmazonAccountRevisionCreateCustomerAmazonAccountRevision = {
  __typename?: "CreateCustomerAmazonAccountRevisionResponse";

  customerAmazonAccount: Maybe<
    CreateCustomerAmazonAccountRevisionCustomerAmazonAccount
  >;
};

export type CreateCustomerAmazonAccountRevisionCustomerAmazonAccount = {
  __typename?: "CustomerAmazonAccount";

  id: number;
};

export type CreateCustomerAmazonAccountVariables = {
  input: CreateCustomerAmazonAccountInput;
};

export type CreateCustomerAmazonAccountMutation = {
  __typename?: "Mutation";

  createCustomerAmazonAccount: CreateCustomerAmazonAccountCreateCustomerAmazonAccount;
};

export type CreateCustomerAmazonAccountCreateCustomerAmazonAccount = {
  __typename?: "CreateCustomerAmazonAccountResponse";

  customerAmazonAccount: Maybe<
    CreateCustomerAmazonAccountCustomerAmazonAccount
  >;
};

export type CreateCustomerAmazonAccountCustomerAmazonAccount = {
  __typename?: "CustomerAmazonAccount";

  id: number;
};

export type SelectCustomerAmazonAccountVariables = {
  customerId: number;
};

export type SelectCustomerAmazonAccountQuery = {
  __typename?: "Query";

  customer: Maybe<SelectCustomerAmazonAccountCustomer>;
};

export type SelectCustomerAmazonAccountCustomer = {
  __typename?: "Customer";

  id: number;

  customerAmazonAccount: Maybe<
    SelectCustomerAmazonAccountCustomerAmazonAccount
  >;
};

export type SelectCustomerAmazonAccountCustomerAmazonAccount = {
  __typename?: "CustomerAmazonAccount";

  id: number;

  email: string;

  password: string;

  totpCode: Maybe<string>;
};

export type SelectCustomerWorkspaceVariables = {
  customerId: number;
};

export type SelectCustomerWorkspaceQuery = {
  __typename?: "Query";

  customer: Maybe<SelectCustomerWorkspaceCustomer>;
};

export type SelectCustomerWorkspaceCustomer = {
  __typename?: "Customer";

  id: number;

  name: string;

  url: Maybe<string>;

  createdAt: DateTime;

  contactName: Maybe<string>;

  contactPhone: Maybe<string>;

  contactEmail: Maybe<string>;

  additionalNames: Maybe<string>;

  accountManagerUserId: Maybe<number>;

  location: Maybe<string>;

  leadSource: Maybe<string>;

  hasSellerAccount: boolean;

  hasVendorAccount: boolean;

  pipedriveDealId: Maybe<number>;

  happinessLevel: Maybe<string>;

  maxEndDate: Maybe<Date>;

  createdByUser: SelectCustomerWorkspaceCreatedByUser;

  accountManagerUser: Maybe<SelectCustomerWorkspaceAccountManagerUser>;
};

export type SelectCustomerWorkspaceCreatedByUser = {
  __typename?: "User";

  id: number;

  fullName: string;
};

export type SelectCustomerWorkspaceAccountManagerUser = {
  __typename?: "User";

  id: number;

  fullName: string;
};

export type UpdateCustomerVariables = {
  input: UpdateCustomerInput;
};

export type UpdateCustomerMutation = {
  __typename?: "Mutation";

  updateCustomer: UpdateCustomerUpdateCustomer;
};

export type UpdateCustomerUpdateCustomer = {
  __typename?: "UpdateCustomerResponse";

  customer: UpdateCustomerCustomer;
};

export type UpdateCustomerCustomer = {
  __typename?: "Customer";

  id: number;
};

export type CreateCustomerFileVariables = {
  input: CreateCustomerFileInput;
};

export type CreateCustomerFileMutation = {
  __typename?: "Mutation";

  createCustomerFile: CreateCustomerFileCreateCustomerFile;
};

export type CreateCustomerFileCreateCustomerFile = {
  __typename?: "CreateCustomerFileResponse";

  customerFile: Maybe<CreateCustomerFileCustomerFile>;

  uploadUrl: string;
};

export type CreateCustomerFileCustomerFile = {
  __typename?: "CustomerFile";

  id: number;
};

export type CustomerFilesVariables = {
  customerId: number;
};

export type CustomerFilesQuery = {
  __typename?: "Query";

  customer: Maybe<CustomerFilesCustomer>;
};

export type CustomerFilesCustomer = {
  __typename?: "Customer";

  id: number;

  customerFiles: Maybe<CustomerFilesCustomerFiles>;
};

export type CustomerFilesCustomerFiles = {
  __typename?: "CustomerFileConnection";

  items: CustomerFilesItems[];
};

export type CustomerFilesItems = {
  __typename?: "CustomerFile";

  id: number;

  file: CustomerFilesFile;
};

export type CustomerFilesFile = {
  __typename?: "File";

  id: number;

  name: string;

  url: string;

  createdAt: Date;

  sizeInBytes: number;

  mimeType: string;
};

export type GetCustomerVariables = {
  customerId: number;
};

export type GetCustomerQuery = {
  __typename?: "Query";

  customer: Maybe<GetCustomerCustomer>;
};

export type GetCustomerCustomer = {
  __typename?: "Customer";

  id: number;

  name: string;

  createdAt: DateTime;

  isStarred: boolean;
};

export type CreateStrategyTaskMutationVariables = {
  input: CreateStrategyTaskInput;
};

export type CreateStrategyTaskMutationMutation = {
  __typename?: "Mutation";

  createStrategyTask: CreateStrategyTaskMutationCreateStrategyTask;
};

export type CreateStrategyTaskMutationCreateStrategyTask = {
  __typename?: "CreateStrategyTaskResponse";

  task: Maybe<CreateStrategyTaskMutationTask>;
};

export type CreateStrategyTaskMutationTask = {
  __typename?: "Task";

  id: number;

  title: string;

  createdAt: DateTime;
};

export type CustomerNotesVariables = {
  customerId: number;
  search?: Maybe<string>;
  limit?: Maybe<number>;
  offset?: Maybe<number>;
};

export type CustomerNotesQuery = {
  __typename?: "Query";

  customer: Maybe<CustomerNotesCustomer>;
};

export type CustomerNotesCustomer = {
  __typename?: "Customer";

  id: number;

  notes: Maybe<CustomerNotesNotes>;
};

export type CustomerNotesNotes = {
  __typename?: "NoteConnection";

  pageInfo: CustomerNotesPageInfo;

  totalCount: number;

  items: CustomerNotesItems[];
};

export type CustomerNotesPageInfo = {
  __typename?: "PageInfo";

  hasPreviousPage: boolean;

  hasNextPage: boolean;
};

export type CustomerNotesItems = {
  __typename?: "Note";

  id: number;

  userId: number;

  content: string;

  createdAt: DateTime;

  originalNoteId: Maybe<number>;

  isLatest: boolean;

  user: CustomerNotesUser;

  noteReminders: CustomerNotesNoteReminders[];

  isDeletable: boolean;
};

export type CustomerNotesUser = {
  __typename?: "User";

  id: number;

  fullName: string;
};

export type CustomerNotesNoteReminders = {
  __typename?: "NoteReminder";

  id: number;

  noteId: number;

  content: string;

  dueAt: Maybe<DateTime>;

  completedAt: Maybe<DateTime>;
};

export type StrategyTasksVariables = {
  customerId: number;
};

export type StrategyTasksQuery = {
  __typename?: "Query";

  viewer: Maybe<StrategyTasksViewer>;

  customer: Maybe<StrategyTasksCustomer>;
};

export type StrategyTasksViewer = {
  __typename?: "User";

  id: number;

  fullName: string;
};

export type StrategyTasksCustomer = {
  __typename?: "Customer";

  id: number;

  customerProducts: Maybe<StrategyTasksCustomerProducts[]>;

  activeStrategyCustomerProduct: Maybe<
    StrategyTasksActiveStrategyCustomerProduct
  >;
};

export type StrategyTasksCustomerProducts = {
  __typename?: "CustomerProduct";

  id: number;

  startDate: Date;
};

export type StrategyTasksActiveStrategyCustomerProduct = {
  __typename?: "CustomerProduct";

  id: number;

  statusId: string;

  tasks: StrategyTasksTasks[];
};

export type StrategyTasksTasks = {
  __typename?: "Task";

  id: number;

  title: string;

  statusId: string;

  dueDate: Maybe<DateTime>;

  isDeletable: boolean;

  assignedUser: Maybe<StrategyTasksAssignedUser>;

  taskType: StrategyTasksTaskType;
};

export type StrategyTasksAssignedUser = {
  __typename?: "User";

  id: number;

  fullName: string;

  profileImageUrl: Maybe<string>;
};

export type StrategyTasksTaskType = {
  __typename?: "TaskType";

  id: string;

  name: string;
};

export type UpdateStrategyTaskVariables = {
  input: UpdateTaskInput;
};

export type UpdateStrategyTaskMutation = {
  __typename?: "Mutation";

  updateTask: UpdateStrategyTaskUpdateTask;
};

export type UpdateStrategyTaskUpdateTask = {
  __typename?: "UpdateTaskResponse";

  task: UpdateStrategyTaskTask;
};

export type UpdateStrategyTaskTask = {
  __typename?: "Task";

  id: number;

  title: string;

  statusId: string;
};

export type AddPhotographyVariables = {
  customerId: number;
};

export type AddPhotographyQuery = {
  __typename?: "Query";

  customer: Maybe<AddPhotographyCustomer>;

  productCategories: Maybe<AddPhotographyProductCategories>;
};

export type AddPhotographyCustomer = {
  __typename?: "Customer";

  id: number;

  customerSkus: AddPhotographyCustomerSkus;
};

export type AddPhotographyCustomerSkus = {
  __typename?: "CustomerSkuConnection";

  items: AddPhotographyItems[];
};

export type AddPhotographyItems = {
  __typename?: "CustomerSku";

  id: number;

  sku: string;

  title: Maybe<string>;
};

export type AddPhotographyProductCategories = {
  __typename?: "ProductCategoryConnection";

  items: Maybe<AddPhotography_Items[]>;
};

export type AddPhotography_Items = {
  __typename?: "ProductCategory";

  id: string;

  name: string;

  products: Maybe<AddPhotographyProducts[]>;
};

export type AddPhotographyProducts = {
  __typename?: "Product";

  id: string;

  name: string;

  currentProductPrice: Maybe<AddPhotographyCurrentProductPrice>;
};

export type AddPhotographyCurrentProductPrice = {
  __typename?: "ProductPrice";

  id: number;

  amount: number;

  clientDiscountPercentage: number;

  maxAdditionalDiscountPercentage: number;
};

export type AddPhotographyChildCustomerSkusVariables = {
  customerSkuId: number;
};

export type AddPhotographyChildCustomerSkusQuery = {
  __typename?: "Query";

  customerSku: Maybe<AddPhotographyChildCustomerSkusCustomerSku>;
};

export type AddPhotographyChildCustomerSkusCustomerSku = {
  __typename?: "CustomerSku";

  id: number;

  sku: string;

  title: Maybe<string>;

  children: Maybe<AddPhotographyChildCustomerSkusChildren[]>;
};

export type AddPhotographyChildCustomerSkusChildren = {
  __typename?: "CustomerSku";

  id: number;

  sku: string;

  title: Maybe<string>;
};

export type AddPhotographyCreateCustomerProductBatchVariables = {
  input: CreateCustomerProductBatchInput;
};

export type AddPhotographyCreateCustomerProductBatchMutation = {
  __typename?: "Mutation";

  createCustomerProductBatch: AddPhotographyCreateCustomerProductBatchCreateCustomerProductBatch;
};

export type AddPhotographyCreateCustomerProductBatchCreateCustomerProductBatch = {
  __typename?: "CreateCustomerProductBatchResponse";

  customerProductBatch: Maybe<
    AddPhotographyCreateCustomerProductBatchCustomerProductBatch
  >;
};

export type AddPhotographyCreateCustomerProductBatchCustomerProductBatch = {
  __typename?: "CustomerProductBatch";

  id: number;
};

export type AddPhotographyDialogCustomerProductItemsVariables = {
  customerId: number;
  customerSkuIds?: Maybe<number[]>;
  productIds?: Maybe<string[]>;
};

export type AddPhotographyDialogCustomerProductItemsQuery = {
  __typename?: "Query";

  customer: Maybe<AddPhotographyDialogCustomerProductItemsCustomer>;

  products: Maybe<AddPhotographyDialogCustomerProductItemsProducts>;
};

export type AddPhotographyDialogCustomerProductItemsCustomer = {
  __typename?: "Customer";

  id: number;

  customerSkus: AddPhotographyDialogCustomerProductItemsCustomerSkus;
};

export type AddPhotographyDialogCustomerProductItemsCustomerSkus = {
  __typename?: "CustomerSkuConnection";

  items: AddPhotographyDialogCustomerProductItemsItems[];
};

export type AddPhotographyDialogCustomerProductItemsItems = {
  __typename?: "CustomerSku";

  id: number;

  sku: string;

  parent: Maybe<AddPhotographyDialogCustomerProductItemsParent>;
};

export type AddPhotographyDialogCustomerProductItemsParent = {
  __typename?: "CustomerSku";

  id: number;

  sku: string;
};

export type AddPhotographyDialogCustomerProductItemsProducts = {
  __typename?: "ProductConnection";

  items: Maybe<AddPhotographyDialogCustomerProductItems_Items[]>;
};

export type AddPhotographyDialogCustomerProductItems_Items = {
  __typename?: "Product";

  id: string;

  name: string;

  currentProductPrice: Maybe<
    AddPhotographyDialogCustomerProductItemsCurrentProductPrice
  >;
};

export type AddPhotographyDialogCustomerProductItemsCurrentProductPrice = {
  __typename?: "ProductPrice";

  id: number;

  amount: number;

  clientDiscountPercentage: number;

  maxAdditionalDiscountPercentage: number;
};

export type AddProductVariables = {
  customerId: number;
  productId: string;
};

export type AddProductQuery = {
  __typename?: "Query";

  customer: Maybe<AddProductCustomer>;

  product: Maybe<AddProductProduct>;
};

export type AddProductCustomer = {
  __typename?: "Customer";

  id: number;

  customerSkus: AddProductCustomerSkus;
};

export type AddProductCustomerSkus = {
  __typename?: "CustomerSkuConnection";

  items: AddProductItems[];
};

export type AddProductItems = {
  __typename?: "CustomerSku";

  id: number;

  sku: string;

  title: Maybe<string>;
};

export type AddProductProduct = {
  __typename?: "Product";

  id: string;

  name: string;

  requiresSku: boolean;

  currentProductPrice: Maybe<AddProductCurrentProductPrice>;
};

export type AddProductCurrentProductPrice = {
  __typename?: "ProductPrice";

  id: number;

  amount: number;

  clientDiscountPercentage: number;

  maxAdditionalDiscountPercentage: number;
};

export type AddContentCreateCustomerProductVariables = {
  input: CreateCustomerProductInput;
};

export type AddContentCreateCustomerProductMutation = {
  __typename?: "Mutation";

  createCustomerProduct: AddContentCreateCustomerProductCreateCustomerProduct;
};

export type AddContentCreateCustomerProductCreateCustomerProduct = {
  __typename?: "CreateCustomerProductResponse";

  customerProduct: Maybe<AddContentCreateCustomerProductCustomerProduct>;
};

export type AddContentCreateCustomerProductCustomerProduct = {
  __typename?: "CustomerProduct";

  id: number;
};

export type SkuSelectorVariables = {
  customerId: number;
  selectedCustomerSkuId: number;
  hasNotSelectedCustomerSku: boolean;
};

export type SkuSelectorQuery = {
  __typename?: "Query";

  customer: Maybe<SkuSelectorCustomer>;

  customerSku: Maybe<SkuSelectorCustomerSku>;
};

export type SkuSelectorCustomer = {
  __typename?: "Customer";

  id: number;

  customerSkus: SkuSelectorCustomerSkus;
};

export type SkuSelectorCustomerSkus = {
  __typename?: "CustomerSkuConnection";

  items: SkuSelectorItems[];
};

export type SkuSelectorItems = {
  __typename?: "CustomerSku";

  id: number;

  sku: string;

  asin: Maybe<string>;

  title: Maybe<string>;
};

export type SkuSelectorCustomerSku = {
  __typename?: "CustomerSku";

  id: number;

  sku: string;

  title: Maybe<string>;

  children: Maybe<SkuSelectorChildren[]>;
};

export type SkuSelectorChildren = {
  __typename?: "CustomerSku";

  id: number;

  sku: string;

  title: Maybe<string>;
};

export type CustomerContractVariables = {
  customerContractId: number;
};

export type CustomerContractQuery = {
  __typename?: "Query";

  customerContract: Maybe<CustomerContractCustomerContract>;
};

export type CustomerContractCustomerContract = {
  __typename?: "CustomerContract";

  id: number;

  salesUser: CustomerContractSalesUser;

  startDate: Date;

  createdAt: DateTime;

  statusId: string;

  customerProjects: CustomerContractCustomerProjects[];

  isDeletable: boolean;
};

export type CustomerContractSalesUser = {
  __typename?: "User";

  id: number;

  fullName: string;
};

export type CustomerContractCustomerProjects = {
  __typename?: "CustomerProject";

  id: number;

  name: Maybe<string>;

  startDate: Date;

  amount: number;
};

export type DeleteCustomerContractVariables = {
  input: DeleteCustomerContractInput;
};

export type DeleteCustomerContractMutation = {
  __typename?: "Mutation";

  deleteCustomerContract: DeleteCustomerContractDeleteCustomerContract;
};

export type DeleteCustomerContractDeleteCustomerContract = {
  __typename?: "DeleteCustomerContractResposne";

  wasSuccessful: boolean;
};

export type RoadmapCustomerProductBatchNotesVariables = {
  customerProductBatchId: number;
  search?: Maybe<string>;
  limit?: Maybe<number>;
  offset?: Maybe<number>;
};

export type RoadmapCustomerProductBatchNotesQuery = {
  __typename?: "Query";

  customerProductBatch: Maybe<
    RoadmapCustomerProductBatchNotesCustomerProductBatch
  >;
};

export type RoadmapCustomerProductBatchNotesCustomerProductBatch = {
  __typename?: "CustomerProductBatch";

  id: number;

  notes: Maybe<RoadmapCustomerProductBatchNotesNotes>;
};

export type RoadmapCustomerProductBatchNotesNotes = {
  __typename?: "NoteConnection";

  pageInfo: RoadmapCustomerProductBatchNotesPageInfo;

  totalCount: number;

  items: RoadmapCustomerProductBatchNotesItems[];
};

export type RoadmapCustomerProductBatchNotesPageInfo = {
  __typename?: "PageInfo";

  hasPreviousPage: boolean;

  hasNextPage: boolean;
};

export type RoadmapCustomerProductBatchNotesItems = {
  __typename?: "Note";

  id: number;

  userId: number;

  content: string;

  createdAt: DateTime;

  originalNoteId: Maybe<number>;

  isLatest: boolean;

  user: RoadmapCustomerProductBatchNotesUser;

  noteReminders: RoadmapCustomerProductBatchNotesNoteReminders[];

  isDeletable: boolean;
};

export type RoadmapCustomerProductBatchNotesUser = {
  __typename?: "User";

  id: number;

  fullName: string;
};

export type RoadmapCustomerProductBatchNotesNoteReminders = {
  __typename?: "NoteReminder";

  id: number;

  noteId: number;

  content: string;

  dueAt: Maybe<DateTime>;

  completedAt: Maybe<DateTime>;
};

export type UpdateCustomerProductVariables = {
  input: UpdateCustomerProductInput;
};

export type UpdateCustomerProductMutation = {
  __typename?: "Mutation";

  updateCustomerProduct: UpdateCustomerProductUpdateCustomerProduct;
};

export type UpdateCustomerProductUpdateCustomerProduct = {
  __typename?: "UpdateCustomerProductResponse";

  customerProduct: UpdateCustomerProductCustomerProduct;
};

export type UpdateCustomerProductCustomerProduct = {
  __typename?: "CustomerProduct";

  id: number;
};

export type RoadmapCustomerProductNotesVariables = {
  customerProductId: number;
  search?: Maybe<string>;
  limit?: Maybe<number>;
  offset?: Maybe<number>;
};

export type RoadmapCustomerProductNotesQuery = {
  __typename?: "Query";

  customerProduct: Maybe<RoadmapCustomerProductNotesCustomerProduct>;
};

export type RoadmapCustomerProductNotesCustomerProduct = {
  __typename?: "CustomerProduct";

  id: number;

  notes: Maybe<RoadmapCustomerProductNotesNotes>;
};

export type RoadmapCustomerProductNotesNotes = {
  __typename?: "NoteConnection";

  pageInfo: RoadmapCustomerProductNotesPageInfo;

  totalCount: number;

  items: RoadmapCustomerProductNotesItems[];
};

export type RoadmapCustomerProductNotesPageInfo = {
  __typename?: "PageInfo";

  hasPreviousPage: boolean;

  hasNextPage: boolean;
};

export type RoadmapCustomerProductNotesItems = {
  __typename?: "Note";

  id: number;

  userId: number;

  content: string;

  createdAt: DateTime;

  originalNoteId: Maybe<number>;

  isLatest: boolean;

  user: RoadmapCustomerProductNotesUser;

  noteReminders: RoadmapCustomerProductNotesNoteReminders[];

  isDeletable: boolean;
};

export type RoadmapCustomerProductNotesUser = {
  __typename?: "User";

  id: number;

  fullName: string;
};

export type RoadmapCustomerProductNotesNoteReminders = {
  __typename?: "NoteReminder";

  id: number;

  noteId: number;

  content: string;

  dueAt: Maybe<DateTime>;

  completedAt: Maybe<DateTime>;
};

export type DeleteCustomerProductBatchVariables = {
  input: DeleteCustomerProductBatchInput;
};

export type DeleteCustomerProductBatchMutation = {
  __typename?: "Mutation";

  deleteCustomerProductBatch: DeleteCustomerProductBatchDeleteCustomerProductBatch;
};

export type DeleteCustomerProductBatchDeleteCustomerProductBatch = {
  __typename?: "DeleteCustomerProductBatchResposne";

  wasSuccessful: boolean;
};

export type DeleteCustomerProductVariables = {
  input: DeleteCustomerProductInput;
};

export type DeleteCustomerProductMutation = {
  __typename?: "Mutation";

  deleteCustomerProduct: DeleteCustomerProductDeleteCustomerProduct;
};

export type DeleteCustomerProductDeleteCustomerProduct = {
  __typename?: "DeleteCustomerProductResponse";

  wasSuccessful: boolean;
};

export type DeleteCustomerProjectVariables = {
  input: DeleteCustomerProjectInput;
};

export type DeleteCustomerProjectMutation = {
  __typename?: "Mutation";

  deleteCustomerProject: DeleteCustomerProjectDeleteCustomerProject;
};

export type DeleteCustomerProjectDeleteCustomerProject = {
  __typename?: "DeleteCustomerProjectResposne";

  wasSuccessful: boolean;
};

export type UpdateCustomerProjectVariables = {
  input: UpdateCustomerProjectInput;
};

export type UpdateCustomerProjectMutation = {
  __typename?: "Mutation";

  updateCustomerProject: UpdateCustomerProjectUpdateCustomerProject;
};

export type UpdateCustomerProjectUpdateCustomerProject = {
  __typename?: "UpdateCustomerProjectResponse";

  customerProject: UpdateCustomerProjectCustomerProject;
};

export type UpdateCustomerProjectCustomerProject = {
  __typename?: "CustomerProject";

  id: number;
};

export type RoadmapCustomerProjectNotesVariables = {
  customerProjectId: number;
  search?: Maybe<string>;
  limit?: Maybe<number>;
  offset?: Maybe<number>;
};

export type RoadmapCustomerProjectNotesQuery = {
  __typename?: "Query";

  customerProject: Maybe<RoadmapCustomerProjectNotesCustomerProject>;
};

export type RoadmapCustomerProjectNotesCustomerProject = {
  __typename?: "CustomerProject";

  id: number;

  notes: Maybe<RoadmapCustomerProjectNotesNotes>;
};

export type RoadmapCustomerProjectNotesNotes = {
  __typename?: "NoteConnection";

  pageInfo: RoadmapCustomerProjectNotesPageInfo;

  totalCount: number;

  items: RoadmapCustomerProjectNotesItems[];
};

export type RoadmapCustomerProjectNotesPageInfo = {
  __typename?: "PageInfo";

  hasPreviousPage: boolean;

  hasNextPage: boolean;
};

export type RoadmapCustomerProjectNotesItems = {
  __typename?: "Note";

  id: number;

  userId: number;

  content: string;

  createdAt: DateTime;

  originalNoteId: Maybe<number>;

  isLatest: boolean;

  user: RoadmapCustomerProjectNotesUser;

  noteReminders: RoadmapCustomerProjectNotesNoteReminders[];

  isDeletable: boolean;
};

export type RoadmapCustomerProjectNotesUser = {
  __typename?: "User";

  id: number;

  fullName: string;
};

export type RoadmapCustomerProjectNotesNoteReminders = {
  __typename?: "NoteReminder";

  id: number;

  noteId: number;

  content: string;

  dueAt: Maybe<DateTime>;

  completedAt: Maybe<DateTime>;
};

export type SelectRoadmapVariables = {
  customerId: number;
};

export type SelectRoadmapQuery = {
  __typename?: "Query";

  customer: Maybe<SelectRoadmapCustomer>;
};

export type SelectRoadmapCustomer = {
  __typename?: "Customer";

  id: number;

  name: string;

  customerProjects: Maybe<SelectRoadmapCustomerProjects[]>;
};

export type SelectRoadmapCustomerProjects = {
  __typename?: "CustomerProject";

  id: number;

  name: Maybe<string>;

  startDate: Date;

  createdAt: Maybe<DateTime>;

  amount: number;

  customerId: number;

  customerContractId: Maybe<number>;

  isDeletable: boolean;

  status: SelectRoadmapStatus;

  notes: Maybe<SelectRoadmapNotes>;

  customerProductBatches: SelectRoadmapCustomerProductBatches[];

  customerProducts: SelectRoadmap_CustomerProducts[];
};

export type SelectRoadmapStatus = {
  __typename?: "CustomerProjectStatus";

  id: string;

  name: string;
};

export type SelectRoadmapNotes = {
  __typename?: "NoteConnection";

  totalCount: number;
};

export type SelectRoadmapCustomerProductBatches = {
  __typename?: "CustomerProductBatch";

  id: number;

  startDate: Date;

  isDeletable: boolean;

  customerProducts: SelectRoadmapCustomerProducts[];

  notes: Maybe<SelectRoadmap_Notes>;
};

export type SelectRoadmapCustomerProducts = {
  __typename?: "CustomerProduct";

  id: number;

  startDate: Date;

  amount: number;

  workflowStatus: Maybe<string>;

  dueDate: Maybe<DateTime>;

  isDeletable: boolean;

  status: SelectRoadmap_Status;

  product: SelectRoadmapProduct;

  customerSku: Maybe<SelectRoadmapCustomerSku>;
};

export type SelectRoadmap_Status = {
  __typename?: "CustomerProductStatus";

  id: string;

  name: string;
};

export type SelectRoadmapProduct = {
  __typename?: "Product";

  id: string;

  name: string;
};

export type SelectRoadmapCustomerSku = {
  __typename?: "CustomerSku";

  id: number;

  sku: string;

  asin: Maybe<string>;

  title: Maybe<string>;
};

export type SelectRoadmap_Notes = {
  __typename?: "NoteConnection";

  totalCount: number;
};

export type SelectRoadmap_CustomerProducts = {
  __typename?: "CustomerProduct";

  id: number;

  startDate: Date;

  amount: number;

  workflowStatus: Maybe<string>;

  dueDate: Maybe<DateTime>;

  isDeletable: boolean;

  customerProject: SelectRoadmapCustomerProject;

  customerProductBatchId: Maybe<number>;

  status: SelectRoadmap__Status;

  product: SelectRoadmap_Product;

  customerSku: Maybe<SelectRoadmap_CustomerSku>;

  notes: Maybe<SelectRoadmap__Notes>;
};

export type SelectRoadmapCustomerProject = {
  __typename?: "CustomerProject";

  id: number;

  startDate: Date;
};

export type SelectRoadmap__Status = {
  __typename?: "CustomerProductStatus";

  id: string;

  name: string;
};

export type SelectRoadmap_Product = {
  __typename?: "Product";

  id: string;

  name: string;
};

export type SelectRoadmap_CustomerSku = {
  __typename?: "CustomerSku";

  id: number;

  sku: string;

  asin: Maybe<string>;

  title: Maybe<string>;
};

export type SelectRoadmap__Notes = {
  __typename?: "NoteConnection";

  totalCount: number;
};

export type CreateCustomerSkuVariables = {
  input: CreateCustomerSkuInput;
};

export type CreateCustomerSkuMutation = {
  __typename?: "Mutation";

  createCustomerSku: CreateCustomerSkuCreateCustomerSku;
};

export type CreateCustomerSkuCreateCustomerSku = {
  __typename?: "CreateCustomerSkuResponse";

  customerSku: Maybe<CreateCustomerSkuCustomerSku>;
};

export type CreateCustomerSkuCustomerSku = {
  __typename?: "CustomerSku";

  sku: string;
};

export type CreateCustomerSkuSyncRequestVariables = {
  input: CreateCustomerSkuSyncRequestInput;
};

export type CreateCustomerSkuSyncRequestMutation = {
  __typename?: "Mutation";

  createCustomerSkuSyncRequest: CreateCustomerSkuSyncRequestCreateCustomerSkuSyncRequest;
};

export type CreateCustomerSkuSyncRequestCreateCustomerSkuSyncRequest = {
  __typename?: "CreateCustomerSkuSyncRequestResponse";

  customerSkuSyncRequest: Maybe<
    CreateCustomerSkuSyncRequestCustomerSkuSyncRequest
  >;
};

export type CreateCustomerSkuSyncRequestCustomerSkuSyncRequest = {
  __typename?: "CustomerSkuSyncRequest";

  id: number;
};

export type SelectCustomerSkusVariables = {
  customerId: number;
  search?: Maybe<string>;
  limit: number;
  offset: number;
  skuTypes?: Maybe<string[]>;
  syncStatuses?: Maybe<string[]>;
};

export type SelectCustomerSkusQuery = {
  __typename?: "Query";

  customer: Maybe<SelectCustomerSkusCustomer>;
};

export type SelectCustomerSkusCustomer = {
  __typename?: "Customer";

  id: number;

  customerSkuSyncRequests: SelectCustomerSkusCustomerSkuSyncRequests[];

  customerSkus: SelectCustomerSkusCustomerSkus;
};

export type SelectCustomerSkusCustomerSkuSyncRequests = {
  __typename?: "CustomerSkuSyncRequest";

  id: number;

  createdAt: DateTime;

  isInProgress: boolean;

  status: string;

  syncErrorMessage: Maybe<string>;
};

export type SelectCustomerSkusCustomerSkus = {
  __typename?: "CustomerSkuConnection";

  pageInfo: SelectCustomerSkusPageInfo;

  totalCount: number;

  items: SelectCustomerSkusItems[];
};

export type SelectCustomerSkusPageInfo = {
  __typename?: "PageInfo";

  hasPreviousPage: boolean;

  hasNextPage: boolean;
};

export type SelectCustomerSkusItems = {
  __typename?: "CustomerSku";

  id: number;

  sku: string;

  title: Maybe<string>;

  asin: Maybe<string>;

  upc: Maybe<string>;

  skuType: Maybe<string>;

  syncStatus: Maybe<string>;

  syncErrorMessage: Maybe<string>;

  children: Maybe<SelectCustomerSkusChildren[]>;
};

export type SelectCustomerSkusChildren = {
  __typename?: "CustomerSku";

  id: number;

  sku: string;

  title: Maybe<string>;

  asin: Maybe<string>;

  upc: Maybe<string>;

  skuType: Maybe<string>;

  syncStatus: Maybe<string>;

  syncErrorMessage: Maybe<string>;
};

export type SelectCustomerSkuVariables = {
  customerSkuId: number;
};

export type SelectCustomerSkuQuery = {
  __typename?: "Query";

  customerSku: Maybe<SelectCustomerSkuCustomerSku>;
};

export type SelectCustomerSkuCustomerSku = {
  __typename?: "CustomerSku";

  id: number;

  sku: string;

  title: Maybe<string>;

  asin: Maybe<string>;

  upc: Maybe<string>;

  skuType: Maybe<string>;

  syncStatus: Maybe<string>;

  createdAt: DateTime;

  lastSyncedAt: Maybe<DateTime>;

  lastSyncAttemptedAt: Maybe<DateTime>;

  parent: Maybe<SelectCustomerSkuParent>;

  children: Maybe<SelectCustomerSkuChildren[]>;
};

export type SelectCustomerSkuParent = {
  __typename?: "CustomerSku";

  id: number;

  sku: string;

  title: Maybe<string>;

  asin: Maybe<string>;

  upc: Maybe<string>;

  skuType: Maybe<string>;

  syncStatus: Maybe<string>;

  createdAt: DateTime;

  lastSyncedAt: Maybe<DateTime>;

  lastSyncAttemptedAt: Maybe<DateTime>;
};

export type SelectCustomerSkuChildren = {
  __typename?: "CustomerSku";

  id: number;

  sku: string;

  title: Maybe<string>;

  asin: Maybe<string>;

  upc: Maybe<string>;

  skuType: Maybe<string>;

  syncStatus: Maybe<string>;

  createdAt: DateTime;

  lastSyncedAt: Maybe<DateTime>;

  lastSyncAttemptedAt: Maybe<DateTime>;
};

export type UpdateCustomerSkuDetailsVariables = {
  input: UpdateCustomerSkuInput;
};

export type UpdateCustomerSkuDetailsMutation = {
  __typename?: "Mutation";

  updateCustomerSku: UpdateCustomerSkuDetailsUpdateCustomerSku;
};

export type UpdateCustomerSkuDetailsUpdateCustomerSku = {
  __typename?: "UpdateCustomerSkuResponse";

  customerSku: UpdateCustomerSkuDetailsCustomerSku;
};

export type UpdateCustomerSkuDetailsCustomerSku = {
  __typename?: "CustomerSku";

  id: number;

  sku: string;

  title: Maybe<string>;

  asin: Maybe<string>;

  upc: Maybe<string>;

  skuType: Maybe<string>;

  syncStatus: Maybe<string>;
};

export type UpdateCustomerSkuVariables = {
  input: UpdateCustomerSkuInput;
};

export type UpdateCustomerSkuMutation = {
  __typename?: "Mutation";

  updateCustomerSku: UpdateCustomerSkuUpdateCustomerSku;
};

export type UpdateCustomerSkuUpdateCustomerSku = {
  __typename?: "UpdateCustomerSkuResponse";

  customerSku: UpdateCustomerSkuCustomerSku;
};

export type UpdateCustomerSkuCustomerSku = {
  __typename?: "CustomerSku";

  id: number;

  title: Maybe<string>;

  sku: string;

  asin: Maybe<string>;

  upc: Maybe<string>;

  skuType: Maybe<string>;
};

export type CreateCustomerUserVariables = {
  input: CreateCustomerUserInput;
};

export type CreateCustomerUserMutation = {
  __typename?: "Mutation";

  createCustomerUser: CreateCustomerUserCreateCustomerUser;
};

export type CreateCustomerUserCreateCustomerUser = {
  __typename?: "CreateCustomerUserResponse";

  customerUser: Maybe<CreateCustomerUserCustomerUser>;
};

export type CreateCustomerUserCustomerUser = {
  __typename?: "CustomerUser";

  id: number;

  userId: number;
};

export type CreateUserEmailAddressVariables = {
  input: CreateUserEmailAddressInput;
};

export type CreateUserEmailAddressMutation = {
  __typename?: "Mutation";

  createUserEmailAddress: CreateUserEmailAddressCreateUserEmailAddress;
};

export type CreateUserEmailAddressCreateUserEmailAddress = {
  __typename?: "CreateUserEmailAddressResponse";

  userEmailAddress: Maybe<CreateUserEmailAddressUserEmailAddress>;
};

export type CreateUserEmailAddressUserEmailAddress = {
  __typename?: "UserEmailAddress";

  id: number;

  label: string;

  email: string;

  isPrimary: boolean;
};

export type CreateUserPhoneNumberVariables = {
  input: CreateUserPhoneNumberInput;
};

export type CreateUserPhoneNumberMutation = {
  __typename?: "Mutation";

  createUserPhoneNumber: CreateUserPhoneNumberCreateUserPhoneNumber;
};

export type CreateUserPhoneNumberCreateUserPhoneNumber = {
  __typename?: "CreateUserPhoneNumberResponse";

  userPhoneNumber: Maybe<CreateUserPhoneNumberUserPhoneNumber>;
};

export type CreateUserPhoneNumberUserPhoneNumber = {
  __typename?: "UserPhoneNumber";

  id: number;

  label: string;

  phoneNumber: string;

  isPrimary: boolean;
};

export type SelectCustomerUsersVariables = {
  customerId: number;
};

export type SelectCustomerUsersQuery = {
  __typename?: "Query";

  customer: Maybe<SelectCustomerUsersCustomer>;
};

export type SelectCustomerUsersCustomer = {
  __typename?: "Customer";

  id: number;

  customerUsers: SelectCustomerUsersCustomerUsers[];
};

export type SelectCustomerUsersCustomerUsers = {
  __typename?: "CustomerUser";

  id: number;

  title: Maybe<string>;

  notes: Maybe<string>;

  createdAt: Maybe<DateTime>;

  user: SelectCustomerUsersUser;
};

export type SelectCustomerUsersUser = {
  __typename?: "User";

  id: number;

  firstName: string;

  lastName: string;

  fullName: string;

  profileImageUrl: Maybe<string>;

  emailAddresses: SelectCustomerUsersEmailAddresses[];

  phoneNumbers: SelectCustomerUsersPhoneNumbers[];
};

export type SelectCustomerUsersEmailAddresses = {
  __typename?: "UserEmailAddress";

  id: number;

  email: string;

  label: string;

  isPrimary: boolean;
};

export type SelectCustomerUsersPhoneNumbers = {
  __typename?: "UserPhoneNumber";

  id: number;

  phoneNumber: string;

  label: string;

  isPrimary: boolean;
};

export type DeleteCustomerUserVariables = {
  input: DeleteCustomerUserInput;
};

export type DeleteCustomerUserMutation = {
  __typename?: "Mutation";

  deleteCustomerUser: DeleteCustomerUserDeleteCustomerUser;
};

export type DeleteCustomerUserDeleteCustomerUser = {
  __typename?: "DeleteCustomerUserResponse";

  wasSuccessful: boolean;
};

export type DeleteUserEmailAddressVariables = {
  userEmailAddressId: number;
};

export type DeleteUserEmailAddressMutation = {
  __typename?: "Mutation";

  deleteUserEmailAddress: DeleteUserEmailAddressDeleteUserEmailAddress;
};

export type DeleteUserEmailAddressDeleteUserEmailAddress = {
  __typename?: "DeleteUserEmailAddressResponse";

  wasDeleted: boolean;
};

export type DeleteUserPhoneNumberVariables = {
  userPhoneNumberId: number;
};

export type DeleteUserPhoneNumberMutation = {
  __typename?: "Mutation";

  deleteUserPhoneNumber: DeleteUserPhoneNumberDeleteUserPhoneNumber;
};

export type DeleteUserPhoneNumberDeleteUserPhoneNumber = {
  __typename?: "DeleteUserPhoneNumberResponse";

  wasDeleted: boolean;
};

export type UpdateCustomerUserVariables = {
  input: UpdateCustomerUserInput;
};

export type UpdateCustomerUserMutation = {
  __typename?: "Mutation";

  updateCustomerUser: UpdateCustomerUserUpdateCustomerUser;
};

export type UpdateCustomerUserUpdateCustomerUser = {
  __typename?: "UpdateCustomerUserResponse";

  customerUser: Maybe<UpdateCustomerUserCustomerUser>;
};

export type UpdateCustomerUserCustomerUser = {
  __typename?: "CustomerUser";

  id: number;

  title: Maybe<string>;

  notes: Maybe<string>;

  user: UpdateCustomerUserUser;
};

export type UpdateCustomerUserUser = {
  __typename?: "User";

  id: number;

  firstName: string;

  lastName: string;
};

export type UpdateUserEmailAddressVariables = {
  input: UpdateUserEmailAddressInput;
};

export type UpdateUserEmailAddressMutation = {
  __typename?: "Mutation";

  updateUserEmailAddress: UpdateUserEmailAddressUpdateUserEmailAddress;
};

export type UpdateUserEmailAddressUpdateUserEmailAddress = {
  __typename?: "UpdateUserEmailAddressResponse";

  userEmailAddress: Maybe<UpdateUserEmailAddressUserEmailAddress>;
};

export type UpdateUserEmailAddressUserEmailAddress = {
  __typename?: "UserEmailAddress";

  id: number;

  label: string;

  email: string;

  isPrimary: boolean;
};

export type UpdateUserPhoneNumberVariables = {
  input: UpdateUserPhoneNumberInput;
};

export type UpdateUserPhoneNumberMutation = {
  __typename?: "Mutation";

  updateUserPhoneNumber: UpdateUserPhoneNumberUpdateUserPhoneNumber;
};

export type UpdateUserPhoneNumberUpdateUserPhoneNumber = {
  __typename?: "UpdateUserPhoneNumberResponse";

  userPhoneNumber: Maybe<UpdateUserPhoneNumberUserPhoneNumber>;
};

export type UpdateUserPhoneNumberUserPhoneNumber = {
  __typename?: "UserPhoneNumber";

  id: number;

  label: string;

  phoneNumber: string;

  isPrimary: boolean;
};

export type CreateCustomerVariables = {
  input: CreateCustomerInput;
};

export type CreateCustomerMutation = {
  __typename?: "Mutation";

  createCustomer: CreateCustomerCreateCustomer;
};

export type CreateCustomerCreateCustomer = {
  __typename?: "CreateCustomerResponse";

  customer: Maybe<CreateCustomerCustomer>;
};

export type CreateCustomerCustomer = {
  __typename?: "Customer";

  id: number;
};

export type SearchCustomersVariables = {
  search?: Maybe<string>;
  limit: number;
  offset?: Maybe<number>;
  sortField: CustomerSortField;
  sortDirection: SortDirection;
};

export type SearchCustomersQuery = {
  __typename?: "Query";

  customers: SearchCustomersCustomers;
};

export type SearchCustomersCustomers = {
  __typename?: "CustomerConnection";

  totalCount: number;

  pageInfo: SearchCustomersPageInfo;

  items: SearchCustomersItems[];
};

export type SearchCustomersPageInfo = {
  __typename?: "PageInfo";

  hasNextPage: boolean;
};

export type SearchCustomersItems = {
  __typename?: "Customer";

  id: number;

  name: string;

  accountManagerUser: Maybe<SearchCustomersAccountManagerUser>;

  isStarred: boolean;
};

export type SearchCustomersAccountManagerUser = {
  __typename?: "User";

  id: number;

  fullName: string;

  profileImageUrl: Maybe<string>;
};

export type SelectMyCustomersVariables = {};

export type SelectMyCustomersQuery = {
  __typename?: "Query";

  viewer: Maybe<SelectMyCustomersViewer>;
};

export type SelectMyCustomersViewer = {
  __typename?: "User";

  id: number;

  tasks: Maybe<SelectMyCustomersTasks>;

  accountManagement: SelectMyCustomersAccountManagement;
};

export type SelectMyCustomersTasks = {
  __typename?: "TaskConnection";

  items: SelectMyCustomersItems[];
};

export type SelectMyCustomersItems = {
  __typename?: "Task";

  id: number;

  title: string;

  dueDate: Maybe<DateTime>;

  customer: Maybe<SelectMyCustomersCustomer>;
};

export type SelectMyCustomersCustomer = {
  __typename?: "Customer";

  id: number;

  name: string;
};

export type SelectMyCustomersAccountManagement = {
  __typename?: "UserAccountManagement";

  customersManaged: Maybe<SelectMyCustomersCustomersManaged>;
};

export type SelectMyCustomersCustomersManaged = {
  __typename?: "CustomerConnection";

  totalCount: number;

  items: SelectMyCustomers_Items[];
};

export type SelectMyCustomers_Items = {
  __typename?: "Customer";

  id: number;

  name: string;

  maxEndDate: Maybe<Date>;

  contractValue: Maybe<number>;
};

export type SelectMyStarredCustomersVariables = {};

export type SelectMyStarredCustomersQuery = {
  __typename?: "Query";

  viewer: Maybe<SelectMyStarredCustomersViewer>;
};

export type SelectMyStarredCustomersViewer = {
  __typename?: "User";

  id: number;

  customerStars: SelectMyStarredCustomersCustomerStars[];
};

export type SelectMyStarredCustomersCustomerStars = {
  __typename?: "CustomerStar";

  id: number;

  customer: SelectMyStarredCustomersCustomer;
};

export type SelectMyStarredCustomersCustomer = {
  __typename?: "Customer";

  id: number;

  name: string;
};

export type SelectMyTasksVariables = {};

export type SelectMyTasksQuery = {
  __typename?: "Query";

  viewer: Maybe<SelectMyTasksViewer>;
};

export type SelectMyTasksViewer = {
  __typename?: "User";

  id: number;

  tasks: Maybe<SelectMyTasksTasks>;
};

export type SelectMyTasksTasks = {
  __typename?: "TaskConnection";

  totalCount: number;

  items: SelectMyTasksItems[];
};

export type SelectMyTasksItems = {
  __typename?: "Task";

  id: number;

  title: string;

  dueDate: Maybe<DateTime>;

  customer: Maybe<SelectMyTasksCustomer>;

  taskType: SelectMyTasksTaskType;

  customerProductBatch: Maybe<SelectMyTasksCustomerProductBatch>;
};

export type SelectMyTasksCustomer = {
  __typename?: "Customer";

  id: number;

  name: string;
};

export type SelectMyTasksTaskType = {
  __typename?: "TaskType";

  id: string;

  name: string;
};

export type SelectMyTasksCustomerProductBatch = {
  __typename?: "CustomerProductBatch";

  id: number;
};

export type CreateInventoryItemVariables = {
  input: CreateInventoryItemInput;
};

export type CreateInventoryItemMutation = {
  __typename?: "Mutation";

  createInventoryItem: CreateInventoryItemCreateInventoryItem;
};

export type CreateInventoryItemCreateInventoryItem = {
  __typename?: "CreateInventoryItemResponse";

  inventoryItem: Maybe<CreateInventoryItemInventoryItem>;
};

export type CreateInventoryItemInventoryItem = {
  __typename?: "InventoryItem";

  id: number;
};

export type DeleteInventoryItemVariables = {
  input: DeleteInventoryItemInput;
};

export type DeleteInventoryItemMutation = {
  __typename?: "Mutation";

  deleteInventoryItem: DeleteInventoryItemDeleteInventoryItem;
};

export type DeleteInventoryItemDeleteInventoryItem = {
  __typename?: "DeleteInventoryItemResponse";

  wasSuccessful: boolean;
};

export type SelectInventoryItemsVariables = {};

export type SelectInventoryItemsQuery = {
  __typename?: "Query";

  inventoryItemLocations: SelectInventoryItemsInventoryItemLocations[];

  inventoryItemConditions: SelectInventoryItemsInventoryItemConditions[];

  inventoryItems: SelectInventoryItemsInventoryItems;
};

export type SelectInventoryItemsInventoryItemLocations = {
  __typename?: "InventoryItemLocation";

  id: string;

  name: string;

  description: string;
};

export type SelectInventoryItemsInventoryItemConditions = {
  __typename?: "InventoryItemCondition";

  id: string;

  name: string;

  description: string;
};

export type SelectInventoryItemsInventoryItems = {
  __typename?: "InventoryItemConnection";

  items: SelectInventoryItemsItems[];
};

export type SelectInventoryItemsItems = {
  __typename?: "InventoryItem";

  id: number;

  description: Maybe<string>;

  customerId: number;

  customerSkuId: Maybe<number>;

  receivedAt: DateTime;

  storageLocation: Maybe<string>;

  quantity: number;

  status: SelectInventoryItemsStatus;

  customerSku: Maybe<SelectInventoryItemsCustomerSku>;

  customer: SelectInventoryItemsCustomer;

  location: SelectInventoryItemsLocation;

  condition: SelectInventoryItemsCondition;
};

export type SelectInventoryItemsStatus = {
  __typename?: "InventoryItemStatus";

  id: string;

  name: string;
};

export type SelectInventoryItemsCustomerSku = {
  __typename?: "CustomerSku";

  id: number;

  title: Maybe<string>;

  sku: string;
};

export type SelectInventoryItemsCustomer = {
  __typename?: "Customer";

  id: number;

  name: string;
};

export type SelectInventoryItemsLocation = {
  __typename?: "InventoryItemLocation";

  id: string;

  name: string;
};

export type SelectInventoryItemsCondition = {
  __typename?: "InventoryItemCondition";

  id: string;

  name: string;
};

export type UpdateInventoryItemVariables = {
  input: UpdateInventoryItemInput;
};

export type UpdateInventoryItemMutation = {
  __typename?: "Mutation";

  updateInventoryItem: UpdateInventoryItemUpdateInventoryItem;
};

export type UpdateInventoryItemUpdateInventoryItem = {
  __typename?: "UpdateInventoryItemResponse";

  inventoryItem: UpdateInventoryItemInventoryItem;
};

export type UpdateInventoryItemInventoryItem = {
  __typename?: "InventoryItem";

  id: number;

  description: Maybe<string>;

  customerId: number;

  customerSkuId: Maybe<number>;

  receivedAt: DateTime;

  storageLocation: Maybe<string>;

  quantity: number;

  status: UpdateInventoryItemStatus;

  customerSku: Maybe<UpdateInventoryItemCustomerSku>;

  customer: UpdateInventoryItemCustomer;

  location: UpdateInventoryItemLocation;

  condition: UpdateInventoryItemCondition;
};

export type UpdateInventoryItemStatus = {
  __typename?: "InventoryItemStatus";

  id: string;

  name: string;
};

export type UpdateInventoryItemCustomerSku = {
  __typename?: "CustomerSku";

  id: number;

  title: Maybe<string>;

  sku: string;
};

export type UpdateInventoryItemCustomer = {
  __typename?: "Customer";

  id: number;

  name: string;
};

export type UpdateInventoryItemLocation = {
  __typename?: "InventoryItemLocation";

  id: string;

  name: string;
};

export type UpdateInventoryItemCondition = {
  __typename?: "InventoryItemCondition";

  id: string;

  name: string;
};

export type PricingToolInfoVariables = {};

export type PricingToolInfoQuery = {
  __typename?: "Query";

  pricingToolInfo: PricingToolInfoPricingToolInfo;
};

export type PricingToolInfoPricingToolInfo = {
  __typename?: "PricingToolInfo";

  averageWeeklyAmHours: number;

  weeksPerMonth: number;

  managedAmountLimit: number;

  amHourlyRate: number;

  trueHourlyOverhead: number;

  desiredProfitMargin: number;
};

export type SelectAccountManagementOverviewVariables = {
  search?: Maybe<string>;
};

export type SelectAccountManagementOverviewQuery = {
  __typename?: "Query";

  accountManagementOverview: SelectAccountManagementOverviewAccountManagementOverview;
};

export type SelectAccountManagementOverviewAccountManagementOverview = {
  __typename?: "UserConnection";

  items: Maybe<SelectAccountManagementOverviewItems[]>;
};

export type SelectAccountManagementOverviewItems = {
  __typename?: "User";

  id: number;

  fullName: string;

  profileImageUrl: Maybe<string>;

  teams: SelectAccountManagementOverviewTeams[];

  accountManagement: SelectAccountManagementOverviewAccountManagement;
};

export type SelectAccountManagementOverviewTeams = {
  __typename?: "Team";

  id: number;

  name: string;
};

export type SelectAccountManagementOverviewAccountManagement = {
  __typename?: "UserAccountManagement";

  amountManaged: number;

  customersManaged: Maybe<SelectAccountManagementOverviewCustomersManaged>;
};

export type SelectAccountManagementOverviewCustomersManaged = {
  __typename?: "CustomerConnection";

  totalCount: number;

  items: SelectAccountManagementOverview_Items[];
};

export type SelectAccountManagementOverview_Items = {
  __typename?: "Customer";

  id: number;

  name: string;
};

export type SelectAccountManagementTeamsVariables = {};

export type SelectAccountManagementTeamsQuery = {
  __typename?: "Query";

  teams: SelectAccountManagementTeamsTeams;
};

export type SelectAccountManagementTeamsTeams = {
  __typename?: "TeamConnection";

  items: SelectAccountManagementTeamsItems[];
};

export type SelectAccountManagementTeamsItems = {
  __typename?: "Team";

  id: number;

  name: string;
};

export type SelectTeamUsersVariables = {
  teamId: number;
};

export type SelectTeamUsersQuery = {
  __typename?: "Query";

  teamUsersInformation: Maybe<SelectTeamUsersTeamUsersInformation>;
};

export type SelectTeamUsersTeamUsersInformation = {
  __typename?: "Team";

  id: number;

  name: string;

  leadUser: SelectTeamUsersLeadUser;

  teamUsers: SelectTeamUsersTeamUsers[];
};

export type SelectTeamUsersLeadUser = {
  __typename?: "User";

  id: number;

  fullName: string;

  profileImageUrl: Maybe<string>;

  accountManagement: SelectTeamUsersAccountManagement;
};

export type SelectTeamUsersAccountManagement = {
  __typename?: "UserAccountManagement";

  customersManaged: Maybe<SelectTeamUsersCustomersManaged>;

  amountManaged: number;
};

export type SelectTeamUsersCustomersManaged = {
  __typename?: "CustomerConnection";

  items: SelectTeamUsersItems[];
};

export type SelectTeamUsersItems = {
  __typename?: "Customer";

  id: number;

  name: string;
};

export type SelectTeamUsersTeamUsers = {
  __typename?: "TeamUser";

  user: SelectTeamUsersUser;
};

export type SelectTeamUsersUser = {
  __typename?: "User";

  id: number;

  fullName: string;

  profileImageUrl: Maybe<string>;

  accountManagement: SelectTeamUsers_AccountManagement;
};

export type SelectTeamUsers_AccountManagement = {
  __typename?: "UserAccountManagement";

  customersManaged: Maybe<SelectTeamUsers_CustomersManaged>;

  amountManaged: number;
};

export type SelectTeamUsers_CustomersManaged = {
  __typename?: "CustomerConnection";

  items: SelectTeamUsers_Items[];
};

export type SelectTeamUsers_Items = {
  __typename?: "Customer";

  id: number;

  name: string;
};

export type SelectTeamsByGroupVariables = {};

export type SelectTeamsByGroupQuery = {
  __typename?: "Query";

  teams: SelectTeamsByGroupTeams;
};

export type SelectTeamsByGroupTeams = {
  __typename?: "TeamConnection";

  items: SelectTeamsByGroupItems[];
};

export type SelectTeamsByGroupItems = {
  __typename?: "Team";

  id: number;

  name: string;

  leadUser: SelectTeamsByGroupLeadUser;

  teamUsers: SelectTeamsByGroupTeamUsers[];
};

export type SelectTeamsByGroupLeadUser = {
  __typename?: "User";

  id: number;

  fullName: string;

  profileImageUrl: Maybe<string>;

  accountManagement: SelectTeamsByGroupAccountManagement;
};

export type SelectTeamsByGroupAccountManagement = {
  __typename?: "UserAccountManagement";

  customersManaged: Maybe<SelectTeamsByGroupCustomersManaged>;

  amountManaged: number;
};

export type SelectTeamsByGroupCustomersManaged = {
  __typename?: "CustomerConnection";

  items: SelectTeamsByGroup_Items[];
};

export type SelectTeamsByGroup_Items = {
  __typename?: "Customer";

  id: number;

  name: string;
};

export type SelectTeamsByGroupTeamUsers = {
  __typename?: "TeamUser";

  user: SelectTeamsByGroupUser;
};

export type SelectTeamsByGroupUser = {
  __typename?: "User";

  id: number;

  fullName: string;

  profileImageUrl: Maybe<string>;

  accountManagement: SelectTeamsByGroup_AccountManagement;
};

export type SelectTeamsByGroup_AccountManagement = {
  __typename?: "UserAccountManagement";

  customersManaged: Maybe<SelectTeamsByGroup_CustomersManaged>;

  amountManaged: number;
};

export type SelectTeamsByGroup_CustomersManaged = {
  __typename?: "CustomerConnection";

  items: SelectTeamsByGroup__Items[];
};

export type SelectTeamsByGroup__Items = {
  __typename?: "Customer";

  id: number;

  name: string;
};

export type SelectCustomersEndingIn30DaysVariables = {};

export type SelectCustomersEndingIn30DaysQuery = {
  __typename?: "Query";

  reports: Maybe<SelectCustomersEndingIn30DaysReports>;
};

export type SelectCustomersEndingIn30DaysReports = {
  __typename?: "Reports";

  customersEndingIn30Days: Maybe<
    SelectCustomersEndingIn30DaysCustomersEndingIn30Days[]
  >;
};

export type SelectCustomersEndingIn30DaysCustomersEndingIn30Days = {
  __typename?: "CustomersEndingIn30Days";

  id: number;

  name: string;

  startDate: Date;
};

export type SelectCustomersNoAssignedAccountManagerVariables = {};

export type SelectCustomersNoAssignedAccountManagerQuery = {
  __typename?: "Query";

  customers: SelectCustomersNoAssignedAccountManagerCustomers;
};

export type SelectCustomersNoAssignedAccountManagerCustomers = {
  __typename?: "CustomerConnection";

  items: SelectCustomersNoAssignedAccountManagerItems[];
};

export type SelectCustomersNoAssignedAccountManagerItems = {
  __typename?: "Customer";

  id: number;

  name: string;
};

export type SearchCustomerSkusVariables = {
  customerId: number;
  search?: Maybe<string>;
  skuTypes?: Maybe<string[]>;
  limit: number;
  offset: number;
};

export type SearchCustomerSkusQuery = {
  __typename?: "Query";

  customer: Maybe<SearchCustomerSkusCustomer>;
};

export type SearchCustomerSkusCustomer = {
  __typename?: "Customer";

  id: number;

  customerSkus: SearchCustomerSkusCustomerSkus;
};

export type SearchCustomerSkusCustomerSkus = {
  __typename?: "CustomerSkuConnection";

  pageInfo: SearchCustomerSkusPageInfo;

  totalCount: number;

  items: SearchCustomerSkusItems[];
};

export type SearchCustomerSkusPageInfo = {
  __typename?: "PageInfo";

  hasPreviousPage: boolean;

  hasNextPage: boolean;
};

export type SearchCustomerSkusItems = {
  __typename?: "CustomerSku";

  id: number;

  sku: string;

  asin: Maybe<string>;

  upc: Maybe<string>;

  title: Maybe<string>;

  skuType: Maybe<string>;
};

export type TaskNotesVariables = {
  taskId: number;
  search?: Maybe<string>;
  limit?: Maybe<number>;
  offset?: Maybe<number>;
};

export type TaskNotesQuery = {
  __typename?: "Query";

  task: Maybe<TaskNotesTask>;
};

export type TaskNotesTask = {
  __typename?: "Task";

  id: number;

  notes: Maybe<TaskNotesNotes>;
};

export type TaskNotesNotes = {
  __typename?: "NoteConnection";

  pageInfo: TaskNotesPageInfo;

  totalCount: number;

  items: TaskNotesItems[];
};

export type TaskNotesPageInfo = {
  __typename?: "PageInfo";

  hasPreviousPage: boolean;

  hasNextPage: boolean;
};

export type TaskNotesItems = {
  __typename?: "Note";

  id: number;

  content: string;

  createdAt: DateTime;

  userId: number;

  originalNoteId: Maybe<number>;

  isLatest: boolean;

  user: TaskNotesUser;

  noteReminders: TaskNotesNoteReminders[];

  isDeletable: boolean;
};

export type TaskNotesUser = {
  __typename?: "User";

  id: number;

  fullName: string;
};

export type TaskNotesNoteReminders = {
  __typename?: "NoteReminder";

  id: number;

  noteId: number;

  content: string;

  dueAt: Maybe<DateTime>;

  completedAt: Maybe<DateTime>;
};

export type SelectCustomerProductBatchInfographicReviewItemsVariables = {
  customerProductBatchId: number;
};

export type SelectCustomerProductBatchInfographicReviewItemsQuery = {
  __typename?: "Query";

  customerProductBatch: Maybe<
    SelectCustomerProductBatchInfographicReviewItemsCustomerProductBatch
  >;
};

export type SelectCustomerProductBatchInfographicReviewItemsCustomerProductBatch = {
  __typename?: "CustomerProductBatch";

  id: number;

  infographicReviewItems: SelectCustomerProductBatchInfographicReviewItemsInfographicReviewItems[];
};

export type SelectCustomerProductBatchInfographicReviewItemsInfographicReviewItems = {
  __typename?: "InfographicReviewItem";

  id: number;

  comments: string;

  createdAt: DateTime;

  user: SelectCustomerProductBatchInfographicReviewItemsUser;
};

export type SelectCustomerProductBatchInfographicReviewItemsUser = {
  __typename?: "User";

  id: number;

  fullName: string;

  profileImageUrl: Maybe<string>;
};

export type CreateInfographicReviewItemsVariables = {
  input: CreateInfographicReviewItemsInput;
};

export type CreateInfographicReviewItemsMutation = {
  __typename?: "Mutation";

  createInfographicReviewItems: CreateInfographicReviewItemsCreateInfographicReviewItems;
};

export type CreateInfographicReviewItemsCreateInfographicReviewItems = {
  __typename?: "CreateInfographicReviewItemsResponse";

  infographicReviewItems: Maybe<
    CreateInfographicReviewItemsInfographicReviewItems[]
  >;
};

export type CreateInfographicReviewItemsInfographicReviewItems = {
  __typename?: "InfographicReviewItem";

  id: number;
};

export type SelectInfographicBatchReviewTaskWorkspaceVariables = {
  customerProductBatchId: number;
};

export type SelectInfographicBatchReviewTaskWorkspaceQuery = {
  __typename?: "Query";

  customerProductBatch: Maybe<
    SelectInfographicBatchReviewTaskWorkspaceCustomerProductBatch
  >;
};

export type SelectInfographicBatchReviewTaskWorkspaceCustomerProductBatch = {
  __typename?: "CustomerProductBatch";

  id: number;

  customerProducts: SelectInfographicBatchReviewTaskWorkspaceCustomerProducts[];
};

export type SelectInfographicBatchReviewTaskWorkspaceCustomerProducts = {
  __typename?: "CustomerProduct";

  id: number;

  product: SelectInfographicBatchReviewTaskWorkspaceProduct;

  customerSku: Maybe<SelectInfographicBatchReviewTaskWorkspaceCustomerSku>;
};

export type SelectInfographicBatchReviewTaskWorkspaceProduct = {
  __typename?: "Product";

  id: string;

  name: string;
};

export type SelectInfographicBatchReviewTaskWorkspaceCustomerSku = {
  __typename?: "CustomerSku";

  id: number;

  title: Maybe<string>;

  sku: string;
};

export type CreatePhotographyReviewItemsVariables = {
  input: CreatePhotographyReviewItemsInput;
};

export type CreatePhotographyReviewItemsMutation = {
  __typename?: "Mutation";

  createPhotographyReviewItems: CreatePhotographyReviewItemsCreatePhotographyReviewItems;
};

export type CreatePhotographyReviewItemsCreatePhotographyReviewItems = {
  __typename?: "CreatePhotographyReviewItemsResponse";

  photographyReviewItems: Maybe<
    CreatePhotographyReviewItemsPhotographyReviewItems[]
  >;
};

export type CreatePhotographyReviewItemsPhotographyReviewItems = {
  __typename?: "PhotographyReviewItem";

  id: number;
};

export type SelectPhotoBatchReviewTaskWorkspaceVariables = {
  customerProductBatchId: number;
};

export type SelectPhotoBatchReviewTaskWorkspaceQuery = {
  __typename?: "Query";

  customerProductBatch: Maybe<
    SelectPhotoBatchReviewTaskWorkspaceCustomerProductBatch
  >;
};

export type SelectPhotoBatchReviewTaskWorkspaceCustomerProductBatch = {
  __typename?: "CustomerProductBatch";

  id: number;

  customerProducts: SelectPhotoBatchReviewTaskWorkspaceCustomerProducts[];
};

export type SelectPhotoBatchReviewTaskWorkspaceCustomerProducts = {
  __typename?: "CustomerProduct";

  id: number;

  product: SelectPhotoBatchReviewTaskWorkspaceProduct;

  customerSku: Maybe<SelectPhotoBatchReviewTaskWorkspaceCustomerSku>;
};

export type SelectPhotoBatchReviewTaskWorkspaceProduct = {
  __typename?: "Product";

  id: string;

  name: string;
};

export type SelectPhotoBatchReviewTaskWorkspaceCustomerSku = {
  __typename?: "CustomerSku";

  id: number;

  title: Maybe<string>;

  sku: string;
};

export type PhotoEditRedoTaskWorkspaceVariables = {
  customerProductBatchId: number;
};

export type PhotoEditRedoTaskWorkspaceQuery = {
  __typename?: "Query";

  customerProductBatch: Maybe<PhotoEditRedoTaskWorkspaceCustomerProductBatch>;
};

export type PhotoEditRedoTaskWorkspaceCustomerProductBatch = {
  __typename?: "CustomerProductBatch";

  id: number;

  photographyReviewItems: PhotoEditRedoTaskWorkspacePhotographyReviewItems[];
};

export type PhotoEditRedoTaskWorkspacePhotographyReviewItems = {
  __typename?: "PhotographyReviewItem";

  id: number;

  createdAt: DateTime;

  comments: string;

  user: PhotoEditRedoTaskWorkspaceUser;
};

export type PhotoEditRedoTaskWorkspaceUser = {
  __typename?: "User";

  id: number;

  fullName: string;

  profileImageUrl: Maybe<string>;
};

export type PhotoReshootTaskWorkspaceVariables = {
  customerProductBatchId: number;
};

export type PhotoReshootTaskWorkspaceQuery = {
  __typename?: "Query";

  customerProductBatch: Maybe<PhotoReshootTaskWorkspaceCustomerProductBatch>;
};

export type PhotoReshootTaskWorkspaceCustomerProductBatch = {
  __typename?: "CustomerProductBatch";

  id: number;

  photographyReviewItems: PhotoReshootTaskWorkspacePhotographyReviewItems[];
};

export type PhotoReshootTaskWorkspacePhotographyReviewItems = {
  __typename?: "PhotographyReviewItem";

  id: number;

  createdAt: DateTime;

  comments: string;

  user: PhotoReshootTaskWorkspaceUser;
};

export type PhotoReshootTaskWorkspaceUser = {
  __typename?: "User";

  id: number;

  fullName: string;

  profileImageUrl: Maybe<string>;
};

export type RecordTaskWorkVariables = {
  input: RecordTaskWorkInput;
};

export type RecordTaskWorkMutation = {
  __typename?: "Mutation";

  recordTaskWork: RecordTaskWorkRecordTaskWork;
};

export type RecordTaskWorkRecordTaskWork = {
  __typename?: "RecordTaskWorkResponse";

  wasRecorded: boolean;
};

export type SelectTaskVariables = {
  taskId: number;
};

export type SelectTaskQuery = {
  __typename?: "Query";

  viewer: Maybe<SelectTaskViewer>;

  task: Maybe<SelectTaskTask>;
};

export type SelectTaskViewer = {
  __typename?: "User";

  id: number;
};

export type SelectTaskTask = {
  __typename?: "Task";

  id: number;

  title: string;

  statusId: string;

  dueDate: Maybe<DateTime>;

  assignedUser: Maybe<SelectTaskAssignedUser>;

  customer: Maybe<SelectTaskCustomer>;

  customerProductBatch: Maybe<SelectTaskCustomerProductBatch>;

  customerProduct: Maybe<SelectTaskCustomerProduct>;

  notes: Maybe<SelectTaskNotes>;

  taskType: SelectTask_TaskType;
};

export type SelectTaskAssignedUser = {
  __typename?: "User";

  id: number;

  fullName: string;
};

export type SelectTaskCustomer = {
  __typename?: "Customer";

  id: number;

  name: string;

  accountManagerUser: Maybe<SelectTaskAccountManagerUser>;
};

export type SelectTaskAccountManagerUser = {
  __typename?: "User";

  id: number;

  fullName: string;
};

export type SelectTaskCustomerProductBatch = {
  __typename?: "CustomerProductBatch";

  id: number;

  customerProducts: SelectTaskCustomerProducts[];
};

export type SelectTaskCustomerProducts = {
  __typename?: "CustomerProduct";

  id: number;

  customerSku: Maybe<SelectTaskCustomerSku>;

  product: SelectTaskProduct;

  tasks: SelectTaskTasks[];
};

export type SelectTaskCustomerSku = {
  __typename?: "CustomerSku";

  id: number;

  sku: string;

  title: Maybe<string>;
};

export type SelectTaskProduct = {
  __typename?: "Product";

  id: string;

  name: string;
};

export type SelectTaskTasks = {
  __typename?: "Task";

  id: number;

  taskType: SelectTaskTaskType;

  statusId: string;

  title: string;

  assignedUser: Maybe<SelectTask_AssignedUser>;
};

export type SelectTaskTaskType = {
  __typename?: "TaskType";

  id: string;

  name: string;
};

export type SelectTask_AssignedUser = {
  __typename?: "User";

  id: number;

  fullName: string;

  profileImageUrl: Maybe<string>;
};

export type SelectTaskCustomerProduct = {
  __typename?: "CustomerProduct";

  id: number;

  product: SelectTask_Product;

  customerSku: Maybe<SelectTask_CustomerSku>;

  customerProject: SelectTaskCustomerProject;
};

export type SelectTask_Product = {
  __typename?: "Product";

  id: string;

  name: string;
};

export type SelectTask_CustomerSku = {
  __typename?: "CustomerSku";

  id: number;

  sku: string;

  title: Maybe<string>;

  asin: Maybe<string>;

  upc: Maybe<string>;
};

export type SelectTaskCustomerProject = {
  __typename?: "CustomerProject";

  id: number;

  name: Maybe<string>;

  customerContract: Maybe<SelectTaskCustomerContract>;
};

export type SelectTaskCustomerContract = {
  __typename?: "CustomerContract";

  id: number;
};

export type SelectTaskNotes = {
  __typename?: "NoteConnection";

  totalCount: number;
};

export type SelectTask_TaskType = {
  __typename?: "TaskType";

  id: string;

  name: string;
};

export type UpdateTaskVariables = {
  input: UpdateTaskInput;
};

export type UpdateTaskMutation = {
  __typename?: "Mutation";

  updateTask: UpdateTaskUpdateTask;
};

export type UpdateTaskUpdateTask = {
  __typename?: "UpdateTaskResponse";

  task: UpdateTaskTask;
};

export type UpdateTaskTask = {
  __typename?: "Task";

  id: number;
};

export type SelectWelcomeCallVariables = {
  customerId: number;
};

export type SelectWelcomeCallQuery = {
  __typename?: "Query";

  customer: Maybe<SelectWelcomeCallCustomer>;
};

export type SelectWelcomeCallCustomer = {
  __typename?: "Customer";

  id: number;

  welcomeCall: Maybe<SelectWelcomeCallWelcomeCall>;
};

export type SelectWelcomeCallWelcomeCall = {
  __typename?: "WelcomeCall";

  id: number;

  scheduledAt: Maybe<DateTime>;
};

export type SelectTasksFilterDataVariables = {};

export type SelectTasksFilterDataQuery = {
  __typename?: "Query";

  taskTypes: Maybe<SelectTasksFilterDataTaskTypes[]>;

  viewer: Maybe<SelectTasksFilterDataViewer>;
};

export type SelectTasksFilterDataTaskTypes = {
  __typename?: "TaskType";

  id: string;

  name: string;
};

export type SelectTasksFilterDataViewer = {
  __typename?: "User";

  id: number;
};

export type SelectTasksVariables = {
  statusId?: Maybe<TaskStatusEnum>;
  taskTypeIds?: Maybe<string[]>;
  assignedUserId?: Maybe<number>;
  customerId?: Maybe<number>;
  limit?: Maybe<number>;
  offset?: Maybe<number>;
};

export type SelectTasksQuery = {
  __typename?: "Query";

  viewer: Maybe<SelectTasksViewer>;

  taskTypes: Maybe<SelectTasksTaskTypes[]>;

  tasks: SelectTasksTasks;
};

export type SelectTasksViewer = {
  __typename?: "User";

  id: number;
};

export type SelectTasksTaskTypes = {
  __typename?: "TaskType";

  id: string;

  name: string;
};

export type SelectTasksTasks = {
  __typename?: "TaskConnection";

  totalCount: number;

  pageInfo: SelectTasksPageInfo;

  items: SelectTasksItems[];
};

export type SelectTasksPageInfo = {
  __typename?: "PageInfo";

  hasPreviousPage: boolean;

  hasNextPage: boolean;
};

export type SelectTasksItems = {
  __typename?: "Task";

  id: number;

  title: string;

  dueDate: Maybe<DateTime>;

  statusId: string;

  assignedUser: Maybe<SelectTasksAssignedUser>;

  taskType: SelectTasksTaskType;

  customer: Maybe<SelectTasksCustomer>;

  customerProduct: Maybe<SelectTasksCustomerProduct>;

  customerProductBatch: Maybe<SelectTasksCustomerProductBatch>;
};

export type SelectTasksAssignedUser = {
  __typename?: "User";

  id: number;

  fullName: string;
};

export type SelectTasksTaskType = {
  __typename?: "TaskType";

  id: string;

  name: string;
};

export type SelectTasksCustomer = {
  __typename?: "Customer";

  id: number;

  name: string;
};

export type SelectTasksCustomerProduct = {
  __typename?: "CustomerProduct";

  id: number;

  product: SelectTasksProduct;

  customerProject: SelectTasksCustomerProject;
};

export type SelectTasksProduct = {
  __typename?: "Product";

  id: string;

  name: string;
};

export type SelectTasksCustomerProject = {
  __typename?: "CustomerProject";

  id: number;

  name: Maybe<string>;
};

export type SelectTasksCustomerProductBatch = {
  __typename?: "CustomerProductBatch";

  id: number;
};

export type TasksUpdateTaskVariables = {
  input: UpdateTaskInput;
};

export type TasksUpdateTaskMutation = {
  __typename?: "Mutation";

  updateTask: TasksUpdateTaskUpdateTask;
};

export type TasksUpdateTaskUpdateTask = {
  __typename?: "UpdateTaskResponse";

  task: TasksUpdateTaskTask;
};

export type TasksUpdateTaskTask = {
  __typename?: "Task";

  id: number;
};

export type CreateTeamUserVariables = {
  input: CreateTeamUserInput;
};

export type CreateTeamUserMutation = {
  __typename?: "Mutation";

  createTeamUser: CreateTeamUserCreateTeamUser;
};

export type CreateTeamUserCreateTeamUser = {
  __typename?: "CreateTeamUserResponse";

  teamUser: Maybe<CreateTeamUserTeamUser>;
};

export type CreateTeamUserTeamUser = {
  __typename?: "TeamUser";

  id: number;
};

export type DeleteTeamUserVariables = {
  input: DeleteTeamUserInput;
};

export type DeleteTeamUserMutation = {
  __typename?: "Mutation";

  deleteTeamUser: DeleteTeamUserDeleteTeamUser;
};

export type DeleteTeamUserDeleteTeamUser = {
  __typename?: "DeleteTeamUserResponse";

  wasSuccessful: boolean;
};

export type SelectTeamVariables = {
  teamId: number;
};

export type SelectTeamQuery = {
  __typename?: "Query";

  team: Maybe<SelectTeamTeam>;
};

export type SelectTeamTeam = {
  __typename?: "Team";

  id: number;

  name: string;

  department: string;

  createdAt: DateTime;

  leadUser: SelectTeamLeadUser;

  teamUsers: SelectTeamTeamUsers[];
};

export type SelectTeamLeadUser = {
  __typename?: "User";

  id: number;

  fullName: string;

  profileImageUrl: Maybe<string>;
};

export type SelectTeamTeamUsers = {
  __typename?: "TeamUser";

  id: number;

  user: SelectTeamUser;
};

export type SelectTeamUser = {
  __typename?: "User";

  id: number;

  fullName: string;

  profileImageUrl: Maybe<string>;
};

export type UpdateTeamVariables = {
  input: UpdateTeamInput;
};

export type UpdateTeamMutation = {
  __typename?: "Mutation";

  updateTeam: UpdateTeamUpdateTeam;
};

export type UpdateTeamUpdateTeam = {
  __typename?: "UpdateTeamResponse";

  team: Maybe<UpdateTeamTeam>;
};

export type UpdateTeamTeam = {
  __typename?: "Team";

  id: number;

  name: string;

  department: string;

  createdAt: DateTime;

  leadUser: UpdateTeamLeadUser;
};

export type UpdateTeamLeadUser = {
  __typename?: "User";

  id: number;

  fullName: string;

  profileImageUrl: Maybe<string>;
};

export type CreateTeamVariables = {
  input: CreateTeamInput;
};

export type CreateTeamMutation = {
  __typename?: "Mutation";

  createTeam: CreateTeamCreateTeam;
};

export type CreateTeamCreateTeam = {
  __typename?: "CreateTeamResponse";

  team: Maybe<CreateTeamTeam>;
};

export type CreateTeamTeam = {
  __typename?: "Team";

  id: number;
};

export type DeleteTeamVariables = {
  input: DeleteTeamInput;
};

export type DeleteTeamMutation = {
  __typename?: "Mutation";

  deleteTeam: DeleteTeamDeleteTeam;
};

export type DeleteTeamDeleteTeam = {
  __typename?: "DeleteTeamResponse";

  wasSuccessful: boolean;
};

export type SelectTeamsVariables = {};

export type SelectTeamsQuery = {
  __typename?: "Query";

  teams: SelectTeamsTeams;
};

export type SelectTeamsTeams = {
  __typename?: "TeamConnection";

  items: SelectTeamsItems[];
};

export type SelectTeamsItems = {
  __typename?: "Team";

  id: number;

  name: string;

  department: string;

  createdAt: DateTime;

  leadUser: SelectTeamsLeadUser;
};

export type SelectTeamsLeadUser = {
  __typename?: "User";

  id: number;

  fullName: string;

  profileImageUrl: Maybe<string>;
};

import * as ReactApollo from "react-apollo";
import * as React from "react";

import gql from "graphql-tag";

// ====================================================
// Components
// ====================================================

export const AdminDocument = gql`
  mutation admin($input: AdminOpsInput!) {
    admin(input: $input)
  }
`;
export class AdminComponent extends React.Component<
  Partial<ReactApollo.MutationProps<AdminMutation, AdminVariables>>
> {
  render() {
    return (
      <ReactApollo.Mutation<AdminMutation, AdminVariables>
        mutation={AdminDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type AdminProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<AdminMutation, AdminVariables>
> &
  TChildProps;
export type AdminMutationFn = ReactApollo.MutationFn<
  AdminMutation,
  AdminVariables
>;
export function AdminHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        AdminMutation,
        AdminVariables,
        AdminProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    AdminMutation,
    AdminVariables,
    AdminProps<TChildProps>
  >(AdminDocument, operationOptions);
}
export const GetCustomerProductBatchDocument = gql`
  query getCustomerProductBatch($customerProductBatchId: Int!) {
    viewer {
      id
    }
    customerProductBatch(customerProductBatchId: $customerProductBatchId) {
      id
      startDate
      tasks {
        id
        title
        status {
          id
          name
        }
        taskType {
          id
          name
        }
        assignedUser {
          id
          fullName
        }
      }
      customerProducts {
        id
        startDate
        customerSku {
          id
          title
          sku
        }
        status {
          id
          name
        }
        product {
          id
          name
        }
        tasks {
          id
          title
          status {
            id
            name
          }
          taskType {
            id
            name
          }
          assignedUser {
            id
            fullName
          }
        }
      }
    }
  }
`;
export class GetCustomerProductBatchComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetCustomerProductBatchQuery,
      GetCustomerProductBatchVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetCustomerProductBatchQuery,
        GetCustomerProductBatchVariables
      >
        query={GetCustomerProductBatchDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetCustomerProductBatchProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    GetCustomerProductBatchQuery,
    GetCustomerProductBatchVariables
  >
> &
  TChildProps;
export function GetCustomerProductBatchHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetCustomerProductBatchQuery,
        GetCustomerProductBatchVariables,
        GetCustomerProductBatchProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetCustomerProductBatchQuery,
    GetCustomerProductBatchVariables,
    GetCustomerProductBatchProps<TChildProps>
  >(GetCustomerProductBatchDocument, operationOptions);
}
export const UpdateTaskInBatchDocument = gql`
  mutation updateTaskInBatch($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      task {
        id
        title
        status {
          id
          name
        }
        taskType {
          id
          name
        }
        assignedUser {
          id
          fullName
        }
      }
    }
  }
`;
export class UpdateTaskInBatchComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      UpdateTaskInBatchMutation,
      UpdateTaskInBatchVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        UpdateTaskInBatchMutation,
        UpdateTaskInBatchVariables
      >
        mutation={UpdateTaskInBatchDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateTaskInBatchProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<UpdateTaskInBatchMutation, UpdateTaskInBatchVariables>
> &
  TChildProps;
export type UpdateTaskInBatchMutationFn = ReactApollo.MutationFn<
  UpdateTaskInBatchMutation,
  UpdateTaskInBatchVariables
>;
export function UpdateTaskInBatchHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateTaskInBatchMutation,
        UpdateTaskInBatchVariables,
        UpdateTaskInBatchProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateTaskInBatchMutation,
    UpdateTaskInBatchVariables,
    UpdateTaskInBatchProps<TChildProps>
  >(UpdateTaskInBatchDocument, operationOptions);
}
export const SelectCustomerProjectsDocument = gql`
  query selectCustomerProjects(
    $upcomingStartDate: Date
    $upcomingEndDate: Date
  ) {
    viewer {
      id
      roles
    }
    currentProjects: customerProjects(statusId: awaitingbilling) {
      items {
        id
        name
        startDate
        statusId
        amount
        customer {
          id
          name
        }
        customerContract {
          id
        }
      }
    }
    upcomingProjects: customerProjects(
      statusId: scheduled
      startDate: { lte: $upcomingEndDate, gte: $upcomingStartDate }
    ) {
      items {
        id
        name
        startDate
        statusId
        amount
        customer {
          id
          name
        }
        customerContract {
          id
        }
      }
    }
  }
`;
export class SelectCustomerProjectsComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      SelectCustomerProjectsQuery,
      SelectCustomerProjectsVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        SelectCustomerProjectsQuery,
        SelectCustomerProjectsVariables
      >
        query={SelectCustomerProjectsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SelectCustomerProjectsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    SelectCustomerProjectsQuery,
    SelectCustomerProjectsVariables
  >
> &
  TChildProps;
export function SelectCustomerProjectsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SelectCustomerProjectsQuery,
        SelectCustomerProjectsVariables,
        SelectCustomerProjectsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SelectCustomerProjectsQuery,
    SelectCustomerProjectsVariables,
    SelectCustomerProjectsProps<TChildProps>
  >(SelectCustomerProjectsDocument, operationOptions);
}
export const UpdateCustomerProjectMutationDocument = gql`
  mutation updateCustomerProjectMutation($input: UpdateCustomerProjectInput!) {
    updateCustomerProject(input: $input) {
      customerProject {
        id
      }
    }
  }
`;
export class UpdateCustomerProjectMutationComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      UpdateCustomerProjectMutationMutation,
      UpdateCustomerProjectMutationVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        UpdateCustomerProjectMutationMutation,
        UpdateCustomerProjectMutationVariables
      >
        mutation={UpdateCustomerProjectMutationDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateCustomerProjectMutationProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    UpdateCustomerProjectMutationMutation,
    UpdateCustomerProjectMutationVariables
  >
> &
  TChildProps;
export type UpdateCustomerProjectMutationMutationFn = ReactApollo.MutationFn<
  UpdateCustomerProjectMutationMutation,
  UpdateCustomerProjectMutationVariables
>;
export function UpdateCustomerProjectMutationHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateCustomerProjectMutationMutation,
        UpdateCustomerProjectMutationVariables,
        UpdateCustomerProjectMutationProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateCustomerProjectMutationMutation,
    UpdateCustomerProjectMutationVariables,
    UpdateCustomerProjectMutationProps<TChildProps>
  >(UpdateCustomerProjectMutationDocument, operationOptions);
}
export const GetCustomerForCustomerSelectDocument = gql`
  query GetCustomerForCustomerSelect($customerId: Int!) {
    customer(customerId: $customerId) {
      id
      name
    }
  }
`;
export class GetCustomerForCustomerSelectComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetCustomerForCustomerSelectQuery,
      GetCustomerForCustomerSelectVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetCustomerForCustomerSelectQuery,
        GetCustomerForCustomerSelectVariables
      >
        query={GetCustomerForCustomerSelectDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetCustomerForCustomerSelectProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    GetCustomerForCustomerSelectQuery,
    GetCustomerForCustomerSelectVariables
  >
> &
  TChildProps;
export function GetCustomerForCustomerSelectHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetCustomerForCustomerSelectQuery,
        GetCustomerForCustomerSelectVariables,
        GetCustomerForCustomerSelectProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetCustomerForCustomerSelectQuery,
    GetCustomerForCustomerSelectVariables,
    GetCustomerForCustomerSelectProps<TChildProps>
  >(GetCustomerForCustomerSelectDocument, operationOptions);
}
export const GetCustomersForCustomerSelectDocument = gql`
  query GetCustomersForCustomerSelect($search: String) {
    customers(search: $search, limit: 50) {
      items {
        id
        name
      }
    }
  }
`;
export class GetCustomersForCustomerSelectComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetCustomersForCustomerSelectQuery,
      GetCustomersForCustomerSelectVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetCustomersForCustomerSelectQuery,
        GetCustomersForCustomerSelectVariables
      >
        query={GetCustomersForCustomerSelectDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetCustomersForCustomerSelectProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    GetCustomersForCustomerSelectQuery,
    GetCustomersForCustomerSelectVariables
  >
> &
  TChildProps;
export function GetCustomersForCustomerSelectHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetCustomersForCustomerSelectQuery,
        GetCustomersForCustomerSelectVariables,
        GetCustomersForCustomerSelectProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetCustomersForCustomerSelectQuery,
    GetCustomersForCustomerSelectVariables,
    GetCustomersForCustomerSelectProps<TChildProps>
  >(GetCustomersForCustomerSelectDocument, operationOptions);
}
export const GetCustomerSkuForCustomerSkuSelectDocument = gql`
  query GetCustomerSkuForCustomerSkuSelect($customerSkuId: Int!) {
    customerSku(customerSkuId: $customerSkuId) {
      id
      sku
      title
    }
  }
`;
export class GetCustomerSkuForCustomerSkuSelectComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetCustomerSkuForCustomerSkuSelectQuery,
      GetCustomerSkuForCustomerSkuSelectVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetCustomerSkuForCustomerSkuSelectQuery,
        GetCustomerSkuForCustomerSkuSelectVariables
      >
        query={GetCustomerSkuForCustomerSkuSelectDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetCustomerSkuForCustomerSkuSelectProps<
  TChildProps = any
> = Partial<
  ReactApollo.DataProps<
    GetCustomerSkuForCustomerSkuSelectQuery,
    GetCustomerSkuForCustomerSkuSelectVariables
  >
> &
  TChildProps;
export function GetCustomerSkuForCustomerSkuSelectHOC<
  TProps,
  TChildProps = any
>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetCustomerSkuForCustomerSkuSelectQuery,
        GetCustomerSkuForCustomerSkuSelectVariables,
        GetCustomerSkuForCustomerSkuSelectProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetCustomerSkuForCustomerSkuSelectQuery,
    GetCustomerSkuForCustomerSkuSelectVariables,
    GetCustomerSkuForCustomerSkuSelectProps<TChildProps>
  >(GetCustomerSkuForCustomerSkuSelectDocument, operationOptions);
}
export const GetCustomerSkusForCustomerSkuSelectDocument = gql`
  query GetCustomerSkusForCustomerSkuSelect(
    $customerId: Int!
    $search: String
  ) {
    customer(customerId: $customerId) {
      id
      customerSkus(search: $search, onlyChildren: true) {
        items {
          id
          sku
          title
          parent {
            id
            sku
            title
          }
        }
      }
    }
  }
`;
export class GetCustomerSkusForCustomerSkuSelectComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetCustomerSkusForCustomerSkuSelectQuery,
      GetCustomerSkusForCustomerSkuSelectVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetCustomerSkusForCustomerSkuSelectQuery,
        GetCustomerSkusForCustomerSkuSelectVariables
      >
        query={GetCustomerSkusForCustomerSkuSelectDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetCustomerSkusForCustomerSkuSelectProps<
  TChildProps = any
> = Partial<
  ReactApollo.DataProps<
    GetCustomerSkusForCustomerSkuSelectQuery,
    GetCustomerSkusForCustomerSkuSelectVariables
  >
> &
  TChildProps;
export function GetCustomerSkusForCustomerSkuSelectHOC<
  TProps,
  TChildProps = any
>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetCustomerSkusForCustomerSkuSelectQuery,
        GetCustomerSkusForCustomerSkuSelectVariables,
        GetCustomerSkusForCustomerSkuSelectProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetCustomerSkusForCustomerSkuSelectQuery,
    GetCustomerSkusForCustomerSkuSelectVariables,
    GetCustomerSkusForCustomerSkuSelectProps<TChildProps>
  >(GetCustomerSkusForCustomerSkuSelectDocument, operationOptions);
}
export const CreateNoteDocument = gql`
  mutation createNote($input: CreateNoteInput!) {
    createNote(input: $input) {
      note {
        id
      }
    }
  }
`;
export class CreateNoteComponent extends React.Component<
  Partial<ReactApollo.MutationProps<CreateNoteMutation, CreateNoteVariables>>
> {
  render() {
    return (
      <ReactApollo.Mutation<CreateNoteMutation, CreateNoteVariables>
        mutation={CreateNoteDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateNoteProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<CreateNoteMutation, CreateNoteVariables>
> &
  TChildProps;
export type CreateNoteMutationFn = ReactApollo.MutationFn<
  CreateNoteMutation,
  CreateNoteVariables
>;
export function CreateNoteHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateNoteMutation,
        CreateNoteVariables,
        CreateNoteProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateNoteMutation,
    CreateNoteVariables,
    CreateNoteProps<TChildProps>
  >(CreateNoteDocument, operationOptions);
}
export const GetMarkdownEditorViewerDocument = gql`
  query getMarkdownEditorViewer {
    viewer {
      id
      fullName
    }
  }
`;
export class GetMarkdownEditorViewerComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetMarkdownEditorViewerQuery,
      GetMarkdownEditorViewerVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetMarkdownEditorViewerQuery,
        GetMarkdownEditorViewerVariables
      >
        query={GetMarkdownEditorViewerDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetMarkdownEditorViewerProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    GetMarkdownEditorViewerQuery,
    GetMarkdownEditorViewerVariables
  >
> &
  TChildProps;
export function GetMarkdownEditorViewerHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetMarkdownEditorViewerQuery,
        GetMarkdownEditorViewerVariables,
        GetMarkdownEditorViewerProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetMarkdownEditorViewerQuery,
    GetMarkdownEditorViewerVariables,
    GetMarkdownEditorViewerProps<TChildProps>
  >(GetMarkdownEditorViewerDocument, operationOptions);
}
export const DeleteNoteDocument = gql`
  mutation deleteNote($input: DeleteNoteInput!) {
    deleteNote(input: $input) {
      wasSuccessful
    }
  }
`;
export class DeleteNoteComponent extends React.Component<
  Partial<ReactApollo.MutationProps<DeleteNoteMutation, DeleteNoteVariables>>
> {
  render() {
    return (
      <ReactApollo.Mutation<DeleteNoteMutation, DeleteNoteVariables>
        mutation={DeleteNoteDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteNoteProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<DeleteNoteMutation, DeleteNoteVariables>
> &
  TChildProps;
export type DeleteNoteMutationFn = ReactApollo.MutationFn<
  DeleteNoteMutation,
  DeleteNoteVariables
>;
export function DeleteNoteHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteNoteMutation,
        DeleteNoteVariables,
        DeleteNoteProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteNoteMutation,
    DeleteNoteVariables,
    DeleteNoteProps<TChildProps>
  >(DeleteNoteDocument, operationOptions);
}
export const CreateNoteReminderDocument = gql`
  mutation createNoteReminder($input: CreateNoteReminderInput!) {
    createNoteReminder(input: $input) {
      noteReminder {
        noteId
        content
      }
    }
  }
`;
export class CreateNoteReminderComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateNoteReminderMutation,
      CreateNoteReminderVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateNoteReminderMutation,
        CreateNoteReminderVariables
      >
        mutation={CreateNoteReminderDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateNoteReminderProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    CreateNoteReminderMutation,
    CreateNoteReminderVariables
  >
> &
  TChildProps;
export type CreateNoteReminderMutationFn = ReactApollo.MutationFn<
  CreateNoteReminderMutation,
  CreateNoteReminderVariables
>;
export function CreateNoteReminderHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateNoteReminderMutation,
        CreateNoteReminderVariables,
        CreateNoteReminderProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateNoteReminderMutation,
    CreateNoteReminderVariables,
    CreateNoteReminderProps<TChildProps>
  >(CreateNoteReminderDocument, operationOptions);
}
export const CreateStrategyTaskDocument = gql`
  mutation createStrategyTask($input: CreateStrategyTaskInput!) {
    createStrategyTask(input: $input) {
      task {
        id
        title
        createdAt
      }
    }
  }
`;
export class CreateStrategyTaskComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateStrategyTaskMutation,
      CreateStrategyTaskVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateStrategyTaskMutation,
        CreateStrategyTaskVariables
      >
        mutation={CreateStrategyTaskDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateStrategyTaskProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    CreateStrategyTaskMutation,
    CreateStrategyTaskVariables
  >
> &
  TChildProps;
export type CreateStrategyTaskMutationFn = ReactApollo.MutationFn<
  CreateStrategyTaskMutation,
  CreateStrategyTaskVariables
>;
export function CreateStrategyTaskHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateStrategyTaskMutation,
        CreateStrategyTaskVariables,
        CreateStrategyTaskProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateStrategyTaskMutation,
    CreateStrategyTaskVariables,
    CreateStrategyTaskProps<TChildProps>
  >(CreateStrategyTaskDocument, operationOptions);
}
export const DeleteNoteReminderDocument = gql`
  mutation deleteNoteReminder($input: DeleteNoteReminderInput!) {
    deleteNoteReminder(input: $input) {
      wasSuccessful
    }
  }
`;
export class DeleteNoteReminderComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      DeleteNoteReminderMutation,
      DeleteNoteReminderVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        DeleteNoteReminderMutation,
        DeleteNoteReminderVariables
      >
        mutation={DeleteNoteReminderDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteNoteReminderProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    DeleteNoteReminderMutation,
    DeleteNoteReminderVariables
  >
> &
  TChildProps;
export type DeleteNoteReminderMutationFn = ReactApollo.MutationFn<
  DeleteNoteReminderMutation,
  DeleteNoteReminderVariables
>;
export function DeleteNoteReminderHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteNoteReminderMutation,
        DeleteNoteReminderVariables,
        DeleteNoteReminderProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteNoteReminderMutation,
    DeleteNoteReminderVariables,
    DeleteNoteReminderProps<TChildProps>
  >(DeleteNoteReminderDocument, operationOptions);
}
export const UpdateNoteReminderDocument = gql`
  mutation updateNoteReminder($input: UpdateNoteReminderInput!) {
    updateNoteReminder(input: $input) {
      noteReminder {
        id
      }
    }
  }
`;
export class UpdateNoteReminderComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      UpdateNoteReminderMutation,
      UpdateNoteReminderVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        UpdateNoteReminderMutation,
        UpdateNoteReminderVariables
      >
        mutation={UpdateNoteReminderDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateNoteReminderProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    UpdateNoteReminderMutation,
    UpdateNoteReminderVariables
  >
> &
  TChildProps;
export type UpdateNoteReminderMutationFn = ReactApollo.MutationFn<
  UpdateNoteReminderMutation,
  UpdateNoteReminderVariables
>;
export function UpdateNoteReminderHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateNoteReminderMutation,
        UpdateNoteReminderVariables,
        UpdateNoteReminderProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateNoteReminderMutation,
    UpdateNoteReminderVariables,
    UpdateNoteReminderProps<TChildProps>
  >(UpdateNoteReminderDocument, operationOptions);
}
export const CreateCustomerStarDocument = gql`
  mutation createCustomerStar($input: CreateCustomerStarInput!) {
    createCustomerStar(input: $input) {
      customerStar {
        userId
        createdAt
      }
    }
  }
`;
export class CreateCustomerStarComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateCustomerStarMutation,
      CreateCustomerStarVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateCustomerStarMutation,
        CreateCustomerStarVariables
      >
        mutation={CreateCustomerStarDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateCustomerStarProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    CreateCustomerStarMutation,
    CreateCustomerStarVariables
  >
> &
  TChildProps;
export type CreateCustomerStarMutationFn = ReactApollo.MutationFn<
  CreateCustomerStarMutation,
  CreateCustomerStarVariables
>;
export function CreateCustomerStarHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateCustomerStarMutation,
        CreateCustomerStarVariables,
        CreateCustomerStarProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateCustomerStarMutation,
    CreateCustomerStarVariables,
    CreateCustomerStarProps<TChildProps>
  >(CreateCustomerStarDocument, operationOptions);
}
export const DeleteCustomerStarDocument = gql`
  mutation deleteCustomerStar($input: DeleteCustomerStarInput!) {
    deleteCustomerStar(input: $input) {
      wasSuccessful
    }
  }
`;
export class DeleteCustomerStarComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      DeleteCustomerStarMutation,
      DeleteCustomerStarVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        DeleteCustomerStarMutation,
        DeleteCustomerStarVariables
      >
        mutation={DeleteCustomerStarDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteCustomerStarProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    DeleteCustomerStarMutation,
    DeleteCustomerStarVariables
  >
> &
  TChildProps;
export type DeleteCustomerStarMutationFn = ReactApollo.MutationFn<
  DeleteCustomerStarMutation,
  DeleteCustomerStarVariables
>;
export function DeleteCustomerStarHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteCustomerStarMutation,
        DeleteCustomerStarVariables,
        DeleteCustomerStarProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteCustomerStarMutation,
    DeleteCustomerStarVariables,
    DeleteCustomerStarProps<TChildProps>
  >(DeleteCustomerStarDocument, operationOptions);
}
export const GetUserForUserSelectDocument = gql`
  query GetUserForUserSelect($userId: Int!) {
    user(id: $userId) {
      id
      fullName
    }
  }
`;
export class GetUserForUserSelectComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetUserForUserSelectQuery,
      GetUserForUserSelectVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetUserForUserSelectQuery,
        GetUserForUserSelectVariables
      >
        query={GetUserForUserSelectDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetUserForUserSelectProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    GetUserForUserSelectQuery,
    GetUserForUserSelectVariables
  >
> &
  TChildProps;
export function GetUserForUserSelectHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetUserForUserSelectQuery,
        GetUserForUserSelectVariables,
        GetUserForUserSelectProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetUserForUserSelectQuery,
    GetUserForUserSelectVariables,
    GetUserForUserSelectProps<TChildProps>
  >(GetUserForUserSelectDocument, operationOptions);
}
export const GetUsersForUserSelectDocument = gql`
  query getUsersForUserSelect($search: String, $roles: [String]) {
    users(search: $search, roles: $roles, isActive: true) {
      items {
        id
        fullName
        email
        profileImageUrl
      }
    }
  }
`;
export class GetUsersForUserSelectComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      GetUsersForUserSelectQuery,
      GetUsersForUserSelectVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        GetUsersForUserSelectQuery,
        GetUsersForUserSelectVariables
      >
        query={GetUsersForUserSelectDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetUsersForUserSelectProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    GetUsersForUserSelectQuery,
    GetUsersForUserSelectVariables
  >
> &
  TChildProps;
export function GetUsersForUserSelectHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetUsersForUserSelectQuery,
        GetUsersForUserSelectVariables,
        GetUsersForUserSelectProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetUsersForUserSelectQuery,
    GetUsersForUserSelectVariables,
    GetUsersForUserSelectProps<TChildProps>
  >(GetUsersForUserSelectDocument, operationOptions);
}
export const CreateCustomerMwsCredentialRevisionDocument = gql`
  mutation CreateCustomerMwsCredentialRevision(
    $input: CreateCustomerMwsCredentialRevisionInput!
  ) {
    createCustomerMwsCredentialRevision(input: $input) {
      customerMwsCredential {
        id
      }
    }
  }
`;
export class CreateCustomerMwsCredentialRevisionComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateCustomerMwsCredentialRevisionMutation,
      CreateCustomerMwsCredentialRevisionVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateCustomerMwsCredentialRevisionMutation,
        CreateCustomerMwsCredentialRevisionVariables
      >
        mutation={CreateCustomerMwsCredentialRevisionDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateCustomerMwsCredentialRevisionProps<
  TChildProps = any
> = Partial<
  ReactApollo.MutateProps<
    CreateCustomerMwsCredentialRevisionMutation,
    CreateCustomerMwsCredentialRevisionVariables
  >
> &
  TChildProps;
export type CreateCustomerMwsCredentialRevisionMutationFn = ReactApollo.MutationFn<
  CreateCustomerMwsCredentialRevisionMutation,
  CreateCustomerMwsCredentialRevisionVariables
>;
export function CreateCustomerMwsCredentialRevisionHOC<
  TProps,
  TChildProps = any
>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateCustomerMwsCredentialRevisionMutation,
        CreateCustomerMwsCredentialRevisionVariables,
        CreateCustomerMwsCredentialRevisionProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateCustomerMwsCredentialRevisionMutation,
    CreateCustomerMwsCredentialRevisionVariables,
    CreateCustomerMwsCredentialRevisionProps<TChildProps>
  >(CreateCustomerMwsCredentialRevisionDocument, operationOptions);
}
export const CreateCustomerMwsCredentialDocument = gql`
  mutation CreateCustomerMwsCredential(
    $input: CreateCustomerMwsCredentialInput!
  ) {
    createCustomerMwsCredential(input: $input) {
      customerMwsCredential {
        id
      }
    }
  }
`;
export class CreateCustomerMwsCredentialComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateCustomerMwsCredentialMutation,
      CreateCustomerMwsCredentialVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateCustomerMwsCredentialMutation,
        CreateCustomerMwsCredentialVariables
      >
        mutation={CreateCustomerMwsCredentialDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateCustomerMwsCredentialProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    CreateCustomerMwsCredentialMutation,
    CreateCustomerMwsCredentialVariables
  >
> &
  TChildProps;
export type CreateCustomerMwsCredentialMutationFn = ReactApollo.MutationFn<
  CreateCustomerMwsCredentialMutation,
  CreateCustomerMwsCredentialVariables
>;
export function CreateCustomerMwsCredentialHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateCustomerMwsCredentialMutation,
        CreateCustomerMwsCredentialVariables,
        CreateCustomerMwsCredentialProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateCustomerMwsCredentialMutation,
    CreateCustomerMwsCredentialVariables,
    CreateCustomerMwsCredentialProps<TChildProps>
  >(CreateCustomerMwsCredentialDocument, operationOptions);
}
export const DeleteCustomerMwsCredentialDocument = gql`
  mutation DeleteCustomerMwsCredential(
    $input: DeleteCustomerMwsCredentialInput!
  ) {
    deleteCustomerMwsCredential(input: $input) {
      wasSuccessful
    }
  }
`;
export class DeleteCustomerMwsCredentialComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      DeleteCustomerMwsCredentialMutation,
      DeleteCustomerMwsCredentialVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        DeleteCustomerMwsCredentialMutation,
        DeleteCustomerMwsCredentialVariables
      >
        mutation={DeleteCustomerMwsCredentialDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteCustomerMwsCredentialProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    DeleteCustomerMwsCredentialMutation,
    DeleteCustomerMwsCredentialVariables
  >
> &
  TChildProps;
export type DeleteCustomerMwsCredentialMutationFn = ReactApollo.MutationFn<
  DeleteCustomerMwsCredentialMutation,
  DeleteCustomerMwsCredentialVariables
>;
export function DeleteCustomerMwsCredentialHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteCustomerMwsCredentialMutation,
        DeleteCustomerMwsCredentialVariables,
        DeleteCustomerMwsCredentialProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteCustomerMwsCredentialMutation,
    DeleteCustomerMwsCredentialVariables,
    DeleteCustomerMwsCredentialProps<TChildProps>
  >(DeleteCustomerMwsCredentialDocument, operationOptions);
}
export const SelectCustomerMwsCredentialsDocument = gql`
  query SelectCustomerMwsCredentials($customerId: Int!) {
    customer(customerId: $customerId) {
      id
      customerMwsCredentials {
        id
        name
        sellerId
        authToken
        isPrimary
        invalidAt
      }
    }
  }
`;
export class SelectCustomerMwsCredentialsComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      SelectCustomerMwsCredentialsQuery,
      SelectCustomerMwsCredentialsVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        SelectCustomerMwsCredentialsQuery,
        SelectCustomerMwsCredentialsVariables
      >
        query={SelectCustomerMwsCredentialsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SelectCustomerMwsCredentialsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    SelectCustomerMwsCredentialsQuery,
    SelectCustomerMwsCredentialsVariables
  >
> &
  TChildProps;
export function SelectCustomerMwsCredentialsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SelectCustomerMwsCredentialsQuery,
        SelectCustomerMwsCredentialsVariables,
        SelectCustomerMwsCredentialsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SelectCustomerMwsCredentialsQuery,
    SelectCustomerMwsCredentialsVariables,
    SelectCustomerMwsCredentialsProps<TChildProps>
  >(SelectCustomerMwsCredentialsDocument, operationOptions);
}
export const CreateCustomerAmazonAccountRevisionDocument = gql`
  mutation CreateCustomerAmazonAccountRevision(
    $input: CreateCustomerAmazonAccountRevisionInput!
  ) {
    createCustomerAmazonAccountRevision(input: $input) {
      customerAmazonAccount {
        id
      }
    }
  }
`;
export class CreateCustomerAmazonAccountRevisionComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateCustomerAmazonAccountRevisionMutation,
      CreateCustomerAmazonAccountRevisionVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateCustomerAmazonAccountRevisionMutation,
        CreateCustomerAmazonAccountRevisionVariables
      >
        mutation={CreateCustomerAmazonAccountRevisionDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateCustomerAmazonAccountRevisionProps<
  TChildProps = any
> = Partial<
  ReactApollo.MutateProps<
    CreateCustomerAmazonAccountRevisionMutation,
    CreateCustomerAmazonAccountRevisionVariables
  >
> &
  TChildProps;
export type CreateCustomerAmazonAccountRevisionMutationFn = ReactApollo.MutationFn<
  CreateCustomerAmazonAccountRevisionMutation,
  CreateCustomerAmazonAccountRevisionVariables
>;
export function CreateCustomerAmazonAccountRevisionHOC<
  TProps,
  TChildProps = any
>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateCustomerAmazonAccountRevisionMutation,
        CreateCustomerAmazonAccountRevisionVariables,
        CreateCustomerAmazonAccountRevisionProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateCustomerAmazonAccountRevisionMutation,
    CreateCustomerAmazonAccountRevisionVariables,
    CreateCustomerAmazonAccountRevisionProps<TChildProps>
  >(CreateCustomerAmazonAccountRevisionDocument, operationOptions);
}
export const CreateCustomerAmazonAccountDocument = gql`
  mutation CreateCustomerAmazonAccount(
    $input: CreateCustomerAmazonAccountInput!
  ) {
    createCustomerAmazonAccount(input: $input) {
      customerAmazonAccount {
        id
      }
    }
  }
`;
export class CreateCustomerAmazonAccountComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateCustomerAmazonAccountMutation,
      CreateCustomerAmazonAccountVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateCustomerAmazonAccountMutation,
        CreateCustomerAmazonAccountVariables
      >
        mutation={CreateCustomerAmazonAccountDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateCustomerAmazonAccountProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    CreateCustomerAmazonAccountMutation,
    CreateCustomerAmazonAccountVariables
  >
> &
  TChildProps;
export type CreateCustomerAmazonAccountMutationFn = ReactApollo.MutationFn<
  CreateCustomerAmazonAccountMutation,
  CreateCustomerAmazonAccountVariables
>;
export function CreateCustomerAmazonAccountHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateCustomerAmazonAccountMutation,
        CreateCustomerAmazonAccountVariables,
        CreateCustomerAmazonAccountProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateCustomerAmazonAccountMutation,
    CreateCustomerAmazonAccountVariables,
    CreateCustomerAmazonAccountProps<TChildProps>
  >(CreateCustomerAmazonAccountDocument, operationOptions);
}
export const SelectCustomerAmazonAccountDocument = gql`
  query SelectCustomerAmazonAccount($customerId: Int!) {
    customer(customerId: $customerId) {
      id
      customerAmazonAccount {
        id
        email
        password
        totpCode
      }
    }
  }
`;
export class SelectCustomerAmazonAccountComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      SelectCustomerAmazonAccountQuery,
      SelectCustomerAmazonAccountVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        SelectCustomerAmazonAccountQuery,
        SelectCustomerAmazonAccountVariables
      >
        query={SelectCustomerAmazonAccountDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SelectCustomerAmazonAccountProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    SelectCustomerAmazonAccountQuery,
    SelectCustomerAmazonAccountVariables
  >
> &
  TChildProps;
export function SelectCustomerAmazonAccountHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SelectCustomerAmazonAccountQuery,
        SelectCustomerAmazonAccountVariables,
        SelectCustomerAmazonAccountProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SelectCustomerAmazonAccountQuery,
    SelectCustomerAmazonAccountVariables,
    SelectCustomerAmazonAccountProps<TChildProps>
  >(SelectCustomerAmazonAccountDocument, operationOptions);
}
export const SelectCustomerWorkspaceDocument = gql`
  query selectCustomerWorkspace($customerId: Int!) {
    customer(customerId: $customerId) {
      id
      name
      url
      createdAt
      contactName
      contactPhone
      contactEmail
      additionalNames
      accountManagerUserId
      location
      leadSource
      hasSellerAccount
      hasVendorAccount
      pipedriveDealId
      happinessLevel
      maxEndDate
      createdByUser {
        id
        fullName
      }
      accountManagerUser {
        id
        fullName
      }
    }
  }
`;
export class SelectCustomerWorkspaceComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      SelectCustomerWorkspaceQuery,
      SelectCustomerWorkspaceVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        SelectCustomerWorkspaceQuery,
        SelectCustomerWorkspaceVariables
      >
        query={SelectCustomerWorkspaceDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SelectCustomerWorkspaceProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    SelectCustomerWorkspaceQuery,
    SelectCustomerWorkspaceVariables
  >
> &
  TChildProps;
export function SelectCustomerWorkspaceHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SelectCustomerWorkspaceQuery,
        SelectCustomerWorkspaceVariables,
        SelectCustomerWorkspaceProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SelectCustomerWorkspaceQuery,
    SelectCustomerWorkspaceVariables,
    SelectCustomerWorkspaceProps<TChildProps>
  >(SelectCustomerWorkspaceDocument, operationOptions);
}
export const UpdateCustomerDocument = gql`
  mutation updateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      customer {
        id
      }
    }
  }
`;
export class UpdateCustomerComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<UpdateCustomerMutation, UpdateCustomerVariables>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<UpdateCustomerMutation, UpdateCustomerVariables>
        mutation={UpdateCustomerDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateCustomerProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<UpdateCustomerMutation, UpdateCustomerVariables>
> &
  TChildProps;
export type UpdateCustomerMutationFn = ReactApollo.MutationFn<
  UpdateCustomerMutation,
  UpdateCustomerVariables
>;
export function UpdateCustomerHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateCustomerMutation,
        UpdateCustomerVariables,
        UpdateCustomerProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateCustomerMutation,
    UpdateCustomerVariables,
    UpdateCustomerProps<TChildProps>
  >(UpdateCustomerDocument, operationOptions);
}
export const CreateCustomerFileDocument = gql`
  mutation createCustomerFile($input: CreateCustomerFileInput!) {
    createCustomerFile(input: $input) {
      customerFile {
        id
      }
      uploadUrl
    }
  }
`;
export class CreateCustomerFileComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateCustomerFileMutation,
      CreateCustomerFileVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateCustomerFileMutation,
        CreateCustomerFileVariables
      >
        mutation={CreateCustomerFileDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateCustomerFileProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    CreateCustomerFileMutation,
    CreateCustomerFileVariables
  >
> &
  TChildProps;
export type CreateCustomerFileMutationFn = ReactApollo.MutationFn<
  CreateCustomerFileMutation,
  CreateCustomerFileVariables
>;
export function CreateCustomerFileHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateCustomerFileMutation,
        CreateCustomerFileVariables,
        CreateCustomerFileProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateCustomerFileMutation,
    CreateCustomerFileVariables,
    CreateCustomerFileProps<TChildProps>
  >(CreateCustomerFileDocument, operationOptions);
}
export const CustomerFilesDocument = gql`
  query customerFiles($customerId: Int!) {
    customer(customerId: $customerId) {
      id
      customerFiles {
        items {
          id
          file {
            id
            name
            url
            createdAt
            sizeInBytes
            mimeType
          }
        }
      }
    }
  }
`;
export class CustomerFilesComponent extends React.Component<
  Partial<ReactApollo.QueryProps<CustomerFilesQuery, CustomerFilesVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<CustomerFilesQuery, CustomerFilesVariables>
        query={CustomerFilesDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CustomerFilesProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<CustomerFilesQuery, CustomerFilesVariables>
> &
  TChildProps;
export function CustomerFilesHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CustomerFilesQuery,
        CustomerFilesVariables,
        CustomerFilesProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CustomerFilesQuery,
    CustomerFilesVariables,
    CustomerFilesProps<TChildProps>
  >(CustomerFilesDocument, operationOptions);
}
export const GetCustomerDocument = gql`
  query getCustomer($customerId: Int!) {
    customer(customerId: $customerId) {
      id
      name
      createdAt
      isStarred
    }
  }
`;
export class GetCustomerComponent extends React.Component<
  Partial<ReactApollo.QueryProps<GetCustomerQuery, GetCustomerVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<GetCustomerQuery, GetCustomerVariables>
        query={GetCustomerDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type GetCustomerProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<GetCustomerQuery, GetCustomerVariables>
> &
  TChildProps;
export function GetCustomerHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        GetCustomerQuery,
        GetCustomerVariables,
        GetCustomerProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    GetCustomerQuery,
    GetCustomerVariables,
    GetCustomerProps<TChildProps>
  >(GetCustomerDocument, operationOptions);
}
export const CreateStrategyTaskMutationDocument = gql`
  mutation createStrategyTaskMutation($input: CreateStrategyTaskInput!) {
    createStrategyTask(input: $input) {
      task {
        id
        title
        createdAt
      }
    }
  }
`;
export class CreateStrategyTaskMutationComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateStrategyTaskMutationMutation,
      CreateStrategyTaskMutationVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateStrategyTaskMutationMutation,
        CreateStrategyTaskMutationVariables
      >
        mutation={CreateStrategyTaskMutationDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateStrategyTaskMutationProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    CreateStrategyTaskMutationMutation,
    CreateStrategyTaskMutationVariables
  >
> &
  TChildProps;
export type CreateStrategyTaskMutationMutationFn = ReactApollo.MutationFn<
  CreateStrategyTaskMutationMutation,
  CreateStrategyTaskMutationVariables
>;
export function CreateStrategyTaskMutationHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateStrategyTaskMutationMutation,
        CreateStrategyTaskMutationVariables,
        CreateStrategyTaskMutationProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateStrategyTaskMutationMutation,
    CreateStrategyTaskMutationVariables,
    CreateStrategyTaskMutationProps<TChildProps>
  >(CreateStrategyTaskMutationDocument, operationOptions);
}
export const CustomerNotesDocument = gql`
  query customerNotes(
    $customerId: Int!
    $search: String
    $limit: Int
    $offset: Int
  ) {
    customer(customerId: $customerId) {
      id
      notes(search: $search, limit: $limit, offset: $offset) {
        pageInfo {
          hasPreviousPage
          hasNextPage
        }
        totalCount
        items {
          id
          userId
          content
          createdAt
          originalNoteId
          isLatest
          user {
            id
            fullName
          }
          noteReminders {
            id
            noteId
            content
            dueAt
            completedAt
          }
          isDeletable
        }
      }
    }
  }
`;
export class CustomerNotesComponent extends React.Component<
  Partial<ReactApollo.QueryProps<CustomerNotesQuery, CustomerNotesVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<CustomerNotesQuery, CustomerNotesVariables>
        query={CustomerNotesDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CustomerNotesProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<CustomerNotesQuery, CustomerNotesVariables>
> &
  TChildProps;
export function CustomerNotesHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CustomerNotesQuery,
        CustomerNotesVariables,
        CustomerNotesProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CustomerNotesQuery,
    CustomerNotesVariables,
    CustomerNotesProps<TChildProps>
  >(CustomerNotesDocument, operationOptions);
}
export const StrategyTasksDocument = gql`
  query strategyTasks($customerId: Int!) {
    viewer {
      id
      fullName
    }
    customer(customerId: $customerId) {
      id
      customerProducts(productId: "am-strategy", statusId: "scheduled") {
        id
        startDate
      }
      activeStrategyCustomerProduct {
        id
        statusId
        tasks(statusIds: ["assigned", "available", "blocked", "scheduled"]) {
          id
          title
          statusId
          dueDate
          isDeletable
          assignedUser {
            id
            fullName
            profileImageUrl
          }
          taskType {
            id
            name
          }
        }
      }
    }
  }
`;
export class StrategyTasksComponent extends React.Component<
  Partial<ReactApollo.QueryProps<StrategyTasksQuery, StrategyTasksVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<StrategyTasksQuery, StrategyTasksVariables>
        query={StrategyTasksDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type StrategyTasksProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<StrategyTasksQuery, StrategyTasksVariables>
> &
  TChildProps;
export function StrategyTasksHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        StrategyTasksQuery,
        StrategyTasksVariables,
        StrategyTasksProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    StrategyTasksQuery,
    StrategyTasksVariables,
    StrategyTasksProps<TChildProps>
  >(StrategyTasksDocument, operationOptions);
}
export const UpdateStrategyTaskDocument = gql`
  mutation updateStrategyTask($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      task {
        id
        title
        statusId
      }
    }
  }
`;
export class UpdateStrategyTaskComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      UpdateStrategyTaskMutation,
      UpdateStrategyTaskVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        UpdateStrategyTaskMutation,
        UpdateStrategyTaskVariables
      >
        mutation={UpdateStrategyTaskDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateStrategyTaskProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    UpdateStrategyTaskMutation,
    UpdateStrategyTaskVariables
  >
> &
  TChildProps;
export type UpdateStrategyTaskMutationFn = ReactApollo.MutationFn<
  UpdateStrategyTaskMutation,
  UpdateStrategyTaskVariables
>;
export function UpdateStrategyTaskHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateStrategyTaskMutation,
        UpdateStrategyTaskVariables,
        UpdateStrategyTaskProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateStrategyTaskMutation,
    UpdateStrategyTaskVariables,
    UpdateStrategyTaskProps<TChildProps>
  >(UpdateStrategyTaskDocument, operationOptions);
}
export const AddPhotographyDocument = gql`
  query addPhotography($customerId: Int!) {
    customer(customerId: $customerId) {
      id
      customerSkus(onlyTopLevel: true) {
        items {
          id
          sku
          title
        }
      }
    }
    productCategories {
      items {
        id
        name
        products {
          id
          name
          currentProductPrice {
            id
            amount
            clientDiscountPercentage
            maxAdditionalDiscountPercentage
          }
        }
      }
    }
  }
`;
export class AddPhotographyComponent extends React.Component<
  Partial<ReactApollo.QueryProps<AddPhotographyQuery, AddPhotographyVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<AddPhotographyQuery, AddPhotographyVariables>
        query={AddPhotographyDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type AddPhotographyProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<AddPhotographyQuery, AddPhotographyVariables>
> &
  TChildProps;
export function AddPhotographyHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        AddPhotographyQuery,
        AddPhotographyVariables,
        AddPhotographyProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    AddPhotographyQuery,
    AddPhotographyVariables,
    AddPhotographyProps<TChildProps>
  >(AddPhotographyDocument, operationOptions);
}
export const AddPhotographyChildCustomerSkusDocument = gql`
  query addPhotographyChildCustomerSkus($customerSkuId: Int!) {
    customerSku(customerSkuId: $customerSkuId) {
      id
      sku
      title
      children {
        id
        sku
        title
      }
    }
  }
`;
export class AddPhotographyChildCustomerSkusComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      AddPhotographyChildCustomerSkusQuery,
      AddPhotographyChildCustomerSkusVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        AddPhotographyChildCustomerSkusQuery,
        AddPhotographyChildCustomerSkusVariables
      >
        query={AddPhotographyChildCustomerSkusDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type AddPhotographyChildCustomerSkusProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    AddPhotographyChildCustomerSkusQuery,
    AddPhotographyChildCustomerSkusVariables
  >
> &
  TChildProps;
export function AddPhotographyChildCustomerSkusHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        AddPhotographyChildCustomerSkusQuery,
        AddPhotographyChildCustomerSkusVariables,
        AddPhotographyChildCustomerSkusProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    AddPhotographyChildCustomerSkusQuery,
    AddPhotographyChildCustomerSkusVariables,
    AddPhotographyChildCustomerSkusProps<TChildProps>
  >(AddPhotographyChildCustomerSkusDocument, operationOptions);
}
export const AddPhotographyCreateCustomerProductBatchDocument = gql`
  mutation addPhotographyCreateCustomerProductBatch(
    $input: CreateCustomerProductBatchInput!
  ) {
    createCustomerProductBatch(input: $input) {
      customerProductBatch {
        id
      }
    }
  }
`;
export class AddPhotographyCreateCustomerProductBatchComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      AddPhotographyCreateCustomerProductBatchMutation,
      AddPhotographyCreateCustomerProductBatchVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        AddPhotographyCreateCustomerProductBatchMutation,
        AddPhotographyCreateCustomerProductBatchVariables
      >
        mutation={AddPhotographyCreateCustomerProductBatchDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type AddPhotographyCreateCustomerProductBatchProps<
  TChildProps = any
> = Partial<
  ReactApollo.MutateProps<
    AddPhotographyCreateCustomerProductBatchMutation,
    AddPhotographyCreateCustomerProductBatchVariables
  >
> &
  TChildProps;
export type AddPhotographyCreateCustomerProductBatchMutationFn = ReactApollo.MutationFn<
  AddPhotographyCreateCustomerProductBatchMutation,
  AddPhotographyCreateCustomerProductBatchVariables
>;
export function AddPhotographyCreateCustomerProductBatchHOC<
  TProps,
  TChildProps = any
>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        AddPhotographyCreateCustomerProductBatchMutation,
        AddPhotographyCreateCustomerProductBatchVariables,
        AddPhotographyCreateCustomerProductBatchProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    AddPhotographyCreateCustomerProductBatchMutation,
    AddPhotographyCreateCustomerProductBatchVariables,
    AddPhotographyCreateCustomerProductBatchProps<TChildProps>
  >(AddPhotographyCreateCustomerProductBatchDocument, operationOptions);
}
export const AddPhotographyDialogCustomerProductItemsDocument = gql`
  query addPhotographyDialogCustomerProductItems(
    $customerId: Int!
    $customerSkuIds: [Int]
    $productIds: [String]
  ) {
    customer(customerId: $customerId) {
      id
      customerSkus(ids: $customerSkuIds) {
        items {
          id
          sku
          parent {
            id
            sku
          }
        }
      }
    }
    products(ids: $productIds) {
      items {
        id
        name
        currentProductPrice {
          id
          amount
          clientDiscountPercentage
          maxAdditionalDiscountPercentage
        }
      }
    }
  }
`;
export class AddPhotographyDialogCustomerProductItemsComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      AddPhotographyDialogCustomerProductItemsQuery,
      AddPhotographyDialogCustomerProductItemsVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        AddPhotographyDialogCustomerProductItemsQuery,
        AddPhotographyDialogCustomerProductItemsVariables
      >
        query={AddPhotographyDialogCustomerProductItemsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type AddPhotographyDialogCustomerProductItemsProps<
  TChildProps = any
> = Partial<
  ReactApollo.DataProps<
    AddPhotographyDialogCustomerProductItemsQuery,
    AddPhotographyDialogCustomerProductItemsVariables
  >
> &
  TChildProps;
export function AddPhotographyDialogCustomerProductItemsHOC<
  TProps,
  TChildProps = any
>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        AddPhotographyDialogCustomerProductItemsQuery,
        AddPhotographyDialogCustomerProductItemsVariables,
        AddPhotographyDialogCustomerProductItemsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    AddPhotographyDialogCustomerProductItemsQuery,
    AddPhotographyDialogCustomerProductItemsVariables,
    AddPhotographyDialogCustomerProductItemsProps<TChildProps>
  >(AddPhotographyDialogCustomerProductItemsDocument, operationOptions);
}
export const AddProductDocument = gql`
  query addProduct($customerId: Int!, $productId: String!) {
    customer(customerId: $customerId) {
      id
      customerSkus(onlyTopLevel: true) {
        items {
          id
          sku
          title
        }
      }
    }
    product(productId: $productId) {
      id
      name
      requiresSku
      currentProductPrice {
        id
        amount
        clientDiscountPercentage
        maxAdditionalDiscountPercentage
      }
    }
  }
`;
export class AddProductComponent extends React.Component<
  Partial<ReactApollo.QueryProps<AddProductQuery, AddProductVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<AddProductQuery, AddProductVariables>
        query={AddProductDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type AddProductProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<AddProductQuery, AddProductVariables>
> &
  TChildProps;
export function AddProductHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        AddProductQuery,
        AddProductVariables,
        AddProductProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    AddProductQuery,
    AddProductVariables,
    AddProductProps<TChildProps>
  >(AddProductDocument, operationOptions);
}
export const AddContentCreateCustomerProductDocument = gql`
  mutation addContentCreateCustomerProduct(
    $input: CreateCustomerProductInput!
  ) {
    createCustomerProduct(input: $input) {
      customerProduct {
        id
      }
    }
  }
`;
export class AddContentCreateCustomerProductComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      AddContentCreateCustomerProductMutation,
      AddContentCreateCustomerProductVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        AddContentCreateCustomerProductMutation,
        AddContentCreateCustomerProductVariables
      >
        mutation={AddContentCreateCustomerProductDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type AddContentCreateCustomerProductProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    AddContentCreateCustomerProductMutation,
    AddContentCreateCustomerProductVariables
  >
> &
  TChildProps;
export type AddContentCreateCustomerProductMutationFn = ReactApollo.MutationFn<
  AddContentCreateCustomerProductMutation,
  AddContentCreateCustomerProductVariables
>;
export function AddContentCreateCustomerProductHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        AddContentCreateCustomerProductMutation,
        AddContentCreateCustomerProductVariables,
        AddContentCreateCustomerProductProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    AddContentCreateCustomerProductMutation,
    AddContentCreateCustomerProductVariables,
    AddContentCreateCustomerProductProps<TChildProps>
  >(AddContentCreateCustomerProductDocument, operationOptions);
}
export const SkuSelectorDocument = gql`
  query skuSelector(
    $customerId: Int!
    $selectedCustomerSkuId: Int!
    $hasNotSelectedCustomerSku: Boolean!
  ) {
    customer(customerId: $customerId) {
      id
      customerSkus(onlyTopLevel: true) {
        items {
          id
          sku
          asin
          title
        }
      }
    }
    customerSku(customerSkuId: $selectedCustomerSkuId)
      @skip(if: $hasNotSelectedCustomerSku) {
      id
      sku
      title
      children {
        id
        sku
        title
      }
    }
  }
`;
export class SkuSelectorComponent extends React.Component<
  Partial<ReactApollo.QueryProps<SkuSelectorQuery, SkuSelectorVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<SkuSelectorQuery, SkuSelectorVariables>
        query={SkuSelectorDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SkuSelectorProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<SkuSelectorQuery, SkuSelectorVariables>
> &
  TChildProps;
export function SkuSelectorHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SkuSelectorQuery,
        SkuSelectorVariables,
        SkuSelectorProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SkuSelectorQuery,
    SkuSelectorVariables,
    SkuSelectorProps<TChildProps>
  >(SkuSelectorDocument, operationOptions);
}
export const CustomerContractDocument = gql`
  query CustomerContract($customerContractId: Int!) {
    customerContract(customerContractId: $customerContractId) {
      id
      salesUser {
        id
        fullName
      }
      startDate
      createdAt
      statusId
      customerProjects {
        id
        name
        startDate
        amount
      }
      isDeletable
    }
  }
`;
export class CustomerContractComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<CustomerContractQuery, CustomerContractVariables>
  >
> {
  render() {
    return (
      <ReactApollo.Query<CustomerContractQuery, CustomerContractVariables>
        query={CustomerContractDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CustomerContractProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<CustomerContractQuery, CustomerContractVariables>
> &
  TChildProps;
export function CustomerContractHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CustomerContractQuery,
        CustomerContractVariables,
        CustomerContractProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CustomerContractQuery,
    CustomerContractVariables,
    CustomerContractProps<TChildProps>
  >(CustomerContractDocument, operationOptions);
}
export const DeleteCustomerContractDocument = gql`
  mutation DeleteCustomerContract($input: DeleteCustomerContractInput!) {
    deleteCustomerContract(input: $input) {
      wasSuccessful
    }
  }
`;
export class DeleteCustomerContractComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      DeleteCustomerContractMutation,
      DeleteCustomerContractVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        DeleteCustomerContractMutation,
        DeleteCustomerContractVariables
      >
        mutation={DeleteCustomerContractDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteCustomerContractProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    DeleteCustomerContractMutation,
    DeleteCustomerContractVariables
  >
> &
  TChildProps;
export type DeleteCustomerContractMutationFn = ReactApollo.MutationFn<
  DeleteCustomerContractMutation,
  DeleteCustomerContractVariables
>;
export function DeleteCustomerContractHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteCustomerContractMutation,
        DeleteCustomerContractVariables,
        DeleteCustomerContractProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteCustomerContractMutation,
    DeleteCustomerContractVariables,
    DeleteCustomerContractProps<TChildProps>
  >(DeleteCustomerContractDocument, operationOptions);
}
export const RoadmapCustomerProductBatchNotesDocument = gql`
  query roadmapCustomerProductBatchNotes(
    $customerProductBatchId: Int!
    $search: String
    $limit: Int
    $offset: Int
  ) {
    customerProductBatch(customerProductBatchId: $customerProductBatchId) {
      id
      notes(search: $search, limit: $limit, offset: $offset) {
        pageInfo {
          hasPreviousPage
          hasNextPage
        }
        totalCount
        items {
          id
          userId
          content
          createdAt
          originalNoteId
          isLatest
          user {
            id
            fullName
          }
          noteReminders {
            id
            noteId
            content
            dueAt
            completedAt
          }
          isDeletable
        }
      }
    }
  }
`;
export class RoadmapCustomerProductBatchNotesComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      RoadmapCustomerProductBatchNotesQuery,
      RoadmapCustomerProductBatchNotesVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        RoadmapCustomerProductBatchNotesQuery,
        RoadmapCustomerProductBatchNotesVariables
      >
        query={RoadmapCustomerProductBatchNotesDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type RoadmapCustomerProductBatchNotesProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    RoadmapCustomerProductBatchNotesQuery,
    RoadmapCustomerProductBatchNotesVariables
  >
> &
  TChildProps;
export function RoadmapCustomerProductBatchNotesHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        RoadmapCustomerProductBatchNotesQuery,
        RoadmapCustomerProductBatchNotesVariables,
        RoadmapCustomerProductBatchNotesProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    RoadmapCustomerProductBatchNotesQuery,
    RoadmapCustomerProductBatchNotesVariables,
    RoadmapCustomerProductBatchNotesProps<TChildProps>
  >(RoadmapCustomerProductBatchNotesDocument, operationOptions);
}
export const UpdateCustomerProductDocument = gql`
  mutation updateCustomerProduct($input: UpdateCustomerProductInput!) {
    updateCustomerProduct(input: $input) {
      customerProduct {
        id
      }
    }
  }
`;
export class UpdateCustomerProductComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      UpdateCustomerProductMutation,
      UpdateCustomerProductVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        UpdateCustomerProductMutation,
        UpdateCustomerProductVariables
      >
        mutation={UpdateCustomerProductDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateCustomerProductProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    UpdateCustomerProductMutation,
    UpdateCustomerProductVariables
  >
> &
  TChildProps;
export type UpdateCustomerProductMutationFn = ReactApollo.MutationFn<
  UpdateCustomerProductMutation,
  UpdateCustomerProductVariables
>;
export function UpdateCustomerProductHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateCustomerProductMutation,
        UpdateCustomerProductVariables,
        UpdateCustomerProductProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateCustomerProductMutation,
    UpdateCustomerProductVariables,
    UpdateCustomerProductProps<TChildProps>
  >(UpdateCustomerProductDocument, operationOptions);
}
export const RoadmapCustomerProductNotesDocument = gql`
  query roadmapCustomerProductNotes(
    $customerProductId: Int!
    $search: String
    $limit: Int
    $offset: Int
  ) {
    customerProduct(customerProductId: $customerProductId) {
      id
      notes(search: $search, limit: $limit, offset: $offset) {
        pageInfo {
          hasPreviousPage
          hasNextPage
        }
        totalCount
        items {
          id
          userId
          content
          createdAt
          originalNoteId
          isLatest
          user {
            id
            fullName
          }
          noteReminders {
            id
            noteId
            content
            dueAt
            completedAt
          }
          isDeletable
        }
      }
    }
  }
`;
export class RoadmapCustomerProductNotesComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      RoadmapCustomerProductNotesQuery,
      RoadmapCustomerProductNotesVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        RoadmapCustomerProductNotesQuery,
        RoadmapCustomerProductNotesVariables
      >
        query={RoadmapCustomerProductNotesDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type RoadmapCustomerProductNotesProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    RoadmapCustomerProductNotesQuery,
    RoadmapCustomerProductNotesVariables
  >
> &
  TChildProps;
export function RoadmapCustomerProductNotesHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        RoadmapCustomerProductNotesQuery,
        RoadmapCustomerProductNotesVariables,
        RoadmapCustomerProductNotesProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    RoadmapCustomerProductNotesQuery,
    RoadmapCustomerProductNotesVariables,
    RoadmapCustomerProductNotesProps<TChildProps>
  >(RoadmapCustomerProductNotesDocument, operationOptions);
}
export const DeleteCustomerProductBatchDocument = gql`
  mutation deleteCustomerProductBatch(
    $input: DeleteCustomerProductBatchInput!
  ) {
    deleteCustomerProductBatch(input: $input) {
      wasSuccessful
    }
  }
`;
export class DeleteCustomerProductBatchComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      DeleteCustomerProductBatchMutation,
      DeleteCustomerProductBatchVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        DeleteCustomerProductBatchMutation,
        DeleteCustomerProductBatchVariables
      >
        mutation={DeleteCustomerProductBatchDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteCustomerProductBatchProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    DeleteCustomerProductBatchMutation,
    DeleteCustomerProductBatchVariables
  >
> &
  TChildProps;
export type DeleteCustomerProductBatchMutationFn = ReactApollo.MutationFn<
  DeleteCustomerProductBatchMutation,
  DeleteCustomerProductBatchVariables
>;
export function DeleteCustomerProductBatchHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteCustomerProductBatchMutation,
        DeleteCustomerProductBatchVariables,
        DeleteCustomerProductBatchProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteCustomerProductBatchMutation,
    DeleteCustomerProductBatchVariables,
    DeleteCustomerProductBatchProps<TChildProps>
  >(DeleteCustomerProductBatchDocument, operationOptions);
}
export const DeleteCustomerProductDocument = gql`
  mutation deleteCustomerProduct($input: DeleteCustomerProductInput!) {
    deleteCustomerProduct(input: $input) {
      wasSuccessful
    }
  }
`;
export class DeleteCustomerProductComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      DeleteCustomerProductMutation,
      DeleteCustomerProductVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        DeleteCustomerProductMutation,
        DeleteCustomerProductVariables
      >
        mutation={DeleteCustomerProductDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteCustomerProductProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    DeleteCustomerProductMutation,
    DeleteCustomerProductVariables
  >
> &
  TChildProps;
export type DeleteCustomerProductMutationFn = ReactApollo.MutationFn<
  DeleteCustomerProductMutation,
  DeleteCustomerProductVariables
>;
export function DeleteCustomerProductHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteCustomerProductMutation,
        DeleteCustomerProductVariables,
        DeleteCustomerProductProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteCustomerProductMutation,
    DeleteCustomerProductVariables,
    DeleteCustomerProductProps<TChildProps>
  >(DeleteCustomerProductDocument, operationOptions);
}
export const DeleteCustomerProjectDocument = gql`
  mutation DeleteCustomerProject($input: DeleteCustomerProjectInput!) {
    deleteCustomerProject(input: $input) {
      wasSuccessful
    }
  }
`;
export class DeleteCustomerProjectComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      DeleteCustomerProjectMutation,
      DeleteCustomerProjectVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        DeleteCustomerProjectMutation,
        DeleteCustomerProjectVariables
      >
        mutation={DeleteCustomerProjectDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteCustomerProjectProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    DeleteCustomerProjectMutation,
    DeleteCustomerProjectVariables
  >
> &
  TChildProps;
export type DeleteCustomerProjectMutationFn = ReactApollo.MutationFn<
  DeleteCustomerProjectMutation,
  DeleteCustomerProjectVariables
>;
export function DeleteCustomerProjectHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteCustomerProjectMutation,
        DeleteCustomerProjectVariables,
        DeleteCustomerProjectProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteCustomerProjectMutation,
    DeleteCustomerProjectVariables,
    DeleteCustomerProjectProps<TChildProps>
  >(DeleteCustomerProjectDocument, operationOptions);
}
export const UpdateCustomerProjectDocument = gql`
  mutation updateCustomerProject($input: UpdateCustomerProjectInput!) {
    updateCustomerProject(input: $input) {
      customerProject {
        id
      }
    }
  }
`;
export class UpdateCustomerProjectComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      UpdateCustomerProjectMutation,
      UpdateCustomerProjectVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        UpdateCustomerProjectMutation,
        UpdateCustomerProjectVariables
      >
        mutation={UpdateCustomerProjectDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateCustomerProjectProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    UpdateCustomerProjectMutation,
    UpdateCustomerProjectVariables
  >
> &
  TChildProps;
export type UpdateCustomerProjectMutationFn = ReactApollo.MutationFn<
  UpdateCustomerProjectMutation,
  UpdateCustomerProjectVariables
>;
export function UpdateCustomerProjectHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateCustomerProjectMutation,
        UpdateCustomerProjectVariables,
        UpdateCustomerProjectProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateCustomerProjectMutation,
    UpdateCustomerProjectVariables,
    UpdateCustomerProjectProps<TChildProps>
  >(UpdateCustomerProjectDocument, operationOptions);
}
export const RoadmapCustomerProjectNotesDocument = gql`
  query roadmapCustomerProjectNotes(
    $customerProjectId: Int!
    $search: String
    $limit: Int
    $offset: Int
  ) {
    customerProject(customerProjectId: $customerProjectId) {
      id
      notes(search: $search, limit: $limit, offset: $offset) {
        pageInfo {
          hasPreviousPage
          hasNextPage
        }
        totalCount
        items {
          id
          userId
          content
          createdAt
          originalNoteId
          isLatest
          user {
            id
            fullName
          }
          noteReminders {
            id
            noteId
            content
            dueAt
            completedAt
          }
          isDeletable
        }
      }
    }
  }
`;
export class RoadmapCustomerProjectNotesComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      RoadmapCustomerProjectNotesQuery,
      RoadmapCustomerProjectNotesVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        RoadmapCustomerProjectNotesQuery,
        RoadmapCustomerProjectNotesVariables
      >
        query={RoadmapCustomerProjectNotesDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type RoadmapCustomerProjectNotesProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    RoadmapCustomerProjectNotesQuery,
    RoadmapCustomerProjectNotesVariables
  >
> &
  TChildProps;
export function RoadmapCustomerProjectNotesHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        RoadmapCustomerProjectNotesQuery,
        RoadmapCustomerProjectNotesVariables,
        RoadmapCustomerProjectNotesProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    RoadmapCustomerProjectNotesQuery,
    RoadmapCustomerProjectNotesVariables,
    RoadmapCustomerProjectNotesProps<TChildProps>
  >(RoadmapCustomerProjectNotesDocument, operationOptions);
}
export const SelectRoadmapDocument = gql`
  query selectRoadmap($customerId: Int!) {
    customer(customerId: $customerId) {
      id
      name
      customerProjects {
        id
        name
        startDate
        createdAt
        amount
        customerId
        customerContractId
        isDeletable
        status {
          id
          name
        }
        notes {
          totalCount
        }
        customerProductBatches {
          id
          startDate
          isDeletable
          customerProducts {
            id
            startDate
            amount
            workflowStatus
            dueDate
            isDeletable
            status {
              id
              name
            }
            product {
              id
              name
            }
            customerSku {
              id
              sku
              asin
              title
            }
          }
          notes {
            totalCount
          }
        }
        customerProducts {
          id
          startDate
          amount
          workflowStatus
          dueDate
          isDeletable
          customerProject {
            id
            startDate
          }
          customerProductBatchId
          status {
            id
            name
          }
          product {
            id
            name
          }
          customerSku {
            id
            sku
            asin
            title
          }
          notes {
            totalCount
          }
        }
      }
    }
  }
`;
export class SelectRoadmapComponent extends React.Component<
  Partial<ReactApollo.QueryProps<SelectRoadmapQuery, SelectRoadmapVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<SelectRoadmapQuery, SelectRoadmapVariables>
        query={SelectRoadmapDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SelectRoadmapProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<SelectRoadmapQuery, SelectRoadmapVariables>
> &
  TChildProps;
export function SelectRoadmapHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SelectRoadmapQuery,
        SelectRoadmapVariables,
        SelectRoadmapProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SelectRoadmapQuery,
    SelectRoadmapVariables,
    SelectRoadmapProps<TChildProps>
  >(SelectRoadmapDocument, operationOptions);
}
export const CreateCustomerSkuDocument = gql`
  mutation createCustomerSku($input: CreateCustomerSkuInput!) {
    createCustomerSku(input: $input) {
      customerSku {
        sku
      }
    }
  }
`;
export class CreateCustomerSkuComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateCustomerSkuMutation,
      CreateCustomerSkuVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateCustomerSkuMutation,
        CreateCustomerSkuVariables
      >
        mutation={CreateCustomerSkuDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateCustomerSkuProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<CreateCustomerSkuMutation, CreateCustomerSkuVariables>
> &
  TChildProps;
export type CreateCustomerSkuMutationFn = ReactApollo.MutationFn<
  CreateCustomerSkuMutation,
  CreateCustomerSkuVariables
>;
export function CreateCustomerSkuHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateCustomerSkuMutation,
        CreateCustomerSkuVariables,
        CreateCustomerSkuProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateCustomerSkuMutation,
    CreateCustomerSkuVariables,
    CreateCustomerSkuProps<TChildProps>
  >(CreateCustomerSkuDocument, operationOptions);
}
export const CreateCustomerSkuSyncRequestDocument = gql`
  mutation createCustomerSkuSyncRequest(
    $input: CreateCustomerSkuSyncRequestInput!
  ) {
    createCustomerSkuSyncRequest(input: $input) {
      customerSkuSyncRequest {
        id
      }
    }
  }
`;
export class CreateCustomerSkuSyncRequestComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateCustomerSkuSyncRequestMutation,
      CreateCustomerSkuSyncRequestVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateCustomerSkuSyncRequestMutation,
        CreateCustomerSkuSyncRequestVariables
      >
        mutation={CreateCustomerSkuSyncRequestDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateCustomerSkuSyncRequestProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    CreateCustomerSkuSyncRequestMutation,
    CreateCustomerSkuSyncRequestVariables
  >
> &
  TChildProps;
export type CreateCustomerSkuSyncRequestMutationFn = ReactApollo.MutationFn<
  CreateCustomerSkuSyncRequestMutation,
  CreateCustomerSkuSyncRequestVariables
>;
export function CreateCustomerSkuSyncRequestHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateCustomerSkuSyncRequestMutation,
        CreateCustomerSkuSyncRequestVariables,
        CreateCustomerSkuSyncRequestProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateCustomerSkuSyncRequestMutation,
    CreateCustomerSkuSyncRequestVariables,
    CreateCustomerSkuSyncRequestProps<TChildProps>
  >(CreateCustomerSkuSyncRequestDocument, operationOptions);
}
export const SelectCustomerSkusDocument = gql`
  query selectCustomerSkus(
    $customerId: Int!
    $search: String
    $limit: Int!
    $offset: Int!
    $skuTypes: [String!]
    $syncStatuses: [String!]
  ) {
    customer(customerId: $customerId) {
      id
      customerSkuSyncRequests {
        id
        createdAt
        isInProgress
        status
        syncErrorMessage
      }
      customerSkus(
        limit: $limit
        offset: $offset
        search: $search
        skuTypes: $skuTypes
        syncStatuses: $syncStatuses
      ) {
        pageInfo {
          hasPreviousPage
          hasNextPage
        }
        totalCount
        items {
          id
          sku
          title
          asin
          upc
          skuType
          syncStatus
          syncErrorMessage
          children {
            id
            sku
            title
            asin
            upc
            skuType
            syncStatus
            syncErrorMessage
          }
        }
      }
    }
  }
`;
export class SelectCustomerSkusComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<SelectCustomerSkusQuery, SelectCustomerSkusVariables>
  >
> {
  render() {
    return (
      <ReactApollo.Query<SelectCustomerSkusQuery, SelectCustomerSkusVariables>
        query={SelectCustomerSkusDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SelectCustomerSkusProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<SelectCustomerSkusQuery, SelectCustomerSkusVariables>
> &
  TChildProps;
export function SelectCustomerSkusHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SelectCustomerSkusQuery,
        SelectCustomerSkusVariables,
        SelectCustomerSkusProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SelectCustomerSkusQuery,
    SelectCustomerSkusVariables,
    SelectCustomerSkusProps<TChildProps>
  >(SelectCustomerSkusDocument, operationOptions);
}
export const SelectCustomerSkuDocument = gql`
  query selectCustomerSku($customerSkuId: Int!) {
    customerSku(customerSkuId: $customerSkuId) {
      id
      sku
      title
      asin
      upc
      skuType
      syncStatus
      createdAt
      lastSyncedAt
      lastSyncAttemptedAt
      parent {
        id
        sku
        title
        asin
        upc
        skuType
        syncStatus
        createdAt
        lastSyncedAt
        lastSyncAttemptedAt
      }
      children {
        id
        sku
        title
        asin
        upc
        skuType
        syncStatus
        createdAt
        lastSyncedAt
        lastSyncAttemptedAt
      }
    }
  }
`;
export class SelectCustomerSkuComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<SelectCustomerSkuQuery, SelectCustomerSkuVariables>
  >
> {
  render() {
    return (
      <ReactApollo.Query<SelectCustomerSkuQuery, SelectCustomerSkuVariables>
        query={SelectCustomerSkuDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SelectCustomerSkuProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<SelectCustomerSkuQuery, SelectCustomerSkuVariables>
> &
  TChildProps;
export function SelectCustomerSkuHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SelectCustomerSkuQuery,
        SelectCustomerSkuVariables,
        SelectCustomerSkuProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SelectCustomerSkuQuery,
    SelectCustomerSkuVariables,
    SelectCustomerSkuProps<TChildProps>
  >(SelectCustomerSkuDocument, operationOptions);
}
export const UpdateCustomerSkuDetailsDocument = gql`
  mutation updateCustomerSkuDetails($input: UpdateCustomerSkuInput!) {
    updateCustomerSku(input: $input) {
      customerSku {
        id
        sku
        title
        asin
        upc
        skuType
        syncStatus
      }
    }
  }
`;
export class UpdateCustomerSkuDetailsComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      UpdateCustomerSkuDetailsMutation,
      UpdateCustomerSkuDetailsVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        UpdateCustomerSkuDetailsMutation,
        UpdateCustomerSkuDetailsVariables
      >
        mutation={UpdateCustomerSkuDetailsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateCustomerSkuDetailsProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    UpdateCustomerSkuDetailsMutation,
    UpdateCustomerSkuDetailsVariables
  >
> &
  TChildProps;
export type UpdateCustomerSkuDetailsMutationFn = ReactApollo.MutationFn<
  UpdateCustomerSkuDetailsMutation,
  UpdateCustomerSkuDetailsVariables
>;
export function UpdateCustomerSkuDetailsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateCustomerSkuDetailsMutation,
        UpdateCustomerSkuDetailsVariables,
        UpdateCustomerSkuDetailsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateCustomerSkuDetailsMutation,
    UpdateCustomerSkuDetailsVariables,
    UpdateCustomerSkuDetailsProps<TChildProps>
  >(UpdateCustomerSkuDetailsDocument, operationOptions);
}
export const UpdateCustomerSkuDocument = gql`
  mutation updateCustomerSku($input: UpdateCustomerSkuInput!) {
    updateCustomerSku(input: $input) {
      customerSku {
        id
        title
        sku
        asin
        upc
        skuType
      }
    }
  }
`;
export class UpdateCustomerSkuComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      UpdateCustomerSkuMutation,
      UpdateCustomerSkuVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        UpdateCustomerSkuMutation,
        UpdateCustomerSkuVariables
      >
        mutation={UpdateCustomerSkuDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateCustomerSkuProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<UpdateCustomerSkuMutation, UpdateCustomerSkuVariables>
> &
  TChildProps;
export type UpdateCustomerSkuMutationFn = ReactApollo.MutationFn<
  UpdateCustomerSkuMutation,
  UpdateCustomerSkuVariables
>;
export function UpdateCustomerSkuHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateCustomerSkuMutation,
        UpdateCustomerSkuVariables,
        UpdateCustomerSkuProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateCustomerSkuMutation,
    UpdateCustomerSkuVariables,
    UpdateCustomerSkuProps<TChildProps>
  >(UpdateCustomerSkuDocument, operationOptions);
}
export const CreateCustomerUserDocument = gql`
  mutation createCustomerUser($input: CreateCustomerUserInput!) {
    createCustomerUser(input: $input) {
      customerUser {
        id
        userId
      }
    }
  }
`;
export class CreateCustomerUserComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateCustomerUserMutation,
      CreateCustomerUserVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateCustomerUserMutation,
        CreateCustomerUserVariables
      >
        mutation={CreateCustomerUserDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateCustomerUserProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    CreateCustomerUserMutation,
    CreateCustomerUserVariables
  >
> &
  TChildProps;
export type CreateCustomerUserMutationFn = ReactApollo.MutationFn<
  CreateCustomerUserMutation,
  CreateCustomerUserVariables
>;
export function CreateCustomerUserHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateCustomerUserMutation,
        CreateCustomerUserVariables,
        CreateCustomerUserProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateCustomerUserMutation,
    CreateCustomerUserVariables,
    CreateCustomerUserProps<TChildProps>
  >(CreateCustomerUserDocument, operationOptions);
}
export const CreateUserEmailAddressDocument = gql`
  mutation CreateUserEmailAddress($input: CreateUserEmailAddressInput!) {
    createUserEmailAddress(input: $input) {
      userEmailAddress {
        id
        label
        email
        isPrimary
      }
    }
  }
`;
export class CreateUserEmailAddressComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateUserEmailAddressMutation,
      CreateUserEmailAddressVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateUserEmailAddressMutation,
        CreateUserEmailAddressVariables
      >
        mutation={CreateUserEmailAddressDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateUserEmailAddressProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    CreateUserEmailAddressMutation,
    CreateUserEmailAddressVariables
  >
> &
  TChildProps;
export type CreateUserEmailAddressMutationFn = ReactApollo.MutationFn<
  CreateUserEmailAddressMutation,
  CreateUserEmailAddressVariables
>;
export function CreateUserEmailAddressHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateUserEmailAddressMutation,
        CreateUserEmailAddressVariables,
        CreateUserEmailAddressProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateUserEmailAddressMutation,
    CreateUserEmailAddressVariables,
    CreateUserEmailAddressProps<TChildProps>
  >(CreateUserEmailAddressDocument, operationOptions);
}
export const CreateUserPhoneNumberDocument = gql`
  mutation CreateUserPhoneNumber($input: CreateUserPhoneNumberInput!) {
    createUserPhoneNumber(input: $input) {
      userPhoneNumber {
        id
        label
        phoneNumber
        isPrimary
      }
    }
  }
`;
export class CreateUserPhoneNumberComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateUserPhoneNumberMutation,
      CreateUserPhoneNumberVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateUserPhoneNumberMutation,
        CreateUserPhoneNumberVariables
      >
        mutation={CreateUserPhoneNumberDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateUserPhoneNumberProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    CreateUserPhoneNumberMutation,
    CreateUserPhoneNumberVariables
  >
> &
  TChildProps;
export type CreateUserPhoneNumberMutationFn = ReactApollo.MutationFn<
  CreateUserPhoneNumberMutation,
  CreateUserPhoneNumberVariables
>;
export function CreateUserPhoneNumberHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateUserPhoneNumberMutation,
        CreateUserPhoneNumberVariables,
        CreateUserPhoneNumberProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateUserPhoneNumberMutation,
    CreateUserPhoneNumberVariables,
    CreateUserPhoneNumberProps<TChildProps>
  >(CreateUserPhoneNumberDocument, operationOptions);
}
export const SelectCustomerUsersDocument = gql`
  query SelectCustomerUsers($customerId: Int!) {
    customer(customerId: $customerId) {
      id
      customerUsers {
        id
        title
        notes
        createdAt
        user {
          id
          firstName
          lastName
          fullName
          profileImageUrl
          emailAddresses {
            id
            email
            label
            isPrimary
          }
          phoneNumbers {
            id
            phoneNumber
            label
            isPrimary
          }
        }
      }
    }
  }
`;
export class SelectCustomerUsersComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      SelectCustomerUsersQuery,
      SelectCustomerUsersVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<SelectCustomerUsersQuery, SelectCustomerUsersVariables>
        query={SelectCustomerUsersDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SelectCustomerUsersProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<SelectCustomerUsersQuery, SelectCustomerUsersVariables>
> &
  TChildProps;
export function SelectCustomerUsersHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SelectCustomerUsersQuery,
        SelectCustomerUsersVariables,
        SelectCustomerUsersProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SelectCustomerUsersQuery,
    SelectCustomerUsersVariables,
    SelectCustomerUsersProps<TChildProps>
  >(SelectCustomerUsersDocument, operationOptions);
}
export const DeleteCustomerUserDocument = gql`
  mutation DeleteCustomerUser($input: DeleteCustomerUserInput!) {
    deleteCustomerUser(input: $input) {
      wasSuccessful
    }
  }
`;
export class DeleteCustomerUserComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      DeleteCustomerUserMutation,
      DeleteCustomerUserVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        DeleteCustomerUserMutation,
        DeleteCustomerUserVariables
      >
        mutation={DeleteCustomerUserDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteCustomerUserProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    DeleteCustomerUserMutation,
    DeleteCustomerUserVariables
  >
> &
  TChildProps;
export type DeleteCustomerUserMutationFn = ReactApollo.MutationFn<
  DeleteCustomerUserMutation,
  DeleteCustomerUserVariables
>;
export function DeleteCustomerUserHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteCustomerUserMutation,
        DeleteCustomerUserVariables,
        DeleteCustomerUserProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteCustomerUserMutation,
    DeleteCustomerUserVariables,
    DeleteCustomerUserProps<TChildProps>
  >(DeleteCustomerUserDocument, operationOptions);
}
export const DeleteUserEmailAddressDocument = gql`
  mutation DeleteUserEmailAddress($userEmailAddressId: Int!) {
    deleteUserEmailAddress(userEmailAddressId: $userEmailAddressId) {
      wasDeleted
    }
  }
`;
export class DeleteUserEmailAddressComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      DeleteUserEmailAddressMutation,
      DeleteUserEmailAddressVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        DeleteUserEmailAddressMutation,
        DeleteUserEmailAddressVariables
      >
        mutation={DeleteUserEmailAddressDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteUserEmailAddressProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    DeleteUserEmailAddressMutation,
    DeleteUserEmailAddressVariables
  >
> &
  TChildProps;
export type DeleteUserEmailAddressMutationFn = ReactApollo.MutationFn<
  DeleteUserEmailAddressMutation,
  DeleteUserEmailAddressVariables
>;
export function DeleteUserEmailAddressHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteUserEmailAddressMutation,
        DeleteUserEmailAddressVariables,
        DeleteUserEmailAddressProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteUserEmailAddressMutation,
    DeleteUserEmailAddressVariables,
    DeleteUserEmailAddressProps<TChildProps>
  >(DeleteUserEmailAddressDocument, operationOptions);
}
export const DeleteUserPhoneNumberDocument = gql`
  mutation DeleteUserPhoneNumber($userPhoneNumberId: Int!) {
    deleteUserPhoneNumber(userPhoneNumberId: $userPhoneNumberId) {
      wasDeleted
    }
  }
`;
export class DeleteUserPhoneNumberComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      DeleteUserPhoneNumberMutation,
      DeleteUserPhoneNumberVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        DeleteUserPhoneNumberMutation,
        DeleteUserPhoneNumberVariables
      >
        mutation={DeleteUserPhoneNumberDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteUserPhoneNumberProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    DeleteUserPhoneNumberMutation,
    DeleteUserPhoneNumberVariables
  >
> &
  TChildProps;
export type DeleteUserPhoneNumberMutationFn = ReactApollo.MutationFn<
  DeleteUserPhoneNumberMutation,
  DeleteUserPhoneNumberVariables
>;
export function DeleteUserPhoneNumberHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteUserPhoneNumberMutation,
        DeleteUserPhoneNumberVariables,
        DeleteUserPhoneNumberProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteUserPhoneNumberMutation,
    DeleteUserPhoneNumberVariables,
    DeleteUserPhoneNumberProps<TChildProps>
  >(DeleteUserPhoneNumberDocument, operationOptions);
}
export const UpdateCustomerUserDocument = gql`
  mutation UpdateCustomerUser($input: UpdateCustomerUserInput!) {
    updateCustomerUser(input: $input) {
      customerUser {
        id
        title
        notes
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
export class UpdateCustomerUserComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      UpdateCustomerUserMutation,
      UpdateCustomerUserVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        UpdateCustomerUserMutation,
        UpdateCustomerUserVariables
      >
        mutation={UpdateCustomerUserDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateCustomerUserProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    UpdateCustomerUserMutation,
    UpdateCustomerUserVariables
  >
> &
  TChildProps;
export type UpdateCustomerUserMutationFn = ReactApollo.MutationFn<
  UpdateCustomerUserMutation,
  UpdateCustomerUserVariables
>;
export function UpdateCustomerUserHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateCustomerUserMutation,
        UpdateCustomerUserVariables,
        UpdateCustomerUserProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateCustomerUserMutation,
    UpdateCustomerUserVariables,
    UpdateCustomerUserProps<TChildProps>
  >(UpdateCustomerUserDocument, operationOptions);
}
export const UpdateUserEmailAddressDocument = gql`
  mutation UpdateUserEmailAddress($input: UpdateUserEmailAddressInput!) {
    updateUserEmailAddress(input: $input) {
      userEmailAddress {
        id
        label
        email
        isPrimary
      }
    }
  }
`;
export class UpdateUserEmailAddressComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      UpdateUserEmailAddressMutation,
      UpdateUserEmailAddressVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        UpdateUserEmailAddressMutation,
        UpdateUserEmailAddressVariables
      >
        mutation={UpdateUserEmailAddressDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateUserEmailAddressProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    UpdateUserEmailAddressMutation,
    UpdateUserEmailAddressVariables
  >
> &
  TChildProps;
export type UpdateUserEmailAddressMutationFn = ReactApollo.MutationFn<
  UpdateUserEmailAddressMutation,
  UpdateUserEmailAddressVariables
>;
export function UpdateUserEmailAddressHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateUserEmailAddressMutation,
        UpdateUserEmailAddressVariables,
        UpdateUserEmailAddressProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateUserEmailAddressMutation,
    UpdateUserEmailAddressVariables,
    UpdateUserEmailAddressProps<TChildProps>
  >(UpdateUserEmailAddressDocument, operationOptions);
}
export const UpdateUserPhoneNumberDocument = gql`
  mutation UpdateUserPhoneNumber($input: UpdateUserPhoneNumberInput!) {
    updateUserPhoneNumber(input: $input) {
      userPhoneNumber {
        id
        label
        phoneNumber
        isPrimary
      }
    }
  }
`;
export class UpdateUserPhoneNumberComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      UpdateUserPhoneNumberMutation,
      UpdateUserPhoneNumberVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        UpdateUserPhoneNumberMutation,
        UpdateUserPhoneNumberVariables
      >
        mutation={UpdateUserPhoneNumberDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateUserPhoneNumberProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    UpdateUserPhoneNumberMutation,
    UpdateUserPhoneNumberVariables
  >
> &
  TChildProps;
export type UpdateUserPhoneNumberMutationFn = ReactApollo.MutationFn<
  UpdateUserPhoneNumberMutation,
  UpdateUserPhoneNumberVariables
>;
export function UpdateUserPhoneNumberHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateUserPhoneNumberMutation,
        UpdateUserPhoneNumberVariables,
        UpdateUserPhoneNumberProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateUserPhoneNumberMutation,
    UpdateUserPhoneNumberVariables,
    UpdateUserPhoneNumberProps<TChildProps>
  >(UpdateUserPhoneNumberDocument, operationOptions);
}
export const CreateCustomerDocument = gql`
  mutation createCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      customer {
        id
      }
    }
  }
`;
export class CreateCustomerComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<CreateCustomerMutation, CreateCustomerVariables>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<CreateCustomerMutation, CreateCustomerVariables>
        mutation={CreateCustomerDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateCustomerProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<CreateCustomerMutation, CreateCustomerVariables>
> &
  TChildProps;
export type CreateCustomerMutationFn = ReactApollo.MutationFn<
  CreateCustomerMutation,
  CreateCustomerVariables
>;
export function CreateCustomerHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateCustomerMutation,
        CreateCustomerVariables,
        CreateCustomerProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateCustomerMutation,
    CreateCustomerVariables,
    CreateCustomerProps<TChildProps>
  >(CreateCustomerDocument, operationOptions);
}
export const SearchCustomersDocument = gql`
  query SearchCustomers(
    $search: String
    $limit: Int!
    $offset: Int
    $sortField: CustomerSortField!
    $sortDirection: SortDirection!
  ) {
    customers(
      search: $search
      limit: $limit
      offset: $offset
      orderBy: { field: $sortField, direction: $sortDirection }
    ) {
      totalCount
      pageInfo {
        hasNextPage
      }
      items {
        id
        name
        accountManagerUser {
          id
          fullName
          profileImageUrl
        }
        isStarred
      }
    }
  }
`;
export class SearchCustomersComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<SearchCustomersQuery, SearchCustomersVariables>
  >
> {
  render() {
    return (
      <ReactApollo.Query<SearchCustomersQuery, SearchCustomersVariables>
        query={SearchCustomersDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SearchCustomersProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<SearchCustomersQuery, SearchCustomersVariables>
> &
  TChildProps;
export function SearchCustomersHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SearchCustomersQuery,
        SearchCustomersVariables,
        SearchCustomersProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SearchCustomersQuery,
    SearchCustomersVariables,
    SearchCustomersProps<TChildProps>
  >(SearchCustomersDocument, operationOptions);
}
export const SelectMyCustomersDocument = gql`
  query selectMyCustomers {
    viewer {
      id
      tasks(statusId: "pending") {
        items {
          id
          title
          dueDate
          customer {
            id
            name
          }
        }
      }
      accountManagement {
        customersManaged {
          totalCount
          items {
            id
            name
            maxEndDate
            contractValue
          }
        }
      }
    }
  }
`;
export class SelectMyCustomersComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<SelectMyCustomersQuery, SelectMyCustomersVariables>
  >
> {
  render() {
    return (
      <ReactApollo.Query<SelectMyCustomersQuery, SelectMyCustomersVariables>
        query={SelectMyCustomersDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SelectMyCustomersProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<SelectMyCustomersQuery, SelectMyCustomersVariables>
> &
  TChildProps;
export function SelectMyCustomersHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SelectMyCustomersQuery,
        SelectMyCustomersVariables,
        SelectMyCustomersProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SelectMyCustomersQuery,
    SelectMyCustomersVariables,
    SelectMyCustomersProps<TChildProps>
  >(SelectMyCustomersDocument, operationOptions);
}
export const SelectMyStarredCustomersDocument = gql`
  query selectMyStarredCustomers {
    viewer {
      id
      customerStars {
        id
        customer {
          id
          name
        }
      }
    }
  }
`;
export class SelectMyStarredCustomersComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      SelectMyStarredCustomersQuery,
      SelectMyStarredCustomersVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        SelectMyStarredCustomersQuery,
        SelectMyStarredCustomersVariables
      >
        query={SelectMyStarredCustomersDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SelectMyStarredCustomersProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    SelectMyStarredCustomersQuery,
    SelectMyStarredCustomersVariables
  >
> &
  TChildProps;
export function SelectMyStarredCustomersHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SelectMyStarredCustomersQuery,
        SelectMyStarredCustomersVariables,
        SelectMyStarredCustomersProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SelectMyStarredCustomersQuery,
    SelectMyStarredCustomersVariables,
    SelectMyStarredCustomersProps<TChildProps>
  >(SelectMyStarredCustomersDocument, operationOptions);
}
export const SelectMyTasksDocument = gql`
  query selectMyTasks {
    viewer {
      id
      tasks(statusId: "assigned") {
        totalCount
        items {
          id
          title
          dueDate
          customer {
            id
            name
          }
          taskType {
            id
            name
          }
          customerProductBatch {
            id
          }
        }
      }
    }
  }
`;
export class SelectMyTasksComponent extends React.Component<
  Partial<ReactApollo.QueryProps<SelectMyTasksQuery, SelectMyTasksVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<SelectMyTasksQuery, SelectMyTasksVariables>
        query={SelectMyTasksDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SelectMyTasksProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<SelectMyTasksQuery, SelectMyTasksVariables>
> &
  TChildProps;
export function SelectMyTasksHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SelectMyTasksQuery,
        SelectMyTasksVariables,
        SelectMyTasksProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SelectMyTasksQuery,
    SelectMyTasksVariables,
    SelectMyTasksProps<TChildProps>
  >(SelectMyTasksDocument, operationOptions);
}
export const CreateInventoryItemDocument = gql`
  mutation createInventoryItem($input: CreateInventoryItemInput!) {
    createInventoryItem(input: $input) {
      inventoryItem {
        id
      }
    }
  }
`;
export class CreateInventoryItemComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateInventoryItemMutation,
      CreateInventoryItemVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateInventoryItemMutation,
        CreateInventoryItemVariables
      >
        mutation={CreateInventoryItemDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateInventoryItemProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    CreateInventoryItemMutation,
    CreateInventoryItemVariables
  >
> &
  TChildProps;
export type CreateInventoryItemMutationFn = ReactApollo.MutationFn<
  CreateInventoryItemMutation,
  CreateInventoryItemVariables
>;
export function CreateInventoryItemHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateInventoryItemMutation,
        CreateInventoryItemVariables,
        CreateInventoryItemProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateInventoryItemMutation,
    CreateInventoryItemVariables,
    CreateInventoryItemProps<TChildProps>
  >(CreateInventoryItemDocument, operationOptions);
}
export const DeleteInventoryItemDocument = gql`
  mutation deleteInventoryItem($input: DeleteInventoryItemInput!) {
    deleteInventoryItem(input: $input) {
      wasSuccessful
    }
  }
`;
export class DeleteInventoryItemComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      DeleteInventoryItemMutation,
      DeleteInventoryItemVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        DeleteInventoryItemMutation,
        DeleteInventoryItemVariables
      >
        mutation={DeleteInventoryItemDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteInventoryItemProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    DeleteInventoryItemMutation,
    DeleteInventoryItemVariables
  >
> &
  TChildProps;
export type DeleteInventoryItemMutationFn = ReactApollo.MutationFn<
  DeleteInventoryItemMutation,
  DeleteInventoryItemVariables
>;
export function DeleteInventoryItemHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteInventoryItemMutation,
        DeleteInventoryItemVariables,
        DeleteInventoryItemProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteInventoryItemMutation,
    DeleteInventoryItemVariables,
    DeleteInventoryItemProps<TChildProps>
  >(DeleteInventoryItemDocument, operationOptions);
}
export const SelectInventoryItemsDocument = gql`
  query selectInventoryItems {
    inventoryItemLocations {
      id
      name
      description
    }
    inventoryItemConditions {
      id
      name
      description
    }
    inventoryItems {
      items {
        id
        description
        customerId
        customerSkuId
        receivedAt
        storageLocation
        quantity
        status {
          id
          name
        }
        customerSku {
          id
          title
          sku
        }
        customer {
          id
          name
        }
        location {
          id
          name
        }
        condition {
          id
          name
        }
      }
    }
  }
`;
export class SelectInventoryItemsComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      SelectInventoryItemsQuery,
      SelectInventoryItemsVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        SelectInventoryItemsQuery,
        SelectInventoryItemsVariables
      >
        query={SelectInventoryItemsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SelectInventoryItemsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    SelectInventoryItemsQuery,
    SelectInventoryItemsVariables
  >
> &
  TChildProps;
export function SelectInventoryItemsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SelectInventoryItemsQuery,
        SelectInventoryItemsVariables,
        SelectInventoryItemsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SelectInventoryItemsQuery,
    SelectInventoryItemsVariables,
    SelectInventoryItemsProps<TChildProps>
  >(SelectInventoryItemsDocument, operationOptions);
}
export const UpdateInventoryItemDocument = gql`
  mutation updateInventoryItem($input: UpdateInventoryItemInput!) {
    updateInventoryItem(input: $input) {
      inventoryItem {
        id
        description
        customerId
        customerSkuId
        receivedAt
        storageLocation
        quantity
        status {
          id
          name
        }
        customerSku {
          id
          title
          sku
        }
        customer {
          id
          name
        }
        location {
          id
          name
        }
        condition {
          id
          name
        }
      }
    }
  }
`;
export class UpdateInventoryItemComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      UpdateInventoryItemMutation,
      UpdateInventoryItemVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        UpdateInventoryItemMutation,
        UpdateInventoryItemVariables
      >
        mutation={UpdateInventoryItemDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateInventoryItemProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    UpdateInventoryItemMutation,
    UpdateInventoryItemVariables
  >
> &
  TChildProps;
export type UpdateInventoryItemMutationFn = ReactApollo.MutationFn<
  UpdateInventoryItemMutation,
  UpdateInventoryItemVariables
>;
export function UpdateInventoryItemHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateInventoryItemMutation,
        UpdateInventoryItemVariables,
        UpdateInventoryItemProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateInventoryItemMutation,
    UpdateInventoryItemVariables,
    UpdateInventoryItemProps<TChildProps>
  >(UpdateInventoryItemDocument, operationOptions);
}
export const PricingToolInfoDocument = gql`
  query pricingToolInfo {
    pricingToolInfo {
      averageWeeklyAmHours
      weeksPerMonth
      managedAmountLimit
      amHourlyRate
      trueHourlyOverhead
      desiredProfitMargin
    }
  }
`;
export class PricingToolInfoComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<PricingToolInfoQuery, PricingToolInfoVariables>
  >
> {
  render() {
    return (
      <ReactApollo.Query<PricingToolInfoQuery, PricingToolInfoVariables>
        query={PricingToolInfoDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type PricingToolInfoProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<PricingToolInfoQuery, PricingToolInfoVariables>
> &
  TChildProps;
export function PricingToolInfoHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        PricingToolInfoQuery,
        PricingToolInfoVariables,
        PricingToolInfoProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    PricingToolInfoQuery,
    PricingToolInfoVariables,
    PricingToolInfoProps<TChildProps>
  >(PricingToolInfoDocument, operationOptions);
}
export const SelectAccountManagementOverviewDocument = gql`
  query selectAccountManagementOverview($search: String) {
    accountManagementOverview: users(
      search: $search
      department: "Account Management"
      isActive: true
    ) {
      items {
        id
        fullName
        profileImageUrl
        teams {
          id
          name
        }
        accountManagement {
          amountManaged
          customersManaged {
            totalCount
            items {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export class SelectAccountManagementOverviewComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      SelectAccountManagementOverviewQuery,
      SelectAccountManagementOverviewVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        SelectAccountManagementOverviewQuery,
        SelectAccountManagementOverviewVariables
      >
        query={SelectAccountManagementOverviewDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SelectAccountManagementOverviewProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    SelectAccountManagementOverviewQuery,
    SelectAccountManagementOverviewVariables
  >
> &
  TChildProps;
export function SelectAccountManagementOverviewHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SelectAccountManagementOverviewQuery,
        SelectAccountManagementOverviewVariables,
        SelectAccountManagementOverviewProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SelectAccountManagementOverviewQuery,
    SelectAccountManagementOverviewVariables,
    SelectAccountManagementOverviewProps<TChildProps>
  >(SelectAccountManagementOverviewDocument, operationOptions);
}
export const SelectAccountManagementTeamsDocument = gql`
  query selectAccountManagementTeams {
    teams(department: "Account Management") {
      items {
        id
        name
      }
    }
  }
`;
export class SelectAccountManagementTeamsComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      SelectAccountManagementTeamsQuery,
      SelectAccountManagementTeamsVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        SelectAccountManagementTeamsQuery,
        SelectAccountManagementTeamsVariables
      >
        query={SelectAccountManagementTeamsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SelectAccountManagementTeamsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    SelectAccountManagementTeamsQuery,
    SelectAccountManagementTeamsVariables
  >
> &
  TChildProps;
export function SelectAccountManagementTeamsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SelectAccountManagementTeamsQuery,
        SelectAccountManagementTeamsVariables,
        SelectAccountManagementTeamsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SelectAccountManagementTeamsQuery,
    SelectAccountManagementTeamsVariables,
    SelectAccountManagementTeamsProps<TChildProps>
  >(SelectAccountManagementTeamsDocument, operationOptions);
}
export const SelectTeamUsersDocument = gql`
  query selectTeamUsers($teamId: Int!) {
    teamUsersInformation: team(teamId: $teamId) {
      id
      name
      leadUser {
        id
        fullName
        profileImageUrl
        accountManagement {
          customersManaged {
            items {
              id
              name
            }
          }
          amountManaged
        }
      }
      teamUsers {
        user {
          id
          fullName
          profileImageUrl
          accountManagement {
            customersManaged {
              items {
                id
                name
              }
            }
            amountManaged
          }
        }
      }
    }
  }
`;
export class SelectTeamUsersComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<SelectTeamUsersQuery, SelectTeamUsersVariables>
  >
> {
  render() {
    return (
      <ReactApollo.Query<SelectTeamUsersQuery, SelectTeamUsersVariables>
        query={SelectTeamUsersDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SelectTeamUsersProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<SelectTeamUsersQuery, SelectTeamUsersVariables>
> &
  TChildProps;
export function SelectTeamUsersHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SelectTeamUsersQuery,
        SelectTeamUsersVariables,
        SelectTeamUsersProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SelectTeamUsersQuery,
    SelectTeamUsersVariables,
    SelectTeamUsersProps<TChildProps>
  >(SelectTeamUsersDocument, operationOptions);
}
export const SelectTeamsByGroupDocument = gql`
  query selectTeamsByGroup {
    teams(department: "Account Management") {
      items {
        id
        name
        leadUser {
          id
          fullName
          profileImageUrl
          accountManagement {
            customersManaged {
              items {
                id
                name
              }
            }
            amountManaged
          }
        }
        teamUsers {
          user {
            id
            fullName
            profileImageUrl
            accountManagement {
              customersManaged {
                items {
                  id
                  name
                }
              }
              amountManaged
            }
          }
        }
      }
    }
  }
`;
export class SelectTeamsByGroupComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<SelectTeamsByGroupQuery, SelectTeamsByGroupVariables>
  >
> {
  render() {
    return (
      <ReactApollo.Query<SelectTeamsByGroupQuery, SelectTeamsByGroupVariables>
        query={SelectTeamsByGroupDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SelectTeamsByGroupProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<SelectTeamsByGroupQuery, SelectTeamsByGroupVariables>
> &
  TChildProps;
export function SelectTeamsByGroupHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SelectTeamsByGroupQuery,
        SelectTeamsByGroupVariables,
        SelectTeamsByGroupProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SelectTeamsByGroupQuery,
    SelectTeamsByGroupVariables,
    SelectTeamsByGroupProps<TChildProps>
  >(SelectTeamsByGroupDocument, operationOptions);
}
export const SelectCustomersEndingIn30DaysDocument = gql`
  query selectCustomersEndingIn30Days {
    reports {
      customersEndingIn30Days {
        id
        name
        startDate
      }
    }
  }
`;
export class SelectCustomersEndingIn30DaysComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      SelectCustomersEndingIn30DaysQuery,
      SelectCustomersEndingIn30DaysVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        SelectCustomersEndingIn30DaysQuery,
        SelectCustomersEndingIn30DaysVariables
      >
        query={SelectCustomersEndingIn30DaysDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SelectCustomersEndingIn30DaysProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    SelectCustomersEndingIn30DaysQuery,
    SelectCustomersEndingIn30DaysVariables
  >
> &
  TChildProps;
export function SelectCustomersEndingIn30DaysHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SelectCustomersEndingIn30DaysQuery,
        SelectCustomersEndingIn30DaysVariables,
        SelectCustomersEndingIn30DaysProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SelectCustomersEndingIn30DaysQuery,
    SelectCustomersEndingIn30DaysVariables,
    SelectCustomersEndingIn30DaysProps<TChildProps>
  >(SelectCustomersEndingIn30DaysDocument, operationOptions);
}
export const SelectCustomersNoAssignedAccountManagerDocument = gql`
  query selectCustomersNoAssignedAccountManager {
    customers(accountManagerUserId: null) {
      items {
        id
        name
      }
    }
  }
`;
export class SelectCustomersNoAssignedAccountManagerComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      SelectCustomersNoAssignedAccountManagerQuery,
      SelectCustomersNoAssignedAccountManagerVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        SelectCustomersNoAssignedAccountManagerQuery,
        SelectCustomersNoAssignedAccountManagerVariables
      >
        query={SelectCustomersNoAssignedAccountManagerDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SelectCustomersNoAssignedAccountManagerProps<
  TChildProps = any
> = Partial<
  ReactApollo.DataProps<
    SelectCustomersNoAssignedAccountManagerQuery,
    SelectCustomersNoAssignedAccountManagerVariables
  >
> &
  TChildProps;
export function SelectCustomersNoAssignedAccountManagerHOC<
  TProps,
  TChildProps = any
>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SelectCustomersNoAssignedAccountManagerQuery,
        SelectCustomersNoAssignedAccountManagerVariables,
        SelectCustomersNoAssignedAccountManagerProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SelectCustomersNoAssignedAccountManagerQuery,
    SelectCustomersNoAssignedAccountManagerVariables,
    SelectCustomersNoAssignedAccountManagerProps<TChildProps>
  >(SelectCustomersNoAssignedAccountManagerDocument, operationOptions);
}
export const SearchCustomerSkusDocument = gql`
  query searchCustomerSkus(
    $customerId: Int!
    $search: String
    $skuTypes: [String!]
    $limit: Int!
    $offset: Int!
  ) {
    customer(customerId: $customerId) {
      id
      customerSkus(
        search: $search
        limit: $limit
        offset: $offset
        skuTypes: $skuTypes
      ) {
        pageInfo {
          hasPreviousPage
          hasNextPage
        }
        totalCount
        items {
          id
          sku
          asin
          upc
          title
          skuType
        }
      }
    }
  }
`;
export class SearchCustomerSkusComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<SearchCustomerSkusQuery, SearchCustomerSkusVariables>
  >
> {
  render() {
    return (
      <ReactApollo.Query<SearchCustomerSkusQuery, SearchCustomerSkusVariables>
        query={SearchCustomerSkusDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SearchCustomerSkusProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<SearchCustomerSkusQuery, SearchCustomerSkusVariables>
> &
  TChildProps;
export function SearchCustomerSkusHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SearchCustomerSkusQuery,
        SearchCustomerSkusVariables,
        SearchCustomerSkusProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SearchCustomerSkusQuery,
    SearchCustomerSkusVariables,
    SearchCustomerSkusProps<TChildProps>
  >(SearchCustomerSkusDocument, operationOptions);
}
export const TaskNotesDocument = gql`
  query taskNotes($taskId: Int!, $search: String, $limit: Int, $offset: Int) {
    task(taskId: $taskId) {
      id
      notes(search: $search, limit: $limit, offset: $offset) {
        pageInfo {
          hasPreviousPage
          hasNextPage
        }
        totalCount
        items {
          id
          content
          createdAt
          userId
          originalNoteId
          isLatest
          user {
            id
            fullName
          }
          noteReminders {
            id
            noteId
            content
            dueAt
            completedAt
          }
          isDeletable
        }
      }
    }
  }
`;
export class TaskNotesComponent extends React.Component<
  Partial<ReactApollo.QueryProps<TaskNotesQuery, TaskNotesVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<TaskNotesQuery, TaskNotesVariables>
        query={TaskNotesDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type TaskNotesProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<TaskNotesQuery, TaskNotesVariables>
> &
  TChildProps;
export function TaskNotesHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        TaskNotesQuery,
        TaskNotesVariables,
        TaskNotesProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    TaskNotesQuery,
    TaskNotesVariables,
    TaskNotesProps<TChildProps>
  >(TaskNotesDocument, operationOptions);
}
export const SelectCustomerProductBatchInfographicReviewItemsDocument = gql`
  query SelectCustomerProductBatchInfographicReviewItems(
    $customerProductBatchId: Int!
  ) {
    customerProductBatch(customerProductBatchId: $customerProductBatchId) {
      id
      infographicReviewItems {
        id
        comments
        createdAt
        user {
          id
          fullName
          profileImageUrl
        }
      }
    }
  }
`;
export class SelectCustomerProductBatchInfographicReviewItemsComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      SelectCustomerProductBatchInfographicReviewItemsQuery,
      SelectCustomerProductBatchInfographicReviewItemsVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        SelectCustomerProductBatchInfographicReviewItemsQuery,
        SelectCustomerProductBatchInfographicReviewItemsVariables
      >
        query={SelectCustomerProductBatchInfographicReviewItemsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SelectCustomerProductBatchInfographicReviewItemsProps<
  TChildProps = any
> = Partial<
  ReactApollo.DataProps<
    SelectCustomerProductBatchInfographicReviewItemsQuery,
    SelectCustomerProductBatchInfographicReviewItemsVariables
  >
> &
  TChildProps;
export function SelectCustomerProductBatchInfographicReviewItemsHOC<
  TProps,
  TChildProps = any
>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SelectCustomerProductBatchInfographicReviewItemsQuery,
        SelectCustomerProductBatchInfographicReviewItemsVariables,
        SelectCustomerProductBatchInfographicReviewItemsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SelectCustomerProductBatchInfographicReviewItemsQuery,
    SelectCustomerProductBatchInfographicReviewItemsVariables,
    SelectCustomerProductBatchInfographicReviewItemsProps<TChildProps>
  >(SelectCustomerProductBatchInfographicReviewItemsDocument, operationOptions);
}
export const CreateInfographicReviewItemsDocument = gql`
  mutation createInfographicReviewItems(
    $input: CreateInfographicReviewItemsInput!
  ) {
    createInfographicReviewItems(input: $input) {
      infographicReviewItems {
        id
      }
    }
  }
`;
export class CreateInfographicReviewItemsComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreateInfographicReviewItemsMutation,
      CreateInfographicReviewItemsVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreateInfographicReviewItemsMutation,
        CreateInfographicReviewItemsVariables
      >
        mutation={CreateInfographicReviewItemsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateInfographicReviewItemsProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    CreateInfographicReviewItemsMutation,
    CreateInfographicReviewItemsVariables
  >
> &
  TChildProps;
export type CreateInfographicReviewItemsMutationFn = ReactApollo.MutationFn<
  CreateInfographicReviewItemsMutation,
  CreateInfographicReviewItemsVariables
>;
export function CreateInfographicReviewItemsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateInfographicReviewItemsMutation,
        CreateInfographicReviewItemsVariables,
        CreateInfographicReviewItemsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateInfographicReviewItemsMutation,
    CreateInfographicReviewItemsVariables,
    CreateInfographicReviewItemsProps<TChildProps>
  >(CreateInfographicReviewItemsDocument, operationOptions);
}
export const SelectInfographicBatchReviewTaskWorkspaceDocument = gql`
  query SelectInfographicBatchReviewTaskWorkspace(
    $customerProductBatchId: Int!
  ) {
    customerProductBatch(customerProductBatchId: $customerProductBatchId) {
      id
      customerProducts {
        id
        product {
          id
          name
        }
        customerSku {
          id
          title
          sku
        }
      }
    }
  }
`;
export class SelectInfographicBatchReviewTaskWorkspaceComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      SelectInfographicBatchReviewTaskWorkspaceQuery,
      SelectInfographicBatchReviewTaskWorkspaceVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        SelectInfographicBatchReviewTaskWorkspaceQuery,
        SelectInfographicBatchReviewTaskWorkspaceVariables
      >
        query={SelectInfographicBatchReviewTaskWorkspaceDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SelectInfographicBatchReviewTaskWorkspaceProps<
  TChildProps = any
> = Partial<
  ReactApollo.DataProps<
    SelectInfographicBatchReviewTaskWorkspaceQuery,
    SelectInfographicBatchReviewTaskWorkspaceVariables
  >
> &
  TChildProps;
export function SelectInfographicBatchReviewTaskWorkspaceHOC<
  TProps,
  TChildProps = any
>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SelectInfographicBatchReviewTaskWorkspaceQuery,
        SelectInfographicBatchReviewTaskWorkspaceVariables,
        SelectInfographicBatchReviewTaskWorkspaceProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SelectInfographicBatchReviewTaskWorkspaceQuery,
    SelectInfographicBatchReviewTaskWorkspaceVariables,
    SelectInfographicBatchReviewTaskWorkspaceProps<TChildProps>
  >(SelectInfographicBatchReviewTaskWorkspaceDocument, operationOptions);
}
export const CreatePhotographyReviewItemsDocument = gql`
  mutation createPhotographyReviewItems(
    $input: CreatePhotographyReviewItemsInput!
  ) {
    createPhotographyReviewItems(input: $input) {
      photographyReviewItems {
        id
      }
    }
  }
`;
export class CreatePhotographyReviewItemsComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<
      CreatePhotographyReviewItemsMutation,
      CreatePhotographyReviewItemsVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<
        CreatePhotographyReviewItemsMutation,
        CreatePhotographyReviewItemsVariables
      >
        mutation={CreatePhotographyReviewItemsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreatePhotographyReviewItemsProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<
    CreatePhotographyReviewItemsMutation,
    CreatePhotographyReviewItemsVariables
  >
> &
  TChildProps;
export type CreatePhotographyReviewItemsMutationFn = ReactApollo.MutationFn<
  CreatePhotographyReviewItemsMutation,
  CreatePhotographyReviewItemsVariables
>;
export function CreatePhotographyReviewItemsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreatePhotographyReviewItemsMutation,
        CreatePhotographyReviewItemsVariables,
        CreatePhotographyReviewItemsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreatePhotographyReviewItemsMutation,
    CreatePhotographyReviewItemsVariables,
    CreatePhotographyReviewItemsProps<TChildProps>
  >(CreatePhotographyReviewItemsDocument, operationOptions);
}
export const SelectPhotoBatchReviewTaskWorkspaceDocument = gql`
  query SelectPhotoBatchReviewTaskWorkspace($customerProductBatchId: Int!) {
    customerProductBatch(customerProductBatchId: $customerProductBatchId) {
      id
      customerProducts {
        id
        product {
          id
          name
        }
        customerSku {
          id
          title
          sku
        }
      }
    }
  }
`;
export class SelectPhotoBatchReviewTaskWorkspaceComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      SelectPhotoBatchReviewTaskWorkspaceQuery,
      SelectPhotoBatchReviewTaskWorkspaceVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        SelectPhotoBatchReviewTaskWorkspaceQuery,
        SelectPhotoBatchReviewTaskWorkspaceVariables
      >
        query={SelectPhotoBatchReviewTaskWorkspaceDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SelectPhotoBatchReviewTaskWorkspaceProps<
  TChildProps = any
> = Partial<
  ReactApollo.DataProps<
    SelectPhotoBatchReviewTaskWorkspaceQuery,
    SelectPhotoBatchReviewTaskWorkspaceVariables
  >
> &
  TChildProps;
export function SelectPhotoBatchReviewTaskWorkspaceHOC<
  TProps,
  TChildProps = any
>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SelectPhotoBatchReviewTaskWorkspaceQuery,
        SelectPhotoBatchReviewTaskWorkspaceVariables,
        SelectPhotoBatchReviewTaskWorkspaceProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SelectPhotoBatchReviewTaskWorkspaceQuery,
    SelectPhotoBatchReviewTaskWorkspaceVariables,
    SelectPhotoBatchReviewTaskWorkspaceProps<TChildProps>
  >(SelectPhotoBatchReviewTaskWorkspaceDocument, operationOptions);
}
export const PhotoEditRedoTaskWorkspaceDocument = gql`
  query PhotoEditRedoTaskWorkspace($customerProductBatchId: Int!) {
    customerProductBatch(customerProductBatchId: $customerProductBatchId) {
      id
      photographyReviewItems {
        id
        createdAt
        comments
        user {
          id
          fullName
          profileImageUrl
        }
      }
    }
  }
`;
export class PhotoEditRedoTaskWorkspaceComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      PhotoEditRedoTaskWorkspaceQuery,
      PhotoEditRedoTaskWorkspaceVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        PhotoEditRedoTaskWorkspaceQuery,
        PhotoEditRedoTaskWorkspaceVariables
      >
        query={PhotoEditRedoTaskWorkspaceDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type PhotoEditRedoTaskWorkspaceProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    PhotoEditRedoTaskWorkspaceQuery,
    PhotoEditRedoTaskWorkspaceVariables
  >
> &
  TChildProps;
export function PhotoEditRedoTaskWorkspaceHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        PhotoEditRedoTaskWorkspaceQuery,
        PhotoEditRedoTaskWorkspaceVariables,
        PhotoEditRedoTaskWorkspaceProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    PhotoEditRedoTaskWorkspaceQuery,
    PhotoEditRedoTaskWorkspaceVariables,
    PhotoEditRedoTaskWorkspaceProps<TChildProps>
  >(PhotoEditRedoTaskWorkspaceDocument, operationOptions);
}
export const PhotoReshootTaskWorkspaceDocument = gql`
  query PhotoReshootTaskWorkspace($customerProductBatchId: Int!) {
    customerProductBatch(customerProductBatchId: $customerProductBatchId) {
      id
      photographyReviewItems {
        id
        createdAt
        comments
        user {
          id
          fullName
          profileImageUrl
        }
      }
    }
  }
`;
export class PhotoReshootTaskWorkspaceComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      PhotoReshootTaskWorkspaceQuery,
      PhotoReshootTaskWorkspaceVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        PhotoReshootTaskWorkspaceQuery,
        PhotoReshootTaskWorkspaceVariables
      >
        query={PhotoReshootTaskWorkspaceDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type PhotoReshootTaskWorkspaceProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    PhotoReshootTaskWorkspaceQuery,
    PhotoReshootTaskWorkspaceVariables
  >
> &
  TChildProps;
export function PhotoReshootTaskWorkspaceHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        PhotoReshootTaskWorkspaceQuery,
        PhotoReshootTaskWorkspaceVariables,
        PhotoReshootTaskWorkspaceProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    PhotoReshootTaskWorkspaceQuery,
    PhotoReshootTaskWorkspaceVariables,
    PhotoReshootTaskWorkspaceProps<TChildProps>
  >(PhotoReshootTaskWorkspaceDocument, operationOptions);
}
export const RecordTaskWorkDocument = gql`
  mutation recordTaskWork($input: RecordTaskWorkInput!) {
    recordTaskWork(input: $input) {
      wasRecorded
    }
  }
`;
export class RecordTaskWorkComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<RecordTaskWorkMutation, RecordTaskWorkVariables>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<RecordTaskWorkMutation, RecordTaskWorkVariables>
        mutation={RecordTaskWorkDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type RecordTaskWorkProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<RecordTaskWorkMutation, RecordTaskWorkVariables>
> &
  TChildProps;
export type RecordTaskWorkMutationFn = ReactApollo.MutationFn<
  RecordTaskWorkMutation,
  RecordTaskWorkVariables
>;
export function RecordTaskWorkHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        RecordTaskWorkMutation,
        RecordTaskWorkVariables,
        RecordTaskWorkProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    RecordTaskWorkMutation,
    RecordTaskWorkVariables,
    RecordTaskWorkProps<TChildProps>
  >(RecordTaskWorkDocument, operationOptions);
}
export const SelectTaskDocument = gql`
  query selectTask($taskId: Int!) {
    viewer {
      id
    }
    task(taskId: $taskId) {
      id
      title
      statusId
      dueDate
      assignedUser {
        id
        fullName
      }
      customer {
        id
        name
        accountManagerUser {
          id
          fullName
        }
      }
      customerProductBatch {
        id
        customerProducts {
          id
          customerSku {
            id
            sku
            title
          }
          product {
            id
            name
          }
          tasks {
            id
            taskType {
              id
              name
            }
            statusId
            title
            assignedUser {
              id
              fullName
              profileImageUrl
            }
          }
        }
      }
      customerProduct {
        id
        product {
          id
          name
        }
        customerSku {
          id
          sku
          title
          asin
          upc
        }
        customerProject {
          id
          name
          customerContract {
            id
          }
        }
      }
      notes {
        totalCount
      }
      taskType {
        id
        name
      }
    }
  }
`;
export class SelectTaskComponent extends React.Component<
  Partial<ReactApollo.QueryProps<SelectTaskQuery, SelectTaskVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<SelectTaskQuery, SelectTaskVariables>
        query={SelectTaskDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SelectTaskProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<SelectTaskQuery, SelectTaskVariables>
> &
  TChildProps;
export function SelectTaskHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SelectTaskQuery,
        SelectTaskVariables,
        SelectTaskProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SelectTaskQuery,
    SelectTaskVariables,
    SelectTaskProps<TChildProps>
  >(SelectTaskDocument, operationOptions);
}
export const UpdateTaskDocument = gql`
  mutation updateTask($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      task {
        id
      }
    }
  }
`;
export class UpdateTaskComponent extends React.Component<
  Partial<ReactApollo.MutationProps<UpdateTaskMutation, UpdateTaskVariables>>
> {
  render() {
    return (
      <ReactApollo.Mutation<UpdateTaskMutation, UpdateTaskVariables>
        mutation={UpdateTaskDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateTaskProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<UpdateTaskMutation, UpdateTaskVariables>
> &
  TChildProps;
export type UpdateTaskMutationFn = ReactApollo.MutationFn<
  UpdateTaskMutation,
  UpdateTaskVariables
>;
export function UpdateTaskHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateTaskMutation,
        UpdateTaskVariables,
        UpdateTaskProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateTaskMutation,
    UpdateTaskVariables,
    UpdateTaskProps<TChildProps>
  >(UpdateTaskDocument, operationOptions);
}
export const SelectWelcomeCallDocument = gql`
  query selectWelcomeCall($customerId: Int!) {
    customer(customerId: $customerId) {
      id
      welcomeCall {
        id
        scheduledAt
      }
    }
  }
`;
export class SelectWelcomeCallComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<SelectWelcomeCallQuery, SelectWelcomeCallVariables>
  >
> {
  render() {
    return (
      <ReactApollo.Query<SelectWelcomeCallQuery, SelectWelcomeCallVariables>
        query={SelectWelcomeCallDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SelectWelcomeCallProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<SelectWelcomeCallQuery, SelectWelcomeCallVariables>
> &
  TChildProps;
export function SelectWelcomeCallHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SelectWelcomeCallQuery,
        SelectWelcomeCallVariables,
        SelectWelcomeCallProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SelectWelcomeCallQuery,
    SelectWelcomeCallVariables,
    SelectWelcomeCallProps<TChildProps>
  >(SelectWelcomeCallDocument, operationOptions);
}
export const SelectTasksFilterDataDocument = gql`
  query selectTasksFilterData {
    taskTypes {
      id
      name
    }
    viewer {
      id
    }
  }
`;
export class SelectTasksFilterDataComponent extends React.Component<
  Partial<
    ReactApollo.QueryProps<
      SelectTasksFilterDataQuery,
      SelectTasksFilterDataVariables
    >
  >
> {
  render() {
    return (
      <ReactApollo.Query<
        SelectTasksFilterDataQuery,
        SelectTasksFilterDataVariables
      >
        query={SelectTasksFilterDataDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SelectTasksFilterDataProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<
    SelectTasksFilterDataQuery,
    SelectTasksFilterDataVariables
  >
> &
  TChildProps;
export function SelectTasksFilterDataHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SelectTasksFilterDataQuery,
        SelectTasksFilterDataVariables,
        SelectTasksFilterDataProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SelectTasksFilterDataQuery,
    SelectTasksFilterDataVariables,
    SelectTasksFilterDataProps<TChildProps>
  >(SelectTasksFilterDataDocument, operationOptions);
}
export const SelectTasksDocument = gql`
  query selectTasks(
    $statusId: TaskStatusEnum
    $taskTypeIds: [String]
    $assignedUserId: Int
    $customerId: Int
    $limit: Int
    $offset: Int
  ) {
    viewer {
      id
    }
    taskTypes {
      id
      name
    }
    tasks(
      statusId: $statusId
      taskTypeIds: $taskTypeIds
      assignedUserId: $assignedUserId
      customerId: $customerId
      limit: $limit
      offset: $offset
    ) {
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      items {
        id
        title
        dueDate
        statusId
        assignedUser {
          id
          fullName
        }
        taskType {
          id
          name
        }
        customer {
          id
          name
        }
        customerProduct {
          id
          product {
            id
            name
          }
          customerProject {
            id
            name
          }
        }
        customerProductBatch {
          id
        }
      }
    }
  }
`;
export class SelectTasksComponent extends React.Component<
  Partial<ReactApollo.QueryProps<SelectTasksQuery, SelectTasksVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<SelectTasksQuery, SelectTasksVariables>
        query={SelectTasksDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SelectTasksProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<SelectTasksQuery, SelectTasksVariables>
> &
  TChildProps;
export function SelectTasksHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SelectTasksQuery,
        SelectTasksVariables,
        SelectTasksProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SelectTasksQuery,
    SelectTasksVariables,
    SelectTasksProps<TChildProps>
  >(SelectTasksDocument, operationOptions);
}
export const TasksUpdateTaskDocument = gql`
  mutation tasksUpdateTask($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      task {
        id
      }
    }
  }
`;
export class TasksUpdateTaskComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<TasksUpdateTaskMutation, TasksUpdateTaskVariables>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<TasksUpdateTaskMutation, TasksUpdateTaskVariables>
        mutation={TasksUpdateTaskDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type TasksUpdateTaskProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<TasksUpdateTaskMutation, TasksUpdateTaskVariables>
> &
  TChildProps;
export type TasksUpdateTaskMutationFn = ReactApollo.MutationFn<
  TasksUpdateTaskMutation,
  TasksUpdateTaskVariables
>;
export function TasksUpdateTaskHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        TasksUpdateTaskMutation,
        TasksUpdateTaskVariables,
        TasksUpdateTaskProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    TasksUpdateTaskMutation,
    TasksUpdateTaskVariables,
    TasksUpdateTaskProps<TChildProps>
  >(TasksUpdateTaskDocument, operationOptions);
}
export const CreateTeamUserDocument = gql`
  mutation CreateTeamUser($input: CreateTeamUserInput!) {
    createTeamUser(input: $input) {
      teamUser {
        id
      }
    }
  }
`;
export class CreateTeamUserComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<CreateTeamUserMutation, CreateTeamUserVariables>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<CreateTeamUserMutation, CreateTeamUserVariables>
        mutation={CreateTeamUserDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateTeamUserProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<CreateTeamUserMutation, CreateTeamUserVariables>
> &
  TChildProps;
export type CreateTeamUserMutationFn = ReactApollo.MutationFn<
  CreateTeamUserMutation,
  CreateTeamUserVariables
>;
export function CreateTeamUserHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateTeamUserMutation,
        CreateTeamUserVariables,
        CreateTeamUserProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateTeamUserMutation,
    CreateTeamUserVariables,
    CreateTeamUserProps<TChildProps>
  >(CreateTeamUserDocument, operationOptions);
}
export const DeleteTeamUserDocument = gql`
  mutation DeleteTeamUser($input: DeleteTeamUserInput!) {
    deleteTeamUser(input: $input) {
      wasSuccessful
    }
  }
`;
export class DeleteTeamUserComponent extends React.Component<
  Partial<
    ReactApollo.MutationProps<DeleteTeamUserMutation, DeleteTeamUserVariables>
  >
> {
  render() {
    return (
      <ReactApollo.Mutation<DeleteTeamUserMutation, DeleteTeamUserVariables>
        mutation={DeleteTeamUserDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteTeamUserProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<DeleteTeamUserMutation, DeleteTeamUserVariables>
> &
  TChildProps;
export type DeleteTeamUserMutationFn = ReactApollo.MutationFn<
  DeleteTeamUserMutation,
  DeleteTeamUserVariables
>;
export function DeleteTeamUserHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteTeamUserMutation,
        DeleteTeamUserVariables,
        DeleteTeamUserProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteTeamUserMutation,
    DeleteTeamUserVariables,
    DeleteTeamUserProps<TChildProps>
  >(DeleteTeamUserDocument, operationOptions);
}
export const SelectTeamDocument = gql`
  query SelectTeam($teamId: Int!) {
    team(teamId: $teamId) {
      id
      name
      department
      createdAt
      leadUser {
        id
        fullName
        profileImageUrl
      }
      teamUsers {
        id
        user {
          id
          fullName
          profileImageUrl
        }
      }
    }
  }
`;
export class SelectTeamComponent extends React.Component<
  Partial<ReactApollo.QueryProps<SelectTeamQuery, SelectTeamVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<SelectTeamQuery, SelectTeamVariables>
        query={SelectTeamDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SelectTeamProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<SelectTeamQuery, SelectTeamVariables>
> &
  TChildProps;
export function SelectTeamHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SelectTeamQuery,
        SelectTeamVariables,
        SelectTeamProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SelectTeamQuery,
    SelectTeamVariables,
    SelectTeamProps<TChildProps>
  >(SelectTeamDocument, operationOptions);
}
export const UpdateTeamDocument = gql`
  mutation UpdateTeam($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      team {
        id
        name
        department
        createdAt
        leadUser {
          id
          fullName
          profileImageUrl
        }
      }
    }
  }
`;
export class UpdateTeamComponent extends React.Component<
  Partial<ReactApollo.MutationProps<UpdateTeamMutation, UpdateTeamVariables>>
> {
  render() {
    return (
      <ReactApollo.Mutation<UpdateTeamMutation, UpdateTeamVariables>
        mutation={UpdateTeamDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type UpdateTeamProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<UpdateTeamMutation, UpdateTeamVariables>
> &
  TChildProps;
export type UpdateTeamMutationFn = ReactApollo.MutationFn<
  UpdateTeamMutation,
  UpdateTeamVariables
>;
export function UpdateTeamHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        UpdateTeamMutation,
        UpdateTeamVariables,
        UpdateTeamProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    UpdateTeamMutation,
    UpdateTeamVariables,
    UpdateTeamProps<TChildProps>
  >(UpdateTeamDocument, operationOptions);
}
export const CreateTeamDocument = gql`
  mutation CreateTeam($input: CreateTeamInput!) {
    createTeam(input: $input) {
      team {
        id
      }
    }
  }
`;
export class CreateTeamComponent extends React.Component<
  Partial<ReactApollo.MutationProps<CreateTeamMutation, CreateTeamVariables>>
> {
  render() {
    return (
      <ReactApollo.Mutation<CreateTeamMutation, CreateTeamVariables>
        mutation={CreateTeamDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type CreateTeamProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<CreateTeamMutation, CreateTeamVariables>
> &
  TChildProps;
export type CreateTeamMutationFn = ReactApollo.MutationFn<
  CreateTeamMutation,
  CreateTeamVariables
>;
export function CreateTeamHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        CreateTeamMutation,
        CreateTeamVariables,
        CreateTeamProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    CreateTeamMutation,
    CreateTeamVariables,
    CreateTeamProps<TChildProps>
  >(CreateTeamDocument, operationOptions);
}
export const DeleteTeamDocument = gql`
  mutation DeleteTeam($input: DeleteTeamInput!) {
    deleteTeam(input: $input) {
      wasSuccessful
    }
  }
`;
export class DeleteTeamComponent extends React.Component<
  Partial<ReactApollo.MutationProps<DeleteTeamMutation, DeleteTeamVariables>>
> {
  render() {
    return (
      <ReactApollo.Mutation<DeleteTeamMutation, DeleteTeamVariables>
        mutation={DeleteTeamDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type DeleteTeamProps<TChildProps = any> = Partial<
  ReactApollo.MutateProps<DeleteTeamMutation, DeleteTeamVariables>
> &
  TChildProps;
export type DeleteTeamMutationFn = ReactApollo.MutationFn<
  DeleteTeamMutation,
  DeleteTeamVariables
>;
export function DeleteTeamHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        DeleteTeamMutation,
        DeleteTeamVariables,
        DeleteTeamProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    DeleteTeamMutation,
    DeleteTeamVariables,
    DeleteTeamProps<TChildProps>
  >(DeleteTeamDocument, operationOptions);
}
export const SelectTeamsDocument = gql`
  query SelectTeams {
    teams {
      items {
        id
        name
        department
        createdAt
        leadUser {
          id
          fullName
          profileImageUrl
        }
      }
    }
  }
`;
export class SelectTeamsComponent extends React.Component<
  Partial<ReactApollo.QueryProps<SelectTeamsQuery, SelectTeamsVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<SelectTeamsQuery, SelectTeamsVariables>
        query={SelectTeamsDocument}
        {...(this as any)["props"] as any}
      />
    );
  }
}
export type SelectTeamsProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<SelectTeamsQuery, SelectTeamsVariables>
> &
  TChildProps;
export function SelectTeamsHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        SelectTeamsQuery,
        SelectTeamsVariables,
        SelectTeamsProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    SelectTeamsQuery,
    SelectTeamsVariables,
    SelectTeamsProps<TChildProps>
  >(SelectTeamsDocument, operationOptions);
}
