import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  DialogContent,
  DialogTitle,
  Dialog,
  TextField,
  DialogActions,
  Button,
  InputAdornment
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { DatePicker } from "material-ui-pickers";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { FieldWrapper } from "../../../common/styled/field-wrapper";
import AlertError from "../../../common/styled/alert-error";
import { isDateDisabled } from "../../../../utilities/date-helpers";
import { transformGraphQLErrorForFormik } from "../../../../utilities/form-helpers";
import Loader from "../../../common/loader";
import AppIcon from "../../../common/app-icon";

class AddProjectDialog extends Component {
  state = {};

  onFormSubmit = async (values, { setErrors, setSubmitting }) => {
    const {
      customer,
      createCustomerProjectMutation,
      onProjectCreated
    } = this.props;
    setSubmitting(true);
    try {
      await createCustomerProjectMutation({
        variables: {
          input: {
            name: values.name,
            amount: parseInt(values.amount, 10),
            customerId: customer.id,
            startDate: moment(values.startDate).format("YYYY-MM-DD"),
            notes: values.notes,
            includesStrategy: false
          }
        }
      });
      setSubmitting(false);
      setErrors({});
      onProjectCreated();
    } catch (e) {
      setErrors(transformGraphQLErrorForFormik(e));
      setSubmitting(false);
    }
  };

  render() {
    const { onCancel } = this.props;
    return (
      <Formik
        onSubmit={this.onFormSubmit}
        initialValues={{
          name: "",
          amount: "",
          startDate: moment().nextBusinessDay(),
          notes: "",
          includesStrategy: false
        }}
        render={({
          values,
          handleChange,
          setFieldValue,
          handleSubmit,
          errors,
          isSubmitting
        }) => (
          <Form>
            <Dialog
              fullWidth
              open
              onClose={onCancel}
              maxWidth="md"
              disableBackdropClick
            >
              <DialogTitle>New One-Time Project</DialogTitle>
              <DialogContent>
                {errors.global && <AlertError message={errors.global} />}
                <FieldWrapper>
                  <TextField
                    autoFocus
                    label="Project Name"
                    id="name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    fullWidth
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    label="Amount"
                    id="amount"
                    name="amount"
                    value={values.amount}
                    placeholder="0.00"
                    onChange={handleChange}
                    fullWidth
                    error={errors.amount}
                    helperText={errors.amount}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AppIcon icon={faDollarSign} color="grey" />
                        </InputAdornment>
                      )
                    }}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <DatePicker
                    label="Start Date"
                    value={values.startDate}
                    format="M/D/YYYY"
                    maxDate={moment()
                      .nextBusinessDay()
                      .add(1, "year")}
                    placeholder={moment()
                      .nextBusinessDay()
                      .format("M/D/YYYY")}
                    shouldDisableDate={isDateDisabled(true, true, false)}
                    // handle clearing outside => pass plain array if you are not controlling value outside
                    disableOpenOnEnter
                    animateYearScrolling={false}
                    fullWidth
                    onChange={date => setFieldValue("startDate", date)}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    label="Notes"
                    id="notes"
                    name="notes"
                    value={values.notes}
                    onChange={handleChange}
                    fullWidth
                    multiline
                  />
                </FieldWrapper>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  {!isSubmitting && (
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ marginRight: ".5em" }}
                    />
                  )}
                  {isSubmitting && <Loader />}
                  Create Project
                </Button>
                <Button
                  style={{ marginLeft: ".5em" }}
                  onClick={onCancel}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        )}
      />
    );
  }
}

AddProjectDialog.propTypes = {
  onCancel: PropTypes.func.isRequired
};

export default AddProjectDialog;
