import React, { Component } from "react";
import {
  Button,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Switch
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faTimes,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import { Formik, FormikActions } from "formik";
import { QueryResult, MutationFn } from "react-apollo";
import MarkdownEditor from "../../common/markdown-editor";
import AppIcon from "../../common/app-icon";
import { ChildWorkspaceCommonProps } from "../task-workspace/workspace";
import { TableHeaderCell } from "../../common/table-header-cell";
import {
  SelectInfographicBatchReviewTaskWorkspaceQuery,
  SelectInfographicBatchReviewTaskWorkspaceVariables,
  CreateInfographicReviewItemsVariables,
  CreateInfographicReviewItemsMutation
} from "../../../generated/graphql";
import Loader from "../../common/loader";
import ErrorDisplay from "../../common/error-display";
import Alert from "../../common/styled/alert-error";
import { transformGraphQLErrorForFormik } from "../../../utilities/form-helpers";
import BatchReviewIntsruction from "./batch-review-instruction";
import Text from "../../common/styled/text";
import DangerButton from "../../common/styled/danger-button";

interface Props extends ChildWorkspaceCommonProps {
  queryResult: QueryResult<
    SelectInfographicBatchReviewTaskWorkspaceQuery,
    SelectInfographicBatchReviewTaskWorkspaceVariables
  >;
  createInfographicReviewItemsMutation: MutationFn<
    CreateInfographicReviewItemsMutation,
    CreateInfographicReviewItemsVariables
  >;
}

interface State {}

interface FormValues {
  global?: string;
  customerProductBatchId: number;
  items: {
    customerProductId: number;
    isApproved: boolean;
    comments: string;
  }[];
}

class Workspace extends Component<Props, State> {
  state = {};

  onCommentChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ comments: e.target.value });
  };

  onPhotoApprovedChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    this.setState({ isPhotoApproved: checked });
  };

  onEditingApprovedChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    this.setState({ isEditingApproved: checked });
  };

  onSubmit = async (
    values: FormValues,
    formikActions: FormikActions<FormValues>
  ) => {
    const { onComplete } = this.props;
    try {
      const { createInfographicReviewItemsMutation, task } = this.props;
      await createInfographicReviewItemsMutation({
        variables: {
          input: {
            reviewTaskId: task.id,
            customerProductBatchId: values.customerProductBatchId,
            items: values.items.map(item => ({
              customerProductId: item.customerProductId,
              needsRevision: !item.isApproved,
              comments: item.comments
            }))
          }
        }
      });
      const itemsNeedingRevision = values.items.filter(
        item => !item.isApproved
      );
      await onComplete(
        itemsNeedingRevision.length === 0,
        itemsNeedingRevision.length === 0
          ? undefined
          : "Not all customer products were approved."
      );
    } catch (e) {
      formikActions.setErrors(transformGraphQLErrorForFormik(e));
      formikActions.setSubmitting(false);
    }
  };

  render() {
    const { onClose, queryResult } = this.props;
    if (queryResult.loading) {
      return <Loader />;
    }
    if (queryResult.error) {
      return <ErrorDisplay error={queryResult.error} />;
    }
    if (!queryResult.data || !queryResult.data.customerProductBatch) {
      return <Alert message="Something went wrong." />;
    }
    const { customerProducts } = queryResult.data.customerProductBatch;
    return (
      <Formik
        onSubmit={this.onSubmit}
        initialValues={{
          customerProductBatchId: queryResult.data.customerProductBatch.id,
          items: customerProducts.map(cp => ({
            customerProductId: cp.id,
            isApproved: true,
            comments: ""
          }))
        }}
        render={({
          values,
          errors,
          isSubmitting,
          handleChange,
          setFieldValue,
          handleSubmit
        }) => {
          const itemsNeedingRevision = values.items.filter(
            item => !item.isApproved
          );
          return (
            <div>
              {errors.global && <Alert message={errors.global} />}
              <Text.HeaderXSmall>Instructions:</Text.HeaderXSmall>
              <BatchReviewIntsruction />
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeaderCell>SKU</TableHeaderCell>
                    <TableHeaderCell>Is Approved</TableHeaderCell>
                    <TableHeaderCell>Comments</TableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customerProducts.map((customerProduct, index) => (
                    <TableRow key={customerProduct.id}>
                      <TableCell>
                        {customerProduct.customerSku
                          ? customerProduct.customerSku.sku
                          : "Unknown"}
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            color: values.items[index].isApproved
                              ? "green"
                              : "red"
                          }}
                        >
                          <Switch
                            color="primary"
                            onChange={(e: any, checked) =>
                              setFieldValue(
                                `items[${index}].isApproved`,
                                checked
                              )
                            }
                            checked={values.items[index].isApproved}
                          />
                        </div>
                      </TableCell>
                      <TableCell>
                        <MarkdownEditor
                          id={`items[${index}].comments`}
                          name={`items[${index}].comments`}
                          value={values.items[index].comments}
                          onChange={handleChange}
                          error={(errors as any)[`items[${index}].comments`]}
                          helperText={
                            (errors as any)[`items[${index}].comments`]
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              <div style={{ marginTop: "4em" }}>
                {itemsNeedingRevision.length === 0 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmit()}
                    size="large"
                    disabled={isSubmitting}
                  >
                    <AppIcon icon={faCheck} standardRightMargin />
                    Approve
                  </Button>
                )}
                {itemsNeedingRevision.length > 0 && (
                  <DangerButton
                    variant="contained"
                    onClick={() => handleSubmit()}
                    size="large"
                    disabled={isSubmitting}
                  >
                    <AppIcon icon={faTimesCircle} standardRightMargin />
                    Reject
                  </DangerButton>
                )}
                <Button
                  onClick={onClose}
                  size="large"
                  style={{ marginLeft: ".5em" }}
                >
                  <FontAwesomeIcon
                    icon={faTimes}
                    style={{ marginRight: ".5em" }}
                  />
                  Close
                </Button>
              </div>
            </div>
          );
        }}
      />
    );
  }
}

export default Workspace;
