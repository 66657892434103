import React, { Component, Fragment } from "react";
import { TextField, Tab, Tabs, Link } from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import MarkdownCheatSheetPDF from "../../../assets/nozani-markdown-cheatsheet.pdf";
interface Props {
  value: string;
  id: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  preview?: React.ReactNode;
  error?: boolean;
  helperText?: string;
}

interface State {
  currentView: string;
}

class MarkdownEditor extends Component<Props, State> {
  state = {
    currentView: "write"
  };

  handleChange = (e: React.ChangeEvent<{}>, value: any) => {
    this.setState({ currentView: value });
  };

  render() {
    const {
      value,
      id,
      name,
      onChange,
      preview,
      error,
      helperText
    } = this.props;
    const { currentView } = this.state;
    return (
      <Fragment>
        <Tabs value={currentView} onChange={this.handleChange}>
          <Tab value="write" label="Write" />
          <Tab value="preview" label="Preview" />
        </Tabs>
        {currentView === "write" && (
          <Fragment>
            <div style={{ marginTop: "1rem", marginBottom: ".3rem" }}>
              <TextField
                id={id}
                name={name}
                onChange={onChange}
                multiline
                fullWidth
                autoFocus
                value={value}
                variant="outlined"
                error={error}
                helperText={helperText}
              />
            </div>
            <Link
              href={MarkdownCheatSheetPDF}
              variant="subtitle2"
              underline="hover"
              target="_blank"
              rel="noopener"
            >
              Markdown Cheatsheet
            </Link>
          </Fragment>
        )}
        {currentView === "preview" && (
          <div style={{ marginTop: "1em" }}>
            {!preview && <ReactMarkdown source={value} />}
            {preview && <Fragment>{preview}</Fragment>}
          </div>
        )}
      </Fragment>
    );
  }
}

export default MarkdownEditor;
