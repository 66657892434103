import React, { FunctionComponent } from "react";

/* Material UI */
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  TableFooter
} from "@material-ui/core";

/* Common Components */
import { TableHeaderCell } from "../../common/table-header-cell";
import Loader from "../../common/loader";
import ErrorDisplay from "../../common/error-display";

/* GraphQL Imports */
import { SelectAccountManagementOverviewComponent } from "../../../generated/graphql";

/* Childe Components */
import GlobalAvatar from "../../global-avatar";

/* Helper Functions */
import { displayCustomersManaged } from "./display-customers-managed-helper";
interface Props {
  accountManagerSearch: string;
}

const AccountManagementOverviewReportCardTable: FunctionComponent<Props> = ({
  accountManagerSearch
}) => (
  <SelectAccountManagementOverviewComponent
    variables={{ search: accountManagerSearch }}
    fetchPolicy="network-only"
  >
    {({ data, loading, error, refetch }) => {
      if (loading) {
        return <Loader />;
      }

      if (error) {
        return <ErrorDisplay error={error} refetch={refetch} />;
      }

      if (!data) {
        return null;
      }

      const {
        accountManagementOverview: { items }
      } = data;

      return (
        <Table>
          <TableHead>
            <TableRow>
              <TableHeaderCell>Account Manager</TableHeaderCell>
              <TableHeaderCell>Clients Managed</TableHeaderCell>
              <TableHeaderCell>Amount Managed</TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items &&
              items.map(accountManager => (
                <TableRow>
                  <TableCell>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <GlobalAvatar
                        skipQuery
                        userId={accountManager.id}
                        fullName={accountManager.fullName}
                        profileImageUrl={accountManager.profileImageUrl}
                      />
                      <Typography
                        variant="caption"
                        noWrap
                        style={{ marginLeft: ".5rem" }}
                      >
                        {accountManager.fullName}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {accountManager.accountManagement.customersManaged &&
                        displayCustomersManaged(
                          accountManager.accountManagement.customersManaged
                            .items
                        )}
                    </div>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption">
                      {`$${accountManager.accountManagement.amountManaged.toFixed(
                        2
                      )}`}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell>Total Amount Managed</TableCell>
              <TableCell />
              <TableCell>
                <Typography color="textSecondary">
                  {`$${items &&
                    items
                      .reduce(
                        (prev, curr) =>
                          prev + curr.accountManagement.amountManaged,
                        0
                      )
                      .toFixed(2)}`}
                </Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      );
    }}
  </SelectAccountManagementOverviewComponent>
);

export default AccountManagementOverviewReportCardTable;
