import React, { Component } from "react";
import { Formik, Form, FormikActions } from "formik";
import { withSnackbar, InjectedNotistackProps } from "notistack";

/* GraphQL Imports */
import {
  SelectCustomerMwsCredentialsQuery,
  SelectCustomerMwsCredentialsVariables,
  CreateCustomerMwsCredentialMutation,
  CreateCustomerMwsCredentialVariables
} from "../../../../generated/graphql";
import { QueryResult, MutationFn } from "react-apollo";

/** Material UI Components */
import {
  TextField,
  Button,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";

/* Font Awesome */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey, faUser, faIdCard } from "@fortawesome/free-solid-svg-icons";

/* Common Components */
import { FieldWrapper } from "../../../common/styled/field-wrapper";

/* Type Form Values */
import { FormValues } from ".";

/* Utilities */
import { transformGraphQLErrorForFormik } from "../../../../utilities/form-helpers";

interface Props extends InjectedNotistackProps {
  queryResult: QueryResult<
    SelectCustomerMwsCredentialsQuery,
    SelectCustomerMwsCredentialsVariables
  >;
  createCustomerMwsCredentialMutation: MutationFn<
    CreateCustomerMwsCredentialMutation,
    CreateCustomerMwsCredentialVariables
  >;
  customerId: number;
  isAddNozaniCredentials: boolean;
  onCloseNozaniCredentialsDialog: () => any;
  onCloseOtherCredentialsDialog: () => any;
}

class MwsCredentialsDialog extends Component<Props, {}> {
  onSubmit = async (values: FormValues, actions: FormikActions<FormValues>) => {
    const { isAddNozaniCredentials } = this.props;
    const {
      queryResult,
      createCustomerMwsCredentialMutation,
      customerId,
      onCloseNozaniCredentialsDialog,
      onCloseOtherCredentialsDialog,
      enqueueSnackbar
    } = this.props;

    const closeDialog = isAddNozaniCredentials
      ? onCloseNozaniCredentialsDialog
      : onCloseOtherCredentialsDialog;

    try {
      await createCustomerMwsCredentialMutation({
        variables: {
          input: {
            customerId: customerId,
            name: isAddNozaniCredentials ? "Nozani" : values.name,
            sellerId: values.sellerId,
            authToken: values.authToken,
            isPrimary: isAddNozaniCredentials
          }
        }
      });
      queryResult.refetch();
      actions.setSubmitting(false);
      closeDialog();
    } catch (e) {
      actions.setSubmitting(false);
      const error = transformGraphQLErrorForFormik(e);
      if (error.global) {
        enqueueSnackbar(error.global, { variant: "error" });
      }
    }
  };

  render() {
    const {
      isAddNozaniCredentials,
      onCloseNozaniCredentialsDialog,
      onCloseOtherCredentialsDialog
    } = this.props;
    return (
      <Formik
        initialValues={{
          name: "",
          sellerId: "",
          authToken: ""
        }}
        onSubmit={this.onSubmit}
        render={({ values, handleChange, handleSubmit, isSubmitting }) => (
          <Dialog
            open
            onClose={
              isAddNozaniCredentials
                ? onCloseNozaniCredentialsDialog
                : onCloseOtherCredentialsDialog
            }
            fullWidth
            disableBackdropClick
            scroll="body"
          >
            <DialogTitle>
              Add {isAddNozaniCredentials ? "Nozani" : "New"} Credentials
            </DialogTitle>
            <DialogContent>
              <Form>
                {!isAddNozaniCredentials && (
                  <FieldWrapper>
                    <TextField
                      id="name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      autoFocus
                      label="Name"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FontAwesomeIcon icon={faUser} fixedWidth />
                          </InputAdornment>
                        )
                      }}
                    />
                  </FieldWrapper>
                )}
                <FieldWrapper>
                  <TextField
                    id="sellerId"
                    name="sellerId"
                    value={values.sellerId}
                    onChange={handleChange}
                    autoFocus
                    label="Seller ID"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faIdCard} fixedWidth />
                        </InputAdornment>
                      )
                    }}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    id="authToken"
                    name="authToken"
                    value={values.authToken}
                    onChange={handleChange}
                    autoFocus
                    label="Authorization Token"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faKey} fixedWidth />
                        </InputAdornment>
                      )
                    }}
                  />
                </FieldWrapper>
                <DialogActions>
                  <Button
                    onClick={
                      isAddNozaniCredentials
                        ? onCloseNozaniCredentialsDialog
                        : onCloseOtherCredentialsDialog
                    }
                    disabled={isSubmitting}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: ".5em" }}
                    type="submit"
                    onClick={() => handleSubmit()}
                    disabled={isSubmitting}
                  >
                    Add Credentials
                  </Button>
                </DialogActions>
              </Form>
            </DialogContent>
          </Dialog>
        )}
      />
    );
  }
}

export default withSnackbar(MwsCredentialsDialog);
