import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faTimes,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import { FieldWrapper } from "../../common/styled/field-wrapper";
import MarkdownEditor from "../../common/markdown-editor";
import DangerButton from "../../common/styled/danger-button";
import AppIcon from "../../common/app-icon";
import { ChildWorkspaceCommonProps } from "../task-workspace/workspace";

interface Props extends ChildWorkspaceCommonProps {}

interface State {
  approvalComment: string;
}

class Workspace extends Component<Props, State> {
  state = {
    approvalComment: ""
  };

  onApprovalCommentChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ approvalComment: e.target.value });
  };

  onApprove = async () => {
    const { onComplete } = this.props;
    const { approvalComment } = this.state;
    await onComplete(true, approvalComment);
  };

  onReject = async () => {
    const { onComplete } = this.props;
    const { approvalComment } = this.state;
    await onComplete(false, approvalComment);
  };

  render() {
    const { approvalComment } = this.state;
    const { onClose, instructionComp } = this.props;
    return (
      <div>
        {instructionComp}
        <FieldWrapper>
          <MarkdownEditor
            id="approvalComment"
            name="approvalComment"
            value={approvalComment || ""}
            onChange={this.onApprovalCommentChanged}
          />
        </FieldWrapper>
        <div style={{ marginTop: "4em" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.onApprove}
            size="large"
          >
            <FontAwesomeIcon icon={faCheck} style={{ marginRight: ".5em" }} />
            Approve
          </Button>
          <DangerButton
            onClick={this.onReject}
            variant="contained"
            size="large"
            style={{ marginLeft: ".5em" }}
          >
            <AppIcon icon={faTimesCircle} standardRightMargin />
            Reject
          </DangerButton>
          <Button onClick={onClose} size="large" style={{ marginLeft: ".5em" }}>
            <FontAwesomeIcon icon={faTimes} style={{ marginRight: ".5em" }} />
            Close
          </Button>
        </div>
      </div>
    );
  }
}

export default Workspace;
