import React, { Component, Fragment } from "react";
import { debounce, isEmpty } from "lodash";
import {
  TextField,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";

import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { QueryResult, MutationFn } from "react-apollo";
import { RouteComponentProps, withRouter } from "react-router";
import AppLink from "../common/app-link";
import Loader from "../common/loader";
import { TableHeaderCell } from "../common/table-header-cell";
import { FieldWrapper } from "../common/styled/field-wrapper";
import GlobalAvatar from "../global-avatar";
import AppIcon from "../common/app-icon";
import {
  SearchCustomersQuery,
  SearchCustomersVariables,
  CreateCustomerMutation,
  CreateCustomerVariables
} from "../../generated/graphql";
import ErrorDisplay from "../common/error-display";
import { AlertInfo, AlertError } from "../common/styled/alert";
import { transformGraphQLErrorForFormik } from "../../utilities/form-helpers";
import { faStar } from "@fortawesome/free-solid-svg-icons";
interface Props extends RouteComponentProps {
  queryResult: QueryResult<SearchCustomersQuery, SearchCustomersVariables>;
  createCustomerMutation: MutationFn<
    CreateCustomerMutation,
    CreateCustomerVariables
  >;
  onSearchChanged: (newSearchValue: string) => any;
}

interface State {
  searchText: string;
  verifyAddCustomer: boolean;
  isCreatingCustomer: boolean;
  createError: string | null;
}

class Customers extends Component<Props, State> {
  state = {
    searchText: "",
    verifyAddCustomer: false,
    isCreatingCustomer: false,
    createError: null
  };

  updateSearch = debounce(
    (searchText: string) => {
      const { onSearchChanged } = this.props;
      onSearchChanged(searchText);
    },
    350,
    { trailing: true }
  );

  onSearchChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: e.target.value });
    this.updateSearch(e.target.value);
  };

  onVerifyCreateCustomer = () => {
    this.setState({ verifyAddCustomer: true });
  };

  onVerifyCreateCustomerCancel = () => {
    this.setState({ verifyAddCustomer: false });
  };

  onCreateCustomer = async () => {
    const { createCustomerMutation } = this.props;
    const { searchText } = this.state;

    this.setState({
      isCreatingCustomer: true,
      createError: null
    });

    try {
      const customer = await createCustomerMutation({
        variables: {
          input: {
            partnerId: 1, // Nozani
            name: searchText
          }
        }
      });

      if (
        !customer ||
        !customer.data ||
        !customer.data.createCustomer.customer
      ) {
        throw new Error("Something went wrong creating the customer.");
      }

      const { history } = this.props;
      history.push(`/customers/${customer.data.createCustomer.customer.id}`);
    } catch (e) {
      const transformedError = transformGraphQLErrorForFormik(e);
      if (transformedError.global) {
        this.setState({ createError: transformedError.global });
      }
      this.setState({
        isCreatingCustomer: false
      });
    }
  };

  renderTable = () => {
    const { queryResult } = this.props;
    const { searchText } = this.state;
    if (searchText.length === 0) {
      return <AlertInfo message="Begin typing to search for customers." />;
    }
    if (
      queryResult.loading &&
      (!queryResult.data || isEmpty(queryResult.data))
    ) {
      return <Loader message="Loading customers..." />;
    }
    if (queryResult.error) {
      return <ErrorDisplay error={queryResult.error} />;
    }
    const { data } = queryResult;
    if (!data) {
      return null;
    }
    const { items: customers } = data.customers;

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell style={{ padding: "0 .2rem 0 .5rem" }} />
            <TableHeaderCell style={{ padding: "0 .2rem 0 .5rem" }}>
              Name
            </TableHeaderCell>
            <TableHeaderCell style={{ padding: "0 .2rem 0 .5rem" }}>
              Account Manager
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customers &&
            customers.map(customer => (
              <TableRow key={customer.id}>
                <TableCell
                  style={{ padding: "0 .2rem 0 .5rem", width: "1.5rem" }}
                >
                  {customer.isStarred && <AppIcon color="gold" icon={faStar} />}
                </TableCell>
                <TableCell style={{ padding: "0 .2rem 0 .5rem" }}>
                  <AppLink to={`/customers/${customer.id}`}>
                    {customer.name}
                  </AppLink>
                </TableCell>
                <TableCell style={{ padding: "0 .2rem 0 .5rem" }}>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <GlobalAvatar
                      userId={
                        customer.accountManagerUser
                          ? customer.accountManagerUser.id
                          : null
                      }
                      size="small"
                      style={{ marginRight: ".5em" }}
                    />
                    {customer.accountManagerUser
                      ? customer.accountManagerUser.fullName
                      : "Unassigned"}
                  </span>
                </TableCell>
              </TableRow>
            ))}
          <TableRow
            key="new"
            hover
            onClick={this.onVerifyCreateCustomer}
            style={{ cursor: "pointer" }}
          >
            <TableCell colSpan={2}>
              <AppIcon icon={faPlusCircle} standardRightMargin />
              Create Customer &quot;{searchText}&quot;
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  render() {
    const {
      searchText,
      verifyAddCustomer,
      isCreatingCustomer,
      createError
    } = this.state;
    return (
      <Fragment>
        {verifyAddCustomer && (
          <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="sm" open>
            <DialogTitle>Confirm New Customer</DialogTitle>
            <DialogContent>
              {createError && <AlertError message={createError} />}
              <div>
                Are you sure you want to create a new customer named &quot;
                {searchText}&quot;?
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.onCreateCustomer}
                variant="contained"
                color="primary"
                disabled={isCreatingCustomer}
              >
                {isCreatingCustomer && <Loader />} Yes, Create Customer
              </Button>
              <Button
                onClick={this.onVerifyCreateCustomerCancel}
                disabled={isCreatingCustomer}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        )}
        <div style={{ marginTop: "2rem", marginBottom: "1rem" }}>
          <FieldWrapper>
            <TextField
              onChange={this.onSearchChanged}
              value={searchText}
              variant="outlined"
              label="Search"
              fullWidth
              autoFocus
            />
          </FieldWrapper>
        </div>
        {this.renderTable()}
      </Fragment>
    );
  }
}

export default withRouter(Customers);
