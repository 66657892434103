import React, { FunctionComponent } from "react";
import { Grid } from "@material-ui/core";

/* Child Components */
import CustomersNoAssignedAccountManagerReportCard from "./customers-no-assigned-account-manager-report-card";

/* Common Components */
import PageLayout from "../../common/page-layout";

const CustomerNoAssignedAccountManagerReportContainer: FunctionComponent = () => (
  <PageLayout pageTitle="Reports - Customers With No Assigned Account Manager">
    <Grid container spacing={16}>
      <Grid item xs={12} lg={6}>
        <CustomersNoAssignedAccountManagerReportCard />
      </Grid>
    </Grid>
  </PageLayout>
);

export default CustomerNoAssignedAccountManagerReportContainer;
