import React, { Component, Fragment } from "react";
import { withSnackbar, InjectedNotistackProps } from "notistack";
import styled from "styled-components";

/* Material UI & FontAwesome */
import {
  LinearProgress,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Typography
} from "@material-ui/core";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";

/* Apollo & GraphQL */
import { MutationFn } from "react-apollo";
import { ApolloQueryResult } from "apollo-client";
import {
  SelectRoadmapCustomerProjects,
  SelectRoadmapCustomerProducts,
  DeleteCustomerProductMutation,
  DeleteCustomerProductVariables,
  SelectRoadmapCustomer,
  SelectRoadmapQuery,
  SelectRoadmapVariables,
  CustomerProjectStatusEnum,
  DeleteCustomerProductBatchMutation,
  DeleteCustomerProductBatchVariables,
  SelectRoadmapCustomerProductBatches,
  SelectRoadmap_CustomerProducts
} from "../../../../../generated/graphql";

/* Common Components */
import { StandardCard } from "../../../../common/standard-card";
import { TableHeaderCell } from "../../../../common/table-header-cell";
import AppIcon from "../../../../common/app-icon";
import ConfirmDeleteDialog from "../../../../common/confirm-delete-dialog";

/* Child Components */
import ContractDialog from "../../contract-dialog";
import ProjectDialog from "../../project-dialog";
import ProjectNotesDialog from "../../project-notes-dialog";
import ProductNotesDialog from "../../product-notes-dialog";
import ProductBatchNotesDialog from "../../product-batch-notes-dialog";
import ProductDialog from "../../product-dialog";
import ProductBatchDialog from "../../product-batch-dialog";
import AddContentDialog from "../../add-content-dialog";
import AddPhotographyDialog from "../../add-photography-dialog";
import AddProductDialog from "../../add-product-dialog";
import ProductBatch from "./product-batch";
import Product from "./product";
import AddProductActions from "./add-product-actions";
import ProjectDetails from "./project-details";

/* Utilities */
import { transformGraphQLErrorForFormik } from "../../../../../utilities/form-helpers";

/* Enums & Constants */
import { PRODUCT_GROUPS } from "./product-groups";
import { PRODUCTS } from "../../../../../constants";

const TableContainter = styled.div<{ show: boolean }>`
  transition: all 500ms ease-in-out;
  overflow: hidden;
  max-height: ${props => (props.show ? "1000px" : 0)};
`;

interface Props extends InjectedNotistackProps {
  refetchCustomerProjects: (
    variables?: SelectRoadmapVariables | undefined
  ) => Promise<ApolloQueryResult<SelectRoadmapQuery>>;
  customerProject: SelectRoadmapCustomerProjects;
  deleteCustomerProductMutation: MutationFn<
    DeleteCustomerProductMutation,
    DeleteCustomerProductVariables
  >;
  deleteCustomerProductBatchMutation: MutationFn<
    DeleteCustomerProductBatchMutation,
    DeleteCustomerProductBatchVariables
  >;
  customer: SelectRoadmapCustomer;
}

interface IsConirmDeleteDialog {
  isConfirmDeleteOpen: boolean;
  onConfirmDelete: () => any;
  onDeleteCancel: () => any;
  deleteMessage: string;
}

interface State {
  showContractDetails: boolean;
  showProjectDetails: boolean;
  showProjectNotes: boolean;
  selectedProductForNotes: SelectRoadmapCustomerProducts | null;
  selectedProduct: SelectRoadmapCustomerProducts | null;
  anchorElement: any;
  productCategoryToAdd: string | null;
  selectedProductBatchForNotes: SelectRoadmapCustomerProductBatches | null;
  selectedProductBatchId: number | null;
  isConfirmDeleteDialog: IsConirmDeleteDialog;
  showCompletedWork: boolean;
}
class ProjectCard extends Component<Props, State> {
  state = {
    showContractDetails: false,
    showProjectDetails: false,
    showProjectNotes: false,
    selectedProductForNotes: null,
    selectedProduct: null,
    anchorElement: null,
    productCategoryToAdd: null,
    selectedProductBatchForNotes: null,
    selectedProductBatchId: null,
    isConfirmDeleteDialog: {
      isConfirmDeleteOpen: false,
      onConfirmDelete: () => {},
      onDeleteCancel: () => {},
      deleteMessage: ""
    },
    showCompletedWork: false
  };

  onProductsAdded = () => {
    const { refetchCustomerProjects } = this.props;
    this.setState({ productCategoryToAdd: null });
    refetchCustomerProjects();
  };

  onAddProductCancel = () => {
    this.setState({ productCategoryToAdd: null });
  };

  onOpenContract = () => {
    this.setState({ showContractDetails: true });
  };

  onCloseContract = () => {
    const { refetchCustomerProjects } = this.props;
    this.setState({ showContractDetails: false });
    refetchCustomerProjects();
  };

  onProjectOpen = () => {
    this.setState({ showProjectDetails: true });
  };

  onProjectClose = () => {
    const { refetchCustomerProjects } = this.props;
    this.setState({ showProjectDetails: false });
    refetchCustomerProjects();
  };

  onProjectUpdated = () => {
    const { refetchCustomerProjects } = this.props;
    refetchCustomerProjects();
    this.setState({ showProjectDetails: false });
  };

  onProjectNotesOpen = () => {
    this.setState({ showProjectNotes: true });
  };

  onProjectNotesClose = () => {
    this.setState({ showProjectNotes: false });
  };

  onProductNotesOpen = (product: SelectRoadmapCustomerProducts) => {
    this.setState({ selectedProductForNotes: product });
  };

  onProductNotesClose = () => {
    this.setState({ selectedProductForNotes: null });
  };

  onProductBatchNotesOpen = (
    productBatch: SelectRoadmapCustomerProductBatches
  ) => {
    this.setState({ selectedProductBatchForNotes: productBatch });
  };

  onProductBatchNotesClose = () => {
    this.setState({ selectedProductBatchForNotes: null });
  };

  onProductUpdated = () => {
    const { refetchCustomerProjects } = this.props;
    refetchCustomerProjects();
    this.onProductClose();
  };

  onProductOpen = (product: SelectRoadmapCustomerProducts) => {
    this.setState({ selectedProduct: product });
  };

  onProductClose = () => {
    this.setState({ selectedProduct: null });
  };

  onProductBatchOpen = (customerProductBatchId: number) => {
    this.setState({
      selectedProductBatchId: customerProductBatchId
    });
  };

  onProductBatchClose = () => {
    this.setState({
      selectedProductBatchId: null
    });
  };

  /* Opens the Confirm Delete Dialog for Product Deletion*/
  onConfirmDeleteCustomerProduct = (
    customerProduct: SelectRoadmapCustomerProducts
  ) => {
    this.setState({
      isConfirmDeleteDialog: {
        isConfirmDeleteOpen: true,
        onConfirmDelete: () => this.onDeleteCustomerProduct(customerProduct),
        onDeleteCancel: this.onDeleteDialogCancel,
        deleteMessage: "Customer Product"
      }
    });
  };

  /* Closes the Confirm Delete Dialog */
  onDeleteDialogCancel = () => {
    this.setState({
      isConfirmDeleteDialog: {
        ...this.state.isConfirmDeleteDialog,
        isConfirmDeleteOpen: false
      }
    });
  };

  onDeleteCustomerProduct = async (
    customerProduct: SelectRoadmapCustomerProducts
  ) => {
    const {
      deleteCustomerProductMutation,
      enqueueSnackbar,
      refetchCustomerProjects
    } = this.props;

    if (!customerProduct || !customerProduct.id) {
      return null;
    }

    try {
      await deleteCustomerProductMutation({
        variables: { input: { customerProductId: customerProduct.id } }
      });
      enqueueSnackbar("The Customer Product was removed successfully!", {
        variant: "success"
      });
      refetchCustomerProjects();
      this.setState({
        isConfirmDeleteDialog: {
          ...this.state.isConfirmDeleteDialog,
          isConfirmDeleteOpen: false
        }
      });
    } catch (e) {
      const error = transformGraphQLErrorForFormik(e);
      if (error.global) {
        enqueueSnackbar(error.global, { variant: "error" });
      }
    }
  };

  /* Opens the Confirm Delete Dialog for Product Batch Deletion*/
  onConfirmDeleteCustomerProductBatch = (customerProductBatchId: number) => {
    this.setState({
      isConfirmDeleteDialog: {
        isConfirmDeleteOpen: true,
        onConfirmDelete: () =>
          this.onDeleteCustomerProductBatch(customerProductBatchId),
        onDeleteCancel: this.onDeleteDialogCancel,
        deleteMessage: "Customer Product Batch"
      }
    });
  };

  onDeleteCustomerProductBatch = async (customerProductBatchId: number) => {
    const {
      deleteCustomerProductBatchMutation,
      enqueueSnackbar,
      refetchCustomerProjects
    } = this.props;

    try {
      await deleteCustomerProductBatchMutation({
        variables: {
          input: {
            customerProductBatchId
          }
        }
      });
      enqueueSnackbar("The Customer Product Batch was removed successfully!", {
        variant: "success"
      });
      this.setState({
        isConfirmDeleteDialog: {
          ...this.state.isConfirmDeleteDialog,
          isConfirmDeleteOpen: false
        }
      });
      refetchCustomerProjects();
    } catch (e) {
      const error = transformGraphQLErrorForFormik(e);
      if (error.global) {
        enqueueSnackbar(error.global, { variant: "error" });
      }
    }
  };

  onAddSomethingElseClick = (e: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorElement: e.currentTarget });
  };

  onAddSomethingElseClose = () => {
    this.setState({ anchorElement: null });
  };

  onAddProduct = (productCategory: string) => {
    this.setState({
      productCategoryToAdd: productCategory,
      anchorElement: null
    });
  };

  onToggleCompletedWork = () => {
    const { showCompletedWork } = this.state;
    this.setState({ showCompletedWork: !showCompletedWork });
  };

  verifyAllProductsComplete = (
    products: SelectRoadmap_CustomerProducts[],
    productBatches: SelectRoadmapCustomerProductBatches[]
  ) => {
    const isAllProductsComplete = products.every(
      product => product.status.id === CustomerProjectStatusEnum.Completed
    );

    let isAllProductBatchesComplete;

    if (productBatches.length === 0) {
      isAllProductBatchesComplete = true;
    } else {
      for (let i = 0; i <= productBatches.length - 1; i += 1) {
        isAllProductBatchesComplete = productBatches[i].customerProducts.every(
          cp => cp.status.id === CustomerProjectStatusEnum.Completed
        );
      }
    }
    return isAllProductsComplete && isAllProductBatchesComplete;
  };

  renderProductBatchDialog = () => {
    const { selectedProductBatchId } = this.state;
    if (!selectedProductBatchId) {
      return null;
    }
    const {
      customerProject,
      deleteCustomerProductMutation,
      refetchCustomerProjects
    } = this.props;
    const customerProductBatch = customerProject.customerProductBatches.find(
      customerProductBatch => customerProductBatch.id === selectedProductBatchId
    );
    if (!customerProductBatch) {
      return null;
    }
    return (
      <ProductBatchDialog
        customerProductBatch={customerProductBatch}
        customerProducts={customerProductBatch.customerProducts}
        deleteCustomerProductMutation={deleteCustomerProductMutation}
        onDeleteCustomerProduct={this.onDeleteCustomerProduct}
        onCancel={this.onProductBatchClose}
        refetchCustomerProjects={refetchCustomerProjects}
      />
    );
  };

  render() {
    const { customerProject, customer, refetchCustomerProjects } = this.props;
    const {
      showContractDetails,
      showProjectDetails,
      showProjectNotes,
      selectedProductForNotes,
      selectedProduct,
      anchorElement,
      productCategoryToAdd,
      selectedProductBatchForNotes,
      isConfirmDeleteDialog,
      showCompletedWork
    } = this.state;
    const productTotalAmount = customerProject.customerProducts.reduce(
      (runningTotal: number, p) => runningTotal + p.amount,
      0
    );
    const percentBudgetUsed = Math.round(
      (productTotalAmount / customerProject.amount) * 100
    );

    const isAllProductsComplete = this.verifyAllProductsComplete(
      customerProject.customerProducts,
      customerProject.customerProductBatches
    );

    return (
      <Fragment>
        {isConfirmDeleteDialog.isConfirmDeleteOpen && (
          <ConfirmDeleteDialog
            onConfirmDelete={isConfirmDeleteDialog.onConfirmDelete}
            onDeleteCancel={isConfirmDeleteDialog.onDeleteCancel}
            deleteMessage={isConfirmDeleteDialog.deleteMessage}
          />
        )}
        <StandardCard style={{ marginBottom: "1em" }}>
          <ProjectDetails
            customerProject={customerProject}
            isAllProductsComplete={isAllProductsComplete}
            onOpenContract={this.onOpenContract}
            onProjectNotesOpen={this.onProjectNotesOpen}
            onProjectOpen={this.onProjectOpen}
            percentBudgetUsed={percentBudgetUsed}
            productTotalAmount={productTotalAmount}
          />
          {customerProject.status.id === CustomerProjectStatusEnum.Completed &&
            isAllProductsComplete && (
              <div
                style={{
                  margin: ".5rem 0",
                  display: "flex",
                  alignItems: "center",
                  color: "#2196f3",
                  cursor: "pointer"
                }}
                onClick={this.onToggleCompletedWork}
              >
                <Typography color="primary" variant="caption">
                  {`${showCompletedWork ? "Hide" : "Show"} completed work`}
                </Typography>
                <AppIcon
                  standardLeftMargin
                  icon={showCompletedWork ? faCaretDown : faCaretUp}
                />
              </div>
            )}
          {productCategoryToAdd === PRODUCT_GROUPS.LISTING_CONTENT_CREATION && (
            <AddContentDialog
              customer={customer}
              customerProject={customerProject}
              onClose={this.onAddProductCancel}
              onProductsAdded={this.onProductsAdded}
            />
          )}
          {productCategoryToAdd === PRODUCT_GROUPS.EBC_TEMPLATE && (
            <AddProductDialog
              customer={customer}
              customerProject={customerProject}
              onClose={this.onAddProductCancel}
              productId={PRODUCTS.EBC_TEMPLATE}
              onProductsAdded={this.onProductsAdded}
            />
          )}
          {productCategoryToAdd === PRODUCT_GROUPS.SOCIAL_POSTING && (
            <AddProductDialog
              customer={customer}
              customerProject={customerProject}
              onClose={this.onAddProductCancel}
              productId={PRODUCTS.SOCIAL_POSTING}
              onProductsAdded={this.onProductsAdded}
            />
          )}
          {productCategoryToAdd === PRODUCT_GROUPS.SOCIAL_ADVERTISING && (
            <AddProductDialog
              customer={customer}
              customerProject={customerProject}
              onClose={this.onAddProductCancel}
              productId={PRODUCTS.SOCIAL_ADVERTISING}
              onProductsAdded={this.onProductsAdded}
            />
          )}
          {productCategoryToAdd ===
            PRODUCT_GROUPS.SPONSORED_ADS_PPC_MANAGEMENT && (
            <AddProductDialog
              customer={customer}
              customerProject={customerProject}
              onClose={this.onAddProductCancel}
              productId={PRODUCTS.SPONSORED_ADS_PPC_MANAGEMENT}
              onProductsAdded={this.onProductsAdded}
            />
          )}
          {productCategoryToAdd ===
            PRODUCT_GROUPS.SPONSORED_BRANDS_PPC_MANAGEMENT && (
            <AddProductDialog
              customer={customer}
              customerProject={customerProject}
              onClose={this.onAddProductCancel}
              productId={PRODUCTS.SPONSORED_BRANDS_PPC_MANAGEMENT}
              onProductsAdded={this.onProductsAdded}
            />
          )}
          {productCategoryToAdd ===
            PRODUCT_GROUPS.PRODUCT_DISPLAY_ADS_PPC_MANAGEMENT && (
            <AddProductDialog
              customer={customer}
              customerProject={customerProject}
              onClose={this.onAddProductCancel}
              productId={PRODUCTS.PRODUCT_DISPLAY_ADS_PPC_MANAGEMENT}
              onProductsAdded={this.onProductsAdded}
            />
          )}
          {productCategoryToAdd === PRODUCT_GROUPS.KEYWORD_RESEARCH && (
            <AddProductDialog
              customer={customer}
              customerProject={customerProject}
              onClose={this.onAddProductCancel}
              productId={PRODUCTS.KEYWORDS}
              onProductsAdded={this.onProductsAdded}
            />
          )}
          {productCategoryToAdd === PRODUCT_GROUPS.PHOTOGRAPHY && (
            <AddPhotographyDialog
              customer={customer}
              customerProject={customerProject}
              onClose={this.onAddProductCancel}
              onProductsAdded={this.onProductsAdded}
            />
          )}
          {showContractDetails && (
            <ContractDialog
              onCancel={this.onCloseContract}
              customerContractId={customerProject.customerContractId}
            />
          )}
          {showProjectDetails && (
            <ProjectDialog
              onCancel={this.onProjectClose}
              customerProject={customerProject}
              onProjectUpdated={this.onProjectUpdated}
              refetchCustomerProjects={refetchCustomerProjects}
            />
          )}
          {showProjectNotes && (
            <ProjectNotesDialog
              customerId={customer.id}
              onCancel={this.onProjectNotesClose}
              customerProject={customerProject}
            />
          )}
          {selectedProductForNotes && (
            <ProductNotesDialog
              customerId={customer.id}
              onCancel={this.onProductNotesClose}
              customerProduct={selectedProductForNotes}
              refreshRoadmap={refetchCustomerProjects}
            />
          )}
          {selectedProductBatchForNotes && (
            <ProductBatchNotesDialog
              onCancel={this.onProductBatchNotesClose}
              customerProductBatch={selectedProductBatchForNotes}
              refreshRoadmap={refetchCustomerProjects}
              customerId={customer.id}
            />
          )}
          {selectedProduct && (
            <ProductDialog
              customerProduct={selectedProduct}
              onProductUpdated={this.onProductUpdated}
              onCancel={this.onProductClose}
            />
          )}

          {this.renderProductBatchDialog()}

          <TableContainter
            show={
              (customerProject.status.id !==
                CustomerProjectStatusEnum.Completed &&
                !isAllProductsComplete) ||
              showCompletedWork
            }
          >
            <LinearProgress
              variant="determinate"
              value={percentBudgetUsed}
              color={percentBudgetUsed > 100 ? "secondary" : "primary"}
            />
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeaderCell>Actions</TableHeaderCell>
                  <TableHeaderCell>ID</TableHeaderCell>
                  <TableHeaderCell>Start</TableHeaderCell>
                  <TableHeaderCell>Estimated Completion</TableHeaderCell>
                  <TableHeaderCell>Product</TableHeaderCell>
                  <TableHeaderCell>SKU</TableHeaderCell>
                  <TableHeaderCell>Amount</TableHeaderCell>
                  <TableHeaderCell>Status</TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customerProject &&
                  customerProject.customerProductBatches &&
                  customerProject.customerProductBatches.map(
                    customerProductBatch => (
                      <ProductBatch
                        customerProject={customerProject}
                        customerProductBatch={customerProductBatch}
                        onProductBatchOpen={this.onProductBatchOpen}
                        onConfirmDeleteCustomerProductBatch={
                          this.onConfirmDeleteCustomerProductBatch
                        }
                        onProductBatchNotesOpen={this.onProductBatchNotesOpen}
                      />
                    )
                  )}
                {customerProject.customerProducts &&
                  customerProject.customerProducts.map(customerProduct => (
                    <Product
                      customerProject={customerProject}
                      customerProduct={customerProduct}
                      onProductOpen={this.onProductOpen}
                      onConfirmDeleteCustomerProduct={
                        this.onConfirmDeleteCustomerProduct
                      }
                      onProductNotesOpen={this.onProductNotesOpen}
                    />
                  ))}
              </TableBody>
            </Table>
            {customerProject.status.id !==
              CustomerProjectStatusEnum.Completed &&
              customerProject.status.id !==
                CustomerProjectStatusEnum.Cancelled && (
                <AddProductActions
                  anchorElement={anchorElement}
                  onAddProduct={this.onAddProduct}
                  onAddSomethingElseClick={this.onAddSomethingElseClick}
                  onAddSomethingElseClose={this.onAddSomethingElseClose}
                />
              )}
          </TableContainter>
        </StandardCard>
      </Fragment>
    );
  }
}

export default withSnackbar(ProjectCard);
