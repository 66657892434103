import React, { FunctionComponent } from "react";
import { Formik, FormikActions, Form } from "formik";
import * as Yup from "yup";
import { withSnackbar, InjectedNotistackProps } from "notistack";
const moment = require("moment-business-days");

/* Material UI */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button
} from "@material-ui/core";

/* GraphQL & Apollo Imports */
import { MutationFn } from "react-apollo";
import {
  CreateStrategyTaskMutationMutation,
  CreateStrategyTaskMutationVariables,
  StrategyTasksVariables,
  StrategyTasksQuery
} from "../../../generated/graphql";

/* Common Components */
import { AlertError } from "../../common/styled/alert";
import { FieldWrapper } from "../../common/styled/field-wrapper";
import Loader from "../../common/loader";

/* Utilities */
import { transformGraphQLErrorForFormik } from "../../../utilities/form-helpers";
import { ApolloQueryResult } from "apollo-client";
import { DateTimePicker } from "material-ui-pickers";
import { isDateDisabled } from "../../../utilities/date-helpers";

interface Props extends InjectedNotistackProps {
  customerId: number;
  createStrategyTaskMutation: MutationFn<
    CreateStrategyTaskMutationMutation,
    CreateStrategyTaskMutationVariables
  >;
  setIsAddStrategyTask: React.Dispatch<React.SetStateAction<boolean>>;
  refetchStrategyTasks: (
    variables?: StrategyTasksVariables | undefined
  ) => Promise<ApolloQueryResult<StrategyTasksQuery>>;
}

interface FormValues {
  global?: string | null;
  title: string;
  dueDate: string;
}
const AddStrategyTaskDialog: FunctionComponent<Props> = (props: Props) => {
  const onSubmit = async (
    values: FormValues,
    actions: FormikActions<FormValues>
  ) => {
    const {
      customerId,
      createStrategyTaskMutation,
      refetchStrategyTasks,
      setIsAddStrategyTask,
      enqueueSnackbar
    } = props;

    try {
      await createStrategyTaskMutation({
        variables: {
          input: {
            customerId,
            title: values.title,
            dueDate: moment(values.dueDate)
              .utc()
              .toDate()
          }
        }
      });
      await refetchStrategyTasks();
      actions.setSubmitting(false);
      setIsAddStrategyTask(false);
      enqueueSnackbar("The task was created successfully.", {
        variant: "success"
      });
    } catch (e) {
      const error = actions.setErrors(transformGraphQLErrorForFormik(e));
      actions.setSubmitting(false);
      if ((error as any) && (error as any).global) {
        enqueueSnackbar((error as any).global, { variant: "error" });
      } else {
        enqueueSnackbar("There was an issue creating the task.", {
          variant: "error"
        });
      }
    }
  };

  const { setIsAddStrategyTask } = props;
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        title: "",
        dueDate: moment().nextBusinessDay()
      }}
      validationSchema={Yup.lazy(values =>
        Yup.object().shape({
          title: Yup.string()
            .max(255, "Maximum number of characters exceeded.")
            .required("You must provide a title."),
          dueDate: Yup.string().required("Must select a due date.")
        })
      )}
      render={({
        handleSubmit,
        handleChange,
        setFieldValue,
        values,
        errors,
        touched,
        isSubmitting
      }) => (
        <Dialog
          open
          onClose={() => setIsAddStrategyTask(false)}
          fullWidth
          disableBackdropClick
          scroll="body"
        >
          <DialogTitle>Add Task</DialogTitle>
          <DialogContent>
            <Form>
              {errors.global && <AlertError message={errors.global} />}
              <FieldWrapper>
                <TextField
                  label="Title"
                  id="title"
                  name="title"
                  onChange={handleChange}
                  value={values.title}
                  placeholder="Title..."
                  fullWidth
                  helperText={
                    errors.title && touched.title ? errors.title : null
                  }
                  error={!!errors.title && !!touched.title}
                />
              </FieldWrapper>
              <FieldWrapper>
                <DateTimePicker
                  value={values.dueDate}
                  fullWidth
                  onChange={date => setFieldValue("dueDate", date)}
                  label="Due Date"
                  maxDate={moment()
                    .nextBusinessDay()
                    .add(1, "year")}
                  placeholder={moment()
                    .nextBusinessDay()
                    .format("M/D/YYYY")}
                  shouldDisableDate={isDateDisabled(true, true, false)}
                  // handle clearing outside => pass plain array if you are not controlling value outside
                  disableOpenOnEnter
                  animateYearScrolling={false}
                  helperText={
                    errors.dueDate && touched.dueDate ? errors.dueDate : null
                  }
                  error={!!errors.dueDate && !!touched.dueDate}
                />
              </FieldWrapper>
            </Form>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setIsAddStrategyTask(false)}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}
              disabled={isSubmitting}
            >
              {isSubmitting && <Loader />}
              Add Task
            </Button>
          </DialogActions>
        </Dialog>
      )}
    />
  );
};

export default withSnackbar(AddStrategyTaskDialog);
