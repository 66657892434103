/* Task Type Constants */
export default {
  ACCOUNT_MANAGER_ASSIGNMENT: "account-manager-assignment",
  AMAZON_ADVERTISING: "amazon-advertising",
  BACKEND_LISTING_CREATION_AND_OPTIMIZATION:
    "backend-listing-creation-and-optimization",
  CASE_LOG_WORK: "case-log-work",
  CLIENT_COMMUNICATION: "client-communication",
  CLIENT_REQUESTED_TASKS: "client-requested-tasks",
  CLIENT_RESEARCH: "client-research",
  CUSTOM_CUSTOMER: "custom-customer",
  CUSTOM: "custom",
  CUSTOMER_SKU_ASSIGNMENT: "customer-sku-assignment",
  EXTERNAL_TOOLS: "external-tools",
  FLAT_FILE_WORK: "flat-file-work",
  HOLD_SUBTEAM_MEETING: "hold-subteam-meeting",
  INFOGRAPHIC_AM_REVIEW: "infographic-am-review",
  INFOGRAPHIC_CREATE_PROPOSAL: "infographic-create-proposal",
  INFOGRAPHIC_CREATE: "infographic-create",
  INFOGRAPHIC_CUSTOMER_REVIEW: "infographic-customer-review",
  INFOGRAPHIC_GDM_REVIEW: "infographic-gdm-review",
  INFOGRAPHIC_HOLD_SUBTEAM_MEETING: "infographic-hold-subteam-meeting",
  INFOGRAPHIC_SELECT_SOURCE: "infographic-select-source",
  INFOGRAPHIC_SHARE_WITH_CUSTOMER: "infographic-share-with-customer",
  INFOGRAPHIC_UPLOAD_TO_GOOGLE_DRIVE: "infographic-upload-to-google-drive",
  INFOGRAPHIC_WAITING_ON_CONTENT_AND_PHOTOS:
    "infographic-waiting-on-content-and-photos",
  INTERNAL_COMMUNICATION: "internal-communication",
  INVENTORY_ARRIVAL_CONFIRMED: "inventory-arrival-confirmed",
  KEYWORD_CREATION: "keyword-creation",
  LISTING_CONTENT_EXACT_CREATION: "listing-content-exact-creation",
  LISTING_CONTENT_FULL_CREATION: "listing-content-full-creation",
  LISTING_CONTENT_RECYCLED_CREATION: "listing-content-recycled-creation",
  LISTING_CONTENT_REFINING: "listing-content-refining",
  LISTING_CONTENT_REVIEW: "listing-content-review",
  MANUAL_UPLOADING: "manual-uploading",
  PHOTO_ADDITIONAL_EDITING: "photo-additional-editing",
  PHOTO_AM_PROPOSAL_REVIEW: "photo-am-proposal-review",
  PHOTO_BATCH_REVIEW: "photo-batch-review",
  PHOTO_CUSTOMER_PROPOSAL_REVIEW: "photo-customer-proposal-review",
  PHOTO_FINAL_EDITING: "photo-final-editing",
  PHOTO_GENERATE_PROPOSAL: "photo-generate-proposal",
  PHOTO_LIFESTYLE_EDITING: "photo-lifestyle-editing",
  PHOTO_LIFESTYLE_REDO_EDITING: "photo-lifestyle-redo-editing",
  PHOTO_LIFESTYLE_RESHOOT: "photo-lifestyle-reshoot",
  PHOTO_LIFESTYLE_SHOOT: "photo-lifestyle-shoot",
  PHOTO_PLAN_SHOOT: "photo-plan-shoot",
  PHOTO_PREP_LIFESTYLE_SHOOT: "photo-prep-lifestyle-shoot",
  PHOTO_PREP_STUDIO_SHOOT: "photo-prep-studio-shoot",
  PHOTO_RAW_GOOGLE_DRIVE_UPLOAD: "photo-raw-google-drive-upload",
  PHOTO_SHARE_TO_OUTSOURCED_EDITING: "photo-share-to-outsourced-editing",
  PHOTO_SHARE_WITH_CUSTOMER: "photo-share-with-customer",
  PHOTO_STUDIO_EDITING: "photo-studio-editing",
  PHOTO_STUDIO_REDO_EDITING: "photo-studio-redo-editing",
  PHOTO_STUDIO_RESHOOT: "photo-studio-reshoot",
  PHOTO_STUDIO_SHOOT: "photo-studio-shoot",
  PHOTO_UPDATE_PROPOSAL: "photo-update-proposal",
  PROMOTIONS: "promotions",
  REQUEST_INVENTORY: "request-inventory",
  SETUP_WELCOME_CALL: "setup-welcome-call",
  TASK_ASSIGNMENT: "task-assignment",
  WELCOME_CALL: "welcome-call"
};
