import gql from "graphql-tag";

export default gql`
  query addContentDialogCustomerProductItems(
    $customerId: Int!
    $customerSkuIds: [Int]
    $productIds: [String]
  ) {
    customer(customerId: $customerId) {
      id
      customerSkus(ids: $customerSkuIds) {
        items {
          id
          sku
          parent {
            id
            sku
          }
        }
      }
    }
    products(ids: $productIds) {
      items {
        id
        name
        currentProductPrice {
          id
          amount
          clientDiscountPercentage
          maxAdditionalDiscountPercentage
        }
      }
    }
  }
`;
