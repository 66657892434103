import React from "react";
import PropTypes from "prop-types";

/* Material UI */
import {
  ListItem,
  Avatar,
  ListItemText,
  ListItemSecondaryAction
} from "@material-ui/core";

/* FontAwesome */
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";

/* EditableDisplayField is used for inline form editing vs using a modal or other method to update customer or user information. See Details tab and Basic Info Card for example. */

const EditableDisplayField = ({
  label,
  value,
  icon,
  renderEdit,
  isEditing,
  secondaryAction
}) => {
  const avatar = icon || <FormatAlignJustifyIcon />;
  if (renderEdit && isEditing) {
    return renderEdit();
  }
  return (
    <ListItem>
      <Avatar>{avatar}</Avatar>
      <ListItemText
        primary={label}
        secondary={
          label === "Website" && value ? (
            <a
              href={`http://${value}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {value}
            </a>
          ) : (
            value || "-"
          )
        }
      />
      {secondaryAction && (
        <ListItemSecondaryAction>{secondaryAction}</ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

EditableDisplayField.defaultProps = {
  renderEdit: null,
  isEditing: false,
  value: null,
  icon: null,
  secondaryAction: null
};

EditableDisplayField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.node,
  renderEdit: PropTypes.func,
  isEditing: PropTypes.bool,
  icon: PropTypes.node,
  secondaryAction: PropTypes.node
};

export default EditableDisplayField;
