import React, { Component } from "react";

/* GraphQL Imports */
import {
  SelectInventoryItemsComponent,
  CreateInventoryItemComponent,
  UpdateInventoryItemComponent,
  DeleteInventoryItemComponent
} from "../../generated/graphql";

/* Child Components */
import Inventory from "./inventory";

interface Props {}

interface State {}

class InventoryContainer extends Component<Props, State> {
  state = {};

  render() {
    return (
      <DeleteInventoryItemComponent>
        {deleteInventoryItemMutation => (
          <UpdateInventoryItemComponent>
            {updateInventoryItemMutation => (
              <CreateInventoryItemComponent>
                {createInventoryItemMutation => (
                  <SelectInventoryItemsComponent fetchPolicy="network-only">
                    {queryResult => (
                      <Inventory
                        selectInventoryItemsResult={queryResult}
                        createInventoryItemMutation={
                          createInventoryItemMutation
                        }
                        updateInventoryItemMutation={
                          updateInventoryItemMutation
                        }
                        deleteInventoryItemMutation={
                          deleteInventoryItemMutation
                        }
                      />
                    )}
                  </SelectInventoryItemsComponent>
                )}
              </CreateInventoryItemComponent>
            )}
          </UpdateInventoryItemComponent>
        )}
      </DeleteInventoryItemComponent>
    );
  }
}

export default InventoryContainer;
