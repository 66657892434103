import ReactGA from "react-ga";

const GoogleAnalyticsProvider = ({ children }) => {
  if (process.env.NODE_ENV === "production") {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
  }
  return children;
};

export default GoogleAnalyticsProvider;
