import { withStyles, TableCell } from "@material-ui/core";

/* TableHeaderCell is a reusable component used to provide consistant styling across tables used in the app. */

export const TableHeaderCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.common.black
  },
  body: {
    fontSize: 14
  }
}))(TableCell);
