import React, { FunctionComponent } from "react";
import { Grid } from "@material-ui/core";

/* Child Components */
import CustomersEndingIn30DaysReportCard from "./customers-ending-in-30-days-report-card";

/* Common Components */
import PageLayout from "../../common/page-layout";

const CustomersEndingIn30DaysReportContainer: FunctionComponent = () => (
  <PageLayout pageTitle="Reports - Customers Ending in 30 Days">
    <Grid container spacing={16}>
      <Grid item xs={12} lg={6}>
        <CustomersEndingIn30DaysReportCard />
      </Grid>
    </Grid>
  </PageLayout>
);

export default CustomersEndingIn30DaysReportContainer;
