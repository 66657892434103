/* REPORTS constant is used for generating the list of Reports available under the Main Menu Reports tab. */
export const REPORTS = [
  {
    name: "Customers Ending in 30 Days",
    path: "customers-ending-in-30-days",
    description: "View all Nozani customers whose contracts end within 30 days."
  },
  {
    name: "Customers With No Assigned Account Manager",
    path: "customers-no-assigned-account-manager",
    description: "View all customers with no assigned account manager."
  },
  {
    name: "Account Management Overview",
    path: "account-management-overview",
    description:
      "View customers and amounts managed by searching a specific account manager, selecting a specific team, or grouping by teams."
  }
];

export default REPORTS;
