import gql from "graphql-tag";

export default gql`
  query selectUserProfileImage($userId: Int!) {
    user(id: $userId) {
      id
      fullName
      profileImageUrl
    }
  }
`;
