import React, { Component } from "react";
import ReactHelmet from "react-helmet";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import LogoImage from "../../assets/nozani-central-logo.png";
import MainMenu from "./main-menu";

/* PageLayout is used to wrap each view (i.e, dashboard, overview, customer, etc) which gives the overall layout (i.e. title, navbar, side navbar, etc.)  */

const drawerWidth = 200;

const styles = (theme: any) => ({
  root: {
    display: "flex"
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    boxshadow: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3
  }
});

interface Props {
  children: React.ReactNode;
  pageTitle: string;
  appBarContent?: React.ReactNode | null;
  classes: {
    root: string;
    drawer: string;
    drawerPaper: string;
    appBar: string;
    toolbar: string;
    content: string;
  };
}
class PageLayout extends Component<Props> {
  state = {};

  render() {
    const { children, pageTitle, classes, appBarContent } = this.props;

    return (
      <div className={classes.root}>
        <ReactHelmet>
          <title>
            {pageTitle ? `${pageTitle} | Nozani Central` : "Nozani Central"}
          </title>
        </ReactHelmet>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" color="inherit" noWrap>
              {appBarContent || pageTitle}
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper
          }}
          anchor="left"
        >
          <div className={classes.toolbar}>
            <img
              src={LogoImage}
              style={{ maxHeight: 60, marginLeft: "auto", marginRight: "auto" }}
              alt="Nozani Central"
            />
          </div>
          <Divider />
          <MainMenu />
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}
        </main>
      </div>
    );
  }
}

export default withStyles(styles)(PageLayout);
