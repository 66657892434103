import React, { FunctionComponent } from "react";
import moment from "moment";
import { uniqBy } from "lodash";

/* Material UI */
import { TableRow, TableCell, Button } from "@material-ui/core";

/* FontAwesome */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faComment,
  faBarcode,
  faCog
} from "@fortawesome/free-solid-svg-icons";

/* Common Components */
import AppIcon from "../../../../common/app-icon";

/* GraphQL Imports */
import {
  SelectRoadmapCustomerProductBatches,
  SelectRoadmapCustomerProjects
} from "../../../../../generated/graphql";

interface Props {
  customerProject: SelectRoadmapCustomerProjects;
  customerProductBatch: SelectRoadmapCustomerProductBatches;
  onProductBatchOpen: (customerProductBatchId: number) => void;
  onConfirmDeleteCustomerProductBatch: (customerProductBatchId: number) => void;
  onProductBatchNotesOpen: (
    productBatch: SelectRoadmapCustomerProductBatches
  ) => void;
}

const ProductBatch: FunctionComponent<Props> = ({
  customerProject,
  customerProductBatch,
  onProductBatchOpen,
  onConfirmDeleteCustomerProductBatch,
  onProductBatchNotesOpen
}) => {
  if (customerProductBatch.customerProducts.length === 0) {
    return null;
  }
  const uniqueProducts = uniqBy(
    customerProductBatch.customerProducts.map(cp => cp.product),
    "name"
  );

  const totalAmount = customerProductBatch.customerProducts.reduce(
    (total, item) => total + item.amount,
    0
  );

  const percentageOfProject = Math.round(
    (totalAmount / customerProject.amount) * 100
  );

  const earliestDueDate = customerProductBatch.customerProducts
    .map(cp => cp.dueDate)
    .reduce((earliest, date) => {
      if (!date) {
        return earliest;
      }
      if (!earliest) {
        return date;
      }
      return date < earliest ? date : earliest;
    }, null);
  return (
    <TableRow key={customerProductBatch.id}>
      <TableCell style={{ width: 300 }}>
        <Button
          size="small"
          onClick={() => onProductBatchOpen(customerProductBatch.id)}
        >
          <FontAwesomeIcon icon={faCog} />
        </Button>
        {customerProductBatch.isDeletable && (
          <Button
            size="small"
            style={{ marginLeft: ".5em" }}
            onClick={() =>
              onConfirmDeleteCustomerProductBatch(customerProductBatch.id)
            }
          >
            <AppIcon standardRightMargin icon={faTrash} />
          </Button>
        )}
        <Button
          size="small"
          style={{ marginLeft: ".5em" }}
          onClick={() => onProductBatchNotesOpen(customerProductBatch)}
        >
          <FontAwesomeIcon icon={faComment} style={{ marginRight: ".5em" }} />
          {customerProductBatch.notes
            ? customerProductBatch.notes.totalCount
            : 0}
        </Button>
      </TableCell>
      <TableCell>{customerProductBatch.id}</TableCell>
      <TableCell>
        {moment(customerProductBatch.startDate).format("M/D/YYYY")}
      </TableCell>
      <TableCell>
        {earliestDueDate ? moment(earliestDueDate).format("M/D/YYYY") : "TBD"}
      </TableCell>
      <TableCell>
        {uniqueProducts.map(product => {
          const count = customerProductBatch.customerProducts.filter(
            x => x.product.id === product.id
          ).length;
          return (
            <span key={product.id} style={{ display: "block" }}>
              <strong>{count}x</strong> {product.name}
            </span>
          );
        })}
      </TableCell>
      <TableCell>
        <FontAwesomeIcon icon={faBarcode} style={{ marginRight: ".5em" }} />
        {customerProductBatch.customerProducts.length} total
      </TableCell>
      <TableCell>
        ${totalAmount.toFixed(2)} ({percentageOfProject}%)
      </TableCell>
      <TableCell>
        {customerProductBatch.customerProducts[0].status.name}
      </TableCell>
    </TableRow>
  );
};

export default ProductBatch;
