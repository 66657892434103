import React, { Component } from "react";

/* Material UI */
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button
} from "@material-ui/core";

/* Common Components */
import AppLink from "../common/app-link";
import { TableHeaderCell } from "../common/table-header-cell";

/* GraphQL Imports */
import { SelectInventoryItemsItems } from "../../generated/graphql";
import DangerButton from "../common/styled/danger-button";

interface Props {
  inventoryItems: SelectInventoryItemsItems[];
  onEdit: (item: SelectInventoryItemsItems) => any;
  onRemove: (item: SelectInventoryItemsItems) => void;
}

interface State {}

class InventoryItemsTable extends Component<Props, State> {
  state = {};

  render() {
    const { inventoryItems, onEdit, onRemove } = this.props;
    if (!inventoryItems || inventoryItems.length === 0) {
      return "No items.";
    }
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell>Customer</TableHeaderCell>
            <TableHeaderCell>SKU</TableHeaderCell>
            <TableHeaderCell>Status</TableHeaderCell>
            <TableHeaderCell>Location</TableHeaderCell>
            <TableHeaderCell>Condition</TableHeaderCell>
            <TableHeaderCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {inventoryItems.map(inventoryItem => (
            <TableRow key={inventoryItem.id}>
              <TableCell>
                <AppLink to={`/customers/${inventoryItem.customerId}`}>
                  {inventoryItem.customer.name}
                </AppLink>
              </TableCell>
              <TableCell>
                {inventoryItem.customerSku && (
                  <span>
                    {inventoryItem.customerSku.sku} -{" "}
                    {inventoryItem.customerSku.title}
                  </span>
                )}
                {!inventoryItem.customerSku && "-"}
              </TableCell>
              <TableCell>{inventoryItem.status.name}</TableCell>
              <TableCell>
                {inventoryItem.location.name}
                {inventoryItem.storageLocation && (
                  <span> - {inventoryItem.storageLocation}</span>
                )}
              </TableCell>
              <TableCell>{inventoryItem.condition.name}</TableCell>
              <TableCell>
                <Button
                  color="primary"
                  size="small"
                  onClick={() => onEdit(inventoryItem)}
                >
                  Edit
                </Button>
                <DangerButton
                  size="small"
                  onClick={() => onRemove(inventoryItem)}
                >
                  Remove
                </DangerButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
}

export default InventoryItemsTable;
