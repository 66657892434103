import React from "react";
import Text from "../../common/styled/text";
import InstructionsContainer from "../../common/styled/instructions-container";

const RAWGoogleDriveUpload = () => (
  <InstructionsContainer>
    <Text>
      Once the images have been shot, they should be uploaded to the Photo
      Google Drive.
    </Text>
  </InstructionsContainer>
);

export default RAWGoogleDriveUpload;
