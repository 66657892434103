import React, { FunctionComponent } from "react";

/* Material UI */
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";

/** Common Components */
import AppLink from "../../common/app-link";
import { StandardCard } from "../../common/standard-card";
import { TableHeaderCell } from "../../common/table-header-cell";
import Loader from "../../common/loader";
import ErrorDisplay from "../../common/error-display";

/* GraphQL Imports */
import { SelectCustomersNoAssignedAccountManagerComponent } from "../../../generated/graphql";

const CustomersNoAssignedAccountManagerReportCard: FunctionComponent = () => (
  <SelectCustomersNoAssignedAccountManagerComponent>
    {({ data, loading, error, refetch }) => {
      if (loading) {
        return <Loader />;
      }

      if (error) {
        return <ErrorDisplay error={error} refetch={refetch} />;
      }

      if (!data) {
        return null;
      }

      const { items } = data.customers;

      return (
        <StandardCard
          title={`Customers With No Assigned Account Manager (${items.length})`}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableHeaderCell>Customer</TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items &&
                items.map(customer => (
                  <TableRow key={customer.id}>
                    <TableCell>
                      <AppLink to={`/customers/${customer.id}`}>
                        {customer.name}
                      </AppLink>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </StandardCard>
      );
    }}
  </SelectCustomersNoAssignedAccountManagerComponent>
);

export default CustomersNoAssignedAccountManagerReportCard;
