import React, { Fragment } from "react";
import Text from "../../common/styled/text";
import InstructionsContainer from "../../common/styled/instructions-container";

const CustomerProposalReview = () => (
  <InstructionsContainer>
    <Text>
      Once the client has responded to the proposal email saying that they
      accept the proposal and don’t need anything changed, the Account Manager
      should forward the email to the photographer and CC the photo manager. If
      the client requests any changes, those revisions should be sent to the
      photographer to review.
    </Text>
    <Text>
      The 10 business day turnaround time begins the day after the proposal was
      accepted and we have the product in photographable condition.
    </Text>
  </InstructionsContainer>
);

export default CustomerProposalReview;
