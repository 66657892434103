import React, { Component } from "react";
import { Formik, Form, FormikActions } from "formik";
import { withSnackbar, InjectedNotistackProps } from "notistack";

/* GraphQL Types */
import {
  SelectCustomerMwsCredentialsCustomerMwsCredentials,
  CreateCustomerMwsCredentialRevisionMutation,
  CreateCustomerMwsCredentialRevisionVariables,
  SelectCustomerMwsCredentialsQuery,
  SelectCustomerMwsCredentialsVariables
} from "../../../../generated/graphql";
import { MutationFn, QueryResult } from "react-apollo";

/** Material UI Components */
import {
  TextField,
  Button,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";

/* Font Awesome */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey, faUser, faIdCard } from "@fortawesome/free-solid-svg-icons";

/* Common Components */
import { FieldWrapper } from "../../../common/styled/field-wrapper";

/* Type Form Values */
import { FormValues } from ".";

/* Utilities */
import { transformGraphQLErrorForFormik } from "../../../../utilities/form-helpers";

interface Props extends InjectedNotistackProps {
  queryResult: QueryResult<
    SelectCustomerMwsCredentialsQuery,
    SelectCustomerMwsCredentialsVariables
  >;
  createCustomerMwsCredentialRevisionMutation: MutationFn<
    CreateCustomerMwsCredentialRevisionMutation,
    CreateCustomerMwsCredentialRevisionVariables
  >;
  currentCredential: SelectCustomerMwsCredentialsCustomerMwsCredentials | null;
  onCloseReviseCredentialDialog: () => any;
}

class MwsCredentialsRevisionDialog extends Component<Props, {}> {
  onRevisedSubmit = async (
    values: FormValues,
    actions: FormikActions<FormValues>
  ) => {
    const { currentCredential, onCloseReviseCredentialDialog } = this.props;
    const {
      queryResult,
      createCustomerMwsCredentialRevisionMutation,
      enqueueSnackbar
    } = this.props;

    if (!currentCredential) {
      return null;
    }

    try {
      await createCustomerMwsCredentialRevisionMutation({
        variables: {
          input: {
            customerMwsCredentialId: (currentCredential as any).id,
            name: values.name,
            sellerId: values.sellerId,
            authToken: values.authToken
          }
        }
      });
      queryResult.refetch();
      actions.setSubmitting(false);
      onCloseReviseCredentialDialog();
    } catch (e) {
      actions.setSubmitting(false);
      const error = transformGraphQLErrorForFormik(e);
      if (error.global) {
        enqueueSnackbar(error.global, { variant: "error" });
      }
    }
  };
  render() {
    const { currentCredential, onCloseReviseCredentialDialog } = this.props;
    return (
      <Formik
        initialValues={{
          name: currentCredential ? currentCredential.name : "",
          sellerId: currentCredential ? currentCredential.sellerId : "",
          authToken: currentCredential ? currentCredential.authToken : ""
        }}
        onSubmit={this.onRevisedSubmit}
        render={({ values, handleChange, handleSubmit, isSubmitting }) => (
          <Dialog
            open
            onClose={onCloseReviseCredentialDialog}
            fullWidth
            disableBackdropClick
            scroll="body"
          >
            <DialogTitle>
              Update {currentCredential ? currentCredential.name : ""}{" "}
              Credentials
            </DialogTitle>
            <DialogContent>
              <Form>
                <FieldWrapper>
                  <TextField
                    id="name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    autoFocus
                    label="Name"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faUser} fixedWidth />
                        </InputAdornment>
                      )
                    }}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    id="sellerId"
                    name="sellerId"
                    value={values.sellerId}
                    onChange={handleChange}
                    autoFocus
                    label="Seller ID"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faIdCard} fixedWidth />
                        </InputAdornment>
                      )
                    }}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    id="authToken"
                    name="authToken"
                    value={values.authToken}
                    onChange={handleChange}
                    autoFocus
                    label="Authorization Token"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faKey} fixedWidth />
                        </InputAdornment>
                      )
                    }}
                  />
                </FieldWrapper>
                <DialogActions>
                  <Button
                    onClick={onCloseReviseCredentialDialog}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: ".5em" }}
                    type="submit"
                    onClick={() => handleSubmit()}
                    disabled={isSubmitting}
                  >
                    Update Credentials
                  </Button>
                </DialogActions>
              </Form>
            </DialogContent>
          </Dialog>
        )}
      />
    );
  }
}

export default withSnackbar(MwsCredentialsRevisionDialog);
