import React, { FunctionComponent, useState } from "react";
import { Formik, Form, FormikActions, FormikErrors } from "formik";
import moment from "moment";
import styled from "styled-components";
import { withSnackbar, InjectedNotistackProps } from "notistack";

/* Material UI */
import {
  Button,
  List,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  InputAdornment
} from "@material-ui/core";

/* Font Awesome */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSync } from "@fortawesome/free-solid-svg-icons";

/* Common Components */
import EditableDisplayField from "../../../common/editable-display-field";
import { StandardCard } from "../../../common/standard-card";
import { FieldWrapper } from "../../../common/styled/field-wrapper";
import AppLink from "../../../common/app-link";
import DangerButton from "../../../common/styled/danger-button";

/* Utilities */
import { transformGraphQLErrorForFormik } from "../../../../utilities/form-helpers";

/* Graphql & Apollo Imports */
import {
  SelectCustomerSkuQuery,
  SelectCustomerSkuVariables,
  UpdateCustomerSkuDetailsMutation,
  UpdateCustomerSkuDetailsVariables,
  SelectCustomerSkuCustomerSku,
  GetCustomerCustomer,
  SearchCustomerSkusItems
} from "../../../../generated/graphql";
import { MutationFn } from "react-apollo";
import { ApolloQueryResult } from "apollo-client";

/* Child Components */
import SelectSkuModalContainer from "../../../select-sku-modal";
import SyncStatus, { CustomerSkuSyncStatusEnum } from "../sync-status";

/* Styled Components */
const ActionWrapper = styled.div`
  margin-bottom: 2rem;
`;

interface Error {
  global: string;
}
interface ResetForm {
  resetForm: (
    nextValues?:
      | {
          sku: string;
          title: string;
          asin: string;
          upc: string;
          skuType: string;
          parent: string | null;
        }
      | undefined
  ) => void;
}

interface FormValues {
  sku: string;
  title: string;
  asin: string;
  upc: string;
  skuType: string;
}

interface Props extends InjectedNotistackProps {
  customer: GetCustomerCustomer;
  customerSku: SelectCustomerSkuCustomerSku;
  refetch: (
    variables?: SelectCustomerSkuVariables | undefined
  ) => Promise<ApolloQueryResult<SelectCustomerSkuQuery>>;
  updateCustomerSkuDetailsMutation: MutationFn<
    UpdateCustomerSkuDetailsMutation,
    UpdateCustomerSkuDetailsVariables
  >;
}

const SkuDetailsOverview: FunctionComponent<Props> = ({
  customer,
  customerSku,
  updateCustomerSkuDetailsMutation,
  refetch,
  enqueueSnackbar
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedCustomerSku, setSelectedCustomerSku] = useState<
    SearchCustomerSkusItems | number | null
  >(null);

  const onFormSubmit = async (
    values: FormValues,
    actions: FormikActions<FormValues>
  ) => {
    try {
      await updateCustomerSkuDetailsMutation({
        variables: {
          input: {
            customerSkuId: customerSku && customerSku.id,
            sku: values.sku,
            title: values.title,
            asin: values.asin,
            upc: values.upc,
            skuType: values.skuType,
            parentCustomerSkuId: selectedCustomerSku
              ? (selectedCustomerSku as SearchCustomerSkusItems).id
              : null
          }
        }
      });
      await refetch();
      actions.setSubmitting(false);
      actions.setErrors({});
      actions.resetForm();
      setIsEditing(false);
      enqueueSnackbar("The Customer Sku was updated successfully.", {
        variant: "success"
      });
    } catch (e) {
      actions.setErrors(transformGraphQLErrorForFormik(e));
      actions.setSubmitting(false);
    }
  };

  const onReSyncSku = async (
    setErrors: (errors: FormikErrors<FormValues>) => any
  ) => {
    try {
      await updateCustomerSkuDetailsMutation({
        variables: {
          input: {
            customerSkuId: customerSku && customerSku.id,
            syncStatus: "Sync Requested"
          }
        }
      });
      await refetch();
      enqueueSnackbar("The Customer Sku Re-Synce was requested successfully.", {
        variant: "success"
      });
    } catch (e) {
      const error = setErrors(transformGraphQLErrorForFormik(e));
      if (error && error.global) {
        enqueueSnackbar(error.global, { variant: "error" });
      } else {
        enqueueSnackbar("There was an error with the Re-Sync request.", {
          variant: "error"
        });
      }
    }
  };

  const onEdit = (resetForm: ResetForm["resetForm"]) => () => {
    resetForm();
    setIsEditing(true);
  };

  const onEditCancel = (resetForm: ResetForm["resetForm"]) => () => {
    resetForm();
    setIsEditing(false);
  };

  return (
    <Formik
      onSubmit={onFormSubmit}
      initialValues={{
        sku: customerSku.sku ? customerSku.sku : "",
        title: customerSku.title ? customerSku.title : "",
        asin: customerSku.asin ? customerSku.asin : "",
        upc: customerSku.upc ? customerSku.upc : "",
        skuType: customerSku.skuType ? customerSku.skuType : "",
        parent:
          customerSku.skuType === "Variation" && customerSku.parent
            ? customerSku.parent.sku
            : null
      }}
      render={({
        values,
        handleChange,
        resetForm,
        setFieldValue,
        setErrors
      }) => (
        <Form>
          <StandardCard>
            <SelectSkuModalContainer
              visible={showModal}
              onCancel={() => setShowModal(false)}
              onCustomerSkuSelected={(
                selectedCustomerSku: SearchCustomerSkusItems | number
              ) => {
                setSelectedCustomerSku(selectedCustomerSku);
                setFieldValue(
                  "parent",
                  (selectedCustomerSku as SearchCustomerSkusItems).sku
                );
                setShowModal(false);
              }}
              customerId={customer.id}
              returnCustomerSkuObject={true}
              skuTypes={["Parent"]}
            />
            {!isEditing && (
              <ActionWrapper>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onEdit(resetForm)}
                >
                  <FontAwesomeIcon
                    icon={faEdit}
                    style={{ marginRight: ".5rem" }}
                  />
                  Edit
                </Button>
              </ActionWrapper>
            )}

            <List dense>
              <EditableDisplayField
                label="Sku"
                isEditing={isEditing}
                value={values.sku}
                renderEdit={() => (
                  <FieldWrapper>
                    <TextField
                      id="sku"
                      name="sku"
                      value={values.sku}
                      onChange={handleChange}
                      label="Sku"
                      fullWidth
                    />
                  </FieldWrapper>
                )}
              />
              <EditableDisplayField
                label="Title"
                isEditing={isEditing}
                value={values.title}
                renderEdit={() => (
                  <FieldWrapper>
                    <TextField
                      id="title"
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                      label="Title"
                      fullWidth
                    />
                  </FieldWrapper>
                )}
              />
              <EditableDisplayField
                label="ASIN"
                isEditing={isEditing}
                value={values.asin}
                renderEdit={() => (
                  <FieldWrapper>
                    <TextField
                      id="asin"
                      name="asin"
                      onChange={handleChange}
                      value={values.asin}
                      label="ASIN"
                      fullWidth
                    />
                  </FieldWrapper>
                )}
              />
              <EditableDisplayField
                label="UPC"
                isEditing={isEditing}
                value={values.upc}
                renderEdit={() => (
                  <FieldWrapper>
                    <TextField
                      id="upc"
                      name="upc"
                      onChange={handleChange}
                      value={values.upc}
                      label="UPC"
                      fullWidth
                    />
                  </FieldWrapper>
                )}
              />
              <EditableDisplayField
                label="Sku Type"
                isEditing={isEditing}
                value={values.skuType}
                renderEdit={() => (
                  <FieldWrapper>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="skuType">Sku Type</InputLabel>
                      <Select
                        id="skuType"
                        name="skuType"
                        onChange={handleChange}
                        value={values.skuType}
                        placeholder="Select a level"
                        fullWidth
                      >
                        <MenuItem value="Parent">Parent</MenuItem>
                        <MenuItem value="Variation">Variation</MenuItem>
                        <MenuItem value="Standalone">Standalone</MenuItem>
                      </Select>
                    </FormControl>
                  </FieldWrapper>
                )}
              />

              {(customerSku.skuType === "Variation" ||
                values.skuType === "Variation") && (
                <EditableDisplayField
                  label="Parent"
                  isEditing={isEditing}
                  value={
                    values.parent ? (
                      <AppLink
                        to={`/customers/${
                          customer.id
                        }/skus/${customerSku.parent && customerSku.parent.id}`}
                      >
                        {values.parent}
                      </AppLink>
                    ) : (
                      "-"
                    )
                  }
                  renderEdit={() => (
                    <FieldWrapper>
                      <TextField
                        id="parent"
                        name="parent"
                        onChange={handleChange}
                        value={values.parent || ""}
                        label="Parent"
                        fullWidth
                        inputProps={{ readOnly: true }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Button
                                size="small"
                                color="primary"
                                onClick={() => setShowModal(true)}
                              >
                                Select
                              </Button>
                            </InputAdornment>
                          )
                        }}
                      />
                    </FieldWrapper>
                  )}
                />
              )}
              <EditableDisplayField
                label="Sync Status"
                isEditing={isEditing}
                value={<SyncStatus status={customerSku.syncStatus} />}
                secondaryAction={
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    disabled={
                      customerSku.syncStatus ===
                        CustomerSkuSyncStatusEnum.REQUESTED ||
                      customerSku.syncStatus ===
                        CustomerSkuSyncStatusEnum.IN_PROGRESS
                    }
                    onClick={() => onReSyncSku(setErrors)}
                    style={{ marginLeft: ".5rem" }}
                  >
                    <FontAwesomeIcon
                      icon={faSync}
                      style={{ marginRight: ".5rem" }}
                    />
                    Re-Sync
                  </Button>
                }
                renderEdit={() => null}
              />
              <EditableDisplayField
                label="Created At"
                isEditing={isEditing}
                value={
                  customerSku.createdAt
                    ? moment(customerSku.createdAt).format("MM-DD-YYYY")
                    : null
                }
                renderEdit={() => null}
              />
              <EditableDisplayField
                label="Last Sync At"
                isEditing={isEditing}
                value={
                  customerSku.lastSyncedAt
                    ? moment(customerSku.lastSyncedAt).format("MM-DD-YYYY")
                    : null
                }
                renderEdit={() => null}
              />
              <EditableDisplayField
                label="Last Synced Attempted At"
                isEditing={isEditing}
                value={
                  customerSku.lastSyncAttemptedAt
                    ? moment(customerSku.lastSyncAttemptedAt).format(
                        "MM-DD-YYYY"
                      )
                    : null
                }
                renderEdit={() => null}
              />
            </List>
            {isEditing && (
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: ".5em" }}
                  type="submit"
                >
                  Save Changes
                </Button>
                <Button onClick={onEditCancel(resetForm)}>Cancel</Button>
              </div>
            )}
          </StandardCard>
        </Form>
      )}
    />
  );
};

export default withSnackbar(SkuDetailsOverview);
