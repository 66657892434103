import React, { Component } from "react";
import { Formik, Form, FormikActions } from "formik";
import { MutationFn } from "react-apollo";
import amplitude from "amplitude-js";
import moment from "moment";
import styled from "styled-components";

/* Material UI */
import PersonIcon from "@material-ui/icons/Person";
import {
  Button,
  List,
  TextField,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  MenuItem,
  ListItem,
  Avatar,
  ListItemText
} from "@material-ui/core";

/* Font Awesome */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faHashtag, faSmile, faGlobe } from "@fortawesome/free-solid-svg-icons";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import GlobalAvatar from "../../global-avatar";

/* Constants */
import { TRACKING_EVENTS } from "../../../constants/tracking-events";

/* Common Components */
import EditableDisplayField from "../../common/editable-display-field";
import { StandardCard } from "../../common/standard-card";
import { FieldWrapper } from "../../common/styled/field-wrapper";
import UserSelectContainer from "../../common/user-select";
import AppIcon from "../../common/app-icon";

/* Utilities */
import { transformGraphQLErrorForFormik } from "../../../utilities/form-helpers";
import { ROLES } from "../../../constants/roles";
import { getMaxEndDateColor } from "../../../utilities/max-end-date-color-helper";

/* GraphQL & Apollo Imports */
import {
  SelectCustomerWorkspaceVariables,
  SelectCustomerWorkspaceQuery,
  UpdateCustomerComponent,
  UpdateCustomerMutation,
  UpdateCustomerVariables,
  SelectCustomerWorkspaceCustomer,
  Maybe
} from "../../../generated/graphql";
import { ApolloQueryResult } from "apollo-client";

const StyledListItemText = styled(ListItemText)<{ endDateColor: string }>`
  p {
    color: ${props => props.endDateColor};
    font-weight: 600;
  }
`;

interface FormValues {
  name: string;
  url: string;
  accountManagerUserId: number | string | null;
  additionalNames: string;
  location: string;
  leadSource: string;
  pipedriveDealId: number | string | null;
  happinessLevel: string;
}

interface Props {
  customer: SelectCustomerWorkspaceCustomer;
  onUpdate: (
    variables?: SelectCustomerWorkspaceVariables | undefined
  ) => Promise<ApolloQueryResult<SelectCustomerWorkspaceQuery>>;
}

class BasicInfoContainer extends Component<Props> {
  state = {
    isEditing: false
  };

  onFormSubmit = (
    updateCustomerMutation: MutationFn<
      UpdateCustomerMutation,
      UpdateCustomerVariables
    >
  ) => async (values: FormValues, actions: FormikActions<FormValues>) => {
    const { customer, onUpdate } = this.props;
    actions.setSubmitting(true);
    try {
      await updateCustomerMutation({
        variables: {
          input: {
            customerId: customer.id,
            name: values.name,
            accountManagerUserId: Number(values.accountManagerUserId) || null,
            additionalNames: values.additionalNames,
            location: values.location,
            leadSource: values.leadSource,
            url: values.url,
            pipedriveDealId:
              values.pipedriveDealId === ""
                ? null
                : Number(values.pipedriveDealId),
            happinessLevel:
              values.happinessLevel === "" ? null : values.happinessLevel
          }
        }
      });
      actions.setSubmitting(false);
      actions.setErrors({});
      this.setState({
        isEditing: false
      });
      actions.resetForm();
      onUpdate();
    } catch (e) {
      actions.setErrors(transformGraphQLErrorForFormik(e));
      actions.setSubmitting(false);
    }
  };

  onEdit = (
    resetForm: (
      nextValues?:
        | {
            name: string;
            url: string;
            accountManagerUserId: Maybe<number>;
            additionalNames: string;
            location: string;
            leadSource: string;
            pipedriveDealId: React.ReactText;
            happinessLevel: string;
          }
        | undefined
    ) => void
  ) => {
    resetForm();
    amplitude.getInstance().logEvent(TRACKING_EVENTS.UI_CUSTOMER_EDIT);
    this.setState({ isEditing: true });
  };

  onEditCancel = (
    resetForm: (
      nextValues?:
        | {
            name: string;
            url: string;
            accountManagerUserId: Maybe<number>;
            additionalNames: string;
            location: string;
            leadSource: string;
            pipedriveDealId: React.ReactText;
            happinessLevel: string;
          }
        | undefined
    ) => void
  ) => {
    resetForm();
    this.setState({ isEditing: false });
  };

  render() {
    const { customer } = this.props;
    const { isEditing } = this.state;

    const maxEndDate = moment(customer.maxEndDate)
      .add(1, "month")
      .format("MM/DD/YYYY");

    return (
      <UpdateCustomerComponent>
        {updateCustomerMutation => (
          <Formik
            onSubmit={this.onFormSubmit(updateCustomerMutation)}
            initialValues={{
              name: customer.name || "",
              url: customer.url || "",
              accountManagerUserId: customer.accountManagerUserId || null,
              additionalNames: customer.additionalNames || "",
              location: customer.location || "",
              leadSource: customer.leadSource || "",
              pipedriveDealId: customer.pipedriveDealId || "",
              happinessLevel: customer.happinessLevel || ""
            }}
            render={({
              values,
              handleChange,
              resetForm,
              setFieldValue,
              errors
            }) => (
              <Form>
                <StandardCard title="Details">
                  {!isEditing && (
                    <div style={{ marginBottom: "2em" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.onEdit(resetForm)}
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          style={{ marginRight: ".5em" }}
                        />
                        Edit
                      </Button>
                    </div>
                  )}

                  <List dense>
                    <EditableDisplayField
                      label="Customer Name"
                      isEditing={isEditing}
                      value={customer.name}
                      renderEdit={() => (
                        <FieldWrapper>
                          <TextField
                            id="name"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            label="Name"
                            error={!!errors.name}
                            fullWidth
                          />
                        </FieldWrapper>
                      )}
                    />
                    {!isEditing && (
                      <ListItem>
                        <Avatar>
                          <AppIcon icon={faCalendar} />
                        </Avatar>
                        <StyledListItemText
                          endDateColor={getMaxEndDateColor(maxEndDate)}
                          primary="Customer End Date"
                          secondary={
                            maxEndDate === "Invalid date" ? "-" : maxEndDate
                          }
                        />
                      </ListItem>
                    )}
                    <EditableDisplayField
                      label="Website"
                      isEditing={isEditing}
                      value={customer.url}
                      renderEdit={() => (
                        <FieldWrapper>
                          <TextField
                            id="url"
                            name="url"
                            value={values.url}
                            onChange={handleChange}
                            label="Website"
                            error={!!errors.url}
                            fullWidth
                          />
                        </FieldWrapper>
                      )}
                    />
                    <EditableDisplayField
                      label="Created By User"
                      isEditing={isEditing}
                      icon={<GlobalAvatar userId={customer.createdByUser.id} />}
                      value={customer.createdByUser.fullName}
                      renderEdit={() => null}
                    />
                    <EditableDisplayField
                      label="Account Manager"
                      isEditing={isEditing}
                      value={
                        customer.accountManagerUser
                          ? customer.accountManagerUser.fullName
                          : null
                      }
                      icon={
                        customer.accountManagerUser ? (
                          <GlobalAvatar
                            userId={customer.accountManagerUser.id}
                          />
                        ) : (
                          <PersonIcon />
                        )
                      }
                      renderEdit={() => (
                        <FieldWrapper>
                          <UserSelectContainer
                            label="Account Manager"
                            userId={values.accountManagerUserId}
                            roles={[ROLES.EMPLOYEE]}
                            onUserSelected={accountManagerUserId =>
                              setFieldValue(
                                "accountManagerUserId",
                                accountManagerUserId
                              )
                            }
                            allowUnassigned
                          />
                        </FieldWrapper>
                      )}
                    />
                    <EditableDisplayField
                      label="Additional Brand Names"
                      isEditing={isEditing}
                      value={customer.additionalNames}
                      renderEdit={() => (
                        <FieldWrapper>
                          <TextField
                            id="additionalNames"
                            name="additionalNames"
                            onChange={handleChange}
                            value={values.additionalNames}
                            label="Additional Brand Names"
                            error={!!errors.additionalNames}
                            fullWidth
                          />
                        </FieldWrapper>
                      )}
                    />
                    <EditableDisplayField
                      label="Location"
                      isEditing={isEditing}
                      icon={<AppIcon icon={faGlobe} />}
                      value={customer.location}
                      renderEdit={() => (
                        <FieldWrapper>
                          <TextField
                            id="location"
                            name="location"
                            onChange={handleChange}
                            value={values.location}
                            label="Location"
                            error={!!errors.location}
                            fullWidth
                          />
                        </FieldWrapper>
                      )}
                    />
                    <EditableDisplayField
                      label="Lead Source"
                      isEditing={isEditing}
                      value={customer.leadSource}
                      renderEdit={() => (
                        <FieldWrapper>
                          <TextField
                            id="leadSource"
                            name="leadSource"
                            onChange={handleChange}
                            value={values.leadSource}
                            label="Lead Source"
                            error={!!errors.leadSource}
                            fullWidth
                          />
                        </FieldWrapper>
                      )}
                    />
                    <EditableDisplayField
                      label="Pipedrive Deal ID"
                      isEditing={isEditing}
                      icon={<AppIcon icon={faHashtag} />}
                      value={customer.pipedriveDealId}
                      renderEdit={() => (
                        <FieldWrapper>
                          <TextField
                            id="pipedriveDealId"
                            name="pipedriveDealId"
                            onChange={handleChange}
                            value={values.pipedriveDealId}
                            label="Pipedrive Deal ID"
                            error={!!errors.pipedriveDealId}
                            fullWidth
                          />
                        </FieldWrapper>
                      )}
                    />
                    <EditableDisplayField
                      label="Happiness Level"
                      isEditing={isEditing}
                      icon={<AppIcon icon={faSmile} />}
                      value={customer.happinessLevel}
                      renderEdit={() => (
                        <FieldWrapper>
                          <FormControl
                            fullWidth
                            error={!!errors.happinessLevel}
                          >
                            <InputLabel htmlFor="happinessLevel">
                              Happiness Level
                            </InputLabel>
                            <Select
                              id="happinessLevel"
                              name="happinessLevel"
                              onChange={handleChange}
                              value={values.happinessLevel}
                              error={!!errors.happinessLevel}
                              placeholder="Select a level"
                              fullWidth
                            >
                              <MenuItem value="">Not set</MenuItem>
                              <MenuItem value="Green">Green</MenuItem>
                              <MenuItem value="Yellow">Yellow</MenuItem>
                              <MenuItem value="Red">Red</MenuItem>
                            </Select>
                            {errors.happinessLevel && (
                              <FormHelperText>
                                {errors.happinessLevel}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </FieldWrapper>
                      )}
                    />
                  </List>
                  {isEditing && (
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginRight: ".5em" }}
                        type="submit"
                      >
                        Save Changes
                      </Button>
                      <Button onClick={() => this.onEditCancel(resetForm)}>
                        Cancel
                      </Button>
                    </div>
                  )}
                </StandardCard>
              </Form>
            )}
          />
        )}
      </UpdateCustomerComponent>
    );
  }
}

export default BasicInfoContainer;
