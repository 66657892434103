import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

import Login from "./display";

class LoginContainer extends Component {
  state = {
    isGoogleLoaded: false,
    isLoading: false,
    error: null
  };

  componentDidMount() {
    this.tryLoadAuth2();
  }

  onSignInSuccess = googleUser => {
    const authResponse = googleUser.getAuthResponse();
    this.props.onGoogleLogin(authResponse.id_token);
  };

  onSignInFailure = () => {
    this.setState({
      error: "An error occurred while logging in. Please try again."
    });
  };

  onLogin = async () => {
    try {
      this.setState({ error: null });
      const googleUser = await this.auth2.signIn();
      this.onSignInSuccess(googleUser);
    } catch (e) {
      this.onSignInFailure(e);
    }
  };

  tryLoadAuth2 = () => {
    if (!window.gapi) {
      setTimeout(this.tryLoadAuth2, 200);
      return;
    }
    window.gapi.load("auth2", async () => {
      this.auth2 = await window.gapi.auth2.init({
        hosted_domain: "buyboxexperts.com"
      });
      this.setState({ isGoogleLoaded: true });
    });
  };

  auth2 = null;

  render() {
    if (this.state.isLoggedIn) {
      return <Redirect to="/" />;
    }
    return (
      <Login
        onLogin={this.onLogin}
        isLoginDisabled={!this.state.isGoogleLoaded}
        isLoading={this.state.isLoading}
        error={this.state.error}
      />
    );
  }
}

LoginContainer.propTypes = {
  onGoogleLogin: PropTypes.func.isRequired
};

export default LoginContainer;
