import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretLeft,
  faCaretRight,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {
  Grid,
  List,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";

import AddProductModalContainer from "./add-product-modal-container";
import SelectSkuModalContainer from "../../select-sku-modal";
import GlobalAvatar from "../../global-avatar";
import AppLink from "../../common/app-link";
import EditableDisplayField from "../../common/editable-display-field";
import { StandardCard } from "../../common/standard-card";
import { TableHeaderCell } from "../../common/table-header-cell";
import Text from "../../common/styled/text";
import DangerButton from "../../common/styled/danger-button";

class CustomerProject extends Component {
  state = {
    isAddProductModalOpen: false,
    selectedCustomerProduct: null
  };

  onAddProduct = () => {
    this.setState({ isAddProductModalOpen: true });
  };

  onAddProductCancel = () => {
    this.setState({ isAddProductModalOpen: false });
  };

  onProductAdded = () => {
    const { refreshData } = this.props;
    this.setState({ isAddProductModalOpen: false });
    refreshData();
  };

  onSetSku = customerProduct => () => {
    this.setState({ selectedCustomerProduct: customerProduct });
  };

  onSetSkuCancel = () => {
    this.setState({ selectedCustomerProduct: null });
  };

  onCustomerSkuSelected = customerSkuId => {
    const { onSkuSetForCustomerProduct } = this.props;
    const { selectedCustomerProduct } = this.state;
    onSkuSetForCustomerProduct(selectedCustomerProduct.id, customerSkuId);
    this.setState({ selectedCustomerProduct: null });
  };

  onDeleteCustomerProduct = customerProduct => async () => {
    const { onDeleteCustomerProduct } = this.props;
    await onDeleteCustomerProduct(customerProduct.id);
  };

  render() {
    const { customer, customerProject } = this.props;
    const { isAddProductModalOpen, selectedCustomerProduct } = this.state;

    return (
      <div>
        <AddProductModalContainer
          visible={isAddProductModalOpen}
          onProductCreated={this.onProductAdded}
          onCancel={this.onAddProductCancel}
          customerProject={customerProject}
          customer={customer}
        />
        <Text>
          <AppLink to={`/customers/${customer.id}/projects`}>
            <FontAwesomeIcon icon={faCaretLeft} style={{ marginRight: 8 }} />
            Back to Projects
          </AppLink>
        </Text>
        <SelectSkuModalContainer
          visible={!!selectedCustomerProduct}
          onCancel={this.onSetSkuCancel}
          onCustomerSkuSelected={this.onCustomerSkuSelected}
          customerId={customer.id}
        />
        <Grid container spacing={16}>
          <Grid item md={4} xs={12} style={{ marginBottom: "1em" }}>
            <StandardCard>
              <List dense>
                <EditableDisplayField
                  label="Name"
                  value={
                    <span>
                      {customerProject.customerContract && (
                        <Fragment>
                          <AppLink
                            to={`/customers/${customer.id}/contracts/${
                              customerProject.customerContract.id
                            }`}
                          >
                            Contract #{customerProject.customerContract.id}
                          </AppLink>
                          <FontAwesomeIcon
                            icon={faCaretRight}
                            style={{ marginLeft: ".5em", marginRight: ".5em" }}
                          />
                        </Fragment>
                      )}
                      <span>{customerProject.name}</span>
                    </span>
                  }
                />
                <EditableDisplayField
                  label="Amount"
                  value={`$${customerProject.amount}`}
                />
                <EditableDisplayField
                  label="Start Date"
                  value={moment(customerProject.startDate).format("M/D/YYYY")}
                />
                <EditableDisplayField
                  label="Created At"
                  value={moment(customerProject.createdAt).format("M/D/YYYY")}
                />
                <EditableDisplayField
                  label="Status"
                  value={customerProject.status.name}
                />
                <EditableDisplayField
                  label="Sales User"
                  value={customerProject.salesUser.fullName}
                  icon={<GlobalAvatar userId={customerProject.salesUser.id} />}
                />
                <EditableDisplayField
                  label="Notes"
                  value={customerProject.notes || "-"}
                />
              </List>
            </StandardCard>
          </Grid>
          <Grid item md={8} xs={12}>
            <StandardCard title="Products">
              {/**
              <div style={{ marginBottom: "1em" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.onAddProduct}
                >
                  <FontAwesomeIcon icon={faPlus} style={{ marginRight: 8 }} />
                  Add Product
                </Button>
                </div>
              */}
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeaderCell>Product</TableHeaderCell>
                    <TableHeaderCell>Start Date</TableHeaderCell>
                    <TableHeaderCell>SKU</TableHeaderCell>
                    <TableHeaderCell>Status</TableHeaderCell>
                    <TableHeaderCell>Action</TableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customerProject.customerProducts &&
                    customerProject.customerProducts.map(customerProduct => (
                      <TableRow>
                        <TableCell>
                          {customerProduct.product.productCategory.name} /{" "}
                          {customerProduct.product.name}
                        </TableCell>
                        <TableCell>
                          {moment(customerProduct.startDate).format("M/D/YYYY")}
                        </TableCell>
                        <TableCell>TODO</TableCell>
                        <TableCell>{customerProduct.status.name}</TableCell>
                        <TableCell>
                          {customerProduct.status.id === "scheduled" && (
                            <DangerButton
                              onClick={this.onDeleteCustomerProduct(
                                customerProduct
                              )}
                            >
                              <FontAwesomeIcon
                                icon={faTrash}
                                style={{ marginRight: ".5em" }}
                              />
                              Cancel
                            </DangerButton>
                          )}
                          {customerProduct.status.id !== "scheduled" && "-"}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </StandardCard>
          </Grid>
        </Grid>
      </div>
    );
  }
}

CustomerProject.propTypes = {
  customer: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  customerProject: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  refreshData: PropTypes.func.isRequired,
  onSkuSetForCustomerProduct: PropTypes.func.isRequired
};

export default CustomerProject;
