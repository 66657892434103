export const PRODUCTS = {
  INFOGRAPHIC: "gd-infographic",
  EBC_EDIT: "gd-ebc-edit",
  PROMO: "gd-promo",
  INSERTS: "gd-inserts",
  VIDEO: "gd-video",
  EBC_TEMPLATE: "gd-ebc-template",
  STOREFRONT: "gd-storefront",
  LISTING_CONTENT_FULL: "c-lcc-full",
  LISTING_CONTENT_EXACT: "c-lcc-exact",
  LISTING_CONTENT_RECYCLED: "c-lcc-recycled",
  KEYWORDS: "c-keyword",
  EBC_CONTENT: "c-ebc",
  EMAIL_CONTENT: "c-email",
  KW_REOPTIMIZATION: "c-kw-reoptimize",
  WELCOME_CALL: "am-welcome",
  STRATEGY: "am-strategy",
  SOCIAL_POSTING: "ad-s-posting",
  SOCIAL_ADVERTISING: "ad-s-advertising",
  PPC: "ad-ppc",
  STUDIO_PHOTO: "p-studio",
  LIFESTYLE_PHOTO: "p-lifestyle",
  LIFESTYLE_PHOTO_UNIQUE: "p-lifestyle-unique",
  LIFESTYLE_PHOTO_SIMILAR: "p-lifestyle-similar",
  SPONSORED_ADS_PPC_MANAGEMENT: "ad-sponsored-ads-ppc",
  SPONSORED_BRANDS_PPC_MANAGEMENT: "ad-sponsored-brands-ppc",
  PRODUCT_DISPLAY_ADS_PPC_MANAGEMENT: "ad-product-display-ppc"
};
