import React, { Component } from "react";

/* Graphql Imports */
import {
  CreateCustomerSkuComponent,
  UpdateCustomerSkuComponent,
  SelectCustomerSkusComponent,
  GetCustomerCustomer,
  CreateCustomerSkuSyncRequestComponent
} from "../../../generated/graphql";

/* Child Components */
import Skus, { parseLocalStorage } from "./skus";

interface Props {
  customer: GetCustomerCustomer;
}

interface State {
  search: string;
  limit: number;
  offset: number;
  skuTypes: string[];
  syncStatuses: string[];
}

class SkusContainer extends Component<Props, State> {
  localStorageSkuFilters = parseLocalStorage();

  state: State = {
    search: "",
    limit: 20,
    offset: 0,
    skuTypes: this.localStorageSkuFilters
      ? this.localStorageSkuFilters.skuTypeFilters
      : [],
    syncStatuses: this.localStorageSkuFilters
      ? this.localStorageSkuFilters.syncStatusFilters
      : []
  };

  onSearchChanged = (search: string) => {
    this.setState({ search, offset: 0 });
  };

  onOffsetChanged = (offset: number) => {
    this.setState({ offset });
  };

  onSkuTypesChange = (skuTypes: string[]) => {
    this.setState({ skuTypes });
  };

  onSyncStatusesChange = (syncStatuses: string[]) => {
    this.setState({ syncStatuses });
  };

  onNextPage = () => {
    this.setState(prevState => ({
      offset: prevState.offset + prevState.limit
    }));
  };

  onPreviousPage = () => {
    const { offset, limit } = this.state;
    if (offset <= limit) {
      this.setState({ offset: 0 });
    } else {
      this.setState(prevState => ({
        offset: prevState.offset - prevState.limit
      }));
    }
  };

  render() {
    const { customer } = this.props;
    const { search, limit, offset, skuTypes, syncStatuses } = this.state;
    return (
      <CreateCustomerSkuSyncRequestComponent>
        {createCustomerSkuSyncRequestMutation => (
          <CreateCustomerSkuComponent>
            {createCustomerSkuMutation => (
              <UpdateCustomerSkuComponent>
                {updateCustomerSkuMutation => (
                  <SelectCustomerSkusComponent
                    variables={{
                      customerId: customer.id,
                      search,
                      limit,
                      offset,
                      skuTypes,
                      syncStatuses
                    }}
                    fetchPolicy="network-only"
                  >
                    {customerSkusQueryResult => (
                      <Skus
                        customer={customer}
                        onSearchChanged={this.onSearchChanged}
                        onNextPage={this.onNextPage}
                        onPreviousPage={this.onPreviousPage}
                        onSkuTypesChange={this.onSkuTypesChange}
                        onSyncStatusesChange={this.onSyncStatusesChange}
                        customerSkusQueryResult={customerSkusQueryResult}
                        createCustomerSkuMutation={createCustomerSkuMutation}
                        updateCustomerSkuMutation={updateCustomerSkuMutation}
                        createCustomerSkuSyncRequestMutation={
                          createCustomerSkuSyncRequestMutation
                        }
                      />
                    )}
                  </SelectCustomerSkusComponent>
                )}
              </UpdateCustomerSkuComponent>
            )}
          </CreateCustomerSkuComponent>
        )}
      </CreateCustomerSkuSyncRequestComponent>
    );
  }
}

export default SkusContainer;
