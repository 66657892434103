import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Query, Mutation } from "react-apollo";
import { has } from "lodash";

import CREATE_CUSTOMER_PRODUCT_MUTATION from "./create-customer-product-mutation";
import PRODUCTS_QUERY from "./products-query";
import { transformGraphQLErrorForFormik } from "../../../utilities/form-helpers";
import AddProductModal from "./add-product-modal";
import Loader from "../../common/loader";

class AddProductModalContainer extends Component {
  onFormSubmit = createCustomerProductMutation => async (
    values,
    { setErrors, setSubmitting }
  ) => {
    const { customer, customerProject, onProductCreated } = this.props;
    setSubmitting(true);
    try {
      const productPromises = [];
      for (let i = 0; i < values.quantity; i += 1) {
        productPromises.push(
          createCustomerProductMutation({
            variables: {
              input: {
                customerId: customer.id,
                customerProjectId: customerProject.id,
                productId: values.productId,
                amount: parseInt(values.amount, 10),
                startDate: moment(values.startDate).format("YYYY-MM-DD")
              }
            }
          })
        );
      }
      await Promise.all(productPromises);
      setSubmitting(false);
      setErrors({});
      onProductCreated();
    } catch (e) {
      setErrors(transformGraphQLErrorForFormik(e));
      setSubmitting(false);
    }
  };

  render() {
    const { customer, customerProject, onCancel, visible } = this.props;

    return (
      <Mutation mutation={CREATE_CUSTOMER_PRODUCT_MUTATION}>
        {createCustomerProductMutation => (
          <Query query={PRODUCTS_QUERY}>
            {({ data, loading, error }) => {
              if (loading) {
                return <Loader message="Loading..." />;
              }
              if (error || !data) {
                return "Error";
              }
              return (
                <AddProductModal
                  visible={visible}
                  customer={customer}
                  customerProject={customerProject}
                  onCancel={onCancel}
                  onFormSubmit={this.onFormSubmit(
                    createCustomerProductMutation
                  )}
                  productCategories={
                    has(data, "productCategories.items")
                      ? data.productCategories.items
                      : []
                  }
                />
              );
            }}
          </Query>
        )}
      </Mutation>
    );
  }
}

AddProductModalContainer.propTypes = {
  customer: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  customerProject: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onProductCreated: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired
};

export default AddProductModalContainer;
