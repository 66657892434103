import React from "react";
import Text from "../../common/styled/text";
import InstructionsContainer from "../../common/styled/instructions-container";

const ShareWithCustomer = () => (
  <InstructionsContainer>
    <Text>
      Once the photographer has the manager or team lead’s approval, they will
      move the images to a folder called Photo- “Delivery Date” inside the
      client’s In Progress folder. Once the Account Manager has reviewed them,
      they will move the batch into the appropriate month in the Media folder.
    </Text>
  </InstructionsContainer>
);

export default ShareWithCustomer;
