import React from "react";
import { GetMarkdownEditorViewerComponent } from "../../../generated/graphql";
import Loader from "../loader";
import NoteFeedItem from "../styled/note-feed-item";

interface Props {
  value: string;
}

const NotePreview = (props: Props) => (
  <GetMarkdownEditorViewerComponent>
    {({ data, loading, error }) => {
      if (loading) {
        return <Loader />;
      }
      if (error) {
        return error.message;
      }

      if (!data || !data.viewer) {
        return <Loader />;
      }

      return (
        <NoteFeedItem
          content={props.value}
          createdAt={new Date()}
          userFullName={data.viewer ? data.viewer.fullName : "Unknown"}
          userId={data.viewer.id}
        />
      );
    }}
  </GetMarkdownEditorViewerComponent>
);

export default NotePreview;
