import React, { Component, Fragment } from "react";

import { QueryResult, MutationFn } from "react-apollo";
import {
  Grid,
  List,
  TextField,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";
import { FormikActions, Formik } from "formik";
import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { withSnackbar, InjectedNotistackProps } from "notistack";
import Loader from "../common/loader";
import { TableHeaderCell } from "../common/table-header-cell";
import { FieldWrapper } from "../common/styled/field-wrapper";
import GlobalAvatar from "../global-avatar";
import AppIcon from "../common/app-icon";
import {
  SelectTeamQuery,
  SelectTeamVariables,
  UpdateTeamMutation,
  UpdateTeamVariables,
  CreateTeamUserMutation,
  CreateTeamUserVariables,
  DeleteTeamUserMutation,
  DeleteTeamUserVariables,
  GetUsersForUserSelectItems
} from "../../generated/graphql";
import ErrorDisplay from "../common/error-display";
import { AlertInfo, AlertError } from "../common/styled/alert";
import { transformGraphQLErrorForFormik } from "../../utilities/form-helpers";
import EditableDisplayField from "../common/editable-display-field";
import UserSelectContainer from "../common/user-select";
import { StandardCard } from "../common/standard-card";
import DangerButton from "../common/styled/danger-button";
import SelectUserDialog from "../common/user-select/select-user-dialog";
import { UserWithAvatar } from "../common/user-with-avatar";
import ConfirmDeleteTeamUserDialog from "./confirm-delete-team-user-dialog";
import { ROLES } from "../../constants/roles";

interface Props extends InjectedNotistackProps {
  teamId: number;
  queryResult: QueryResult<SelectTeamQuery, SelectTeamVariables>;
  updateTeamMutation: MutationFn<UpdateTeamMutation, UpdateTeamVariables>;
  createTeamUserMutation: MutationFn<
    CreateTeamUserMutation,
    CreateTeamUserVariables
  >;
  deleteTeamUserMutation: MutationFn<
    DeleteTeamUserMutation,
    DeleteTeamUserVariables
  >;
}

interface State {
  isEditing: boolean;
  isAddingUser: boolean;
  isDeletingTeamUser: boolean;
  teamUserId: number | null;
}

interface FormValues {
  global?: string;
  name: string;
  department: string;
  leadUserId: number | null;
}

class Team extends Component<Props, State> {
  state = {
    isEditing: false,
    isAddingUser: false,
    isDeletingTeamUser: false,
    teamUserId: null
  };

  onEdit = () => {
    this.setState({ isEditing: true });
  };

  onEditCancel = () => {
    this.setState({ isEditing: false });
  };

  onSubmit = async (values: FormValues, actions: FormikActions<FormValues>) => {
    const { teamId, updateTeamMutation } = this.props;

    if (!values.leadUserId) {
      return;
    }
    try {
      await updateTeamMutation({
        variables: {
          input: {
            teamId,
            department: values.department,
            leadUserId: values.leadUserId,
            name: values.name
          }
        }
      });
      this.onEditCancel();
      actions.setSubmitting(false);
    } catch (e) {
      actions.setErrors(transformGraphQLErrorForFormik(e));
      actions.setSubmitting(false);
    }
  };

  onAddUser = () => {
    this.setState({ isAddingUser: true });
  };

  onAddUserSelected = async (userId: number | null) => {
    const {
      queryResult,
      createTeamUserMutation,
      teamId,
      enqueueSnackbar
    } = this.props;

    if (!userId) {
      return;
    }

    try {
      await createTeamUserMutation({
        variables: {
          input: {
            teamId,
            userId
          }
        }
      });
      queryResult.refetch();
    } catch (e) {
      const errors = transformGraphQLErrorForFormik(e);
      if (errors.global) {
        enqueueSnackbar(errors.global, {
          variant: "error"
        });
      }
    }
    this.onAddUserCancel();
  };

  onAddUserCancel = () => {
    this.setState({ isAddingUser: false });
  };

  onDeleteTeamUser = (teamUserId: number) => {
    this.setState({ isDeletingTeamUser: true, teamUserId });
  };

  onDeleteTeamUserCancel = () => {
    this.setState({ isDeletingTeamUser: false });
  };

  onConfirmDeleteTeamUser = async (teamUserId: number) => {
    const { queryResult, deleteTeamUserMutation, enqueueSnackbar } = this.props;
    try {
      await deleteTeamUserMutation({
        variables: {
          input: {
            teamUserId
          }
        }
      });
      queryResult.refetch();
      this.setState({ isDeletingTeamUser: false });
    } catch (e) {
      const errors = transformGraphQLErrorForFormik(e);
      if (errors.global) {
        enqueueSnackbar(errors.global);
      }
    }
  };

  render() {
    const { queryResult } = this.props;
    const {
      isEditing,
      isAddingUser,
      isDeletingTeamUser,
      teamUserId
    } = this.state;

    if (queryResult.loading) {
      return <Loader />;
    }
    if (queryResult.error) {
      return <ErrorDisplay error={queryResult.error} />;
    }
    const { data } = queryResult;
    if (!data || !data.team) {
      return <AlertError message="Unable to find specified team." />;
    }
    const { team } = data;

    return (
      <Fragment>
        {isDeletingTeamUser && teamUserId && (
          <ConfirmDeleteTeamUserDialog
            onConfirmDeleteTeamUser={this.onConfirmDeleteTeamUser}
            onDeleteTeamUserCancel={this.onDeleteTeamUserCancel}
            teamUserId={teamUserId}
          />
        )}
        <Grid container spacing={16}>
          <Grid item xs={4}>
            <StandardCard title="Overview">
              <Formik
                onSubmit={this.onSubmit}
                initialValues={{
                  department: team.department,
                  leadUserId: team.leadUser.id,
                  name: team.name
                }}
                render={({
                  values,
                  handleChange,
                  isSubmitting,
                  handleSubmit,
                  errors,
                  setFieldValue,
                  resetForm
                }) => (
                  <Fragment>
                    {!isEditing && (
                      <div style={{ marginBottom: "1rem" }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.onEdit}
                        >
                          <AppIcon icon={faPen} standardRightMargin />
                          Edit
                        </Button>
                      </div>
                    )}
                    {errors.global && <AlertError message={errors.global} />}
                    <List dense>
                      <EditableDisplayField
                        label="Name"
                        isEditing={isEditing}
                        value={team.name}
                        renderEdit={() => (
                          <FieldWrapper>
                            <TextField
                              id="name"
                              name="name"
                              label="Team Name"
                              value={values.name}
                              onChange={handleChange}
                              error={!!errors.name}
                              helperText={errors.name}
                              fullWidth
                            />
                          </FieldWrapper>
                        )}
                      />
                      <EditableDisplayField
                        label="Department"
                        isEditing={isEditing}
                        value={team.department}
                        renderEdit={() => (
                          <FieldWrapper>
                            <TextField
                              id="department"
                              name="department"
                              label="Department"
                              value={values.department}
                              onChange={handleChange}
                              error={!!errors.department}
                              helperText={errors.department}
                              fullWidth
                            />
                          </FieldWrapper>
                        )}
                      />
                      <EditableDisplayField
                        label="Team Lead"
                        isEditing={isEditing}
                        value={team.leadUser.fullName}
                        icon={
                          <GlobalAvatar
                            userId={team.leadUser.id}
                            profileImageUrl={team.leadUser.profileImageUrl}
                            fullName={team.leadUser.fullName}
                            skipQuery
                          />
                        }
                        renderEdit={() => (
                          <FieldWrapper>
                            <UserSelectContainer
                              roles={[ROLES.EMPLOYEE]}
                              allowUnassigned={false}
                              label="Team Lead"
                              onUserSelected={userId => {
                                setFieldValue("leadUserId", userId);
                              }}
                              userId={values.leadUserId}
                            />
                          </FieldWrapper>
                        )}
                      />
                    </List>
                    {isEditing && (
                      <div>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleSubmit()}
                          disabled={isSubmitting}
                        >
                          {isSubmitting && <Loader />}Save
                        </Button>
                        <Button
                          onClick={() => {
                            resetForm();
                            this.onEditCancel();
                          }}
                          style={{ marginLeft: ".5rem" }}
                          disabled={isSubmitting}
                        >
                          Cancel
                        </Button>
                      </div>
                    )}
                  </Fragment>
                )}
              />
            </StandardCard>
          </Grid>
          <Grid item xs={8}>
            <StandardCard title="Team Members">
              {isAddingUser && (
                <SelectUserDialog
                  roles={[ROLES.EMPLOYEE]}
                  allowUnassigned={false}
                  onClose={this.onAddUserCancel}
                  onUserSelected={this.onAddUserSelected}
                />
              )}
              <div style={{ marginBottom: "1rem" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.onAddUser}
                >
                  <AppIcon icon={faPlus} standardRightMargin />
                  Add Member
                </Button>
              </div>
              {team.teamUsers.length === 0 && (
                <AlertInfo message="No users have been added yet." />
              )}
              {team.teamUsers.length > 0 && (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableHeaderCell>Name</TableHeaderCell>
                      <TableHeaderCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {team.teamUsers.map(teamUser => (
                      <TableRow key={teamUser.id}>
                        <TableCell>
                          <UserWithAvatar
                            fullName={teamUser.user.fullName}
                            userId={teamUser.user.id}
                            profileImageUrl={teamUser.user.profileImageUrl}
                          />
                        </TableCell>
                        <TableCell>
                          <DangerButton
                            onClick={() => this.onDeleteTeamUser(teamUser.id)}
                          >
                            <AppIcon icon={faTrash} standardRightMargin />
                            Remove
                          </DangerButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </StandardCard>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withSnackbar(Team);
