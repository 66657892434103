import React from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import WarningIcon from "@material-ui/icons/Warning";
import { intersection } from "lodash";

import Loader from "../loader";

import VIEWER_QUERY from "./viewer-query";
import { ROLES } from "../../../constants/roles";

const RoleChecker = ({ allowedRoles, children, ...props }) => (
  <Query query={VIEWER_QUERY}>
    {({ data, loading, error }) => {
      if (error) {
        return (
          <span>
            <WarningIcon />
            Something went wrong.
          </span>
        );
      }
      if (loading) {
        return <Loader />;
      }
      if (
        data.viewer &&
        intersection(data.viewer.roles, [
          ...allowedRoles,
          ROLES.ADMIN,
          ROLES.SUPER_ADMIN
        ]).length > 0
      ) {
        return React.Children.map(children, child =>
          React.cloneElement(child, { ...props })
        );
      }
      return null;
    }}
  </Query>
);

RoleChecker.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired
};

export default RoleChecker;
