import React from "react";
import Text from "../../common/styled/text";
import InstructionsContainer from "../../common/styled/instructions-container";

const PrepLifestyleShoot = () => (
  <InstructionsContainer>
    <Text>
      Now that the 10 business day turnaround time has begun, the photographer
      should prepare to shoot the lifestyle images as soon as possible. The
      photographer can now begin to coordinate schedules with the models,
      prepare the product to be shot, purchase props, and schedule equipment to
      shoot the product (if necessary).
    </Text>
  </InstructionsContainer>
);

export default PrepLifestyleShoot;
