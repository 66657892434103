import React, { FunctionComponent } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";

import AppLink from "../common/app-link";
import { StandardCard } from "../common/standard-card";
import { TableHeaderCell } from "../common/table-header-cell";
import { SelectMyStarredCustomersCustomerStars } from "../../generated/graphql";

interface Props {
  starredCustomers: SelectMyStarredCustomersCustomerStars[];
  starredCustomersCount: number;
}

const MyStarredCustomersCard: FunctionComponent<Props> = ({
  starredCustomers,
  starredCustomersCount
}) => (
  <StandardCard title={`My Starred Customers (${starredCustomersCount})`}>
    <Table>
      <TableHead>
        <TableRow>
          <TableHeaderCell>Starred Customers</TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {starredCustomers &&
          starredCustomers.map(sc => (
            <TableRow key={sc.id}>
              <TableCell>
                <AppLink to={`/customers/${sc.customer.id}`}>
                  {sc.customer.name}
                </AppLink>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  </StandardCard>
);

export default MyStarredCustomersCard;
