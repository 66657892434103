import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { TableHeaderCell } from "../../common/table-header-cell";

class Workspace extends Component {
  state = {
    selectedUserId: null
  };

  onUserSelected = user => {
    this.setState({ selectedUserId: user.id });
  };

  onCompleteTask = async () => {
    const { selectedUserId } = this.state;
    const { task, onComplete, updateCustomerMutation } = this.props;
    try {
      await updateCustomerMutation({
        variables: {
          input: {
            customerId: task.customer.id,
            accountManagerUserId: selectedUserId
          }
        }
      });
      await onComplete();
    } catch (e) {
      console.log("Error updating the account manager: ", e);
    }
  };

  onClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  render() {
    const { users, onSearchUpdated, search } = this.props;
    const { selectedUserId } = this.state;
    return (
      <Fragment>
        <div>
          <TextField
            label="Search Users"
            onChange={e => onSearchUpdated(e.target.value)}
            value={search}
            fullWidth
            style={{ marginBottom: "1em" }}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableHeaderCell>Name</TableHeaderCell>
                <TableHeaderCell>Customers Managed</TableHeaderCell>
                <TableHeaderCell>Spend Managed</TableHeaderCell>
                <TableHeaderCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {users &&
                users.map(user => (
                  <TableRow key={user.id}>
                    <TableCell>{user.fullName}</TableCell>
                    <TableCell>
                      {user.accountManagement.customersManaged.totalCount}
                    </TableCell>
                    <TableCell>
                      ${user.accountManagement.amountManaged.toFixed(2)}
                    </TableCell>
                    <TableCell>
                      {user.id === selectedUserId && (
                        <span>
                          <FontAwesomeIcon
                            icon={faCheck}
                            style={{ marginRight: ".5em" }}
                          />
                          Selected
                        </span>
                      )}
                      {user.id !== selectedUserId && (
                        <Button
                          color="primary"
                          onClick={() => this.onUserSelected(user)}
                        >
                          Select
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
        <div style={{ marginTop: "4em" }}>
          <Button
            variant="contained"
            color="primary"
            disabled={!selectedUserId}
            onClick={this.onCompleteTask}
            size="large"
          >
            <FontAwesomeIcon icon={faCheck} style={{ marginRight: ".5em" }} />
            Complete Task
          </Button>
          <Button
            onClick={this.onClose}
            size="large"
            style={{ marginLeft: ".5em" }}
          >
            <FontAwesomeIcon icon={faTimes} style={{ marginRight: ".5em" }} />
            Close
          </Button>
        </div>
      </Fragment>
    );
  }
}

Workspace.propTypes = {
  task: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  users: PropTypes.shape({
    id: PropTypes.number,
    fullName: PropTypes.string,
    accountManagement: PropTypes.shape({
      amountManaged: PropTypes.number,
      customersManaged: PropTypes.number
    })
  }).isRequired,
  onSearchUpdated: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired
};

export default Workspace;
