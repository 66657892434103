import React, { Component } from "react";
import { Formik, Form, FormikActions } from "formik";
import { withSnackbar, InjectedNotistackProps } from "notistack";

/* GraphQL Imports */
import {
  SelectCustomerAmazonAccountCustomerAmazonAccount,
  SelectCustomerAmazonAccountQuery,
  SelectCustomerAmazonAccountVariables,
  CreateCustomerAmazonAccountRevisionMutation,
  CreateCustomerAmazonAccountRevisionVariables,
  CreateCustomerAmazonAccountMutation,
  CreateCustomerAmazonAccountVariables
} from "../../../../generated/graphql";
import { FormValues } from ".";
import { QueryResult, MutationFn } from "react-apollo";

/** Material UI Components */
import {
  TextField,
  Button,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";

/* Font Awesome */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faUnlockAlt,
  faKey
} from "@fortawesome/free-solid-svg-icons";

/* Common Components */
import { FieldWrapper } from "../../../common/styled/field-wrapper";

/* Utilities */
import { transformGraphQLErrorForFormik } from "../../../../utilities/form-helpers";

interface Props extends InjectedNotistackProps {
  customerId: number;
  isAddingSellerInformation: boolean;
  queryResult: QueryResult<
    SelectCustomerAmazonAccountQuery,
    SelectCustomerAmazonAccountVariables
  >;
  createCustomerAmazonAccountMutation: MutationFn<
    CreateCustomerAmazonAccountMutation,
    CreateCustomerAmazonAccountVariables
  >;
  createCustomerAmazonAccountRevisionMutation: MutationFn<
    CreateCustomerAmazonAccountRevisionMutation,
    CreateCustomerAmazonAccountRevisionVariables
  >;
  customerAmazonAccount?: SelectCustomerAmazonAccountCustomerAmazonAccount | null;
  onClose: () => any;
}

interface State {}

class SellerCentralDialog extends Component<Props, State> {
  onAddInformation = async (
    values: FormValues,
    actions: FormikActions<FormValues>
  ) => {
    const {
      queryResult,
      createCustomerAmazonAccountMutation,
      customerId,
      onClose,
      enqueueSnackbar
    } = this.props;

    if (!createCustomerAmazonAccountMutation) {
      return null;
    }

    try {
      await createCustomerAmazonAccountMutation({
        variables: {
          input: {
            customerId: customerId,
            email: values.email,
            password: values.password,
            totpSecret:
              values.totpSecret === ""
                ? null
                : values.totpSecret && values.totpSecret.replace(/\s/g, "")
          }
        }
      });
      queryResult.refetch();
      actions.setSubmitting(false);
      onClose();
    } catch (e) {
      actions.setSubmitting(false);
      const error = transformGraphQLErrorForFormik(e);
      if (error.global) {
        enqueueSnackbar(error.global, { variant: "error" });
      }
    }
  };

  onUpdateInformation = async (
    values: FormValues,
    actions: FormikActions<FormValues>
  ) => {
    const {
      queryResult,
      createCustomerAmazonAccountRevisionMutation,
      onClose,
      enqueueSnackbar
    } = this.props;

    if (!createCustomerAmazonAccountRevisionMutation) {
      return null;
    }

    if (!queryResult.data || !queryResult.data.customer) {
      return null;
    }

    if (
      !queryResult.data.customer.customerAmazonAccount ||
      !queryResult.data.customer.customerAmazonAccount.id
    ) {
      return null;
    }

    try {
      await createCustomerAmazonAccountRevisionMutation({
        variables: {
          input: {
            customerAmazonAccountId:
              queryResult.data.customer.customerAmazonAccount.id,
            email: values.email,
            password: values.password,
            totpSecret:
              values.totpSecret === ""
                ? null
                : values.totpSecret && values.totpSecret.replace(/\s/g, "")
          }
        }
      });
      queryResult.refetch();
      actions.setSubmitting(false);
      onClose();
    } catch (e) {
      actions.setSubmitting(false);
      const error = transformGraphQLErrorForFormik(e);
      if (error.global) {
        enqueueSnackbar(error.global, { variant: "error" });
      }
    }
  };
  render() {
    const {
      customerAmazonAccount,
      onClose,
      isAddingSellerInformation
    } = this.props;
    return (
      <Formik
        initialValues={{
          email:
            customerAmazonAccount && customerAmazonAccount.email
              ? customerAmazonAccount.email
              : "",
          password:
            customerAmazonAccount && customerAmazonAccount.password
              ? customerAmazonAccount.password
              : "",
          totpSecret: ""
        }}
        onSubmit={
          isAddingSellerInformation
            ? this.onAddInformation
            : this.onUpdateInformation
        }
        render={({ values, handleChange, handleSubmit, isSubmitting }) => (
          <Dialog
            open
            onClose={onClose}
            fullWidth
            disableBackdropClick
            scroll="body"
          >
            <DialogTitle>Update Seller Central Information</DialogTitle>
            <DialogContent>
              <Form>
                <FieldWrapper>
                  <TextField
                    id="email"
                    name="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    autoFocus
                    label="Email Address"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faEnvelope} fixedWidth />
                        </InputAdornment>
                      )
                    }}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    id="password"
                    name="password"
                    type="text"
                    value={values.password}
                    onChange={handleChange}
                    autoFocus
                    label="Password"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faUnlockAlt} fixedWidth />
                        </InputAdornment>
                      )
                    }}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    id="totpSecret"
                    name="totpSecret"
                    value={values.totpSecret}
                    onChange={handleChange}
                    autoFocus
                    label="TOTP Secret"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faKey} fixedWidth />
                        </InputAdornment>
                      )
                    }}
                  />
                </FieldWrapper>
                <DialogActions>
                  <Button onClick={onClose} disabled={isSubmitting}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: ".5em" }}
                    type="submit"
                    onClick={() => handleSubmit()}
                    disabled={isSubmitting}
                  >
                    {isAddingSellerInformation
                      ? "Add Information"
                      : "Update Information"}
                  </Button>
                </DialogActions>
              </Form>
            </DialogContent>
          </Dialog>
        )}
      />
    );
  }
}
export default withSnackbar(SellerCentralDialog);
