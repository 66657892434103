import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Query } from "react-apollo";

import CHILD_CUSTOMER_SKUS_QUERY from "./child-customer-skus-query";
import ErrorDisplay from "../../../common/error-display";
import { TableHeaderCell } from "../../../common/table-header-cell";
import Loader from "../../../common/loader";
import { FieldWrapper } from "../../../common/styled/field-wrapper";

class ChildCustomerSkusTable extends Component {
  state = {};

  onAddFull = customerSkuId => {
    const { addCustomerProductItem } = this.props;
    addCustomerProductItem({
      customerSkuId,
      productId: "c-lcc-full"
    });
  };

  onAddRecycled = customerSkuId => {
    const { addCustomerProductItem } = this.props;
    addCustomerProductItem({
      customerSkuId,
      productId: "c-lcc-recycled"
    });
  };

  onAddExact = customerSkuId => {
    const { addCustomerProductItem } = this.props;
    addCustomerProductItem({
      customerSkuId,
      productId: "c-lcc-exact"
    });
  };

  render() {
    const { topLevelCustomerSkuId, customerProductItems } = this.props;
    return (
      <Query
        query={CHILD_CUSTOMER_SKUS_QUERY}
        skip={!topLevelCustomerSkuId}
        variables={{ customerSkuId: topLevelCustomerSkuId }}
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loader />;
          }
          if (error) {
            return <ErrorDisplay error={error} />;
          }
          if (!data) {
            return null;
          }
          if (data.customerSku && data.customerSku.children.length === 0) {
            return (
              <FieldWrapper>
                <Button
                  onClick={() => this.onAddFull(data.customerSku.id)}
                  color="primary"
                  disabled={
                    !!customerProductItems.find(
                      c => c.customerSkuId === data.customerSku.id
                    )
                  }
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{ marginRight: ".5em" }}
                  />
                  Full
                </Button>
                <Button
                  onClick={() => this.onAddExact(data.customerSku.id)}
                  color="primary"
                  disabled={
                    !!customerProductItems.find(
                      c => c.customerSkuId === data.customerSku.id
                    )
                  }
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{ marginRight: ".5em" }}
                  />
                  Exact
                </Button>
                <Button
                  onClick={() => this.onAddRecycled(data.customerSku.id)}
                  color="primary"
                  disabled={
                    !!customerProductItems.find(
                      c => c.customerSkuId === data.customerSku.id
                    )
                  }
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{ marginRight: ".5em" }}
                  />
                  Recycled
                </Button>
              </FieldWrapper>
            );
          }
          return (
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeaderCell>SKU</TableHeaderCell>
                  <TableHeaderCell>Title</TableHeaderCell>
                  <TableHeaderCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {data.customerSku.children.map(customerSku => (
                  <TableRow key={customerSku.id}>
                    <TableCell>{customerSku.sku}</TableCell>
                    <TableCell>{customerSku.title}</TableCell>
                    <TableCell>
                      <Button
                        color="primary"
                        disabled={
                          !!customerProductItems.find(
                            c => c.customerSkuId === customerSku.id
                          )
                        }
                        onClick={() => this.onAddFull(customerSku.id)}
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          style={{ marginRight: ".5em" }}
                        />
                        Full
                      </Button>
                      <Button
                        color="primary"
                        disabled={
                          !!customerProductItems.find(
                            c => c.customerSkuId === customerSku.id
                          )
                        }
                        onClick={() => this.onAddExact(customerSku.id)}
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          style={{ marginRight: ".5em" }}
                        />
                        Exact
                      </Button>
                      <Button
                        color="primary"
                        disabled={
                          !!customerProductItems.find(
                            c => c.customerSkuId === customerSku.id
                          )
                        }
                        onClick={() => this.onAddRecycled(customerSku.id)}
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          style={{ marginRight: ".5em" }}
                        />
                        Recyled
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          );
        }}
      </Query>
    );
  }
}

ChildCustomerSkusTable.defaultProps = {
  topLevelCustomerSkuId: null
};

ChildCustomerSkusTable.propTypes = {
  addCustomerProductItem: PropTypes.func.isRequired,
  topLevelCustomerSkuId: PropTypes.number,
  customerProductItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      productId: PropTypes.string
    })
  ).isRequired
};

export default ChildCustomerSkusTable;
