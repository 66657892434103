import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect } from "react-router-dom";
import { Mutation } from "react-apollo";

import CREATE_CUSTOMER_CONTRACT from "./create-customer-contract-mutation";
import AddContractDialog from "./add-contract-dialog";

class AddContractDialogContainer extends Component {
  state = {
    customerContractId: null
  };

  render() {
    const { customer, onClose, onContractCreated } = this.props;
    const { customerContractId } = this.state;
    if (customerContractId) {
      return <Redirect to={`/customers/${customer.id}/roadmap`} />;
    }
    return (
      <Mutation mutation={CREATE_CUSTOMER_CONTRACT}>
        {createCustomerContractMutation => (
          <AddContractDialog
            createCustomerContractMutation={createCustomerContractMutation}
            onClose={onClose}
            onContractCreated={onContractCreated}
            customer={customer}
          />
        )}
      </Mutation>
    );
  }
}

AddContractDialogContainer.propTypes = {
  customer: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onContractCreated: PropTypes.func.isRequired
};

export default withRouter(AddContractDialogContainer);
