import React, { Component } from "react";
import { Formik, FormikActions, Form } from "formik";
import { MutationFn } from "react-apollo";
import { withSnackbar, InjectedNotistackProps } from "notistack";

/* GraphQL */
import {
  UpdateCustomerUserMutation,
  UpdateCustomerUserVariables
} from "../../../generated/graphql";

/* Material UI */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button
} from "@material-ui/core";

/* Common Components */
import { AlertError } from "../../common/styled/alert";
import { FieldWrapper } from "../../common/styled/field-wrapper";
import Loader from "../../common/loader";

/* Utilities */
import { transformGraphQLErrorForFormik } from "../../../utilities/form-helpers";
interface Props extends InjectedNotistackProps {
  customerUser?: {
    customerUserId: number;
    firstName: string;
    lastName: string;
    title: string;
    notes: string;
  };
  onClose: () => any;
  onCustomerUserUpdated?: () => any;
  updateCustomerUserMutation: MutationFn<
    UpdateCustomerUserMutation,
    UpdateCustomerUserVariables
  >;
}

interface State {}

interface FormValues {
  global?: string | null;
  firstName: string;
  lastName: string;
  title: string;
  notes: string;
}

class CustomerUserUpdateDialog extends Component<Props, State> {
  state = {};

  onSubmit = async (values: FormValues, actions: FormikActions<FormValues>) => {
    const {
      customerUser,
      onCustomerUserUpdated,
      updateCustomerUserMutation,
      enqueueSnackbar
    } = this.props;

    if (!onCustomerUserUpdated) {
      return;
    }

    if (!customerUser || !customerUser.customerUserId) {
      return;
    }

    try {
      await updateCustomerUserMutation({
        variables: {
          input: {
            customerUserId: customerUser.customerUserId,
            firstName: values.firstName !== "" ? values.firstName : undefined,
            lastName: values.lastName,
            title: values.title !== "" ? values.title : null,
            notes: values.notes !== "" ? values.notes : null
          }
        }
      });
      onCustomerUserUpdated();
      actions.setSubmitting(false);
      enqueueSnackbar("The Customer User has been successfully updated!", {
        variant: "success"
      });
    } catch (e) {
      actions.setSubmitting(false);
      const error = transformGraphQLErrorForFormik(e);
      actions.setErrors(error);
      if (error.global) {
        enqueueSnackbar(error.global, { variant: "error" });
      }
    }
  };

  render() {
    const { onClose, customerUser } = this.props;
    return (
      <Formik
        onSubmit={this.onSubmit}
        initialValues={{
          firstName: customerUser ? customerUser.firstName : "",
          lastName: customerUser ? customerUser.lastName : "",
          title: customerUser ? customerUser.title : "",
          notes: customerUser ? customerUser.notes : ""
        }}
        render={({
          handleSubmit,
          handleChange,
          values,
          errors,
          isSubmitting
        }) => (
          <Dialog
            open
            onClose={onClose}
            fullWidth
            disableBackdropClick
            scroll="body"
          >
            <DialogTitle>Update Customer User</DialogTitle>
            <DialogContent>
              <Form>
                {errors.global && <AlertError message={errors.global} />}
                <FieldWrapper>
                  <TextField
                    label="First Name"
                    id="firstName"
                    name="firstName"
                    onChange={handleChange}
                    value={values.firstName}
                    placeholder="First name"
                    fullWidth
                    helperText={errors.firstName}
                    error={!!errors.firstName}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    label="Last Name"
                    id="lastName"
                    name="lastName"
                    onChange={handleChange}
                    value={values.lastName}
                    placeholder="Last name"
                    fullWidth
                    helperText={errors.lastName}
                    error={!!errors.lastName}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    label="Title"
                    id="title"
                    name="title"
                    onChange={handleChange}
                    value={values.title}
                    placeholder="Title"
                    fullWidth
                    helperText={errors.title}
                    error={!!errors.title}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    label="Notes"
                    id="notes"
                    name="notes"
                    onChange={handleChange}
                    value={values.notes}
                    placeholder="Notes..."
                    fullWidth
                    helperText={errors.notes}
                    error={!!errors.notes}
                  />
                </FieldWrapper>
              </Form>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} disabled={isSubmitting}>
                Cancel
              </Button>
              <Button
                onClick={() => handleSubmit()}
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                {isSubmitting && <Loader />}
                Update User
              </Button>
            </DialogActions>
          </Dialog>
        )}
      />
    );
  }
}

export default withSnackbar(CustomerUserUpdateDialog);
