import React, { FunctionComponent } from "react";
import {
  DialogContent,
  DialogTitle,
  Dialog,
  Button,
  DialogActions,
  TablePagination
} from "@material-ui/core";
import { ApolloQueryResult } from "apollo-client";
import {
  CreateNoteMutationFn,
  NoteEntityType,
  RoadmapCustomerProjectNotesItems,
  RoadmapCustomerProjectNotesVariables,
  RoadmapCustomerProjectNotesQuery
} from "../../../../generated/graphql";
import NoteFeed from "../../../common/note-feed";

interface CustomerProject {
  id: number;
}

interface Props {
  customerId: number;
  createNoteMutation: CreateNoteMutationFn;
  customerProject: CustomerProject;
  notes: RoadmapCustomerProjectNotesItems[];
  count: number;
  limit: number;
  offset: number;
  refetchNotes: (
    variables?: RoadmapCustomerProjectNotesVariables | undefined
  ) => Promise<ApolloQueryResult<RoadmapCustomerProjectNotesQuery>>;
  onCancel: () => void;
  onNextPage: () => any;
  onPreviousPage: () => any;
}

const ProjectNotesDialog: FunctionComponent<Props> = ({
  createNoteMutation,
  customerProject,
  refetchNotes,
  onCancel,
  notes,
  customerId,
  limit,
  offset,
  count,
  onNextPage,
  onPreviousPage
}) => {
  const onRevisionCreate = async (
    content: string,
    originalNoteId: number | null
  ) => {
    await createNoteMutation({
      variables: {
        input: {
          content,
          originalNoteId,
          entityId: customerProject.id.toString(),
          entityType: NoteEntityType.CustomerProject
        }
      }
    });
    refetchNotes();
  };

  return (
    <Dialog
      fullWidth
      open
      onClose={onCancel}
      maxWidth="md"
      scroll="paper"
      disableBackdropClick
    >
      <DialogTitle>Project Notes</DialogTitle>
      <DialogContent>
        {count > limit && (
          <TablePagination
            component="div"
            count={count}
            rowsPerPage={limit}
            page={offset / limit}
            rowsPerPageOptions={[limit]}
            onChangePage={(e, page) => {
              const currentPage = offset / limit;
              if (page > currentPage) {
                onNextPage();
              } else {
                onPreviousPage();
              }
            }}
          />
        )}
        <NoteFeed
          customerId={customerId}
          notes={notes}
          onRevisionCreate={onRevisionCreate}
        />
        {count > limit && (
          <TablePagination
            component="div"
            count={count}
            rowsPerPage={limit}
            page={offset / limit}
            rowsPerPageOptions={[limit]}
            onChangePage={(e, page) => {
              const currentPage = offset / limit;
              if (page > currentPage) {
                onNextPage();
              } else {
                onPreviousPage();
              }
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectNotesDialog;
