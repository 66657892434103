import React, { Component, Fragment } from "react";
import { TextField, InputAdornment, Button } from "@material-ui/core";
import SelectUserDialog from "./select-user-dialog";
import { GetUserForUserSelectComponent } from "../../../generated/graphql";
import DangerButton from "../styled/danger-button";

interface Props {
  roles: string[];
  label: string;
  allowUnassigned: boolean | null;
  userId: number | null;
  onUserSelected: (userId: number | null) => void;
}

interface State {}

class UserSelectContainer extends Component<Props, State> {
  state = {
    isDialogOpen: false
  };

  onSelectUser = () => {
    this.setState({ isDialogOpen: true });
  };

  onSelectUserClose = () => {
    this.setState({ isDialogOpen: false });
  };

  onUserSelected = (userId: number | null) => {
    const { onUserSelected } = this.props;
    this.setState({ isDialogOpen: false });
    onUserSelected(userId);
  };

  render() {
    const { userId, label, allowUnassigned, roles } = this.props;
    const { isDialogOpen } = this.state;

    return (
      <Fragment>
        {isDialogOpen && (
          <SelectUserDialog
            roles={roles}
            onClose={this.onSelectUserClose}
            onUserSelected={this.onUserSelected}
            allowUnassigned={allowUnassigned}
          />
        )}
        <GetUserForUserSelectComponent
          variables={{ userId: userId || 0 }}
          skip={!userId}
        >
          {({ data, loading, error }) => {
            let userFullName = "Select a User";
            if (loading) {
              userFullName = "Loading...";
            }
            if (error) {
              userFullName = "An Error occurred.";
            }
            if (data && data.user) {
              userFullName = data.user.fullName;
            }
            return (
              <TextField
                value={userFullName}
                label={label}
                fullWidth
                inputProps={{ readOnly: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {allowUnassigned && userId && (
                        <DangerButton
                          size="small"
                          onClick={() => this.onUserSelected(null)}
                        >
                          Clear
                        </DangerButton>
                      )}
                      <Button size="small" onClick={this.onSelectUser}>
                        Select
                      </Button>
                    </InputAdornment>
                  )
                }}
              />
            );
          }}
        </GetUserForUserSelectComponent>
      </Fragment>
    );
  }
}

export default UserSelectContainer;
