import React from "react";
import {
  MuiThemeProvider,
  createMuiTheme,
  colors,
  Button
} from "@material-ui/core";
import { ButtonProps } from "@material-ui/core/Button";
import { themeOptions } from "../../core/mui-theme";

const ModifiedTheme = createMuiTheme({
  ...themeOptions,
  palette: { primary: { main: colors.red[500] } }
});

const DangerButton = (props: ButtonProps) => (
  <MuiThemeProvider theme={ModifiedTheme}>
    <Button {...props} color="primary" />
  </MuiThemeProvider>
);

export default DangerButton;
