import React, { Component } from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";

import { Grid } from "@material-ui/core";
import USER_QUERY from "./user-query";
import ErrorDisplay from "../common/error-display";
import PageLayout from "../common/page-layout";
import Loader from "../common/loader";
import Details from "./details";
import Groups from "./groups";

class UserContainer extends Component {
  renderContent = (data, loading) => {
    if (loading) {
      return <Loader />;
    }

    return (
      <Grid container spacing={16}>
        <Grid item xs={12} lg={6}>
          <Details user={data.user} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Groups user={data.user} />
        </Grid>
      </Grid>
    );
  };

  render() {
    const {
      match: {
        params: { userId }
      }
    } = this.props;
    return (
      <Query query={USER_QUERY} variables={{ userId }}>
        {({ data, loading, error }) => {
          if (error) {
            return <ErrorDisplay error={error} />;
          }

          return (
            <PageLayout pageTitle="User Details">
              {this.renderContent(data, loading)}
            </PageLayout>
          );
        }}
      </Query>
    );
  }
}

UserContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      activeSection: PropTypes.string
    }).isRequired
  }).isRequired
};

export default UserContainer;
