import React, { FunctionComponent } from "react";
import {
  GetCustomerCustomer,
  StrategyTasksQuery,
  StrategyTasksVariables,
  UpdateStrategyTaskMutation,
  UpdateStrategyTaskVariables
} from "../../../../generated/graphql";
import Loader from "../../../common/loader";
import ErrorDisplay from "../../../common/error-display";
import TaskCards from "./task-cards";
import { QueryResult, MutationFn } from "react-apollo";
import AppIcon from "../../../common/app-icon";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@material-ui/core";

interface Props {
  customer: GetCustomerCustomer;
  strategyTaskResult: QueryResult<StrategyTasksQuery, StrategyTasksVariables>;
  updateStrategyTaskMutation: MutationFn<
    UpdateStrategyTaskMutation,
    UpdateStrategyTaskVariables
  >;
}

const TasksContainer: FunctionComponent<Props> = ({
  strategyTaskResult: { data, loading, error, refetch },
  updateStrategyTaskMutation
}) => {
  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <ErrorDisplay error={error} refetch={refetch} />;
  }

  if (!data || !data.customer || !data.customer.activeStrategyCustomerProduct) {
    return (
      <Typography>
        <AppIcon standardRightMargin icon={faInfoCircle} />
        No tasks.
      </Typography>
    );
  }
  return (
    <TaskCards
      tasks={data.customer.activeStrategyCustomerProduct.tasks}
      updateStrategyTaskMutation={updateStrategyTaskMutation}
      refetchTasks={refetch}
    />
  );
};

export default TasksContainer;
