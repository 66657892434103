import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApolloQueryResult } from "apollo-client";
import ProjectCard from "./project-card";
import AddProjectDialog from "./add-project-dialog";
import AddContractDialog from "./add-contract-dialog";
import {
  SelectRoadmapVariables,
  SelectRoadmapQuery,
  SelectRoadmapCustomerProjects,
  SelectRoadmapCustomer
} from "../../../generated/graphql";

interface Props {
  refetchCustomerProjects: (
    variables?: SelectRoadmapVariables | undefined
  ) => Promise<ApolloQueryResult<SelectRoadmapQuery>>;
  customerProjects: SelectRoadmapCustomerProjects[];
  customer: SelectRoadmapCustomer;
}

interface State {
  showAddProject: boolean;
  showAddContract: boolean;
}

class Roadmap extends Component<Props, State> {
  state = {
    showAddContract: false,
    showAddProject: false
  };

  onNewProjectOpen = () => {
    this.setState({ showAddProject: true });
  };

  onNewProjectClose = () => {
    this.setState({ showAddProject: false });
  };

  onNewProjectCreated = () => {
    const { refetchCustomerProjects } = this.props;
    this.setState({ showAddProject: false });
    refetchCustomerProjects();
  };

  onNewContractOpen = () => {
    this.setState({ showAddContract: true });
  };

  onNewContractClose = () => {
    this.setState({ showAddContract: false });
  };

  onNewContractCreated = () => {
    const { refetchCustomerProjects } = this.props;
    this.setState({ showAddContract: false });
    refetchCustomerProjects();
  };

  render() {
    const { customerProjects, customer, ...rest } = this.props;
    const { showAddContract, showAddProject } = this.state;
    return (
      <div>
        <div style={{ marginBottom: "1em" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.onNewContractOpen}
          >
            <FontAwesomeIcon icon={faPlus} style={{ marginRight: ".5em" }} />
            Add Contract
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: ".5em" }}
            onClick={this.onNewProjectOpen}
          >
            <FontAwesomeIcon icon={faPlus} style={{ marginRight: ".5em" }} />
            Add One-time Project
          </Button>
        </div>
        {showAddProject && (
          <AddProjectDialog
            customer={customer}
            onCancel={this.onNewProjectClose}
            onProjectCreated={this.onNewProjectCreated}
          />
        )}
        {showAddContract && (
          <AddContractDialog
            customer={customer}
            onClose={this.onNewContractClose}
            onContractCreated={this.onNewContractCreated}
          />
        )}
        {customerProjects.map(customerProject => (
          <ProjectCard
            {...rest}
            key={customerProject.id}
            customer={customer}
            customerProject={customerProject}
          />
        ))}
      </div>
    );
  }
}

export default Roadmap;
