import React, { Component, Fragment } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  TextField
} from "@material-ui/core";
import { debounce } from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { GetCustomersForCustomerSelectComponent } from "../../../generated/graphql";
import Loader from "../loader";
import ErrorDisplay from "../error-display";

interface Props {
  onClose: () => void;
  onCustomerSelected: (customerId: number) => void;
}

interface State {
  search: string;
  searchText: string;
}

class SelectCustomerDialog extends Component<Props, State> {
  state = {
    search: "",
    searchText: ""
  };

  onSearchTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: e.target.value });
    this.updateSearch(e.target.value);
  };

  updateSearch = debounce(
    (search: string) => {
      this.setState({ search });
    },
    300,
    { trailing: true }
  );

  render() {
    const { onClose, onCustomerSelected } = this.props;
    const { search, searchText } = this.state;
    return (
      <Dialog open onClose={onClose} scroll="paper" maxWidth="sm" fullWidth>
        <DialogContent>
          <TextField
            variant="outlined"
            onChange={this.onSearchTextChanged}
            value={searchText}
            fullWidth
            label="Search"
            autoFocus
            style={{ marginBottom: "1em" }}
          />
          <div style={{ height: 300 }}>
            <GetCustomersForCustomerSelectComponent
              variables={{ search }}
              fetchPolicy="network-only"
            >
              {({ data, loading, error, refetch }) => {
                if (loading) {
                  return <Loader />;
                }
                if (error) {
                  return <ErrorDisplay error={error} refetch={refetch} />;
                }
                if (!data || !data.customers) {
                  return "There was an error.";
                }

                const {
                  customers: { items }
                } = data;
                return (
                  <Fragment>
                    {items.length === 0 && (
                      <div>
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          style={{ marginRight: ".5em" }}
                        />
                        No customers found.
                      </div>
                    )}
                    <List dense style={{ height: 300, overflowY: "scroll" }}>
                      {items &&
                        items.map(customer => (
                          <ListItem
                            key={customer.id}
                            button
                            onClick={() => onCustomerSelected(customer.id)}
                          >
                            <ListItemText
                              primary={customer.name}
                              secondary={`ID: ${customer.id}`}
                            />
                          </ListItem>
                        ))}
                    </List>
                  </Fragment>
                );
              }}
            </GetCustomersForCustomerSelectComponent>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default SelectCustomerDialog;
