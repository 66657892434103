import React from "react";
import Text from "../../common/styled/text";
import InstructionsContainer from "../../common/styled/instructions-container";

const BatchReview = () => (
  <InstructionsContainer>
    <Text>Review the inforgraphic created for each SKU.</Text>
  </InstructionsContainer>
);

export default BatchReview;
