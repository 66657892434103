import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  Button,
  FormControl
} from "@material-ui/core";
import { Formik, Form } from "formik";
import moment from "moment-business-days";
import { DatePicker } from "material-ui-pickers";

import { isDateDisabled } from "../../../utilities/date-helpers";
import { FieldWrapper } from "../../common/styled/field-wrapper";
import Text from "../../common/styled/text";

const AddProductModal = ({
  visible,
  onCancel,
  productCategories,
  onFormSubmit
}) => {
  if (!visible) {
    return null;
  }
  return (
    <Formik
      onSubmit={onFormSubmit}
      initialValues={{
        name: "",
        quantity: 1,
        productId: null,
        amount: 0,
        startDate: moment().nextBusinessDay()
      }}
      render={({
        handleSubmit,
        handleChange,
        values,
        setFieldValue,
        errors
      }) => (
        <Form onSubmit={handleSubmit}>
          <Dialog open={visible} onClose={onCancel} fullWidth>
            <DialogTitle>Add Product</DialogTitle>
            <DialogContent>
              {errors.global && <Text>{errors.global}</Text>}
              <FieldWrapper>
                <FormControl fullWidth>
                  <Select
                    onChange={handleChange}
                    value={values.productId}
                    name="productId"
                  >
                    {productCategories.map(productCategory =>
                      productCategory.products.map(product => (
                        <MenuItem value={product.id}>
                          {productCategory.name} / {product.name}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </FieldWrapper>
              {values.productId && (
                <Fragment>
                  <FieldWrapper>
                    <TextField
                      label="Quantity"
                      id="quantity"
                      name="quantity"
                      value={values.quantity}
                      onChange={handleChange}
                      fullWidth
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <TextField
                      label="Amount"
                      id="amount"
                      name="amount"
                      value={values.amount}
                      onChange={handleChange}
                      fullWidth
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <DatePicker
                      label="Start Date"
                      value={values.startDate}
                      format="M/D/YYYY"
                      maxDate={moment()
                        .nextBusinessDay()
                        .add(1, "year")}
                      placeholder={moment()
                        .nextBusinessDay()
                        .format("M/D/YYYY")}
                      shouldDisableDate={isDateDisabled(true, true, false)}
                      // handle clearing outside => pass plain array if you are not controlling value outside
                      disableOpenOnEnter
                      animateYearScrolling={false}
                      fullWidth
                      onChange={date => setFieldValue("startDate", date)}
                    />
                  </FieldWrapper>
                </Fragment>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={onCancel}>Cancel</Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Create Product
              </Button>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    />
  );
};

AddProductModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  customerProject: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  productCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      products: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string
        })
      )
    })
  ).isRequired,
  onFormSubmit: PropTypes.func.isRequired
};

export default AddProductModal;
