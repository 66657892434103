import React, { FunctionComponent, useState, useEffect } from "react";
import { debounce, cloneDeep, sortBy } from "lodash";
import styled from "styled-components";

/* Material UI & FontAwesome */
import { Button, TextField, Grid, Collapse } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSlidersH, faSync } from "@fortawesome/free-solid-svg-icons";

/* Child Components */
import { MutationFn, QueryResult } from "react-apollo";
import { faAmazon } from "@fortawesome/free-brands-svg-icons";
import { withSnackbar, InjectedNotistackProps } from "notistack";
import SkusTable from "./skus-table";
import SkuModal from "./sku-modal";
import SkusFilter from "./skus-filter";

/* Common Components */
import { StandardCard } from "../../common/standard-card";
import { FieldWrapper } from "../../common/styled/field-wrapper";
import AppIcon from "../../common/app-icon";

/* Graphql & Apollo Imports */
import {
  GetCustomerCustomer,
  CreateCustomerSkuMutation,
  CreateCustomerSkuVariables,
  UpdateCustomerSkuMutation,
  UpdateCustomerSkuVariables,
  SelectCustomerSkusQuery,
  SelectCustomerSkusVariables,
  SelectCustomerSkusItems,
  SelectCustomerSkusChildren,
  CreateCustomerSkuSyncRequestMutation,
  CreateCustomerSkuSyncRequestVariables
} from "../../../generated/graphql";
import { transformGraphQLErrorForFormik } from "../../../utilities/form-helpers";

/* Initial and SkuFilters Reset State */
const skuFiltersInitialState = {
  isSkuTypeOpen: false,
  isSyncStatusOpen: false,
  skuTypeFilters: [],
  syncStatusFilters: []
};

/* Parse skuFilters to object from localStorage */
export const parseLocalStorage = () => {
  const item = JSON.parse(localStorage.getItem("skuFilters") as string);
  if (item && item.isSkuTypeOpen) {
    return item;
  }
  return {
    ...skuFiltersInitialState
  };
};

/* Stringify skuFilters before setting on localStorage */
const setLocalStorage = (skuFilters: SkuFilters) => {
  localStorage.setItem("skuFilters", JSON.stringify(skuFilters));
};

const ActionWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
`;
export interface SkuFilters {
  isSkuTypeOpen: boolean;
  isSyncStatusOpen: boolean;
  skuTypeFilters: string[];
  syncStatusFilters: string[];
}
interface Props extends InjectedNotistackProps {
  customer: GetCustomerCustomer;
  onSearchChanged: (search: string) => void;
  customerSkusQueryResult: QueryResult<
    SelectCustomerSkusQuery,
    SelectCustomerSkusVariables
  >;
  createCustomerSkuMutation: MutationFn<
    CreateCustomerSkuMutation,
    CreateCustomerSkuVariables
  >;
  updateCustomerSkuMutation: MutationFn<
    UpdateCustomerSkuMutation,
    UpdateCustomerSkuVariables
  >;
  createCustomerSkuSyncRequestMutation: MutationFn<
    CreateCustomerSkuSyncRequestMutation,
    CreateCustomerSkuSyncRequestVariables
  >;
  onNextPage: () => any;
  onPreviousPage: () => any;
  onSkuTypesChange: (skuTypes: string[]) => void;
  onSyncStatusesChange: (syncStatuses: string[]) => void;
}

const Skus: FunctionComponent<Props> = props => {
  const [isShowFilters, setIsShowFilters] = useState<boolean>(
    parseLocalStorage().isSkuTypeOpen || parseLocalStorage().isSyncStatusOpen
  );
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedCustomerSku, setSelectedCustomerSku] = useState<
    SelectCustomerSkusItems | SelectCustomerSkusChildren | null
  >(null);
  const [
    selectedParentCustomerSku,
    setSelectedParentCustomerSku
  ] = useState<SelectCustomerSkusItems | null>(null);
  const [searchText, setSearchText] = useState<string>("");
  const [skuFilters, setSkuFilters] = useState<SkuFilters>(
    parseLocalStorage()
      ? parseLocalStorage()
      : cloneDeep(skuFiltersInitialState)
  );

  /* Component mounts set skuFilters on localStorage if doesn't already exist */
  useEffect(() => {
    const localStorageSkuFilters = localStorage.getItem("skuFilters");
    if (!localStorageSkuFilters) {
      setLocalStorage(cloneDeep(skuFiltersInitialState));
    }
  });

  const onAddSku = () => {
    setIsModalVisible(true);
    setSelectedParentCustomerSku(null);
  };

  const onAddVariation = (parentCustomerSku: SelectCustomerSkusItems) => {
    setIsModalVisible(true);
    setSelectedParentCustomerSku(parentCustomerSku);
  };

  const onSkuModalClose = () => {
    setIsModalVisible(false);
    setSelectedCustomerSku(null);
    setSelectedParentCustomerSku(null);
  };

  const onEditSku = (
    customerSku: SelectCustomerSkusItems | SelectCustomerSkusChildren,
    parentCustomerSku?: SelectCustomerSkusItems
  ) => () => {
    setSelectedCustomerSku(customerSku);
    setSelectedParentCustomerSku(parentCustomerSku || null);
  };

  const updateSearch = debounce(
    (searchText: string) => {
      const { onSearchChanged } = props;
      onSearchChanged(searchText);
    },
    350,
    { trailing: true }
  );

  const onSearchChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    updateSearch(e.target.value);
  };

  /* SKU FILTER HELPERS */
  const onResetFilters = () => {
    const { onSkuTypesChange, onSyncStatusesChange } = props;
    const clonedSkuFilters = cloneDeep(skuFiltersInitialState);
    localStorage.removeItem("skuFilters");
    setLocalStorage(clonedSkuFilters);
    setSkuFilters(clonedSkuFilters);
    onSkuTypesChange(clonedSkuFilters.skuTypeFilters);
    onSyncStatusesChange(clonedSkuFilters.syncStatusFilters);
  };

  const handleIsSkuTypeOpen = () => {
    const { onSkuTypesChange } = props;
    const localStorageSkuFilters = parseLocalStorage();
    const clonedSkuFilters = cloneDeep(skuFilters);

    if (skuFilters.isSkuTypeOpen) {
      localStorageSkuFilters.isSkuTypeOpen = false;
      localStorageSkuFilters.skuTypeFilters =
        skuFiltersInitialState.skuTypeFilters;

      clonedSkuFilters.isSkuTypeOpen = false;
      clonedSkuFilters.skuTypeFilters = cloneDeep(
        skuFiltersInitialState.skuTypeFilters
      );

      setLocalStorage(cloneDeep(localStorageSkuFilters));
      setSkuFilters({ ...clonedSkuFilters });
      onSkuTypesChange(clonedSkuFilters.skuTypeFilters);
    } else {
      localStorageSkuFilters.isSkuTypeOpen = !skuFilters.isSkuTypeOpen;
      setLocalStorage(cloneDeep(localStorageSkuFilters));
      setSkuFilters({
        ...skuFilters,
        isSkuTypeOpen: !skuFilters.isSkuTypeOpen
      });
    }
  };

  const handleIsSyncStatusOpen = () => {
    const { onSyncStatusesChange } = props;
    const localStorageSkuFilters = parseLocalStorage();
    const clonedSkuFilters = cloneDeep(skuFilters);

    if (skuFilters.isSyncStatusOpen) {
      localStorageSkuFilters.isSyncStatusOpen = false;
      localStorageSkuFilters.syncStatusFilters =
        skuFiltersInitialState.syncStatusFilters;

      clonedSkuFilters.isSyncStatusOpen = false;
      clonedSkuFilters.syncStatusFilters = cloneDeep(
        skuFiltersInitialState.syncStatusFilters
      );

      setLocalStorage(cloneDeep(localStorageSkuFilters));
      setSkuFilters({ ...clonedSkuFilters });
      onSyncStatusesChange(clonedSkuFilters.syncStatusFilters);
    } else {
      localStorageSkuFilters.isSyncStatusOpen = !skuFilters.isSyncStatusOpen;
      setLocalStorage(cloneDeep(localStorageSkuFilters));
      setSkuFilters({
        ...skuFilters,
        isSyncStatusOpen: !skuFilters.isSyncStatusOpen
      });
    }
  };

  const handleSkuTypeFilters = (skuType: string) => {
    const { onSkuTypesChange } = props;
    const localStorageSkuFilters = parseLocalStorage();
    const clonedSkuFilters = cloneDeep(skuFilters);

    if (skuFilters.skuTypeFilters.indexOf(skuType) === -1) {
      localStorageSkuFilters.skuTypeFilters.push(skuType);
      setLocalStorage(cloneDeep(localStorageSkuFilters));

      clonedSkuFilters.skuTypeFilters.push(skuType);
      setSkuFilters({ ...clonedSkuFilters });
      onSkuTypesChange(clonedSkuFilters.skuTypeFilters);
    } else {
      localStorageSkuFilters.skuTypeFilters.splice(
        localStorageSkuFilters.skuTypeFilters.indexOf(skuType),
        1
      );
      setLocalStorage(cloneDeep(localStorageSkuFilters));

      clonedSkuFilters.skuTypeFilters.splice(
        clonedSkuFilters.skuTypeFilters.indexOf(skuType),
        1
      );
      setSkuFilters({ ...clonedSkuFilters });
      onSkuTypesChange(clonedSkuFilters.skuTypeFilters);
    }
  };

  const handleSyncStatusFilters = (syncStatus: string) => {
    const { onSyncStatusesChange } = props;
    const localStorageSkuFilters = parseLocalStorage();
    const clonedSkuFilters = cloneDeep(skuFilters);

    if (skuFilters.syncStatusFilters.indexOf(syncStatus) === -1) {
      localStorageSkuFilters.syncStatusFilters.push(syncStatus);
      setLocalStorage(cloneDeep(localStorageSkuFilters));

      clonedSkuFilters.syncStatusFilters.push(syncStatus);
      setSkuFilters({ ...clonedSkuFilters });
      onSyncStatusesChange(clonedSkuFilters.syncStatusFilters);
    } else {
      localStorageSkuFilters.syncStatusFilters.splice(
        localStorageSkuFilters.syncStatusFilters.indexOf(syncStatus),
        1
      );
      setLocalStorage(cloneDeep(localStorageSkuFilters));

      clonedSkuFilters.syncStatusFilters.splice(
        clonedSkuFilters.syncStatusFilters.indexOf(syncStatus),
        1
      );
      setSkuFilters({ ...clonedSkuFilters });
      onSyncStatusesChange(clonedSkuFilters.syncStatusFilters);
    }
  };

  const requestSkuSync = async () => {
    const {
      customer,
      createCustomerSkuSyncRequestMutation,
      enqueueSnackbar,
      customerSkusQueryResult
    } = props;
    try {
      await createCustomerSkuSyncRequestMutation({
        variables: {
          input: {
            customerId: customer.id
          }
        }
      });
      enqueueSnackbar("SKU Sync has been requested.", { variant: "success" });
      customerSkusQueryResult.refetch();
    } catch (e) {
      const exc = transformGraphQLErrorForFormik(e);
      enqueueSnackbar(exc.global ? exc.global : e.message, {
        variant: "error"
      });
    }
  };

  const {
    customer,
    createCustomerSkuMutation,
    updateCustomerSkuMutation,
    customerSkusQueryResult,
    onNextPage,
    onPreviousPage
  } = props;
  const {
    data,
    variables: { limit, offset }
  } = customerSkusQueryResult;
  let totalCount = null;
  if (data && data.customer) {
    totalCount = data.customer.customerSkus.totalCount;
  }

  if (!data || !data.customer) {
    return null;
  }

  const inProgressSyncRequest = data.customer.customerSkuSyncRequests.find(
    x => x.isInProgress
  );

  return (
    <Grid container spacing={16}>
      <Grid item md={isShowFilters && 2}>
        <Collapse in={isShowFilters}>
          <SkusFilter
            skuFilters={skuFilters}
            onResetFilters={onResetFilters}
            handleIsSkuTypeOpen={handleIsSkuTypeOpen}
            handleIsSyncStatusOpen={handleIsSyncStatusOpen}
            handleSkuTypeFilters={handleSkuTypeFilters}
            handleSyncStatusFilters={handleSyncStatusFilters}
          />
        </Collapse>
      </Grid>
      <Grid item md={isShowFilters ? 10 : 12}>
        <StandardCard title="SKUs">
          <ActionWrapper>
            <div>
              <Button variant="contained" color="primary" onClick={onAddSku}>
                <FontAwesomeIcon icon={faPlus} style={{ marginRight: 8 }} />
                Add SKU
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={requestSkuSync}
                disabled={!!inProgressSyncRequest}
                style={{ marginLeft: ".5em" }}
              >
                {inProgressSyncRequest && (
                  <span>
                    <AppIcon icon={faSync} spin standardRightMargin />
                    Syncing with Amazon
                  </span>
                )}
                {!inProgressSyncRequest && (
                  <span>
                    <AppIcon icon={faAmazon} standardRightMargin />
                    Request Sync with Amazon
                  </span>
                )}
              </Button>
            </div>
            <Button
              color="primary"
              onClick={() => setIsShowFilters(!isShowFilters)}
            >
              <AppIcon standardRightMargin icon={faSlidersH} />
              Filter
            </Button>
          </ActionWrapper>
          <div style={{ marginTop: "1rem" }}>
            <FieldWrapper>
              <TextField
                onChange={onSearchChanged}
                value={searchText}
                variant="outlined"
                label="Search"
                fullWidth
                autoFocus
              />
            </FieldWrapper>
          </div>
          {(isModalVisible || selectedCustomerSku) && (
            <SkuModal
              customer={customer}
              onClose={onSkuModalClose}
              customerSku={selectedCustomerSku}
              parentCustomerSku={selectedParentCustomerSku}
              createCustomerSkuMutation={createCustomerSkuMutation}
              updateCustomerSkuMutation={updateCustomerSkuMutation}
              onCustomerSkuCreated={() => customerSkusQueryResult.refetch()}
            />
          )}
          <SkusTable
            customer={customer}
            customerSkusQueryResult={customerSkusQueryResult}
            onAddVariation={onAddVariation}
            onEditSku={onEditSku}
            onNextPage={onNextPage}
            onPreviousPage={onPreviousPage}
            count={totalCount || 0}
            limit={limit}
            offset={offset}
            skuFilters={skuFilters}
          />
        </StandardCard>
      </Grid>
    </Grid>
  );
};

export default withSnackbar(Skus);
