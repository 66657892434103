import React from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import Text from "../common/styled/text";

const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh
  align-items: center;
`;

const NotFound = () => (
  <Container>
    <div>
      <Text.HeaderLarge>Uh oh!</Text.HeaderLarge>
      <Text.HeaderSmall>Looks like something went wrong.</Text.HeaderSmall>
      <div>
        <Button as="a" href="/dashboard" variant="contained" color="primary">
          Go Back to Dashboard
        </Button>
      </div>
    </div>
  </Container>
);

export default NotFound;
