import React, { FunctionComponent, useState } from "react";

import { withSnackbar, InjectedNotistackProps } from "notistack";
import { ApolloQueryResult } from "apollo-client";
import ProductBatchNotesDialog from "./product-batch-notes-dialog";
import {
  CreateNoteComponent,
  SelectRoadmapVariables,
  SelectRoadmapQuery,
  RoadmapCustomerProductBatchNotesComponent,
  RoadmapCustomerProductBatchNotesCustomerProductBatch
} from "../../../../generated/graphql";

interface CustomerProductBatch {
  id: number;
}

interface Props extends InjectedNotistackProps {
  customerId: number;
  customerProductBatch: CustomerProductBatch;
  onCancel: () => void;
  refreshRoadmap: (
    variables?: SelectRoadmapVariables | undefined
  ) => Promise<ApolloQueryResult<SelectRoadmapQuery>>;
}

const ProductBatchNotesDialogContainer: FunctionComponent<Props> = (
  props: Props
) => {
  const [limit, setLimit] = useState<number>(20);
  const [offset, setOffset] = useState<number>(0);

  const onNextPage = () => {
    setOffset(offset + limit);
  };

  const onPreviousPage = () => {
    if (offset <= limit) {
      setOffset(0);
    } else {
      setOffset(offset - limit);
    }
  };

  const { customerProductBatch, customerId } = props;
  return (
    <RoadmapCustomerProductBatchNotesComponent
      variables={{
        customerProductBatchId: customerProductBatch.id,
        limit,
        offset
      }}
    >
      {({ data, loading, error, refetch }) => {
        if (loading) {
          return null;
        }
        const { enqueueSnackbar } = props;
        if (error) {
          enqueueSnackbar("Something went wrong trying to load notes.");
          return null;
        }
        if (
          !data ||
          !data.customerProductBatch ||
          !data.customerProductBatch.notes ||
          !data.customerProductBatch.notes.items
        ) {
          enqueueSnackbar("Something went wrong trying to load notes.");
          return null;
        }

        const {
          customerProductBatch: {
            notes: { items, totalCount }
          }
        } = data;

        return (
          <CreateNoteComponent>
            {createNoteMutation => (
              <ProductBatchNotesDialog
                customerId={customerId}
                notes={items}
                createNoteMutation={createNoteMutation}
                refetchNotes={refetch}
                onNextPage={onNextPage}
                onPreviousPage={onPreviousPage}
                count={totalCount || 0}
                limit={limit}
                offset={offset}
                {...props}
              />
            )}
          </CreateNoteComponent>
        );
      }}
    </RoadmapCustomerProductBatchNotesComponent>
  );
};

export default withSnackbar(ProductBatchNotesDialogContainer);
