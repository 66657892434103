/* The below helpers are used for managing the authentication token per the apps authentication process/flow */

export const getApiToken = () => localStorage.getItem("token");
export const getApiTokenExpiration = () =>
  localStorage.getItem("token_expiration");

export const setApiToken = (token, expiration) => {
  localStorage.setItem("token_expiration", expiration);
  localStorage.setItem("token", token);
};

export const clearToken = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("token_expiration");
};
