import React from "react";
import PropTypes from "prop-types";

import EditableDisplayField from "../common/editable-display-field";

const SkuDetails = ({ customerSku }) => (
  <div>
    <EditableDisplayField label="Title" value={customerSku.title} />
    <EditableDisplayField label="SKU" value={customerSku.sku} />
    <EditableDisplayField label="ASIN" value={customerSku.asin} />
    <EditableDisplayField label="UPC" value={customerSku.upc} />
  </div>
);

SkuDetails.propTypes = {
  customerSku: PropTypes.shape({
    title: PropTypes.string,
    sku: PropTypes.string,
    asin: PropTypes.string,
    upc: PropTypes.string
  }).isRequired
};

export default SkuDetails;
