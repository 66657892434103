import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";

export const CardTitle = ({ children }) => (
  <Typography variant="h6" gutterBottom>
    {children}
  </Typography>
);

CardTitle.propTypes = {
  children: PropTypes.node.isRequired
};
