import React from "react";

/* FontAwesome */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
  faSyncAlt
} from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";

/* Constants/Enums */
export const CustomerSkuSyncStatusEnum = {
  REQUESTED: "Sync Requested",
  IN_PROGRESS: "Sync In Progress",
  COMPLETED: "Completed",
  ERROR: "Error"
};

interface Props {
  status: string | null;
  errorMessage?: string | null;
}

const SyncStatus = (props: Props) => {
  switch (props.status) {
    case "Completed":
      return (
        <FontAwesomeIcon
          color="green"
          icon={faCheckCircle}
          title={CustomerSkuSyncStatusEnum.COMPLETED}
        />
      );
    case "Error":
      return (
        <FontAwesomeIcon
          color="red"
          icon={faTimesCircle}
          title={
            props.errorMessage
              ? props.errorMessage
              : CustomerSkuSyncStatusEnum.ERROR
          }
        />
      );
    case CustomerSkuSyncStatusEnum.IN_PROGRESS:
      return (
        <FontAwesomeIcon
          color="blue"
          icon={faSyncAlt}
          spin
          title={CustomerSkuSyncStatusEnum.IN_PROGRESS}
        />
      );
    case CustomerSkuSyncStatusEnum.REQUESTED:
      return (
        <FontAwesomeIcon
          color="blue"
          icon={faPaperPlane}
          title={CustomerSkuSyncStatusEnum.REQUESTED}
        />
      );
    case null:
      return <span>-</span>;
    default:
      return <span>{props.status}</span>;
  }
};

export default SyncStatus;
