import gql from "graphql-tag";

export default gql`
  query selectViewer {
    viewer {
      id
      firstName
      lastName
      fullName
      email
      profileImageUrl
    }
  }
`;
