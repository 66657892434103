import React, { FunctionComponent } from "react";
import { Formik, Form, FormikValues, FormikActions } from "formik";

/* Material UI */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  withStyles,
  Button
} from "@material-ui/core";

/* GraphQL Imports */
import { SelectCustomerProjects_Items } from "../../generated/graphql";

const styles = (theme: any) => ({
  margin: {
    margin: theme.spacing.unit
  }
});

interface Props {
  customerProject: SelectCustomerProjects_Items | null;
  onCancel: () => any;
  onFormSubmit: (
    values: FormikValues,
    actions: FormikActions<FormikValues>
  ) => Promise<void>;
  visible: boolean;
  classes: any;
}

const RecordSaleModal: FunctionComponent<Props> = ({
  customerProject,
  visible,
  onCancel,
  onFormSubmit,
  classes
}) => {
  if (!customerProject || !customerProject.customer) {
    return null;
  }
  return (
    <Formik
      onSubmit={onFormSubmit}
      initialValues={{ saleReferenceNumber: "" }}
      render={({ handleSubmit, handleChange, values }) => (
        <Dialog open={visible} onClose={onCancel}>
          <DialogTitle>Record Sale</DialogTitle>
          <DialogContent>
            <Form>
              <TextField
                disabled
                className={classes.margin}
                value={customerProject.customer.name}
                label="Customer"
                fullWidth
                margin="normal"
              />
              <TextField
                disabled
                className={classes.margin}
                value={customerProject.name ? customerProject.name : ""}
                label="Customer Project"
                fullWidth
              />
              <TextField
                disabled
                className={classes.margin}
                value={customerProject.amount}
                label="Amount"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                }}
              />
              <TextField
                autoFocus
                id="saleReferenceNumber"
                name="saleReferenceNumber"
                className={classes.margin}
                onChange={handleChange}
                value={values.saleReferenceNumber}
                label="Sale Reference Number"
                fullWidth
              />
            </Form>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel} color="default">
              Cancel
            </Button>
            <Button onClick={() => handleSubmit()} color="primary">
              Record Sale
            </Button>
          </DialogActions>
        </Dialog>
      )}
    />
  );
};

export default withStyles(styles)(RecordSaleModal);
