import React from "react";
import { Dialog, DialogTitle, DialogActions, Button } from "@material-ui/core";
import DangerButton from "../common/styled/danger-button";

interface Props {
  onConfirmDelete: () => any;
  onDeleteCancel: () => any;
  deleteMessage?: string;
  customMessage?: string;
}

/* ConfirmDeleteDialog is a reusable component used anywhere a delete action needs to be confirmed before completing the action */

const ConfirmDeleteDialog = (props: Props) => (
  <Dialog open onClose={props.onDeleteCancel} maxWidth="md">
    <DialogTitle>
      {props.customMessage
        ? props.customMessage
        : `Are you sure you want to remove this ${props.deleteMessage}?`}
    </DialogTitle>
    <DialogActions>
      <DangerButton
        variant="contained"
        size="large"
        onClick={() => props.onConfirmDelete()}
      >
        Yes
      </DangerButton>
      <Button size="large" onClick={props.onDeleteCancel}>
        No
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmDeleteDialog;
