import { Component } from "react";
import PropTypes from "prop-types";

/* eslint-disable no-undef */
export const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
  authEndpoint: `${process.env.REACT_APP_API_URL}/pusher/auth`,
  cluster: "us2",
  forceTLS: true
});
/* eslint-enable no-undef */

class RealTimeNotifier extends Component {
  componentDidMount() {
    const { channelName, eventName, onMessage } = this.props;
    this.pusherChannel = pusher.subscribe(channelName);
    this.pusherChannel.bind(eventName, data => {
      onMessage(data);
    });
  }

  componentWillUnmount() {
    const { channelName } = this.props;
    if (this.pusherChannel) {
      pusher.unsubscribe(channelName);
    }
  }

  render() {
    return null;
  }
}

RealTimeNotifier.propTypes = {
  channelName: PropTypes.string.isRequired,
  eventName: PropTypes.string.isRequired,
  onMessage: PropTypes.func.isRequired
};

export default RealTimeNotifier;
