import React from "react";

import Workspace from "./workspace";
import { ChildWorkspaceCommonProps } from "../task-workspace/workspace";
import { PhotoReshootTaskWorkspaceComponent } from "../../../generated/graphql";

interface Props extends ChildWorkspaceCommonProps {}

const PhotoReshootTaskContainer = (props: Props) => {
  const {
    task: { customerProductBatch }
  } = props;
  if (!customerProductBatch) {
    throw new Error("Unable to find batch.");
  }
  return (
    <PhotoReshootTaskWorkspaceComponent
      variables={{ customerProductBatchId: customerProductBatch.id }}
    >
      {queryResult => <Workspace {...props} queryResult={queryResult} />}
    </PhotoReshootTaskWorkspaceComponent>
  );
};

export default PhotoReshootTaskContainer;
