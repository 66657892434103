import React, { FunctionComponent, useState, Fragment } from "react";
const moment = require("moment");

/* Material UI & FontAwesome */
import {
  Typography,
  Button,
  ListItem,
  TextField,
  InputAdornment
} from "@material-ui/core";
import {
  faCircle,
  faCalendarAlt,
  faSave
} from "@fortawesome/free-regular-svg-icons";
import {
  faExclamationTriangle,
  faCheckCircle,
  faTimes
} from "@fortawesome/free-solid-svg-icons";

/* GraphQL & Apollo Imports */
import { CustomerNotesNoteReminders } from "../../../../generated/graphql";
import {
  CompleteValues,
  UpdateValues,
  ReminderToConvert
} from "./note-reminders-list";

/* Child Components */
import AppIcon from "../../app-icon";

interface Props {
  customerId: number;
  reminder: CustomerNotesNoteReminders;
  setReminderToUpdate: React.Dispatch<
    React.SetStateAction<CustomerNotesNoteReminders | null>
  >;
  setReminderToConvert: React.Dispatch<
    React.SetStateAction<ReminderToConvert | null>
  >;
  setIsSetDueDateDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setParentReminderContent: React.Dispatch<React.SetStateAction<string>>;
  onCompleteNoteReminder: (completeValues: CompleteValues) => Promise<void>;
  onUpdateNoteReminder: (
    updateValues: UpdateValues
  ) => Promise<null | undefined>;
  onDeleteNoteReminder: (noteReminderId: number) => Promise<void>;
}

const NoteReminder: FunctionComponent<Props> = ({
  customerId,
  reminder,
  onCompleteNoteReminder,
  setReminderToUpdate,
  onUpdateNoteReminder,
  setReminderToConvert,
  setIsSetDueDateDialogOpen,
  onDeleteNoteReminder,
  setParentReminderContent
}) => {
  const [localReminderContent, setLocalReminderContent] = useState<string>(
    reminder.content
  );
  const [isEditContent, setIsEditContent] = useState<boolean>(false);

  return (
    <Fragment>
      <ListItem style={{ marginBottom: ".275rem", padding: "0" }}>
        <Button
          size="small"
          style={{ minWidth: ".0625rem", textTransform: "none" }}
          onClick={() =>
            onCompleteNoteReminder({
              noteReminderId: reminder.id,
              completedAt: reminder.completedAt
                ? null
                : moment()
                    .utc()
                    .toDate()
            })
          }
        >
          <AppIcon
            icon={reminder.completedAt ? faCheckCircle : faCircle}
            color="grey"
          />
        </Button>
        {!isEditContent ? (
          <Button
            size="small"
            style={{ textTransform: "none" }}
            onClick={() => {
              setReminderToUpdate(reminder);
              setIsEditContent(true);
            }}
          >
            <Typography
              style={{
                textDecoration: reminder.completedAt ? "line-through" : "none",
                color: reminder.completedAt ? "grey" : ""
              }}
            >
              {reminder.content}
            </Typography>
          </Button>
        ) : (
          <TextField
            value={localReminderContent}
            autoFocus={true}
            onKeyPress={async e => {
              if (e.key === "Enter") {
                e.preventDefault();
                await onUpdateNoteReminder({
                  content: localReminderContent
                });
                setIsEditContent(false);
                setLocalReminderContent(localReminderContent);
              }
            }}
            onChange={e => {
              setLocalReminderContent(e.target.value);
              setParentReminderContent(e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    size="small"
                    color="primary"
                    style={{ minWidth: ".0625rem" }}
                    onClick={async () => {
                      await onUpdateNoteReminder({
                        content: localReminderContent
                      });
                      setIsEditContent(false);
                      setLocalReminderContent(localReminderContent);
                    }}
                  >
                    <AppIcon icon={faSave} />
                  </Button>
                  <Button
                    size="small"
                    style={{ minWidth: ".0625rem", color: "red" }}
                    onClick={() => {
                      setIsEditContent(false);
                      setLocalReminderContent(reminder.content);
                      setReminderToUpdate(null);
                    }}
                  >
                    <AppIcon icon={faTimes} />
                  </Button>
                </InputAdornment>
              )
            }}
          />
        )}
        {!reminder.completedAt && (
          <Fragment>
            <Button
              size="small"
              color="primary"
              style={{ textTransform: "none" }}
              onClick={() =>
                setReminderToConvert({
                  customerId,
                  noteReminderId: reminder.id,
                  title: reminder.content,
                  dueDate: reminder.dueAt ? reminder.dueAt : null
                })
              }
            >
              Convert to Task
            </Button>
            <Button
              size="small"
              color="primary"
              style={{ textTransform: "none" }}
              onClick={() => {
                setIsSetDueDateDialogOpen(true);
                setReminderToUpdate(reminder);
              }}
            >
              {reminder.dueAt ? (
                <Typography
                  style={{
                    color: moment(reminder.dueAt) > moment() ? "inherit" : "red"
                  }}
                >
                  <AppIcon
                    icon={
                      moment(reminder.dueAt) > moment()
                        ? faCalendarAlt
                        : faExclamationTriangle
                    }
                    standardRightMargin
                  />{" "}
                  {`Due ${moment(reminder.dueAt).fromNow()}`}
                </Typography>
              ) : (
                "Set a Date"
              )}
            </Button>
            <Button
              size="small"
              style={{ textTransform: "none", color: "red" }}
              onClick={() => onDeleteNoteReminder(reminder.id)}
            >
              Delete
            </Button>
          </Fragment>
        )}
      </ListItem>
    </Fragment>
  );
};

export default NoteReminder;
