import React, { FunctionComponent, Fragment } from "react";

/* Material UI */
import {
  TableBody,
  TableRow,
  TableCell,
  Typography,
  TableFooter
} from "@material-ui/core";

/* Child Componets */
import GlobalAvatar from "../../global-avatar";

/* Helper Function */
import { displayCustomersManaged } from "./display-customers-managed-helper";

/* GraphQL Imports */
import {
  SelectTeamsByGroupLeadUser,
  SelectTeamsByGroupTeamUsers
} from "../../../generated/graphql";

const sumAmountManaged = (
  leadUserAmount: number,
  accountManagers: SelectTeamsByGroupTeamUsers[]
) => {
  const accountManagersSum = accountManagers.reduce(
    (prev, curr) => prev + curr.user.accountManagement.amountManaged,
    0
  );
  return accountManagersSum + leadUserAmount;
};
interface Props {
  leadUser: SelectTeamsByGroupLeadUser;
  accountManagers: SelectTeamsByGroupTeamUsers[];
}

const AccountManagementOverviewTableBody: FunctionComponent<Props> = ({
  leadUser,
  accountManagers
}) => {
  return (
    <Fragment>
      <TableBody>
        <TableRow>
          <TableCell>
            <div style={{ display: "flex", alignItems: "center" }}>
              <GlobalAvatar
                skipQuery
                userId={leadUser.id}
                fullName={leadUser.fullName}
                profileImageUrl={leadUser.profileImageUrl}
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="caption"
                  noWrap
                  style={{ marginLeft: ".5rem" }}
                >
                  {leadUser.fullName}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="caption"
                  noWrap
                  style={{ marginLeft: ".5rem" }}
                >
                  Team Lead
                </Typography>
              </div>
            </div>
          </TableCell>
          <TableCell>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {leadUser.accountManagement.customersManaged &&
                displayCustomersManaged(
                  leadUser.accountManagement.customersManaged.items
                )}
            </div>
          </TableCell>
          <TableCell>
            <Typography variant="caption">
              {`$${leadUser.accountManagement.amountManaged.toFixed(2)}`}
            </Typography>
          </TableCell>
        </TableRow>

        {accountManagers.map(accountManager => (
          <TableRow>
            <TableCell>
              <div style={{ display: "flex", alignItems: "center" }}>
                <GlobalAvatar
                  skipQuery
                  userId={accountManager.user.id}
                  fullName={accountManager.user.fullName}
                  profileImageUrl={accountManager.user.profileImageUrl}
                />
                <Typography
                  variant="caption"
                  noWrap
                  style={{ marginLeft: ".5rem" }}
                >
                  {accountManager.user.fullName}
                </Typography>
              </div>
            </TableCell>
            <TableCell>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {accountManager.user.accountManagement.customersManaged &&
                  displayCustomersManaged(
                    accountManager.user.accountManagement.customersManaged.items
                  )}
              </div>
            </TableCell>
            <TableCell>
              <Typography variant="caption">
                {`$${accountManager.user.accountManagement.amountManaged.toFixed(
                  2
                )}`}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell>Total Amount Managed</TableCell>
          <TableCell />
          <TableCell>
            <Typography color="textSecondary">
              {`$${sumAmountManaged(
                leadUser.accountManagement.amountManaged,
                accountManagers
              ).toFixed(2)}`}
            </Typography>
          </TableCell>
        </TableRow>
      </TableFooter>
    </Fragment>
  );
};

export default AccountManagementOverviewTableBody;
