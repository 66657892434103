import gql from "graphql-tag";

export default gql`
  query addContentChildCustomerSkus($customerSkuId: Int!) {
    customerSku(customerSkuId: $customerSkuId) {
      id
      sku
      title
      children {
        id
        sku
        title
      }
    }
  }
`;
