import React, { FunctionComponent } from "react";

/* Material UI */
import { Button, Menu, MenuItem } from "@material-ui/core";

/* FontAwesome */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faCamera,
  faPlus
} from "@fortawesome/free-solid-svg-icons";

/* Constants */
import { PRODUCT_GROUPS } from "./product-groups";

interface Props {
  anchorElement: EventTarget & HTMLElement | null;
  onAddProduct: (productCategory: string) => void;
  onAddSomethingElseClick: (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void;
  onAddSomethingElseClose: () => void;
}

const AddProductActions: FunctionComponent<Props> = ({
  anchorElement,
  onAddProduct,
  onAddSomethingElseClick,
  onAddSomethingElseClose
}) => {
  return (
    <div style={{ marginTop: "1em", textAlign: "center" }}>
      <Button
        color="primary"
        onClick={() => onAddProduct(PRODUCT_GROUPS.LISTING_CONTENT_CREATION)}
      >
        <FontAwesomeIcon icon={faPencilAlt} style={{ marginRight: ".5em" }} />
        Add Content
      </Button>
      <Button
        color="primary"
        onClick={() => onAddProduct(PRODUCT_GROUPS.PHOTOGRAPHY)}
      >
        <FontAwesomeIcon icon={faCamera} style={{ marginRight: ".5em" }} />
        Add Photography
      </Button>
      <Button color="primary" onClick={onAddSomethingElseClick}>
        <FontAwesomeIcon icon={faPlus} style={{ marginRight: ".5em" }} />
        Add Something Else
      </Button>
      <Menu
        open={Boolean(anchorElement)}
        onClose={onAddSomethingElseClose}
        anchorEl={anchorElement}
      >
        <MenuItem
          onClick={() =>
            onAddProduct(PRODUCT_GROUPS.SPONSORED_ADS_PPC_MANAGEMENT)
          }
        >
          Sponsored Ads PPC Management
        </MenuItem>
        <MenuItem
          onClick={() =>
            onAddProduct(PRODUCT_GROUPS.SPONSORED_BRANDS_PPC_MANAGEMENT)
          }
        >
          Sponsored Brands PPC Management
        </MenuItem>
        <MenuItem
          onClick={() =>
            onAddProduct(PRODUCT_GROUPS.PRODUCT_DISPLAY_ADS_PPC_MANAGEMENT)
          }
        >
          Product Display Ads PPC Management
        </MenuItem>
        <MenuItem
          onClick={() => onAddProduct(PRODUCT_GROUPS.SOCIAL_ADVERTISING)}
        >
          Social Advertising
        </MenuItem>
        <MenuItem onClick={() => onAddProduct(PRODUCT_GROUPS.SOCIAL_POSTING)}>
          Social Posting
        </MenuItem>
        <MenuItem onClick={() => onAddProduct(PRODUCT_GROUPS.KEYWORD_RESEARCH)}>
          Keyword Research
        </MenuItem>
        <MenuItem onClick={() => onAddProduct(PRODUCT_GROUPS.EBC_TEMPLATE)}>
          EBC Template
        </MenuItem>
      </Menu>
    </div>
  );
};

export default AddProductActions;
