import React, { Component } from "react";
import { MutationFn } from "react-apollo";
import { Button } from "@material-ui/core";
import { withSnackbar, InjectedNotistackProps } from "notistack";
import PageLayout from "../common/page-layout";
import { StandardCard } from "../common/standard-card";
import { AdminMutation, AdminVariables } from "../../generated/graphql";
import RoleChecker from "../common/role-checker";
import { ROLES } from "../../constants/roles";
import { transformGraphQLErrorForFormik } from "../../utilities/form-helpers";
import { AlertInfo } from "../common/styled/alert";

interface Props extends InjectedNotistackProps {
  adminMutation: MutationFn<AdminMutation, AdminVariables>;
}

interface State {
  wasBambooHrSyncExecuted: boolean;
}

class Admin extends Component<Props, State> {
  state: State = {
    wasBambooHrSyncExecuted: false
  };

  startBambooHrSync = async () => {
    const { adminMutation, enqueueSnackbar } = this.props;
    try {
      await adminMutation({
        variables: {
          input: {
            bambooHRUsersSync: true
          }
        }
      });
      enqueueSnackbar("Bamboo HR Sync manually executed.", {
        variant: "success"
      });
      this.setState({
        wasBambooHrSyncExecuted: true
      });
    } catch (e) {
      const exc = transformGraphQLErrorForFormik(e);
      enqueueSnackbar(exc.global ? exc.global : e.message, {
        variant: "error"
      });
    }
  };

  render() {
    const { wasBambooHrSyncExecuted } = this.state;
    return (
      <PageLayout pageTitle="Admin">
        <RoleChecker allowedRoles={[ROLES.SUPER_ADMIN]}>
          <StandardCard title="Bamboo HR User Sync">
            <p>
              Normally this process runs daily at 9:00AM and does not require
              manual execution.
            </p>
            {wasBambooHrSyncExecuted && (
              <AlertInfo message="Sync was requested." />
            )}
            {!wasBambooHrSyncExecuted && (
              <p>
                <Button
                  onClick={this.startBambooHrSync}
                  color="primary"
                  variant="contained"
                >
                  Manually Run Sync
                </Button>
              </p>
            )}
          </StandardCard>
        </RoleChecker>
      </PageLayout>
    );
  }
}

export default withSnackbar(Admin);
