import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "@material-ui/core";

import { clearToken } from "../../utilities/token-helper";
import Text from "../common/styled/text";

/** Rollbar Utils */
import rollbar from "../../utilities/rollbar.ts";

class ErrorBoundary extends Component {
  state = {
    error: null,
    info: null
  };

  componentDidCatch(error, info) {
    this.setState({ error, info });
  }

  render() {
    const { children } = this.props;
    const { error, info } = this.state;

    if (error) {
      /* Rollbar critical error log */
      rollbar.critical(`Component: ErrorBoundary -> ${error.message}`, {
        error
      });

      if (error.message === "Unauthorized user") {
        clearToken();
        window.location.reload();
        return null;
      }

      return (
        <Grid container style={{ marginTop: 30 }}>
          <Grid item md={2} xs={1} />
          <Grid item md={8} xs={11}>
            <Text.HeaderLarge>Uh oh!</Text.HeaderLarge>
            <Text.HeaderSmall>
              Looks like something went wrong.
            </Text.HeaderSmall>
            <Text>{error.message}</Text>
            <ExpansionPanel>
              <ExpansionPanelSummary>Error Details</ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <pre>{info.componentStack}</pre>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <Text style={{ marginTop: 16 }}>
              <Button
                style={{ marginRight: ".5em" }}
                type="primary"
                onClick={() => window.location.reload()}
              >
                Refresh
              </Button>
              <a href="/dashboard">
                <Button color="primary">Go Back to Dashboard</Button>
              </a>
            </Text>
          </Grid>
        </Grid>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
};

export default ErrorBoundary;
