import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import { getApiToken, clearToken } from "../../utilities/token-helper";

/* eslint-disable */
const customFetch = (uri, options) => {
  const token = getApiToken();
  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }
  options.headers["X-App-Version"] = process.env.REACT_APP_VERSION;
  return fetch(uri, options);
};
/* eslint-enable */

class ApolloClientProvider {
  constructor() {
    this.client = new ApolloClient({
      link: ApolloLink.from([
        onError(({ networkError }) => {
          if (
            networkError &&
            networkError.statusCode &&
            networkError.statusCode === 401
          ) {
            clearToken();
            window.location.reload();
          }
        }),
        new HttpLink({
          uri: `${process.env.REACT_APP_API_URL}/admin/graphql`,
          fetch: customFetch
        })
      ]),
      cache: new InMemoryCache()
    });
  }
}

export default new ApolloClientProvider();
