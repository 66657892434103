import React, { Component } from "react";
import PropTypes from "prop-types";
import { Query, Mutation } from "react-apollo";

import CUSTOMER_PROJECT_QUERY from "./customer-project-query";
import UPDATE_CUSTOMER_PRODUCT_MUTATION from "./update-customer-product-mutation";
import DELETE_CUSTOMER_PRODUCT_MUTATION from "./delete-customer-product-mutation";
import Project from "./project";
import Loader from "../../common/loader";
import ErrorDisplay from "../../common/error-display";

class CustomerProject extends Component {
  onSkuSetForCustomerProduct = (
    updateCustomerProductMutation,
    refetch
  ) => async (customerProductId, customerSkuId) => {
    try {
      await updateCustomerProductMutation({
        variables: { input: { customerProductId, customerSkuId } }
      });
      await refetch();
    } catch (e) {
      console.error({
        message: "Uh oh!",
        description:
          "It looks like something went wrong trying to set the SKU. Please try again."
      });
    }
  };

  onDeleteCustomerProduct = (
    deleteCustomerProductMutation,
    refetch
  ) => async customerProductId => {
    try {
      await deleteCustomerProductMutation({
        variables: { input: { customerProductId } }
      });
      await refetch();
    } catch (e) {
      console.error({
        message: "Uh oh!",
        description:
          "It looks like something went wrong trying to cancel the Customer Product. Please try again."
      });
    }
  };

  render() {
    const { customer, customerProjectId } = this.props;

    return (
      <Query
        query={CUSTOMER_PROJECT_QUERY}
        variables={{ customerProjectId }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error, refetch }) => {
          if (loading) {
            return <Loader message="Loading..." />;
          }
          if (error) {
            return <ErrorDisplay error={error} refetch={refetch} />;
          }
          return (
            <Mutation mutation={UPDATE_CUSTOMER_PRODUCT_MUTATION}>
              {updateCustomerProductMutation => (
                <Mutation mutation={DELETE_CUSTOMER_PRODUCT_MUTATION}>
                  {deleteCustomerProductMutation => (
                    <Project
                      customer={customer}
                      customerProject={data.customerProject}
                      onSkuSetForCustomerProduct={this.onSkuSetForCustomerProduct(
                        updateCustomerProductMutation,
                        refetch
                      )}
                      onDeleteCustomerProduct={this.onDeleteCustomerProduct(
                        deleteCustomerProductMutation,
                        refetch
                      )}
                      refreshData={refetch}
                    />
                  )}
                </Mutation>
              )}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

CustomerProject.propTypes = {
  customer: PropTypes.shape({}).isRequired,
  customerProjectId: PropTypes.number.isRequired
};

export default CustomerProject;
