import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import moment from "moment-business-days";
import { Formik, Form } from "formik";
import {
  FormControlLabel,
  TextField,
  Switch,
  Button,
  Grid
} from "@material-ui/core";
import { DatePicker } from "material-ui-pickers";

import { isDateDisabled } from "../../../utilities/date-helpers";
import { StandardCard } from "../../common/standard-card";
import { FieldWrapper } from "../../common/styled/field-wrapper";
import Text from "../../common/styled/text";

const NewCustomerProject = ({ onFormSubmit, onCancel }) => (
  <Formik
    onSubmit={onFormSubmit}
    initialValues={{
      name: "",
      amount: 0,
      startDate: moment().nextBusinessDay(),
      notes: "",
      includesStrategy: false
    }}
    render={({ values, handleChange, setFieldValue, handleSubmit, errors }) => (
      <Grid container>
        <Grid item xs={12} lg={4}>
          <Form>
            <StandardCard title="New Project">
              {errors.global && <Text>{errors.global}</Text>}
              <FieldWrapper>
                <TextField
                  autoFocus
                  label="Project Name"
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  fullWidth
                />
              </FieldWrapper>
              <FieldWrapper>
                <FormControlLabel
                  control={
                    <Switch id="includesStrategy" name="includesStrategy" />
                  }
                  label="Includes Strategy"
                  onChange={e =>
                    setFieldValue("includesStrategy", e.target.checked)
                  }
                  checked={values.includesStrategy}
                />
              </FieldWrapper>

              <FieldWrapper>
                <TextField
                  label="Amount"
                  id="amount"
                  name="amount"
                  value={values.amount}
                  onChange={handleChange}
                  fullWidth
                />
              </FieldWrapper>
              <FieldWrapper>
                <DatePicker
                  label="Start Date"
                  value={values.startDate}
                  format="M/D/YYYY"
                  maxDate={moment()
                    .nextBusinessDay()
                    .add(1, "year")}
                  placeholder={moment()
                    .nextBusinessDay()
                    .format("M/D/YYYY")}
                  shouldDisableDate={isDateDisabled(true, true, false)}
                  // handle clearing outside => pass plain array if you are not controlling value outside
                  disableOpenOnEnter
                  animateYearScrolling={false}
                  fullWidth
                  onChange={date => setFieldValue("startDate", date)}
                />
              </FieldWrapper>
              <FieldWrapper>
                <TextField
                  label="Notes"
                  id="notes"
                  name="notes"
                  value={values.notes}
                  onChange={handleChange}
                  fullWidth
                  multiline
                />
              </FieldWrapper>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ marginRight: ".5em" }}
                  />
                  Create Project
                </Button>
                <Button style={{ marginLeft: ".5em" }} onClick={onCancel}>
                  Cancel
                </Button>
              </div>
            </StandardCard>
          </Form>
        </Grid>
      </Grid>
    )}
  />
);

NewCustomerProject.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default NewCustomerProject;
