import * as React from "react";
import Rollbar from "../../../utilities/rollbar";

import { Query } from "react-apollo";
import VIEWER_QUERY from "./viewer-query";

const RollbarPersonTracker: React.SFC = () => (
  <Query query={VIEWER_QUERY}>
    {({ data, loading, error }) => {
      if (loading) {
        return null;
      }
      if (error) {
        return null;
      }

      Rollbar.configure({
        payload: {
          person: {
            id: data.viewer.id, // required
            username: data.viewer.fullName,
            email: data.viewer.email
          }
        }
      });

      return null;
    }}
  </Query>
);

export default RollbarPersonTracker;
