import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

/* Material Components */
import { Tabs, Tab, AppBar } from "@material-ui/core";

/* Font Awesome */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAmazon } from "@fortawesome/free-brands-svg-icons";
import {
  faEdit,
  faUsers,
  faBarcode,
  faTasks,
  faHeartbeat,
  faMapMarked,
  faFile,
  faInfo
} from "@fortawesome/free-solid-svg-icons";

/* Common Components */
import AppLink from "../common/app-link";

/* Styled Components */
const StyledTab = styled(Tab)`
  min-width: 0 !important;
  font-size: 0.75rem !important;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 0.75rem;
`;

const LinkTab = props => <StyledTab component={AppLink} {...props} />;

const SubnavMenu1 = ({ customer, activeSection }) => (
  <div style={{ marginBottom: "1rem" }}>
    <AppBar position="static" color="default">
      <Tabs value={activeSection} fullWidth indicatorColor="primary">
        <LinkTab
          to={`/customers/${customer.id}/workspace`}
          icon={<StyledFontAwesomeIcon icon={faEdit} />}
          label="Workspace"
          value="workspace"
        />
        <LinkTab
          to={`/customers/${customer.id}/overview`}
          icon={<StyledFontAwesomeIcon icon={faInfo} />}
          label="Overview"
          value="overview"
        />
        <LinkTab
          to={`/customers/${customer.id}/users`}
          icon={<StyledFontAwesomeIcon icon={faUsers} />}
          label="Users"
          value="users"
        />
        <LinkTab
          to={`/customers/${customer.id}/roadmap`}
          icon={<StyledFontAwesomeIcon icon={faMapMarked} />}
          label="Roadmap"
          value="roadmap"
        />
        <LinkTab
          to={`/customers/${customer.id}/skus`}
          icon={<StyledFontAwesomeIcon icon={faBarcode} />}
          label="SKUs"
          value="skus"
        />
        <LinkTab
          to={`/customers/${customer.id}/tasks`}
          icon={<StyledFontAwesomeIcon icon={faTasks} />}
          label="Tasks"
          value="tasks"
        />
        <LinkTab
          to={`/customers/${customer.id}/activity`}
          icon={<StyledFontAwesomeIcon icon={faHeartbeat} />}
          label="Activity"
          value="activity"
        />
        <LinkTab
          to={`/customers/${customer.id}/amazon-accounts`}
          icon={<StyledFontAwesomeIcon icon={faAmazon} />}
          label="Amazon Accounts"
          value="amazon-accounts"
        />
        <LinkTab
          to={`/customers/${customer.id}/files`}
          icon={<StyledFontAwesomeIcon icon={faFile} />}
          label="Files"
          value="files"
        />
      </Tabs>
    </AppBar>
  </div>
);

SubnavMenu1.propTypes = {
  customer: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  activeSection: PropTypes.string.isRequired
};

export default SubnavMenu1;
