import React, { FunctionComponent } from "react";

import {
  DialogContent,
  DialogTitle,
  Dialog,
  Button,
  DialogActions,
  TablePagination
} from "@material-ui/core";
import { ApolloQueryResult } from "apollo-client";
import {
  CreateNoteMutationFn,
  NoteEntityType,
  RoadmapCustomerProductBatchNotesItems,
  RoadmapCustomerProductBatchNotesVariables,
  RoadmapCustomerProductBatchNotesQuery,
  SelectRoadmapVariables,
  SelectRoadmapQuery
} from "../../../../generated/graphql";
import NoteFeed from "../../../common/note-feed";

interface CustomerProductBatch {
  id: number;
}

interface Props {
  customerId: number;
  createNoteMutation: CreateNoteMutationFn;
  customerProductBatch: CustomerProductBatch;
  notes: RoadmapCustomerProductBatchNotesItems[];
  count: number;
  limit: number;
  offset: number;
  refetchNotes: (
    variables?: RoadmapCustomerProductBatchNotesVariables | undefined
  ) => Promise<ApolloQueryResult<RoadmapCustomerProductBatchNotesQuery>>;
  onCancel: () => void;
  refreshRoadmap: (
    variables?: SelectRoadmapVariables | undefined
  ) => Promise<ApolloQueryResult<SelectRoadmapQuery>>;
  onNextPage: () => any;
  onPreviousPage: () => any;
}

const ProductBatchNotesDialog: FunctionComponent<Props> = ({
  createNoteMutation,
  customerProductBatch,
  refetchNotes,
  refreshRoadmap,
  onCancel,
  notes,
  customerId,
  count,
  limit,
  offset,
  onNextPage,
  onPreviousPage
}) => {
  const onRevisionCreate = async (
    content: string,
    originalNoteId: number | null
  ) => {
    await createNoteMutation({
      variables: {
        input: {
          content,
          originalNoteId,
          entityId: customerProductBatch.id.toString(),
          entityType: NoteEntityType.CustomerProductBatch
        }
      }
    });
    refetchNotes();
    refreshRoadmap();
  };
  return (
    <Dialog fullWidth open onClose={onCancel} maxWidth="md" scroll="paper">
      <DialogTitle>Product Batch Notes</DialogTitle>
      <DialogContent>
        {count > limit && (
          <TablePagination
            component="div"
            count={count}
            rowsPerPage={limit}
            page={offset / limit}
            rowsPerPageOptions={[limit]}
            onChangePage={(e, page) => {
              const currentPage = offset / limit;
              if (page > currentPage) {
                onNextPage();
              } else {
                onPreviousPage();
              }
            }}
          />
        )}
        <NoteFeed
          customerId={customerId}
          notes={notes}
          onRevisionCreate={onRevisionCreate}
          onNoteCreated={refetchNotes}
        />
        {count > limit && (
          <TablePagination
            component="div"
            count={count}
            rowsPerPage={limit}
            page={offset / limit}
            rowsPerPageOptions={[limit]}
            onChangePage={(e, page) => {
              const currentPage = offset / limit;
              if (page > currentPage) {
                onNextPage();
              } else {
                onPreviousPage();
              }
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductBatchNotesDialog;
