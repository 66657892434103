import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { List } from "@material-ui/core";
import EditableDisplayField from "../common/editable-display-field";
import GlobalAvatar from "../global-avatar";
import AppLink from "../common/app-link";

const TaskDetails = ({ task }) => {
  const isOverdue = moment(task.dueDate) < moment().utc();
  return (
    <List dense>
      <EditableDisplayField label="Task Id" value={task.id} />
      <EditableDisplayField
        label="Due Date"
        value={
          isOverdue ? (
            <span style={{ color: "red" }}>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                style={{ marginRight: ".5em" }}
              />
              {moment(task.dueDate).format("L")}
            </span>
          ) : (
            moment(task.dueDate).format("M/D/YYYY h:mm A")
          )
        }
      />
      <EditableDisplayField
        label="Customer"
        value={(
          <AppLink to={`/customers/${task.customer.id}`}>
            {task.customer.name}
          </AppLink>
)}
      />
      <EditableDisplayField
        label="Account Manager"
        icon={(
          <GlobalAvatar
            userId={
              task.customer.accountManagerUser
                ? task.customer.accountManagerUser.id
                : null
            }
          />
)}
        value={
          task.customer.accountManagerUser
            ? task.customer.accountManagerUser.fullName
            : "Unassigned"
        }
      />
      <EditableDisplayField
        label="Project"
        value={
          task.customerProduct ? (
            <AppLink
              to={`/customers/${task.customer.id}/projects/${
                task.customerProduct.customerProject.id
              }`}
            >
              {task.customerProduct.customerProject.name}
            </AppLink>
          ) : (
            "-"
          )
        }
      />
      <EditableDisplayField
        label="Product"
        hideDivider
        value={task.customerProduct ? task.customerProduct.product.name : "-"}
      />
    </List>
  );
};

TaskDetails.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number,
    customer: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    }),
    customerProduct: PropTypes.shape({
      id: PropTypes.number,
      customerProject: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        contract: PropTypes.shape({
          id: PropTypes.number
        })
      })
    })
  }).isRequired
};

export default TaskDetails;
