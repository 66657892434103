import React, { FunctionComponent, useState } from "react";
const moment = require("moment-business-days");
import { DatePicker } from "material-ui-pickers";
/* Material UI */
import {
  DialogContent,
  DialogTitle,
  Dialog,
  Button,
  DialogActions
} from "@material-ui/core";

/* Font Awesome */
import { faEdit } from "@fortawesome/free-solid-svg-icons";

/* Common Components */
import { FieldWrapper } from "../../styled/field-wrapper";
import AppIcon from "../../app-icon";

/* Utilities */
import { isDateDisabled } from "../../../../utilities/date-helpers";
import { UpdateValues } from "./note-reminders-list";
import { CustomerNotesNoteReminders } from "../../../../generated/graphql";

interface Props {
  reminderToUpdate: CustomerNotesNoteReminders | null;
  onCancel: React.Dispatch<React.SetStateAction<boolean>>;
  onUpdateNoteReminder: (
    updateValues: UpdateValues
  ) => Promise<null | undefined>;
}

const SetDueDateDialog: FunctionComponent<Props> = ({
  onCancel,
  onUpdateNoteReminder,
  reminderToUpdate
}) => {
  const [dueDate, setDueDate] = useState<string | null>(
    reminderToUpdate && reminderToUpdate.dueAt
      ? moment(reminderToUpdate.dueAt)
      : moment().nextBusinessDay()
  );

  return (
    <Dialog
      fullWidth
      open
      onClose={() => {
        setDueDate("");
        onCancel(false);
      }}
      maxWidth="sm"
      disableBackdropClick
    >
      <DialogTitle>Set Due Date</DialogTitle>
      <DialogContent>
        <FieldWrapper>
          <DatePicker
            value={dueDate}
            fullWidth
            onChange={date => setDueDate(date)}
            label="Due Date"
            format="M/D/YYYY"
            maxDate={moment()
              .nextBusinessDay()
              .add(1, "year")}
            placeholder={moment()
              .nextBusinessDay()
              .format("M/D/YYYY")}
            shouldDisableDate={isDateDisabled(true, true, false)}
            // handle clearing outside => pass plain array if you are not controlling value outside
            disableOpenOnEnter
            animateYearScrolling={false}
          />
        </FieldWrapper>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            onUpdateNoteReminder({
              dueAt: moment(dueDate)
                .utc()
                .toDate()
            });
            onCancel(false);
          }}
        >
          <AppIcon standardRightMargin icon={faEdit} />
          Set Due Date
        </Button>
        <Button
          onClick={() => {
            setDueDate("");
            onCancel(false);
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SetDueDateDialog;
