import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";

import theme from "../core/mui-theme";
import { AppUpdateContext } from "../core/app-update-monitor";

const StyledA = styled.a`
  color: ${theme.palette.primary.dark}
  text-decoration: none;

  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
    color: ${theme.palette.primary.dark}
  }

  &:hover {
    color: ${theme.palette.primary.main}
  }
`;

const StyledLink = styled(Link)`
  color: ${theme.palette.primary.dark}
  text-decoration: none;

  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
    color: ${theme.palette.primary.dark}
  }

  &:hover {
    color: ${theme.palette.primary.main}
  }
`;

/* AppLink is a wrapper adding custom styling to React Routers Link component */
class AppLink extends Component {
  state = {};

  render() {
    const { to, children, ...rest } = this.props;
    const { isUpdateAvailable } = this.context;
    if (isUpdateAvailable) {
      return (
        <StyledA href={to} {...rest}>
          {children}
        </StyledA>
      );
    }
    return (
      <StyledLink to={to} {...rest}>
        {children}
      </StyledLink>
    );
  }
}

AppLink.defaultProps = {
  style: null
};

AppLink.propTypes = {
  to: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
  children: PropTypes.node.isRequired
};

AppLink.contextType = AppUpdateContext;

export const ButtonAppLink = props => <AppLink {...props} />;

export default AppLink;
