import React, { Component } from "react";
import { Query } from "react-apollo";
import { has } from "lodash";

import TASKS_QUERY from "./tasks-query";
import TasksTable from "./tasks-table";
import Empty from "./empty";
import ErrorDisplay from "../../common/error-display";
import Loader from "../../common/loader";
import { StandardCard } from "../../common/standard-card";

class TasksContainer extends Component {
  renderContent = ({ data, loading, error }) => {
    if (error) {
      return <ErrorDisplay error={error} />;
    }
    if (loading) {
      return <Loader />;
    }

    if (data && data.tasks && data.tasks.items.length === 0) {
      return <Empty />;
    }
    return (
      <TasksTable
        {...this.props}
        tasks={has(data, "tasks.items") ? data.tasks.items : []}
        totalTasks={has(data, "tasks.totalCount") ? data.tasks.totalCount : 0}
        viewer={data.viewer}
      />
    );
  };

  resetFilters = () => {
    this.setState({ ...this.initialState });
  };

  render() {
    const { customer } = this.props;
    return (
      <StandardCard title="Fulfillment Tasks">
        <Query
          query={TASKS_QUERY}
          variables={{ customerId: customer.id, limit: 1000 }}
          fetchPolicy="network-only"
          pollInterval={1000 * 10}
        >
          {this.renderContent}
        </Query>
      </StandardCard>
    );
  }
}

export default TasksContainer;
