import Rollbar from "rollbar";

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: process.env.NODE_ENV === "production",
  payload: {
    environment: "production"
  }
};

export default new Rollbar(rollbarConfig);
