import React, { Component } from "react";
import { withSnackbar, InjectedNotistackProps } from "notistack";
import { Formik, FormikProps, FormikActions, Form } from "formik";
import moment from "moment";

/* Material UI */
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select
} from "@material-ui/core";

/* GraphQL & Apollo Imports */
import { MutationFn } from "react-apollo";
import {
  SelectInventoryItemsItems,
  SelectInventoryItemsLocation,
  SelectInventoryItemsCondition,
  CreateInventoryItemMutation,
  CreateInventoryItemVariables,
  UpdateInventoryItemMutation,
  UpdateInventoryItemVariables
} from "../../generated/graphql";

/* Common Components */
import { FieldWrapper } from "../common/styled/field-wrapper";
import CustomerSelectContainer from "../common/customer-select";
import CustomerSkuSelectContainer from "../common/customer-sku-select";
import AlertError from "../common/styled/alert-error";
import { INVENTORY_STATUSES_OPTIONS } from "../../constants/inventory-statuses";

/* Utilities */
import { transformGraphQLErrorForFormik } from "../../utilities/form-helpers";

interface Props extends InjectedNotistackProps {
  onClose: () => void;
  onInventoryItemAdded?: () => any;
  onInventoryItemUpdated?: () => any;
  inventoryItem?: SelectInventoryItemsItems;
  locations: SelectInventoryItemsLocation[];
  conditions: SelectInventoryItemsCondition[];
  createInventoryItemMutation?: MutationFn<
    CreateInventoryItemMutation,
    CreateInventoryItemVariables
  >;
  updateInventoryItemMutation?: MutationFn<
    UpdateInventoryItemMutation,
    UpdateInventoryItemVariables
  >;
}

interface State {}

interface FormValues {
  global?: string | null;
  description: string;
  customerId: number | null;
  customerSkuId: number | null;
  locationId: string;
  storageLocation: string | null;
  conditionId: string;
  quantity: number;
  statusId: string;
}

class InventoryDetailsDialog extends Component<Props, State> {
  state = {};

  onSubmit = async (
    values: FormValues,
    formikActions: FormikActions<FormValues>
  ) => {
    const { inventoryItem, enqueueSnackbar } = this.props;
    try {
      if (inventoryItem) {
        // Update Inventory Item
        const {
          updateInventoryItemMutation,
          onInventoryItemUpdated
        } = this.props;
        if (!updateInventoryItemMutation) {
          return;
        }
        await updateInventoryItemMutation({
          variables: {
            input: {
              inventoryItemId: inventoryItem.id,
              conditionId: values.conditionId,
              description: values.description,
              locationId: values.locationId,
              storageLocation: values.storageLocation,
              statusId: values.statusId
            }
          }
        });

        if (onInventoryItemUpdated) {
          onInventoryItemUpdated();
        }
      } else {
        // Create Inventory Item
        const {
          createInventoryItemMutation,
          onInventoryItemAdded
        } = this.props;
        if (!createInventoryItemMutation) {
          return;
        }
        const customerId = values.customerId as number;
        delete values.statusId;
        await createInventoryItemMutation({
          variables: {
            input: { ...values, customerId, receivedAt: moment().toISOString() }
          }
        });

        if (onInventoryItemAdded) {
          onInventoryItemAdded();
        }
      }
      enqueueSnackbar("The selected inventory was updated successfully.", {
        variant: "success"
      });
    } catch (e) {
      const error = transformGraphQLErrorForFormik(e);
      formikActions.setSubmitting(false);
      formikActions.setErrors(error);
      if (error.global) {
        enqueueSnackbar(error.global, { variant: "error" });
      } else {
        enqueueSnackbar("There was an error updating the selected inventory.", {
          variant: "error"
        });
      }
    }
  };

  render() {
    const { onClose, inventoryItem, locations, conditions } = this.props;
    const goodCondition = conditions.find(c => c.id === "good");
    const defaultConditionId = goodCondition
      ? goodCondition.id
      : conditions[0].id;
    const initialConditionId = inventoryItem
      ? inventoryItem.condition.id
      : defaultConditionId;
    return (
      <Formik
        onSubmit={this.onSubmit}
        initialValues={{
          description: inventoryItem ? inventoryItem.description || "" : "",
          customerId: inventoryItem ? inventoryItem.customerId : null,
          customerSkuId: inventoryItem ? inventoryItem.customerSkuId : null,
          locationId: inventoryItem
            ? inventoryItem.location.id
            : locations[0].id,
          storageLocation: inventoryItem ? inventoryItem.storageLocation : "",
          conditionId: initialConditionId,
          quantity: inventoryItem ? inventoryItem.quantity : 1,
          statusId: inventoryItem ? inventoryItem.status.id : ""
        }}
        render={({
          values,
          setFieldValue,
          handleChange,
          handleSubmit,
          isSubmitting,
          errors
        }: FormikProps<FormValues>) => (
          <Form>
            <Dialog
              open
              onClose={onClose}
              scroll="body"
              maxWidth="sm"
              fullWidth
              disableBackdropClick
            >
              <DialogTitle>
                {inventoryItem ? "Edit Inventory" : "Add Inventory"}
              </DialogTitle>
              <DialogContent>
                {errors.global && <AlertError message={errors.global} />}
                <FieldWrapper>
                  <TextField
                    id="description"
                    name="description"
                    value={values.description}
                    label="Description"
                    onChange={handleChange}
                    fullWidth
                    error={!!errors.description}
                    helperText={errors.description}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <CustomerSelectContainer
                    allowClear={false}
                    customerId={values.customerId}
                    label="Customer"
                    disabled={!!inventoryItem}
                    onCustomerSelected={customerId =>
                      setFieldValue("customerId", customerId)
                    }
                  />
                </FieldWrapper>
                {values.customerId && (
                  <FieldWrapper>
                    <CustomerSkuSelectContainer
                      label="Customer SKU"
                      allowClear
                      customerSkuId={values.customerSkuId}
                      disabled={!!inventoryItem}
                      customerId={values.customerId}
                      onCustomerSkuSelected={customerSkuId =>
                        setFieldValue("customerSkuId", customerSkuId)
                      }
                    />
                  </FieldWrapper>
                )}
                {inventoryItem && (
                  <FieldWrapper>
                    <FormControl fullWidth>
                      <InputLabel>Status</InputLabel>
                      <Select
                        native
                        id="statusId"
                        name="statusId"
                        value={values.statusId}
                        onChange={handleChange}
                      >
                        {INVENTORY_STATUSES_OPTIONS.map(status => (
                          <option value={status.value}>{status.name}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </FieldWrapper>
                )}
                <FieldWrapper>
                  <FormControl fullWidth>
                    <InputLabel>Location</InputLabel>
                    <Select
                      native
                      id="locationId"
                      name="locationId"
                      value={values.locationId}
                      onChange={handleChange}
                    >
                      {locations.map(location => (
                        <option value={location.id}>{location.name}</option>
                      ))}
                    </Select>
                  </FormControl>
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    id="storageLocation"
                    name="storageLocation"
                    label="Storage Location"
                    value={values.storageLocation || ""}
                    onChange={handleChange}
                    fullWidth
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <FormControl fullWidth>
                    <InputLabel>Condition</InputLabel>
                    <Select
                      native
                      id="conditionId"
                      name="conditionId"
                      value={values.conditionId}
                      onChange={handleChange}
                    >
                      {conditions.map(condition => (
                        <option value={condition.id}>{condition.name}</option>
                      ))}
                    </Select>
                  </FormControl>
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    id="quantity"
                    name="quantity"
                    label="Quantity"
                    disabled={!!inventoryItem}
                    value={values.quantity}
                    onChange={handleChange}
                    error={!!errors.quantity}
                    helperText={errors.quantity}
                    fullWidth
                  />
                </FieldWrapper>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => handleSubmit()}
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  {inventoryItem ? "Update" : "Add"}
                </Button>
                <Button onClick={onClose}>Cancel</Button>
              </DialogActions>
            </Dialog>
          </Form>
        )}
      />
    );
  }
}

export default withSnackbar(InventoryDetailsDialog);
