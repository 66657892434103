import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";

import AppLink from "../../common/app-link";
import { StandardCard } from "../../common/standard-card";
import { TableHeaderCell } from "../../common/table-header-cell";

const ProjectsTable = ({ projects, customer }) => (
  <StandardCard title="Projects">
    <div style={{ marginBottom: "1em" }}>
      <AppLink
        to={`/customers/${customer.id}/contracts/new`}
        style={{ marginRight: ".5em" }}
      >
        <Button variant="contained" color="primary">
          <FontAwesomeIcon icon={faPlus} style={{ marginRight: 8 }} />
          Create Contract
        </Button>
      </AppLink>
      <AppLink
        to={`/customers/${customer.id}/projects/new`}
        style={{ marginRight: ".5em" }}
      >
        <Button variant="contained" color="primary">
          <FontAwesomeIcon icon={faPlus} style={{ marginRight: 8 }} />
          Create Project
        </Button>
      </AppLink>
    </div>
    <Table>
      <TableHead>
        <TableRow>
          <TableHeaderCell>Name</TableHeaderCell>
          <TableHeaderCell>Start Date</TableHeaderCell>
          <TableHeaderCell>Amount</TableHeaderCell>
          <TableHeaderCell>Status</TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {projects &&
          projects.map(project => (
            <TableRow key={project.id}>
              <TableCell>
                {!project.customerContractId && (
                  <AppLink
                    to={`/customers/${customer.id}/projects/${project.id}`}
                  >
                    {project.name}
                  </AppLink>
                )}
                {project.customerContractId && (
                  <span>
                    <AppLink
                      to={`/customers/${customer.id}/contracts/${
                        project.customerContractId
                      }`}
                    >
                      Contract #{project.customerContractId}
                    </AppLink>
                    <FontAwesomeIcon
                      icon={faCaretRight}
                      style={{ marginLeft: ".5em", marginRight: ".5em" }}
                    />
                    <AppLink
                      to={`/customers/${customer.id}/projects/${project.id}`}
                    >
                      {project.name}
                    </AppLink>
                  </span>
                )}
              </TableCell>
              <TableCell>
                {moment(project.startDate).format("M/D/YYYY")}
              </TableCell>
              <TableCell>${project.amount}</TableCell>
              <TableCell>{project.status.name}</TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  </StandardCard>
);

ProjectsTable.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customer: PropTypes.shape({}).isRequired
};

export default ProjectsTable;
