import React, { FunctionComponent, Fragment, useState } from "react";
import { debounce } from "lodash";

/* Common Components */
import NoteFeed from "../../../common/note-feed";
import { FieldWrapper } from "../../../common/styled/field-wrapper";

/* GraphQL & Apollo Imports */
import {
  CustomerNotesItems,
  NoteEntityType,
  CreateNoteMutationFn,
  StrategyTasksVariables,
  StrategyTasksQuery
} from "../../../../generated/graphql";
import { ApolloQueryResult } from "apollo-client";

/* Material UI */
import { TextField, TablePagination } from "@material-ui/core";

interface Customer {
  id: number;
}

interface Props {
  customer: Customer;
  notes: CustomerNotesItems[];
  createNoteMutation: CreateNoteMutationFn;
  searchText: string;
  count: number;
  limit: number;
  offset: number;
  onNoteCreated: () => void;
  strategyRefetch: (
    variables?: StrategyTasksVariables | undefined
  ) => Promise<ApolloQueryResult<StrategyTasksQuery>>;
  onSearchTextChange: (searchString: string) => void;
  onNextPage: () => any;
  onPreviousPage: () => any;
}

const CustomerNotes: FunctionComponent<Props> = (props: Props) => {
  const [searchValue, setSearchValue] = useState<string>(props.searchText);

  const updateSearch = debounce(
    (searchText: string) => {
      props.onSearchTextChange(searchText);
    },
    350,
    { trailing: true }
  );

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    updateSearch(e.target.value);
  };

  const onRevisionCreate = async (
    content: string,
    originalNoteId: number | null
  ) => {
    const { createNoteMutation, customer, onNoteCreated } = props;
    await createNoteMutation({
      variables: {
        input: {
          content,
          originalNoteId,
          entityId: customer.id.toString(),
          entityType: NoteEntityType.Customer
        }
      }
    });
    onNoteCreated();
  };

  const {
    notes,
    onNoteCreated,
    customer,
    strategyRefetch,
    count,
    limit,
    offset,
    onNextPage,
    onPreviousPage
  } = props;
  return (
    <Fragment>
      <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
        <FieldWrapper>
          <TextField
            onChange={onSearchChange}
            value={searchValue}
            variant="outlined"
            label="Search"
            fullWidth
          />
        </FieldWrapper>
      </div>
      {count > limit && (
        <TablePagination
          component="div"
          count={count}
          rowsPerPage={limit}
          page={offset / limit}
          rowsPerPageOptions={[limit]}
          onChangePage={(e, page) => {
            const currentPage = offset / limit;
            if (page > currentPage) {
              onNextPage();
            } else {
              onPreviousPage();
            }
          }}
        />
      )}
      <NoteFeed
        customerId={customer.id}
        notes={notes}
        onRevisionCreate={onRevisionCreate}
        wrapNewNoteInCard
        onNoteCreated={onNoteCreated}
        strategyRefetch={strategyRefetch}
      />
      {count > limit && (
        <TablePagination
          component="div"
          count={count}
          rowsPerPage={limit}
          page={offset / limit}
          rowsPerPageOptions={[limit]}
          onChangePage={(e, page) => {
            const currentPage = offset / limit;
            if (page > currentPage) {
              onNextPage();
            } else {
              onPreviousPage();
            }
          }}
        />
      )}
    </Fragment>
  );
};

export default CustomerNotes;
