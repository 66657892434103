import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect } from "react-router-dom";
import gql from "graphql-tag";

import ApolloClientProvider from "./apollo-client-provider";
import LoginContainer from "../login/container";
import {
  getApiToken,
  // getApiTokenExpiration,
  setApiToken,
  clearToken
} from "../../utilities/token-helper";
import Loader from "../common/loader";

class AuthManager extends Component {
  state = {
    isLoading: true,
    isLoggedIn: false
  };

  componentDidMount() {
    const token = getApiToken();
    if (!token) {
      this.setState({ isLoggedIn: false, isLoading: false });
      return;
    }
    // const tokenExpiration = getApiTokenExpiration();
    // TODO: set timeout with expiration to avoid unnecessary 401
    this.setState({ isLoggedIn: true, isLoading: false });
  }

  onGoogleLogin = async idToken => {
    const { client } = ApolloClientProvider;
    const loginMutation = gql`
      mutation login($googleIdToken: String!) {
        loginUser(googleIdToken: $googleIdToken) {
          token
          expiration
        }
      }
    `;
    try {
      const result = await client.mutate({
        mutation: loginMutation,
        variables: { googleIdToken: idToken }
      });
      // TODO: check for error
      setApiToken(
        result.data.loginUser.token,
        result.data.loginUser.expiration
      );
      this.setState({ isLoggedIn: true, isLoading: false });
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
      clearToken();
      this.setState({ isLoggedIn: false, isLoading: false });
    }
  };

  render() {
    const {
      children,
      location: { pathname }
    } = this.props;
    const { isLoading, isLoggedIn } = this.state;
    if (isLoading) {
      return <Loader />;
    }
    if (!isLoggedIn && pathname !== "/login") {
      return <Redirect to="/login" />;
    }
    if (!isLoggedIn && pathname === "/login") {
      return <LoginContainer onGoogleLogin={this.onGoogleLogin} />;
    }
    if (isLoggedIn && pathname === "/login") {
      return <Redirect to="/" />;
    }
    return children;
  }
}

AuthManager.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
    .isRequired
};

export default withRouter(AuthManager);
