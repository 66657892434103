import React, { FunctionComponent, Fragment } from "react";
import moment from "moment";

/* Material UI */
import { Chip, Button } from "@material-ui/core";

/* FontAwesome */
import {
  faComment,
  faCog,
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";

/* Common Components */
import AppIcon from "../../../../common/app-icon";
import Text from "../../../../common/styled/text";

/* GraphQL Imports */
import {
  SelectRoadmapCustomerProjects,
  CustomerProjectStatusEnum
} from "../../../../../generated/graphql";

interface Props {
  customerProject: SelectRoadmapCustomerProjects;
  isAllProductsComplete: boolean | undefined;
  percentBudgetUsed: number;
  productTotalAmount: number;
  onOpenContract: () => void;
  onProjectNotesOpen: () => void;
  onProjectOpen: () => void;
}

const ProjectDetails: FunctionComponent<Props> = ({
  customerProject,
  isAllProductsComplete,
  onOpenContract,
  onProjectNotesOpen,
  onProjectOpen,
  percentBudgetUsed,
  productTotalAmount
}) => {
  return (
    <Fragment>
      <div>
        <Text.HeaderSmall>
          {customerProject.name}

          {customerProject.customerContractId && (
            <span style={{ marginLeft: ".5em" }}>
              <Chip
                color="primary"
                label={`Contract ${customerProject.customerContractId}`}
                onClick={onOpenContract}
              />
            </span>
          )}
          {!customerProject.customerContractId && (
            <span style={{ marginLeft: ".5em" }}>
              <Chip color="secondary" label="One-Time Project" />
            </span>
          )}
          <Button onClick={onProjectNotesOpen} style={{ marginLeft: ".5em" }}>
            <FontAwesomeIcon icon={faComment} style={{ marginRight: ".5em" }} />
            {customerProject.notes ? customerProject.notes.totalCount : 0}
          </Button>

          <Button
            size="small"
            style={{ marginLeft: ".5em" }}
            onClick={onProjectOpen}
          >
            <FontAwesomeIcon icon={faCog} />
          </Button>
        </Text.HeaderSmall>
        <Text style={{ marginTop: ".5em", marginBottom: "1em" }}>
          <span style={{ marginRight: ".5em" }}>
            {customerProject.status.id ===
              CustomerProjectStatusEnum.Completed &&
              isAllProductsComplete && (
                <AppIcon
                  standardRightMargin
                  color="green"
                  icon={faCheckCircle}
                />
              )}
            {customerProject.status.name}
          </span>
          <FontAwesomeIcon icon={faClock} style={{ marginRight: ".5em" }} />
          Start
          {moment(customerProject.startDate) < moment() ? "ed" : "s"}{" "}
          <strong>
            {moment(customerProject.startDate).format("M/D/YYYY")}
          </strong>
        </Text>
      </div>

      <Text.HeaderXSmall>
        {customerProject.status.id === CustomerProjectStatusEnum.Completed &&
        isAllProductsComplete
          ? `$${customerProject.amount.toFixed(
              2
            )} - ${percentBudgetUsed}% allocated`
          : `$${productTotalAmount.toFixed(
              2
            )}/$${customerProject.amount.toFixed(
              2
            )} ${percentBudgetUsed}% allocated`}
      </Text.HeaderXSmall>
    </Fragment>
  );
};

export default ProjectDetails;
