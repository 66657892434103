import React from "react";
import PropTypes from "prop-types";
import { Query, Mutation } from "react-apollo";

import ADD_USER_TO_GROUP_MUTATION from "./add-user-to-group-mutation";
import REMOVE_USER_FROM_GROUP_MUTATION from "./remove-user-from-group-mutation";
import GROUPS_QUERY from "./groups-query";
import Loader from "../../common/loader";
import ErrorDisplay from "../../common/error-display";
import GroupList from "./group-list";

const Groups = ({ user }) => (
  <Mutation mutation={ADD_USER_TO_GROUP_MUTATION}>
    {addUserToGroupMutation => (
      <Mutation mutation={REMOVE_USER_FROM_GROUP_MUTATION}>
        {removeUserFromGroupMutation => (
          <Query query={GROUPS_QUERY} variables={{ userId: user.id }}>
            {({ data, loading, error, refetch }) => {
              if (loading) {
                return <Loader />;
              }
              if (error) {
                return <ErrorDisplay error={error} refetch={refetch} />;
              }
              return (
                <GroupList
                  viewer={data.viewer}
                  user={data.user}
                  groups={data.groups}
                  addUserToGroupMutation={addUserToGroupMutation}
                  removeUserFromGroupMutation={removeUserFromGroupMutation}
                />
              );
            }}
          </Query>
        )}
      </Mutation>
    )}
  </Mutation>
);

Groups.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number
  }).isRequired
};

export default Groups;
