import React, { Component } from "react";

import PageLayout from "../common/page-layout";
import { StandardCard } from "../common/standard-card";
import {
  GetCustomerProductBatchComponent,
  UpdateTaskInBatchComponent
} from "../../generated/graphql";
import BatchTable from "./batch-table";

interface Props {
  batchId: number;
}

interface State {
  selectedCustomerProductId: number | null;
}

class BatchContainer extends Component<Props, State> {
  render() {
    const { batchId } = this.props;
    return (
      <PageLayout pageTitle="Batch Workspace">
        <StandardCard title={`Batch #${batchId}`}>
          <UpdateTaskInBatchComponent>
            {updateTaskMutation => (
              <GetCustomerProductBatchComponent
                variables={{ customerProductBatchId: batchId }}
                fetchPolicy="network-only"
                pollInterval={1000 * 10}
              >
                {query => (
                  <BatchTable
                    customerProductBatchQuery={query}
                    updateTaskMutation={updateTaskMutation}
                  />
                )}
              </GetCustomerProductBatchComponent>
            )}
          </UpdateTaskInBatchComponent>
        </StandardCard>
      </PageLayout>
    );
  }
}

export default BatchContainer;
