import gql from "graphql-tag";

export default gql`
  query customerContracts($customerId: Int!) {
    customer(customerId: $customerId) {
      id
      contracts {
        id
        startDate
        customerProducts {
          id
        }
        status {
          id
          name
        }
      }
    }
  }
`;
