import React from "react";

import MyCustomersCard from "./my-customers-card";
import Loader from "../common/loader";
import ErrorDisplay from "../common/error-display";
import { SelectMyCustomersComponent } from "../../generated/graphql";

const MyCustomersContainer = () => (
  <SelectMyCustomersComponent fetchPolicy="network-only">
    {({ data, loading, error, refetch }) => {
      if (loading) {
        return <Loader />;
      }
      if (error) {
        return <ErrorDisplay error={error} refetch={refetch} />;
      }
      if (
        !data ||
        !data.viewer ||
        !data.viewer.accountManagement ||
        !data.viewer.accountManagement.customersManaged
      ) {
        return null;
      }
      return (
        <MyCustomersCard
          customerCount={
            data.viewer.accountManagement.customersManaged.totalCount
          }
          customers={data.viewer.accountManagement.customersManaged.items}
        />
      );
    }}
  </SelectMyCustomersComponent>
);

export default MyCustomersContainer;
