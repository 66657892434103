import React, { Component } from "react";
import {
  Grid,
  List,
  ListItemText,
  ListItem,
  ListItemAvatar,
  Avatar
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLockOpen,
  faCheck,
  faMinus
} from "@fortawesome/free-solid-svg-icons";
import { StandardCard } from "../common/standard-card";
import PageLayout from "../common/page-layout";

class BatchWorkspace extends Component {
  state = {};

  render() {
    return (
      <PageLayout pageTitle="Batch Workspace">
        <Grid container spacing={16}>
          <Grid item xs={12} lg={3}>
            <StandardCard title="Tasks in Batch">
              <List dense style={{ maxHeight: 500, overflowY: "auto" }}>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "green" }}>
                      <FontAwesomeIcon icon={faCheck} fixedWidth />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Task Type 1"
                    secondary="CUSTOMER-SKU-1"
                  />
                </ListItem>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "orange" }}>
                      <FontAwesomeIcon icon={faLockOpen} fixedWidth />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Task Type 2"
                    secondary="CUSTOMER-SKU-1"
                  />
                </ListItem>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "orange" }}>
                      <FontAwesomeIcon icon={faLockOpen} fixedWidth />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Task Type 2"
                    secondary="CUSTOMER-SKU-1"
                  />
                </ListItem>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "red" }}>
                      <FontAwesomeIcon icon={faMinus} fixedWidth />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Task Type 2"
                    secondary="CUSTOMER-SKU-1"
                  />
                </ListItem>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "orange" }}>
                      <FontAwesomeIcon icon={faLockOpen} fixedWidth />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Task Type 2"
                    secondary="CUSTOMER-SKU-1"
                  />
                </ListItem>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "orange" }}>
                      <FontAwesomeIcon icon={faLockOpen} fixedWidth />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Task Type 2"
                    secondary="CUSTOMER-SKU-1"
                  />
                </ListItem>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "orange" }}>
                      <FontAwesomeIcon icon={faLockOpen} fixedWidth />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Task Type 2"
                    secondary="CUSTOMER-SKU-1"
                  />
                </ListItem>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "orange" }}>
                      <FontAwesomeIcon icon={faLockOpen} fixedWidth />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Task Type 2"
                    secondary="CUSTOMER-SKU-1"
                  />
                </ListItem>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "orange" }}>
                      <FontAwesomeIcon icon={faLockOpen} fixedWidth />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Task Type 2"
                    secondary="CUSTOMER-SKU-1"
                  />
                </ListItem>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "orange" }}>
                      <FontAwesomeIcon icon={faLockOpen} fixedWidth />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Task Type 2"
                    secondary="CUSTOMER-SKU-1"
                  />
                </ListItem>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "orange" }}>
                      <FontAwesomeIcon icon={faLockOpen} fixedWidth />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Task Type 2"
                    secondary="CUSTOMER-SKU-1"
                  />
                </ListItem>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "orange" }}>
                      <FontAwesomeIcon icon={faLockOpen} fixedWidth />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Task Type 2"
                    secondary="CUSTOMER-SKU-1"
                  />
                </ListItem>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "orange" }}>
                      <FontAwesomeIcon icon={faLockOpen} fixedWidth />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Task Type 2"
                    secondary="CUSTOMER-SKU-1"
                  />
                </ListItem>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "orange" }}>
                      <FontAwesomeIcon icon={faLockOpen} fixedWidth />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Task Type 2"
                    secondary="CUSTOMER-SKU-1"
                  />
                </ListItem>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "orange" }}>
                      <FontAwesomeIcon icon={faLockOpen} fixedWidth />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Task Type 2"
                    secondary="CUSTOMER-SKU-1"
                  />
                </ListItem>
              </List>
            </StandardCard>
          </Grid>
          <Grid item xs={12} lg={9}>
            <StandardCard title="Task Workspace">TODO</StandardCard>
          </Grid>
        </Grid>
      </PageLayout>
    );
  }
}

export default BatchWorkspace;
