import React from "react";
import {
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Button,
  Hidden
} from "@material-ui/core";

import PageLayout from "../common/page-layout";
import TaskDetails from "./task-details";
import GlobalAvatar from "../global-avatar";
import { StandardCard } from "../common/standard-card";
import AlertError from "../common/styled/alert-error";
import Text from "../common/styled/text";
import { SelectTaskTask } from "../../generated/graphql";

interface Props {
  task: SelectTaskTask;
  onAssignToMe: () => any;
  goBack: () => any;
  onAssignToUser: (userId: number) => any;
}

const NotAssignedToMe = ({ task, onAssignToMe, goBack }: Props) => (
  <PageLayout pageTitle="Task Not Assigned to You">
    <StandardCard title={task.taskType.name}>
      <Hidden smUp>
        <ExpansionPanel>
          <ExpansionPanelSummary>Details</ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <TaskDetails task={task} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Hidden>
      <Grid container spacing={16}>
        <Grid item xs={12} lg={8}>
          <AlertError message="This task is not assigned to you." />

          <div style={{ marginTop: "1em" }}>
            <Text>This task is already assigned to:</Text>
            <div style={{ display: "flex", alignItems: "center" }}>
              <GlobalAvatar
                userId={task.assignedUser ? task.assignedUser.id : null}
                style={{ marginRight: "1em" }}
              />
              {task.assignedUser ? task.assignedUser.fullName : "Unassigned"}
            </div>
          </div>
          <div style={{ marginTop: "1em" }}>
            <Button
              variant="raised"
              color="primary"
              style={{ marginRight: ".5em" }}
              onClick={onAssignToMe}
            >
              Assign to Me
            </Button>
            <Button onClick={goBack}>Go Back</Button>
          </div>
          <Text>*Taking over this task may result in lost work.</Text>
        </Grid>
        <Grid item lg={4}>
          <StandardCard title="Additional Information">
            <TaskDetails task={task} />
          </StandardCard>
        </Grid>
      </Grid>
    </StandardCard>
  </PageLayout>
);

export default NotAssignedToMe;
