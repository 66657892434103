import React, { Component } from "react";
import { has } from "lodash";
import { Grid } from "@material-ui/core";

import Customers from "./customers";
import {
  SearchCustomersComponent,
  CustomerSortField,
  SortDirection,
  CreateCustomerComponent
} from "../../generated/graphql";
import PageLayout from "../common/page-layout";
import MyStarredCustomersContainer from "../dashboard/my-starred-customers-container";
import MyCustomersContainer from "../dashboard/my-customers-container";
import { StandardCard } from "../common/standard-card";

interface Props {}

interface State {
  search: string;
  sortField: CustomerSortField;
  sortDirection: SortDirection;
}

class CustomersWithData extends Component<Props, State> {
  state = {
    search: "",
    sortField: CustomerSortField.Name,
    sortDirection: SortDirection.Asc
  };

  onSearchChanged = (newSearchValue: string) => {
    this.setState({ search: newSearchValue });
  };

  render() {
    const { search } = this.state;
    return (
      <PageLayout pageTitle="Customers">
        <Grid container spacing={16}>
          <Grid item xs={12} lg={6}>
            <MyStarredCustomersContainer />
            <MyCustomersContainer />
          </Grid>
          <Grid item xs={12} lg={6}>
            <StandardCard title="All Customers">
              <CreateCustomerComponent>
                {createCustomerMutation => (
                  <SearchCustomersComponent
                    variables={{ ...this.state, limit: 1000 }}
                    fetchPolicy="network-only"
                    skip={search.length === 0}
                  >
                    {queryResult => (
                      <Customers
                        queryResult={queryResult}
                        onSearchChanged={this.onSearchChanged}
                        createCustomerMutation={createCustomerMutation}
                      />
                    )}
                  </SearchCustomersComponent>
                )}
              </CreateCustomerComponent>
            </StandardCard>
          </Grid>
        </Grid>
      </PageLayout>
    );
  }
}

export default CustomersWithData;
