import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CustomerUsersTable from "./users-table";
import { StandardCard } from "../../common/standard-card";
import {
  SelectCustomerUsersPhoneNumbers,
  SelectCustomerUsersEmailAddresses
} from "../../../generated/graphql";
import AppIcon from "../../common/app-icon";
import UserDialog from "./user-dialog";
import { UserPhoneNumberDialog } from "./user-phone-number-dialog";
import { ComposedRenderProps } from ".";
import { UserEmailAddressDialog } from "./user-email-address-dialog";
import CustomerUserUpdateDialog from "./customer-user-update-dialog";

interface Props extends ComposedRenderProps {
  customer: {
    id: number;
  };
}

interface State {
  isAddingUser: boolean;
  userIdForPhoneNumber: number | null;
  userPhoneNumberToEdit: SelectCustomerUsersPhoneNumbers | null;
  userIdForEmailAddress: number | null;
  userEmailAddressToEdit: SelectCustomerUsersEmailAddresses | null;
  customerUserToEdit: {
    customerUserId: number;
    firstName: string;
    lastName: string;
    title: string;
    notes: string;
  } | null;
}

class CustomerUsers extends Component<Props, State> {
  state = {
    isAddingUser: false,
    userIdForPhoneNumber: null,
    userPhoneNumberToEdit: null,
    userIdForEmailAddress: null,
    userEmailAddressToEdit: null,
    customerUserToEdit: null
  };

  onAddUser = () => {
    this.setState({ isAddingUser: true });
  };

  onAddUserClose = () => {
    this.setState({ isAddingUser: false });
  };

  onUserAdded = () => {
    const { customerUsersQueryResult } = this.props;
    customerUsersQueryResult.refetch();
    this.onAddUserClose();
  };

  onAddUserPhoneNumber = (userId: number) => {
    this.setState({ userIdForPhoneNumber: userId });
  };

  onAddUserPhoneNumberClose = () => {
    this.setState({ userIdForPhoneNumber: null });
  };

  onUserPhoneNumberAdded = () => {
    const { customerUsersQueryResult } = this.props;
    this.setState({ userIdForPhoneNumber: null });
    customerUsersQueryResult.refetch();
  };

  onEditUserPhoneNumber = (
    userPhoneNumber: SelectCustomerUsersPhoneNumbers
  ) => {
    this.setState({ userPhoneNumberToEdit: userPhoneNumber });
  };

  onEditUserPhoneNumberClose = () => {
    this.setState({ userPhoneNumberToEdit: null });
  };

  onUserPhoneNumberUpdated = () => {
    const { customerUsersQueryResult } = this.props;
    this.setState({ userPhoneNumberToEdit: null });
    customerUsersQueryResult.refetch();
  };

  onUserPhoneNumberDeleted = () => {
    const { customerUsersQueryResult } = this.props;
    customerUsersQueryResult.refetch();
  };

  onAddUserEmailAddress = (userId: number) => {
    this.setState({ userIdForEmailAddress: userId });
  };

  onAddUserEmailAddressClose = () => {
    this.setState({ userIdForEmailAddress: null });
  };

  onUserEmailAddressAdded = () => {
    const { customerUsersQueryResult } = this.props;
    this.setState({ userIdForEmailAddress: null });
    customerUsersQueryResult.refetch();
  };

  onEditUserEmailAddress = (
    userEmailAddress: SelectCustomerUsersEmailAddresses
  ) => {
    this.setState({ userEmailAddressToEdit: userEmailAddress });
  };

  onEditUserEmailAddressClose = () => {
    this.setState({ userEmailAddressToEdit: null });
  };

  onUserEmailAddressUpdated = () => {
    const { customerUsersQueryResult } = this.props;
    this.setState({ userEmailAddressToEdit: null });
    customerUsersQueryResult.refetch();
  };

  onUserEmailAddressDeleted = () => {
    const { customerUsersQueryResult } = this.props;
    customerUsersQueryResult.refetch();
  };

  onEditCustomerUser = (
    customerUserId: number,
    firstName: string,
    lastName: string,
    title: string,
    notes: string
  ) => {
    this.setState({
      customerUserToEdit: { customerUserId, firstName, lastName, title, notes }
    });
  };

  onEditCustomerUserClose = () => {
    this.setState({ customerUserToEdit: null });
  };

  onCustomerUserUpdated = () => {
    const { customerUsersQueryResult } = this.props;
    this.setState({ customerUserToEdit: null });
    customerUsersQueryResult.refetch();
  };

  render() {
    const {
      customer,
      customerUsersQueryResult,
      createCustomerUserMutation,
      createUserPhoneNumberMutation,
      updateUserPhoneNumberMutation,
      deleteUserPhoneNumberMutation,
      createUserEmailAddressMutation,
      deleteUserEmailAddressMutation,
      updateUserEmailAddressMutation,
      updateCustomerUserMutation
    } = this.props;
    const {
      isAddingUser,
      userIdForPhoneNumber,
      userPhoneNumberToEdit,
      userIdForEmailAddress,
      userEmailAddressToEdit,
      customerUserToEdit
    } = this.state;
    return (
      <StandardCard title="Users">
        {isAddingUser && (
          <UserDialog
            onClose={this.onAddUserClose}
            createCustomerUserMutation={createCustomerUserMutation}
            createUserPhoneNumberMutation={createUserPhoneNumberMutation}
            customerId={customer.id}
            onCustomerUserAdded={this.onUserAdded}
          />
        )}
        {userIdForPhoneNumber && (
          <UserPhoneNumberDialog
            onClose={this.onAddUserPhoneNumberClose}
            createUserPhoneNumberMutation={createUserPhoneNumberMutation}
            onUserPhoneNumberAdded={this.onUserPhoneNumberAdded}
            userId={userIdForPhoneNumber}
          />
        )}
        {userPhoneNumberToEdit && (
          <UserPhoneNumberDialog
            onClose={this.onEditUserPhoneNumberClose}
            updateUserPhoneNumberMutation={updateUserPhoneNumberMutation}
            onUserPhoneNumberUpdated={this.onUserPhoneNumberUpdated}
            userPhoneNumber={userPhoneNumberToEdit}
          />
        )}
        {userIdForEmailAddress && (
          <UserEmailAddressDialog
            onClose={this.onAddUserEmailAddressClose}
            createUserEmailAddressMutation={createUserEmailAddressMutation}
            onUserEmailAddressAdded={this.onUserEmailAddressAdded}
            userId={userIdForEmailAddress}
          />
        )}
        {userEmailAddressToEdit && (
          <UserEmailAddressDialog
            onClose={this.onEditUserEmailAddressClose}
            updateUserEmailAddressMutation={updateUserEmailAddressMutation}
            onUserEmailAddressUpdated={this.onUserEmailAddressUpdated}
            userEmailAddress={userEmailAddressToEdit}
          />
        )}
        {customerUserToEdit && (
          <CustomerUserUpdateDialog
            onClose={this.onEditCustomerUserClose}
            updateCustomerUserMutation={updateCustomerUserMutation}
            onCustomerUserUpdated={this.onCustomerUserUpdated}
            customerUser={customerUserToEdit}
          />
        )}
        <div style={{ marginBottom: "1em" }}>
          <Button variant="contained" color="primary" onClick={this.onAddUser}>
            <AppIcon icon={faPlus} standardRightMargin />
            Add User
          </Button>
        </div>
        <CustomerUsersTable
          queryResult={customerUsersQueryResult}
          onAddUserPhoneNumber={this.onAddUserPhoneNumber}
          onEditUserPhoneNumber={this.onEditUserPhoneNumber}
          deleteUserPhoneNumberMutation={deleteUserPhoneNumberMutation}
          onUserPhoneNumberDeleted={this.onUserPhoneNumberDeleted}
          onAddUserEmailAddress={this.onAddUserEmailAddress}
          onEditUserEmailAddress={this.onEditUserEmailAddress}
          deleteUserEmailAddressMutation={deleteUserEmailAddressMutation}
          onUserEmailAddressDeleted={this.onUserEmailAddressDeleted}
          onEditCustomerUser={this.onEditCustomerUser}
        />
      </StandardCard>
    );
  }
}

export { CustomerUsers };
