import React from "react";
import moment from "moment";
import ReactMarkdown from "react-markdown";

import { StandardCard } from "../standard-card";
import GlobalAvatar from "../../global-avatar";
import Text from "../styled/text";

interface Props {
  user: {
    id: number;
    fullName: string;
    profileImageUrl: string | null;
  };
  date: Date;
  content: string;
}

const FeedItem = (props: Props) => (
  <StandardCard>
    <div style={{ display: "flex", justifyContent: "flex-start" }}>
      <GlobalAvatar
        userId={props.user.id}
        profileImageUrl={props.user.profileImageUrl}
        fullName={props.user.fullName}
        style={{ marginRight: "1em" }}
        size="large"
      />
      <div>
        <Text
          style={{
            fontSize: "1.1em",
            fontWeight: "bold",
            marginBottom: 0
          }}
        >
          {props.user.fullName}
        </Text>
        <Text style={{ color: "#999999" }}>
          {moment(props.date).format("LLL")} ({moment(props.date).fromNow()})
        </Text>
      </div>
    </div>
    <div style={{ marginTop: ".5em" }}>
      <ReactMarkdown source={props.content} />
    </div>
  </StandardCard>
);

export { FeedItem };
