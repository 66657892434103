import React, { FunctionComponent, Fragment } from "react";

import { SelectTaskTask } from "../../../generated/graphql";
import { ChildWorkspaceCommonProps } from "./workspace";

/* Child Components */
import ChildWorkpace from "./child-workspace";
import AdditionalPanels from "./additional-panels";

import { Button } from "@material-ui/core";
import { StandardCard } from "../../common/standard-card";
import AppIcon from "../../common/app-icon";
import { faComment } from "@fortawesome/free-solid-svg-icons";

interface Props {
  task: SelectTaskTask;
  commonProps: ChildWorkspaceCommonProps;
  showTaskNotes: () => void;
  renderGlobalError: () => JSX.Element | null;
}

const TaskCard: FunctionComponent<Props> = ({
  task,
  commonProps,
  showTaskNotes,
  renderGlobalError
}) => {
  return (
    <Fragment>
      <StandardCard title={task.title ? task.title : task.taskType.name}>
        <div style={{ marginBottom: "1em" }}>
          <Button onClick={showTaskNotes}>
            <AppIcon icon={faComment} standardRightMargin />
            {task.notes ? task.notes.totalCount : 0} notes
          </Button>
        </div>
        {renderGlobalError()}
        <ChildWorkpace task={task} commonProps={commonProps} />
      </StandardCard>
      <AdditionalPanels task={task} />
    </Fragment>
  );
};

export default TaskCard;
