import React, { FunctionComponent } from "react";

/* Material UI */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  TablePagination
} from "@material-ui/core";

/* Common Components */
import { TableHeaderCell } from "../common/table-header-cell";

/* Graphql Imports */
import { SearchCustomerSkusItems } from "../../generated/graphql";

interface Props {
  search: string;
  customerSkus: SearchCustomerSkusItems[];
  returnCustomerSkuObject?: boolean;
  totalCount: number;
  limit: number;
  offset: number;
  onCustomerSkuSelected: (
    selectedCustomerSku: number | SearchCustomerSkusItems
  ) => void;
  onSearchUpdated: (search: string) => void;
  onCancel: () => void;
  onNextPage: () => void;
  onPreviousPage: () => void;
}

const SelectSkuModal: FunctionComponent<Props> = ({
  onCustomerSkuSelected,
  onSearchUpdated,
  onCancel,
  customerSkus,
  search,
  returnCustomerSkuObject,
  totalCount,
  limit,
  offset,
  onNextPage,
  onPreviousPage
}) => (
  <Dialog open title="Select SKU" maxWidth="lg" fullWidth>
    <DialogTitle>Select SKU</DialogTitle>
    <DialogContent>
      <div style={{ marginBottom: "1em" }}>
        <TextField
          placeholder="Search SKUs"
          value={search}
          onChange={e => onSearchUpdated(e.target.value)}
          fullWidth
          variant="outlined"
        />
      </div>
      {totalCount > limit && (
        <TablePagination
          component="div"
          count={totalCount}
          rowsPerPage={limit}
          page={offset / limit}
          rowsPerPageOptions={[limit]}
          onChangePage={(e, page) => {
            const currentPage = offset / limit;
            if (page > currentPage) {
              onNextPage();
            } else {
              onPreviousPage();
            }
          }}
        />
      )}
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell>SKU</TableHeaderCell>
            <TableHeaderCell>ASIN</TableHeaderCell>
            <TableHeaderCell>UPC</TableHeaderCell>
            <TableHeaderCell>Title</TableHeaderCell>
            <TableHeaderCell>Sku Type</TableHeaderCell>
            <TableHeaderCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {customerSkus &&
            customerSkus.map(customerSku => (
              <TableRow key={customerSku.id}>
                <TableCell>{customerSku.sku}</TableCell>
                <TableCell>{customerSku.asin || "-"}</TableCell>
                <TableCell>{customerSku.upc || "-"}</TableCell>
                <TableCell>{customerSku.title}</TableCell>
                <TableCell>{customerSku.skuType}</TableCell>
                <TableCell>
                  <Button
                    color="primary"
                    onClick={() =>
                      onCustomerSkuSelected(
                        returnCustomerSkuObject ? customerSku : customerSku.id
                      )
                    }
                  >
                    Select
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {totalCount > limit && (
        <TablePagination
          component="div"
          count={totalCount}
          rowsPerPage={limit}
          page={offset / limit}
          rowsPerPageOptions={[limit]}
          onChangePage={(e, page) => {
            const currentPage = offset / limit;
            if (page > currentPage) {
              onNextPage();
            } else {
              onPreviousPage();
            }
          }}
        />
      )}
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel}>Cancel</Button>
    </DialogActions>
  </Dialog>
);

export default SelectSkuModal;
