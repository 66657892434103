import React, { Component } from "react";
import { InfographicReviewItems } from "./infographic-review-items";
import {
  SelectTaskTask,
  SelectInfographicBatchReviewTaskWorkspaceComponent,
  SelectCustomerProductBatchInfographicReviewItemsComponent
} from "../../../../generated/graphql";

interface Props {
  task: SelectTaskTask;
}

interface State {}

class InfographicReviewItemsContainer extends Component<Props, State> {
  render() {
    const {
      task: { customerProductBatch }
    } = this.props;
    if (!customerProductBatch) {
      return null;
    }
    return (
      <SelectCustomerProductBatchInfographicReviewItemsComponent
        variables={{ customerProductBatchId: customerProductBatch.id }}
      >
        {queryResult => <InfographicReviewItems queryResult={queryResult} />}
      </SelectCustomerProductBatchInfographicReviewItemsComponent>
    );
  }
}

export default InfographicReviewItemsContainer;
