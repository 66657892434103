import React, { Component } from "react";
import { Formik, FormikActions, Form } from "formik";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { MutationFn } from "react-apollo";
import { AlertError } from "../../common/styled/alert";
import { FieldWrapper } from "../../common/styled/field-wrapper";
import {
  CreateUserEmailAddressMutation,
  CreateUserEmailAddressVariables,
  UpdateUserEmailAddressMutation,
  UpdateUserEmailAddressVariables,
  SelectCustomerUsersEmailAddresses
} from "../../../generated/graphql";
import { transformGraphQLErrorForFormik } from "../../../utilities/form-helpers";
import Loader from "../../common/loader";

interface Props {
  userId?: number;
  onClose: () => any;
  onUserEmailAddressAdded?: () => any;
  onUserEmailAddressUpdated?: () => any;
  createUserEmailAddressMutation?: MutationFn<
    CreateUserEmailAddressMutation,
    CreateUserEmailAddressVariables
  >;
  updateUserEmailAddressMutation?: MutationFn<
    UpdateUserEmailAddressMutation,
    UpdateUserEmailAddressVariables
  >;
  userEmailAddress?: SelectCustomerUsersEmailAddresses;
}

interface State {}

interface FormValues {
  global?: string | null;
  label: string;
  email: string;
  isPrimary: boolean;
}

class UserEmailAddressDialog extends Component<Props, State> {
  state = {};

  onSubmit = async (values: FormValues, actions: FormikActions<FormValues>) => {
    const {
      createUserEmailAddressMutation,
      userId,
      userEmailAddress,
      onUserEmailAddressAdded,
      updateUserEmailAddressMutation,
      onUserEmailAddressUpdated
    } = this.props;
    try {
      if (
        userEmailAddress &&
        updateUserEmailAddressMutation &&
        onUserEmailAddressUpdated
      ) {
        await updateUserEmailAddressMutation({
          variables: {
            input: {
              isPrimary: values.isPrimary,
              label: values.label,
              email: values.email,
              userEmailAddressId: userEmailAddress.id
            }
          }
        });
        actions.setSubmitting(false);
        onUserEmailAddressUpdated();
      } else if (
        userId &&
        createUserEmailAddressMutation &&
        onUserEmailAddressAdded
      ) {
        await createUserEmailAddressMutation({
          variables: {
            input: {
              userId,
              isPrimary: values.isPrimary,
              label: values.label,
              email: values.email
            }
          }
        });
        actions.setSubmitting(false);
        onUserEmailAddressAdded();
      }
    } catch (e) {
      actions.setErrors(transformGraphQLErrorForFormik(e));
      actions.setSubmitting(false);
    }
  };

  render() {
    const { onClose, userEmailAddress } = this.props;
    return (
      <Formik
        onSubmit={this.onSubmit}
        initialValues={{
          isPrimary: userEmailAddress ? userEmailAddress.isPrimary : false,
          label: userEmailAddress ? userEmailAddress.label : "",
          email: userEmailAddress ? userEmailAddress.email : ""
        }}
        render={({
          handleSubmit,
          handleChange,
          values,
          errors,
          isSubmitting
        }) => (
          <Dialog
            open
            onClose={onClose}
            fullWidth
            disableBackdropClick
            scroll="body"
          >
            <DialogTitle>
              {userEmailAddress ? "Update Email Address" : "Add Email Address"}
            </DialogTitle>
            <DialogContent>
              <Form>
                {errors.global && <AlertError message={errors.global} />}
                <FieldWrapper>
                  <TextField
                    label="Label"
                    id="label"
                    name="label"
                    onChange={handleChange}
                    value={values.label}
                    placeholder="Label"
                    fullWidth
                    helperText={errors.label}
                    error={!!errors.label}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    label="Email Address"
                    id="email"
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                    placeholder="Email Address"
                    fullWidth
                    helperText={errors.email}
                    error={!!errors.email}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <FormControlLabel
                    label="Is Primary Email Address"
                    control={
                      <Checkbox
                        onChange={handleChange}
                        checked={values.isPrimary}
                      />
                    }
                    id="isPrimary"
                    name="isPrimary"
                  />
                </FieldWrapper>
              </Form>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} disabled={isSubmitting}>
                Cancel
              </Button>
              <Button
                onClick={() => handleSubmit()}
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                {isSubmitting && <Loader />}
                {userEmailAddress
                  ? "Update Email Address"
                  : "Add Email Address"}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      />
    );
  }
}

export { UserEmailAddressDialog };
