import React, { Component } from "react";
import { Button, TextField } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import Text from "../../common/styled/text";
import { MutationFn } from "react-apollo";
import {
  SelectTaskTask,
  CreateNoteMutation,
  CreateNoteVariables,
  NoteEntityType
} from "../../../generated/graphql";
import { withSnackbar, InjectedNotistackProps } from "notistack";
import { transformGraphQLErrorForFormik } from "../../../utilities/form-helpers";

interface Props extends InjectedNotistackProps {
  task: SelectTaskTask;
  createNoteMutation: MutationFn<CreateNoteMutation, CreateNoteVariables>;
  onComplete: () => any;
  onClose: () => any;
}

interface State {
  content: string;
}

class Workspace extends Component<Props, State> {
  state = {
    content: "WELCOME CALL NOTES\n\n"
  };

  onCompleteTask = async () => {
    const {
      task,
      onComplete,
      createNoteMutation,
      enqueueSnackbar
    } = this.props;
    const { content } = this.state;

    if (!task || !task.customer) {
      return null;
    }

    try {
      await createNoteMutation({
        variables: {
          input: {
            entityType: NoteEntityType.Customer,
            entityId: task.customer.id.toString(),
            content
          }
        }
      });
      await onComplete();
    } catch (e) {
      const errors = transformGraphQLErrorForFormik(e);
      if (errors.global) {
        enqueueSnackbar(errors.global, { variant: "error" });
      }
      enqueueSnackbar("There was an error updating the Customer Product", {
        variant: "error"
      });
    }
  };

  onClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  onNoteChange = (e: any) => {
    this.setState({
      content: e.target.value
    });
  };

  render() {
    const { content } = this.state;
    return (
      <div>
        <div>
          <Text>
            <strong>Instructions</strong>
          </Text>
          <Text>Record notes from the welcome call.</Text>
          <TextField
            autoFocus
            rows={10}
            onChange={this.onNoteChange}
            value={content}
            fullWidth
          />
        </div>
        <div style={{ marginTop: "4em" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.onCompleteTask}
            size="large"
          >
            <FontAwesomeIcon icon={faCheck} style={{ marginRight: ".5em" }} />
            Complete Task
          </Button>
          <Button
            onClick={this.onClose}
            size="large"
            style={{ marginLeft: ".5em" }}
          >
            <FontAwesomeIcon icon={faTimes} style={{ marginRight: ".5em" }} />
            Close
          </Button>
        </div>
      </div>
    );
  }
}

export default withSnackbar(Workspace);
