import React, { FunctionComponent } from "react";
import moment from "moment";
import {
  faExclamationTriangle,
  faInfoCircle,
  faLayerGroup,
  faAngleRight
} from "@fortawesome/free-solid-svg-icons";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip
} from "@material-ui/core";

import AppLink from "../common/app-link";
import { StandardCard } from "../common/standard-card";
import { TableHeaderCell } from "../common/table-header-cell";
import AppIcon from "../common/app-icon";
import { SelectMyTasksItems } from "../../generated/graphql";

interface Props {
  tasks: SelectMyTasksItems[];
  taskCount: number;
}

const MyTasksCard: FunctionComponent<Props> = ({ tasks, taskCount }) => (
  <StandardCard title={`My Tasks (${taskCount})`}>
    <Table>
      <TableHead>
        <TableRow>
          <TableHeaderCell>Task</TableHeaderCell>
          <TableHeaderCell>Customer</TableHeaderCell>
          <TableHeaderCell>Due</TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tasks.map(task => (
          <TableRow key={task.id}>
            <TableCell>
              {task.customerProductBatch && (
                <span>
                  <AppLink
                    to={`/batches/${task.customerProductBatch.id}`}
                    title="View Batch"
                  >
                    <AppIcon icon={faLayerGroup} standardRightMargin />#
                    {task.customerProductBatch.id}
                  </AppLink>
                  <AppIcon icon={faAngleRight} wideLeftMargin wideRightMargin />
                </span>
              )}
              <AppLink to={`/tasks/${task.id}`}>
                {task.title || task.taskType.name}
              </AppLink>
            </TableCell>
            <TableCell>
              {task.customer && (
                <AppLink to={`/customers/${task.customer.id}`}>
                  {task.customer.name}
                </AppLink>
              )}
            </TableCell>
            <TableCell>
              {moment(task.dueDate) < moment() && (
                <span style={{ color: "red" }}>
                  <Tooltip title={moment(task.dueDate).format("LLL")}>
                    <AppIcon icon={faExclamationTriangle} standardRightMargin />
                  </Tooltip>
                  {moment(task.dueDate).fromNow()}
                </span>
              )}
              {moment(task.dueDate) >= moment() && (
                <span>
                  <Tooltip title={moment(task.dueDate).format("LLL")}>
                    <AppIcon icon={faInfoCircle} standardRightMargin />
                  </Tooltip>
                  {moment(task.dueDate).fromNow()}
                </span>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </StandardCard>
);

export default MyTasksCard;
