import React, { FunctionComponent, Fragment, useState } from "react";
const moment = require("moment");

/* GraphQL Imports */
import {
  StrategyTasksTasks,
  UpdateStrategyTaskMutation,
  UpdateStrategyTaskVariables,
  StrategyTasksVariables,
  StrategyTasksQuery
} from "../../../../generated/graphql";

/* Material UI & FontAwesome */
import {
  Typography,
  Button,
  Paper,
  Theme,
  withStyles,
  TextField,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faExclamationTriangle,
  faTimes
} from "@fortawesome/free-solid-svg-icons";

/* Common Components */
import AppLink from "../../../common/app-link";
import GlobalAvatar from "../../../global-avatar";
import { MutationFn } from "react-apollo";
import { withSnackbar, InjectedNotistackProps } from "notistack";
import { FieldWrapper } from "../../../common/styled/field-wrapper";
import AppIcon from "../../../common/app-icon";
import { faSave, faCircle } from "@fortawesome/free-regular-svg-icons";
import { ApolloQueryResult } from "apollo-client";
import styled from "styled-components";
import ConfirmCompleteDialog from "../../../common/confirm-complete-dialog";
import SelectUserDialog from "../../../common/user-select/select-user-dialog";
import { ROLES } from "../../../../constants/roles";
import UpdateDueDateDialog from "./update-due-date-dialog";

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TitleWrapper = styled.div`
  width: 100%;
  padding-left: 0.5rem;
`;

const TaskContentWrapper = styled.div`
  padding-left: 2.7rem;
`;

const styles = (theme: Theme) => ({
  root: {
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2
  }
});

interface Props extends InjectedNotistackProps {
  task: StrategyTasksTasks;
  isOverdue: boolean;
  classes: any;
  updateStrategyTaskMutation: MutationFn<
    UpdateStrategyTaskMutation,
    UpdateStrategyTaskVariables
  >;
  refetchTasks: (
    variables?: StrategyTasksVariables | undefined
  ) => Promise<ApolloQueryResult<StrategyTasksQuery>>;
}

const TaskCard: FunctionComponent<Props> = ({
  task,
  isOverdue,
  classes,
  updateStrategyTaskMutation,
  refetchTasks,
  enqueueSnackbar
}) => {
  const [isEditTaskTitle, setIsEditTaskTitle] = useState<boolean>(false);
  const [newTitleValue, setNewTitleValue] = useState<string>(task.title);
  const [taskToComplete, setTaskToComplete] = useState<number | null>(null);
  const [isAssignUser, setIsAssignUser] = useState<boolean>(false);
  const [isEditDueDate, setIsEditDueDate] = useState<boolean>(false);
  const [newDueDate, setNewDueDate] = useState<string | null>(null);

  const onUpdateStrategyTaskTitle = async () => {
    try {
      await updateStrategyTaskMutation({
        variables: { input: { taskId: task.id, title: newTitleValue } }
      });
      await refetchTasks();
      setNewTitleValue("");
      setIsEditTaskTitle(false);
      enqueueSnackbar("The task title was updated successfully.", {
        variant: "success"
      });
    } catch (e) {
      enqueueSnackbar("There was an error updating the task title.", {
        variant: "error"
      });
    }
  };

  const onUpdateStrategyTaskDueDate = async () => {
    try {
      await updateStrategyTaskMutation({
        variables: {
          input: {
            taskId: task.id,
            dueDate: moment(newDueDate ? newDueDate : task.dueDate)
              .utc()
              .toDate()
          }
        }
      });
      await refetchTasks();
      setNewDueDate(null);
      setIsEditDueDate(false);
      enqueueSnackbar("The task due date was updated successfully.", {
        variant: "success"
      });
    } catch (e) {
      enqueueSnackbar("There was an error updating the task due date.", {
        variant: "error"
      });
    }
  };

  const onCancelUpdateDueDate = () => {
    setIsEditDueDate(false);
    setNewDueDate(null);
  };

  const onCompleteTask = async () => {
    try {
      await updateStrategyTaskMutation({
        variables: { input: { taskId: task.id, statusId: "completed" } }
      });
      await refetchTasks();
      setTaskToComplete(null);
      enqueueSnackbar("The task has been completed successfully.", {
        variant: "success"
      });
    } catch (e) {
      enqueueSnackbar("There was an error completing the task.", {
        variant: "error"
      });
    }
  };

  const onCancelCompleteTask = () => {
    setTaskToComplete(null);
    setNewTitleValue(task.title);
  };

  const onAssignUser = async (assignedUserId: number | null) => {
    try {
      await updateStrategyTaskMutation({
        variables: { input: { taskId: task.id, assignedUserId } }
      });
      await refetchTasks();
      setIsAssignUser(false);
      enqueueSnackbar("The selected user has been assigned successfully.", {
        variant: "success"
      });
    } catch (e) {
      enqueueSnackbar("There was an error assigning the selected user.", {
        variant: "error"
      });
    }
  };

  return (
    <Fragment>
      {taskToComplete && (
        <ConfirmCompleteDialog
          onConfirmComplete={onCompleteTask}
          onCompleteCancel={onCancelCompleteTask}
          completeMessage="task"
        />
      )}
      {isAssignUser && (
        <SelectUserDialog
          onUserSelected={onAssignUser}
          onClose={() => setIsAssignUser(false)}
          roles={[ROLES.EMPLOYEE]}
          allowUnassigned
        />
      )}
      {isEditDueDate && (
        <UpdateDueDateDialog
          onCancel={onCancelUpdateDueDate}
          setNewDueDate={setNewDueDate}
          currentDueDate={task.dueDate}
          onUpdateStrategyTaskDueDate={onUpdateStrategyTaskDueDate}
        />
      )}
      <Paper className={classes.root} elevation={1}>
        <TitleContainer>
          <IconButton
            onClick={() => setTaskToComplete(task.id)}
            style={{ padding: " 0.25rem" }}
          >
            <FontAwesomeIcon icon={faCircle} color="grey" />
          </IconButton>
          <TitleWrapper>
            {!isEditTaskTitle ? (
              <Button
                size="small"
                style={{ padding: " 0.25rem", textTransform: "none" }}
                onClick={() => {
                  setNewTitleValue(task.title);
                  setIsEditTaskTitle(true);
                }}
              >
                <Typography variant="h6">{task.title}</Typography>
              </Button>
            ) : (
              <FieldWrapper>
                <TextField
                  value={newTitleValue}
                  placeholder="Title..."
                  autoFocus={true}
                  style={{ width: "50%" }}
                  onKeyPress={e => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      onUpdateStrategyTaskTitle();
                    }
                  }}
                  onChange={e => setNewTitleValue(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          size="small"
                          color="primary"
                          style={{ minWidth: ".0625rem" }}
                          onClick={() => onUpdateStrategyTaskTitle()}
                        >
                          <AppIcon icon={faSave} />
                        </Button>
                        <Button
                          size="small"
                          style={{ minWidth: ".0625rem", color: "red" }}
                          onClick={() => {
                            setIsEditTaskTitle(false);
                            setNewTitleValue("");
                          }}
                        >
                          <AppIcon icon={faTimes} />
                        </Button>
                      </InputAdornment>
                    )
                  }}
                />
              </FieldWrapper>
            )}
          </TitleWrapper>
        </TitleContainer>
        <TaskContentWrapper>
          <Typography
            style={{
              marginBottom: "1rem",
              textTransform: "uppercase",
              color: "grey"
            }}
          >
            {task.taskType.name}
          </Typography>
          <div style={{ display: "flex" }}>
            <Typography
              style={{
                marginRight: "5rem",
                marginBottom: ".275rem",
                textTransform: "uppercase"
              }}
            >
              ID
            </Typography>
            <Typography
              style={{
                marginBottom: ".275rem",
                textTransform: "uppercase"
              }}
            >
              {task.assignedUser ? "Assigned" : "Unassigned"}
            </Typography>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <AppLink to={`/tasks/${task.id}`}>
                <Button
                  variant="contained"
                  style={{ height: "2rem", marginRight: "2rem" }}
                >
                  {task.id}
                </Button>
              </AppLink>
              <IconButton
                style={{ padding: ".25rem" }}
                onClick={() => setIsAssignUser(true)}
              >
                <GlobalAvatar
                  userId={task.assignedUser ? task.assignedUser.id : null}
                  title={task.assignedUser ? task.assignedUser.fullName : null}
                  profileImageUrl={
                    task.assignedUser && task.assignedUser.profileImageUrl
                  }
                  size="x-small"
                >
                  <FontAwesomeIcon icon={faUser} />
                </GlobalAvatar>
              </IconButton>
            </div>
            <Button
              size="small"
              style={{ textTransform: "none" }}
              onClick={() => setIsEditDueDate(true)}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  marginRight: "1em",
                  color: isOverdue ? "red" : "inherit"
                }}
              >
                {isOverdue && (
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    style={{ marginRight: ".5em" }}
                  />
                )}
                {`Due: ${
                  task.dueDate
                    ? moment(task.dueDate).calendar()
                    : "No set due date"
                }`}
              </Typography>
            </Button>
          </div>
        </TaskContentWrapper>
      </Paper>
    </Fragment>
  );
};

export default withStyles(styles)(withSnackbar(TaskCard));
