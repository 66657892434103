import React, { FunctionComponent } from "react";
import moment from "moment";

/* Material UI */
import { TableRow, TableCell, Button } from "@material-ui/core";

/* FontAwesome */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faTrash, faComment } from "@fortawesome/free-solid-svg-icons";

/* GraphQL Imports */
import {
  SelectRoadmap_CustomerProducts,
  SelectRoadmapCustomerProducts,
  CustomerProjectStatusEnum,
  SelectRoadmapCustomerProjects
} from "../../../../../generated/graphql";

interface Props {
  customerProject: SelectRoadmapCustomerProjects;
  customerProduct: SelectRoadmap_CustomerProducts;
  onProductOpen: (product: SelectRoadmapCustomerProducts) => void;
  onConfirmDeleteCustomerProduct: (
    customerProduct: SelectRoadmapCustomerProducts
  ) => void;
  onProductNotesOpen: (product: SelectRoadmapCustomerProducts) => void;
}

const Product: FunctionComponent<Props> = ({
  customerProject,
  customerProduct,
  onProductOpen,
  onConfirmDeleteCustomerProduct,
  onProductNotesOpen
}) => {
  if (customerProduct.customerProductBatchId) {
    return null;
  }

  return (
    <TableRow key={customerProduct.id}>
      <TableCell style={{ width: 300 }}>
        <Button size="small" onClick={() => onProductOpen(customerProduct)}>
          <FontAwesomeIcon icon={faCog} />
        </Button>
        {customerProduct.isDeletable &&
          customerProduct.product.name !== "Strategy" && (
            <Button
              size="small"
              style={{ marginLeft: ".5em" }}
              type="secondary"
              disabled={
                customerProduct.status.id !==
                CustomerProjectStatusEnum.Scheduled
              }
              onClick={() => onConfirmDeleteCustomerProduct(customerProduct)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          )}
        <Button
          size="small"
          style={{ marginLeft: ".5em" }}
          onClick={() => onProductNotesOpen(customerProduct)}
        >
          <FontAwesomeIcon icon={faComment} style={{ marginRight: ".5em" }} />
          {customerProduct.notes ? customerProduct.notes.totalCount : 0}
        </Button>
      </TableCell>
      <TableCell>{customerProduct.id}</TableCell>
      <TableCell>
        {moment(customerProduct.startDate).format("M/D/YYYY")}
      </TableCell>
      <TableCell>
        {customerProduct.dueDate
          ? moment(customerProduct.dueDate).format("M/D/YYYY")
          : "TBD"}
      </TableCell>
      <TableCell>{customerProduct.product.name}</TableCell>
      <TableCell>
        {customerProduct.customerSku ? customerProduct.customerSku.sku : "-"}
      </TableCell>
      <TableCell>
        ${customerProduct.amount.toFixed(2)} (
        {Math.round((customerProduct.amount / customerProject.amount) * 100)}
        %)
      </TableCell>
      <TableCell>{customerProduct.workflowStatus}</TableCell>
    </TableRow>
  );
};

export default Product;
