import React, { FunctionComponent, ReactElement } from "react";

/* GraphQL & Apollo Imports */
import { SelectTaskTask } from "../../../generated/graphql";

/* Prop Interface */
import { ChildWorkspaceCommonProps } from "../task-workspace/workspace";

/* Constants */
import TASK_TYPES from "../../../constants/task-types";

/* Child Components */
import StrategyTaskContainer from "../strategy";
import TaskAssignmentContainer from "../task-assignment";
import CustomerSkuAssignmentContainer from "../customer-sku-assignment";
import GenericTaskContainer from "../generic";
import AccountManagerAssignmentContainer from "../account-manager-assignment";
import PhotoBatchReviewTaskContainer from "../photo-batch-review";
import PhotoReshootTaskContainer from "../photo-reshoot";
import PhotoEditRedoTaskContainer from "../photo-edit-redo";
import SetupWelcomeCallContainer from "../setup-welcome-call";
import WelcomeCallContainer from "../welcome-call";
import InforgraphicBatchReviewTaskContainer from "../infographic-batch-review";

/** Photo Task Instruction Components */
import RequestInventory from "../request-inventory";
import InventoryArrivalConfirmed from "../inventory-arrival-confirmed";
import PlanShoot from "../plan-shoot";
import GenerateProposal from "../generate-proposal";
import HoldSubteamMeeting from "../hold-subteam-meeting";
import AMProposalReview from "../am-proposal-review";
import UpdateProposal from "../update-proposal";
import CustomerProposalReview from "../customer-proposal-review";
import PrepStudioShoot from "../prep-studio-shoot";
import PrepLifestyleShoot from "../prep-lifestyle-shoot";
import StudioAndLifestylePhotoShoot from "../studio-and-lifestyle-photo-shoot";
import RAWGoogleDriveUpload from "../raw-google-drive-upload";
import StudioAndLifestylePhotoEditing from "../studio-and-lifestyle-photo-editing";
import SharedToOutsourcedEditing from "../share-to-outsourced-editing";
import AdditionalEditing from "../additional-editing";
import FinalEditing from "../final-editing";
import ShareWithCustomer from "../share-with-customer";
import GenericReviewTaskContainer from "../generic-review";

interface Props {
  task: SelectTaskTask;
  commonProps: ChildWorkspaceCommonProps;
}

const ChildWorkpace: FunctionComponent<Props> = ({
  task,
  commonProps
}): ReactElement<any> => {
  switch (task.taskType.id) {
    case TASK_TYPES.CUSTOM_CUSTOMER:
    case TASK_TYPES.AMAZON_ADVERTISING:
    case TASK_TYPES.BACKEND_LISTING_CREATION_AND_OPTIMIZATION:
    case TASK_TYPES.CASE_LOG_WORK:
    case TASK_TYPES.CLIENT_COMMUNICATION:
    case TASK_TYPES.CLIENT_REQUESTED_TASKS:
    case TASK_TYPES.CLIENT_RESEARCH:
    case TASK_TYPES.EXTERNAL_TOOLS:
    case TASK_TYPES.FLAT_FILE_WORK:
    case TASK_TYPES.INTERNAL_COMMUNICATION:
    case TASK_TYPES.MANUAL_UPLOADING:
    case TASK_TYPES.PROMOTIONS:
      return <StrategyTaskContainer {...commonProps} />;
    case TASK_TYPES.TASK_ASSIGNMENT:
      return <TaskAssignmentContainer />;
    case TASK_TYPES.CUSTOMER_SKU_ASSIGNMENT:
      return <CustomerSkuAssignmentContainer {...commonProps} />;
    case TASK_TYPES.KEYWORD_CREATION:
    case TASK_TYPES.LISTING_CONTENT_FULL_CREATION:
    case TASK_TYPES.LISTING_CONTENT_EXACT_CREATION:
    case TASK_TYPES.LISTING_CONTENT_RECYCLED_CREATION:
    case TASK_TYPES.LISTING_CONTENT_REVIEW:
    case TASK_TYPES.LISTING_CONTENT_REFINING:
      return <GenericTaskContainer {...commonProps} />;
    case TASK_TYPES.REQUEST_INVENTORY:
      return (
        <GenericTaskContainer
          {...commonProps}
          instructionComp={<RequestInventory />}
        />
      );
    case TASK_TYPES.INVENTORY_ARRIVAL_CONFIRMED:
      return (
        <GenericTaskContainer
          {...commonProps}
          instructionComp={<InventoryArrivalConfirmed />}
        />
      );
    case TASK_TYPES.PHOTO_PLAN_SHOOT:
      return (
        <GenericTaskContainer
          {...commonProps}
          instructionComp={<PlanShoot />}
        />
      );
    case TASK_TYPES.PHOTO_GENERATE_PROPOSAL:
      return (
        <GenericTaskContainer
          {...commonProps}
          instructionComp={<GenerateProposal />}
        />
      );
    case TASK_TYPES.HOLD_SUBTEAM_MEETING:
      return (
        <GenericTaskContainer
          {...commonProps}
          instructionComp={<HoldSubteamMeeting />}
        />
      );

    case TASK_TYPES.PHOTO_AM_PROPOSAL_REVIEW:
      return (
        <GenericReviewTaskContainer
          {...commonProps}
          instructionComp={<AMProposalReview />}
        />
      );
    case TASK_TYPES.PHOTO_UPDATE_PROPOSAL:
      return (
        <GenericTaskContainer
          {...commonProps}
          instructionComp={<UpdateProposal />}
        />
      );
    case TASK_TYPES.PHOTO_CUSTOMER_PROPOSAL_REVIEW:
      return (
        <GenericReviewTaskContainer
          {...commonProps}
          instructionComp={<CustomerProposalReview />}
        />
      );
    case TASK_TYPES.PHOTO_PREP_STUDIO_SHOOT:
      return (
        <GenericTaskContainer
          {...commonProps}
          instructionComp={<PrepStudioShoot />}
        />
      );
    case TASK_TYPES.PHOTO_PREP_LIFESTYLE_SHOOT:
      return (
        <GenericTaskContainer
          {...commonProps}
          instructionComp={<PrepLifestyleShoot />}
        />
      );
    case TASK_TYPES.PHOTO_STUDIO_SHOOT:
    case TASK_TYPES.PHOTO_LIFESTYLE_SHOOT:
      return (
        <GenericTaskContainer
          {...commonProps}
          instructionComp={<StudioAndLifestylePhotoShoot />}
        />
      );
    case TASK_TYPES.PHOTO_RAW_GOOGLE_DRIVE_UPLOAD:
      return (
        <GenericTaskContainer
          {...commonProps}
          instructionComp={<RAWGoogleDriveUpload />}
        />
      );
    case TASK_TYPES.PHOTO_STUDIO_EDITING:
    case TASK_TYPES.PHOTO_LIFESTYLE_EDITING:
      return (
        <GenericTaskContainer
          {...commonProps}
          instructionComp={<StudioAndLifestylePhotoEditing />}
        />
      );
    case TASK_TYPES.PHOTO_SHARE_TO_OUTSOURCED_EDITING:
      return (
        <GenericTaskContainer
          {...commonProps}
          instructionComp={<SharedToOutsourcedEditing />}
        />
      );
    case TASK_TYPES.PHOTO_ADDITIONAL_EDITING:
      return (
        <GenericTaskContainer
          {...commonProps}
          instructionComp={<AdditionalEditing />}
        />
      );
    case TASK_TYPES.PHOTO_FINAL_EDITING:
      return (
        <GenericTaskContainer
          {...commonProps}
          instructionComp={<FinalEditing />}
        />
      );
    case TASK_TYPES.PHOTO_SHARE_WITH_CUSTOMER:
      return (
        <GenericTaskContainer
          {...commonProps}
          instructionComp={<ShareWithCustomer />}
        />
      );
    case TASK_TYPES.ACCOUNT_MANAGER_ASSIGNMENT:
      return <AccountManagerAssignmentContainer {...commonProps} />;
    case TASK_TYPES.SETUP_WELCOME_CALL:
      return <SetupWelcomeCallContainer {...commonProps} />;
    case TASK_TYPES.WELCOME_CALL:
      return <WelcomeCallContainer {...commonProps} />;
    case TASK_TYPES.PHOTO_BATCH_REVIEW:
      return <PhotoBatchReviewTaskContainer {...commonProps} />;
    case TASK_TYPES.PHOTO_STUDIO_RESHOOT:
      return <PhotoReshootTaskContainer {...commonProps} />;
    case TASK_TYPES.PHOTO_STUDIO_REDO_EDITING:
      return <PhotoEditRedoTaskContainer {...commonProps} />;
    case TASK_TYPES.INFOGRAPHIC_SELECT_SOURCE:
    case TASK_TYPES.INFOGRAPHIC_CREATE_PROPOSAL:
    case TASK_TYPES.INFOGRAPHIC_HOLD_SUBTEAM_MEETING:
    case TASK_TYPES.INFOGRAPHIC_WAITING_ON_CONTENT_AND_PHOTOS:
    case TASK_TYPES.INFOGRAPHIC_CREATE:
    case TASK_TYPES.INFOGRAPHIC_UPLOAD_TO_GOOGLE_DRIVE:
    case TASK_TYPES.INFOGRAPHIC_SHARE_WITH_CUSTOMER:
      return <GenericTaskContainer {...commonProps} />;
    case TASK_TYPES.INFOGRAPHIC_GDM_REVIEW:
    case TASK_TYPES.INFOGRAPHIC_AM_REVIEW:
    case TASK_TYPES.INFOGRAPHIC_CUSTOMER_REVIEW:
      return <InforgraphicBatchReviewTaskContainer {...commonProps} />;
    default:
      return <span>Unknown task type</span>;
  }
};

export default ChildWorkpace;
