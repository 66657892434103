import React from "react";

import Workspace from "./workspace";
import { ChildWorkspaceCommonProps } from "../task-workspace/workspace";
import {
  SelectPhotoBatchReviewTaskWorkspaceComponent,
  CreatePhotographyReviewItemsComponent
} from "../../../generated/graphql";

interface Props extends ChildWorkspaceCommonProps {}

const PhotoBatchReviewTaskContainer = (props: Props) => {
  const {
    task: { customerProductBatch }
  } = props;
  if (!customerProductBatch) {
    throw new Error("Unable to find Customer Product Batch.");
  }
  return (
    <CreatePhotographyReviewItemsComponent>
      {createPhotographyReviewItemsMutation => (
        <SelectPhotoBatchReviewTaskWorkspaceComponent
          variables={{ customerProductBatchId: customerProductBatch.id }}
          fetchPolicy="network-only"
        >
          {queryResult => (
            <Workspace
              {...props}
              queryResult={queryResult}
              createPhotographyReviewItemsMutation={
                createPhotographyReviewItemsMutation
              }
            />
          )}
        </SelectPhotoBatchReviewTaskWorkspaceComponent>
      )}
    </CreatePhotographyReviewItemsComponent>
  );
};

export default PhotoBatchReviewTaskContainer;
