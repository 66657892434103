import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Select,
  Button,
  FormControlLabel,
  Checkbox,
  MenuItem
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

class Workspace extends Component {
  state = {
    isReadyToComplete: false
  };

  onTaskTypeChanged = async e => {
    const { task, updateTaskMutation, refetchTask } = this.props;
    try {
      await updateTaskMutation({
        variables: {
          input: {
            taskId: task.id,
            taskTypeId: e.target.value
          }
        }
      });
      refetchTask();
    } catch (err) {
      console.log(err);
    }
  };

  onCheckboxChange = e => {
    this.setState({ isReadyToComplete: e.target.checked });
  };

  render() {
    const { isReadyToComplete } = this.state;
    const { onComplete, onClose, taskTypes, task } = this.props;
    return (
      <div>
        <Select
          style={{ minWidth: 300, marginBottom: "1em" }}
          onChange={this.onTaskTypeChanged}
          value={task.taskType.id}
        >
          {taskTypes.map(taskType => (
            <MenuItem value={taskType.id}>{taskType.name}</MenuItem>
          ))}
        </Select>
        <div>
          <FormControlLabel
            label="All work for this task has been completed."
            control={(
              <Checkbox
                onChange={this.onCheckboxChange}
                style={{ marginRight: "1em" }}
              />
)}
          />
        </div>
        <div style={{ marginTop: "4em" }}>
          <Button
            variant="contained"
            color="primary"
            disabled={!isReadyToComplete}
            onClick={() => onComplete()}
            size="large"
          >
            <FontAwesomeIcon icon={faCheck} style={{ marginRight: ".5em" }} />
            Complete Task
          </Button>
          <Button onClick={onClose} size="large" style={{ marginLeft: ".5em" }}>
            <FontAwesomeIcon icon={faTimes} style={{ marginRight: ".5em" }} />
            Close
          </Button>
        </div>
      </div>
    );
  }
}

Workspace.propTypes = {
  task: PropTypes.shape({
    taskType: PropTypes.shape({
      name: PropTypes.string
    })
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  taskTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ).isRequired
};

export default Workspace;
