import gql from "graphql-tag";

export default gql`
  query selectWelcomeCall($customerId: Int!) {
    customer(customerId: $customerId) {
      id
      welcomeCall {
        id
        scheduledAt
      }
    }
  }
`;
