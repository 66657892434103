import React from "react";

import { Grid } from "@material-ui/core";
import PageLayout from "../common/page-layout";
import MyCustomersContainer from "./my-customers-container";
import MyStarredCustomersContainer from "./my-starred-customers-container";
import MyTasksContainer from "./my-tasks-container";

const Dashboard = () => (
  <PageLayout pageTitle="Dashboard">
    <Grid container spacing={16}>
      <Grid item xs={12} lg={5}>
        <MyStarredCustomersContainer />
        <MyCustomersContainer />
      </Grid>
      <Grid item xs={12} lg={7}>
        <MyTasksContainer />
      </Grid>
    </Grid>
  </PageLayout>
);

export default Dashboard;
