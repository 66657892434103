import React, { Component } from "react";

import { ApolloQueryResult } from "apollo-client";
import ProjectCard from "./project";
import {
  DeleteCustomerProductComponent,
  SelectRoadmapCustomer,
  SelectRoadmapCustomerProjects,
  SelectRoadmapQuery,
  SelectRoadmapVariables,
  DeleteCustomerProductBatchComponent
} from "../../../../generated/graphql";

interface Props {
  customer: SelectRoadmapCustomer;
  customerProject: SelectRoadmapCustomerProjects;
  refetchCustomerProjects: (
    variables?: SelectRoadmapVariables | undefined
  ) => Promise<ApolloQueryResult<SelectRoadmapQuery>>;
}

class ProjectCardContainer extends Component<Props> {
  state = {};

  render() {
    return (
      <DeleteCustomerProductBatchComponent>
        {deleteCustomerProductBatchMutation => (
          <DeleteCustomerProductComponent>
            {deleteCustomerProductMutation => (
              <ProjectCard
                {...this.props}
                deleteCustomerProductMutation={deleteCustomerProductMutation}
                deleteCustomerProductBatchMutation={
                  deleteCustomerProductBatchMutation
                }
              />
            )}
          </DeleteCustomerProductComponent>
        )}
      </DeleteCustomerProductBatchComponent>
    );
  }
}

export default ProjectCardContainer;
