import React, { FunctionComponent, useState } from "react";

/* Material UI */
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button,
  TableFooter,
  InputAdornment,
  Input,
  InputBase
} from "@material-ui/core";

/* FontAwesome */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCaretRight } from "@fortawesome/free-solid-svg-icons";

/* Common Components */
import { TableHeaderCell } from "../../../common/table-header-cell";
import Text from "../../../common/styled/text";
import Loader from "../../../common/loader";
import ErrorDisplay from "../../../common/error-display";

/* GraphQL Imports */
import {
  AddPhotographyDialogCustomerProductItemsComponent,
  SelectRoadmapCustomer
} from "../../../../generated/graphql";

/* TS Types */
import { CustomerProductItem } from "./add-photography-dialog";

interface Props {
  customer: SelectRoadmapCustomer;
  customerProductItems: CustomerProductItem[];
  onDeleteCustomerProductItem: (customerProductItemId: number) => void;
  onAmountChanged: (
    customerSkuId: number,
    productId: string,
    newAmountValue: string
  ) => void;
  onIncrementQuantity: (
    customerSkuId: number,
    productId: string,
    newQuantity: number
  ) => void;
}

const CustomerProductItemsTable: FunctionComponent<Props> = props => {
  const {
    customerProductItems,
    customer,
    onDeleteCustomerProductItem,
    onAmountChanged,
    onIncrementQuantity
  } = props;

  const [quantity, setQuantity] = useState<number>(1);

  return (
    <AddPhotographyDialogCustomerProductItemsComponent
      skip={customerProductItems.length === 0}
      variables={{
        customerId: customer.id,
        customerSkuIds: [
          ...customerProductItems.map(
            customerProductItem => customerProductItem.customerSkuId
          )
        ],
        productIds: customerProductItems.map(
          customerProductItem => customerProductItem.productId
        )
      }}
    >
      {({ data, loading, error }) => {
        if (loading) {
          return <Loader />;
        }
        if (error) {
          return <ErrorDisplay error={error} />;
        }
        if (customerProductItems.length === 0) {
          return <Text>No SKUs added yet.</Text>;
        }
        if (!data || !data.customer) {
          return null;
        }
        let total = 0;
        return (
          <Table>
            <TableHead>
              <TableRow>
                <TableHeaderCell />
                <TableHeaderCell>SKU</TableHeaderCell>
                <TableHeaderCell>Product</TableHeaderCell>
                <TableHeaderCell>Quantity</TableHeaderCell>
                <TableHeaderCell>Amount</TableHeaderCell>
                <TableHeaderCell>Total</TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.customer &&
                customerProductItems.map(customerProductItem => {
                  const customerSku =
                    data.customer &&
                    data.customer.customerSkus.items.find(
                      c => c.id === customerProductItem.customerSkuId
                    );
                  const product =
                    data.products &&
                    data.products.items &&
                    data.products.items.find(
                      p => p.id === customerProductItem.productId
                    );
                  if (!customerSku || !product) {
                    return null;
                  }
                  total +=
                    customerProductItem.amount * customerProductItem.quantity;

                  return (
                    <TableRow key={customerProductItem.id}>
                      <TableCell>
                        <Button
                          size="small"
                          onClick={() =>
                            onDeleteCustomerProductItem(customerProductItem.id)
                          }
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </TableCell>
                      <TableCell>
                        {customerSku && customerSku.parent && (
                          <span>
                            {customerSku.parent.sku}
                            <FontAwesomeIcon
                              icon={faCaretRight}
                              style={{
                                marginLeft: ".5em",
                                marginRight: ".5em"
                              }}
                            />
                          </span>
                        )}
                        {customerSku.sku}
                      </TableCell>
                      <TableCell>{product.name}</TableCell>
                      <TableCell style={{ width: "10%" }}>
                        <InputBase
                          type="number"
                          value={customerProductItem.quantity}
                          inputProps={{ min: 1 }}
                          onChange={e => {
                            if (Number(e.target.value) === 0) {
                              onIncrementQuantity(
                                customerProductItem.customerSkuId,
                                customerProductItem.productId,
                                1
                              );
                            } else {
                              onIncrementQuantity(
                                customerProductItem.customerSkuId,
                                customerProductItem.productId,
                                Number(e.target.value)
                              );
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Input
                          value={customerProductItem.amount}
                          onChange={e =>
                            onAmountChanged(
                              customerProductItem.customerSkuId,
                              customerProductItem.productId,
                              e.target.value
                            )
                          }
                          startAdornment={
                            <InputAdornment position="start">$</InputAdornment>
                          }
                        />
                      </TableCell>
                      <TableCell>
                        $
                        {(
                          customerProductItem.amount *
                          customerProductItem.quantity
                        ).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={5} />
                <TableCell>
                  <strong>${total.toFixed(2)}</strong>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        );
      }}
    </AddPhotographyDialogCustomerProductItemsComponent>
  );
};

export default CustomerProductItemsTable;
