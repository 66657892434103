import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { Mutation } from "react-apollo";
import moment from "moment";

import CREATE_CUSTOMER_PROJECT_MUTATION from "./create-customer-project-mutation";
import { transformGraphQLErrorForFormik } from "../../../utilities/form-helpers";
import NewProject from "./new-project";

class NewCustomerProject extends Component {
  state = {
    customerProjectId: null,
    cancelled: false
  };

  onFormSubmit = createCustomerProjectMutation => async (
    values,
    { setErrors, setSubmitting }
  ) => {
    const { customer } = this.props;
    setSubmitting(true);
    try {
      const result = await createCustomerProjectMutation({
        variables: {
          input: {
            name: values.name,
            amount: parseInt(values.amount, 10),
            customerId: customer.id,
            startDate: moment(values.startDate).format("YYYY-MM-DD"),
            notes: values.notes,
            includesStrategy: values.includesStrategy
          }
        }
      });
      setSubmitting(false);
      setErrors({});
      this.setState({
        customerProjectId: result.data.createCustomerProject.customerProject.id
      });
    } catch (e) {
      setErrors(transformGraphQLErrorForFormik(e));
      setSubmitting(false);
    }
  };

  onCancel = () => {
    this.setState({ cancelled: true });
  };

  render() {
    const { customer } = this.props;
    const { customerProjectId, cancelled } = this.state;

    if (cancelled) {
      return <Redirect to={`/customers/${customer.id}/projects`} />;
    }

    if (customerProjectId) {
      return (
        <Redirect
          to={`/customers/${customer.id}/projects/${customerProjectId}`}
        />
      );
    }

    return (
      <Mutation mutation={CREATE_CUSTOMER_PROJECT_MUTATION}>
        {createCustomerProjectMutation => (
          <NewProject
            customer={customer}
            onFormSubmit={this.onFormSubmit(createCustomerProjectMutation)}
            onCancel={this.onCancel}
          />
        )}
      </Mutation>
    );
  }
}

NewCustomerProject.propTypes = {
  customer: PropTypes.shape({
    id: PropTypes.number
  }).isRequired
};

export default NewCustomerProject;
