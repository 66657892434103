import React from "react";
import { AdminComponent } from "../../generated/graphql";
import Admin from "./admin";

const AdminContainer = () => (
  <AdminComponent>
    {adminMutation => <Admin adminMutation={adminMutation} />}
  </AdminComponent>
);

export default AdminContainer;
