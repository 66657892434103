import React, { Fragment, FunctionComponent } from "react";
import { Grid } from "@material-ui/core";

import RealTimeNotifier from "../../core/real-time-notifier";
import BasicInfoContainer from "./basic-info-container";
import {
  SelectCustomerWorkspaceVariables,
  SelectCustomerWorkspaceQuery,
  SelectCustomerWorkspaceCustomer
} from "../../../generated/graphql";
import { ApolloQueryResult } from "apollo-client";

interface Props {
  customer: SelectCustomerWorkspaceCustomer;
  refetch: (
    variables?: SelectCustomerWorkspaceVariables | undefined
  ) => Promise<ApolloQueryResult<SelectCustomerWorkspaceQuery>>;
}

const OverviewDisplay: FunctionComponent<Props> = ({ customer, refetch }) => {
  return (
    <Fragment>
      <RealTimeNotifier
        channelName={`customer-${customer.id}`}
        eventName="updated"
        onMessage={() => {
          refetch();
        }}
      />
      <Grid container spacing={16}>
        <Grid item lg={6} xs={12} style={{ marginBottom: "1em" }}>
          <BasicInfoContainer customer={customer} onUpdate={() => refetch()} />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default OverviewDisplay;
