import gql from "graphql-tag";

export default gql`
  mutation createCustomerContract($input: CreateCustomerContractInput!) {
    createCustomerContract(input: $input) {
      customerContract {
        id
      }
    }
  }
`;
