import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { DatePicker } from "material-ui-pickers";

import { isDateDisabled } from "../../../utilities/date-helpers";
import { FieldWrapper } from "../../common/styled/field-wrapper";
import TimePicker from "../../common/time-picker";

class Workspace extends Component {
  state = {
    selectedDate: moment()
      .startOf("hour")
      .add(1, "hour"),
    isReviewed: false
  };

  componentDidMount() {
    const { welcomeCall } = this.props;
    if (welcomeCall.scheduledAt) {
      this.setState({
        selectedDate: moment(welcomeCall.scheduledAt)
      });
    }
  }

  onCompleteTask = async () => {
    const { welcomeCall, onComplete, updateWelcomeCallMutation } = this.props;
    const { selectedDate } = this.state;
    try {
      await updateWelcomeCallMutation({
        variables: {
          input: {
            welcomeCallId: welcomeCall.id,
            scheduledAt: moment(selectedDate).toDate()
          }
        }
      });
      await onComplete();
    } catch (e) {
      console.log("Error updating the customer product: ", e);
    }
  };

  onClose = () => {
    const { onClose } = this.props;
    // TODO: save
    onClose();
  };

  onDateChange = date => {
    this.setState({ selectedDate: date, isReviewed: false });
  };

  onReview = e => {
    this.setState({ isReviewed: e.target.checked });
  };

  render() {
    const { selectedDate, isReviewed } = this.state;
    return (
      <div>
        <div>
          <FieldWrapper>
            <DatePicker
              label="Scheduled Date"
              disabledDate={isDateDisabled(true, false, true)}
              format="M/D/YYYY"
              onChange={this.onDateChange}
              value={selectedDate}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TimePicker
              label="Scheduled Time"
              value={selectedDate}
              onChange={this.onDateChange}
            />
          </FieldWrapper>
          <FieldWrapper>
            <FormControlLabel
              label={`Schedule Welcome call for ${moment(selectedDate).format(
                "M/D/YYYY"
              )} at ${moment(selectedDate).format("h:mm A")}`}
              control={
                <Checkbox checked={isReviewed} onChange={this.onReview} />
              }
            />
          </FieldWrapper>
        </div>
        <div style={{ marginTop: "4em" }}>
          <Button
            type="primary"
            disabled={!isReviewed}
            onClick={this.onCompleteTask}
            size="large"
          >
            <FontAwesomeIcon icon={faCheck} style={{ marginRight: ".5em" }} />
            Complete Task
          </Button>
          <Button
            onClick={this.onClose}
            size="large"
            style={{ marginLeft: ".5em" }}
          >
            <FontAwesomeIcon icon={faTimes} style={{ marginRight: ".5em" }} />
            Close
          </Button>
        </div>
      </div>
    );
  }
}

Workspace.propTypes = {
  task: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  welcomeCall: PropTypes.shape({
    id: PropTypes.number,
    scheduledAt: PropTypes.string
  }).isRequired
};

export default Workspace;
