import React from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button
} from "@material-ui/core";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import AppLink from "../../common/app-link";
import { TableHeaderCell } from "../../common/table-header-cell";
import { StandardCard } from "../../common/standard-card";
import { CardTitle } from "../../common/styled/card-title";
import Text from "../../common/styled/text";

const ContractsTable = ({ contracts, customer }) => (
  <StandardCard>
    <CardTitle>Contracts</CardTitle>
    <Text>
      <AppLink to={`/customers/${customer.id}/contracts/new`}>
        <Button color="primary" variant="contained">
          <FontAwesomeIcon icon={faPlus} style={{ marginRight: 8 }} />
          Create Contract
        </Button>
      </AppLink>
    </Text>
    <Table>
      <TableHead>
        <TableRow>
          <TableHeaderCell>ID</TableHeaderCell>
          <TableHeaderCell>Start Date</TableHeaderCell>
          <TableHeaderCell>Status</TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {contracts.map(contract => (
          <TableRow key={contract.id}>
            <TableCell>
              <AppLink
                to={`/customers/${customer.id}/contracts/${contract.id}`}
              >
                Contract #{contract.id}
              </AppLink>
            </TableCell>
            <TableCell>
              {moment(contract.startDate).format("M/D/YYYY")}
            </TableCell>
            <TableCell>{contract.status.name}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </StandardCard>
);

ContractsTable.propTypes = {
  contracts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customer: PropTypes.shape({}).isRequired
};

export default ContractsTable;
