import React, { Component, Fragment } from "react";
import { QueryResult, MutationFn } from "react-apollo";
import { transformGraphQLErrorForFormik } from "../../utilities/form-helpers";
import { withSnackbar, InjectedNotistackProps } from "notistack";

/* Material UI Components */
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button
} from "@material-ui/core";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

/* Generated GraphQL Components */
import {
  SelectTeamsQuery,
  SelectTeamsVariables,
  CreateTeamMutation,
  CreateTeamVariables,
  DeleteTeamMutation,
  DeleteTeamVariables
} from "../../generated/graphql";

/* Common Components */
import { TableHeaderCell } from "../common/table-header-cell";
import Loader from "../common/loader";
import ErrorDisplay from "../common/error-display";
import { AlertInfo } from "../common/styled/alert";
import { UserWithAvatar } from "../common/user-with-avatar";
import AppIcon from "../common/app-icon";
import AppLink from "../common/app-link";
import DangerButton from "../common/styled/danger-button";

import { CreateTeamDialog } from "./create-team-dialog";
import ConfirmDeleteTeamDialog from "./confirm-delete-team-dialog";

interface Props extends InjectedNotistackProps {
  queryResult: QueryResult<SelectTeamsQuery, SelectTeamsVariables>;
  createTeamMutation: MutationFn<CreateTeamMutation, CreateTeamVariables>;
  deleteTeamMutation: MutationFn<DeleteTeamMutation, DeleteTeamVariables>;
}

interface State {
  isAddingTeam: boolean;
  isDeletingTeam: boolean;
  teamId: number | null;
}

class Teams extends Component<Props, State> {
  state = {
    isAddingTeam: false,
    isDeletingTeam: false,
    teamId: null
  };

  onAddTeam = () => {
    this.setState({ isAddingTeam: true });
  };

  onAddTeamCancel = () => {
    this.setState({ isAddingTeam: false });
  };

  onTeamCreated = () => {
    this.setState({ isAddingTeam: false });
    const { queryResult } = this.props;
    queryResult.refetch();
  };

  onDeleteTeamCancel = () => {
    this.setState({ isDeletingTeam: false });
  };

  onDeleteTeam = (teamId: number) => {
    this.setState({ isDeletingTeam: true, teamId });
  };

  onConfirmDeleteTeam = async (teamId: number) => {
    const { queryResult, deleteTeamMutation, enqueueSnackbar } = this.props;
    try {
      await deleteTeamMutation({
        variables: {
          input: {
            teamId
          }
        }
      });
      queryResult.refetch();
      this.setState({ isDeletingTeam: false });
    } catch (e) {
      const errors = transformGraphQLErrorForFormik(e);
      if (errors.global) {
        enqueueSnackbar(errors.global);
      }
    }
  };

  renderTable = () => {
    const { queryResult } = this.props;
    if (queryResult.loading) {
      return <Loader />;
    }
    if (queryResult.error) {
      return <ErrorDisplay error={queryResult.error} />;
    }
    const { data } = queryResult;
    if (!data || !data.teams) {
      return null;
    }
    if (data.teams.items.length === 0) {
      return <AlertInfo message="No teams." />;
    }
    const {
      teams: { items: teams }
    } = data;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell>Name</TableHeaderCell>
            <TableHeaderCell>Department</TableHeaderCell>
            <TableHeaderCell>Lead</TableHeaderCell>
            <TableHeaderCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {teams.map(team => (
            <TableRow key={team.id}>
              <TableCell>
                <AppLink to={`/teams/${team.id}`}>{team.name}</AppLink>
              </TableCell>
              <TableCell>{team.department}</TableCell>
              <TableCell>
                <UserWithAvatar
                  userId={team.leadUser.id}
                  fullName={team.leadUser.fullName}
                  profileImageUrl={team.leadUser.profileImageUrl}
                />
              </TableCell>
              <TableCell>
                <DangerButton onClick={() => this.onDeleteTeam(team.id)}>
                  <AppIcon icon={faTrash} standardRightMargin />
                  Remove
                </DangerButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  render() {
    const { createTeamMutation } = this.props;
    const { isAddingTeam, isDeletingTeam, teamId } = this.state;
    return (
      <Fragment>
        <div style={{ marginBottom: "1rem" }}>
          <Button variant="contained" color="primary" onClick={this.onAddTeam}>
            <AppIcon icon={faPlus} standardRightMargin />
            Add Team
          </Button>
        </div>
        {isDeletingTeam && teamId && (
          <ConfirmDeleteTeamDialog
            onDeleteTeamCancel={this.onDeleteTeamCancel}
            onConfirmDeleteTeam={this.onConfirmDeleteTeam}
            teamId={teamId}
          />
        )}
        {isAddingTeam && (
          <CreateTeamDialog
            createTeamMutation={createTeamMutation}
            onClose={this.onAddTeamCancel}
            onTeamCreated={this.onTeamCreated}
          />
        )}
        {this.renderTable()}
      </Fragment>
    );
  }
}

export default withSnackbar(Teams);
