import React, { Component } from "react";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "@material-ui/core";
import { QueryResult } from "react-apollo";
import { faStream } from "@fortawesome/free-solid-svg-icons";
import {
  SelectCustomerProductBatchInfographicReviewItemsQuery,
  SelectCustomerProductBatchInfographicReviewItemsVariables
} from "../../../../generated/graphql";
import ErrorDisplay from "../../../common/error-display";
import { FeedItem } from "../../../common/feed-item";
import AppIcon from "../../../common/app-icon";

interface Props {
  queryResult: QueryResult<
    SelectCustomerProductBatchInfographicReviewItemsQuery,
    SelectCustomerProductBatchInfographicReviewItemsVariables
  >;
}

interface State {}

export class InfographicReviewItems extends Component<Props, State> {
  state = {};

  render() {
    const { queryResult } = this.props;
    if (queryResult.loading) {
      return null;
    }
    if (queryResult.error) {
      return <ErrorDisplay error={queryResult.error} />;
    }
    if (!queryResult.data || !queryResult.data.customerProductBatch) {
      return null;
    }
    const { customerProductBatch } = queryResult.data;
    return (
      <ExpansionPanel>
        <ExpansionPanelSummary>
          <span>
            <AppIcon icon={faStream} standardRightMargin />
            Review Items
          </span>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{ width: "100%" }}>
            {customerProductBatch.infographicReviewItems.map(item => (
              <FeedItem
                key={item.id}
                content={item.comments}
                date={item.createdAt}
                user={item.user}
              />
            ))}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}
