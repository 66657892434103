import React, { Component, Fragment } from "react";
import styled from "styled-components";

/* Material UI & FontAwesome */
import { faAmazon } from "@fortawesome/free-brands-svg-icons";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  TablePagination,
  IconButton
} from "@material-ui/core";

/* Graphql & Apollo Imports */
import { QueryResult } from "react-apollo";
import {
  SelectCustomerSkusQuery,
  SelectCustomerSkusVariables,
  SelectCustomerSkusItems,
  SelectCustomerSkusChildren,
  GetCustomerCustomer
} from "../../../generated/graphql";

/* Common Components */
import { TableHeaderCell } from "../../common/table-header-cell";
import Loader from "../../common/loader";
import ErrorDisplay from "../../common/error-display";
import ConstrainedText from "../../common/constrained-text";
import AppIcon from "../../common/app-icon";
import AppLink from "../../common/app-link";

/* Imported Interface */
import { SkuFilters } from "./skus";

/* Child Components */
import SyncStatus from "./sync-status";

/* Styled Components */
const StyledTableCell = styled(TableCell)`
  padding: 0.5rem 1rem !important;
`;

const StyledTableHeaderCell = styled(TableHeaderCell)`
  padding: 0.5rem 1rem !important;
` as typeof TableHeaderCell;

interface Props {
  customer: GetCustomerCustomer;
  onAddVariation: (customerSku: SelectCustomerSkusItems) => any;
  customerSkusQueryResult: QueryResult<
    SelectCustomerSkusQuery,
    SelectCustomerSkusVariables
  >;
  onEditSku: (
    customerSku: SelectCustomerSkusItems | SelectCustomerSkusChildren,
    parentCustomerSku?: SelectCustomerSkusItems
  ) => any;
  onNextPage: () => any;
  onPreviousPage: () => any;
  count: number;
  limit: number;
  offset: number;
  skuFilters: SkuFilters;
}

interface State {
  selectedTopLevelSkuId: number | null;
}

class SkusTable extends Component<Props> {
  state: State = {
    selectedTopLevelSkuId: null
  };

  renderRow = (
    customerSku: SelectCustomerSkusItems | SelectCustomerSkusChildren,
    isChild?: boolean
  ) => {
    const { onEditSku, customer } = this.props;
    return (
      <TableRow
        key={`${customerSku.id}${isChild ? "-child" : ""}`}
        style={{ backgroundColor: isChild ? "#fafafa" : "auto" }}
      >
        <StyledTableCell>
          <AppLink to={`/customers/${customer.id}/skus/${customerSku.id}`}>
            {customerSku.sku}
          </AppLink>
        </StyledTableCell>
        <StyledTableCell>
          <ConstrainedText value={customerSku.title} maxLength={50} />
        </StyledTableCell>
        <StyledTableCell
          style={{ textAlign: !customerSku.asin ? "center" : "inherit" }}
        >
          {customerSku.asin && (
            <span style={{ whiteSpace: "nowrap" }}>
              <IconButton
                style={{ paddingLeft: ".1rem" }}
                href={`https://www.amazon.com/gp/product/${customerSku.asin}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AppIcon icon={faAmazon} />
              </IconButton>
              {customerSku.asin}{" "}
            </span>
          )}
          {!customerSku.asin && "-"}
        </StyledTableCell>
        <StyledTableCell>{customerSku.upc || "-"}</StyledTableCell>
        <StyledTableCell>{customerSku.skuType || "-"}</StyledTableCell>
        <StyledTableCell>
          <SyncStatus
            status={customerSku.syncStatus}
            errorMessage={customerSku.syncErrorMessage}
          />
        </StyledTableCell>
        <StyledTableCell>
          <Button onClick={onEditSku(customerSku)} color="primary" size="small">
            Edit
          </Button>
        </StyledTableCell>
      </TableRow>
    );
  };

  render() {
    const {
      customerSkusQueryResult,
      onNextPage,
      onPreviousPage,
      count,
      limit,
      offset
    } = this.props;
    const { selectedTopLevelSkuId } = this.state;
    if (customerSkusQueryResult.loading) {
      return <Loader />;
    }
    if (customerSkusQueryResult.error) {
      return <ErrorDisplay error={customerSkusQueryResult.error} />;
    }
    const { data } = customerSkusQueryResult;
    if (
      !data ||
      !data.customer ||
      !data.customer.customerSkus ||
      !data.customer.customerSkus.items
    ) {
      return null;
    }

    /* Filter skus if filters selected */
    const { items } = data.customer.customerSkus;

    return (
      <Fragment>
        {count > limit && (
          <TablePagination
            component="div"
            count={count}
            rowsPerPage={limit}
            page={offset / limit}
            rowsPerPageOptions={[limit]}
            onChangePage={(e, page) => {
              const currentPage = offset / limit;
              if (page > currentPage) {
                onNextPage();
              } else {
                onPreviousPage();
              }
            }}
          />
        )}
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableHeaderCell>SKU</StyledTableHeaderCell>
              <StyledTableHeaderCell>Title</StyledTableHeaderCell>
              <StyledTableHeaderCell>ASIN</StyledTableHeaderCell>
              <StyledTableHeaderCell>UPC</StyledTableHeaderCell>
              <StyledTableHeaderCell>Type</StyledTableHeaderCell>
              <StyledTableHeaderCell>Sync Status</StyledTableHeaderCell>
              <TableHeaderCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(customerSku => (
              <Fragment key={customerSku.id}>
                {this.renderRow(customerSku)}
                {customerSku.children &&
                  selectedTopLevelSkuId === customerSku.id &&
                  customerSku.children.map(childSku =>
                    this.renderRow(childSku, true)
                  )}
              </Fragment>
            ))}
          </TableBody>
        </Table>
        {count > limit && (
          <TablePagination
            component="div"
            count={count}
            rowsPerPage={limit}
            page={offset / limit}
            rowsPerPageOptions={[limit]}
            onChangePage={(e, page) => {
              const currentPage = offset / limit;
              if (page > currentPage) {
                onNextPage();
              } else {
                onPreviousPage();
              }
            }}
          />
        )}
      </Fragment>
    );
  }
}

export default SkusTable;
