import React, { FunctionComponent } from "react";
import moment from "moment";

/* Material UI */
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";

/* GraphQL Imports */
import { SelectCustomersEndingIn30DaysComponent } from "../../../generated/graphql";

/* Common Components */
import AppLink from "../../common/app-link";
import { StandardCard } from "../../common/standard-card";
import { TableHeaderCell } from "../../common/table-header-cell";
import Loader from "../../common/loader";
import ErrorDisplay from "../../common/error-display";

const CustomersEndingIn30DaysReportCard: FunctionComponent = () => (
  <SelectCustomersEndingIn30DaysComponent fetchPolicy="network-only">
    {({ data, loading, error, refetch }) => {
      if (loading) {
        return <Loader />;
      }
      if (error) {
        return <ErrorDisplay error={error} refetch={refetch} />;
      }
      if (!data || !data.reports || !data.reports.customersEndingIn30Days) {
        return null;
      }

      const { customersEndingIn30Days } = data.reports;

      return (
        <StandardCard
          title={`Customers Ending in 30 Days (${
            customersEndingIn30Days.length
          })`}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableHeaderCell>Customer</TableHeaderCell>
                <TableHeaderCell>Actual End Date</TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customersEndingIn30Days &&
                customersEndingIn30Days.map(customer => (
                  <TableRow key={customer.id}>
                    <TableCell>
                      <AppLink to={`/customers/${customer.id}`}>
                        {customer.name}
                      </AppLink>
                    </TableCell>
                    <TableCell>
                      {moment(customer.startDate)
                        .add(1, "month")
                        .format("MM/DD/YYYY")}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </StandardCard>
      );
    }}
  </SelectCustomersEndingIn30DaysComponent>
);

export default CustomersEndingIn30DaysReportCard;
