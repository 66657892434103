import gql from "graphql-tag";

export default gql`
  mutation createCustomerProduct($input: CreateCustomerProductInput!) {
    createCustomerProduct(input: $input) {
      customerProduct {
        id
      }
    }
  }
`;
