import React, { Component, Fragment } from "react";
import { Formik, FormikValues, FormikActions, Form } from "formik";

import { Prompt } from "react-router";
import { Button } from "@material-ui/core";
import { StandardCard } from "../standard-card";
import { transformGraphQLErrorForFormik } from "../../../utilities/form-helpers";
import { FieldWrapper } from "../styled/field-wrapper";
import MarkdownEditor from "../markdown-editor";
import NotePreview from "./note-preview";

interface Props {
  onRevisionCreate: (content: string, originalNoteId: number | null) => void;
}

interface State {}

interface FormValues {
  global?: null;
  content: string;
}

class NewNote extends Component<Props, State> {
  state = {};

  onSubmit = async (
    values: FormikValues,
    formikBag: FormikActions<FormValues>
  ) => {
    const { onRevisionCreate } = this.props;
    try {
      await onRevisionCreate(values.content, null);
      formikBag.setSubmitting(false);
      formikBag.resetForm();
    } catch (e) {
      console.log("got an error", { e });
      formikBag.setErrors(transformGraphQLErrorForFormik(e));
      formikBag.setSubmitting(false);
    }
  };

  render() {
    return (
      <Formik
        initialValues={{ content: "" }}
        onSubmit={this.onSubmit}
        render={({ values, handleChange, handleSubmit, dirty, resetForm }) => (
          <Form>
            <FieldWrapper>
              <MarkdownEditor
                id="content"
                name="content"
                value={values.content}
                onChange={handleChange}
                preview={<NotePreview value={values.content} />}
              />
            </FieldWrapper>
            <Prompt
              when={dirty}
              message="Do you want to discard your unsaved note?"
            />
            {dirty && (
              <Fragment>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit()}
                >
                  Create Note
                </Button>
                <Button
                  onClick={() => resetForm()}
                  style={{ marginLeft: ".5em" }}
                >
                  Cancel
                </Button>
              </Fragment>
            )}
          </Form>
        )}
      />
    );
  }
}

export default NewNote;
