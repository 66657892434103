import React from "react";
import { Query } from "react-apollo";

import MyTasksCard from "./my-tasks-card";
import Loader from "../common/loader";
import ErrorDisplay from "../common/error-display";
import { SelectMyTasksComponent } from "../../generated/graphql";

const MyTasksContainer = () => (
  <SelectMyTasksComponent fetchPolicy="network-only" pollInterval={5000}>
    {({ data, loading, error, refetch }) => {
      if (loading) {
        return <Loader />;
      }
      if (error) {
        return <ErrorDisplay error={error} refetch={refetch} />;
      }
      if (!data || !data.viewer || !data.viewer.tasks) {
        return null;
      }
      return (
        <MyTasksCard
          taskCount={data.viewer.tasks.totalCount}
          tasks={data.viewer.tasks.items}
        />
      );
    }}
  </SelectMyTasksComponent>
);

export default MyTasksContainer;
