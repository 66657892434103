import gql from "graphql-tag";

export default gql`
  query selectCustomerProjectsProducts($customerId: Int!) {
    customer(customerId: $customerId) {
      id
      customerProjects {
        id
        name
        amount
        startDate
        status {
          id
          name
        }
        customerContractId
      }
      customerProducts {
        id
        productId
      }
    }
  }
`;
