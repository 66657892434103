import React, { FunctionComponent, Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import { ApolloQueryResult } from "apollo-client";

/* GraphQL & Apollo Imports */
import {
  GetCustomerCustomer,
  GetCustomerVariables,
  GetCustomerQuery,
  Maybe,
  DeleteCustomerStarComponent,
  CreateCustomerStarComponent
} from "../../../generated/graphql";

/* Common Components */
import Loader from "../../common/loader";
import PageLayout from "../../common/page-layout";
import StarButton from "../../common/star-button";
import SubnavMenu1 from "../subnav-menu-1";

/* Child Components */
import Workspace from "../workspace";
import Overview from "../overview";
import Contracts from "../contracts";
import CustomerUsersContainer from "../users";
import Contract from "../contract/contract";
import RoadmapContainer from "../roadmap";
import Projects from "../projects";
import Project from "../project";
import NewCustomerProject from "../new-project";
import Skus from "../skus";
import Tasks from "../tasks";
import Activity from "../activity";
import AmazonAccounts from "../amazon-accounts";
import FilesContainer from "../files";
import SkuDetailsContainer from "../skus/sku-details";

interface Props {
  customer: Maybe<GetCustomerCustomer>;
  isLoading: boolean;
  activeSection: string;
  refetch: (
    variables?: GetCustomerVariables | undefined
  ) => Promise<ApolloQueryResult<GetCustomerQuery>>;
}

const Customer: FunctionComponent<Props> = ({
  customer,
  isLoading,
  activeSection,
  refetch
}) => {
  if (isLoading) {
    return (
      <PageLayout pageTitle="Customer">
        <Loader large message="Loading customer..." />
      </PageLayout>
    );
  }

  if (!customer) {
    return null;
  }

  const refetchData = () => refetch();

  return (
    <PageLayout
      pageTitle={customer.name}
      appBarContent={(
        <DeleteCustomerStarComponent>
          {deleteCustomerStarMutation => (
            <CreateCustomerStarComponent>
              {createCustomerStarMutation => (
                <Fragment>
                  <StarButton
                    isStarred={customer.isStarred}
                    customerId={customer.id}
                    refetchData={refetchData}
                    createCustomerStarMutation={createCustomerStarMutation}
                    deleteCustomerStarMutation={deleteCustomerStarMutation}
                  />
                  <span>{customer.name}</span>
                </Fragment>
              )}
            </CreateCustomerStarComponent>
          )}
        </DeleteCustomerStarComponent>
)}
    >
      <SubnavMenu1 customer={customer} activeSection={activeSection} />

      <Switch>
        <Route
          path="/customers/:customerId/workspace"
          exact
          render={() => <Workspace customer={customer} />}
        />
        <Route
          path="/customers/:customerId/overview"
          exact
          render={() => <Overview customer={customer} />}
        />
        <Route
          path="/customers/:customerId/contracts"
          exact
          render={() => <Contracts customer={customer} />}
        />
        <Route
          path="/customers/:customerId/users"
          exact
          render={() => <CustomerUsersContainer customer={customer} />}
        />
        <Route
          path="/customers/:customerId/contracts/:contractId"
          exact
          render={({
            match: {
              params: { contractId }
            }
          }) => (
            <Contract
              customer={customer}
              customerContractId={parseInt(contractId, 10)}
            />
          )}
        />
        <Route
          path="/customers/:customerId/roadmap"
          exact
          render={() => <RoadmapContainer customer={customer} />}
        />
        <Route
          path="/customers/:customerId/projects"
          exact
          render={() => <Projects customer={customer} />}
        />
        <Route
          path="/customers/:customerId/projects/new"
          exact
          render={() => <NewCustomerProject customer={customer} />}
        />
        <Route
          path="/customers/:customerId/projects/:projectId"
          exact
          render={({
            match: {
              params: { projectId }
            }
          }) => (
            <Project
              customer={customer}
              customerProjectId={parseInt(projectId, 10)}
            />
          )}
        />
        <Route
          path="/customers/:customerId/skus"
          exact
          render={() => <Skus customer={customer} />}
        />
        <Route
          path="/customers/:customerId/skus/:skuId"
          exact
          render={({
            match: {
              params: { skuId }
            }
          }) => (
            <SkuDetailsContainer
              customer={customer}
              skuId={parseInt(skuId, 10)}
            />
          )}
        />
        <Route
          path="/customers/:customerId/tasks"
          exact
          render={() => <Tasks customer={customer} />}
        />
        <Route
          path="/customers/:customerId/activity"
          exact
          render={() => <Activity />}
        />
        <Route
          path="/customers/:customerId/amazon-accounts"
          exact
          render={() => <AmazonAccounts customerId={customer.id} />}
        />
        <Route
          path="/customers/:customerId/files"
          exact
          render={() => <FilesContainer customerId={customer.id} />}
        />
      </Switch>
    </PageLayout>
  );
};

export default Customer;
