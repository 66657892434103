import React from "react";
import Text from "../../common/styled/text";
import InstructionsContainer from "../../common/styled/instructions-container";

const BatchReview = () => (
  <InstructionsContainer>
    <Text>
      The photo manager/team lead are responsible for reviewing the batch before
      they’re shared with the Account Manager and client. If any additional
      edits are needed, the photo manager or team lead should communicate the
      necessary changes to the photographer. Once the edits are made, the photo
      manager or lead will review them again. The manager and lead are
      responsible for making sure we deliver quality work and can stand behind
      the photos we deliver our clients.
    </Text>
  </InstructionsContainer>
);

export default BatchReview;
