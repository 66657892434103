/* getObjectDifference is a utility for comparing two objects and returns a single object with the different values. */

export const getObjectDifference = (newObj: any, currObj: any) => {
  let diffObj: any = {};
  if (Array.isArray(newObj)) {
    for (let i = 0; i <= newObj.length - 1; i += 1) {
      if (!Array.isArray(diffObj)) {
        diffObj = [];
      }
      diffObj[i] = getObjectDifference(newObj[i], (currObj || [])[i]);
    }
  } else if (newObj != null && typeof newObj == "object") {
    const objectKeys = Object.keys(newObj);
    for (let j = 0; j <= objectKeys.length - 1; j += 1) {
      if (Array.isArray(newObj[objectKeys[j]])) {
        let arr = getObjectDifference(
          newObj[objectKeys[j]],
          currObj[objectKeys[j]]
        );
        if (!Array.isArray(arr)) {
          arr = [];
        }
        for (let k = 0; k <= arr.length - 1; k += 1) {
          if (!Array.isArray(diffObj[objectKeys[j]])) {
            diffObj[objectKeys[j]] = [];
          }
          diffObj[objectKeys[j]][k] = arr[k];
        }
      } else if (typeof newObj[objectKeys[j]] === "object") {
        diffObj[objectKeys[j]] = getObjectDifference(
          newObj[objectKeys[j]],
          currObj[objectKeys[j]]
        );
      } else if (newObj[objectKeys[j]] !== (currObj || {})[objectKeys[j]]) {
        diffObj[objectKeys[j]] = newObj[objectKeys[j]];
      } else if (newObj[objectKeys[j]] === (currObj || {})[objectKeys[j]]) {
        delete newObj[objectKeys[j]];
      }
    }
  }
  const objectKeys = Object.keys(diffObj);

  for (let i = 0; i <= objectKeys.length - 1; i += 1) {
    if (
      typeof diffObj[objectKeys[i]] === "object" &&
      JSON.stringify(diffObj[objectKeys[i]]) === "{}"
    ) {
      delete diffObj[objectKeys[i]];
    }
  }
  return diffObj;
};
