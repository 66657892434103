import React from "react";
// import PropTypes from "prop-types";
import { Mutation, Query } from "react-apollo";

import WELCOME_CALL_QUERY from "./welcome-call-query";
import UPDATE_WELCOME_CALL_MUTATION from "./update-welcome-call-mutation";
import Workspace from "./workspace";
import Loader from "../../common/loader";
import ErrorDisplay from "../../common/error-display";

const CustomerSkuAssignmentContainer = ({ task, ...rest }) => (
  <Query
    query={WELCOME_CALL_QUERY}
    variables={{ customerId: task.customer.id }}
    fetchPolicy="network-only"
  >
    {({ data, loading, error }) => {
      if (loading) {
        return <Loader />;
      }
      if (error) {
        return <ErrorDisplay error={error} />;
      }
      return (
        <Mutation mutation={UPDATE_WELCOME_CALL_MUTATION}>
          {updateWelcomeCallMutation => (
            <Workspace
              task={task}
              {...rest}
              welcomeCall={
                data && data.customer ? data.customer.welcomeCall : null
              }
              updateWelcomeCallMutation={updateWelcomeCallMutation}
            />
          )}
        </Mutation>
      );
    }}
  </Query>
);

CustomerSkuAssignmentContainer.propTypes = {};

export default CustomerSkuAssignmentContainer;
