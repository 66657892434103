import React, { Component } from "react";
import { Formik, FormikActions, Form, FormikProps } from "formik";
import * as Yup from "yup";
import { withSnackbar, InjectedNotistackProps } from "notistack";
import styled from "styled-components";

/* Masked Input Component for masking Phone Number input */
import MaskedInput from "react-text-mask";

/* Material UI */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button
} from "@material-ui/core";

/* GraphQL & Apollo Imports */
import { MutationFn } from "react-apollo";
import { FetchResult } from "apollo-link";
import {
  CreateCustomerUserMutation,
  CreateCustomerUserVariables,
  CreateUserPhoneNumberMutation,
  CreateUserPhoneNumberVariables
} from "../../../generated/graphql";

/* Common Components */
import { AlertError } from "../../common/styled/alert";
import { FieldWrapper } from "../../common/styled/field-wrapper";
import Loader from "../../common/loader";

/* Utilities */
import { transformGraphQLErrorForFormik } from "../../../utilities/form-helpers";

/* Styled Components */
const StyledFieldWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const PhoneNumberMaskedInput = (props: any) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]}
      placeholderChar={"\u2000"}
    />
  );
};

interface Props extends InjectedNotistackProps {
  customerId: number;
  onClose: () => any;
  createCustomerUserMutation: MutationFn<
    CreateCustomerUserMutation,
    CreateCustomerUserVariables
  >;
  createUserPhoneNumberMutation: MutationFn<
    CreateUserPhoneNumberMutation,
    CreateUserPhoneNumberVariables
  >;
  onCustomerUserAdded: () => any;
}

interface State {}

interface FormValues {
  global?: string | null;
  firstName: string;
  lastName: string;
  email: string;
  title: string;
  notes: string;
  phoneNumber: string;
  label: string;
}
class UserDialog extends Component<Props, State> {
  state = {};

  onSubmit = async (values: FormValues, actions: FormikActions<FormValues>) => {
    const {
      createCustomerUserMutation,
      customerId,
      onCustomerUserAdded,
      createUserPhoneNumberMutation,
      enqueueSnackbar
    } = this.props;

    try {
      const createdCustomerUser = await createCustomerUserMutation({
        variables: {
          input: {
            customerId,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            title: values.title,
            notes: values.notes
          }
        }
      });
      if (values.phoneNumber !== "" || values.label !== "") {
        await createUserPhoneNumberMutation({
          variables: {
            input: {
              userId: (createdCustomerUser as FetchResult<
                CreateCustomerUserMutation,
                Record<string, any>
              >).data.createCustomerUser.customerUser.userId,
              phoneNumber: values.phoneNumber.replace(/[^0-9]/gi, ""),
              label: values.label,
              isPrimary: true
            }
          }
        });
      }
      actions.setSubmitting(false);
      onCustomerUserAdded();
      enqueueSnackbar("The Customer User was created successfully.", {
        variant: "success"
      });
    } catch (e) {
      const error = actions.setErrors(transformGraphQLErrorForFormik(e));
      actions.setSubmitting(false);
      if ((error as any) && (error as any).global) {
        enqueueSnackbar((error as any).global, { variant: "error" });
      } else {
        enqueueSnackbar("There was an issue creating the Customer User.", {
          variant: "error"
        });
      }
    }
  };

  render() {
    const { onClose } = this.props;
    return (
      <Formik
        onSubmit={this.onSubmit}
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          title: "",
          notes: "",
          phoneNumber: "",
          label: ""
        }}
        validationSchema={Yup.lazy(values =>
          Yup.object().shape({
            firstName: Yup.string()
              .max(255, "Maximum number of characters exceeded.")
              .required("First name is rquired."),
            lastName: Yup.string()
              .max(255, "Maximum number of characters exceeded.")
              .required("Last name is required."),
            email: Yup.string()
              .email("You must provide a valid email.")
              .required("Email is required."),
            label: (values as FormValues).phoneNumber
              ? Yup.string()
                  .max(255, "Maximum number of characters exceeded.")
                  .required("You must provide a label.")
              : Yup.string().nullable(),
            phoneNumber: (values as FormValues).label
              ? Yup.string()
                  .matches(
                    /^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/,
                    "You must provide a valid phone number."
                  )
                  .required("You must provide a phone number.")
              : Yup.string().nullable(),
            title: Yup.string()
              .max(255, "Maximum number of characters exceeded.")
              .nullable()
          })
        )}
        render={({
          handleSubmit,
          handleChange,
          values,
          errors,
          touched,
          isSubmitting
        }) => (
          <Dialog
            open
            onClose={onClose}
            fullWidth
            disableBackdropClick
            scroll="body"
          >
            <DialogTitle>Add User</DialogTitle>
            <DialogContent>
              <Form>
                {errors.global && <AlertError message={errors.global} />}
                <FieldWrapper>
                  <TextField
                    label="First Name"
                    id="firstName"
                    name="firstName"
                    onChange={handleChange}
                    value={values.firstName}
                    placeholder="First Name"
                    fullWidth
                    helperText={
                      errors.firstName && touched.firstName
                        ? errors.firstName
                        : null
                    }
                    error={!!errors.firstName && !!touched.firstName}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    label="Last Name"
                    id="lastName"
                    name="lastName"
                    onChange={handleChange}
                    value={values.lastName}
                    placeholder="Last Name"
                    fullWidth
                    helperText={
                      errors.lastName && touched.lastName
                        ? errors.lastName
                        : null
                    }
                    error={!!errors.lastName && !!touched.lastName}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    label="Primary Email"
                    id="email"
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                    placeholder="Email"
                    fullWidth
                    helperText={
                      errors.email && touched.email ? errors.email : null
                    }
                    error={!!errors.email && !!touched.email}
                  />
                </FieldWrapper>
                <StyledFieldWrapper>
                  <TextField
                    style={{ marginRight: "1rem" }}
                    label="Label"
                    id="label"
                    name="label"
                    onChange={handleChange}
                    value={values.label}
                    fullWidth
                    helperText={
                      errors.label && touched.label ? errors.label : "optional"
                    }
                    error={!!errors.label && !!touched.label}
                  />
                  <TextField
                    label="Phone Number"
                    id="phoneNumber"
                    name="phoneNumber"
                    onChange={handleChange}
                    value={values.phoneNumber}
                    placeholder=""
                    fullWidth
                    helperText={
                      errors.phoneNumber && touched.phoneNumber
                        ? errors.phoneNumber
                        : "optional"
                    }
                    error={!!errors.phoneNumber && !!touched.phoneNumber}
                    InputProps={{
                      inputComponent: PhoneNumberMaskedInput
                    }}
                  />
                </StyledFieldWrapper>
                <FieldWrapper>
                  <TextField
                    label="Title"
                    id="title"
                    name="title"
                    onChange={handleChange}
                    value={values.title}
                    placeholder="Title"
                    fullWidth
                    helperText={
                      errors.title && touched.title ? errors.title : "optional"
                    }
                    error={!!errors.title && !!touched.title}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    label="Notes"
                    id="notes"
                    name="notes"
                    onChange={handleChange}
                    value={values.notes}
                    placeholder="Notes"
                    fullWidth
                    helperText="optional"
                  />
                </FieldWrapper>
              </Form>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} disabled={isSubmitting}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
              >
                {isSubmitting && <Loader />}
                Add User
              </Button>
            </DialogActions>
          </Dialog>
        )}
      />
    );
  }
}

export default withSnackbar(UserDialog);
