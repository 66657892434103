import React from "react";
import PropTypes from "prop-types";

export const FieldWrapper = ({ children }) => (
  <div style={{ marginBottom: "1em" }}>{children}</div>
);

FieldWrapper.propTypes = {
  children: PropTypes.node.isRequired
};
