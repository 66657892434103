import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faInfoCircle,
  faUser,
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import GlobalAvatar from "../../global-avatar";
import AppLink from "../../common/app-link";
import { TableHeaderCell } from "../../common/table-header-cell";
import { TaskStatusEnum } from "../../../generated/graphql";

class TasksTable extends Component {
  state = {};

  renderDueColumn = task => {
    if (task.statusId === TaskStatusEnum.Completed) {
      return (
        <span>
          <FontAwesomeIcon
            icon={faCheckCircle}
            style={{ color: "green", marginRight: ".5em" }}
          />
          Completed
        </span>
      );
    }
    if (
      moment(task.dueDate) < moment() &&
      (task.statusId === TaskStatusEnum.Available ||
        task.statusId === TaskStatusEnum.Assigned)
    ) {
      return (
        <span style={{ color: "red" }}>
          <Tooltip title={moment(task.dueDate).format("LLL")}>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              style={{ marginRight: ".5em" }}
            />
          </Tooltip>
          {moment(task.dueDate).fromNow()}
        </span>
      );
    }
    return (
      <span>
        <Tooltip title={moment(task.dueDate).format("LLL")}>
          <FontAwesomeIcon
            icon={faInfoCircle}
            style={{ marginRight: ".5em" }}
          />
        </Tooltip>
        {moment(task.dueDate).fromNow()}
      </span>
    );
  };

  render() {
    const { tasks } = this.props;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell>Task</TableHeaderCell>
            <TableHeaderCell>Assigned User</TableHeaderCell>
            <TableHeaderCell>Due</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tasks &&
            tasks.map(task => (
              <TableRow key={task.id}>
                <TableCell>
                  {task.title && task.title.length > 0 && (
                    <AppLink to={`/tasks/${task.id}`}>
                      {task.title} ({task.taskType.name})
                    </AppLink>
                  )}
                  {!task.title && (
                    <AppLink to={`/tasks/${task.id}`}>
                      {task.taskType.name}
                    </AppLink>
                  )}
                </TableCell>
                <TableCell>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <GlobalAvatar
                      userId={task.assignedUser ? task.assignedUser.id : null}
                      size="small"
                      style={{ marginRight: ".5em" }}
                    >
                      <FontAwesomeIcon icon={faUser} />
                    </GlobalAvatar>
                    <span>
                      {task.assignedUser
                        ? task.assignedUser.fullName
                        : "Unassigned"}
                    </span>
                  </div>
                </TableCell>
                <TableCell>{this.renderDueColumn(task)}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    );
  }
}

TasksTable.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default TasksTable;
