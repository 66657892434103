import gql from "graphql-tag";

export default gql`
  query strategyTask {
    taskTypes(isCustomStrategy: true) {
      id
      name
      description
      isCustomStrategy
    }
  }
`;
