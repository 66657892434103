import React, { FunctionComponent } from "react";

/* Material UI */
import { Table, TableHead, TableRow } from "@material-ui/core";

/* Common Components */
import { TableHeaderCell } from "../../common/table-header-cell";
import Loader from "../../common/loader";
import ErrorDisplay from "../../common/error-display";

/* GraphQL Imports */
import { SelectTeamUsersComponent } from "../../../generated/graphql";

/* Child Components */
import AccountManagementOverviewTableBody from "./acount-management-overview-table-body";

interface Props {
  teamId: number;
}

const TeamOverviewReportCardTable: FunctionComponent<Props> = ({ teamId }) => (
  <SelectTeamUsersComponent variables={{ teamId }} fetchPolicy="network-only">
    {({ data, loading, error, refetch }) => {
      if (loading) {
        return <Loader />;
      }

      if (error) {
        return <ErrorDisplay error={error} refetch={refetch} />;
      }

      if (!data || !data.teamUsersInformation) {
        return null;
      }

      const { teamUsersInformation } = data;
      const { leadUser } = teamUsersInformation;
      const { teamUsers } = teamUsersInformation;

      return (
        <Table>
          <TableHead>
            <TableRow>
              <TableHeaderCell>Account Manager</TableHeaderCell>
              <TableHeaderCell>Customers Managed</TableHeaderCell>
              <TableHeaderCell>Amount Managed</TableHeaderCell>
            </TableRow>
          </TableHead>
          <AccountManagementOverviewTableBody
            leadUser={leadUser}
            accountManagers={teamUsers}
          />
        </Table>
      );
    }}
  </SelectTeamUsersComponent>
);

export default TeamOverviewReportCardTable;
