import React, { FunctionComponent, useState, Fragment } from "react";
import styled from "styled-components";

/* GraphQL Imports */
import { SelectTaskTask } from "../../../generated/graphql";

/* Material UI */
import {
  Paper,
  MenuList,
  Typography,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
  ListItem
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

/* Common Components */
import AppIcon from "../../common/app-icon";
import AppLink from "../../common/app-link";
import GlobalAvatar from "../../global-avatar";

/* Font Awesome */
import {
  faBarcode,
  faCheckCircle,
  faTasks,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";

/* Styled Components */
const StyledListItemText = styled(ListItemText)`
  > span {
    font-size: 0.8rem;
  }

  > p {
    font-size: 0.8rem;
  }
`;

const StyledListItem = styled(ListItem)`
  display: flex !important;
  justify-content: space-between !important;
`;

interface Props {
  task: SelectTaskTask;
}

const BatchedProductsMenu: FunctionComponent<Props> = ({ task }) => {
  const currentTask = task;

  const [openProduct, setOpenProduct] = useState<number[]>([]);

  const onCollapseProduct = (id: number | null) => {
    if (!id) {
      return null;
    }
    if (openProduct.includes(id)) {
      setOpenProduct(openProduct.filter(currSku => currSku !== id));
    } else {
      setOpenProduct([id, ...openProduct]);
    }
  };

  if (!currentTask.customerProductBatch) {
    return null;
  }

  return (
    <Paper style={{ padding: "1rem 1.5rem" }}>
      <MenuList
        subheader={
          <Typography variant="h6" component="div">
            Batched Products
          </Typography>
        }
      >
        {currentTask.customerProductBatch.customerProducts.map(cp => {
          if (!cp.customerSku) {
            return null;
          }

          const isProductOpen = openProduct.includes(cp.id);
          const isNoTasks = cp.tasks.length === 0;

          // Check to see which product is open and task selected
          let isActiveTask;
          if (cp.tasks.length === 0) {
            isActiveTask = cp.id === currentTask.id && !isNoTasks;
          } else {
            isActiveTask =
              cp.tasks.some(task => task.id === currentTask.id) && !isNoTasks;
          }

          return (
            <Fragment>
              <ListItem
                selected={isNoTasks && isProductOpen}
                style={{ padding: ".5rem 0" }}
              >
                <ListItemIcon
                  style={{ marginRight: "1rem", fontSize: ".7rem" }}
                >
                  <AppIcon icon={faBarcode} />
                </ListItemIcon>
                <StyledListItemText
                  style={{
                    padding: ".2rem .2rem",
                    whiteSpace: "normal"
                  }}
                  primary={cp.product.name}
                  secondary={cp.customerSku.sku}
                  onClick={() => onCollapseProduct(cp ? cp.id : null)}
                />
                {isActiveTask || isProductOpen ? (
                  <ExpandLess style={{ fontSize: ".9rem" }} />
                ) : (
                  <ExpandMore style={{ fontSize: ".9rem" }} />
                )}
              </ListItem>
              <Collapse in={isActiveTask || isProductOpen} unmountOnExit>
                {isNoTasks && (
                  <List disablePadding>
                    <ListItem disabled={true}>
                      <ListItemIcon style={{ fontSize: ".7rem" }}>
                        <AppIcon icon={faInfoCircle} />
                      </ListItemIcon>
                      <StyledListItemText
                        primary="There are no tasks."
                        style={{ padding: ".2rem .2rem" }}
                      />
                    </ListItem>
                  </List>
                )}
                {!isNoTasks &&
                  cp.tasks.map(task => (
                    <List disablePadding>
                      <StyledListItem
                        button
                        selected={task.id === currentTask.id}
                        disabled={task.statusId === "completed"}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <ListItemIcon style={{ fontSize: ".7rem" }}>
                            {task.statusId === "completed" ? (
                              <AppIcon color="green" icon={faCheckCircle} />
                            ) : (
                              <AppIcon icon={faTasks} />
                            )}
                          </ListItemIcon>

                          <AppLink
                            to={`/tasks/${task.id}`}
                            style={{ fontSize: ".8rem" }}
                          >
                            {task.title}
                          </AppLink>
                        </div>

                        {task.assignedUser && (
                          <GlobalAvatar
                            userId={task.assignedUser.id}
                            fullName={task.assignedUser.fullName}
                            profileImageUrl={task.assignedUser.profileImageUrl}
                            skipQuery
                          />
                        )}
                      </StyledListItem>
                    </List>
                  ))}
              </Collapse>
            </Fragment>
          );
        })}
      </MenuList>
    </Paper>
  );
};

export default BatchedProductsMenu;
