import gql from "graphql-tag";

export default gql`
  query selectCustomerProject($customerProjectId: Int!) {
    customerProject(customerProjectId: $customerProjectId) {
      id
      name
      amount
      startDate
      createdAt
      notes
      customerContract {
        id
      }
      status {
        id
        name
        description
      }
      salesUser {
        id
        fullName
      }
      customerProducts {
        id
        productId
        startDate
        customerSku {
          id
          sku
          asin
          upc
          title
        }
        product {
          id
          name
          requiresSku
          productCategory {
            name
          }
        }
        status {
          id
          name
        }
      }
    }
  }
`;
