import React, { FunctionComponent, useState } from "react";
import moment from "moment";
import { map, clone } from "lodash";

/* Material UI & FontAwesome */
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button
} from "@material-ui/core";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";

/* Common Components */
import AppLink from "../common/app-link";
import { StandardCard } from "../common/standard-card";
import { TableHeaderCell } from "../common/table-header-cell";
import AppIcon from "../common/app-icon";

import { SelectMyCustomers_Items } from "../../generated/graphql";

/* Utilities */
import { getMaxEndDateColor } from "../../utilities/max-end-date-color-helper";

const SORT_TYPES = {
  ASC_CONTRACT_VALUE: "asc-contract-value",
  DESC_CONTRACT_VALUE: "desc-contract-value",
  ASC_END_DATE: "asc-end-date",
  DESC_END_DATE: "desc-end-date"
};

const renderTableRows = (customers: SelectMyCustomers_Items[]) => {
  return (
    customers &&
    customers.map(customer => {
      const maxEndDate = moment(customer.maxEndDate)
        .add(1, "month")
        .format("MM/DD/YYYY");
      return (
        <TableRow key={customer.id}>
          <TableCell>
            <AppLink to={`/customers/${customer.id}`}>{customer.name}</AppLink>
          </TableCell>
          <TableCell>
            {`$${customer.contractValue && customer.contractValue.toFixed(2)}`}
          </TableCell>
          <TableCell
            style={{
              color: getMaxEndDateColor(maxEndDate),
              fontWeight: 600
            }}
          >
            {customer.maxEndDate ? maxEndDate : "-"}
          </TableCell>
        </TableRow>
      );
    })
  );
};

const renderTableBodyContent = (
  customers: SelectMyCustomers_Items[],
  sortType: string
) => {
  const {
    ASC_CONTRACT_VALUE,
    DESC_CONTRACT_VALUE,
    ASC_END_DATE,
    DESC_END_DATE
  } = SORT_TYPES;
  switch (sortType) {
    case ASC_CONTRACT_VALUE: {
      const clonedCustomers = map(customers, clone);
      const customersSortedByContractValue = clonedCustomers.sort((c1, c2) =>
        c1.contractValue && c2.contractValue
          ? c1.contractValue - c2.contractValue
          : 0
      );
      return renderTableRows(customersSortedByContractValue);
    }
    case DESC_CONTRACT_VALUE: {
      const clonedCustomers = map(customers, clone);
      const customersSortedByContractValue = clonedCustomers.sort((c1, c2) =>
        c1.contractValue && c2.contractValue
          ? c2.contractValue - c1.contractValue
          : 0
      );
      return renderTableRows(customersSortedByContractValue);
    }
    case ASC_END_DATE: {
      const clonedCustomers = map(customers, clone);
      const customersSortedByEndDate = clonedCustomers.sort((c1, c2) => {
        if (moment(c1.maxEndDate) > moment(c2.maxEndDate)) {
          return 1;
        }
        if (moment(c1.maxEndDate) < moment(c2.maxEndDate)) {
          return -1;
        }
        return 0;
      });
      return renderTableRows(customersSortedByEndDate);
    }
    case DESC_END_DATE: {
      const clonedCustomers = map(customers, clone);
      const customersSortedByEndDate = clonedCustomers.sort((c1, c2) => {
        if (moment(c1.maxEndDate) > moment(c2.maxEndDate)) {
          return 1;
        }
        if (moment(c1.maxEndDate) < moment(c2.maxEndDate)) {
          return -1;
        }
        return 0;
      });
      return renderTableRows(customersSortedByEndDate.reverse());
    }
    default: {
      return renderTableRows(customers);
    }
  }
};

const filterIcon = () => (
  <div
    style={{
      marginLeft: ".5rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      fontSize: ".5rem"
    }}
  >
    <AppIcon icon={faCaretUp} />
    <AppIcon icon={faCaretDown} />
  </div>
);

interface Props {
  customers: SelectMyCustomers_Items[];
  customerCount: number;
}

const MyCustomersCard: FunctionComponent<Props> = ({
  customers,
  customerCount
}) => {
  const [sortType, setSortType] = useState<string>("");
  const {
    ASC_CONTRACT_VALUE,
    DESC_CONTRACT_VALUE,
    ASC_END_DATE,
    DESC_END_DATE
  } = SORT_TYPES;

  return (
    <StandardCard title={`My Customers (${customerCount})`}>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell>Customer</TableHeaderCell>
            <TableHeaderCell>
              <Button
                size="small"
                style={{ padding: "0", textTransform: "none" }}
                onClick={() =>
                  sortType === ASC_CONTRACT_VALUE
                    ? setSortType(DESC_CONTRACT_VALUE)
                    : setSortType(ASC_CONTRACT_VALUE)
                }
              >
                Contract Value
                {filterIcon()}
              </Button>
            </TableHeaderCell>
            <TableHeaderCell>
              <Button
                size="small"
                style={{ padding: "0", textTransform: "none" }}
                onClick={() =>
                  sortType === ASC_END_DATE
                    ? setSortType(DESC_END_DATE)
                    : setSortType(ASC_END_DATE)
                }
              >
                Contract End Date
                {filterIcon()}
              </Button>
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>{renderTableBodyContent(customers, sortType)}</TableBody>
      </Table>
    </StandardCard>
  );
};

export default MyCustomersCard;
