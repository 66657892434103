import React, { Component, Fragment } from "react";
const moment = require("moment-business-days");
import { Formik, Form, FormikActions } from "formik";
import { withSnackbar, InjectedNotistackProps } from "notistack";

/* Material UI & FontAwesome */
import {
  DialogContent,
  DialogTitle,
  Dialog,
  DialogActions,
  Button,
  Input,
  InputAdornment,
  TextField,
  FormControl,
  InputLabel,
  Grid
} from "@material-ui/core";
import { Slider } from "@material-ui/lab";
import { DatePicker } from "material-ui-pickers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

/* Common Components */
import { FieldWrapper } from "../../../common/styled/field-wrapper";
import AlertError from "../../../common/styled/alert-error";
import Loader from "../../../common/loader";

/* Utilities */
import { isDateDisabled } from "../../../../utilities/date-helpers";
import { transformGraphQLErrorForFormik } from "../../../../utilities/form-helpers";

/* Child Components */
import SkuSelectorTable from "./sku-selector-table";

/* GraphQL & Apollo Imports */
import {
  SelectRoadmapCustomerProjects,
  AddContentCreateCustomerProductMutation,
  AddContentCreateCustomerProductVariables,
  SelectRoadmapCustomer,
  AddProductProduct
} from "../../../../generated/graphql";
import { MutationFn } from "react-apollo";

interface Pricing {
  discount: number | null;
  amount: number | null;
}

interface FormValues {
  startDate: Date;
  discount: number;
  amount: number;
  global?: string;
}

interface Props extends InjectedNotistackProps {
  product: AddProductProduct;
  customer: SelectRoadmapCustomer;
  customerProject: SelectRoadmapCustomerProjects;
  createCustomerProductMutation: MutationFn<
    AddContentCreateCustomerProductMutation,
    AddContentCreateCustomerProductVariables
  >;
  onProductsAdded: () => void;
  onClose: () => void;
}

interface State {
  selectedCustomerSkuId: number | null;
}

class AddProductDialog extends Component<Props, State> {
  state = { selectedCustomerSkuId: null };

  onFormSubmit = async (
    values: FormValues,
    actions: FormikActions<FormValues>
  ) => {
    const {
      customerProject,
      createCustomerProductMutation,
      product,
      onProductsAdded,
      enqueueSnackbar
    } = this.props;

    const { selectedCustomerSkuId } = this.state;

    if (product.requiresSku && !selectedCustomerSkuId) {
      actions.setErrors({ global: "A SKU must be selected." });
      actions.setSubmitting(false);
      return;
    }

    try {
      const pricing: Pricing = {
        discount: null,
        amount: null
      };
      if (product.currentProductPrice) {
        pricing.discount = values.discount / 100;
      } else {
        pricing.amount = values.amount;
      }
      await createCustomerProductMutation({
        variables: {
          input: {
            ...pricing,
            customerId: customerProject.customerId,
            customerProjectId: customerProject.id,
            startDate: moment(values.startDate).format("YYYY-MM-DD"),
            customerSkuId: product.requiresSku
              ? Number(selectedCustomerSkuId)
              : null,
            productId: product.id
          }
        }
      });
      actions.setSubmitting(false);
      actions.setErrors({});
      onProductsAdded();
      enqueueSnackbar("The Product was added succesfully!", {
        variant: "success"
      });
    } catch (e) {
      const error = transformGraphQLErrorForFormik(e);
      actions.setErrors(error);
      actions.setSubmitting(false);

      if (error.global) {
        enqueueSnackbar(error.global, { variant: "error" });
      } else {
        enqueueSnackbar("The was an error creating the selected Product.", {
          variant: "error"
        });
      }
    }
  };

  onCustomerSkuSelected = (customerSkuId: number) => {
    this.setState({ selectedCustomerSkuId: customerSkuId });
  };

  render() {
    const { onClose, product, customer } = this.props;
    const { selectedCustomerSkuId } = this.state;
    return (
      <Formik
        initialValues={{
          startDate: moment().nextBusinessDay(),
          discount: 0,
          amount: 0
        }}
        onSubmit={this.onFormSubmit}
        render={({
          values,
          setFieldValue,
          handleSubmit,
          errors,
          handleChange,
          isSubmitting
        }) => (
          <Form>
            <Dialog
              fullWidth
              open
              onClose={onClose}
              maxWidth="lg"
              scroll="body"
            >
              <DialogTitle>Add {product.name}</DialogTitle>
              <DialogContent>
                {errors.global && <AlertError message={errors.global} />}
                <Grid container spacing={16}>
                  <Grid item xs={12} lg={4}>
                    <FieldWrapper>
                      <DatePicker
                        value={values.startDate}
                        fullWidth
                        onChange={date => setFieldValue("startDate", date)}
                        label="Start Date"
                        format="M/D/YYYY"
                        maxDate={moment()
                          .nextBusinessDay()
                          .add(1, "year")}
                        placeholder={moment()
                          .nextBusinessDay()
                          .format("M/D/YYYY")}
                        shouldDisableDate={isDateDisabled(true, true, false)}
                        // handle clearing outside => pass plain array if you are not controlling value outside
                        disableOpenOnEnter
                        animateYearScrolling={false}
                      />
                    </FieldWrapper>
                    {product.currentProductPrice && (
                      <Fragment>
                        <FieldWrapper>
                          <TextField
                            label="Client Pricing"
                            value={`$${product.currentProductPrice.amount *
                              (1 -
                                product.currentProductPrice
                                  .clientDiscountPercentage)}`}
                            disabled
                            fullWidth
                          />
                        </FieldWrapper>
                        <FieldWrapper>
                          <TextField
                            label="Maximum Additional Discount"
                            value={`${(
                              product.currentProductPrice
                                .maxAdditionalDiscountPercentage * 100
                            ).toFixed(2)}%`}
                            disabled
                            fullWidth
                          />
                        </FieldWrapper>
                        <FieldWrapper>
                          <FormControl fullWidth>
                            <InputLabel>Additional Discount</InputLabel>
                            <Input
                              value={values.discount.toFixed(2)}
                              fullWidth
                              endAdornment={
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              }
                              disabled
                            />
                            <Slider
                              id="discount"
                              value={values.discount}
                              onChange={(e, value) =>
                                setFieldValue("discount", value)
                              }
                              min={0}
                              max={
                                product.currentProductPrice
                                  .maxAdditionalDiscountPercentage * 100
                              }
                              step={0.01}
                            />
                          </FormControl>
                        </FieldWrapper>
                        <FieldWrapper>
                          <TextField
                            label="Total Amount"
                            value={`$${(
                              product.currentProductPrice.amount -
                              product.currentProductPrice.amount *
                                (product.currentProductPrice
                                  .clientDiscountPercentage +
                                  values.discount / 100)
                            ).toFixed(2)}`}
                            disabled
                            fullWidth
                          />
                        </FieldWrapper>
                      </Fragment>
                    )}
                    {!product.currentProductPrice && (
                      <Fragment>
                        <FieldWrapper>
                          <FormControl fullWidth>
                            <InputLabel>Amount</InputLabel>
                            <Input
                              id="amount"
                              name="amount"
                              value={values.amount}
                              onChange={handleChange}
                              fullWidth
                              startAdornment={
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                        </FieldWrapper>
                      </Fragment>
                    )}
                  </Grid>
                  {product.requiresSku && (
                    <Grid item xs={12} lg={8}>
                      <SkuSelectorTable
                        customerId={customer.id}
                        selectedCustomerSkuId={selectedCustomerSkuId}
                        onCustomerSkuSelected={this.onCustomerSkuSelected}
                      />
                    </Grid>
                  )}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit()}
                  disabled={isSubmitting}
                >
                  {!isSubmitting && (
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ marginRight: ".5em" }}
                    />
                  )}
                  {isSubmitting && <Loader />}
                  Save and Finish
                </Button>
                <Button onClick={onClose} disabled={isSubmitting}>
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        )}
      />
    );
  }
}

export default withSnackbar(AddProductDialog);
