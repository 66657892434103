import React, { Component, Fragment } from "react";
import { TextField, InputAdornment, Button } from "@material-ui/core";
import SelectCustomerDialog from "./select-customer-dialog";
import { GetCustomerForCustomerSelectComponent } from "../../../generated/graphql";
import DangerButton from "../styled/danger-button";

interface Props {
  label: string;
  allowClear?: boolean;
  customerId: number | null;
  onCustomerSelected: (customerId: number | null) => void;
  disabled?: boolean;
}

interface State {}

class CustomerSelectContainer extends Component<Props, State> {
  state = {
    isDialogOpen: false
  };

  onOpenSelector = () => {
    this.setState({ isDialogOpen: true });
  };

  onCloseSelector = () => {
    this.setState({ isDialogOpen: false });
  };

  onCustomerSelected = (customerId: number | null) => {
    const { onCustomerSelected } = this.props;
    this.setState({ isDialogOpen: false });
    onCustomerSelected(customerId);
  };

  render() {
    const { label, allowClear, customerId, disabled } = this.props;
    const { isDialogOpen } = this.state;
    return (
      <Fragment>
        {isDialogOpen && (
          <SelectCustomerDialog
            onClose={this.onCloseSelector}
            onCustomerSelected={this.onCustomerSelected}
          />
        )}
        <GetCustomerForCustomerSelectComponent
          variables={{ customerId: customerId || 0 }}
          skip={!customerId}
        >
          {({ data, loading, error }) => {
            let displayText = "Select a Customer";
            if (loading) {
              displayText = "Loading...";
            }
            if (error) {
              displayText = "An Error occurred.";
            }
            if (data && data.customer) {
              displayText = data.customer.name;
            }
            return (
              <TextField
                value={displayText}
                label={label}
                fullWidth
                disabled={disabled}
                inputProps={{ readOnly: true }}
                InputProps={{
                  endAdornment: disabled ? null : (
                    <InputAdornment position="end">
                      {allowClear && customerId && (
                        <DangerButton
                          size="small"
                          onClick={() => this.onCustomerSelected(null)}
                        >
                          Clear
                        </DangerButton>
                      )}
                      <Button size="small" onClick={this.onOpenSelector}>
                        Select
                      </Button>
                    </InputAdornment>
                  )
                }}
              />
            );
          }}
        </GetCustomerForCustomerSelectComponent>
      </Fragment>
    );
  }
}

export default CustomerSelectContainer;
