import React, { FunctionComponent } from "react";

/* Material UI */
import { Grid } from "@material-ui/core";

/* Child Components */
import AccountManagementOverviewReportCard from "./account-management-overview-report-card";

/* Common Components */
import PageLayout from "../../common/page-layout";

const AccountManagementOverviewReportContainer: FunctionComponent = () => (
  <PageLayout pageTitle="Reports - Account Management Overview">
    <Grid container spacing={16}>
      <Grid item xs={12} lg={8}>
        <AccountManagementOverviewReportCard />
      </Grid>
    </Grid>
  </PageLayout>
);

export default AccountManagementOverviewReportContainer;
