import React, { FunctionComponent } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

/* Material UI & FontAwesome */
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faClipboardList,
  faMoneyBill,
  faTasks,
  faUsers,
  faCreditCard,
  faCubes,
  faPeopleCarry,
  faTachometerAlt
} from "@fortawesome/free-solid-svg-icons";

/* Common Components */
import AppLink from "./app-link";

/* Utility for checking user role (authorization checker) */
import RoleChecker from "./role-checker";

/* Constants */
import { ROLES } from "../../constants/roles";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";

/* In File Styled Components */
const StyledListItemText = styled(ListItemText)`
  span {
    font-size: 0.9rem;
  }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 0.9rem;
`;

/* MainMenuContent provides the side bar navigation (i.e. dashboard, customers, billing, tasks, etc) */

const MainMenuContent: FunctionComponent<any> = ({ location }) => {
  const basePath = location.pathname.match(/^\/([a-zA-Z-]+)/i)[0];
  return (
    <List>
      <AppLink to="/dashboard">
        <ListItem button selected={basePath === "/dashboard"}>
          <ListItemIcon>
            <StyledFontAwesomeIcon icon={faTachometerAlt} fixedWidth />
          </ListItemIcon>
          <StyledListItemText primary="Dashboard" />
        </ListItem>
      </AppLink>
      <AppLink to="/customers">
        <ListItem button selected={basePath === "/customers"}>
          <ListItemIcon>
            <StyledFontAwesomeIcon icon={faClipboardList} fixedWidth />
          </ListItemIcon>
          <StyledListItemText primary="Customers" />
        </ListItem>
      </AppLink>
      <RoleChecker allowedRoles={[ROLES.FINANCE]}>
        <AppLink to="/billing">
          <ListItem button selected={basePath === "/billing"}>
            <ListItemIcon>
              <StyledFontAwesomeIcon icon={faMoneyBill} fixedWidth />
            </ListItemIcon>
            <StyledListItemText primary="Billing" />
          </ListItem>
        </AppLink>
      </RoleChecker>
      <AppLink to="/tasks">
        <ListItem button selected={basePath === "/tasks"}>
          <ListItemIcon>
            <StyledFontAwesomeIcon icon={faTasks} fixedWidth />
          </ListItemIcon>
          <StyledListItemText primary="Tasks" />
        </ListItem>
      </AppLink>
      <AppLink to="/users">
        <ListItem button selected={basePath === "/users"}>
          <ListItemIcon>
            <StyledFontAwesomeIcon icon={faUsers} fixedWidth />
          </ListItemIcon>
          <StyledListItemText primary="Users" />
        </ListItem>
      </AppLink>
      <AppLink to="/teams">
        <ListItem button selected={basePath === "/teams"}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faPeopleCarry} fixedWidth />
          </ListItemIcon>
          <ListItemText primary="Teams" />
        </ListItem>
      </AppLink>
      <AppLink to="/pricing-tool">
        <ListItem button selected={basePath === "/pricing-tool"}>
          <ListItemIcon>
            <StyledFontAwesomeIcon icon={faCreditCard} fixedWidth />
          </ListItemIcon>
          <StyledListItemText primary="Pricing Tool" />
        </ListItem>
      </AppLink>
      <AppLink to="/inventory">
        <ListItem button selected={basePath === "/inventory"}>
          <ListItemIcon>
            <StyledFontAwesomeIcon icon={faCubes} fixedWidth />
          </ListItemIcon>
          <StyledListItemText primary="Inventory" />
        </ListItem>
      </AppLink>
      <AppLink to="/reports">
        <ListItem button selected={basePath === "/reports"}>
          <ListItemIcon>
            <StyledFontAwesomeIcon icon={faChartLine} fixedWidth />
          </ListItemIcon>
          <StyledListItemText primary="Reports" />
        </ListItem>
      </AppLink>
      <AppLink to="/help">
        <ListItem button selected={basePath === "/help"}>
          <ListItemIcon>
            <StyledFontAwesomeIcon icon={faQuestionCircle} fixedWidth />
          </ListItemIcon>
          <StyledListItemText primary="Help" />
        </ListItem>
      </AppLink>
    </List>
  );
};

export default withRouter(MainMenuContent);
