import React, { FunctionComponent } from "react";
import { StandardCard } from "../../../common/standard-card";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@material-ui/core";
import { TableHeaderCell } from "../../../common/table-header-cell";
import AppLink from "../../../common/app-link";
import {
  SelectCustomerSkuCustomerSku,
  GetCustomerCustomer
} from "../../../../generated/graphql";

interface Props {
  customer: GetCustomerCustomer;
  customerSku: SelectCustomerSkuCustomerSku;
}

const VariationSkusTable: FunctionComponent<Props> = ({
  customer,
  customerSku
}) => {
  return (
    <StandardCard title="Variations">
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell>Sku</TableHeaderCell>
            <TableHeaderCell>Title</TableHeaderCell>
          </TableRow>
        </TableHead>
        {customerSku.children &&
          customerSku.children.map(child => (
            <TableBody>
              <TableRow>
                <TableCell>
                  <AppLink to={`/customers/${customer.id}/skus/${child.id}`}>
                    {child.sku}
                  </AppLink>
                </TableCell>
                <TableCell>{child.title}</TableCell>
              </TableRow>
            </TableBody>
          ))}
      </Table>
    </StandardCard>
  );
};

export default VariationSkusTable;
