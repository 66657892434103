import gql from "graphql-tag";

export default gql`
  query selectViewerRoles {
    viewer {
      id
      roles
    }
  }
`;
