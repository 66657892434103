import React from "react";
import AlertInfo from "../../common/styled/alert-info";

const TaskAssignmentContainer = () => (
  <div>
    <AlertInfo message="Task Assignment functionality coming soon." />
  </div>
);

export default TaskAssignmentContainer;
