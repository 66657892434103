import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faMousePointer,
  faTimes,
  faUserSlash
} from "@fortawesome/free-solid-svg-icons";
import SelectSkuModalContainer from "../../select-sku-modal";
import Text from "../../common/styled/text";
import AppIcon from "../../common/app-icon";
import DangerButton from "../../common/styled/danger-button";

class Workspace extends Component {
  state = {
    showModal: false,
    selectedCustomerSkuId: null
  };

  onSelectCustomerSku = () => {
    this.setState({ showModal: true });
  };

  onSelectCustomerSkuCancel = () => {
    this.setState({ showModal: false });
  };

  onCustomerSkuSelected = customerSkuId => {
    this.setState({ showModal: false, selectedCustomerSkuId: customerSkuId });
  };

  onCompleteTask = async () => {
    console.log("Saving Selected Customer Sku");
    const { selectedCustomerSkuId } = this.state;
    const { task, onComplete, updateCustomerProductMutation } = this.props;
    try {
      await updateCustomerProductMutation({
        variables: {
          input: {
            customerProductId: task.customerProduct.id,
            customerSkuId: selectedCustomerSkuId
          }
        }
      });
      await onComplete();
    } catch (e) {
      console.log("Error updating the customer product: ", e);
    }
  };

  onClose = () => {
    const { onClose } = this.props;
    // TODO: save
    onClose();
  };

  onUnassign = async () => {
    const { onClose, onUnassign } = this.props;
    await onUnassign();
    onClose();
  };

  render() {
    const { showModal, selectedCustomerSkuId } = this.state;
    const { task } = this.props;
    const { customer } = task;
    return (
      <div>
        <SelectSkuModalContainer
          visible={showModal}
          onCancel={this.onSelectCustomerSkuCancel}
          customerId={customer.id}
          onCustomerSkuSelected={this.onCustomerSkuSelected}
        />
        <div>
          <Text.HeaderSmall>Select a Customer SKU</Text.HeaderSmall>
          {selectedCustomerSkuId && (
            <Text style={{ color: "green" }}>
              <FontAwesomeIcon icon={faCheck} style={{ marginRight: ".5em" }} />
              Customer SKU Selected.
            </Text>
          )}
          <Button type="primary" onClick={this.onSelectCustomerSku}>
            <FontAwesomeIcon
              icon={faMousePointer}
              style={{ marginRight: ".5em" }}
            />
            Select {selectedCustomerSkuId ? "Another" : ""} Customer SKU
          </Button>
        </div>
        <div style={{ marginTop: "4em" }}>
          <Button
            variant="contained"
            color="primary"
            disabled={!selectedCustomerSkuId}
            onClick={this.onCompleteTask}
            size="large"
          >
            <FontAwesomeIcon icon={faCheck} style={{ marginRight: ".5em" }} />
            Complete Task
          </Button>
          <DangerButton
            onClick={this.onUnassign}
            variant="contained"
            style={{ marginLeft: ".5em" }}
            size="large"
          >
            <AppIcon icon={faUserSlash} standardRightMargin />
            Abandon Task
          </DangerButton>
          <Button
            onClick={this.onClose}
            size="large"
            style={{ marginLeft: ".5em" }}
          >
            <FontAwesomeIcon icon={faTimes} style={{ marginRight: ".5em" }} />
            Close
          </Button>
        </div>
      </div>
    );
  }
}

Workspace.propTypes = {
  task: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired
};

export default Workspace;
