import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ReactHelmet from "react-helmet";
import { Button } from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import Logo from "../../assets/nozani-central-logo.png";
import Text from "../common/styled/text";

const LogoImage = styled.img`
  max-height: 150px;
  padding-bottom: 10px;
`;

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
`;

const ErrorText = styled.p`
  color: red;
  font-weight: bold;
`;

const Login = ({ onLogin, isLoginDisabled, error }) => (
  <Fragment>
    <ReactHelmet>
      <title>Login | Nozani Central</title>
    </ReactHelmet>
    <CenteredContainer>
      <div>
        <Text>
          <LogoImage src={Logo} alt="Nozani" />
        </Text>
        {error && <ErrorText>{error}</ErrorText>}
        <div>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={onLogin}
            disabled={isLoginDisabled}
          >
            <FontAwesomeIcon icon={faGoogle} style={{ marginRight: ".5em" }} />
            Login with Google
          </Button>
        </div>
      </div>
    </CenteredContainer>
  </Fragment>
);

Login.defaultProps = {
  error: null
};

Login.propTypes = {
  error: PropTypes.string,
  onLogin: PropTypes.func.isRequired,
  isLoginDisabled: PropTypes.bool.isRequired
};

export default Login;
