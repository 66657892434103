import React, { FunctionComponent, useState } from "react";

import { withSnackbar, InjectedNotistackProps } from "notistack";
import ProjectNotesDialog from "./project-notes-dialog";
import {
  CreateNoteComponent,
  RoadmapCustomerProjectNotesComponent
} from "../../../../generated/graphql";

interface CustomerProject {
  id: number;
}

interface Props extends InjectedNotistackProps {
  customerId: number;
  customerProject: CustomerProject;
  onCancel: () => void;
}

const ProjectNotesDialogContainer: FunctionComponent<Props> = (
  props: Props
) => {
  const [limit, setLimit] = useState<number>(20);
  const [offset, setOffset] = useState<number>(0);

  const onNextPage = () => {
    setOffset(offset + limit);
  };

  const onPreviousPage = () => {
    if (offset <= limit) {
      setOffset(0);
    } else {
      setOffset(offset - limit);
    }
  };

  const { customerProject, customerId } = props;
  return (
    <RoadmapCustomerProjectNotesComponent
      variables={{
        customerProjectId: customerProject.id,
        limit,
        offset
      }}
    >
      {({ data, loading, error, refetch }) => {
        if (loading) {
          return null;
        }
        const { enqueueSnackbar } = props;
        if (error) {
          enqueueSnackbar("Something went wrong trying to load notes.");
          return null;
        }
        if (
          !data ||
          !data.customerProject ||
          !data.customerProject.notes ||
          !data.customerProject.notes.items
        ) {
          enqueueSnackbar("Something went wrong trying to load notes.");
          return null;
        }
        const {
          customerProject: {
            notes: { items, totalCount }
          }
        } = data;
        return (
          <CreateNoteComponent>
            {createNoteMutation => (
              <ProjectNotesDialog
                customerId={customerId}
                notes={items}
                createNoteMutation={createNoteMutation}
                refetchNotes={refetch}
                onNextPage={onNextPage}
                onPreviousPage={onPreviousPage}
                count={totalCount || 0}
                limit={limit}
                offset={offset}
                {...props}
              />
            )}
          </CreateNoteComponent>
        );
      }}
    </RoadmapCustomerProjectNotesComponent>
  );
};

export default withSnackbar(ProjectNotesDialogContainer);
