import React, { FunctionComponent, Fragment } from "react";

import { SelectTaskTask } from "../../../generated/graphql";

import InfographicReviewItemsContainer from "../common/infographic-review-items";
import TASK_TYPES from "../../../constants/task-types";

interface Props {
  task: SelectTaskTask;
}

const AdditionalPanels: FunctionComponent<Props> = ({ task }) => {
  switch (task.taskType.id) {
    case TASK_TYPES.INFOGRAPHIC_CREATE:
    case TASK_TYPES.INFOGRAPHIC_GDM_REVIEW:
    case TASK_TYPES.INFOGRAPHIC_AM_REVIEW:
    case TASK_TYPES.INFOGRAPHIC_CUSTOMER_REVIEW:
      return (
        <Fragment>
          <InfographicReviewItemsContainer task={task} />
        </Fragment>
      );
    default:
      return null;
  }
};

export default AdditionalPanels;
