import React, { Fragment } from "react";
import Text from "../../common/styled/text";
import InstructionsContainer from "../../common/styled/instructions-container";

const PlanShoot = () => (
  <InstructionsContainer>
    <Text>
      The photographer begins their initial research by reading through the
      questionnaire, looking at the client’s website, studying the Amazon
      listing, analyzing their Amazon competition, etc.. The photographer should
      also begin thinking about models, props, location (weather), a tentative
      date for the shoot, etc. This list of notes will later be used to create a
      proposal.
    </Text>
  </InstructionsContainer>
);

export default PlanShoot;
