import React, { Fragment, Component } from "react";

/* Material UI */
import { Tabs, Tab, Paper } from "@material-ui/core";

/* Common Components */
import Loader from "../common/loader";
import ErrorDisplay from "../common/error-display";
import PageLayout from "../common/page-layout";
import { StandardCard } from "../common/standard-card";

/* Child Components */
import RetainerBudget from "./retainer-budget";
import Upsells from "./upsells";

/* GraphQL Imports */
import { PricingToolInfoComponent } from "../../generated/graphql";

class PricingToolContainer extends Component {
  state = {
    selectedTab: 1
  };

  onTabChange = (event: React.ChangeEvent<{}>, value: number) => {
    this.setState({ selectedTab: value });
  };

  render() {
    const { selectedTab } = this.state;
    return (
      <PageLayout pageTitle="Pricing Tool">
        <StandardCard title="Pricing Tool">
          <PricingToolInfoComponent>
            {({ data, loading, error, refetch }) => {
              if (loading) {
                return <Loader />;
              }

              if (error) {
                return <ErrorDisplay error={error} refetch={refetch} />;
              }

              if (!data) {
                return null;
              }

              const { pricingToolInfo } = data;
              return (
                <Fragment>
                  <Paper square style={{ marginBottom: "1em" }}>
                    <Tabs value={selectedTab} onChange={this.onTabChange}>
                      <Tab label="Upsells" />
                      <Tab label="Retainer Budget" />
                    </Tabs>
                  </Paper>
                  {selectedTab === 0 && <Upsells />}
                  {selectedTab === 1 && (
                    <RetainerBudget
                      averageWeeklyAmHours={
                        pricingToolInfo.averageWeeklyAmHours
                      }
                      weeksPerMonth={pricingToolInfo.weeksPerMonth}
                      managedAmountLimit={pricingToolInfo.managedAmountLimit}
                      amHourlyRate={pricingToolInfo.amHourlyRate}
                      trueHourlyOverhead={pricingToolInfo.trueHourlyOverhead}
                      desiredProfitMargin={pricingToolInfo.desiredProfitMargin}
                    />
                  )}
                </Fragment>
              );
            }}
          </PricingToolInfoComponent>
        </StandardCard>
      </PageLayout>
    );
  }
}

export default PricingToolContainer;
