import React from "react";
import Text from "../../common/styled/text";
import InstructionsContainer from "../../common/styled/instructions-container";

const GenerateProposal = () => (
  <InstructionsContainer>
    <Text>
      The photographer referencing their notes from planning to create a
      proposal. The proposal should be as specific as possible so the client can
      clearly understand if the proposed shoot is what they’re expecting or not.
    </Text>
  </InstructionsContainer>
);

export default GenerateProposal;
