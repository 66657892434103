import React from "react";
import Text from "../../common/styled/text";
import InstructionsContainer from "../../common/styled/instructions-container";

const AMProposalReview = () => (
  <InstructionsContainer>
    <Text>
      After the Subteam Meeting, the photographer will send the revised (if
      necessary) proposal for the Account Manager to review. The Account Manager
      should double check that the proposal is consistent with everything
      discussed in the Subteam Meeting and with what the client wants. If there
      are any corrections needed, it can be sent back to the photographer to
      revise. If there are no revisions needed, it should then be sent to the
      client to review.
    </Text>
  </InstructionsContainer>
);

export default AMProposalReview;
