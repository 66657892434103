import React, { Component, Fragment } from "react";
import { FormikValues, FormikActions } from "formik";
import { intersection } from "lodash";

/* GraphQL & Apollo Imports */
import {
  SelectCustomerProjects_Items,
  SelectCustomerProjectsVariables,
  SelectCustomerProjectsQuery,
  UpdateCustomerProjectMutation,
  UpdateCustomerProjectVariables
} from "../../generated/graphql";
import { MutationFn, QueryResult } from "react-apollo";

/* Child Components */
import BillingTable from "./billing-table";
import RecordSaleModal from "./record-sale-modal";

/* Common Components */
import Text from "../common/styled/text";

/* Constants & Utilities */
import { ROLES } from "../../constants/roles";
import { transformGraphQLErrorForFormik } from "../../utilities/form-helpers";

interface Props {
  queryResult: QueryResult<
    SelectCustomerProjectsQuery,
    SelectCustomerProjectsVariables
  >;
  updateCustomerProjectMutation: MutationFn<
    UpdateCustomerProjectMutation,
    UpdateCustomerProjectVariables
  >;
}
interface State {
  selectedCustomerProject: SelectCustomerProjects_Items | null;
}

class Billing extends Component<Props, State> {
  state = {
    selectedCustomerProject: null
  };

  onRecordSale = (customerProject: SelectCustomerProjects_Items) => {
    this.setState({ selectedCustomerProject: customerProject });
  };

  onRecordSaleCancel = () => {
    this.setState({ selectedCustomerProject: null });
  };

  onRecordSaleSubmit = async (
    values: FormikValues,
    actions: FormikActions<FormikValues>
  ) => {
    const { selectedCustomerProject } = this.state;
    const {
      queryResult: { refetch },
      updateCustomerProjectMutation
    } = this.props;
    actions.setSubmitting(true);

    if (!selectedCustomerProject) {
      return;
    }

    try {
      await updateCustomerProjectMutation({
        variables: {
          input: {
            customerProjectId: (selectedCustomerProject as SelectCustomerProjects_Items)
              .id,
            saleReferenceNumber: values.saleReferenceNumber,
            statusId: "inprogress"
          }
        }
      });
      actions.setSubmitting(false);
      actions.setErrors({});
      this.setState({ selectedCustomerProject: null });
      await refetch();
    } catch (e) {
      actions.setErrors(transformGraphQLErrorForFormik(e));
      actions.setSubmitting(false);
    }
  };

  render() {
    const { selectedCustomerProject } = this.state;
    const { queryResult } = this.props;

    if (!queryResult || !queryResult.data || !queryResult.data.viewer) {
      return null;
    }

    return (
      <Fragment>
        <RecordSaleModal
          customerProject={selectedCustomerProject}
          visible={!!selectedCustomerProject}
          onCancel={this.onRecordSaleCancel}
          onFormSubmit={this.onRecordSaleSubmit}
        />
        <div>
          <Text.HeaderSmall>Ready for Billing</Text.HeaderSmall>
          <BillingTable
            customerProjects={queryResult.data.currentProjects.items}
            onRecordSale={this.onRecordSale}
            canRecordSale={
              intersection(
                [ROLES.SUPER_ADMIN, ROLES.FINANCE],
                queryResult.data.viewer.roles
              ).length > 0
            }
          />
        </div>
        <div style={{ marginTop: "2em" }}>
          <Text.HeaderSmall>Next 7 Days</Text.HeaderSmall>
          <BillingTable
            customerProjects={queryResult.data.upcomingProjects.items}
            onRecordSale={this.onRecordSale}
            canRecordSale={false}
          />
        </div>
      </Fragment>
    );
  }
}

export default Billing;
