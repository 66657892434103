import gql from "graphql-tag";

export default gql`
  query selectTasks(
    $statusId: TaskStatusEnum
    $taskTypeIds: [String]
    $assignedUserId: Int
    $customerId: Int
  ) {
    tasks(
      statusId: $statusId
      taskTypeIds: $taskTypeIds
      assignedUserId: $assignedUserId
      customerId: $customerId
    ) {
      items {
        id
        title
        statusId
        dueDate
        assignedUser {
          id
          fullName
        }
        taskType {
          id
          name
        }
        customer {
          id
          name
        }
        customerProduct {
          id
          product {
            id
            name
          }
          customerProject {
            id
            name
          }
        }
      }
    }
  }
`;
