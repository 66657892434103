import React, { FunctionComponent, Fragment } from "react";

/* Material UI */
import { Table, TableHead, TableRow } from "@material-ui/core";

/* Common Components */
import { TableHeaderCell } from "../../common/table-header-cell";
import Loader from "../../common/loader";
import ErrorDisplay from "../../common/error-display";
import AppLink from "../../common/app-link";

/* GraphQL Imports */
import { SelectTeamsByGroupComponent } from "../../../generated/graphql";

/* Child Components */
import AccountManagementOverviewTableBody from "./acount-management-overview-table-body";

const GroupByTeamsOverviewReportCardTable: FunctionComponent = () => (
  <SelectTeamsByGroupComponent fetchPolicy="network-only">
    {({ data, loading, error, refetch }) => {
      if (loading) {
        return <Loader />;
      }

      if (error) {
        return <ErrorDisplay error={error} refetch={refetch} />;
      }

      if (!data) {
        return null;
      }

      const { teams } = data;

      return (
        <Fragment>
          {teams.items.map(team => (
            <Table style={{ marginBottom: "1.5rem" }}>
              <TableHead>
                <TableRow style={{ height: "2.188rem" }}>
                  <TableHeaderCell>
                    <AppLink
                      to={`/teams/${team.id}`}
                      style={{ marginBottom: ".25rem", marginTop: ".25rem" }}
                    >
                      {team.name}
                    </AppLink>
                  </TableHeaderCell>
                  <TableHeaderCell>Clients Managed</TableHeaderCell>
                  <TableHeaderCell>Amount Managed</TableHeaderCell>
                </TableRow>
              </TableHead>
              <AccountManagementOverviewTableBody
                leadUser={team.leadUser}
                accountManagers={team.teamUsers}
              />
            </Table>
          ))}
        </Fragment>
      );
    }}
  </SelectTeamsByGroupComponent>
);

export default GroupByTeamsOverviewReportCardTable;
