import React from "react";
import Text from "../../common/styled/text";
import InstructionsContainer from "../../common/styled/instructions-container";

const ShareToOutsourxcedEditing = () => (
  <InstructionsContainer>
    <Text>
      The edited images should be uploaded to the Google Drive and shared with
      Sumon.
    </Text>
  </InstructionsContainer>
);

export default ShareToOutsourxcedEditing;
