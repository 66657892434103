import React, { Component, Fragment } from "react";
import { QueryResult } from "react-apollo";
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button
} from "@material-ui/core";

import TasksTable from "./tasks-table";
import PageLayout from "../common/page-layout";
import Empty from "./empty";
import Loader from "../common/loader";
import ErrorDisplay from "../common/error-display";
import { StandardCard } from "../common/standard-card";
import {
  TaskStatusEnum,
  SelectTasksComponent,
  SelectTasksFilterDataComponent,
  SelectTasksFilterDataQuery,
  SelectTasksFilterDataVariables,
  SelectTasksVariables,
  SelectTasksQuery,
  TasksUpdateTaskComponent,
  GetUsersForUserSelectItems
} from "../../generated/graphql";
import { FieldWrapper } from "../common/styled/field-wrapper";
import UserSelectContainer from "../common/user-select";
import { ROLES } from "../../constants/roles";

interface Props {}
interface State {
  statusId: TaskStatusEnum;
  taskTypeIds: string[];
  assignedUserId: number | null;
  customerId: number | null;
  limit: number;
  offset: number;
}

class CustomersWithData extends Component<Props, State> {
  initialState: State = {
    statusId: TaskStatusEnum.Available,
    taskTypeIds: [],
    assignedUserId: null,
    customerId: null,
    limit: 50,
    offset: 0
  };

  state = {
    ...this.initialState
  };

  onStatusChange = () => {};

  onTaskTypeIdsChange = (e: React.ChangeEvent<any>, child: React.ReactNode) => {
    this.setState({
      taskTypeIds: e.target.value,
      limit: this.initialState.limit,
      offset: 0
    });
  };

  onAssignedUserIdChange = (userId: number | null) => {
    this.setState({
      assignedUserId: userId || null,
      limit: this.initialState.limit,
      offset: 0
    });
  };

  onStatusFilterChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      statusId: e.target.value as TaskStatusEnum,
      limit: this.initialState.limit,
      offset: 0
    });
  };

  onCustomerIdChange = () => {};

  resetFilters = () => {
    this.setState({ ...this.initialState });
  };

  renderFilters = ({
    data,
    loading,
    error
  }: QueryResult<
    SelectTasksFilterDataQuery,
    SelectTasksFilterDataVariables
  >) => {
    if (loading) {
      return <Loader />;
    }
    if (error) {
      return <ErrorDisplay error={error} />;
    }
    if (!data || !data.viewer) {
      return null;
    }

    const { viewer } = data;

    const { taskTypeIds, assignedUserId, statusId } = this.state;

    return (
      <div>
        <Grid container spacing={16}>
          <Grid item lg={2}>
            <FieldWrapper>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  native
                  fullWidth
                  onChange={this.onStatusFilterChanged}
                  value={statusId}
                >
                  <option value={TaskStatusEnum.Available}>Available</option>
                  <option value={TaskStatusEnum.Assigned}>Assigned</option>
                  <option value={TaskStatusEnum.Completed}>Completed</option>
                </Select>
              </FormControl>
            </FieldWrapper>
          </Grid>
          <Grid item lg={6}>
            <FieldWrapper>
              <FormControl fullWidth>
                <InputLabel>Task Types</InputLabel>
                <Select
                  multiple
                  onChange={this.onTaskTypeIdsChange}
                  style={{ width: "100%", minWidth: 300 }}
                  value={taskTypeIds}
                >
                  {data &&
                    data.taskTypes &&
                    data.taskTypes.map(taskType => (
                      <MenuItem value={taskType.id} key={taskType.id}>
                        {taskType.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </FieldWrapper>
          </Grid>
          <Grid item lg={4}>
            <FieldWrapper>
              <UserSelectContainer
                roles={[ROLES.EMPLOYEE]}
                allowUnassigned
                onUserSelected={this.onAssignedUserIdChange}
                userId={assignedUserId}
                label="Assigned User"
              />
            </FieldWrapper>
          </Grid>
        </Grid>
        <div>
          <span
            style={{
              fontSize: 12,
              marginRight: "2em",
              textTransform: "uppercase"
            }}
          >
            Quick Filters
          </span>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            style={{ marginRight: ".5em" }}
            onClick={() => {
              this.setState({
                ...this.initialState
              });
            }}
          >
            Available
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            style={{ marginRight: ".5em" }}
            onClick={() => {
              this.setState({
                assignedUserId: viewer.id,
                statusId: TaskStatusEnum.Assigned,
                limit: this.initialState.limit,
                offset: 0
              });
            }}
          >
            Assigned to Me
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            style={{ marginRight: ".5em" }}
            onClick={() => {
              this.setState({
                assignedUserId: viewer.id,
                statusId: TaskStatusEnum.Completed,
                limit: this.initialState.limit,
                offset: 0
              });
            }}
          >
            Completed by Me
          </Button>
        </div>
      </div>
    );
  };

  onNextPage = () => {
    this.setState(prevState => ({
      offset: prevState.offset + prevState.limit
    }));
  };

  onPreviousPage = () => {
    const { offset, limit } = this.state;
    if (offset <= limit) {
      this.setState({ offset: 0 });
    } else {
      this.setState(prevState => ({
        offset: prevState.offset - prevState.limit
      }));
    }
  };

  render() {
    const { limit, offset } = this.state;
    return (
      <PageLayout pageTitle="Tasks">
        <StandardCard title="Tasks">
          <TasksUpdateTaskComponent>
            {updateTaskMutation => (
              <Fragment>
                <SelectTasksFilterDataComponent fetchPolicy="cache-and-network">
                  {queryResult => this.renderFilters(queryResult)}
                </SelectTasksFilterDataComponent>
                <SelectTasksComponent
                  variables={{ ...this.state }}
                  fetchPolicy="network-only"
                  pollInterval={1000 * 10}
                >
                  {queryResult => (
                    <TasksTable
                      queryResult={queryResult}
                      updateTaskMutation={updateTaskMutation}
                      onPreviousPage={this.onPreviousPage}
                      onNextPage={this.onNextPage}
                      limit={limit}
                      offset={offset}
                    />
                  )}
                </SelectTasksComponent>
              </Fragment>
            )}
          </TasksUpdateTaskComponent>
        </StandardCard>
      </PageLayout>
    );
  }
}

export default CustomersWithData;
