import React, { Component } from "react";
import { InjectedNotistackProps, withSnackbar } from "notistack";

/* Child Components */
import AddProductDialog from "./add-product-dialog";

/* Common Components */
import Loader from "../../../common/loader";

/* GraphQL Imports */
import {
  SelectRoadmapCustomer,
  SelectRoadmapCustomerProjects,
  AddContentCreateCustomerProductComponent,
  AddProductComponent
} from "../../../../generated/graphql";

interface Props extends InjectedNotistackProps {
  customer: SelectRoadmapCustomer;
  customerProject: SelectRoadmapCustomerProjects;
  productId: string;
  onClose: () => void;
  onProductsAdded: () => void;
}

class AddProductDialogContainer extends Component<Props, {}> {
  state = {};

  render() {
    const { enqueueSnackbar, customer, productId } = this.props;

    return (
      <AddContentCreateCustomerProductComponent>
        {createCustomerProductMutation => (
          <AddProductComponent
            variables={{ customerId: customer.id, productId }}
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loader />;
              }

              if (error) {
                enqueueSnackbar("Something went wrong. Please try again.");
                return null;
              }

              if (!data || !data.product) {
                return null;
              }

              return (
                <AddProductDialog
                  product={data.product}
                  createCustomerProductMutation={createCustomerProductMutation}
                  {...this.props}
                />
              );
            }}
          </AddProductComponent>
        )}
      </AddContentCreateCustomerProductComponent>
    );
  }
}

export default withSnackbar(AddProductDialogContainer);
