import React, { FunctionComponent, useState } from "react";
const moment = require("moment-business-days");
import { DateTimePicker } from "material-ui-pickers";
/* Material UI */
import {
  DialogContent,
  DialogTitle,
  Dialog,
  Button,
  DialogActions
} from "@material-ui/core";

/* Font Awesome */
import { faEdit } from "@fortawesome/free-solid-svg-icons";

/* Common Components */
import AppIcon from "../../../common/app-icon";
import { FieldWrapper } from "../../../common/styled/field-wrapper";

/* Utilities */
import { isDateDisabled } from "../../../../utilities/date-helpers";

interface Props {
  setNewDueDate: React.Dispatch<React.SetStateAction<string | null>>;
  currentDueDate: any;
  onUpdateStrategyTaskDueDate: () => Promise<void>;
  onCancel: () => void;
}

const UpdateDueDateDialog: FunctionComponent<Props> = ({
  onCancel,
  currentDueDate,
  onUpdateStrategyTaskDueDate,
  setNewDueDate
}) => {
  const [dueDate, setDueDate] = useState<string | null>(
    currentDueDate ? moment(currentDueDate) : moment().nextBusinessDay()
  );

  return (
    <Dialog
      fullWidth
      open
      onClose={() => {
        setDueDate("");
        onCancel();
      }}
      maxWidth="sm"
      disableBackdropClick
    >
      <DialogTitle>Update Due Date</DialogTitle>
      <DialogContent>
        <FieldWrapper>
          <DateTimePicker
            value={dueDate}
            fullWidth
            onChange={date => {
              setDueDate(date);
              setNewDueDate(date);
            }}
            label="Due Date"
            maxDate={moment()
              .nextBusinessDay()
              .add(1, "year")}
            placeholder={moment()
              .nextBusinessDay()
              .format("M/D/YYYY")}
            shouldDisableDate={isDateDisabled(true, true, false)}
            // handle clearing outside => pass plain array if you are not controlling value outside
            disableOpenOnEnter
            animateYearScrolling={false}
          />
        </FieldWrapper>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            onUpdateStrategyTaskDueDate();
            onCancel();
          }}
        >
          <AppIcon standardRightMargin icon={faEdit} />
          Update Due Date
        </Button>
        <Button
          onClick={() => {
            setDueDate("");
            onCancel();
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateDueDateDialog;
