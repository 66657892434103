import React from "react";
import Text from "../../common/styled/text";
import InstructionsContainer from "../../common/styled/instructions-container";

const StudioAndLifestylePhotoEditing = () => (
  <InstructionsContainer>
    <Text>
      Once the raw photos have been uploaded to the Photo Google Drive, they
      should be edited.
    </Text>
  </InstructionsContainer>
);

export default StudioAndLifestylePhotoEditing;
