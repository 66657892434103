import moment from "moment";

/* getMaxEndDateColor util returns the correct color respective to the date passed in as the argument. */
export const getMaxEndDateColor = (maxEndDate: string) => {
  const currentDate = moment();

  if (moment(maxEndDate).diff(currentDate, "days") > 60) {
    return "green";
  }

  if (
    moment(maxEndDate).diff(currentDate, "days") < 60 &&
    moment(maxEndDate).diff(currentDate, "days") > 30
  ) {
    return "#f9b313";
  }

  if (moment(maxEndDate).diff(currentDate, "days") < 30) {
    return "red";
  }

  return "inherit";
};
