import React, { Component } from "react";
import { Formik, Form, FormikActions } from "formik";
import { withSnackbar, InjectedNotistackProps } from "notistack";

/* Material UI */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";

/* Graphql & Apollo Imports */
import { MutationFn } from "react-apollo";
import {
  SelectCustomerSkusItems,
  GetCustomerCustomer,
  CreateCustomerSkuMutation,
  CreateCustomerSkuVariables,
  UpdateCustomerSkuMutation,
  UpdateCustomerSkuVariables,
  SelectCustomerSkusChildren
} from "../../../generated/graphql";

/* Common Components */
import AlertError from "../../common/styled/alert-error";
import { FieldWrapper } from "../../common/styled/field-wrapper";
import Loader from "../../common/loader";

/* Utilities */
import { transformGraphQLErrorForFormik } from "../../../utilities/form-helpers";

interface Props extends InjectedNotistackProps {
  customer: GetCustomerCustomer;
  customerSku?: SelectCustomerSkusItems | SelectCustomerSkusChildren | null;
  parentCustomerSku?: SelectCustomerSkusItems | null;
  createCustomerSkuMutation: MutationFn<
    CreateCustomerSkuMutation,
    CreateCustomerSkuVariables
  >;
  updateCustomerSkuMutation: MutationFn<
    UpdateCustomerSkuMutation,
    UpdateCustomerSkuVariables
  >;
  onClose: () => any;
  onCustomerSkuCreated: () => any;
}

interface FormValues {
  global?: string;
  sku: string;
  title: string;
  asin: string;
  upc: string;
  skuType: string;
}

class SkuModal extends Component<Props> {
  onSubmit = async (values: FormValues, actions: FormikActions<FormValues>) => {
    const {
      customer,
      customerSku,
      createCustomerSkuMutation,
      updateCustomerSkuMutation,
      onClose,
      onCustomerSkuCreated,
      parentCustomerSku,
      enqueueSnackbar
    } = this.props;
    try {
      if (customerSku) {
        await updateCustomerSkuMutation({
          variables: {
            input: {
              customerSkuId: customerSku.id,
              sku: values.sku,
              title: values.title,
              asin: values.asin.length > 0 ? values.asin : null,
              upc: values.upc.length > 0 ? values.upc : null,
              skuType: values.skuType
            }
          }
        });
        onCustomerSkuCreated();
        enqueueSnackbar("The Customer Sku has been created successfully.", {
          variant: "success"
        });
      } else {
        await createCustomerSkuMutation({
          variables: {
            input: {
              customerId: customer.id,
              sku: values.sku,
              title: values.title,
              asin: values.asin.length > 0 ? values.asin : null,
              upc: values.upc.length > 0 ? values.upc : null,
              skuType: values.skuType,
              parentCustomerSkuId: parentCustomerSku
                ? parentCustomerSku.id
                : null
            }
          }
        });
        enqueueSnackbar("The Customer Sku has been updated successfully.", {
          variant: "success"
        });
      }
      actions.setSubmitting(false);
      actions.setErrors({});
      onCustomerSkuCreated();
      onClose();
    } catch (e) {
      const error = actions.setErrors(transformGraphQLErrorForFormik(e));
      actions.setSubmitting(false);

      if ((error as any).global) {
        enqueueSnackbar((error as any).global, { variant: "error" });
      } else {
        enqueueSnackbar(
          "There seems to be an issue with the selected Customer Sku.",
          { variant: "error" }
        );
      }
    }
  };

  render() {
    const { onClose, customerSku, parentCustomerSku } = this.props;
    return (
      <Formik
        onSubmit={this.onSubmit}
        initialValues={
          customerSku
            ? {
                sku: customerSku.sku || "",
                title: customerSku.title || "",
                asin: customerSku.asin || "",
                upc: customerSku.upc || "",
                skuType: customerSku.skuType || ""
              }
            : {
                sku: "",
                title: parentCustomerSku ? parentCustomerSku.title || "" : "",
                asin: "",
                upc: "",
                skuType: ""
              }
        }
        render={({
          handleSubmit,
          handleChange,
          values,
          errors,
          isSubmitting
        }) => (
          <Dialog open onClose={onClose} fullWidth disableBackdropClick>
            <DialogTitle>
              {customerSku ? "Update SKU" : "Create SKU"}
            </DialogTitle>
            <DialogContent>
              <Form>
                {errors.global && <AlertError message={errors.global} />}
                {parentCustomerSku && (
                  <FieldWrapper>
                    <TextField
                      disabled
                      fullWidth
                      value={`${parentCustomerSku.sku} - ${
                        parentCustomerSku.title
                      }`}
                      label="Parent SKU"
                    />
                  </FieldWrapper>
                )}
                <FieldWrapper>
                  <TextField
                    label="SKU"
                    id="sku"
                    name="sku"
                    onChange={handleChange}
                    value={values.sku}
                    placeholder="SKU"
                    fullWidth
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    label="Title"
                    id="title"
                    name="title"
                    onChange={handleChange}
                    value={values.title}
                    placeholder="Title"
                    fullWidth
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    label="ASIN (optional)"
                    id="asin"
                    name="asin"
                    onChange={handleChange}
                    value={values.asin}
                    placeholder="ASIN"
                    fullWidth
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    label="UPC (optional)"
                    id="upc"
                    name="upc"
                    onChange={handleChange}
                    value={values.upc}
                    placeholder="UPC"
                    fullWidth
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="happinessLevel">Sku Type</InputLabel>
                    <Select
                      id="skuType"
                      name="skuType"
                      onChange={handleChange}
                      value={values.skuType}
                      placeholder="Select a sku type"
                      fullWidth
                    >
                      <MenuItem value="Parent">Parent</MenuItem>
                      <MenuItem value="Variation">Variation</MenuItem>
                      <MenuItem value="Standalone">Standalone</MenuItem>
                    </Select>
                  </FormControl>
                </FieldWrapper>
              </Form>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} disabled={isSubmitting}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
              >
                {isSubmitting && <Loader />}
                {customerSku ? "Update SKU" : "Create SKU"}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      />
    );
  }
}

export default withSnackbar(SkuModal);
