import gql from "graphql-tag";

export default gql`
  query addContent($customerId: Int!) {
    customer(customerId: $customerId) {
      id
      customerSkus(onlyTopLevel: true) {
        items {
          id
          sku
          title
        }
      }
    }
    productCategories {
      items {
        id
        name
        products {
          id
          name
          currentProductPrice {
            id
            amount
            clientDiscountPercentage
            maxAdditionalDiscountPercentage
          }
        }
      }
    }
  }
`;
