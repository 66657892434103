import React, { Component } from "react";
import PropTypes from "prop-types";
import RealTimeNotifier from "./real-time-notifier";

export const AppUpdateContext = React.createContext({
  isUpdateAvailable: false
});

class AppUpdateMonitor extends Component {
  state = {
    isUpdateAvailable: false
  };

  onAppUpdated = () => {
    this.setState({ isUpdateAvailable: true });
  };

  render() {
    const { children } = this.props;
    const { isUpdateAvailable } = this.state;
    return (
      <AppUpdateContext.Provider value={{ isUpdateAvailable }}>
        <RealTimeNotifier
          channelName="ui"
          eventName="updated"
          onMessage={this.onAppUpdated}
        />
        {children}
      </AppUpdateContext.Provider>
    );
  }
}

AppUpdateMonitor.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppUpdateMonitor;
