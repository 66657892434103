import React, { Component, Fragment } from "react";
import amplitude from "amplitude-js";
import { debounce } from "lodash";

/* Material UI & FontAwesome */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import {
  Table,
  TableHead,
  TableBody,
  Grid,
  TextField,
  Button
} from "@material-ui/core";

/* Child Components */
import ServiceRow from "./service-row";

/* Constants */
import { TRACKING_EVENTS } from "../../constants/tracking-events";

/* Common Components */
import { FieldWrapper } from "../common/styled/field-wrapper";

interface State {
  serviceAmounts: any;
}
class Upsells extends Component<{}, State> {
  initialState = {
    serviceAmounts: {} as any
  };

  state = {
    ...this.initialState
  };

  trackUsage = debounce(
    () => {
      amplitude.getInstance().logEvent(TRACKING_EVENTS.UI_PRICING_TOOL_USED, {
        isSingleProject: true,
        ...this.state
      });
    },
    1000,
    { leading: false, trailing: true }
  );

  onServiceChange = (serviceName: string) => (
    quantity: number,
    additionalDiscount: number | null,
    amount: number
  ) => {
    const { serviceAmounts } = this.state;
    this.setState({
      serviceAmounts: {
        ...serviceAmounts,
        [serviceName]: { quantity, additionalDiscount, amount }
      }
    });
    this.trackUsage();
  };

  onCustomPhotoAmountChanged = (e: any) => {
    const { serviceAmounts } = this.state;
    this.setState({
      serviceAmounts: {
        ...serviceAmounts,
        customPhotoAmount: { amount: e.target.value * 1.0 }
      }
    });
    this.trackUsage();
  };

  onReset = () => {
    this.setState({ ...this.initialState });
  };

  render() {
    const { serviceAmounts } = this.state;
    const totalAmount = Object.keys(serviceAmounts)
      .map(key => (serviceAmounts[key] ? serviceAmounts[key].amount : 0))
      .reduce((total, amount) => total + amount, 0);

    const services = [
      {
        name: "Content Bundle (Unique)",
        price: 190,
        maximumDiscount: 31.5184208428496
      },
      { name: "Child Exact", price: 10, maximumDiscount: 56.9867767277403 },
      { name: "Child Recycled ", price: 28, maximumDiscount: 32.7918386370942 },
      {
        name: "Infographic Creation (1 graphic)",
        price: 149,
        maximumDiscount: 32.6251546930248
      },
      {
        name: "EBC, no photos",
        price: 600,
        maximumDiscount: 2.68077900103576
      },
      {
        name: "Amazon Stores (1 page)",
        price: 1500,
        maximumDiscount: 28.5418307350263
      },
      {
      name: "Additional Tab",
      price: 300,
      maximumDiscount: 5.0
      },
      {
        name: "Email Template (comes with 2)",
        price: 250,
        maximumDiscount: 5.0
      },
      {
        name: "Packaging Insert",
        price: 150,
        maximumDiscount: 32.6251546930248
      },
      {
        name: "Studio Image (1 image)",
        price: 60,
        maximumDiscount: 0.0
      },
      {
        name: "Unique Lifestyle Image (1 image)",
        price: 299,
        maximumDiscount: 0.0
      },
      {
        name: "Similar Lifestyle Image (1 image)",
        price: 99,
        maximumDiscount: 0.0
      },
      {
        name: "Video Package 1",
        price: 450,
        maximumDiscount: 0.0
      },
      {
        name: "Video Package 2",
        price: 700,
        maximumDiscount: 0.0
      },
      {
        name: "Video Package 3",
        price: 800,
        maximumDiscount: 0.0
      },
      {
        name: "Video Package 4",
        price: 1300,
        maximumDiscount: 0.0
      },
      {
        name: "Video Narration",
        price: 400,
        maximumDiscount: 0.0
      },
      {
        name: "Video Additional Model",
        price: 200,
        maximumDiscount: 0.0
      },
      {
        name: "Video Animated Text",
        price: 200,
        maximumDiscount: 0.0
      },
      { name: "PPC Management $0-2500k", price: 750, maximumDiscount: 0 },
      {
        name: "PPC Management $2500-10k",
        price: 1250,
        maximumDiscount: 0.0
      },
      { name: "PPC Management $10-20k", price: 2250, maximumDiscount: 0.0 },
      { name: "PPC Management $20-50k", price: 2950, maximumDiscount: 0.0 },
      {
        name: "PPC Management $50-100k",
        price: 4050,
        maximumDiscount: 0.0
      },
    ];

    return (
      <Fragment>
        <div style={{ marginBottom: "2em" }}>
          <Button variant="contained" color="primary" onClick={this.onReset}>
            <FontAwesomeIcon icon={faSync} style={{ marginRight: ".5em" }} />
            Reset
          </Button>
        </div>
        <Grid container spacing={16} style={{ marginBottom: "1em" }}>
          <Grid item xs={3}>
            <FieldWrapper>
              <TextField
                label="Services Total"
                value={`$${totalAmount.toFixed(2)}`}
                variant="filled"
                disabled
                fullWidth
              />
            </FieldWrapper>
          </Grid>
        </Grid>
        <Table>
          <TableHead>
            <ServiceRow
              isHeaderRow
              hideStandardClientDiscount
              priceHeaderText="Nozani Website Pricing"
            />
          </TableHead>
          <TableBody>
            {services.map(service => {
              const serviceKey = service.name.replace(/\W/g, "");
              return (
                <ServiceRow
                  key={serviceKey}
                  name={service.name}
                  nonClientAmount={service.price}
                  hideStandardClientDiscount
                  maximumAdditionalDiscount={service.maximumDiscount}
                  onChange={this.onServiceChange(serviceKey)}
                  quantity={
                    serviceAmounts[serviceKey]
                      ? serviceAmounts[serviceKey].quantity
                      : null
                  }
                  additionalDiscount={
                    serviceAmounts[serviceKey]
                      ? serviceAmounts[serviceKey].additionalDiscount
                      : null
                  }
                />
              );
            })}
          </TableBody>
        </Table>
        <Grid container style={{ marginTop: "2em" }}>
          <Grid item xs={3}>
            <FieldWrapper>
              <TextField
                label="Input $ from Photo Calculator"
                variant="outlined"
                value={
                  serviceAmounts.customPhotoAmount
                    ? serviceAmounts.customPhotoAmount.amount
                    : 0
                }
                onChange={e => this.onCustomPhotoAmountChanged}
                fullWidth
              />
            </FieldWrapper>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default Upsells;
