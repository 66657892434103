import React, { Fragment } from "react";
import Text from "../../common/styled/text";
import InstructionsContainer from "../../common/styled/instructions-container";

const AdditionalEditing = () => (
  <InstructionsContainer>
    <Text>
      Once Sumon has finished working on the photos we shared with his team, we
      should review the photos and complete any additional edits necessary
      (photoshop, lightroom, illustrator, etc.).
    </Text>
    <Text>
      Once the additional edits are complete (if any), the PSD’s should be
      uploaded to the hard drive for safe storage.
    </Text>
  </InstructionsContainer>
);

export default AdditionalEditing;
