import React, { Component } from "react";
import { Formik, FormikActions, Form } from "formik";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { MutationFn } from "react-apollo";
import { AlertError } from "../../common/styled/alert";
import { FieldWrapper } from "../../common/styled/field-wrapper";
import {
  CreateUserPhoneNumberMutation,
  CreateUserPhoneNumberVariables,
  SelectCustomerUsersPhoneNumbers,
  UpdateUserPhoneNumberMutation,
  UpdateUserPhoneNumberVariables
} from "../../../generated/graphql";
import { transformGraphQLErrorForFormik } from "../../../utilities/form-helpers";
import Loader from "../../common/loader";

interface Props {
  userId?: number;
  onClose: () => any;
  onUserPhoneNumberAdded?: () => any;
  onUserPhoneNumberUpdated?: () => any;
  createUserPhoneNumberMutation?: MutationFn<
    CreateUserPhoneNumberMutation,
    CreateUserPhoneNumberVariables
  >;
  updateUserPhoneNumberMutation?: MutationFn<
    UpdateUserPhoneNumberMutation,
    UpdateUserPhoneNumberVariables
  >;
  userPhoneNumber?: SelectCustomerUsersPhoneNumbers;
}

interface State {}

interface FormValues {
  global?: string | null;
  label: string;
  phoneNumber: string;
  isPrimary: boolean;
}

class UserPhoneNumberDialog extends Component<Props, State> {
  state = {};

  onSubmit = async (values: FormValues, actions: FormikActions<FormValues>) => {
    const {
      createUserPhoneNumberMutation,
      userId,
      userPhoneNumber,
      onUserPhoneNumberAdded,
      updateUserPhoneNumberMutation,
      onUserPhoneNumberUpdated
    } = this.props;
    try {
      if (
        userPhoneNumber &&
        updateUserPhoneNumberMutation &&
        onUserPhoneNumberUpdated
      ) {
        await updateUserPhoneNumberMutation({
          variables: {
            input: {
              isPrimary: values.isPrimary,
              label: values.label,
              phoneNumber: values.phoneNumber,
              userPhoneNumberId: userPhoneNumber.id
            }
          }
        });
        actions.setSubmitting(false);
        onUserPhoneNumberUpdated();
      } else if (
        userId &&
        createUserPhoneNumberMutation &&
        onUserPhoneNumberAdded
      ) {
        await createUserPhoneNumberMutation({
          variables: {
            input: {
              userId,
              isPrimary: values.isPrimary,
              label: values.label,
              phoneNumber: values.phoneNumber
            }
          }
        });
        actions.setSubmitting(false);
        onUserPhoneNumberAdded();
      }
    } catch (e) {
      actions.setErrors(transformGraphQLErrorForFormik(e));
      actions.setSubmitting(false);
    }
  };

  render() {
    const { onClose, userPhoneNumber } = this.props;
    return (
      <Formik
        onSubmit={this.onSubmit}
        initialValues={{
          isPrimary: userPhoneNumber ? userPhoneNumber.isPrimary : false,
          label: userPhoneNumber ? userPhoneNumber.label : "",
          phoneNumber: userPhoneNumber ? userPhoneNumber.phoneNumber : ""
        }}
        render={({
          handleSubmit,
          handleChange,
          values,
          errors,
          isSubmitting
        }) => (
          <Dialog
            open
            onClose={onClose}
            fullWidth
            disableBackdropClick
            scroll="body"
          >
            <DialogTitle>
              {userPhoneNumber ? "Update Phone Number" : "Add Phone Number"}
            </DialogTitle>
            <DialogContent>
              <Form>
                {errors.global && <AlertError message={errors.global} />}
                <FieldWrapper>
                  <TextField
                    label="Label"
                    id="label"
                    name="label"
                    onChange={handleChange}
                    value={values.label}
                    placeholder="Label"
                    fullWidth
                    helperText={errors.label}
                    error={!!errors.label}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    label="Phone Number"
                    id="phoneNumber"
                    name="phoneNumber"
                    onChange={handleChange}
                    value={values.phoneNumber}
                    placeholder="Phone Number"
                    fullWidth
                    helperText={errors.phoneNumber}
                    error={!!errors.phoneNumber}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <FormControlLabel
                    label="Is Primary Phone Number"
                    control={
                      <Checkbox
                        onChange={handleChange}
                        checked={values.isPrimary}
                      />
                    }
                    id="isPrimary"
                    name="isPrimary"
                  />
                </FieldWrapper>
              </Form>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} disabled={isSubmitting}>
                Cancel
              </Button>
              <Button
                onClick={() => handleSubmit()}
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                {isSubmitting && <Loader />}
                {userPhoneNumber ? "Update Phone Number" : "Add Phone Number"}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      />
    );
  }
}

export { UserPhoneNumberDialog };
