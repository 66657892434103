import React from "react";
import PropTypes from "prop-types";
import { Mutation } from "react-apollo";

import UPDATE_CUSTOMER_PRODUCT_MUTATION from "./update-customer-product-mutation";
import Workspace from "./workspace";

const CustomerSkuAssignmentContainer = props => (
  <Mutation mutation={UPDATE_CUSTOMER_PRODUCT_MUTATION}>
    {updateCustomerProductMutation => (
      <Workspace
        {...props}
        updateCustomerProductMutation={updateCustomerProductMutation}
      />
    )}
  </Mutation>
);

CustomerSkuAssignmentContainer.propTypes = {
  task: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onUnassign: PropTypes.func.isRequired
};

export default CustomerSkuAssignmentContainer;
