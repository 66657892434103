import React, { Component, Fragment } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { withSnackbar, InjectedNotistackProps } from "notistack";

/* Material UI */
import {
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Divider
} from "@material-ui/core";

/* Fontawesome */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTasks } from "@fortawesome/free-solid-svg-icons";
import IdleTimer from "react-idle-timer";

/* GraphQL & Apollo Imports */
import { MutationFn } from "react-apollo";
import { ApolloQueryResult } from "apollo-client";
import {
  TaskStatusEnum,
  SelectTaskTask,
  SelectTaskViewer,
  UpdateTaskMutation,
  UpdateTaskVariables,
  RecordTaskWorkMutation,
  RecordTaskWorkVariables,
  SelectTaskVariables,
  SelectTaskQuery
} from "../../../generated/graphql";

/* Utilities & Contants */
import { transformGraphQLErrorForFormik } from "../../../utilities/form-helpers";
import { ROLES } from "../../../constants/roles";

/* Common Components */
import PageLayout from "../../common/page-layout";
import Loader from "../../common/loader";
import AlertError from "../../common/styled/alert-error";

/* Child Components */
import TaskNotFound from "../task-not-found";
import NotAssignedToMe from "../not-assigned-to-me";
import Unassigned from "../unassigned";
import TaskDetails from "../task-details";
import SkuDetails from "../sku-details";
import IdleModal from "../idle-modal";
import TaskNotesModalContainer from "../../task-notes-modal";
import TaskCompleted from "../task-completed";
import BatchedProducstMenu from "./batched-products-menu";
import TaskCard from "./task.card";

interface Props extends RouteComponentProps, InjectedNotistackProps {
  customerId: number;
  task: SelectTaskTask;
  viewer: SelectTaskViewer;
  updateTaskMutation: MutationFn<UpdateTaskMutation, UpdateTaskVariables>;
  recordTaskWorkMutation: MutationFn<
    RecordTaskWorkMutation,
    RecordTaskWorkVariables
  >;
  refetchTask: (
    variables?: SelectTaskVariables | undefined
  ) => Promise<ApolloQueryResult<SelectTaskQuery>>;
  loading: boolean;
}

interface State {
  error: string | null;
  unrecordedSeconds: number;
  unrecordedIdleSeconds: number;
  isIdle: boolean;
  isNotesModalVisible: boolean;
}

export interface ChildWorkspaceCommonProps {
  task: SelectTaskTask;
  onClose: () => Promise<void>;
  onComplete: (isApproved?: boolean, approvalComment?: string) => Promise<void>;
  onUnassign: () => Promise<void>;
  refetchTask: (
    variables?: SelectTaskVariables | undefined
  ) => Promise<ApolloQueryResult<SelectTaskQuery>>;
  additionalPanels?: React.ReactElement<any>[];
  instructionComp?: React.ReactElement<any>;
}

class Workspace extends Component<Props, State> {
  state: State = {
    error: null,
    unrecordedSeconds: 0,
    unrecordedIdleSeconds: 0,
    isIdle: false,
    isNotesModalVisible: false
  };

  timerInterval: NodeJS.Timeout | null = null;

  idleTimerInterval: NodeJS.Timeout | null = null;

  componentDidMount() {
    if (!this.timerInterval) {
      this.timerInterval = setInterval(this.incrementTime, 1000);
    }
  }

  componentWillUnmount() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
    if (this.idleTimerInterval) {
      clearInterval(this.idleTimerInterval);
    }
  }

  incrementIdleTime = () => {
    this.setState(prevState => ({
      unrecordedIdleSeconds: prevState.unrecordedIdleSeconds + 1
    }));
  };

  incrementTime = () => {
    const { isIdle } = this.state;
    if (!isIdle) {
      this.setState(prevState => ({
        unrecordedSeconds: prevState.unrecordedSeconds + 1
      }));
    }
  };

  onAssignToMe = async () => {
    const {
      task,
      viewer,
      updateTaskMutation,
      refetchTask,
      enqueueSnackbar
    } = this.props;
    try {
      await updateTaskMutation({
        variables: { input: { taskId: task.id, assignedUserId: viewer.id } }
      });
      await refetchTask();
    } catch (e) {
      const error = transformGraphQLErrorForFormik(e);
      if (error.global) {
        enqueueSnackbar(error.global, { variant: "error" });
      } else {
        enqueueSnackbar("Unable to assign this task.", { variant: "error" });
      }
    }
  };

  onAssignToUser = async (userId: number | null) => {
    const {
      task,
      updateTaskMutation,
      refetchTask,
      enqueueSnackbar
    } = this.props;
    try {
      await updateTaskMutation({
        variables: { input: { taskId: task.id, assignedUserId: userId } }
      });
      await refetchTask();
    } catch (e) {
      const error = transformGraphQLErrorForFormik(e);
      if (error.global) {
        enqueueSnackbar(error.global, { variant: "error" });
      } else {
        enqueueSnackbar("Unable to assign this task.", { variant: "error" });
      }
    }
  };

  onUnassign = async () => {
    const { task, updateTaskMutation, enqueueSnackbar } = this.props;
    try {
      await updateTaskMutation({
        variables: { input: { taskId: task.id, assignedUserId: null } }
      });
    } catch (e) {
      const error = transformGraphQLErrorForFormik(e);
      if (error.global) {
        enqueueSnackbar(error.global, { variant: "error" });
      } else {
        enqueueSnackbar("Unable to unassign this task.", { variant: "error" });
      }
    }
  };

  onComplete = async (isApproved?: boolean, approvalComment?: string) => {
    const { task, updateTaskMutation, enqueueSnackbar } = this.props;
    if (task.statusId !== TaskStatusEnum.Assigned) {
      await this.onClose();
      return;
    }
    try {
      await updateTaskMutation({
        variables: {
          input: {
            taskId: task.id,
            statusId: "completed",
            isApproved,
            approvalComment
          }
        }
      });
      await this.onClose();
      enqueueSnackbar(`${task.title} was completed successfully!`, {
        variant: "success"
      });
    } catch (e) {
      if (e.graphQLErrors && e.graphQLErrors.length > 0) {
        this.setState({ error: e.graphQLErrors[0].message });
        return;
      }
      console.log("Error", JSON.stringify(e, null, 2));
      this.setState({ error: "Something went wrong!" });
    }
  };

  onIdle = () => {
    this.setState({ isIdle: true, unrecordedIdleSeconds: 0 });
    if (!this.idleTimerInterval) {
      this.idleTimerInterval = setInterval(this.incrementIdleTime, 1000);
    }
  };

  onStillWorking = () => {
    const { unrecordedSeconds, unrecordedIdleSeconds } = this.state;
    this.setState({
      isIdle: false,
      unrecordedSeconds: unrecordedSeconds + unrecordedIdleSeconds,
      unrecordedIdleSeconds: 0
    });
  };

  onNotWorking = async () => {
    await this.onClose();
  };

  onClose = async () => {
    const {
      history: { goBack, push },
      recordTaskWorkMutation,
      task,
      enqueueSnackbar,
      refetchTask
    } = this.props;
    const { unrecordedSeconds } = this.state;
    if (
      unrecordedSeconds > 0 &&
      task.statusId !== TaskStatusEnum.Completed &&
      task.statusId !== TaskStatusEnum.Cancelled
    ) {
      try {
        await recordTaskWorkMutation({
          variables: {
            input: { taskId: task.id, secondsRecorded: unrecordedSeconds }
          }
        });
        refetchTask();
      } catch (e) {
        const error = transformGraphQLErrorForFormik(e);
        if (error.global) {
          enqueueSnackbar(error.global, { variant: "error" });
        } else {
          enqueueSnackbar("There was an error recording task work.", {
            variant: "error"
          });
        }
      }
    }
    if (task.customerProductBatch) {
      push(`/batches/${task.customerProductBatch.id}`);
    } else {
      goBack();
    }
  };

  showTaskNotes = () => {
    this.setState({ isNotesModalVisible: true });
  };

  closeTaskNotes = () => {
    this.setState({ isNotesModalVisible: false });
  };

  renderGlobalError = () => {
    const { error } = this.state;
    if (!error) {
      return null;
    }
    return <AlertError message={error} />;
  };

  render() {
    const {
      loading,
      task,
      viewer,
      history: { goBack },
      refetchTask,
      customerId
    } = this.props;

    const { isIdle, isNotesModalVisible } = this.state;
    const isCustomerProductBatch = task.customerProductBatch;
    const commonProps: ChildWorkspaceCommonProps = {
      task,
      onClose: this.onClose,
      onComplete: this.onComplete,
      onUnassign: this.onUnassign,
      refetchTask
    };

    if (loading) {
      return (
        <PageLayout pageTitle="Task Workspace">
          <Loader />
        </PageLayout>
      );
    }
    if (!task) {
      return <TaskNotFound goBack={goBack} />;
    }
    if (task.statusId === "completed") {
      return (
        <TaskCompleted
          customerId={customerId}
          task={task}
          showTaskNotes={this.showTaskNotes}
          closeTaskNotes={this.closeTaskNotes}
          onStillWorking={this.onStillWorking}
          onNotWorking={this.onNotWorking}
          isNotesModalVisible={isNotesModalVisible}
          onClose={this.onClose}
        />
      );
    }
    if (!task.assignedUser) {
      return (
        <Unassigned
          roles={[ROLES.EMPLOYEE]}
          goBack={goBack}
          task={task}
          onAssignToMe={this.onAssignToMe}
          onAssignToUser={this.onAssignToUser}
        />
      );
    }
    if (task.assignedUser.id !== viewer.id) {
      return (
        <NotAssignedToMe
          goBack={goBack}
          task={task}
          onAssignToMe={this.onAssignToMe}
          onAssignToUser={this.onAssignToUser}
        />
      );
    }

    return (
      <IdleTimer onIdle={this.onIdle} timeout={1000 * 60 * 30}>
        {isIdle && (
          <IdleModal
            visible={isIdle}
            onStillWorking={this.onStillWorking}
            onNotWorking={this.onNotWorking}
          />
        )}
        {isNotesModalVisible && (
          <TaskNotesModalContainer
            customerId={customerId}
            taskId={task.id}
            onCancel={this.closeTaskNotes}
            allowNewNotes={
              task.statusId !== TaskStatusEnum.Cancelled &&
              task.statusId !== TaskStatusEnum.Completed
            }
          />
        )}
        <PageLayout pageTitle="Task Workspace">
          <Grid container spacing={isCustomerProductBatch ? 40 : 16}>
            <Grid item lg={isCustomerProductBatch ? 3 : 7}>
              {!isCustomerProductBatch ? (
                <TaskCard
                  task={task}
                  commonProps={commonProps}
                  showTaskNotes={this.showTaskNotes}
                  renderGlobalError={this.renderGlobalError}
                />
              ) : (
                <BatchedProducstMenu task={task} />
              )}
            </Grid>

            <Grid item lg={isCustomerProductBatch ? 9 : 5}>
              {isCustomerProductBatch && (
                <TaskCard
                  task={task}
                  commonProps={commonProps}
                  showTaskNotes={this.showTaskNotes}
                  renderGlobalError={this.renderGlobalError}
                />
              )}
              <ExpansionPanel defaultExpanded>
                <ExpansionPanelSummary>
                  <span>
                    <FontAwesomeIcon
                      icon={faTasks}
                      style={{ marginRight: ".5em" }}
                    />
                    Task Information
                  </span>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <TaskDetails task={task} />
                  {task.customerProduct && task.customerProduct.customerSku && (
                    <Fragment>
                      <Divider />
                      <SkuDetails
                        customerSku={task.customerProduct.customerSku}
                      />
                    </Fragment>
                  )}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
          </Grid>
        </PageLayout>
      </IdleTimer>
    );
  }
}

export default withRouter(withSnackbar(Workspace));
