import React, { Component } from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import amplitude from "amplitude-js";

import VIEWER_QUERY from "./viewer-query";
import Loader from "../../common/loader";
import ErrorDisplay from "../../common/error-display";

class AmplitudeProvider extends Component {
  isInitialized = false;

  render() {
    const { children } = this.props;
    return (
      <Query query={VIEWER_QUERY}>
        {({ data, loading, error }) => {
          if (loading) {
            return <Loader />;
          }
          if (error) {
            return <ErrorDisplay error={error} />;
          }

          if (!this.isInitialized) {
            amplitude
              .getInstance()
              .init(process.env.REACT_APP_AMPLITUDE_KEY, data.viewer.id);
            const identify = new amplitude.Identify()
              .set("email", data.viewer.email)
              .set("name", data.viewer.fullName);
            amplitude.getInstance().identify(identify);
            this.isInitialized = true;
          }
          return children;
        }}
      </Query>
    );
  }
}

AmplitudeProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AmplitudeProvider;
