import React from "react";
import PropTypes from "prop-types";
import { Paper, colors } from "@material-ui/core";
import styled from "styled-components";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Text from "./text";

const StyledPaper = styled(Paper)`
  && {
    background-color: ${colors.lightBlue[500]};
    color: white;
    padding: 1em 2em;
    margin-bottom: 1em;
  }
`;

const IconContainer = styled.div`
  font-size: 2em;
  margin-right: 0.5em;
`;

const Alert = ({ message }) => (
  <StyledPaper>
    <div style={{ display: "flex", alignItems: "center" }}>
      <IconContainer>
        <FontAwesomeIcon icon={faInfoCircle} />
      </IconContainer>
      <Text>{message}</Text>
    </div>
  </StyledPaper>
);

Alert.propTypes = {
  message: PropTypes.string.isRequired
};

export default Alert;
