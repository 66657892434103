import gql from "graphql-tag";

export default gql`
  mutation addContentCreateCustomerProduct(
    $input: CreateCustomerProductInput!
  ) {
    createCustomerProduct(input: $input) {
      customerProduct {
        id
      }
    }
  }
`;
