import React, { Component } from "react";
import { Mutation, Query } from "react-apollo";

import ADD_CONTENT_QUERY from "./add-content-query";
import CREATE_CUSTOMER_PRODUCT_MUTATION from "./create-customer-product-mutation";
import CREATE_CUSTOMER_PRODUCT_BATCH_MUTATION from "./create-customer-product-batch-mutation";
import AddContentDialog from "./add-content-dialog";
import Loader from "../../../common/loader";

class AddBatchDialogContainer extends Component {
  state = {};

  render() {
    const { enqueueSnackbar, customer } = this.props;

    return (
      <Query query={ADD_CONTENT_QUERY} variables={{ customerId: customer.id }}>
        {({ data, loading, error }) => {
          if (loading) {
            return <Loader />;
          }
          if (error) {
            enqueueSnackbar(
              "Something went wrong while attempting to add content."
            );
            return null;
          }
          return (
            <Mutation mutation={CREATE_CUSTOMER_PRODUCT_MUTATION}>
              {createCustomerProductMutation => (
                <Mutation mutation={CREATE_CUSTOMER_PRODUCT_BATCH_MUTATION}>
                  {createCustomerProductBatchMutation => (
                    <AddContentDialog
                      topLevelCustomerSkus={data.customer.customerSkus.items}
                      productCategories={data.productCategories.items}
                      createCustomerProductMutation={
                        createCustomerProductMutation
                      }
                      createCustomerProductBatchMutation={
                        createCustomerProductBatchMutation
                      }
                      {...this.props}
                    />
                  )}
                </Mutation>
              )}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

export default AddBatchDialogContainer;
