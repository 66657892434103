import React from "react";
import PropTypes from "prop-types";
import { List } from "@material-ui/core";
import EditableDisplayField from "../../common/editable-display-field";
import { StandardCard } from "../../common/standard-card";

const Details = ({ user }) => (
  <StandardCard title={user.fullName}>
    <List dense>
      <EditableDisplayField label="First Name" value={user.firstName} />
      <EditableDisplayField label="Last Name" value={user.lastName} />
      <EditableDisplayField label="Email" value={user.email} />
      <EditableDisplayField
        label="Additional Emails"
        value={user.emailAliases}
      />
    </List>
  </StandardCard>
);

Details.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    emailAliases: PropTypes.string
  }).isRequired
};

export default Details;
