import gql from "graphql-tag";

export default gql`
  mutation createCustomerProject($input: CreateCustomerProjectInput!) {
    createCustomerProject(input: $input) {
      customerProject {
        id
      }
    }
  }
`;
