import React, { FunctionComponent } from "react";
import { withSnackbar, InjectedNotistackProps } from "notistack";

/* GraphQL & Apollo Imports */
import { MutationFn } from "react-apollo";

/* Utilities */

/* FontAwesome */
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as farStar } from "@fortawesome/free-regular-svg-icons";

/* Components */
import { IconButton } from "@material-ui/core";
import AppIcon from "../app-icon";
import { transformGraphQLErrorForFormik } from "../../../utilities/form-helpers";
import {
  Maybe,
  CreateCustomerStarMutation,
  CreateCustomerStarVariables,
  DeleteCustomerStarMutation,
  DeleteCustomerStarVariables
} from "../../../generated/graphql";

interface Props extends InjectedNotistackProps {
  isStarred: Maybe<boolean>;
  customerId: number;
  refetchData: () => any | null;
  createCustomerStarMutation: MutationFn<
    CreateCustomerStarMutation,
    CreateCustomerStarVariables
  >;
  deleteCustomerStarMutation: MutationFn<
    DeleteCustomerStarMutation,
    DeleteCustomerStarVariables
  >;
}

const StarButton: FunctionComponent<Props> = ({
  isStarred,
  customerId,
  createCustomerStarMutation,
  deleteCustomerStarMutation,
  refetchData,
  enqueueSnackbar
}) => {
  const onDeleteCustomerStar = async () => {
    if (isStarred) {
      try {
        await deleteCustomerStarMutation({
          variables: { input: { customerId } }
        });
        refetchData();
      } catch (e) {
        const error = transformGraphQLErrorForFormik(e);
        if (error.global) {
          enqueueSnackbar(error.global, { variant: "error" });
        } else {
          enqueueSnackbar("There was an error deleting this customer star.", {
            variant: "error"
          });
        }
      }
    }
  };

  const onStarCustomer = async () => {
    // Only create customer star if isStarred is false
    if (!isStarred) {
      try {
        await createCustomerStarMutation({
          variables: { input: { customerId } }
        });
        refetchData(); // Refetch customer data to get updated isStarred value
      } catch (e) {
        const error = transformGraphQLErrorForFormik(e);
        if (error.global) {
          enqueueSnackbar(error.global, { variant: "error" });
        } else {
          enqueueSnackbar("There was an error starring this customer.", {
            variant: "error"
          });
        }
      }
    }
  };

  return (
    <IconButton
      onClick={isStarred ? onDeleteCustomerStar : onStarCustomer}
      style={{ color: isStarred ? "gold" : "inherit", marginRight: "0.5rem" }}
    >
      <AppIcon icon={isStarred ? faStar : farStar} />
    </IconButton>
  );
};

export default withSnackbar(StarButton);
