import React, { Component } from "react";
import { Query } from "react-apollo";
import { has } from "lodash";

import SELECT_USERS_QUERY from "./select-users-query";
import Display from "./display";
import ErrorDisplay from "../common/error-display";

class UsersContainer extends Component {
  state = {
    search: ""
  };

  onSearchChanged = newSearchValue => {
    this.setState({ search: newSearchValue });
  };

  render() {
    return (
      <Query
        query={SELECT_USERS_QUERY}
        variables={{ ...this.state, limit: 1000 }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (error) {
            return <ErrorDisplay error={error} />;
          }
          return (
            <Display
              {...this.props}
              users={has(data, "users.items") ? data.users.items : []}
              totalUsers={
                has(data, "users.totalCount") ? data.users.totalCount : 0
              }
              isLoading={loading}
              onSearchChanged={this.onSearchChanged}
            />
          );
        }}
      </Query>
    );
  }
}

export default UsersContainer;
