import React, { Component } from "react";

/* Child Components */
import ProductDialog from "./product-dialog";

/* GraphQL Imports */
import {
  UpdateCustomerProductComponent,
  SelectRoadmap_CustomerProducts
} from "../../../../generated/graphql";

interface Props {
  customerProduct: SelectRoadmap_CustomerProducts;
  onProductUpdated: () => void;
  onCancel: () => any;
}
interface State {}

class ProductDialogContainer extends Component<Props, State> {
  state = {};

  render() {
    const { onProductUpdated } = this.props;
    return (
      <UpdateCustomerProductComponent>
        {updateCustomerProductMutation => (
          <ProductDialog
            {...this.props}
            updateCustomerProductMutation={updateCustomerProductMutation}
            onProductUpdated={onProductUpdated}
          />
        )}
      </UpdateCustomerProductComponent>
    );
  }
}

export default ProductDialogContainer;
