import React, { Component, Fragment } from "react";
import amplitude from "amplitude-js";
import styled from "styled-components";
import { debounce } from "lodash";

/* Material UI  & FontAwesome */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import {
  Table,
  TableHead,
  TableBody,
  Grid,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  LinearProgress
} from "@material-ui/core";

/* Common Components */
import { FieldWrapper } from "../common/styled/field-wrapper";

/* Child Components */
import ServiceRow from "./service-row";

/* Contstants */
import { TRACKING_EVENTS } from "../../constants/tracking-events";
import { any } from "async";

const StyledTextField = styled(TextField)`
  label {
    width: 115%;
  }
` as typeof TextField;

interface State {
  serviceAmounts: any;
  retainerAmount: number;
  agreementLength: number;
  isCalculateAgreement: boolean;
}

interface Props {
  averageWeeklyAmHours: number;
  weeksPerMonth: number;
  managedAmountLimit: number;
  amHourlyRate: number;
  trueHourlyOverhead: number;
  desiredProfitMargin: number;
}

class RetainerBudget extends Component<Props, State> {
  initialState: State = {
    serviceAmounts: {},
    retainerAmount: 0,
    agreementLength: 0,
    isCalculateAgreement: false
  };

  state = {
    ...this.initialState
  };

  trackUsage = debounce(
    () => {
      amplitude
        .getInstance()
        .logEvent(TRACKING_EVENTS.UI_PRICING_TOOL_USED, this.state);
    },
    1000,
    { leading: false, trailing: true }
  );

  onAgreementLengthChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ agreementLength: Number(e.target.value) });
    this.trackUsage();
  };

  onServiceChange = (serviceName: string) => (
    quantity: number,
    additionalDiscount: number | null,
    amount: number
  ) => {
    const { serviceAmounts } = this.state;
    this.setState({
      serviceAmounts: {
        ...serviceAmounts,
        [serviceName]: { quantity, additionalDiscount, amount }
      }
    });
    this.trackUsage();
  };

  onRetainerAmountChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ retainerAmount: Number(e.target.value) });
    this.trackUsage();
  };

  onCalculateAgreementChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ isCalculateAgreement: e.target.checked });
    this.trackUsage();
  };

  onCustomPhotoAmountChanged = (e: React.ChangeEvent<any>) => {
    const { serviceAmounts } = this.state;
    this.setState({
      serviceAmounts: {
        ...serviceAmounts,
        customPhotoAmount: { amount: e.target.value * 1.0 }
      }
    });
    this.trackUsage();
  };

  onReset = () => {
    this.setState({ ...this.initialState });
  };

  render() {
    const {
      averageWeeklyAmHours,
      weeksPerMonth,
      managedAmountLimit,
      amHourlyRate,
      trueHourlyOverhead,
      desiredProfitMargin
    } = this.props;
    const {
      retainerAmount,
      serviceAmounts,
      isCalculateAgreement,
      agreementLength
    } = this.state;

    const totalAmount = Object.keys(serviceAmounts)
      .map(key =>
        serviceAmounts[key] && serviceAmounts[key].amount
          ? serviceAmounts[key].amount
          : 0
      )
      .reduce((total, amount) => total + amount, 0);
    const monthlyAMHours = Math.floor(
      (averageWeeklyAmHours / (managedAmountLimit / retainerAmount)) *
        weeksPerMonth
    );
    const weeklyAMHours = (monthlyAMHours / weeksPerMonth).toFixed(1);
    const agreementAmount = retainerAmount * agreementLength;
    const amCost =
      monthlyAMHours *
      (amHourlyRate + trueHourlyOverhead) *
      (1 + desiredProfitMargin);
    let remainingAmount = 0;
    let percentUsed = 0;
    if (isCalculateAgreement) {
      remainingAmount =
        agreementAmount - totalAmount - amCost * agreementLength;
      percentUsed =
        ((agreementAmount - remainingAmount) / agreementAmount) * 100;
    } else {
      remainingAmount = retainerAmount - totalAmount - amCost;
      percentUsed = ((retainerAmount - remainingAmount) / retainerAmount) * 100;
    }

    const services = [
      {
        name: "Content (Full)",
        price: 190,
        maximumDiscount: 31.52
      },
      { name: "Child Exact", price: 10, maximumDiscount: 56.99 },
      { name: "Child Recycled ", price: 28, maximumDiscount: 32.79 },
      {
        name: "Infographics (1 graphic)",
        price: 149,
        maximumDiscount: 32.63
      },
      {
        name: "Studio Image (1 image)",
        price: 60,
        maximumDiscount: 0.0
      },
    ];

    return (
      <Fragment>
        <div style={{ marginBottom: "2em" }}>
          <Button variant="contained" color="primary" onClick={this.onReset}>
            <FontAwesomeIcon icon={faSync} style={{ marginRight: ".5em" }} />
            Reset
          </Button>
        </div>
        <Grid container spacing={16} style={{ marginBottom: "1em" }}>
          <Grid item xs={2}>
            <FieldWrapper>
              <TextField
                label="Retainer Amount"
                value={retainerAmount}
                onChange={this.onRetainerAmountChanged}
                autoFocus
                fullWidth
              />
            </FieldWrapper>
          </Grid>
          <Grid item xs={2}>
            <FieldWrapper>
              <TextField
                label="Agreement Length"
                value={agreementLength}
                onChange={this.onAgreementLengthChanged}
                fullWidth
              />
            </FieldWrapper>
          </Grid>
          <Grid item xs={2}>
            <FieldWrapper>
              <FormControlLabel
                label="Calculate Agreement"
                control={
                  <Switch
                    checked={isCalculateAgreement}
                    onChange={this.onCalculateAgreementChanged}
                  />
                }
              />
            </FieldWrapper>
          </Grid>
          <Grid item xs={2}>
            <FieldWrapper>
              <StyledTextField
                label={
                  isCalculateAgreement
                    ? "Remaining Agreement Budget"
                    : "Remaining Budget for Month"
                }
                value={`$${remainingAmount.toFixed(2)}`}
                variant="filled"
                disabled
                fullWidth
              />
              <LinearProgress
                variant="determinate"
                value={percentUsed}
                color={percentUsed >= 100 ? "secondary" : "primary"}
              />
            </FieldWrapper>
          </Grid>
          <Grid item xs={2}>
            <FieldWrapper>
              <TextField
                label="Total Services"
                value={`$${totalAmount.toFixed(2)}`}
                variant="filled"
                disabled
                fullWidth
              />
            </FieldWrapper>
          </Grid>
          <Grid item xs={2}>
            <FieldWrapper>
              <TextField
                label="AM Hours"
                value={`${monthlyAMHours} (${weeklyAMHours} per week)`}
                variant="filled"
                disabled
                fullWidth
              />
            </FieldWrapper>
          </Grid>
        </Grid>
        <Table>
          <TableHead>
            <ServiceRow isHeaderRow />
          </TableHead>
          <TableBody>
            {services.map(service => {
              const serviceKey = service.name.replace(/\W/g, "");
              return (
                <ServiceRow
                  key={serviceKey}
                  name={service.name}
                  nonClientAmount={service.price}
                  standardClientDiscount={10}
                  maximumAdditionalDiscount={service.maximumDiscount}
                  onChange={this.onServiceChange(serviceKey)}
                  quantity={
                    serviceAmounts[serviceKey]
                      ? serviceAmounts[serviceKey].quantity
                      : null
                  }
                  additionalDiscount={
                    serviceAmounts[serviceKey]
                      ? serviceAmounts[serviceKey].additionalDiscount
                      : null
                  }
                />
              );
            })}
          </TableBody>
        </Table>

        <Grid container style={{ marginTop: "2em" }}>
          <Grid item xs={12} lg={3}>
            <FieldWrapper>
              <TextField
                label="Input $ from Photo Calculator"
                variant="outlined"
                value={
                  serviceAmounts.customPhotoAmount
                    ? serviceAmounts.customPhotoAmount.amount
                    : 0
                }
                onChange={this.onCustomPhotoAmountChanged}
                fullWidth
              />
            </FieldWrapper>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default RetainerBudget;
