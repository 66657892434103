import React, { Component } from "react";

import ProjectDialog from "./project-dialog";
import {
  UpdateCustomerProjectComponent,
  SelectRoadmapCustomerProjects,
  DeleteCustomerProjectComponent,
  SelectRoadmapVariables,
  SelectRoadmapQuery
} from "../../../../generated/graphql";
import { ApolloQueryResult } from "apollo-client";

interface Props {
  customerProject: SelectRoadmapCustomerProjects;
  onCancel: () => any;
  onProjectUpdated: () => any;
  refetchCustomerProjects: (
    variables?: SelectRoadmapVariables | undefined
  ) => Promise<ApolloQueryResult<SelectRoadmapQuery>>;
}
interface State {}

class ProjectDialogContainer extends Component<Props, State> {
  state = {};

  render() {
    return (
      <DeleteCustomerProjectComponent>
        {deleteCustomerProjectMutation => (
          <UpdateCustomerProjectComponent>
            {updateCustomerProjectMutation => (
              <ProjectDialog
                {...this.props}
                updateCustomerProjectMutation={updateCustomerProjectMutation}
                deleteCustomerProjectMutation={deleteCustomerProjectMutation}
              />
            )}
          </UpdateCustomerProjectComponent>
        )}
      </DeleteCustomerProjectComponent>
    );
  }
}

export default ProjectDialogContainer;
