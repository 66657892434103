import React, { Component, FunctionComponent, useState } from "react";

import { withSnackbar, InjectedNotistackProps } from "notistack";
import { ApolloQueryResult } from "apollo-client";
import ProductNotesDialog from "./product-notes-dialog";
import {
  CreateNoteComponent,
  SelectRoadmapVariables,
  SelectRoadmapQuery,
  RoadmapCustomerProductNotesComponent
} from "../../../../generated/graphql";

interface CustomerProduct {
  id: number;
}

interface Props extends InjectedNotistackProps {
  customerId: number;
  customerProduct: CustomerProduct;
  onCancel: () => void;
  refreshRoadmap: (
    variables?: SelectRoadmapVariables | undefined
  ) => Promise<ApolloQueryResult<SelectRoadmapQuery>>;
}

const ProductNotesDialogContainer: FunctionComponent<Props> = (
  props: Props
) => {
  const { customerProduct, customerId } = props;

  const [limit, setLimit] = useState<number>(20);
  const [offset, setOffset] = useState<number>(0);

  const onNextPage = () => {
    setOffset(offset + limit);
  };

  const onPreviousPage = () => {
    if (offset <= limit) {
      setOffset(0);
    } else {
      setOffset(offset - limit);
    }
  };

  return (
    <RoadmapCustomerProductNotesComponent
      variables={{ customerProductId: customerProduct.id, limit, offset }}
    >
      {({ data, loading, error, refetch }) => {
        if (loading) {
          return null;
        }
        const { enqueueSnackbar } = props;
        if (error) {
          enqueueSnackbar("Something went wrong trying to load notes.");
          return null;
        }
        if (
          !data ||
          !data.customerProduct ||
          !data.customerProduct.notes ||
          !data.customerProduct.notes.items
        ) {
          enqueueSnackbar("Something went wrong trying to load notes.");
          return null;
        }
        const {
          customerProduct: {
            notes: { items, totalCount }
          }
        } = data;
        return (
          <CreateNoteComponent>
            {createNoteMutation => (
              <ProductNotesDialog
                customerId={customerId}
                notes={items}
                createNoteMutation={createNoteMutation}
                refetchNotes={refetch}
                onNextPage={onNextPage}
                onPreviousPage={onPreviousPage}
                count={totalCount || 0}
                limit={limit}
                offset={offset}
                {...props}
              />
            )}
          </CreateNoteComponent>
        );
      }}
    </RoadmapCustomerProductNotesComponent>
  );
};

export default withSnackbar(ProductNotesDialogContainer);
