import React from "react";
import PropTypes from "prop-types";
import { Query, Mutation } from "react-apollo";

import STRATEGY_QUERY from "./strategy-query";
import UPDATE_TASK_MUTATION from "./update-task-mutation";
import Workspace from "./workspace";
import Loader from "../../common/loader";
import ErrorDisplay from "../../common/error-display";

const StrategyTaskContainer = props => (
  <Mutation mutation={UPDATE_TASK_MUTATION}>
    {updateTaskMutation => (
      <Query query={STRATEGY_QUERY}>
        {({ data, loading, error, refetch }) => {
          if (loading) {
            return <Loader />;
          }
          if (error) {
            return <ErrorDisplay error={error} refetch={refetch} />;
          }
          return (
            <Workspace
              {...props}
              taskTypes={data.taskTypes}
              updateTaskMutation={updateTaskMutation}
            />
          );
        }}
      </Query>
    )}
  </Mutation>
);

StrategyTaskContainer.propTypes = {
  task: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired
};

export default StrategyTaskContainer;
