import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";

/* Material UI */
import {
  Grid,
  DialogContent,
  DialogTitle,
  Dialog,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Table,
  DialogActions,
  Button
} from "@material-ui/core";

/* Font Awesome */
import {
  faCalendar,
  faClock,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/* Common Components */
import GlobalAvatar from "../../../global-avatar";
import EditableDisplayField from "../../../common/editable-display-field";
import { TableHeaderCell } from "../../../common/table-header-cell";
import DangerButton from "../../../common/styled/danger-button";
import AppIcon from "../../../common/app-icon";
import {
  CustomerContractQuery,
  CustomerContractVariables,
  DeleteCustomerContractMutation,
  DeleteCustomerContractVariables
} from "../../../../generated/graphql";
import { QueryResult, MutationFn } from "react-apollo";
import Loader from "../../../common/loader";
import { withSnackbar, InjectedNotistackProps } from "notistack";
import ConfirmDeleteDialog from "../../../common/confirm-delete-dialog";
import { transformGraphQLErrorForFormik } from "../../../../utilities/form-helpers";

interface Props extends InjectedNotistackProps {
  queryResult: QueryResult<CustomerContractQuery, CustomerContractVariables>;
  customerContractId: number | null;
  deleteCustomerContractMutation: MutationFn<
    DeleteCustomerContractMutation,
    DeleteCustomerContractVariables
  >;
  onCancel: () => any;
}

interface State {
  isRemoveCustomerContract: boolean;
}

class ContractDialog extends Component<Props, State> {
  state = {
    isRemoveCustomerContract: false
  };

  onRemoveCustomerContract = () => {
    this.setState({ isRemoveCustomerContract: true });
  };

  onRemoveCustomerContractCancel = () => {
    this.setState({ isRemoveCustomerContract: false });
  };

  onRemoveCustomerContractConfirmed = async () => {
    const {
      queryResult,
      deleteCustomerContractMutation,
      customerContractId,
      enqueueSnackbar,
      onCancel
    } = this.props;

    if (!customerContractId) {
      return null;
    }

    try {
      await deleteCustomerContractMutation({
        variables: { input: { customerContractId } }
      });
      queryResult.refetch();
      this.setState({ isRemoveCustomerContract: false }, () => onCancel());
      enqueueSnackbar("The Customer Contract was removed successfully!", {
        variant: "success"
      });
    } catch (e) {
      const error = transformGraphQLErrorForFormik(e);
      if (error.global) {
        enqueueSnackbar(error.global, { variant: "error" });
      }
      this.setState({ isRemoveCustomerContract: false });
    }
  };

  render() {
    const { isRemoveCustomerContract } = this.state;
    const { queryResult, onCancel, enqueueSnackbar } = this.props;

    if (queryResult.loading) {
      return <Loader />;
    }

    if (queryResult.error) {
      enqueueSnackbar("Something went wrong loading the contract.");
      return null;
    }

    if (!queryResult.data || !queryResult.data.customerContract) {
      return null;
    }

    const { customerContract } = queryResult.data;

    return (
      <Dialog fullWidth open onClose={onCancel} maxWidth="md">
        <DialogTitle>Contract Details</DialogTitle>
        <DialogContent>
          {isRemoveCustomerContract && (
            <ConfirmDeleteDialog
              onConfirmDelete={this.onRemoveCustomerContractConfirmed}
              onDeleteCancel={this.onRemoveCustomerContractCancel}
              deleteMessage="Customer Contract"
            />
          )}
          <Grid container spacing={16}>
            <Grid item xs={12} lg={6}>
              <EditableDisplayField
                label="Created by"
                value={customerContract.salesUser.fullName}
                icon={<GlobalAvatar userId={customerContract.salesUser.id} />}
              />
              <EditableDisplayField
                label="Created Date"
                value={moment(customerContract.createdAt).format("M/D/YYYY")}
                icon={<FontAwesomeIcon icon={faCalendar} />}
              />
              <EditableDisplayField
                label="Start Date"
                value={moment(customerContract.startDate).format("M/D/YYYY")}
                icon={<FontAwesomeIcon icon={faClock} />}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeaderCell>Name</TableHeaderCell>
                    <TableHeaderCell>Start Date</TableHeaderCell>
                    <TableHeaderCell>Amount</TableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customerContract.customerProjects.map(customerProject => (
                    <TableRow key={customerProject.id}>
                      <TableCell>{customerProject.name}</TableCell>
                      <TableCell>
                        {moment(customerProject.startDate).format("M/D/YYYY")}
                      </TableCell>
                      <TableCell>${customerProject.amount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {customerContract.isDeletable && (
            <DangerButton
              variant="contained"
              onClick={this.onRemoveCustomerContract}
            >
              <AppIcon standardRightMargin icon={faTrash} />
              Remove Contract
            </DangerButton>
          )}
          <Button onClick={onCancel}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withSnackbar(ContractDialog);
