import gql from "graphql-tag";

export default gql`
  query selectUsers($search: String, $limit: Int!, $offset: Int) {
    users(search: $search, limit: $limit, offset: $offset) {
      totalCount
      pageInfo {
        hasNextPage
      }
      items {
        id
        fullName
        profileImageUrl
      }
    }
  }
`;
