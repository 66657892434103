import React from "react";
import { Dialog, DialogTitle, DialogActions, Button } from "@material-ui/core";

interface Props {
  onConfirmConvert: () => any;
  onConvertCancel: () => any;
}

const ConfirmConvertReminderDialog = (props: Props) => (
  <Dialog open onClose={props.onConvertCancel} maxWidth="md">
    <DialogTitle>
      Are you sure you want to convert this reminder to a task?
    </DialogTitle>
    <DialogActions>
      <Button
        variant="contained"
        size="large"
        color="primary"
        onClick={() => props.onConfirmConvert()}
      >
        Convert Reminder
      </Button>
      <Button size="large" onClick={props.onConvertCancel}>
        No
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmConvertReminderDialog;
