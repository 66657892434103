import React from "react";

/* Common Components */
import PageLayout from "../common/page-layout";

/* Child Components */
import ReportsList from "./reports-list";

const Reports = () => (
  <PageLayout pageTitle="Reports">
    <ReportsList />
  </PageLayout>
);

export default Reports;
