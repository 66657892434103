/* Authorization Roles */
export const ROLES = {
  SUPER_ADMIN: "super-admin",
  ADMIN: "admin",
  EMPLOYEE: "employee",
  MANAGER: "manager",
  CONTENT_WRITING: "content-writing",
  CONTENT_EDITING: "content-editing",
  GRAPHIC_DESIGN: "graphic-design",
  FINANCE: "finance",
  SALES: "sales",
  MARKETING: "marketing",
  PHOTOGRAPHY: "photography"
};
