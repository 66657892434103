import React, { FunctionComponent, useState, Fragment } from "react";
import { withSnackbar, InjectedNotistackProps } from "notistack";
const moment = require("moment");

/* Material UI & FontAwesome */
import {
  List,
  Typography,
  ListItem,
  Button,
  TextField,
  InputAdornment
} from "@material-ui/core";

/* GraphQL & Apollo Imports */
import {
  CustomerNotesNoteReminders,
  CreateNoteReminderVariables,
  CreateNoteReminderMutation,
  DeleteNoteReminderMutation,
  DeleteNoteReminderVariables,
  UpdateNoteReminderMutation,
  UpdateNoteReminderVariables,
  CreateStrategyTaskMutation,
  CreateStrategyTaskVariables,
  StrategyTasksVariables,
  StrategyTasksQuery
} from "../../../../generated/graphql";
import { MutationFn } from "react-apollo";
import { ApolloQueryResult } from "apollo-client";

/* Child Components */
import SetDueDateDialog from "./set-due-date-dialog";
import ConfirmConvertReminderDialog from "./confirm-convert-reminder-dialog";
import NoteReminder from "./note-reminder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import AppIcon from "../../app-icon";
import { faCircle, faSave } from "@fortawesome/free-regular-svg-icons";

export interface UpdateValues {
  content?: string;
  dueAt?: Date;
  completedAt?: Date;
}

export interface CompleteValues {
  noteReminderId: number;
  completedAt?: Date;
}

export interface ReminderToConvert {
  customerId: number;
  noteReminderId: number;
  title: string;
  dueDate?: Date;
}

interface Props extends InjectedNotistackProps {
  customerId: number;
  noteId: number;
  reminders: CustomerNotesNoteReminders[] | undefined;
  isAddReminder: boolean;
  createStrategyTaskMutation: MutationFn<
    CreateStrategyTaskMutation,
    CreateStrategyTaskVariables
  >;
  createNoteReminderMutation: MutationFn<
    CreateNoteReminderMutation,
    CreateNoteReminderVariables
  >;
  deleteNotReminderMutation: MutationFn<
    DeleteNoteReminderMutation,
    DeleteNoteReminderVariables
  >;
  updateNoteReminderMutation: MutationFn<
    UpdateNoteReminderMutation,
    UpdateNoteReminderVariables
  >;
  resetIsAddReminder: () => void;
  refetchNoteData?: () => void;
  strategyRefetch: (
    variables?: StrategyTasksVariables | undefined
  ) => Promise<ApolloQueryResult<StrategyTasksQuery>>;
}

const NoteRemindersList: FunctionComponent<Props> = ({
  customerId,
  noteId,
  reminders,
  resetIsAddReminder,
  createStrategyTaskMutation,
  createNoteReminderMutation,
  deleteNotReminderMutation,
  updateNoteReminderMutation,
  refetchNoteData,
  enqueueSnackbar,
  strategyRefetch
}) => {
  const [parentReminderContent, setParentReminderContent] = useState<string>(
    ""
  );
  const [isAddReminder, setIsAddReminder] = useState<boolean>(false);
  const [isSetDateDialogOpen, setIsSetDueDateDialogOpen] = useState<boolean>(
    false
  );
  const [
    reminderToUpdate,
    setReminderToUpdate
  ] = useState<CustomerNotesNoteReminders | null>(null);
  const [
    reminderToConvert,
    setReminderToConvert
  ] = useState<ReminderToConvert | null>(null);

  const getActiveRemindersCount = () => {
    let count = 0;
    if (reminders && reminders.length > 0) {
      count = reminders.filter(reminder => reminder.completedAt === null)
        .length;
    }
    return `${count} ACTIVE`;
  };

  const getCompletedRemindersCount = () => {
    let count = 0;
    if (reminders && reminders.length > 0) {
      count = reminders.filter(reminder => reminder.completedAt !== null)
        .length;
    }
    return `${count} COMPLETE`;
  };

  const onAddNewReminder = async () => {
    try {
      await createNoteReminderMutation({
        variables: { input: { noteId, content: parentReminderContent } }
      });
      if (refetchNoteData) {
        refetchNoteData();
      }
      resetIsAddReminder();
      setIsAddReminder(false);
      setParentReminderContent("");
      enqueueSnackbar("Your reminder was successfully added.", {
        variant: "success"
      });
    } catch (e) {
      enqueueSnackbar("There was an error adding the reminder.", {
        variant: "error"
      });
    }
  };

  const onUpdateNoteReminder = async (updateValues: UpdateValues) => {
    if (!reminderToUpdate) {
      enqueueSnackbar("There was an error updating the reminder.", {
        variant: "error"
      });
      return null;
    }
    try {
      await updateNoteReminderMutation({
        variables: {
          input: { noteReminderId: reminderToUpdate.id, ...updateValues }
        }
      });
      if (refetchNoteData) {
        refetchNoteData();
      }
      setParentReminderContent("");
      setReminderToUpdate(null);
      resetIsAddReminder();
      enqueueSnackbar("Your reminder was successfully updated.", {
        variant: "success"
      });
    } catch (e) {
      enqueueSnackbar("There was an error updating the reminder.", {
        variant: "error"
      });
    }
  };

  const onCompleteNoteReminder = async (completeValues: CompleteValues) => {
    try {
      await updateNoteReminderMutation({
        variables: {
          input: { ...completeValues }
        }
      });
      if (refetchNoteData) {
        refetchNoteData();
      }
      resetIsAddReminder();
      enqueueSnackbar(
        completeValues.completedAt
          ? "Your reminder is now completed."
          : "Your reminder is now active.",
        {
          variant: "success"
        }
      );
    } catch (e) {
      enqueueSnackbar("There was an error updating the reminders status.", {
        variant: "error"
      });
    }
  };

  const onDeleteNoteReminder = async (noteReminderId: number) => {
    try {
      await deleteNotReminderMutation({
        variables: { input: { noteReminderId } }
      });
      if (refetchNoteData) {
        refetchNoteData();
      }
      resetIsAddReminder();
      enqueueSnackbar("Your reminder was successfully deleted.", {
        variant: "success"
      });
    } catch (e) {
      enqueueSnackbar("There was an error deleting the reminder.", {
        variant: "error"
      });
    }
  };

  const onConfirmConvertReminder = async () => {
    if (reminderToConvert) {
      try {
        await createStrategyTaskMutation({
          variables: {
            input: {
              customerId: reminderToConvert.customerId,
              title: reminderToConvert.title,
              dueDate: reminderToConvert.dueDate
                ? moment(reminderToConvert.dueDate)
                    .utc()
                    .toDate()
                : null
            }
          }
        });
        await onDeleteNoteReminder(reminderToConvert.noteReminderId);
        if (refetchNoteData) {
          refetchNoteData();
        }
        if (strategyRefetch) {
          strategyRefetch();
        }
        setReminderToConvert(null);
        enqueueSnackbar("Your reminder was converted successfully.", {
          variant: "success"
        });
      } catch (e) {
        enqueueSnackbar("There was an error converting the reminder.", {
          variant: "error"
        });
      }
    }
  };

  const onConvertReminderCancel = () => {
    setReminderToConvert(null);
  };

  return (
    <Fragment>
      {isSetDateDialogOpen && (
        <SetDueDateDialog
          reminderToUpdate={reminderToUpdate}
          onUpdateNoteReminder={onUpdateNoteReminder}
          onCancel={setIsSetDueDateDialogOpen}
        />
      )}
      {reminderToConvert && (
        <ConfirmConvertReminderDialog
          onConfirmConvert={onConfirmConvertReminder}
          onConvertCancel={onConvertReminderCancel}
        />
      )}
      <div style={{ marginTop: ".5rem" }}>
        <Typography variant="subtitle1" style={{ fontWeight: "bold", borderTop: "solid 1px gray" }}>
          {`REMINDERS (${getActiveRemindersCount()}, ${getCompletedRemindersCount()})`}
        </Typography>
        <List style={{ padding: "0" }}>
          {reminders &&
            reminders.map(reminder => (
              <NoteReminder
                customerId={customerId}
                reminder={reminder}
                onCompleteNoteReminder={onCompleteNoteReminder}
                setReminderToUpdate={setReminderToUpdate}
                onUpdateNoteReminder={onUpdateNoteReminder}
                setReminderToConvert={setReminderToConvert}
                setIsSetDueDateDialogOpen={setIsSetDueDateDialogOpen}
                onDeleteNoteReminder={onDeleteNoteReminder}
                setParentReminderContent={setParentReminderContent}
              />
            ))}
          <ListItem
            style={{ marginBottom: ".275rem", padding: "0", color: "grey" }}
          >
            <Button
              size="small"
              style={{ minWidth: ".0625rem" }}
              onClick={() => setIsAddReminder(!isAddReminder)}
            >
              <FontAwesomeIcon icon={faCircle} color="grey" />
            </Button>
            {!isAddReminder ? (
              <Fragment>
                <Button
                  size="small"
                  onClick={() => setIsAddReminder(!isAddReminder)}
                  style={{ textTransform: "none" }}
                >
                  <Typography style={{ color: "grey" }}>
                    Add reminder
                  </Typography>
                </Button>
              </Fragment>
            ) : (
              <TextField
                value={parentReminderContent}
                placeholder="Add new reminder..."
                autoFocus={true}
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    onAddNewReminder();
                  }
                }}
                onChange={e => setParentReminderContent(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        size="small"
                        color="primary"
                        onClick={onAddNewReminder}
                        style={{ minWidth: ".0625rem" }}
                      >
                        <AppIcon icon={faSave} />
                      </Button>
                      <Button
                        size="small"
                        style={{ minWidth: ".0625rem", color: "red" }}
                        onClick={() => setIsAddReminder(!isAddReminder)}
                      >
                        <AppIcon icon={faTimes} />
                      </Button>
                    </InputAdornment>
                  )
                }}
              />
            )}
          </ListItem>
        </List>
      </div>
    </Fragment>
  );
};

export default withSnackbar(NoteRemindersList);
