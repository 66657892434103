import React from "react";
import { Dialog, DialogTitle, DialogActions, Button } from "@material-ui/core";
import DangerButton from "../common/styled/danger-button";

interface Props {
  onConfirmDeleteTeamUser: (teamUserId: number) => any;
  onDeleteTeamUserCancel: () => any;
  teamUserId: number;
}

const ConfirmDeleteTeamUserDialog = (props: Props) => (
  <Dialog open onClose={props.onDeleteTeamUserCancel} scroll="body">
    <DialogTitle>Are you sure you want to remove this team member?</DialogTitle>
    <DialogActions>
      <DangerButton
        variant="contained"
        size="large"
        onClick={() => props.onConfirmDeleteTeamUser(props.teamUserId)}
      >
        Yes
      </DangerButton>
      <Button size="large" onClick={props.onDeleteTeamUserCancel}>
        No
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmDeleteTeamUserDialog;
