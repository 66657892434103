import React from "react";

interface Props {
  value: string | null;
  maxLength: number;
}

const ConstrainedText = (props: Props) => {
  if (props.value && props.value.length > props.maxLength) {
    return (
      <span title={props.value}>
        {props.value.substring(0, props.maxLength)}...
      </span>
    );
  }
  return <span>{props.value}</span>;
};

export default ConstrainedText;
