import React from "react";
import { Dialog, DialogTitle, DialogActions, Button } from "@material-ui/core";

interface Props {
  onConfirmComplete: () => any;
  onCompleteCancel: () => any;
  completeMessage?: string;
  customMessage?: string;
}

/* ConfirmCompleteDialog is a reusable component used anywhere a complete action needs to be confirmed before completing the action */

const ConfirmCompleteDialog = (props: Props) => (
  <Dialog open onClose={props.onCompleteCancel} maxWidth="md">
    <DialogTitle>
      {props.customMessage
        ? props.customMessage
        : `Are you sure you want to complete this ${props.completeMessage}?`}
    </DialogTitle>
    <DialogActions>
      <Button
        variant="contained"
        size="large"
        color="primary"
        onClick={() => props.onConfirmComplete()}
      >
        Yes
      </Button>
      <Button size="large" onClick={props.onCompleteCancel}>
        No
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmCompleteDialog;
