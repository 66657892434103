import React, { Component } from "react";
import { has } from "lodash";

/* Child Components */
import SelectSkuModal from "./select-sku-modal";

/* Common Components */
import Loader from "../common/loader";
import ErrorDisplay from "../common/error-display";

/* Graphql Imports */
import {
  SearchCustomerSkusComponent,
  SearchCustomerSkusItems
} from "../../generated/graphql";

interface State {
  search: string;
  limit: number;
  offset: number;
}

interface Props {
  visible: boolean;
  customerId: number;
  onCancel: () => void;
  onCustomerSkuSelected: (
    selectedCustomerSku: number | SearchCustomerSkusItems
  ) => void;
  skuTypes?: string[];
  returnCustomerSkuObject?: boolean;
}

class SelectSkuModalContainer extends Component<Props, State> {
  state = {
    search: "",
    limit: 20,
    offset: 0
  };

  onSearchUpdated = (search: string) => {
    this.setState({ search });
  };

  onNextPage = () => {
    this.setState(prevState => ({
      offset: prevState.offset + prevState.limit
    }));
  };

  onPreviousPage = () => {
    const { offset, limit } = this.state;
    if (offset <= limit) {
      this.setState({ offset: 0 });
    } else {
      this.setState(prevState => ({
        offset: prevState.offset - prevState.limit
      }));
    }
  };

  render() {
    const {
      visible,
      onCancel,
      customerId,
      onCustomerSkuSelected,
      returnCustomerSkuObject,
      skuTypes
    } = this.props;
    if (!visible) {
      return null;
    }
    const { search, limit, offset } = this.state;
    return (
      <SearchCustomerSkusComponent
        variables={{ search, customerId, limit, offset, skuTypes }}
        fetchPolicy="network-only"
      >
        {({ data, variables, loading, error }) => {
          if (loading) {
            <Loader />;
          }

          if (error) {
            return <ErrorDisplay error={error} />;
          }

          if (!data || !data.customer) {
            return null;
          }
          return (
            <SelectSkuModal
              search={search}
              onCancel={onCancel}
              customerSkus={
                has(data, "customer.customerSkus.items")
                  ? data.customer.customerSkus.items
                  : []
              }
              onCustomerSkuSelected={onCustomerSkuSelected}
              onSearchUpdated={this.onSearchUpdated}
              returnCustomerSkuObject={returnCustomerSkuObject}
              totalCount={data.customer.customerSkus.totalCount}
              limit={variables.limit}
              offset={variables.offset}
              onNextPage={this.onNextPage}
              onPreviousPage={this.onPreviousPage}
            />
          );
        }}
      </SearchCustomerSkusComponent>
    );
  }
}

export default SelectSkuModalContainer;
