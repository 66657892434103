import React, { Component } from "react";
import PageLayout from "../common/page-layout";
import { StandardCard } from "../common/standard-card";
import Teams from "./teams";
import {
  SelectTeamsComponent,
  CreateTeamComponent,
  DeleteTeamComponent
} from "../../generated/graphql";

interface Props {}

interface State {}

class TeamsContainer extends Component<Props, State> {
  state = {};

  render() {
    return (
      <PageLayout pageTitle="Teams">
        <StandardCard title="Teams">
          <CreateTeamComponent>
            {createTeamMutation => (
              <DeleteTeamComponent>
                {deleteTeamMutation => (
                  <SelectTeamsComponent>
                    {queryResult => (
                      <Teams
                        queryResult={queryResult}
                        createTeamMutation={createTeamMutation}
                        deleteTeamMutation={deleteTeamMutation}
                      />
                    )}
                  </SelectTeamsComponent>
                )}
              </DeleteTeamComponent>
            )}
          </CreateTeamComponent>
        </StandardCard>
      </PageLayout>
    );
  }
}

export default TeamsContainer;
