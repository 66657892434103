import React from "react";

import Workspace from "./workspace";
import { ChildWorkspaceCommonProps } from "../task-workspace/workspace";

interface Props extends ChildWorkspaceCommonProps {}

const GenericTaskContainer = (props: Props) => <Workspace {...props} />;

export default GenericTaskContainer;
