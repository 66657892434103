import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import { StandardCard } from "../../common/standard-card";
import Text from "../../common/styled/text";

const Empty = () => (
  <StandardCard style={{ textAlign: "center" }}>
    <div style={{ fontSize: "2em" }}>
      <FontAwesomeIcon icon={faInfoCircle} />
    </div>
    <Text>No tasks were found.</Text>
  </StandardCard>
);

export default Empty;
