import React, { FunctionComponent, useState, Fragment } from "react";

/* Material UI & FontAwesome*/
import { Grid, Typography, Button, Tooltip } from "@material-ui/core";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

/* Child Components */
import CustomerNotes from "./notes";
import TasksContainer from "./tasks";

/* GraphQL & Apollo Imports */
import {
  GetCustomerCustomer,
  StrategyTasksQuery,
  StrategyTasksVariables,
  UpdateStrategyTaskMutation,
  UpdateStrategyTaskVariables,
  CreateStrategyTaskMutationVariables,
  CreateStrategyTaskMutationMutation
} from "../../../generated/graphql";
import { QueryResult, MutationFn } from "react-apollo";

/* Common Components */
import AppIcon from "../../common/app-icon";
import AddStrategyTaskDialog from "./add-strategy-task-dialog";

interface Props {
  customer: GetCustomerCustomer;
  strategyTaskResult: QueryResult<StrategyTasksQuery, StrategyTasksVariables>;
  updateStrategyTaskMutation: MutationFn<
    UpdateStrategyTaskMutation,
    UpdateStrategyTaskVariables
  >;
  createStrategyTaskMutation: MutationFn<
    CreateStrategyTaskMutationMutation,
    CreateStrategyTaskMutationVariables
  >;
}

const WorkspaceDisplay: FunctionComponent<Props> = ({
  customer,
  strategyTaskResult,
  updateStrategyTaskMutation,
  createStrategyTaskMutation
}) => {
  const [isAddStrategyTask, setIsAddStrategyTask] = useState<boolean>(false);

  const { data, refetch } = strategyTaskResult;

  let activeStrategyProductStatus = null;
  if (data && data.customer && data.customer.activeStrategyCustomerProduct) {
    activeStrategyProductStatus =
      data.customer.activeStrategyCustomerProduct.statusId;
  }

  return (
    <Fragment>
      {isAddStrategyTask &&
        data &&
        data.customer &&
        data.customer.activeStrategyCustomerProduct && (
          <AddStrategyTaskDialog
            createStrategyTaskMutation={createStrategyTaskMutation}
            setIsAddStrategyTask={setIsAddStrategyTask}
            refetchStrategyTasks={refetch}
            customerId={customer.id}
          />
        )}
      <Grid container spacing={16}>
        <Grid item lg={5} xs={12}>
          <Typography
            variant="h6"
            style={{ marginTop: "1rem", marginBottom: "1rem", color: "grey" }}
          >
            Strategy Tasks
          </Typography>

          <Tooltip title="An active Strategy product is required to create Strategy tasks.">
            <span>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                disabled={
                  !activeStrategyProductStatus ||
                  activeStrategyProductStatus === "completed" ||
                  activeStrategyProductStatus === "cancelled"
                }
                style={{ marginBottom: "1rem" }}
                onClick={() => setIsAddStrategyTask(true)}
              >
                <AppIcon standardRightMargin icon={faPlus} />
                Add Task
              </Button>
            </span>
          </Tooltip>

          <TasksContainer
            customer={customer}
            strategyTaskResult={strategyTaskResult}
            updateStrategyTaskMutation={updateStrategyTaskMutation}
          />
        </Grid>
        <Grid item lg={7} xs={12}>
          <Typography
            variant="h6"
            style={{ marginTop: "1rem", marginBottom: "1rem", color: "grey" }}
          >
            Notes
          </Typography>
          <CustomerNotes
            customer={customer}
            strategyRefetch={strategyTaskResult.refetch}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default WorkspaceDisplay;
