import React from "react";

import { Grid } from "@material-ui/core";
import PageLayout from "../common/page-layout";

const Help = () => (
  <PageLayout pageTitle="Help">


      <div>
        <h3>Dashboard</h3>
        <p>View your customers and tasks on the dashboard.</p>
      </div>

      <div>
        <h3>Customers</h3>
        <p>View your customers and search all customers here.</p>
        <ul>
          <li>Add tasks and notes</li>
          <li>Add customer contacts (users) and information.</li>
          <li>View contract details.</li>
          <li>View and pull SKUs from Amazon.</li>
          <li>Store Amazon credentials.</li>
          <li>Add files to a clients profile. (contracts, etc)</li>
        </ul>
      </div>

      <div>
        <h3>Billing</h3>
        <p>View customer bills according to their contract.</p>
      </div>

      <div>
        <h3>Users</h3>
        <p>View all users including Nozani and customer users.</p>
      </div>

      <div>
        <h3>Teams</h3>
        <p>View teams and their users.</p>
      </div>

      <div>
        <h3>Pricing Tool</h3>
        <p>Use this tool to price out contracts, upsells, etc.</p>
      </div>

      <div>
        <h3>Inventory</h3>
        <p>Used to add incoming inventory that we should expect. Eventually will be used to track its location.</p>
      </div>

      <div>
        <h3>Customers</h3>
        <p>View your customers and search all customers here.</p>
      </div>

      <div>
        <hr />
        <h3>Bugs or Suggestions?</h3>
        <p>Send a Slack message to our Technology team with as much information as possible.</p>
      </div>

  </PageLayout>
);

export default Help;
