import React, { Component, Fragment } from "react";
import { QueryResult, MutationFn } from "react-apollo";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button
} from "@material-ui/core";
import {
  faExternalLinkAlt,
  faBarcode
} from "@fortawesome/free-solid-svg-icons";

import {
  GetCustomerProductBatchQuery,
  GetCustomerProductBatchVariables,
  TaskStatusEnum,
  UpdateTaskInBatchMutation,
  UpdateTaskInBatchVariables
} from "../../generated/graphql";
import Loader from "../common/loader";
import { TableHeaderCell } from "../common/table-header-cell";
import AppLink from "../common/app-link";
import AppIcon from "../common/app-icon";
import GlobalAvatar from "../global-avatar";
import Text from "../common/styled/text";

interface Props {
  customerProductBatchQuery: QueryResult<
    GetCustomerProductBatchQuery,
    GetCustomerProductBatchVariables
  >;
  updateTaskMutation: MutationFn<
    UpdateTaskInBatchMutation,
    UpdateTaskInBatchVariables
  >;
}

interface State {
  selectedCustomerProductId: number | null;
}

class BatchTable extends Component<Props, State> {
  state = {
    selectedCustomerProductId: null
  };

  handleShowTasks = (customerProductId: number) => {
    const { selectedCustomerProductId } = this.state;
    if (customerProductId === selectedCustomerProductId) {
      this.setState({ selectedCustomerProductId: null });
    } else {
      this.setState({ selectedCustomerProductId: customerProductId });
    }
  };

  onAssignToMe = async (taskId: number) => {
    const { updateTaskMutation, customerProductBatchQuery } = this.props;
    if (
      customerProductBatchQuery.data &&
      customerProductBatchQuery.data.viewer
    ) {
      const viewerId = customerProductBatchQuery.data.viewer.id;
      await updateTaskMutation({
        variables: { input: { assignedUserId: viewerId, taskId } }
      });
    }
  };

  render() {
    const { customerProductBatchQuery } = this.props;
    if (customerProductBatchQuery.loading) {
      return <Loader />;
    }
    if (customerProductBatchQuery.error) {
      return customerProductBatchQuery.error.message;
    }
    if (
      !customerProductBatchQuery.data ||
      !customerProductBatchQuery.data.customerProductBatch
    ) {
      return "No batch found.";
    }
    const { customerProductBatch } = customerProductBatchQuery.data;

    const { selectedCustomerProductId } = this.state;
    return (
      <Fragment>
        {customerProductBatch.tasks && customerProductBatch.tasks.length > 0 && (
          <div style={{ marginBottom: "1em" }}>
            <Text.HeaderXSmall>Batch Tasks</Text.HeaderXSmall>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeaderCell>Task</TableHeaderCell>
                  <TableHeaderCell>Status</TableHeaderCell>
                  <TableHeaderCell>Assigned User</TableHeaderCell>
                  <TableHeaderCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {customerProductBatch.tasks.map(task => (
                  <TableRow key={task.id}>
                    <TableCell style={{ width: "40%" }}>
                      <AppLink to={`/tasks/${task.id}`}>
                        <AppIcon icon={faExternalLinkAlt} standardRightMargin />
                        {task.title || task.taskType.name}
                      </AppLink>
                    </TableCell>
                    <TableCell style={{ width: "20%" }}>
                      {task.status.name}
                    </TableCell>
                    <TableCell style={{ width: "20%" }}>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        <GlobalAvatar
                          userId={
                            task.assignedUser ? task.assignedUser.id : null
                          }
                          size="small"
                          style={{ marginRight: ".5em" }}
                        />
                        {task.assignedUser
                          ? task.assignedUser.fullName
                          : "Unassigned"}
                      </span>
                    </TableCell>
                    <TableCell style={{ width: "20%" }}>
                      {task.status.id === TaskStatusEnum.Available && (
                        <Button
                          size="small"
                          onClick={() => this.onAssignToMe(task.id)}
                        >
                          Assign to Me
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
        {customerProductBatch.customerProducts &&
          customerProductBatch.customerProducts.map(customerProduct => (
            <div style={{ marginBottom: "1em" }}>
              <Text.HeaderXSmall>
                {customerProduct.product.name}
              </Text.HeaderXSmall>
              {customerProduct.customerSku && (
                <Text>
                  <AppIcon icon={faBarcode} standardRightMargin />
                  {customerProduct.customerSku.sku} -{" "}
                  {customerProduct.customerSku.title}
                </Text>
              )}
              {(!customerProduct.tasks ||
                customerProduct.tasks.length === 0) && <Text>No tasks.</Text>}
              {customerProduct.tasks && customerProduct.tasks.length > 0 && (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableHeaderCell>Task</TableHeaderCell>
                      <TableHeaderCell>Status</TableHeaderCell>
                      <TableHeaderCell>Assigned User</TableHeaderCell>
                      <TableHeaderCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {customerProduct.tasks.map(task => (
                      <TableRow key={task.id}>
                        <TableCell style={{ width: "40%" }}>
                          <AppLink to={`/tasks/${task.id}`}>
                            <AppIcon
                              icon={faExternalLinkAlt}
                              standardRightMargin
                            />
                            {task.title || task.taskType.name}
                          </AppLink>
                        </TableCell>
                        <TableCell style={{ width: "20%" }}>
                          {task.status.name}
                        </TableCell>
                        <TableCell style={{ width: "20%" }}>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center"
                            }}
                          >
                            <GlobalAvatar
                              userId={
                                task.assignedUser ? task.assignedUser.id : null
                              }
                              size="small"
                              style={{ marginRight: ".5em" }}
                            />
                            {task.assignedUser
                              ? task.assignedUser.fullName
                              : "Unassigned"}
                          </span>
                        </TableCell>
                        <TableCell style={{ width: "20%" }}>
                          {task.status.id === TaskStatusEnum.Available && (
                            <Button
                              size="small"
                              onClick={() => this.onAssignToMe(task.id)}
                            >
                              Assign to Me
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </div>
          ))}
      </Fragment>
    );
  }
}

export default BatchTable;
