import React, { Component } from "react";
import { Formik, FormikActions } from "formik";
const moment = require("moment-business-days");
import { DatePicker } from "material-ui-pickers";
import { isEmpty } from "lodash";
/* Material UI */
import {
  DialogContent,
  DialogTitle,
  Dialog,
  Button,
  DialogActions,
  TextField,
  InputAdornment
} from "@material-ui/core";

/* Font Awesome */
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";

/* Common Components */
import { FieldWrapper } from "../../../common/styled/field-wrapper";
import DangerButton from "../../../common/styled/danger-button";
import AppIcon from "../../../common/app-icon";

/* Utilities */
import { isDateDisabled } from "../../../../utilities/date-helpers";
import { transformGraphQLErrorForFormik } from "../../../../utilities/form-helpers";
import { getObjectDifference } from "../../../../utilities/get-object-difference-helper";

/* GraphQL */
import { MutationFn } from "react-apollo";
import {
  UpdateCustomerProjectMutation,
  UpdateCustomerProjectVariables,
  SelectRoadmapCustomerProjects,
  DeleteCustomerProjectMutation,
  DeleteCustomerProjectVariables,
  SelectRoadmapVariables,
  SelectRoadmapQuery
} from "../../../../generated/graphql";
import { withSnackbar, InjectedNotistackProps } from "notistack";
import ConfirmDeleteDialog from "../../../common/confirm-delete-dialog";
import { ApolloQueryResult } from "apollo-client";

interface Props extends InjectedNotistackProps {
  updateCustomerProjectMutation: MutationFn<
    UpdateCustomerProjectMutation,
    UpdateCustomerProjectVariables
  >;
  deleteCustomerProjectMutation: MutationFn<
    DeleteCustomerProjectMutation,
    DeleteCustomerProjectVariables
  >;
  customerProject: SelectRoadmapCustomerProjects;
  onCancel: () => any;
  onProjectUpdated: () => any;
  refetchCustomerProjects: (
    variables?: SelectRoadmapVariables | undefined
  ) => Promise<ApolloQueryResult<SelectRoadmapQuery>>;
}
interface State {
  isRemoveCustomerProject: boolean;
}

interface FormValues {
  name: string;
  amount: number | null;
  startDate: Date;
}

class ProjectDialog extends Component<Props, State> {
  state = {
    isRemoveCustomerProject: false
  };

  onRemoveCustomerProject = () => {
    this.setState({ isRemoveCustomerProject: true });
  };

  onRemoveCustomerProjectCancel = () => {
    this.setState({ isRemoveCustomerProject: false });
  };

  onConfirmRemoveCustomerProject = async () => {
    const {
      customerProject,
      deleteCustomerProjectMutation,
      enqueueSnackbar,
      refetchCustomerProjects
    } = this.props;

    try {
      await deleteCustomerProjectMutation({
        variables: { input: { customerProjectId: customerProject.id } }
      });
      this.setState({ isRemoveCustomerProject: false });
      enqueueSnackbar("The Customer Project was removed successfully!", {
        variant: "success"
      });
      refetchCustomerProjects();
    } catch (e) {
      const error = transformGraphQLErrorForFormik(e);
      if (error.global) {
        enqueueSnackbar(error.global, { variant: "error" });
      }
    }
  };

  onFormSubmit = async (
    values: FormValues,
    actions: FormikActions<FormValues>
  ) => {
    const {
      customerProject,
      updateCustomerProjectMutation,
      onProjectUpdated,
      enqueueSnackbar
    } = this.props;

    // getObjectDifference will return an object containing only the changed key value pairs
    const updateCustomerProjectInput = getObjectDifference(
      {
        ...values,
        startDate: moment(values.startDate).format("YYYY-MM-DD")
      },
      {
        ...customerProject,
        startDate: moment(customerProject.startDate).format("YYYY-MM-DD")
      }
    );

    if (!isEmpty(updateCustomerProjectInput)) {
      try {
        await updateCustomerProjectMutation({
          variables: {
            input: {
              ...updateCustomerProjectInput,
              customerProjectId: customerProject.id
            }
          }
        });
        actions.setSubmitting(false);
        actions.setErrors({});
        onProjectUpdated();
        enqueueSnackbar("The Customer Project was updated succesfully.", {
          variant: "success"
        });
      } catch (e) {
        actions.setErrors(transformGraphQLErrorForFormik(e));
        actions.setSubmitting(false);
        enqueueSnackbar("There was an error updating the Customer Project", {
          variant: "error"
        });
      }
    } else {
      actions.setSubmitting(false);
      actions.setErrors({});
      onProjectUpdated();
    }
  };

  render() {
    const { isRemoveCustomerProject } = this.state;
    const { customerProject, onCancel } = this.props;

    return (
      <Formik
        initialValues={{
          name: customerProject.name || "",
          amount: customerProject.amount || null,
          startDate: customerProject.startDate
            ? moment(customerProject.startDate)
            : ""
        }}
        onSubmit={this.onFormSubmit}
        render={({
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting
        }) => {
          return (
            <Dialog
              fullWidth
              open
              onClose={onCancel}
              maxWidth="sm"
              disableBackdropClick
            >
              <DialogTitle>{customerProject.name} Details</DialogTitle>
              <DialogContent>
                {isRemoveCustomerProject && (
                  <ConfirmDeleteDialog
                    onConfirmDelete={this.onConfirmRemoveCustomerProject}
                    onDeleteCancel={this.onRemoveCustomerProjectCancel}
                    deleteMessage="Customer Project"
                  />
                )}
                <FieldWrapper>
                  <TextField
                    label="ID"
                    value={customerProject.id}
                    disabled
                    fullWidth
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    id="name"
                    name="name"
                    label="Name"
                    value={values.name}
                    onChange={handleChange}
                    fullWidth
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    id="amount"
                    name="amount"
                    label="Amount"
                    value={values.amount ? values.amount : ""}
                    onChange={handleChange}
                    disabled
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <DatePicker
                    value={values.startDate}
                    fullWidth
                    onChange={date => setFieldValue("startDate", date)}
                    label="Start Date"
                    disabled={customerProject.status.id !== "scheduled"}
                    format="M/D/YYYY"
                    maxDate={moment()
                      .nextBusinessDay()
                      .add(1, "year")}
                    placeholder={moment()
                      .nextBusinessDay()
                      .format("M/D/YYYY")}
                    shouldDisableDate={isDateDisabled(true, true, false)}
                    // handle clearing outside => pass plain array if you are not controlling value outside
                    disableOpenOnEnter
                    animateYearScrolling={false}
                  />
                </FieldWrapper>
              </DialogContent>
              <DialogActions>
                {customerProject.isDeletable && (
                  <DangerButton
                    variant="contained"
                    onClick={this.onRemoveCustomerProject}
                    disabled={isSubmitting}
                  >
                    <AppIcon standardRightMargin icon={faTrash} />
                    Remove Project
                  </DangerButton>
                )}

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit()}
                  disabled={isSubmitting}
                >
                  <AppIcon standardRightMargin icon={faEdit} />
                  Update Project
                </Button>
                <Button onClick={onCancel}>Close</Button>
              </DialogActions>
            </Dialog>
          );
        }}
      />
    );
  }
}

export default withSnackbar(ProjectDialog);
