import React, { Component } from "react";
import PageLayout from "../common/page-layout";
import Team from "./team";
import {
  SelectTeamComponent,
  UpdateTeamComponent,
  CreateTeamUserComponent,
  DeleteTeamUserComponent
} from "../../generated/graphql";

interface Props {
  teamId: number;
}

interface State {}

class TeamContainer extends Component<Props, State> {
  state = {};

  render() {
    const { teamId } = this.props;
    return (
      <PageLayout pageTitle="Team">
        <UpdateTeamComponent>
          {updateTeamMutation => (
            <CreateTeamUserComponent>
              {createTeamUserMutation => (
                <DeleteTeamUserComponent>
                  {deleteTeamUserMutation => (
                    <SelectTeamComponent variables={{ teamId }}>
                      {queryResult => (
                        <Team
                          teamId={teamId}
                          queryResult={queryResult}
                          updateTeamMutation={updateTeamMutation}
                          createTeamUserMutation={createTeamUserMutation}
                          deleteTeamUserMutation={deleteTeamUserMutation}
                        />
                      )}
                    </SelectTeamComponent>
                  )}
                </DeleteTeamUserComponent>
              )}
            </CreateTeamUserComponent>
          )}
        </UpdateTeamComponent>
      </PageLayout>
    );
  }
}

export default TeamContainer;
