import React, { Component, Fragment } from "react";

/* Material UI & FontAwesome */
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button,
  Select,
  FormControl,
  InputLabel
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

/* Common Components */
import ErrorDisplay from "../../../common/error-display";
import { TableHeaderCell } from "../../../common/table-header-cell";
import Loader from "../../../common/loader";
import { FieldWrapper } from "../../../common/styled/field-wrapper";

/* GraphQL Imports */
import {
  SkuSelectorComponent,
  SkuSelectorItems,
  SkuSelectorCustomerSku
} from "../../../../generated/graphql";

interface Props {
  customerId: number;
  onCustomerSkuSelected: (customerSkuId: number) => void;
  selectedCustomerSkuId: null;
}

interface State {
  topLevelCustomerSkuId: string | null;
}

class SkuSelectorTable extends Component<Props, State> {
  state = {
    topLevelCustomerSkuId: null
  };

  onTopLevelCustomerSkuSelected = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ topLevelCustomerSkuId: e.target.value });
  };

  renderTopLevelSkus = (customerSkus: SkuSelectorItems[]) => {
    const { topLevelCustomerSkuId } = this.state;
    return (
      <FieldWrapper>
        <FormControl fullWidth>
          <InputLabel htmlFor="topLevelSku">Top-Level Customer SKU</InputLabel>
          <Select
            id="topLevelSku"
            name="topLevelSku"
            native
            fullWidth
            onChange={e => this.onTopLevelCustomerSkuSelected(e)}
            value={topLevelCustomerSkuId || ""}
          >
            <option value="" />
            {customerSkus &&
              customerSkus.map(customerSku => (
                <option value={customerSku.id} key={customerSku.id}>
                  {customerSku.sku}
                </option>
              ))}
          </Select>
        </FormControl>
      </FieldWrapper>
    );
  };

  renderTable = (customerSku: SkuSelectorCustomerSku) => {
    if (!customerSku) {
      return null;
    }
    const { onCustomerSkuSelected, selectedCustomerSkuId } = this.props;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell>SKU</TableHeaderCell>
            <TableHeaderCell>Title</TableHeaderCell>
            <TableHeaderCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {customerSku.children &&
            customerSku.children.map(childCustomerSku => (
              <TableRow key={childCustomerSku.id}>
                <TableCell>{childCustomerSku.sku}</TableCell>
                <TableCell>{childCustomerSku.title}</TableCell>
                <TableCell>
                  {selectedCustomerSkuId === childCustomerSku.id && (
                    <span>
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{ marginRight: ".5em" }}
                      />
                      Selected
                    </span>
                  )}
                  {selectedCustomerSkuId !== childCustomerSku.id && (
                    <Button
                      size="small"
                      onClick={() => onCustomerSkuSelected(childCustomerSku.id)}
                    >
                      Select
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          {!customerSku.children ||
            (customerSku.children.length === 0 && (
              <TableRow key={customerSku.id}>
                <TableCell>{customerSku.sku}</TableCell>
                <TableCell>{customerSku.title}</TableCell>
                <TableCell>
                  {selectedCustomerSkuId === customerSku.id && (
                    <span>
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{ marginRight: ".5em" }}
                      />
                      Selected
                    </span>
                  )}
                  {selectedCustomerSkuId !== customerSku.id && (
                    <Button
                      size="small"
                      onClick={() => onCustomerSkuSelected(customerSku.id)}
                    >
                      Select
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    );
  };

  render() {
    const { customerId } = this.props;
    const { topLevelCustomerSkuId } = this.state;
    return (
      <SkuSelectorComponent
        variables={{
          customerId,
          selectedCustomerSkuId: topLevelCustomerSkuId || 0,
          hasNotSelectedCustomerSku: !topLevelCustomerSkuId
        }}
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loader />;
          }
          if (error) {
            return <ErrorDisplay error={error} />;
          }
          if (!data || !data.customer) {
            console.log("Here", data);
            return null;
          }
          return (
            <Fragment>
              {this.renderTopLevelSkus(data.customer.customerSkus.items)}
              {data.customerSku ? this.renderTable(data.customerSku) : null}
            </Fragment>
          );
        }}
      </SkuSelectorComponent>
    );
  }
}

export default SkuSelectorTable;
