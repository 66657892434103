import React, { Component } from "react";
import { QueryResult } from "react-apollo";

import ErrorDisplay from "../../common/error-display";
import Roadmap from "./roadmap";
import Loader from "../../common/loader";
import {
  SelectRoadmapComponent,
  SelectRoadmapQuery,
  SelectRoadmapVariables
} from "../../../generated/graphql";

interface Props {
  customer: any;
}

interface State {}

class RoadmapContainer extends Component<Props, State> {
  state = {};

  renderWithData = (
    query: QueryResult<SelectRoadmapQuery, SelectRoadmapVariables>
  ) => {
    if (query.loading) {
      return <Loader />;
    }
    if (query.error || !query.data) {
      return <ErrorDisplay error={query.error} refetch={query.refetch} />;
    }

    if (!query.data || !query.data.customer) {
      return null;
    }

    const { customer } = this.props;

    return (
      <Roadmap
        customer={customer}
        customerProjects={
          query.data.customer.customerProjects
            ? query.data.customer.customerProjects
            : []
        }
        refetchCustomerProjects={query.refetch}
      />
    );
  };

  render() {
    const { customer } = this.props;
    return (
      <SelectRoadmapComponent
        variables={{ customerId: customer.id }}
        fetchPolicy="network-only"
      >
        {this.renderWithData}
      </SelectRoadmapComponent>
    );
  }
}

export default RoadmapContainer;
