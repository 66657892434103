import React, { Component } from "react";
import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faTimes,
  faUserSlash
} from "@fortawesome/free-solid-svg-icons";
import { QueryResult } from "react-apollo";
import { ChildWorkspaceCommonProps } from "../task-workspace/workspace";
import DangerButton from "../../common/styled/danger-button";
import AppIcon from "../../common/app-icon";
import {
  PhotoEditRedoTaskWorkspaceQuery,
  PhotoEditRedoTaskWorkspaceVariables
} from "../../../generated/graphql";
import Loader from "../../common/loader";
import ErrorDisplay from "../../common/error-display";
import Text from "../../common/styled/text";
import { FeedItem } from "../../common/feed-item";

interface Props extends ChildWorkspaceCommonProps {
  queryResult: QueryResult<
    PhotoEditRedoTaskWorkspaceQuery,
    PhotoEditRedoTaskWorkspaceVariables
  >;
}

interface State {
  isReadyToComplete: boolean;
}

class Workspace extends Component<Props, State> {
  state = {
    isReadyToComplete: false
  };

  onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ isReadyToComplete: e.target.checked });
  };

  onUnassign = async () => {
    const { onClose, onUnassign } = this.props;
    await onUnassign();
    onClose();
  };

  render() {
    const { onComplete, onClose, queryResult } = this.props;
    const { isReadyToComplete } = this.state;
    if (queryResult.loading) {
      return <Loader />;
    }
    if (queryResult.error) {
      return <ErrorDisplay error={queryResult.error} />;
    }
    if (!queryResult.data || !queryResult.data.customerProductBatch) {
      throw new Error("Unable to find customer product.");
    }
    const { photographyReviewItems } = queryResult.data.customerProductBatch;
    return (
      <div>
        <Text.HeaderXSmall>Review Comments</Text.HeaderXSmall>
        {photographyReviewItems.map(item => (
          <FeedItem
            user={item.user}
            content={item.comments}
            date={item.createdAt}
            key={item.id}
          />
        ))}
        <div>
          <FormControlLabel
            label="All work for this task has been completed."
            control={<Checkbox onChange={this.onCheckboxChange} />}
          />
        </div>
        <div style={{ marginTop: "4em" }}>
          <Button
            variant="contained"
            color="primary"
            disabled={!isReadyToComplete}
            onClick={() => onComplete()}
            size="large"
          >
            <FontAwesomeIcon icon={faCheck} style={{ marginRight: ".5em" }} />
            Complete Task
          </Button>
          <DangerButton
            onClick={this.onUnassign}
            variant="contained"
            style={{ marginLeft: ".5em" }}
            size="large"
          >
            <AppIcon icon={faUserSlash} standardRightMargin />
            Abandon Task
          </DangerButton>
          <Button onClick={onClose} size="large" style={{ marginLeft: ".5em" }}>
            <FontAwesomeIcon icon={faTimes} style={{ marginRight: ".5em" }} />
            Close
          </Button>
        </div>
      </div>
    );
  }
}

export default Workspace;
