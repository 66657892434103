import React, { Component } from "react";
import { TextField } from "@material-ui/core";
import moment from "moment";

interface Props {
  value: Date | moment.Moment;
  onChange: (date: moment.Moment) => void;
  label: string;
  fullWidth?: boolean;
  error?: boolean;
  helperText?: string;
}

interface State {
  isEditing: boolean;
  visibleValue: string;
}

const FORMAT = "h:mm A";

class TimePicker extends Component<Props, State> {
  state = {
    isEditing: false,
    visibleValue: moment().format(FORMAT)
  };

  componentDidMount() {
    const { value } = this.props;
    this.setState({
      visibleValue: moment(value).format(FORMAT)
    });
  }

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ visibleValue: e.target.value, isEditing: true });
  };

  componentWillReceiveProps(nextProps: Props) {
    const { visibleValue, isEditing } = this.state;
    if (!isEditing && moment(nextProps.value).format(FORMAT) !== visibleValue) {
      this.setState({ visibleValue: moment(nextProps.value).format(FORMAT) });
    }
  }

  onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = moment(e.target.value, "h:mm A", true);
    if (!inputValue.isValid()) {
      const { value } = this.props;
      this.setState({
        visibleValue: moment(value).format(FORMAT),
        isEditing: false
      });
      return;
    }
    const { onChange, value } = this.props;
    this.setState({
      visibleValue: inputValue.format(FORMAT),
      isEditing: false
    });
    onChange(
      moment(value)
        .hour(inputValue.hour())
        .minute(inputValue.minute())
    );
  };

  render() {
    const { onChange, value, ...rest } = this.props;
    const { visibleValue } = this.state;
    return (
      <TextField
        {...rest}
        onBlur={this.onBlur}
        onChange={this.onChange}
        value={visibleValue}
      />
    );
  }
}

export default TimePicker;
