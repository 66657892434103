import React, { Component } from "react";
import { Mutation } from "react-apollo";

import { withSnackbar } from "notistack";
import CREATE_CUSTOMER_PROJECT_MUTATION from "./create-customer-project-mutation";
import AddProjectDialog from "./add-project-dialog";

class AddProjectDialogContainer extends Component {
  state = {};

  render() {
    return (
      <Mutation mutation={CREATE_CUSTOMER_PROJECT_MUTATION}>
        {createCustomerProjectMutation => (
          <AddProjectDialog
            {...this.props}
            createCustomerProjectMutation={createCustomerProjectMutation}
          />
        )}
      </Mutation>
    );
  }
}

export default withSnackbar(AddProjectDialogContainer);
