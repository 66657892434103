import React, { FunctionComponent, useState } from "react";

/* GraphQL & Apollo Imports */
import {
  CustomerNotesComponent,
  CreateNoteComponent,
  StrategyTasksVariables,
  StrategyTasksQuery
} from "../../../../generated/graphql";
import { ApolloQueryResult } from "apollo-client";

/* Common Components */
import Loader from "../../../common/loader";

/* Child Components */
import Notes from "./notes";

interface Customer {
  id: number;
}

interface Props {
  customer: Customer;
  strategyRefetch: (
    variables?: StrategyTasksVariables | undefined
  ) => Promise<ApolloQueryResult<StrategyTasksQuery>>;
}

const CustomerNotes: FunctionComponent<Props> = ({
  customer,
  strategyRefetch
}) => {
  const [searchText, setSearchText] = useState<string>("");
  const [limit, setLimit] = useState<number>(20);
  const [offset, setOffset] = useState<number>(0);

  const onSearchChange = (searchString: string) => {
    setOffset(0);
    setSearchText(searchString);
  };

  const onNextPage = () => {
    setOffset(offset + limit);
  };

  const onPreviousPage = () => {
    if (offset <= limit) {
      setOffset(0);
    } else {
      setOffset(offset - limit);
    }
  };

  return (
    <CreateNoteComponent>
      {createNoteMutation => (
        <CustomerNotesComponent
          variables={{
            customerId: customer.id,
            search: searchText,
            limit,
            offset
          }}
        >
          {({ data, loading, error, refetch }) => {
            if (loading) {
              return <Loader />;
            }
            if (error) {
              return error.message;
            }
            if (
              !data ||
              !data.customer ||
              !data.customer.notes ||
              !data.customer.notes.items
            ) {
              return "No notes.";
            }
            return (
              <Notes
                createNoteMutation={createNoteMutation}
                customer={customer}
                notes={data.customer.notes.items}
                onNoteCreated={() => refetch()}
                strategyRefetch={strategyRefetch}
                searchText={searchText}
                onSearchTextChange={onSearchChange}
                onNextPage={onNextPage}
                onPreviousPage={onPreviousPage}
                count={data.customer.notes.totalCount || 0}
                limit={limit}
                offset={offset}
              />
            );
          }}
        </CustomerNotesComponent>
      )}
    </CreateNoteComponent>
  );
};

export default CustomerNotes;
