import gql from "graphql-tag";

export default gql`
  mutation updateCustomerProduct($input: UpdateCustomerProductInput!) {
    updateCustomerProduct(input: $input) {
      customerProduct {
        id
      }
    }
  }
`;
