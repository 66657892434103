import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import PageLayout from "../common/page-layout";
import { StandardCard } from "../common/standard-card";
import AlertError from "../common/styled/alert-error";

const TaskNotFound = ({ goBack }) => (
  <PageLayout pageTitle="Invalid Task">
    <StandardCard>
      <AlertError message="The task you're looking for doesn't exist." />
      <div style={{ marginTop: "1em" }}>
        <Button variant="contained" color="primary" onClick={goBack}>
          Go Back
        </Button>
      </div>
    </StandardCard>
  </PageLayout>
);

TaskNotFound.propTypes = {
  goBack: PropTypes.func.isRequired
};
export default TaskNotFound;
