import React from "react";

/* GraphQL Imports */
import { SelectAccountManagementOverview_Items } from "../../../generated/graphql";

/* Material UI & FontAwesome */
import { Typography } from "@material-ui/core";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

/* Common Components */
import AppIcon from "../../common/app-icon";
import AppLink from "../../common/app-link";

export const displayCustomersManaged = (
  customersManaged: SelectAccountManagementOverview_Items[] | null
) => {
  if (!customersManaged || customersManaged.length === 0) {
    return (
      <Typography noWrap color="textSecondary" variant="caption">
        <AppIcon icon={faInfoCircle} standardRightMargin /> no clients managed
      </Typography>
    );
  }

  return customersManaged.map(customer => (
    <AppLink
      to={`/customers/${customer.id}`}
      style={{ marginBottom: ".25rem", marginTop: ".25rem" }}
    >
      <Typography noWrap color="primary" variant="caption">
        {customer.name}
      </Typography>
    </AppLink>
  ));
};
