/* This utility returns the correct error object with the proper message to be displayed when using Formik for controlling forms */

export const transformGraphQLErrorForFormik = e => {
  try {
    if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
      if (
        e.graphQLErrors[0].extensions &&
        e.graphQLErrors[0].extensions.exception
      ) {
        const { exception } = e.graphQLErrors[0].extensions;
        if (exception.inner) {
          const validationErrors = exception.inner.reduce(
            (currentErrors, err) => ({
              ...currentErrors,
              [err.path]: err.message
            }),
            { global: exception.message }
          );
          return validationErrors;
        }
        if (exception.invalidArgs) {
          const validationErrors = exception.invalidArgs.reduce(
            (acc, current) => ({
              ...acc,
              [current.field]: current.message
            }),
            {}
          );
          return validationErrors;
        }
      }
      return {
        global: e.graphQLErrors[0].message
      };
    }
    if (e.networkError) {
      if (e.networkError.statusCode === 400) {
        return {
          global: "Something went wrong unexpectedly. Please try again."
        };
      }
    }
    return {
      global: e.message
    };
  } catch (err) {
    return {
      global: "Something went wrong unexpectedly. Please try again."
    };
  }
};
