import React from "react";
import GlobalAvatar from "../global-avatar";

interface Props {
  userId: number;
  fullName: string;
  profileImageUrl: string | null;
}

const UserWithAvatar = (props: Props) => {
  const { profileImageUrl, userId, fullName } = props;
  return (
    <span style={{ display: "flex", alignItems: "center" }}>
      <GlobalAvatar
        userId={userId}
        profileImageUrl={profileImageUrl}
        size="small"
        style={{ marginRight: ".5em" }}
      />
      {fullName}
    </span>
  );
};

export { UserWithAvatar };
