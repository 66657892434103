import React, { FunctionComponent } from "react";

import OverviewDisplay from "./overview-display";
import Loader from "../../common/loader";
import ErrorDisplay from "../../common/error-display";
import {
  SelectCustomerWorkspaceComponent,
  GetCustomerCustomer
} from "../../../generated/graphql";

interface Props {
  customer: GetCustomerCustomer;
}

const Workspace: FunctionComponent<Props> = ({ customer }) => (
  <SelectCustomerWorkspaceComponent
    variables={{ customerId: customer.id }}
    fetchPolicy="network-only"
  >
    {({ data, loading, error, refetch }) => {
      if (loading) {
        return <Loader message="Loading details..." />;
      }
      if (error) {
        return <ErrorDisplay error={error} refetch={refetch} />;
      }

      if (!data || !data.customer) {
        return null;
      }

      return <OverviewDisplay customer={data.customer} refetch={refetch} />;
    }}
  </SelectCustomerWorkspaceComponent>
);

export default Workspace;
