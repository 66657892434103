import React from "react";
import Text from "../../common/styled/text";
import InstructionsContainer from "../../common/styled/instructions-container";

const InventoryArrivalConfirmed = () => (
  <InstructionsContainer>
    <Text>
      Inventory specialist receives the inventory and confirms that it is in
      photographable condition. It is then stored and cataloged in our
      inventory.
    </Text>
  </InstructionsContainer>
);

export default InventoryArrivalConfirmed;
