import React, { Component, Fragment } from "react";
import { QueryResult, MutationFn } from "react-apollo";
import styled from "styled-components";

/* GraphQL Imports */
import {
  SelectCustomerMwsCredentialsQuery,
  SelectCustomerMwsCredentialsVariables,
  CreateCustomerMwsCredentialMutation,
  CreateCustomerMwsCredentialVariables,
  CreateCustomerMwsCredentialRevisionMutation,
  CreateCustomerMwsCredentialRevisionVariables,
  SelectCustomerMwsCredentialsCustomerMwsCredentials,
  DeleteCustomerMwsCredentialMutation,
  DeleteCustomerMwsCredentialVariables
} from "../../../../generated/graphql";

/** Material UI Components */
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Button
} from "@material-ui/core";

/* Font Awesome */
import { faPlus } from "@fortawesome/free-solid-svg-icons";

/* Common Components */
import Loader from "../../../common/loader";
import ErrorDisplay from "../../../common/error-display";
import { AlertInfo } from "../../../common/styled/alert";
import { TableHeaderCell } from "../../../common/table-header-cell";
import AppIcon from "../../../common/app-icon";
import DangerButton from "../../../common/styled/danger-button";

/* Child Components */
import MwsCredentialsDialog from "./mws-credential-dialog";
import MwsCredentialsRevisionDialog from "./mws-credential-revision-dialog";
import ConfirmDeleteCredentialsDialog from "../confirm-delete-credentials-dialog";
import CopyButton from "../../../common/copy-button";
interface Props {
  customerId: number;
  queryResult: QueryResult<
    SelectCustomerMwsCredentialsQuery,
    SelectCustomerMwsCredentialsVariables
  >;
  createCustomerMwsCredentialMutation: MutationFn<
    CreateCustomerMwsCredentialMutation,
    CreateCustomerMwsCredentialVariables
  >;
  createCustomerMwsCredentialRevisionMutation: MutationFn<
    CreateCustomerMwsCredentialRevisionMutation,
    CreateCustomerMwsCredentialRevisionVariables
  >;
  deleteCustomerMwsCredentialMutation: MutationFn<
    DeleteCustomerMwsCredentialMutation,
    DeleteCustomerMwsCredentialVariables
  >;
}

/* Styled Components */
const TextEllipse = styled.div`
  width: 5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
`;

const StyledTableCell = styled(TableCell)`
  padding: 1rem !important;
`;

interface State {
  isAddNozaniCredentials: boolean;
  isAddOtherCredentials: boolean;
  isReviseCredentials: boolean;
  nozaniCredentialExist: boolean;
  isConfirmDialog: boolean;
  currentCredential: SelectCustomerMwsCredentialsCustomerMwsCredentials | null;
}

export interface FormValues {
  name: string;
  sellerId: string;
  authToken: string;
}

class MwsAuthorizationInformationTable extends Component<Props, State> {
  state = {
    isAddNozaniCredentials: false,
    isAddOtherCredentials: false,
    isReviseCredentials: false,
    nozaniCredentialExist: false,
    isConfirmDialog: false,
    currentCredential: null
  };

  onOpenNozaniCredentialsDialog = () => {
    this.setState({ isAddNozaniCredentials: true });
  };

  onCloseNozaniCredentialsDialog = () => {
    this.setState({ isAddNozaniCredentials: false });
  };

  onOpenOtherCredentialsDialog = () => {
    this.setState({ isAddOtherCredentials: true });
  };

  onCloseOtherCredentialsDialog = () => {
    this.setState({ isAddOtherCredentials: false });
  };

  onOpenReviseCredentialsDialog = (
    credential: SelectCustomerMwsCredentialsCustomerMwsCredentials
  ) => {
    this.setState({ currentCredential: credential, isReviseCredentials: true });
  };

  onCloseReviseCredentialDialog = () => {
    this.setState({ isReviseCredentials: false });
  };

  onRemoveCredentialDialog = (
    credential: SelectCustomerMwsCredentialsCustomerMwsCredentials
  ) => {
    this.setState({ currentCredential: credential, isConfirmDialog: true });
  };

  onCancelRemoveCredential = () => {
    this.setState({ isConfirmDialog: false });
  };

  render() {
    const {
      isAddNozaniCredentials,
      isAddOtherCredentials,
      isReviseCredentials,
      nozaniCredentialExist,
      currentCredential,
      isConfirmDialog
    } = this.state;
    const {
      queryResult: { data, loading, error },
      createCustomerMwsCredentialMutation,
      createCustomerMwsCredentialRevisionMutation,
      deleteCustomerMwsCredentialMutation,
      customerId
    } = this.props;

    if (loading) {
      return <Loader />;
    }

    if (error) {
      return <ErrorDisplay error={error} />;
    }

    if (!data || !data.customer || !data.customer.customerMwsCredentials) {
      return <AlertInfo message="There are currently no MWS credentials." />;
    }

    const { customerMwsCredentials } = data.customer;

    if (!nozaniCredentialExist) {
      const exist = customerMwsCredentials.find(
        credential => credential.name === "Nozani"
      );
      if (exist !== undefined) {
        this.setState({ nozaniCredentialExist: true });
      }
    }

    return (
      <Fragment>
        {(isAddNozaniCredentials || isAddOtherCredentials) && (
          <MwsCredentialsDialog
            queryResult={this.props.queryResult}
            createCustomerMwsCredentialMutation={
              createCustomerMwsCredentialMutation
            }
            customerId={customerId}
            isAddNozaniCredentials={isAddNozaniCredentials}
            onCloseNozaniCredentialsDialog={this.onCloseNozaniCredentialsDialog}
            onCloseOtherCredentialsDialog={this.onCloseOtherCredentialsDialog}
          />
        )}
        {isReviseCredentials && (
          <MwsCredentialsRevisionDialog
            queryResult={this.props.queryResult}
            createCustomerMwsCredentialRevisionMutation={
              createCustomerMwsCredentialRevisionMutation
            }
            currentCredential={currentCredential}
            onCloseReviseCredentialDialog={this.onCloseReviseCredentialDialog}
          />
        )}
        {isConfirmDialog && (
          <ConfirmDeleteCredentialsDialog
            currentCredential={currentCredential}
            onClose={this.onCancelRemoveCredential}
            deleteCustomerMwsCredentialMutation={
              deleteCustomerMwsCredentialMutation
            }
            queryResult={this.props.queryResult}
          />
        )}
        <div style={{ marginBottom: "1em" }}>
          {!nozaniCredentialExist && (
            <Button
              variant="contained"
              color="primary"
              onClick={this.onOpenNozaniCredentialsDialog}
              disabled={nozaniCredentialExist}
            >
              <AppIcon icon={faPlus} standardRightMargin />
              Add Nozani Credentials
            </Button>
          )}
          <Button
            style={{ marginLeft: `${!nozaniCredentialExist ? "1rem" : "0"}` }}
            variant="contained"
            color="primary"
            onClick={this.onOpenOtherCredentialsDialog}
          >
            <AppIcon icon={faPlus} standardRightMargin />
            Add New Credentials
          </Button>
        </div>
        {customerMwsCredentials.length === 0 ? (
          <AlertInfo message="There are currently no MWS credentials." />
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableHeaderCell style={{ padding: "1rem" }}>
                  Name
                </TableHeaderCell>
                <TableHeaderCell style={{ padding: "1rem" }}>
                  Seller ID
                </TableHeaderCell>
                <TableHeaderCell style={{ padding: "1rem" }}>
                  Authorization Token
                </TableHeaderCell>
                <TableHeaderCell style={{ padding: "1rem" }} />
                <TableHeaderCell style={{ padding: "1rem" }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {customerMwsCredentials.map(credential => (
                <TableRow key={credential.sellerId}>
                  <StyledTableCell>
                    <TextEllipse
                      title={credential.name}
                      style={{ width: "7rem" }}
                    >
                      {credential.name === "Nozani" ? (
                        <strong>Nozani</strong>
                      ) : (
                        credential.name
                      )}
                    </TextEllipse>
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextEllipse title={credential.sellerId}>
                      {credential.sellerId}
                    </TextEllipse>
                    <CopyButton
                      fieldName="Seller ID"
                      textToCopy={credential.sellerId}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextEllipse title={credential.authToken}>
                      {credential.authToken}
                    </TextEllipse>
                    <CopyButton
                      fieldName="Authorization Token"
                      textToCopy={credential.authToken}
                    />
                  </StyledTableCell>
                  <StyledTableCell style={{ padding: "0" }}>
                    <Button
                      size="small"
                      type="text"
                      color="primary"
                      onClick={() =>
                        this.onOpenReviseCredentialsDialog(credential)
                      }
                    >
                      Edit
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell style={{ padding: "0" }}>
                    {credential.name !== "Nozani" && (
                      <DangerButton
                        size="small"
                        type="text"
                        onClick={() =>
                          this.onRemoveCredentialDialog(credential)
                        }
                      >
                        Remove
                      </DangerButton>
                    )}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Fragment>
    );
  }
}

export default MwsAuthorizationInformationTable;
