import React from "react";
import { Route } from "react-router-dom";
import ReactGA from "react-ga";

const PageTracker = () => (
  <Route
    path="*"
    render={() => {
      if (process.env.NODE_ENV === "production") {
        ReactGA.pageview(window.location.pathname + window.location.search);
      }
      return null;
    }}
  />
);

export default PageTracker;
