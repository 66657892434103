import React, { Component } from "react";
import { Dialog, DialogTitle, DialogActions, Button } from "@material-ui/core";
import DangerButton from "../../common/styled/danger-button";
import {
  SelectCustomerMwsCredentialsCustomerMwsCredentials,
  DeleteCustomerMwsCredentialMutation,
  DeleteCustomerMwsCredentialVariables,
  SelectCustomerMwsCredentialsQuery,
  SelectCustomerMwsCredentialsVariables
} from "../../../generated/graphql";
import { MutationFn, QueryResult } from "react-apollo";

interface Props {
  currentCredential: SelectCustomerMwsCredentialsCustomerMwsCredentials | null;
  deleteCustomerMwsCredentialMutation: MutationFn<
    DeleteCustomerMwsCredentialMutation,
    DeleteCustomerMwsCredentialVariables
  >;
  queryResult: QueryResult<
    SelectCustomerMwsCredentialsQuery,
    SelectCustomerMwsCredentialsVariables
  >;
  onClose: () => any;
}

interface State {}

class ConfirmDeleteCredentialsDialog extends Component<Props, State> {
  onConfirmRemoveCredentials = async () => {
    const {
      queryResult,
      currentCredential,
      onClose,
      deleteCustomerMwsCredentialMutation
    } = this.props;

    if (!currentCredential) {
      return null;
    }

    try {
      await deleteCustomerMwsCredentialMutation({
        variables: { input: { customerMwsCredentialId: currentCredential.id } }
      });
      queryResult.refetch();
      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const { onClose, currentCredential } = this.props;
    return (
      <Dialog open onClose={onClose} fullWidth scroll="body">
        <DialogTitle>
          Are you sure you want to Remove{" "}
          {currentCredential ? `${currentCredential.name}'s` : "these"}{" "}
          credentials?
        </DialogTitle>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={onClose}
          >
            No
          </Button>
          <DangerButton
            variant="contained"
            size="large"
            onClick={this.onConfirmRemoveCredentials}
          >
            Yes
          </DangerButton>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ConfirmDeleteCredentialsDialog;
