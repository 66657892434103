import React, { Component, Fragment } from "react";
import { debounce } from "lodash";

/* Material UI */
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField
} from "@material-ui/core";

/* Font Awesome */
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

/* Graph QL */
import { GetUsersForUserSelectComponent } from "../../../generated/graphql";

/* Common Components */
import Loader from "../loader";
import ErrorDisplay from "../error-display";
import GlobalAvatar from "../../global-avatar";
import AppIcon from "../app-icon";

interface Props {
  roles: string[];
  onClose: () => void;
  onUserSelected: (userId: number | null) => void;
  allowUnassigned: boolean | null;
}

interface State {
  search: string;
  searchText: string;
}

class SelectUserDialog extends Component<Props, State> {
  state = {
    search: "",
    searchText: ""
  };

  onSearchTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: e.target.value });
    this.updateSearch(e.target.value);
  };

  updateSearch = debounce(
    (search: string) => {
      this.setState({ search });
    },
    300,
    { trailing: true }
  );

  render() {
    const { onClose, onUserSelected, allowUnassigned, roles } = this.props;
    const { search, searchText } = this.state;
    return (
      <Dialog open onClose={onClose} scroll="paper" maxWidth="sm" fullWidth>
        <DialogContent>
          <TextField
            variant="outlined"
            onChange={this.onSearchTextChanged}
            value={searchText}
            fullWidth
            label="Search"
            autoFocus
            style={{ marginBottom: "1em" }}
          />
          <GetUsersForUserSelectComponent variables={{ search, roles }}>
            {({ data, loading, error, refetch }) => {
              if (loading) {
                return <Loader />;
              }
              if (error) {
                return <ErrorDisplay error={error} refetch={refetch} />;
              }
              if (!data) {
                return "There was an error.";
              }

              return (
                <Fragment>
                  {data.users.items && data.users.items.length === 0 && (
                    <div>
                      <AppIcon icon={faInfoCircle} />
                      No users found.
                    </div>
                  )}
                  <List dense style={{ maxHeight: 300, overflowY: "scroll" }}>
                    {allowUnassigned && searchText.length === 0 && (
                      <ListItem
                        key={0}
                        button
                        onClick={() => onUserSelected(null)}
                      >
                        <ListItemAvatar>
                          <GlobalAvatar userId={null} />
                        </ListItemAvatar>
                        <ListItemText primary="Unassigned" />
                      </ListItem>
                    )}
                    {data.users.items &&
                      data.users.items.map(user => (
                        <ListItem
                          key={user.id}
                          button
                          onClick={() => onUserSelected(user.id)}
                        >
                          <ListItemAvatar>
                            <GlobalAvatar
                              skipQuery
                              userId={user.id}
                              fullName={user.fullName}
                              profileImageUrl={user.profileImageUrl}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={user.fullName}
                            secondary={user.email}
                          />
                        </ListItem>
                      ))}
                  </List>
                </Fragment>
              );
            }}
          </GetUsersForUserSelectComponent>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default SelectUserDialog;
