import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";

const Text = ({ children, ...rest }) => (
  <Typography {...rest} variant="body2" color="inherit">
    {children}
  </Typography>
);

Text.propTypes = {
  children: PropTypes.node.isRequired
};

Text.HeaderXSmall = ({ children, ...rest }) => (
  <Typography {...rest} variant="subtitle2" color="inherit">
    {children}
  </Typography>
);

Text.HeaderXSmall.propTypes = {
  children: PropTypes.node.isRequired
};

Text.HeaderSmall = ({ children, ...rest }) => (
  <Typography {...rest} variant="h6" color="inherit">
    {children}
  </Typography>
);

Text.HeaderSmall.propTypes = {
  children: PropTypes.node.isRequired
};

Text.HeaderLarge = ({ children, ...rest }) => (
  <Typography {...rest} variant="h4" color="inherit">
    {children}
  </Typography>
);

Text.HeaderLarge.propTypes = {
  children: PropTypes.node.isRequired
};

export default Text;
