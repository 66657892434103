import React, { Component, Fragment, MouseEvent } from "react";
import { Formik, FormikValues, FormikActions } from "formik";
import { withSnackbar, InjectedNotistackProps } from "notistack";
import ReactMarkdown from "react-markdown";
import MarkdownEditor from "../markdown-editor";
import moment from "moment";
import styled from "styled-components";

/* Material UI & FontAwesome */
import {
  Button,
  Paper,
  IconButton,
  MenuItem,
  Popper,
  ClickAwayListener,
  MenuList
} from "@material-ui/core";
import Grow from "@material-ui/core/Grow";
import { Slider } from "@material-ui/lab";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";

/* Child Components */
import GlobalAvatar from "../../global-avatar";
import { StandardCard } from "../standard-card";
import Text from "../styled/text";
import { transformGraphQLErrorForFormik } from "../../../utilities/form-helpers";
import Alert from "../styled/alert-error";
import AppIcon from "../app-icon";
import NoteReminderContainer from "./note-reminders";
import ConfirmDeleteDialog from "../confirm-delete-dialog";

/* GraphQL & Apollo Imports */
import {
  CustomerNotesItems,
  RoadmapCustomerProductBatchNotesItems,
  StrategyTasksVariables,
  StrategyTasksQuery,
  DeleteNoteMutation,
  DeleteNoteVariables
} from "../../../generated/graphql";
import { ApolloQueryResult } from "apollo-client";
import { MutationFn } from "react-apollo";

/* Styled Components */
const StyledMenuItem = styled(MenuItem)`
  padding: 0.0625rem 0.625rem 0.0625rem 0.625rem !important;
`;

interface FormValues {
  global: string;
  content: string;
}

interface Props extends InjectedNotistackProps {
  customerId: number;
  note: CustomerNotesItems | RoadmapCustomerProductBatchNotesItems;
  revisions: CustomerNotesItems[] | RoadmapCustomerProductBatchNotesItems[];
  deleteNoteMutation: MutationFn<DeleteNoteMutation, DeleteNoteVariables>;

  onRevisionCreate: (content: string, originalNoteId: number) => void;
  onNoteCreated?: () => void;
  strategyRefetch?: (
    variables?: StrategyTasksVariables | undefined
  ) => Promise<ApolloQueryResult<StrategyTasksQuery>>;
}

interface State {
  showRevisionsSlider: boolean;
  selectedRevisionIndex: number | null;
  isEditing: boolean;
  revisionContent: string;
  anchorElement: any;
  isAddReminder: boolean;
  noteToDelete: number | null;
}

class NoteDisplay extends Component<Props, State> {
  state = {
    showRevisionsSlider: false,
    selectedRevisionIndex: null,
    isEditing: false,
    revisionContent: "",
    anchorElement: null,
    isAddReminder: false,
    noteToDelete: null
  };

  onNoteMenuOpen = (e: MouseEvent<HTMLElement>) => {
    this.setState({ anchorElement: e.currentTarget });
  };

  onNoteMenuClose = () => {
    this.setState({ anchorElement: null });
  };

  onShowRevisions = () => {
    this.setState({ showRevisionsSlider: true, anchorElement: null });
  };

  onHideRevisions = () => {
    this.setState({ showRevisionsSlider: false, selectedRevisionIndex: null });
  };

  onSelectedRevisionChange = (e: React.ChangeEvent<{}>, value: number) => {
    this.setState({ selectedRevisionIndex: value });
  };

  onStartEdit = () => {
    this.setState({ isEditing: true });
  };

  onCancelEdit = () => {
    this.setState({ isEditing: false, anchorElement: null });
  };

  onAddReminder = () => {
    this.setState({ isAddReminder: !this.state.isAddReminder });
  };

  onDeleteNote = (noteId: number) => {
    this.setState({ noteToDelete: noteId });
  };

  onDeleteNoteCancel = () => {
    this.setState({ noteToDelete: null });
  };

  onConfirmDeleteNote = async () => {
    const { deleteNoteMutation, onNoteCreated, enqueueSnackbar } = this.props;
    const { noteToDelete } = this.state;
    if (noteToDelete) {
      try {
        await deleteNoteMutation({
          variables: { input: { noteId: noteToDelete } }
        });
        if (onNoteCreated) {
          // Refetches the notes
          onNoteCreated();
        }
        this.setState({ noteToDelete: null });
        enqueueSnackbar("The note has been deleted successfully.", {
          variant: "success"
        });
      } catch (e) {
        enqueueSnackbar("There was an error deleting the requested note.", {
          variant: "error"
        });
      }
    }
  };

  getVisibleNote = () => {
    const { note, revisions } = this.props;
    const { selectedRevisionIndex } = this.state;

    let noteToShow = note;
    if (
      selectedRevisionIndex !== null &&
      selectedRevisionIndex < revisions.length
    ) {
      noteToShow = revisions[selectedRevisionIndex];
    }
    return noteToShow;
  };

  onSubmit = async (
    values: FormikValues,
    formikBag: FormikActions<FormValues>
  ) => {
    const { onRevisionCreate, note } = this.props;
    try {
      await onRevisionCreate(values.content, note.originalNoteId || note.id);
      this.setState({
        isEditing: false,
        selectedRevisionIndex: null,
        showRevisionsSlider: false
      });
      formikBag.setSubmitting(false);
    } catch (e) {
      formikBag.setErrors(transformGraphQLErrorForFormik(e));
      formikBag.setSubmitting(false);
    }
  };

  render() {
    const {
      revisions,
      onNoteCreated,
      customerId,
      strategyRefetch
    } = this.props;
    const {
      showRevisionsSlider,
      selectedRevisionIndex,
      isEditing,
      anchorElement,
      isAddReminder,
      noteToDelete
    } = this.state;

    const noteToShow = this.getVisibleNote();
    const reminders = (noteToShow as CustomerNotesItems).noteReminders;

    return (
      <Fragment>
        {noteToDelete && (
          <ConfirmDeleteDialog
            onConfirmDelete={this.onConfirmDeleteNote}
            onDeleteCancel={this.onDeleteNoteCancel}
            customMessage="Are you sure you want to delete this note?"
          />
        )}
        <StandardCard>
          {!isEditing && (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <GlobalAvatar
                  userId={noteToShow.user.id}
                  style={{ marginRight: "1em" }}
                  size="large"
                />
                <div>
                  <Text
                    style={{
                      fontSize: "1.1em",
                      fontWeight: "bold",
                      marginBottom: 0
                    }}
                  >
                    {noteToShow.user.fullName}
                  </Text>
                  <Text style={{ color: "#999999" }}>
                    {moment(noteToShow.createdAt).format("LLL")} (
                    {moment(noteToShow.createdAt).fromNow()})
                  </Text>
                </div>
              </div>
              <div style={{ marginRight: ".5rem" }}>
                <IconButton onClick={e => this.onNoteMenuOpen(e)}>
                  <AppIcon icon={faEllipsisH} />
                </IconButton>
                <Popper
                  open={Boolean(anchorElement)}
                  anchorEl={anchorElement}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom"
                      }}
                    >
                      <Paper style={{ backgroundColor: "#f5f5f5" }}>
                        <ClickAwayListener onClickAway={this.onNoteMenuClose}>
                          <MenuList style={{ marginTop: "0" }}>
                            <StyledMenuItem onClick={this.onStartEdit}>
                              Edit
                            </StyledMenuItem>
                            <StyledMenuItem
                              onClick={this.onShowRevisions}
                              style={{
                                color:
                                  revisions.length === 0 ? "grey" : "inherit"
                              }}
                            >
                              Show Revisions
                            </StyledMenuItem>
                            {noteToShow.isDeletable && (
                              <StyledMenuItem
                                onClick={() => this.onDeleteNote(noteToShow.id)}
                                style={{ color: "red" }}
                              >
                                Delete
                              </StyledMenuItem>
                            )}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </div>
          )}
          <div style={{ marginTop: ".5em" }}>
            {!isEditing && <ReactMarkdown source={noteToShow.content} />}
            {isEditing && (
              <div>
                <Formik
                  onSubmit={this.onSubmit}
                  initialValues={{ global: "", content: noteToShow.content }}
                  render={({
                    values,
                    handleChange,
                    handleSubmit,
                    errors,
                    isSubmitting
                  }) => (
                    <Fragment>
                      {errors.global && <Alert message={errors.global} />}
                      <MarkdownEditor
                        id="content"
                        name="content"
                        value={values.content}
                        onChange={handleChange}
                      />

                      <div style={{ marginTop: "1em" }}>
                        <Button
                          onClick={() => handleSubmit()}
                          variant="contained"
                          color="primary"
                          disabled={
                            isSubmitting ||
                            values.content === noteToShow.content
                          }
                        >
                          Update Note
                        </Button>
                        <Button
                          onClick={this.onCancelEdit}
                          style={{ marginLeft: ".5em" }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Fragment>
                  )}
                />
              </div>
            )}
          </div>

          <NoteReminderContainer
            customerId={customerId}
            noteId={noteToShow.id}
            reminders={reminders}
            isAddReminder={isAddReminder}
            resetIsAddReminder={this.onAddReminder}
            refetchNoteData={onNoteCreated}
            strategyRefetch={
              strategyRefetch as ((
                variables?: StrategyTasksVariables | undefined
              ) => Promise<ApolloQueryResult<StrategyTasksQuery>>)
            }
          />

          {revisions.length > 0 && showRevisionsSlider && !isEditing && (
            <Paper
              style={{ marginTop: "2em", maxWidth: 500, padding: "1em 2em" }}
            >
              <Text.HeaderXSmall>Revision Timeline</Text.HeaderXSmall>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center"
                }}
              >
                <Slider
                  min={0}
                  max={revisions.length}
                  value={
                    selectedRevisionIndex === null
                      ? revisions.length
                      : selectedRevisionIndex
                  }
                  onChange={this.onSelectedRevisionChange}
                  step={1}
                  style={{ maxWidth: 300 }}
                />
                <Button
                  onClick={this.onHideRevisions}
                  style={{ marginLeft: "2em" }}
                >
                  Cancel
                </Button>
              </div>
            </Paper>
          )}
        </StandardCard>
      </Fragment>
    );
  }
}

export default withSnackbar(NoteDisplay);
