import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Switch,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction
} from "@material-ui/core";
import { StandardCard } from "../../common/standard-card";

class GroupList extends Component {
  addToGroup = async groupId => {
    const { addUserToGroupMutation, user } = this.props;
    try {
      await addUserToGroupMutation({
        variables: { input: { userId: user.id, groupId } }
      });
    } catch (e) {
      console.log(e);
    }
  };

  removeFromGroup = async groupId => {
    const { removeUserFromGroupMutation, user } = this.props;
    try {
      await removeUserFromGroupMutation({
        variables: { input: { userId: user.id, groupId } }
      });
    } catch (e) {
      console.error({
        message:
          "Unable to remove this group right now. Please try again later."
      });
    }
  };

  render() {
    const { user, groups, viewer } = this.props;
    return (
      <StandardCard title="Groups">
        <List>
          {groups.map(group => (
            <ListItem>
              <ListItemText>{group.name}</ListItemText>

              <ListItemSecondaryAction>
                <Switch
                  disabled={
                    !viewer.assignableGroups.find(g => g.id === group.id)
                  }
                  onChange={e => {
                    if (e.target.checked) {
                      this.addToGroup(group.id);
                    } else {
                      this.removeFromGroup(group.id);
                    }
                  }}
                  checked={!!user.groups.find(x => x.id === group.id)}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </StandardCard>
    );
  }
}

GroupList.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  groups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  viewer: PropTypes.shape({
    id: PropTypes.number
  }).isRequired
};

export default GroupList;
