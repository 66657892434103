import React, { Component } from "react";
import { isNumber } from "lodash";

/* Material UI */
import { TextField, TableRow, TableCell } from "@material-ui/core";

/* Common Components */
import { TableHeaderCell } from "../common/table-header-cell";

interface Props {
  name: string;
  nonClientAmount: number;
  standardClientDiscount: number;
  maximumAdditionalDiscount: number;
  isHeaderRow: boolean;
  hideStandardClientDiscount: boolean;
  quantity: number;
  additionalDiscount: number;
  priceHeaderText: string;
  onChange: (
    quantity: number,
    additionalDiscount: number | null,
    amount: number
  ) => void;
}

class ServiceRow extends Component<Props> {
  static defaultProps = {
    name: "",
    nonClientAmount: 0,
    standardClientDiscount: 0,
    maximumAdditionalDiscount: 0,
    onChange: null,
    isHeaderRow: false,
    hideStandardClientDiscount: false,
    quantity: 0,
    additionalDiscount: 0,
    priceHeaderText: "Non-client Pricing"
  };

  onQuantityChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { additionalDiscount } = this.props;
    let parsedQuantity = parseInt(e.target.value, 10);
    if (!parsedQuantity || !isNumber(parsedQuantity)) {
      parsedQuantity = 0;
    }
    this.onChange(parsedQuantity, additionalDiscount);
  };

  onAdditionalDiscountChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { maximumAdditionalDiscount, quantity } = this.props;
    let parsedDiscount = parseFloat(e.target.value);
    if (!parsedDiscount || !isNumber(parsedDiscount)) {
      parsedDiscount = 0;
    }
    if (parsedDiscount > maximumAdditionalDiscount) {
      parsedDiscount = parseFloat(maximumAdditionalDiscount.toFixed(2));
    }
    this.onChange(quantity, parsedDiscount);
  };

  onChange = (quantity: number, additionalDiscount: number) => {
    const { onChange, standardClientDiscount, nonClientAmount } = this.props;
    const totalDiscount = additionalDiscount + standardClientDiscount;
    const pricePerPiece = nonClientAmount * (1 - totalDiscount / 100);
    const totalServiceCost = pricePerPiece * quantity;
    onChange(quantity, additionalDiscount, totalServiceCost);
  };

  renderHeaderRow = () => {
    const { hideStandardClientDiscount, priceHeaderText } = this.props;
    return (
      <TableRow>
        <TableHeaderCell style={{ width: "20%" }}>Service</TableHeaderCell>
        <TableHeaderCell>Quantity</TableHeaderCell>
        <TableHeaderCell>{priceHeaderText}</TableHeaderCell>
        {!hideStandardClientDiscount && (
          <TableHeaderCell>Standard Client Discount</TableHeaderCell>
        )}
        <TableHeaderCell>Maximum Additional Discount</TableHeaderCell>
        <TableHeaderCell>Additional Discount</TableHeaderCell>
        <TableHeaderCell>Total Discount</TableHeaderCell>
        <TableHeaderCell>Unit Price</TableHeaderCell>
        <TableHeaderCell>Total Service Price</TableHeaderCell>
      </TableRow>
    );
  };

  render() {
    const {
      name,
      nonClientAmount,
      standardClientDiscount,
      maximumAdditionalDiscount,
      isHeaderRow,
      hideStandardClientDiscount,
      quantity,
      additionalDiscount
    } = this.props;
    if (isHeaderRow) {
      return this.renderHeaderRow();
    }
    const totalDiscount = additionalDiscount + standardClientDiscount;
    const pricePerPiece = nonClientAmount * (1 - totalDiscount / 100);
    const totalServiceCost = pricePerPiece * quantity;
    return (
      <TableRow>
        <TableCell>{name}</TableCell>
        <TableCell>
          <TextField
            value={quantity ? quantity : ""}
            onChange={this.onQuantityChanged}
          />
        </TableCell>
        <TableCell>${nonClientAmount.toFixed(2)}</TableCell>
        {!hideStandardClientDiscount && (
          <TableCell>{standardClientDiscount.toFixed(2)}%</TableCell>
        )}
        <TableCell>{maximumAdditionalDiscount.toFixed(2)}%</TableCell>
        <TableCell>
          <TextField
            value={additionalDiscount ? additionalDiscount : ""}
            onChange={this.onAdditionalDiscountChanged}
            disabled={!quantity || quantity === 0}
          />
        </TableCell>
        <TableCell>{totalDiscount.toFixed(2)}%</TableCell>
        <TableCell>${pricePerPiece.toFixed(2)}</TableCell>
        <TableCell>
          <strong>${totalServiceCost.toFixed(2)}</strong>
        </TableCell>
      </TableRow>
    );
  }
}

export default ServiceRow;
