import React, { Component } from "react";

/* Material UI */
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button
} from "@material-ui/core";

/* FontAwesome */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCheck } from "@fortawesome/free-solid-svg-icons";

/* Common Components */
import ErrorDisplay from "../../../common/error-display";
import { TableHeaderCell } from "../../../common/table-header-cell";
import Loader from "../../../common/loader";
import { FieldWrapper } from "../../../common/styled/field-wrapper";
import AppIcon from "../../../common/app-icon";

/* Constants */
import { PRODUCTS } from "../../../../constants";

/* GraphQL Imports */
import { AddPhotographyChildCustomerSkusComponent } from "../../../../generated/graphql";

interface Props {
  topLevelCustomerSkuId: number | null;
  customerProductItems: any[] | [];
  addCustomerProductItem: (
    customerProductItem: {
      customerSkuId: number;
      productId: string;
      amount: number;
    }
  ) => void;
}

class ChildCustomerSkusTable extends Component<Props, {}> {
  state = {};

  onAddStudioPhoto = (customerSkuId: number) => {
    const { addCustomerProductItem } = this.props;
    addCustomerProductItem({
      customerSkuId,
      productId: "p-studio",
      amount: 58
    });
  };

  onAddLifestylePhoto = (customerSkuId: number) => {
    const { addCustomerProductItem, customerProductItems } = this.props;
    const existingUnique = customerProductItems.find(
      c => c.productId === "p-lifestyle-unique"
    );
    if (!existingUnique) {
      addCustomerProductItem({
        customerSkuId,
        productId: "p-lifestyle-unique",
        amount: 299
      });
    } else {
      addCustomerProductItem({
        customerSkuId,
        productId: "p-lifestyle-similar",
        amount: 99
      });
    }
  };

  onAddInfographic = (customerSkuId: number) => {
    const { addCustomerProductItem } = this.props;
    addCustomerProductItem({
      customerSkuId,
      productId: "gd-infographic",
      amount: 149
    });
  };

  onAddAll = (customerSkuId: number) => {
    if (!this.hasStudioPhoto(customerSkuId)) {
      this.onAddStudioPhoto(customerSkuId);
    }
    if (
      !this.hasUniqueLifestylePhoto(customerSkuId) &&
      !this.hasSimilarLifestylePhoto(customerSkuId)
    ) {
      this.onAddLifestylePhoto(customerSkuId);
    }
  };

  hasStudioPhoto = (customerSkuId: number) => {
    const { customerProductItems } = this.props;
    return !!customerProductItems.find(
      cp => cp.customerSkuId === customerSkuId && cp.productId === "p-studio"
    );
  };

  hasUniqueLifestylePhoto = (customerSkuId: number) => {
    const { customerProductItems } = this.props;
    return !!customerProductItems.find(
      cp =>
        cp.customerSkuId === customerSkuId &&
        cp.productId === "p-lifestyle-unique"
    );
  };

  hasSimilarLifestylePhoto = (customerSkuId: number) => {
    const { customerProductItems } = this.props;
    return !!customerProductItems.find(
      cp =>
        cp.customerSkuId === customerSkuId &&
        cp.productId === "p-lifestyle-similar"
    );
  };

  render() {
    const { topLevelCustomerSkuId, customerProductItems } = this.props;
    if (!topLevelCustomerSkuId) {
      return null;
    }
    return (
      <AddPhotographyChildCustomerSkusComponent
        skip={!topLevelCustomerSkuId}
        variables={{ customerSkuId: topLevelCustomerSkuId }}
      >
        {({ data, loading, error }) => {
          if (loading && !data) {
            return <Loader />;
          }
          if (error) {
            return <ErrorDisplay error={error} />;
          }
          if (!data || !data.customerSku || !data.customerSku.children) {
            return null;
          }
          if (data.customerSku && data.customerSku.children.length === 0) {
            const { customerSku } = data;
            const hasStudioPhoto = customerProductItems.find(
              cp =>
                cp.customerSkuId === customerSku.id &&
                cp.productId === PRODUCTS.STUDIO_PHOTO
            );
            const hasInfographic = customerProductItems.find(
              cp =>
                cp.customerSkuId === customerSku.id &&
                cp.productId === PRODUCTS.INFOGRAPHIC
            );
            const hasLifestylePhoto = customerProductItems.find(
              cp =>
                cp.customerSkuId === customerSku.id &&
                (cp.productId === PRODUCTS.LIFESTYLE_PHOTO_SIMILAR ||
                  cp.productId === PRODUCTS.LIFESTYLE_PHOTO_UNIQUE)
            );
            return (
              <FieldWrapper>
                <Button
                  onClick={() => this.onAddStudioPhoto(customerSku.id)}
                  disabled={hasStudioPhoto}
                >
                  <AppIcon icon={faPlus} standardRightMargin />
                  Studio
                </Button>
                <Button
                  onClick={() => this.onAddLifestylePhoto(customerSku.id)}
                  disabled={hasLifestylePhoto}
                >
                  <AppIcon icon={faPlus} standardRightMargin />
                  Lifestyle
                </Button>
                <Button
                  onClick={() => this.onAddInfographic(customerSku.id)}
                  disabled={hasInfographic}
                >
                  <AppIcon icon={faPlus} standardRightMargin />
                  Infographic
                </Button>
              </FieldWrapper>
            );
          }
          return (
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeaderCell>SKU</TableHeaderCell>
                  <TableHeaderCell>Title</TableHeaderCell>
                  <TableHeaderCell>Studio Photo</TableHeaderCell>
                  <TableHeaderCell>Lifestyle Photo</TableHeaderCell>
                  <TableHeaderCell>Infographic</TableHeaderCell>
                  <TableHeaderCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {data.customerSku.children.map(customerSku => {
                  const hasStudioPhoto = customerProductItems.find(
                    cp =>
                      cp.customerSkuId === customerSku.id &&
                      cp.productId === PRODUCTS.STUDIO_PHOTO
                  );
                  const hasInfographic = customerProductItems.find(
                    cp =>
                      cp.customerSkuId === customerSku.id &&
                      cp.productId === PRODUCTS.INFOGRAPHIC
                  );
                  const hasLifestylePhoto = customerProductItems.find(
                    cp =>
                      cp.customerSkuId === customerSku.id &&
                      (cp.productId === PRODUCTS.LIFESTYLE_PHOTO_SIMILAR ||
                        cp.productId === PRODUCTS.LIFESTYLE_PHOTO_UNIQUE)
                  );
                  return (
                    <TableRow key={customerSku.id}>
                      <TableCell>{customerSku.sku}</TableCell>
                      <TableCell>{customerSku.title}</TableCell>
                      <TableCell>
                        <Button
                          size="small"
                          color="primary"
                          onClick={() => this.onAddStudioPhoto(customerSku.id)}
                          disabled={hasStudioPhoto}
                        >
                          <FontAwesomeIcon
                            icon={hasStudioPhoto ? faCheck : faPlus}
                            style={{ marginRight: ".5em" }}
                          />
                          {hasStudioPhoto ? "Added" : "Add"}
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          size="small"
                          color="primary"
                          onClick={() =>
                            this.onAddLifestylePhoto(customerSku.id)
                          }
                          disabled={hasLifestylePhoto}
                        >
                          <FontAwesomeIcon
                            icon={hasLifestylePhoto ? faCheck : faPlus}
                            style={{ marginRight: ".5em" }}
                          />
                          {hasLifestylePhoto ? "Added" : "Add"}
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          size="small"
                          color="primary"
                          onClick={() => this.onAddInfographic(customerSku.id)}
                          disabled={hasInfographic}
                        >
                          <FontAwesomeIcon
                            icon={hasInfographic ? faCheck : faPlus}
                            style={{ marginRight: ".5em" }}
                          />
                          {hasInfographic ? "Added" : "Add"}
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          size="small"
                          color="primary"
                          onClick={() => this.onAddAll(customerSku.id)}
                          disabled={
                            hasStudioPhoto &&
                            hasLifestylePhoto &&
                            hasInfographic
                          }
                        >
                          <FontAwesomeIcon
                            icon={faPlus}
                            style={{ marginRight: ".5em" }}
                          />
                          Add All
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          );
        }}
      </AddPhotographyChildCustomerSkusComponent>
    );
  }
}

export default ChildCustomerSkusTable;
