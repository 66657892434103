import React, { Fragment, Component } from "react";

/* GraphQL Imports */
import {
  SelectCustomerAmazonAccountQuery,
  SelectCustomerAmazonAccountVariables,
  CreateCustomerAmazonAccountRevisionMutation,
  CreateCustomerAmazonAccountRevisionVariables,
  CreateCustomerAmazonAccountMutation,
  CreateCustomerAmazonAccountVariables
} from "../../../../generated/graphql";
import { QueryResult, MutationFn } from "react-apollo";

/* Child Components */
import SellerCentralDialog from "./seller-central-dialog";

/** Material UI Components */
import {
  List,
  ListItem,
  Avatar,
  ListItemText,
  Button
} from "@material-ui/core";

/* Font Awesome */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faUnlockAlt,
  faKey,
  faPlus
} from "@fortawesome/free-solid-svg-icons";

/* Common Components */
import Loader from "../../../common/loader";
import ErrorDisplay from "../../../common/error-display";
import CopyButton from "../../../common/copy-button";
import AppIcon from "../../../common/app-icon";

interface Props {
  customerId: number;
  queryResult: QueryResult<
    SelectCustomerAmazonAccountQuery,
    SelectCustomerAmazonAccountVariables
  >;
  createCustomerAmazonAccountRevisionMutation: MutationFn<
    CreateCustomerAmazonAccountRevisionMutation,
    CreateCustomerAmazonAccountRevisionVariables
  >;
  createCustomerAmazonAccountMutation: MutationFn<
    CreateCustomerAmazonAccountMutation,
    CreateCustomerAmazonAccountVariables
  >;
}

interface State {
  isUpdateInformation: boolean;
  isAddingSellerInformation: boolean;
}

export interface FormValues {
  email: string;
  password: string;
  totpSecret: string | null;
}

class SellerCentralInformation extends Component<Props, State> {
  state = {
    isAddingSellerInformation: false,
    isUpdateInformation: false
  };

  onOpenUpdateDialog = () => {
    this.setState({ isUpdateInformation: true });
  };

  onOpenAddInformationDialog = () => {
    this.setState({ isAddingSellerInformation: true });
  };

  onClose = () => {
    this.setState({
      isUpdateInformation: false,
      isAddingSellerInformation: false
    });
  };

  render() {
    const { isUpdateInformation, isAddingSellerInformation } = this.state;
    const {
      queryResult: { data, loading, error },
      createCustomerAmazonAccountRevisionMutation,
      createCustomerAmazonAccountMutation,
      customerId
    } = this.props;

    if (loading) {
      return <Loader />;
    }

    if (error) {
      return <ErrorDisplay error={error} />;
    }

    if (!data || !data.customer) {
      return null;
    }

    if (!data.customer.customerAmazonAccount) {
      return (
        <Fragment>
          {isAddingSellerInformation && (
            <SellerCentralDialog
              isAddingSellerInformation={isAddingSellerInformation}
              queryResult={this.props.queryResult}
              createCustomerAmazonAccountMutation={
                createCustomerAmazonAccountMutation
              }
              createCustomerAmazonAccountRevisionMutation={
                createCustomerAmazonAccountRevisionMutation
              }
              onClose={this.onClose}
              customerId={customerId}
            />
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={this.onOpenAddInformationDialog}
          >
            <AppIcon icon={faPlus} standardRightMargin />
            Add Seller Central Information
          </Button>
        </Fragment>
      );
    }

    return (
      <Fragment>
        {isUpdateInformation && (
          <SellerCentralDialog
            customerId={customerId}
            isAddingSellerInformation={isAddingSellerInformation}
            queryResult={this.props.queryResult}
            createCustomerAmazonAccountRevisionMutation={
              createCustomerAmazonAccountRevisionMutation
            }
            createCustomerAmazonAccountMutation={
              createCustomerAmazonAccountMutation
            }
            onClose={this.onClose}
            customerAmazonAccount={data.customer.customerAmazonAccount}
          />
        )}
        <List>
        <div style={{ marginBottom: "1rem" }}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: ".5em" }}
            onClick={this.onOpenUpdateDialog}
          >
            Update Information
          </Button>
        </div>
          <ListItem>
            <Avatar>
              <FontAwesomeIcon icon={faEnvelope} />
            </Avatar>
            <ListItemText
              primary="Customer Email"
              secondary={
                <CopyButton
                  fieldName="Customer Email"
                  displayText={data.customer.customerAmazonAccount.email}
                  textToCopy={data.customer.customerAmazonAccount.email}
                />
              }
            />
          </ListItem>
          <ListItem>
            <Avatar>
              <FontAwesomeIcon icon={faUnlockAlt} />
            </Avatar>
            <ListItemText
              primary="Password"
              secondary={
                <CopyButton
                  fieldName="Password"
                  displayText="............"
                  textToCopy={data.customer.customerAmazonAccount.password}
                />
              }
            />
          </ListItem>
          <ListItem>
            <Avatar>
              <FontAwesomeIcon icon={faKey} />
            </Avatar>
            <ListItemText
              primary="2 Factor Authentication"
              secondary={
                data.customer.customerAmazonAccount.totpCode ? (
                  <CopyButton
                    fieldName="2 Factor Authentication Token"
                    displayText={data.customer.customerAmazonAccount.totpCode}
                    textToCopy={data.customer.customerAmazonAccount.totpCode}
                  />
                ) : (
                  "Not set yet."
                )
              }
            />
          </ListItem>
          
          <div style={{ marginTop: "1.5rem" }}>
          <Button
          // TODO: Fix this hacky attempt at some form of auto-login attempt similar to 1Password.
            variant="contained"
            color="primary"
            href={"https://sellercentral.amazon.com/ap/signin?openid.pape.max_auth_age=0&openid.return_to=https%3A%2F%2Fsellercentral.amazon.com%2Fhome&openid.identity=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.assoc_handle=sc_na_amazon_v2&openid.mode=checkid_setup&language=en_US&openid.claimed_id=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&pageId=sc_na_amazon_v2&openid.ns=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0&ssoResponse=eyJ6aXAiOiJERUYiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiQTI1NktXIn0.bBZ_XuJzpLtwSohPOi8-k2S7v-0x0Oe7g9YhvCZ49fA_7Le-hoD9EQ.yRRaCXnWW1pf4bwr.IQ37nPLiv2pVNY77SzYMLIT0BO8Btx2XoSR45er0dn7tJ8jH-kf6a4QEi7qrEjNBlJajZDrF97zdleCNPb1-9uaTIdLA17qEEwRQmtR35nV6-i-nABTROE187FWwD_gtu2-nBIQWeFFkUGnsjMrH2y7LXJZzl58li9bdjLMum82YiVq7TUgoVWwC6yuxxl3YYCiWguSDmdnUYz72M3i4bUKjwvCQF5pTcJrRLj8TTQAqLLeTp-irjEcyEWEKw3okVMbl.75zrDAZrVjQRNGoEqBuPZA"}
            rel="noopener"
            target="_blank"
          >
            <CopyButton
              fieldName="Customer Email"
              displayText={"Launch US Seller Central"}
              textToCopy={data.customer.customerAmazonAccount.email}
            />
          </Button>
          </div>

          <div style={{ marginTop: ".5rem" }}>
          <Button
          // TODO: Fix this hacky attempt at some form of auto-login attempt similar to 1Password.
            variant="contained"
            color="primary"
            href={"https://sellercentral.amazon.co.uk/ap/signin?openid.pape.max_auth_age=0&openid.return_to=https%3A%2F%2Fsellercentral.amazon.co.uk%2Fhome&openid.identity=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.assoc_handle=sc_uk_amazon_v2&openid.mode=checkid_setup&language=en_GB&openid.claimed_id=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&pageId=sc_uk_amazon_v2&openid.ns=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0&ssoResponse=eyJ6aXAiOiJERUYiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiQTI1NktXIn0.mn89_p5uOvY4mK3ghMOrxYeOzUiV30NGDWk3aI4ho7W_ZpDQQKTFKQ.WEE8Y1Bay5YDDtMA.iiWuC21fjeBNkFdVt5lF6NU3YqL8Kid5_GRzcTE6Cy0ABIm1FTPHISvS2EWGFpb7OHhCwoF_BRYNz2sX36W3SPGwUkROn1LK1Ffn8HkUsdsBLiMUQWZ9XL4IXW05BBcUwKlEsmnQTQAEN6eapuOo2iM2b3TsmFmBCf0MTQBc9c38gnJVxXKMCNLF8aMsCKJa4aSZtqH4bIpIXu-5_tuOv_Wld1eDSbrf7wzXFBkzf7I2PSyCwL1V2vWnMT6gHeOF01zX.y6Lbq3YXHy3htxDMQiQUJQ"}
            rel="noopener"
            target="_blank"
          >
            <CopyButton
              fieldName="Customer Email"
              displayText={"Launch UK Seller Central"}
              textToCopy={data.customer.customerAmazonAccount.email}
            />
          </Button>
          </div>

          <div style={{ marginTop: ".5rem" }}>
          <Button
          // TODO: Fix this hacky attempt at some form of auto-login attempt similar to 1Password.
            variant="contained"
            color="primary"
            href={"https://sellercentral.amazon.ca/ap/signin?openid.pape.max_auth_age=18000&openid.return_to=https%3A%2F%2Fsellercentral.amazon.ca%3A443%2Fgp%2Fhomepage.html%3Flanguage%3Den_CA%26ref%3Defph_home_cont_G200123250&openid.identity=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.assoc_handle=sc_ca_amazon_v2&openid.mode=checkid_setup&openid.claimed_id=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&pageId=sc_ca_amazon_v2&openid.ns=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0&ssoResponse=eyJ6aXAiOiJERUYiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiQTI1NktXIn0.NGI7Auw6Ww97W9X5kggu5jNCRBoNsDswgc3bkbrW-bHQY_FHcFIgyg.jwFT0KpYhqRvNsbA.EM1zRsLgADju5Du2fI4YrsySdKe7ghrbKWaUWwgxfH9sEFpfL-VB4imZpzihpA6qObNUQjGbYEgskSpiNqx75OYG_P2RlMDp2uMyse6xwSaHXd1V0iEcdfi_zohCLZ0DjwqjWoqyzma_hdh6BpUlenfF8_lTDFqL3qr-HVyOcdg48XmE-7-ObON6UjQhQGb3cDHX2rYYLY831IrCJoQRIj8Da8mKp9UX0VxpDj8lYHdVbLIKgfzuR4K6ixcwyMykw1rS._SjPHaGmbJMLp6oKVAisOg"}
            rel="noopener"
            target="_blank"
          >
            <CopyButton
              fieldName="Customer Email"
              displayText={"Launch CA Seller Central"}
              textToCopy={data.customer.customerAmazonAccount.email}
            />
          </Button>
          </div>

        </List>
      </Fragment>
    );
  }
}

export default SellerCentralInformation;
