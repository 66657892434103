import React, { FunctionComponent, Fragment } from "react";
const moment = require("moment");

/* GraphQL Imports */
import {
  StrategyTasksTasks,
  UpdateStrategyTaskMutation,
  UpdateStrategyTaskVariables,
  StrategyTasksVariables,
  StrategyTasksQuery
} from "../../../../generated/graphql";

/* Common Components */
import { MutationFn } from "react-apollo";

/* Child Components */
import TaskCard from "./task-card";
import { ApolloQueryResult } from "apollo-client";

interface Props {
  tasks: StrategyTasksTasks[];
  updateStrategyTaskMutation: MutationFn<
    UpdateStrategyTaskMutation,
    UpdateStrategyTaskVariables
  >;
  refetchTasks: (
    variables?: StrategyTasksVariables | undefined
  ) => Promise<ApolloQueryResult<StrategyTasksQuery>>;
}

const TaskCards: FunctionComponent<Props> = ({
  tasks,
  updateStrategyTaskMutation,
  refetchTasks
}) => {
  return (
    <Fragment>
      {tasks.map(task => {
        const isOverdue = moment(task.dueDate) < moment();

        return (
          <TaskCard
            task={task}
            isOverdue={isOverdue}
            updateStrategyTaskMutation={updateStrategyTaskMutation}
            refetchTasks={refetchTasks}
          />
        );
      })}
    </Fragment>
  );
};

export default TaskCards;
