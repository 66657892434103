import React from "react";
import { Dialog, DialogTitle, DialogActions, Button } from "@material-ui/core";
import DangerButton from "../common/styled/danger-button";

interface Props {
  onConfirmDeleteTeam: (teamId: number) => any;
  onDeleteTeamCancel: () => any;
  teamId: number;
}

const ConfirmDeleteTeamDialog = (props: Props) => (
  <Dialog open onClose={props.onDeleteTeamCancel} scroll="body">
    <DialogTitle>Are you sure you want to remove this team?</DialogTitle>
    <DialogActions>
      <DangerButton
        variant="contained"
        size="large"
        onClick={() => props.onConfirmDeleteTeam(props.teamId)}
      >
        Yes
      </DangerButton>
      <Button size="large" onClick={props.onDeleteTeamCancel}>
        No
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmDeleteTeamDialog;
