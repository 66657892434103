import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText
} from "@material-ui/core";

class IdleModal extends Component {
  state = {
    secondsLeft: 60 * 1
  };

  componentDidMount() {
    this.countdownInterval = setInterval(this.countdown, 1000);
  }

  componentWillUnmount() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  }

  countdown = () => {
    this.setState(
      prevState => ({ secondsLeft: prevState.secondsLeft - 1 }),
      () => {
        const { secondsLeft } = this.state;
        if (secondsLeft <= 0) {
          const { onNotWorking } = this.props;
          onNotWorking();
        }
      }
    );
  };

  render() {
    const { visible, onStillWorking, onNotWorking } = this.props;
    const { secondsLeft } = this.state;
    return (
      <Dialog
        open={visible}
        onClose={onNotWorking}
        fullWidth
        disableBackdropClick
      >
        <DialogTitle>Inactivity</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you still working on this task?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={onStillWorking}>
            Yes, keep working
          </Button>
          <Button onClick={onNotWorking}>
            No, close the task ({secondsLeft})
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

IdleModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onStillWorking: PropTypes.func.isRequired,
  onNotWorking: PropTypes.func.isRequired
};

export default IdleModal;
