import React, { Component } from "react";
const moment = require("moment-business-days");
import { Formik, FormikActions } from "formik";
import { withSnackbar, InjectedNotistackProps } from "notistack";

/* Material UI */
import {
  DialogContent,
  DialogTitle,
  Dialog,
  TextField,
  DialogActions,
  Button
} from "@material-ui/core";
import { DatePicker } from "material-ui-pickers";

/* FontAwesome */
import { faEdit } from "@fortawesome/free-solid-svg-icons";

/* GraphQL & Apollo Imports */
import {
  UpdateCustomerProductMutation,
  UpdateCustomerProductVariables,
  SelectRoadmap_CustomerProducts
} from "../../../../generated/graphql";
import { MutationFn } from "react-apollo";

/* Common Components */
import { FieldWrapper } from "../../../common/styled/field-wrapper";
import AppIcon from "../../../common/app-icon";

/* Utilities */
import { isDateDisabled } from "../../../../utilities/date-helpers";
import { transformGraphQLErrorForFormik } from "../../../../utilities/form-helpers";

interface FormValues {
  startDate: Date;
}

interface Props extends InjectedNotistackProps {
  customerProduct: SelectRoadmap_CustomerProducts;
  updateCustomerProductMutation: MutationFn<
    UpdateCustomerProductMutation,
    UpdateCustomerProductVariables
  >;
  onProductUpdated: () => void;
  onCancel: () => void;
}
interface State {}

class ProductDialog extends Component<Props, State> {
  state = {};

  onFormSubmit = async (
    values: FormValues,
    actions: FormikActions<FormValues>
  ) => {
    const {
      customerProduct,
      updateCustomerProductMutation,
      onProductUpdated,
      enqueueSnackbar
    } = this.props;

    const businessDayDiff = moment(
      moment(values.startDate),
      "MM/DD/YYYY"
    ).businessDiff(
      moment(moment(customerProduct.customerProject.startDate), "MM/DD/YYYY")
    );

    if (businessDayDiff < 1) {
      enqueueSnackbar(
        "The product start date cannot start on or before the project start date.",
        { variant: "error" }
      );
      actions.setErrors({});
      return;
    }

    if (
      moment(values.startDate) >
      moment(customerProduct.customerProject.startDate).add(1, "month")
    ) {
      enqueueSnackbar(
        "The product start date must be before the project end date.",
        { variant: "error" }
      );
      actions.setErrors({});
      return;
    }

    try {
      await updateCustomerProductMutation({
        variables: {
          input: {
            customerProductId: customerProduct.id,
            startDate: moment(values.startDate).format("YYYY-MM-DD")
          }
        }
      });
      actions.setSubmitting(false);
      actions.setErrors({});
      onProductUpdated();
      enqueueSnackbar("The Customer Product was updated successfully.", {
        variant: "success"
      });
    } catch (e) {
      const error = transformGraphQLErrorForFormik(e);
      actions.setErrors(error);

      if (error.global) {
        enqueueSnackbar(error.global, { variant: "error" });
      } else {
        enqueueSnackbar("There was an error updating the Customer Product.", {
          variant: "error"
        });
      }
    }
  };

  render() {
    const { onCancel, customerProduct } = this.props;
    return (
      <Formik
        initialValues={{
          startDate: moment(customerProduct.startDate)
        }}
        onSubmit={this.onFormSubmit}
        render={({
          values,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          setSubmitting
        }) => {
          return (
            <Dialog
              fullWidth
              open
              onClose={onCancel}
              maxWidth="sm"
              disableBackdropClick={customerProduct.status.id === "scheduled"}
            >
              <DialogTitle>Product Details</DialogTitle>
              <DialogContent>
                <FieldWrapper>
                  <TextField
                    label="ID"
                    value={customerProduct.id}
                    disabled
                    fullWidth
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <DatePicker
                    value={values.startDate}
                    fullWidth
                    onChange={date => {
                      setFieldValue("startDate", date);
                      setSubmitting(false);
                    }}
                    label="Start Date"
                    disabled={customerProduct.status.id !== "scheduled"}
                    format="M/D/YYYY"
                    maxDate={moment()
                      .nextBusinessDay()
                      .add(1, "year")}
                    placeholder={moment()
                      .nextBusinessDay()
                      .format("M/D/YYYY")}
                    shouldDisableDate={isDateDisabled(true, true, false)}
                    disableOpenOnEnter
                    animateYearScrolling={false}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    label="Amount"
                    value={`$${customerProduct.amount.toFixed(2)}`}
                    disabled
                    fullWidth
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    label="Status"
                    value={customerProduct.status.name}
                    disabled
                    fullWidth
                  />
                </FieldWrapper>
              </DialogContent>
              {customerProduct.status.id === "scheduled" && (
                <DialogActions>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmit()}
                    disabled={isSubmitting}
                  >
                    <AppIcon standardRightMargin icon={faEdit} />
                    Update Project
                  </Button>
                  <Button onClick={onCancel}>Close</Button>
                </DialogActions>
              )}
            </Dialog>
          );
        }}
      />
    );
  }
}

export default withSnackbar(ProductDialog);
