import React, { Component, Fragment } from "react";
import { Button, Checkbox, FormControlLabel } from "@material-ui/core";
import Text from "../../common/styled/text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faTimes,
  faUserSlash
} from "@fortawesome/free-solid-svg-icons";
import { ChildWorkspaceCommonProps } from "../task-workspace/workspace";
import DangerButton from "../../common/styled/danger-button";
import AppIcon from "../../common/app-icon";

interface Props extends ChildWorkspaceCommonProps {
  instructionComp?: React.ReactElement<any>;
}

interface State {
  isReadyToComplete: boolean;
}

class Workspace extends Component<Props, State> {
  state = {
    isReadyToComplete: false
  };

  onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ isReadyToComplete: e.target.checked });
  };

  onUnassign = async () => {
    const { onClose, onUnassign } = this.props;
    await onUnassign();
    onClose();
  };

  render() {
    const { isReadyToComplete } = this.state;
    const { task, onComplete, onClose, instructionComp } = this.props;
    return (
      <div>
        <Text.HeaderXSmall>Instructions:</Text.HeaderXSmall>
        {instructionComp}
        <div>
          <FormControlLabel
            label="All work for this task has been completed."
            control={<Checkbox onChange={this.onCheckboxChange} />}
          />
        </div>
        <div style={{ marginTop: "4em" }}>
          <Button
            variant="contained"
            color="primary"
            disabled={!isReadyToComplete}
            onClick={() => onComplete()}
            size="large"
          >
            <FontAwesomeIcon icon={faCheck} style={{ marginRight: ".5em" }} />
            Complete Task
          </Button>
          <DangerButton
            onClick={this.onUnassign}
            variant="contained"
            style={{ marginLeft: ".5em" }}
            size="large"
          >
            <AppIcon icon={faUserSlash} standardRightMargin />
            Abandon Task
          </DangerButton>
          <Button onClick={onClose} size="large" style={{ marginLeft: ".5em" }}>
            <FontAwesomeIcon icon={faTimes} style={{ marginRight: ".5em" }} />
            Close
          </Button>
        </div>
      </div>
    );
  }
}

export default Workspace;
