import React, { FunctionComponent } from "react";
import {
  DialogContent,
  DialogTitle,
  Dialog,
  Button,
  DialogActions,
  TablePagination
} from "@material-ui/core";
import { ApolloQueryResult } from "apollo-client";
import {
  CreateNoteMutationFn,
  NoteEntityType,
  TaskNotesItems,
  TaskNotesVariables,
  TaskNotesQuery
} from "../../generated/graphql";
import NoteFeed from "../common/note-feed";

interface Props {
  customerId: number;
  taskId: number;
  allowNewNotes: boolean;
  createNoteMutation: CreateNoteMutationFn;
  notes: TaskNotesItems[];
  count: number;
  limit: number;
  offset: number;
  refetchNotes: (
    variables?: TaskNotesVariables | undefined
  ) => Promise<ApolloQueryResult<TaskNotesQuery>>;
  onCancel: () => void;
  onNextPage: () => any;
  onPreviousPage: () => any;
}

const ProjectNotesDialog: FunctionComponent<Props> = (props: Props) => {
  const onRevisionCreate = async (
    content: string,
    originalNoteId: number | null
  ) => {
    const { createNoteMutation, taskId, refetchNotes } = props;
    await createNoteMutation({
      variables: {
        input: {
          content,
          originalNoteId,
          entityId: taskId.toString(),
          entityType: NoteEntityType.Task
        }
      }
    });
    refetchNotes();
  };

  const {
    onCancel,
    notes,
    allowNewNotes,
    customerId,
    count,
    limit,
    offset,
    onNextPage,
    onPreviousPage
  } = props;
  return (
    <Dialog fullWidth open onClose={onCancel} maxWidth="md" scroll="paper">
      <DialogTitle>Task Notes</DialogTitle>
      <DialogContent>
        {count > limit && (
          <TablePagination
            component="div"
            count={count}
            rowsPerPage={limit}
            page={offset / limit}
            rowsPerPageOptions={[limit]}
            onChangePage={(e, page) => {
              const currentPage = offset / limit;
              if (page > currentPage) {
                onNextPage();
              } else {
                onPreviousPage();
              }
            }}
          />
        )}
        <NoteFeed
          customerId={customerId}
          notes={notes}
          onRevisionCreate={onRevisionCreate}
          preventNewNotes={!allowNewNotes}
        />
        {count > limit && (
          <TablePagination
            component="div"
            count={count}
            rowsPerPage={limit}
            page={offset / limit}
            rowsPerPageOptions={[limit]}
            onChangePage={(e, page) => {
              const currentPage = offset / limit;
              if (page > currentPage) {
                onNextPage();
              } else {
                onPreviousPage();
              }
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectNotesDialog;
