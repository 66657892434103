import React from "react";

import Workspace from "./workspace";
import { ChildWorkspaceCommonProps } from "../task-workspace/workspace";
import {
  CreateInfographicReviewItemsComponent,
  SelectInfographicBatchReviewTaskWorkspaceComponent
} from "../../../generated/graphql";

interface Props extends ChildWorkspaceCommonProps {}

const PhotoBatchReviewTaskContainer = (props: Props) => {
  const {
    task: { customerProductBatch }
  } = props;
  if (!customerProductBatch) {
    throw new Error("Unable to find Customer Product Batch.");
  }
  return (
    <CreateInfographicReviewItemsComponent>
      {createInfographicReviewItemsMutation => (
        <SelectInfographicBatchReviewTaskWorkspaceComponent
          variables={{ customerProductBatchId: customerProductBatch.id }}
          fetchPolicy="network-only"
        >
          {queryResult => (
            <Workspace
              {...props}
              queryResult={queryResult}
              createInfographicReviewItemsMutation={
                createInfographicReviewItemsMutation
              }
            />
          )}
        </SelectInfographicBatchReviewTaskWorkspaceComponent>
      )}
    </CreateInfographicReviewItemsComponent>
  );
};

export default PhotoBatchReviewTaskContainer;
