import React, { Component, FunctionComponent } from "react";
import {
  DialogContent,
  DialogTitle,
  Dialog,
  Button,
  DialogActions,
  TablePagination
} from "@material-ui/core";
import { ApolloQueryResult } from "apollo-client";
import {
  CreateNoteMutationFn,
  NoteEntityType,
  RoadmapCustomerProductNotesItems,
  RoadmapCustomerProductNotesVariables,
  RoadmapCustomerProductNotesQuery,
  SelectRoadmapVariables,
  SelectRoadmapQuery
} from "../../../../generated/graphql";
import NoteFeed from "../../../common/note-feed";

interface CustomerProduct {
  id: number;
}

interface Props {
  customerId: number;
  createNoteMutation: CreateNoteMutationFn;
  customerProduct: CustomerProduct;
  notes: RoadmapCustomerProductNotesItems[];
  count: number;
  limit: number;
  offset: number;
  refetchNotes: (
    variables?: RoadmapCustomerProductNotesVariables | undefined
  ) => Promise<ApolloQueryResult<RoadmapCustomerProductNotesQuery>>;
  onCancel: () => void;
  refreshRoadmap: (
    variables?: SelectRoadmapVariables | undefined
  ) => Promise<ApolloQueryResult<SelectRoadmapQuery>>;
  onNextPage: () => any;
  onPreviousPage: () => any;
}

const ProductNotesDialog: FunctionComponent<Props> = (props: Props) => {
  const onRevisionCreate = async (
    content: string,
    originalNoteId: number | null
  ) => {
    const {
      createNoteMutation,
      customerProduct,
      refetchNotes,
      refreshRoadmap
    } = props;
    await createNoteMutation({
      variables: {
        input: {
          content,
          originalNoteId,
          entityId: customerProduct.id.toString(),
          entityType: NoteEntityType.CustomerProduct
        }
      }
    });
    refetchNotes();
    refreshRoadmap();
  };

  const {
    onCancel,
    notes,
    customerId,
    count,
    limit,
    offset,
    onNextPage,
    onPreviousPage
  } = props;
  return (
    <Dialog
      fullWidth
      open
      onClose={onCancel}
      maxWidth="md"
      scroll="paper"
      disableBackdropClick
    >
      <DialogTitle>Product Notes</DialogTitle>
      <DialogContent>
        {count > limit && (
          <TablePagination
            component="div"
            count={count}
            rowsPerPage={limit}
            page={offset / limit}
            rowsPerPageOptions={[limit]}
            onChangePage={(e, page) => {
              const currentPage = offset / limit;
              if (page > currentPage) {
                onNextPage();
              } else {
                onPreviousPage();
              }
            }}
          />
        )}
        <NoteFeed
          customerId={customerId}
          notes={notes}
          onRevisionCreate={onRevisionCreate}
        />
        {count > limit && (
          <TablePagination
            component="div"
            count={count}
            rowsPerPage={limit}
            page={offset / limit}
            rowsPerPageOptions={[limit]}
            onChangePage={(e, page) => {
              const currentPage = offset / limit;
              if (page > currentPage) {
                onNextPage();
              } else {
                onPreviousPage();
              }
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductNotesDialog;
