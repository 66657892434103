import React, { Component } from "react";

/* Child Components */
import SellerCentralInformation from "./seller-central-information";
import MWSAuthorizationInformation from "./mws-authorization-information";

/* Material UI Components */
import { Grid } from "@material-ui/core";
import {
  SelectCustomerAmazonAccountComponent,
  CreateCustomerAmazonAccountRevisionComponent,
  SelectCustomerMwsCredentialsComponent,
  CreateCustomerMwsCredentialComponent,
  CreateCustomerMwsCredentialRevisionComponent,
  DeleteCustomerMwsCredentialComponent,
  CreateCustomerAmazonAccountComponent
} from "../../../generated/graphql";
import { StandardCard } from "../../common/standard-card";

interface Props {
  customerId: number;
}

interface State {}

class AmazonAccounts extends Component<Props, State> {
  render() {
    const { customerId } = this.props;
    return (
      <Grid container spacing={16}>
        <Grid item lg={5} xs={12} style={{ marginBottom: "1em" }}>
          <StandardCard title="Seller Central Information">
            <CreateCustomerAmazonAccountComponent>
              {createCustomerAmazonAccountMutation => (
                <CreateCustomerAmazonAccountRevisionComponent>
                  {createCustomerAmazonAccountRevisionMutation => (
                    <SelectCustomerAmazonAccountComponent
                      pollInterval={15 * 1000}
                      variables={{ customerId }}
                    >
                      {queryResult => (
                        <SellerCentralInformation
                          customerId={customerId}
                          queryResult={queryResult}
                          createCustomerAmazonAccountRevisionMutation={
                            createCustomerAmazonAccountRevisionMutation
                          }
                          createCustomerAmazonAccountMutation={
                            createCustomerAmazonAccountMutation
                          }
                        />
                      )}
                    </SelectCustomerAmazonAccountComponent>
                  )}
                </CreateCustomerAmazonAccountRevisionComponent>
              )}
            </CreateCustomerAmazonAccountComponent>
          </StandardCard>
        </Grid>
        <Grid item lg={7} xs={12}>
          <StandardCard title="MWS Credentials">
            <DeleteCustomerMwsCredentialComponent>
              {deleteCustomerMwsCredentialMutation => (
                <CreateCustomerMwsCredentialRevisionComponent>
                  {createCustomerMwsCredentialRevisionMutation => (
                    <CreateCustomerMwsCredentialComponent>
                      {createCustomerMwsCredentialMutation => (
                        <SelectCustomerMwsCredentialsComponent
                          variables={{ customerId }}
                        >
                          {queryResult => (
                            <MWSAuthorizationInformation
                              customerId={customerId}
                              queryResult={queryResult}
                              createCustomerMwsCredentialMutation={
                                createCustomerMwsCredentialMutation
                              }
                              createCustomerMwsCredentialRevisionMutation={
                                createCustomerMwsCredentialRevisionMutation
                              }
                              deleteCustomerMwsCredentialMutation={
                                deleteCustomerMwsCredentialMutation
                              }
                            />
                          )}
                        </SelectCustomerMwsCredentialsComponent>
                      )}
                    </CreateCustomerMwsCredentialComponent>
                  )}
                </CreateCustomerMwsCredentialRevisionComponent>
              )}
            </DeleteCustomerMwsCredentialComponent>
          </StandardCard>
        </Grid>
      </Grid>
    );
  }
}

export default AmazonAccounts;
