import React from "react";

import Loader from "../common/loader";
import ErrorDisplay from "../common/error-display";
import { SelectMyStarredCustomersComponent } from "../../generated/graphql";
import MyStarredCustomersCard from "./my-starred-customers-card";

const MyStarredCustomersContainer = () => (
  <SelectMyStarredCustomersComponent fetchPolicy="network-only">
    {({ data, loading, error, refetch }) => {
      if (loading) {
        return <Loader />;
      }
      if (error) {
        return <ErrorDisplay error={error} refetch={refetch} />;
      }
      if (!data || !data.viewer || !data.viewer.customerStars) {
        return null;
      }
      return (
        <MyStarredCustomersCard
          starredCustomers={data.viewer.customerStars}
          starredCustomersCount={data.viewer.customerStars.length}
        />
      );
    }}
  </SelectMyStarredCustomersComponent>
);

export default MyStarredCustomersContainer;
