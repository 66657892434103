import React from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import { Avatar, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faExclamation } from "@fortawesome/free-solid-svg-icons";

import USER_QUERY from "./user-query";

const GlobalAvatar = ({
  userId,
  fullName = null,
  profileImageUrl = null,
  skipQuery = false,
  ...rest
}) => {
  let avatarTitle = rest.title || "Unknown user";
  if (!userId) {
    return (
      <Avatar {...rest} alt={avatarTitle}>
        <FontAwesomeIcon icon={faUser} />
      </Avatar>
    );
  }
  if (skipQuery) {
    if (
      fullName &&
      fullName.length > 0 &&
      profileImageUrl &&
      profileImageUrl.length > 0
    ) {
      return (
        <Tooltip title={fullName}>
          <Avatar {...rest} src={profileImageUrl} alt={fullName} />
        </Tooltip>
      );
    }
  }
  return (
    <Query query={USER_QUERY} variables={{ userId }} fetchPolicy="network-only">
      {({ data, error }) => {
        if (error) {
          return (
            <Tooltip title={avatarTitle}>
              <Avatar {...rest}>
                <FontAwesomeIcon icon={faExclamation} />
              </Avatar>
            </Tooltip>
          );
        }
        if (
          data.user &&
          data.user.profileImageUrl &&
          data.user.profileImageUrl.length > 0
        ) {
          avatarTitle = data.user.fullName;
          return (
            <Tooltip title={avatarTitle}>
              <Avatar
                {...rest}
                src={data.user.profileImageUrl}
                alt={avatarTitle}
              />
            </Tooltip>
          );
        }
        return (
          <Tooltip title={avatarTitle}>
            <Avatar {...rest} alt={avatarTitle}>
              <FontAwesomeIcon icon={faUser} />
            </Avatar>
          </Tooltip>
        );
      }}
    </Query>
  );
};

GlobalAvatar.defaultProps = {
  userId: null,
  fullName: null,
  profileImageUrl: null,
  skipQuery: false
};

GlobalAvatar.propTypes = {
  userId: PropTypes.number,
  fullName: PropTypes.string,
  profileImageUrl: PropTypes.string,
  skipQuery: PropTypes.bool
};

export default GlobalAvatar;
