import React, { FunctionComponent } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";

import ErrorDisplay from "../../common/error-display";

import { GetCustomerComponent } from "../../../generated/graphql";

import Customer from "./customer";

interface MatchParams {
  customerId: string;
  activeSection: string;
}

const CustomerWithData: FunctionComponent<RouteComponentProps<MatchParams>> = ({
  match: {
    params: { customerId, activeSection }
  }
}) => {
  if (!activeSection) {
    return <Redirect to={`/customers/${customerId}/workspace`} />;
  }
  return (
    <GetCustomerComponent
      variables={{ customerId: parseInt(customerId, 10) }}
      fetchPolicy="network-only"
    >
      {queryResult => {
        if (queryResult.error) {
          return <ErrorDisplay error={queryResult.error} />;
        }
        if (!queryResult.data) {
          return null;
        }
        return (
          <Customer
            customer={queryResult.data.customer}
            isLoading={queryResult.loading}
            activeSection={activeSection}
            refetch={queryResult.refetch}
          />
        );
      }}
    </GetCustomerComponent>
  );
};

export default CustomerWithData;
