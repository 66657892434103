import gql from "graphql-tag";

export default gql`
  query selectUsers($search: String) {
    users(search: $search, isActive: true) {
      items {
        id
        fullName
        accountManagement {
          amountManaged
          customersManaged {
            totalCount
          }
        }
      }
    }
  }
`;
