import React, { Component } from "react";
import moment from "moment";

/* GraphQL Imports */
import {
  SelectCustomerProjectsComponent,
  SelectCustomerProjects_Items,
  UpdateCustomerProjectMutationComponent
} from "../../generated/graphql";

/* Common Components */
import PageLayout from "../common/page-layout";
import Loader from "../common/loader";
import ErrorDisplay from "../common/error-display";
import { StandardCard } from "../common/standard-card";

/* Child Components */
import Billing from "./billing";

interface Props {}
interface State {
  selectedCustomerProject: SelectCustomerProjects_Items | null;
}

class BillingContainer extends Component<Props, State> {
  render() {
    return (
      <UpdateCustomerProjectMutationComponent>
        {updateCustomerProjectMutation => (
          <SelectCustomerProjectsComponent
            fetchPolicy="network-only"
            variables={{
              upcomingStartDate: moment()
                .add(1, "day")
                .startOf("day")
                .format("YYYY-MM-DD"),
              upcomingEndDate: moment()
                .add(1, "week")
                .startOf("day")
                .format("YYYY-MM-DD")
            }}
          >
            {queryResult => {
              if (queryResult.loading) {
                return <Loader />;
              }
              if (queryResult.error) {
                return (
                  <ErrorDisplay
                    error={queryResult.error}
                    refetch={queryResult.refetch}
                  />
                );
              }

              if (!queryResult.data || !queryResult.data.viewer) {
                return null;
              }

              return (
                <PageLayout pageTitle="Billing">
                  <StandardCard>
                    <Billing
                      queryResult={queryResult}
                      updateCustomerProjectMutation={
                        updateCustomerProjectMutation
                      }
                    />
                  </StandardCard>
                </PageLayout>
              );
            }}
          </SelectCustomerProjectsComponent>
        )}
      </UpdateCustomerProjectMutationComponent>
    );
  }
}

export default BillingContainer;
