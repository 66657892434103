import React, { Component } from "react";
import { Button, LinearProgress } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { withSnackbar, InjectedNotistackProps } from "notistack";
import { MutationFn } from "react-apollo";
import axios from "axios";
import {
  CreateCustomerFileMutation,
  CreateCustomerFileVariables
} from "../../../generated/graphql";

interface Props extends InjectedNotistackProps {
  createCustomerFileMutation: MutationFn<
    CreateCustomerFileMutation,
    CreateCustomerFileVariables
  >;
  customerId: number;
  onFileAdded: () => void;
}
interface State {
  isUploading: boolean;
  uploadProgressPercentage: number;
}

class UploadButton extends Component<Props, State> {
  state = {
    isUploading: false,
    uploadProgressPercentage: 0
  };

  onFileSelected = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files && e.target.files.length > 0) {
      const {
        enqueueSnackbar,
        createCustomerFileMutation,
        customerId,
        onFileAdded
      } = this.props;
      const file = e.target.files[0];
      if (file.size > 1024 * 1024 * 50) {
        enqueueSnackbar("Files must be smaller than 50MB.");
        return;
      }

      const result = await createCustomerFileMutation({
        variables: {
          input: {
            customerId,
            mimeType: file.type,
            name: file.name,
            sizeInBytes: file.size
          }
        }
      });

      try {
        if (result && result.data) {
          this.setState({ isUploading: true, uploadProgressPercentage: 0 });
          await axios.put(result.data.createCustomerFile.uploadUrl, file, {
            headers: {
              "Content-Type": file.type
            },
            onUploadProgress: progressEvent => {
              this.setState({
                uploadProgressPercentage: Math.round(
                  (progressEvent.loaded / progressEvent.total) * 100
                )
              });
            }
          });
          this.setState({ isUploading: false });
        }
        enqueueSnackbar(`${file.name} was uploaded successfully.`);
        onFileAdded();
      } catch (e) {
        enqueueSnackbar(e.message);
      }
    }
  };

  render() {
    const { isUploading, uploadProgressPercentage } = this.state;

    return (
      <div style={{ marginBottom: "1em" }}>
        <input
          style={{ display: "none" }}
          id="contained-button-file"
          multiple
          type="file"
          onChange={this.onFileSelected}
        />
        {!isUploading && (
          <label htmlFor="contained-button-file">
            <Button variant="contained" color="primary" component="span">
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: ".5em" }} />
              Add File
            </Button>
          </label>
        )}
        {isUploading && (
          <LinearProgress
            color="primary"
            value={uploadProgressPercentage}
            variant="determinate"
            title={`${uploadProgressPercentage}%`}
          />
        )}
      </div>
    );
  }
}

export default withSnackbar(UploadButton);
