import React, { FunctionComponent } from "react";
import styled from "styled-components";

/* Material UI */
import {
  Paper,
  Button,
  ListItem,
  ListItemText,
  List,
  Collapse,
  ListSubheader,
  Checkbox
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

/* Imported Interface */
import { SkuFilters } from "./skus";

/* Constants/Enums */
const SKU_SYNC_STATUSES = [
  { name: "Sync Requested", statusId: "sync_requested" },
  { name: "Sync In Progress", statusId: "sync_inprogress" },
  { name: "Completed", statusId: "completed" },
  { name: "Error", statusId: "error" }
];

const SKU_TYPES = [
  { name: "Parent", statusId: "parent" },
  { name: "Variation", statusId: "variation" },
  { name: "Stand Alone", statusId: "standalone" }
];

const StyleListItemText = styled(ListItemText)`
  padding-left: 1rem !important;
`;
interface Props {
  skuFilters: SkuFilters;
  onResetFilters: () => void;
  handleIsSkuTypeOpen: () => void;
  handleIsSyncStatusOpen: () => void;
  handleSkuTypeFilters: (statusId: string) => void;
  handleSyncStatusFilters: (statusId: string) => void;
}

const SkusFilter: FunctionComponent<Props> = ({
  skuFilters,
  onResetFilters,
  handleIsSkuTypeOpen,
  handleSkuTypeFilters,
  handleIsSyncStatusOpen,
  handleSyncStatusFilters
}) => (
  <Paper elevation={4}>
    <List
      component="nav"
      subheader={
        <ListSubheader component="div" style={{ paddingRight: ".5rem" }}>
          Filter skus{" "}
          <Button
            color="primary"
            style={{ marginLeft: "1.8rem" }}
            onClick={onResetFilters}
          >
            Reset
          </Button>
        </ListSubheader>
      }
    >
      <ListItem
        dense
        button
        selected={skuFilters.isSkuTypeOpen}
        onClick={handleIsSkuTypeOpen}
      >
        <ListItemText primary="Sku Type" />
        {skuFilters.isSkuTypeOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={skuFilters.isSkuTypeOpen} timeout="auto" unmountOnExit>
        <List disablePadding>
          {SKU_TYPES.map((type, index) => (
            <ListItem
              key={`${type.statusId}-${index}`}
              dense
              button
              onClick={() => handleSkuTypeFilters(type.name)}
            >
              <Checkbox
                style={{ padding: "0.0625rem" }}
                checked={skuFilters.skuTypeFilters.indexOf(type.name) !== -1}
                tabIndex={-1}
                disableRipple
              />
              <StyleListItemText primary={type.name} />
            </ListItem>
          ))}
        </List>
      </Collapse>
      <ListItem
        dense
        button
        selected={skuFilters.isSyncStatusOpen}
        onClick={handleIsSyncStatusOpen}
      >
        <ListItemText primary="Sync Status" />
        {skuFilters.isSyncStatusOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={skuFilters.isSyncStatusOpen} timeout="auto" unmountOnExit>
        <List disablePadding>
          {SKU_SYNC_STATUSES.map((status, index) => (
            <ListItem
              key={`${status.statusId}-${index}`}
              dense
              button
              onClick={() => handleSyncStatusFilters(status.name)}
            >
              <Checkbox
                style={{ padding: "0.0625rem" }}
                checked={
                  skuFilters.syncStatusFilters.indexOf(status.name) !== -1
                }
                tabIndex={-1}
                disableRipple
              />
              <StyleListItemText
                primary={status.name}
                style={{ padding: "0" }}
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </List>
  </Paper>
);

export default SkusFilter;
