import gql from "graphql-tag";

export default gql`
  mutation removeUserFromGroup($input: RemoveUserFromGroupInput!) {
    removeUserFromGroup(input: $input) {
      user {
        id
        groups {
          id
          name
        }
      }
    }
  }
`;
