import React, { Component } from "react";
import { adopt } from "react-adopt";
import { QueryResult, MutationFn } from "react-apollo";
import {
  SelectCustomerUsersComponent,
  CreateCustomerUserComponent,
  CreateUserPhoneNumberComponent,
  UpdateUserPhoneNumberComponent,
  DeleteUserPhoneNumberComponent,
  SelectCustomerUsersQuery,
  SelectCustomerUsersVariables,
  CreateCustomerUserMutation,
  CreateCustomerUserVariables,
  CreateUserPhoneNumberMutation,
  CreateUserPhoneNumberVariables,
  UpdateUserPhoneNumberMutation,
  UpdateUserPhoneNumberVariables,
  DeleteUserPhoneNumberMutation,
  DeleteUserPhoneNumberVariables,
  CreateUserEmailAddressComponent,
  DeleteUserEmailAddressComponent,
  UpdateUserEmailAddressComponent,
  CreateUserEmailAddressMutation,
  CreateUserEmailAddressVariables,
  UpdateUserEmailAddressMutation,
  UpdateUserEmailAddressVariables,
  DeleteUserEmailAddressMutation,
  DeleteUserEmailAddressVariables,
  UpdateCustomerUserComponent,
  UpdateCustomerUserMutation,
  UpdateCustomerUserVariables
} from "../../../generated/graphql";
import { CustomerUsers } from "./users";

interface Props {
  customer: {
    id: number;
  };
}

interface ComposedInputProps {
  customerId: number;
}

export interface ComposedRenderProps {
  customerUsersQueryResult: QueryResult<
    SelectCustomerUsersQuery,
    SelectCustomerUsersVariables
  >;
  createCustomerUserMutation: MutationFn<
    CreateCustomerUserMutation,
    CreateCustomerUserVariables
  >;
  createUserPhoneNumberMutation: MutationFn<
    CreateUserPhoneNumberMutation,
    CreateUserPhoneNumberVariables
  >;
  updateUserPhoneNumberMutation: MutationFn<
    UpdateUserPhoneNumberMutation,
    UpdateUserPhoneNumberVariables
  >;
  deleteUserPhoneNumberMutation: MutationFn<
    DeleteUserPhoneNumberMutation,
    DeleteUserPhoneNumberVariables
  >;
  createUserEmailAddressMutation: MutationFn<
    CreateUserEmailAddressMutation,
    CreateUserEmailAddressVariables
  >;
  updateUserEmailAddressMutation: MutationFn<
    UpdateUserEmailAddressMutation,
    UpdateUserEmailAddressVariables
  >;
  deleteUserEmailAddressMutation: MutationFn<
    DeleteUserEmailAddressMutation,
    DeleteUserEmailAddressVariables
  >;
  updateCustomerUserMutation: MutationFn<
    UpdateCustomerUserMutation,
    UpdateCustomerUserVariables
  >;
}

interface State {}

const Composed = adopt<ComposedRenderProps, ComposedInputProps>({
  createCustomerUserMutation: <CreateCustomerUserComponent />,
  createUserPhoneNumberMutation: <CreateUserPhoneNumberComponent />,
  updateUserPhoneNumberMutation: <UpdateUserPhoneNumberComponent />,
  deleteUserPhoneNumberMutation: <DeleteUserPhoneNumberComponent />,
  createUserEmailAddressMutation: <CreateUserEmailAddressComponent />,
  deleteUserEmailAddressMutation: <DeleteUserEmailAddressComponent />,
  updateUserEmailAddressMutation: <UpdateUserEmailAddressComponent />,
  updateCustomerUserMutation: <UpdateCustomerUserComponent />,
  customerUsersQueryResult: ({ customerId, render }) => (
    <SelectCustomerUsersComponent
      variables={{ customerId }}
      fetchPolicy="network-only"
    >
      {render}
    </SelectCustomerUsersComponent>
  )
});

class CustomerUsersContainer extends Component<Props, State> {
  render() {
    const { customer } = this.props;
    return (
      <Composed customerId={customer.id}>
        {props => <CustomerUsers customer={customer} {...props} />}
      </Composed>
    );
  }
}

export default CustomerUsersContainer;
