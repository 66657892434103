import React, { Component } from "react";
import { Formik, FormikActions, Form } from "formik";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button
} from "@material-ui/core";
import { MutationFn } from "react-apollo";
import { AlertError } from "../common/styled/alert";
import { FieldWrapper } from "../common/styled/field-wrapper";
import { transformGraphQLErrorForFormik } from "../../utilities/form-helpers";
import Loader from "../common/loader";
import {
  CreateTeamMutation,
  CreateTeamVariables
} from "../../generated/graphql";
import UserSelectContainer from "../common/user-select";
import { ROLES } from "../../constants/roles";

interface Props {
  createTeamMutation: MutationFn<CreateTeamMutation, CreateTeamVariables>;
  onTeamCreated: () => any;
  onClose: () => any;
}

interface State {}

interface FormValues {
  global?: string | null;
  name: string;
  department: string;
  leadUserId: number | null;
}

class CreateTeamDialog extends Component<Props, State> {
  state = {};

  onSubmit = async (values: FormValues, actions: FormikActions<FormValues>) => {
    const { createTeamMutation, onTeamCreated } = this.props;
    try {
      if (!values.leadUserId) {
        return;
      }
      await createTeamMutation({
        variables: {
          input: {
            name: values.name,
            department: values.department,
            leadUserId: values.leadUserId
          }
        }
      });
      actions.setSubmitting(false);
      onTeamCreated();
    } catch (e) {
      actions.setErrors(transformGraphQLErrorForFormik(e));
      actions.setSubmitting(false);
    }
  };

  render() {
    const { onClose } = this.props;
    return (
      <Formik
        onSubmit={this.onSubmit}
        initialValues={{
          department: "",
          name: "",
          leadUserId: null
        }}
        render={({
          handleSubmit,
          handleChange,
          values,
          errors,
          isSubmitting,
          setFieldValue
        }) => (
          <Dialog
            open
            onClose={onClose}
            fullWidth
            disableBackdropClick
            scroll="body"
          >
            <DialogTitle>Add Team</DialogTitle>
            <DialogContent>
              <Form>
                {errors.global && <AlertError message={errors.global} />}
                <FieldWrapper>
                  <TextField
                    label="Team Name"
                    id="name"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    placeholder="Team Name"
                    fullWidth
                    helperText={errors.name}
                    error={!!errors.name}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    label="Department"
                    id="department"
                    name="department"
                    onChange={handleChange}
                    value={values.department}
                    placeholder="Department"
                    fullWidth
                    helperText={errors.department}
                    error={!!errors.department}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <UserSelectContainer
                    roles={[ROLES.EMPLOYEE]}
                    allowUnassigned={false}
                    label="Team Lead"
                    onUserSelected={userId => {
                      setFieldValue("leadUserId", userId);
                    }}
                    userId={values.leadUserId}
                  />
                </FieldWrapper>
              </Form>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} disabled={isSubmitting}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
              >
                {isSubmitting && <Loader />}
                Create Team
              </Button>
            </DialogActions>
          </Dialog>
        )}
      />
    );
  }
}

export { CreateTeamDialog };
