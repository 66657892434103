import React from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import { has } from "lodash";

import CUSTOMER_CONTRACTS_QUERY from "./customer-contracts-query";
import ContractsTable from "./contracts-table";
import Loader from "../../common/loader";

const Contracts = ({ customer }) => (
  <Query
    query={CUSTOMER_CONTRACTS_QUERY}
    variables={{ customerId: customer.id }}
    fetchPolicy="network-only"
  >
    {({ data, loading, error }) => {
      if (loading) {
        return <Loader message="Loading..." />;
      }
      if (error || !data) {
        return `Error: ${error}`;
      }
      return (
        <ContractsTable
          customer={customer}
          contracts={
            has(data, "customer.customerContracts")
              ? data.customer.customerContracts
              : []
          }
        />
      );
    }}
  </Query>
);

Contracts.propTypes = {
  customer: PropTypes.shape({}).isRequired
};

export default Contracts;
