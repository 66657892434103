export const PRODUCT_GROUPS = {
  AUDIT: "audit",
  SPONSORED_ADS_PPC_MANAGEMENT: "sponsored-ads-ppc-management",
  SPONSORED_BRANDS_PPC_MANAGEMENT: "sponsored-brands-ppc-management",
  PRODUCT_DISPLAY_ADS_PPC_MANAGEMENT: "product-display-ads-ppc-management",
  SOCIAL_ADVERTISING: "social-advertising",
  SOCIAL_POSTING: "social-posting",
  KEYWORD_RESEARCH: "keyword-research",
  LISTING_CONTENT_CREATION: "listing-content-creation",
  EBC_TEMPLATE: "ebc-template",
  GRAPHIC_DESIGN: "graphic-design",
  PHOTOGRAPHY: "photography"
};
