import React, { Component } from "react";

import Workspace from "./task-workspace/workspace";
import PageLayout from "../common/page-layout";
import ErrorDisplay from "../common/error-display";
import {
  SelectTaskComponent,
  UpdateTaskComponent,
  RecordTaskWorkComponent
} from "../../generated/graphql";

interface Props {
  taskId: number;
}
interface State {}

class Task extends Component<Props, State> {
  state = {};

  render() {
    const { taskId } = this.props;
    return (
      <RecordTaskWorkComponent>
        {recordTaskWorkMutation => (
          <UpdateTaskComponent>
            {updateTaskMutation => (
              <SelectTaskComponent
                variables={{
                  taskId
                }}
                fetchPolicy="network-only"
              >
                {({ data, loading, error, refetch }) => {
                  if (error) {
                    return (
                      <PageLayout pageTitle="Error">
                        <ErrorDisplay error={error} refetch={refetch} />
                      </PageLayout>
                    );
                  }
                  if (
                    !data ||
                    !data.task ||
                    !data.viewer ||
                    !data.task.customer
                  ) {
                    return (
                      <PageLayout pageTitle="Error">
                        Could not find task.
                      </PageLayout>
                    );
                  }
                  return (
                    <Workspace
                      customerId={data.task.customer.id}
                      loading={loading}
                      task={data.task}
                      viewer={data.viewer}
                      updateTaskMutation={updateTaskMutation}
                      recordTaskWorkMutation={recordTaskWorkMutation}
                      refetchTask={refetch}
                    />
                  );
                }}
              </SelectTaskComponent>
            )}
          </UpdateTaskComponent>
        )}
      </RecordTaskWorkComponent>
    );
  }
}

export default Task;
