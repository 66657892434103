import React, { Component, Fragment } from "react";

/* Material UI */
import { Button } from "@material-ui/core";

/* FontAwesome */
import { faPlus } from "@fortawesome/free-solid-svg-icons";

/* GraphQL & Apollo Imports */
import { QueryResult, MutationFn } from "react-apollo";
import {
  SelectInventoryItemsQuery,
  SelectInventoryItemsVariables,
  SelectInventoryItemsItems,
  CreateInventoryItemMutation,
  CreateInventoryItemVariables,
  UpdateInventoryItemMutation,
  UpdateInventoryItemVariables,
  DeleteInventoryItemMutation,
  DeleteInventoryItemVariables
} from "../../generated/graphql";

/* Child Components */
import InventoryItemsTable from "./inventory-items-table";
import InventoryDetailsDialog from "./inventory-details-dialog";

/* Common Components */
import ErrorDisplay from "../common/error-display";
import PageLayout from "../common/page-layout";
import { StandardCard } from "../common/standard-card";
import Loader from "../common/loader";
import { FieldWrapper } from "../common/styled/field-wrapper";
import AppIcon from "../common/app-icon";
import ConfirmDeleteDialog from "../common/confirm-delete-dialog";
import { transformGraphQLErrorForFormik } from "../../utilities/form-helpers";
import { withSnackbar, InjectedNotistackProps } from "notistack";

interface Props extends InjectedNotistackProps {
  selectInventoryItemsResult: QueryResult<
    SelectInventoryItemsQuery,
    SelectInventoryItemsVariables
  >;
  createInventoryItemMutation: MutationFn<
    CreateInventoryItemMutation,
    CreateInventoryItemVariables
  >;
  updateInventoryItemMutation: MutationFn<
    UpdateInventoryItemMutation,
    UpdateInventoryItemVariables
  >;
  deleteInventoryItemMutation: MutationFn<
    DeleteInventoryItemMutation,
    DeleteInventoryItemVariables
  >;
}

interface State {
  isAddDialogOpen: boolean;
  isEditInventoryItem: boolean;
  isRemoveDialogOpen: boolean;
  selectedInventoryItem: SelectInventoryItemsItems | null;
}

class Inventory extends Component<Props, State> {
  state = {
    isAddDialogOpen: false,
    isEditInventoryItem: false,
    isRemoveDialogOpen: false,
    selectedInventoryItem: null
  };

  onAddInventoryOpen = () => {
    this.setState({ isAddDialogOpen: true });
  };

  onAddInventoryClose = () => {
    this.setState({ isAddDialogOpen: false });
  };

  onInventoryItemSelected = (item: SelectInventoryItemsItems) => {
    this.setState({ isEditInventoryItem: true, selectedInventoryItem: item });
  };

  onInventoryItemDeselected = () => {
    this.setState({ isEditInventoryItem: false, selectedInventoryItem: null });
  };

  onInventoryItemAdded = async () => {
    const { selectInventoryItemsResult } = this.props;
    await selectInventoryItemsResult.refetch();
    this.onAddInventoryClose();
  };

  onRemoveInventoryItem = (item: SelectInventoryItemsItems) => {
    this.setState({ isRemoveDialogOpen: true, selectedInventoryItem: item });
  };

  onCancelRemoveInventoryItem = () => {
    this.setState({ isRemoveDialogOpen: false, selectedInventoryItem: null });
  };

  onConfirmRemoveInventoryItem = async () => {
    const { selectedInventoryItem } = this.state;
    const {
      deleteInventoryItemMutation,
      selectInventoryItemsResult,
      enqueueSnackbar
    } = this.props;

    if (!selectedInventoryItem) {
      return;
    }

    try {
      await deleteInventoryItemMutation({
        variables: {
          input: {
            inventoryItemId: (selectedInventoryItem as SelectInventoryItemsItems)
              .id
          }
        }
      });
      await selectInventoryItemsResult.refetch();
      this.setState({ isRemoveDialogOpen: false, selectedInventoryItem: null });
      enqueueSnackbar("Inventory Item removed successfully.", {
        variant: "success"
      });
    } catch (e) {
      const error = transformGraphQLErrorForFormik(e);
      if (error.global) {
        enqueueSnackbar(error.global, { variant: "error" });
      } else {
        enqueueSnackbar(
          "There was an error removing the selected Inventory Item.",
          { variant: "error" }
        );
      }
    }
  };

  renderTable = () => {
    const {
      selectInventoryItemsResult: { data, loading, error }
    } = this.props;
    if (loading) {
      return <Loader />;
    }
    if (error) {
      return <ErrorDisplay error={error} />;
    }
    return (
      <InventoryItemsTable
        inventoryItems={data ? data.inventoryItems.items : []}
        onEdit={this.onInventoryItemSelected}
        onRemove={this.onRemoveInventoryItem}
      />
    );
  };

  render() {
    const {
      selectInventoryItemsResult,
      createInventoryItemMutation,
      updateInventoryItemMutation
    } = this.props;
    const {
      isAddDialogOpen,
      selectedInventoryItem,
      isEditInventoryItem,
      isRemoveDialogOpen
    } = this.state;
    return (
      <PageLayout pageTitle="Inventory">
        {selectInventoryItemsResult.data && (
          <Fragment>
            {isAddDialogOpen && (
              <InventoryDetailsDialog
                onClose={this.onAddInventoryClose}
                onInventoryItemAdded={this.onInventoryItemAdded}
                conditions={
                  selectInventoryItemsResult.data.inventoryItemConditions
                }
                locations={
                  selectInventoryItemsResult.data.inventoryItemLocations
                }
                createInventoryItemMutation={createInventoryItemMutation}
              />
            )}
            {isEditInventoryItem && selectedInventoryItem && (
              <InventoryDetailsDialog
                onClose={this.onInventoryItemDeselected}
                onInventoryItemUpdated={this.onInventoryItemDeselected}
                inventoryItem={selectedInventoryItem}
                conditions={
                  selectInventoryItemsResult.data.inventoryItemConditions
                }
                locations={
                  selectInventoryItemsResult.data.inventoryItemLocations
                }
                updateInventoryItemMutation={updateInventoryItemMutation}
              />
            )}
            {selectedInventoryItem && isRemoveDialogOpen && (
              <ConfirmDeleteDialog
                onConfirmDelete={this.onConfirmRemoveInventoryItem}
                onDeleteCancel={this.onCancelRemoveInventoryItem}
                deleteMessage="Inventory Item"
              />
            )}
          </Fragment>
        )}
        <StandardCard>
          <FieldWrapper>
            <Button
              variant="contained"
              color="primary"
              onClick={this.onAddInventoryOpen}
            >
              <AppIcon icon={faPlus} standardRightMargin />
              Add Inventory
            </Button>
          </FieldWrapper>
          {this.renderTable()}
        </StandardCard>
      </PageLayout>
    );
  }
}

export default withSnackbar(Inventory);
