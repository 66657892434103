import React from "react";
import Text from "../../common/styled/text";
import InstructionsContainer from "../../common/styled/instructions-container";

const FinalEditing = () => (
  <InstructionsContainer>
    <Text>
      Once the PSD’s are uploaded to the hard drive, the images should be edited
      to be square. The square images should then be uploaded to the hard drive.
      The images should then be uploaded to the Google Drive.
    </Text>
  </InstructionsContainer>
);

export default FinalEditing;
