import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import moment from "moment-business-days";
import { Formik, Form } from "formik";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Button,
  Select,
  InputLabel,
  FormControl,
  Input,
  MenuItem,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import { DatePicker } from "material-ui-pickers";
import { isDateDisabled } from "../../../../utilities/date-helpers";
import AlertError from "../../../common/styled/alert-error";
import { FieldWrapper } from "../../../common/styled/field-wrapper";
import { TableHeaderCell } from "../../../common/table-header-cell";
import { transformGraphQLErrorForFormik } from "../../../../utilities/form-helpers";
import Loader from "../../../common/loader";

class AddContractDialog extends Component {
  state = {
    isCustomized: false,
    months: 0
  };

  onSubmit = async (values, { setErrors, setSubmitting }) => {
    try {
      const {
        customer,
        createCustomerContractMutation,
        onContractCreated
      } = this.props;
      await createCustomerContractMutation({
        variables: {
          input: {
            customerId: customer.id,
            startDate: moment(values.startDate).format("YYYY-MM-DD"),
            projects: values.projects.map((project, index) => ({
              ...project,
              amount: parseFloat(project.amount),
              startDate: moment(values.startDate)
                .add(index, "months")
                .format("YYYY-MM-DD")
            }))
          }
        }
      });
      setSubmitting(false);
      setErrors({});
      onContractCreated();
    } catch (e) {
      setErrors(transformGraphQLErrorForFormik(e));
      setSubmitting(false);
    }
  };

  onMonthsChanged = (values, setFieldValue) => e => {
    this.setState({ months: e.target.value }, () => {
      this.rebuildProjects(values, setFieldValue);
    });
  };

  onCustomizeToggle = (values, setFieldValue) => () => {
    const { isCustomized } = this.state;
    const newIsCustomized = !isCustomized;
    this.setState({ isCustomized: newIsCustomized }, () => {
      this.rebuildProjects(values, setFieldValue);
    });
  };

  rebuildProjects = (values, setFieldValue) => {
    const { isCustomized, months } = this.state;
    if (isCustomized) {
      const projects = [];
      for (let i = 0; i < months; i += 1) {
        if (values.projects && values.projects.length > i) {
          projects.push(values.projects[i]);
        } else {
          projects.push({
            name: `Month ${i + 1}`,
            amount: values.amount
          });
        }
      }
      setFieldValue("projects", projects);
    } else {
      const projects = [];
      for (let i = 0; i < months; i += 1) {
        projects.push({
          name: `Month ${i + 1}`,
          amount: values.amount
        });
      }
      setFieldValue("projects", projects);
    }
  };

  render() {
    const { onClose } = this.props;
    const { isCustomized, months } = this.state;
    return (
      <Formik
        onSubmit={this.onSubmit}
        initialValues={{
          projects: [],
          includesStrategy: true,
          startDate: moment().nextBusinessDay(),
          isExistingContract: false
        }}
        render={({
          values,
          handleChange,
          setFieldValue,
          errors,
          handleSubmit,
          isSubmitting
        }) => (
          <Form>
            <Dialog
              fullWidth
              open
              maxWidth="lg"
              onClose={onClose}
              disableBackdropClick
              scroll="body"
            >
              <DialogTitle>Add Contract</DialogTitle>
              <DialogContent>
                {errors.global && <AlertError message={errors.global} />}
                <Grid container>
                  <Grid item xs={12} lg={4}>
                    <FieldWrapper>
                      <FormControlLabel
                        label="Existing Contract"
                        id="isExistingContract"
                        name="isExistingContract"
                        control={<Checkbox onChange={handleChange} />}
                        checked={values.isExistingContract}
                      />
                    </FieldWrapper>
                    <FieldWrapper>
                      <FormControl fullWidth>
                        <InputLabel shrink htmlFor="months">
                          How many months?
                        </InputLabel>
                        <Select
                          value={months}
                          onChange={this.onMonthsChanged(values, setFieldValue)}
                          input={<Input name="months" id="months" />}
                          name="months"
                        >
                          <MenuItem value={0}>Select</MenuItem>
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                          <MenuItem value={4}>4</MenuItem>
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={6}>6</MenuItem>
                          <MenuItem value={7}>7</MenuItem>
                          <MenuItem value={8}>8</MenuItem>
                          <MenuItem value={9}>9</MenuItem>
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={11}>11</MenuItem>
                          <MenuItem value={12}>12</MenuItem>
                        </Select>
                      </FormControl>
                    </FieldWrapper>
                    <FieldWrapper>
                      <DatePicker
                        label="Start Date"
                        value={values.startDate}
                        format="M/D/YYYY"
                        maxDate={moment()
                          .nextBusinessDay()
                          .add(1, "year")}
                        placeholder={moment()
                          .nextBusinessDay()
                          .format("M/D/YYYY")}
                        shouldDisableDate={isDateDisabled(
                          !values.isExistingContract,
                          !values.isExistingContract,
                          !values.isExistingContract
                        )}
                        // handle clearing outside => pass plain array if you are not controlling value outside
                        disableOpenOnEnter
                        animateYearScrolling={false}
                        fullWidth
                        onChange={date => setFieldValue("startDate", date)}
                      />
                    </FieldWrapper>
                    <FieldWrapper>
                      <TextField
                        label="Amount"
                        id="amount"
                        name="amount"
                        placeholder="0.00"
                        value={values.amount}
                        onChange={handleChange}
                        onBlur={() =>
                          this.rebuildProjects(values, setFieldValue)
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          )
                        }}
                        fullWidth
                        disabled={isCustomized}
                        helperText={
                          !isCustomized && errors["projects[0].amount"]
                        }
                        error={!isCustomized && !!errors["projects[0].amount"]}
                      />
                    </FieldWrapper>
                    <FieldWrapper>
                      <TextField
                        label="Contract Notes"
                        id="notes"
                        name="notes"
                        value={values.notes}
                        onChange={handleChange}
                        fullWidth
                      />
                    </FieldWrapper>
                    <FieldWrapper>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={this.onCustomizeToggle(
                              values,
                              setFieldValue
                            )}
                            checked={isCustomized}
                          />
                        }
                        label="Customize each month of contract"
                      />
                    </FieldWrapper>
                  </Grid>
                </Grid>
                {months > 0 && (
                  <Table style={{ marginBottom: "1em" }}>
                    <TableHead>
                      <TableRow>
                        <TableHeaderCell>Month #</TableHeaderCell>
                        <TableHeaderCell>Start Date</TableHeaderCell>
                        <TableHeaderCell>Amount</TableHeaderCell>
                        <TableHeaderCell>Notes</TableHeaderCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {values.projects &&
                        values.projects.map((project, index) => (
                          <TableRow key={project.name}>
                            <TableCell>Month {index + 1}</TableCell>
                            <TableCell>
                              {moment(values.startDate)
                                .add(index, "months")
                                .format("M/D/YYYY")}
                            </TableCell>
                            <TableCell>
                              <TextField
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                  )
                                }}
                                id={`projects[${index}].amount`}
                                name={`projects[${index}].amount`}
                                type="number"
                                placeholder="0.00"
                                value={project.amount}
                                onChange={handleChange}
                                disabled={!isCustomized}
                                error={
                                  isCustomized &&
                                  !!errors[`projects[${index}].amount`]
                                }
                                helperText={
                                  isCustomized &&
                                  errors[`projects[${index}].amount`]
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                id={`projects[${index}].notes`}
                                name={`projects[${index}].notes`}
                                value={project.notes}
                                onChange={handleChange}
                                placeholder="Notes"
                                disabled={!isCustomized}
                                fullWidth
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  {!isSubmitting && (
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ marginRight: 8 }}
                    />
                  )}
                  {isSubmitting && <Loader />}
                  Create Contract
                </Button>
                <Button
                  variant="text"
                  onClick={onClose}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        )}
      />
    );
  }
}

AddContractDialog.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default AddContractDialog;
