import React, { Component, Fragment } from "react";
import moment from "moment";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Button,
  TablePagination
} from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faInfoCircle,
  faLayerGroup,
  faAngleRight
} from "@fortawesome/free-solid-svg-icons";
import { MutationFn, QueryResult } from "react-apollo";
import { withSnackbar, InjectedNotistackProps } from "notistack";
import GlobalAvatar from "../global-avatar";
import AppLink from "../common/app-link";
import { TableHeaderCell } from "../common/table-header-cell";
import SelectUserDialog from "../../components/common/user-select/select-user-dialog";
import AppIcon from "../common/app-icon";
import {
  SelectTasksViewer,
  SelectTasksItems,
  TasksUpdateTaskMutation,
  TasksUpdateTaskVariables,
  TaskStatusEnum,
  SelectTasksQuery,
  SelectTasksVariables
} from "../../generated/graphql";
import { ROLES } from "../../constants/roles";
import ErrorDisplay from "../common/error-display";
import Loader from "../common/loader";
import Empty from "./empty";

interface Props extends InjectedNotistackProps {
  queryResult: QueryResult<SelectTasksQuery, SelectTasksVariables>;
  updateTaskMutation: MutationFn<
    TasksUpdateTaskMutation,
    TasksUpdateTaskVariables
  >;
  onPreviousPage: () => any;
  onNextPage: () => any;
  limit: number;
  offset: number;
}

interface State {
  taskIdToAssign: number | null;
}

class TasksTable extends Component<Props, State> {
  state = {
    taskIdToAssign: null
  };

  onAssignUser = (taskId: number) => {
    this.setState({ taskIdToAssign: taskId });
  };

  onAssignUserClose = () => {
    this.setState({ taskIdToAssign: null });
  };

  onUserSelected = async (userId: number | null) => {
    const { updateTaskMutation, enqueueSnackbar, queryResult } = this.props;
    const { taskIdToAssign } = this.state;

    if (!taskIdToAssign) {
      return;
    }

    try {
      await updateTaskMutation({
        variables: {
          input: { taskId: taskIdToAssign, assignedUserId: userId }
        }
      });
      queryResult.refetch();
      this.onAssignUserClose();
      enqueueSnackbar(`Task assigned successfully!`, {
        variant: "success"
      });
    } catch (e) {
      enqueueSnackbar(e.message);
    }
  };

  render() {
    const {
      queryResult: { data, error, loading },
      onNextPage,
      onPreviousPage,
      limit,
      offset
    } = this.props;

    if (error) {
      return <ErrorDisplay error={error} />;
    }
    if (loading) {
      return <Loader />;
    }

    if (!data) {
      return <Empty />;
    }

    if (!data || !data.viewer || !data.tasks || !data.tasks.items) {
      return <Empty />;
    }

    const tasks = data.tasks.items;

    const {
      tasks: {
        pageInfo: { hasPreviousPage, hasNextPage },
        totalCount
      }
    } = data;

    const { taskIdToAssign } = this.state;
    return (
      <Fragment>
        {taskIdToAssign && (
          <SelectUserDialog
            roles={[ROLES.EMPLOYEE]}
            onClose={this.onAssignUserClose}
            onUserSelected={this.onUserSelected}
            allowUnassigned
          />
        )}
        <TablePagination
          component="div"
          count={totalCount}
          rowsPerPage={limit}
          page={offset / limit}
          rowsPerPageOptions={[limit]}
          onChangePage={(e, page) => {
            const currentPage = offset / limit;
            if (page > currentPage) {
              onNextPage();
            } else {
              onPreviousPage();
            }
          }}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableHeaderCell>Task</TableHeaderCell>
              <TableHeaderCell>Customer</TableHeaderCell>
              <TableHeaderCell>Assigned User</TableHeaderCell>
              <TableHeaderCell>Due</TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks &&
              tasks.map(task => (
                <TableRow key={task.id}>
                  <TableCell>
                    {task.customerProductBatch && (
                      <span>
                        <AppLink
                          to={`/batches/${task.customerProductBatch.id}`}
                          title="View Batch"
                        >
                          <AppIcon icon={faLayerGroup} standardRightMargin />#
                          {task.customerProductBatch.id}
                        </AppLink>
                        <AppIcon
                          icon={faAngleRight}
                          wideLeftMargin
                          wideRightMargin
                        />
                      </span>
                    )}
                    {task.title && task.title.length > 0 ? (
                      <AppLink to={`/tasks/${task.id}`}>{task.title}</AppLink>
                    ) : (
                      <AppLink to={`/tasks/${task.id}`}>
                        {task.taskType.name}
                      </AppLink>
                    )}
                  </TableCell>
                  <TableCell>
                    {task.customer && (
                      <AppLink to={`/customers/${task.customer.id}`}>
                        {task.customer.name}
                      </AppLink>
                    )}
                  </TableCell>
                  <TableCell>
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <GlobalAvatar
                        userId={task.assignedUser ? task.assignedUser.id : null}
                        size="small"
                        style={{ marginRight: ".5em" }}
                      />
                      {task.assignedUser
                        ? task.assignedUser.fullName
                        : "Unassigned"}
                      {task.statusId === TaskStatusEnum.Available && (
                        <Button
                          size="small"
                          color="primary"
                          style={{ marginLeft: ".5rem" }}
                          onClick={() => this.onAssignUser(task.id)}
                        >
                          Assign
                        </Button>
                      )}
                    </span>
                  </TableCell>
                  <TableCell>
                    {moment(task.dueDate) < moment() &&
                      task.statusId !== TaskStatusEnum.Completed && (
                        <span style={{ color: "red" }}>
                          <Tooltip title={moment(task.dueDate).format("LLL")}>
                            <AppIcon
                              icon={faExclamationTriangle}
                              standardRightMargin
                            />
                          </Tooltip>
                          {moment(task.dueDate).fromNow()}
                        </span>
                      )}
                    {(moment(task.dueDate) >= moment() ||
                      task.statusId === TaskStatusEnum.Completed) && (
                      <span>
                        <Tooltip title={moment(task.dueDate).format("LLL")}>
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            style={{ marginRight: ".5em" }}
                          />
                        </Tooltip>
                        {moment(task.dueDate).fromNow()}
                      </span>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalCount}
          rowsPerPage={limit}
          page={offset / limit}
          rowsPerPageOptions={[limit]}
          onChangePage={(e, page) => {
            const currentPage = offset / limit;
            if (page > currentPage) {
              onNextPage();
            } else {
              onPreviousPage();
            }
          }}
        />
      </Fragment>
    );
  }
}

export default withSnackbar(TasksTable);
