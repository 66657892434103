import React, { Component } from "react";
import {
  Grid,
  Button,
  Hidden,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "@material-ui/core";
import PageLayout from "../common/page-layout";
import TaskDetails from "./task-details";
import AlertInfo from "../common/styled/alert-info";
import { StandardCard } from "../common/standard-card";
import {
  SelectTaskTask,
  GetUsersForUserSelectItems
} from "../../generated/graphql";
import UserSelectDialog from "../common/user-select/select-user-dialog";

interface Props {
  roles: string[];
  task: SelectTaskTask;
  onAssignToMe: () => any;
  goBack: () => any;
  onAssignToUser: (userId: number | null) => any;
}

interface State {
  isAssigningUser: boolean;
}

class Unassigned extends Component<Props, State> {
  state = {
    isAssigningUser: false
  };

  onAssignUser = () => {
    this.setState({ isAssigningUser: true });
  };

  onAssignUserClose = () => {
    this.setState({ isAssigningUser: false });
  };

  onUserAssigned = async (userId: number | null) => {
    const { onAssignToUser } = this.props;
    await onAssignToUser(userId);
  };

  render() {
    const { task, onAssignToMe, goBack, roles } = this.props;
    const { isAssigningUser } = this.state;
    return (
      <PageLayout pageTitle="Unassigned Task">
        {isAssigningUser && (
          <UserSelectDialog
            roles={roles}
            allowUnassigned={false}
            onClose={this.onAssignUserClose}
            onUserSelected={this.onUserAssigned}
          />
        )}
        <StandardCard title={task.taskType.name}>
          <Hidden smUp>
            <ExpansionPanel>
              <ExpansionPanelSummary>Details</ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <TaskDetails task={task} />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Hidden>
          <Grid container spacing={16}>
            <Grid item xs={12} lg={8}>
              <AlertInfo message="This task hasn't been assigned yet." />
              <div style={{ marginTop: "1em" }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: ".5em" }}
                  onClick={onAssignToMe}
                >
                  Assign to Me
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ marginRight: ".5em" }}
                  onClick={this.onAssignUser}
                >
                  Assign User
                </Button>
                <Button onClick={goBack}>Go Back</Button>
              </div>
            </Grid>
            <Grid item lg={4}>
              <StandardCard title="Additional Information">
                <TaskDetails task={task} />
              </StandardCard>
            </Grid>
          </Grid>
        </StandardCard>
      </PageLayout>
    );
  }
}

export default Unassigned;
