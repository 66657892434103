import React from "react";
import Text from "../../common/styled/text";
import InstructionsContainer from "../../common/styled/instructions-container";

const StudioAndLifestylePhotoShoot = () => (
  <InstructionsContainer>
    <Text>The photographer can now shoot the product(s).</Text>
  </InstructionsContainer>
);

export default StudioAndLifestylePhotoShoot;
