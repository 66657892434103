import React, { Component } from "react";

import ContractDialog from "./contract-dialog";
import {
  CustomerContractComponent,
  DeleteCustomerContractComponent
} from "../../../../generated/graphql";

interface Props {
  customerContractId: number | null;
  onCancel: () => any;
}

interface State {}

class ContractDialogContainer extends Component<Props, State> {
  state = {};

  render() {
    const { customerContractId } = this.props;

    if (!customerContractId) {
      return null;
    }

    return (
      <DeleteCustomerContractComponent>
        {deleteCustomerContractMutation => (
          <CustomerContractComponent variables={{ customerContractId }}>
            {queryResult => (
              <ContractDialog
                queryResult={queryResult}
                customerContractId={customerContractId}
                deleteCustomerContractMutation={deleteCustomerContractMutation}
                {...this.props}
              />
            )}
          </CustomerContractComponent>
        )}
      </DeleteCustomerContractComponent>
    );
  }
}

export default ContractDialogContainer;
