import React from "react";
import styled from "styled-components";
import Text from "../../common/styled/text";
import InstructionsContainer from "../../common/styled/instructions-container";
import RequestInventoryDocImage from "../../../assets/request-inventory-doc.jpg";

const StyledImage = styled.img`
  margin-top: 0.8rem;
  width: 21.88rem;
  height: auto;
`;

const RequestInventory = () => (
  <InstructionsContainer>
    <Text>
      The two prerequisites to beginning a photography batch are having the
      product onsite in photographable condition and having the proposal
      accepted. The Account Manager is responsible for helping the client
      coordinate the shipping of their inventory so we can work on their batch.
      The following image (download from Google Drive) should be put into an
      email so our client’s know what to expect:
    </Text>
    <StyledImage src={RequestInventoryDocImage} />
  </InstructionsContainer>
);

export default RequestInventory;
