import React, { FunctionComponent } from "react";
import { InjectedNotistackProps, withSnackbar } from "notistack";

/* Child Components */
import AddPhotographyDialog from "./add-photography-dialog";
import Loader from "../../../common/loader";

/* GraphQL Imports */
import {
  AddPhotographyComponent,
  AddPhotographyCreateCustomerProductBatchComponent,
  SelectRoadmapCustomer,
  SelectRoadmapCustomerProjects,
  AddPhotographyProductCategories,
  AddPhotographyCustomer
} from "../../../../generated/graphql";

interface Props extends InjectedNotistackProps {
  customer: SelectRoadmapCustomer;
  customerProject: SelectRoadmapCustomerProjects;
  onClose: () => void;
  onProductsAdded: () => void;
}

const AddBatchDialogContainer: FunctionComponent<Props> = props => {
  const { enqueueSnackbar, customer } = props;

  return (
    <AddPhotographyComponent variables={{ customerId: customer.id }}>
      {({ data, loading, error }) => {
        if (loading) {
          return <Loader />;
        }
        if (error) {
          enqueueSnackbar(
            "Something went wrong while attempting to add content.",
            { variant: "error" }
          );
          return null;
        }
        if (!data || !data.customer || !data.productCategories) {
          return null;
        }

        return (
          <AddPhotographyCreateCustomerProductBatchComponent>
            {createCustomerProductBatchMutation => (
              <AddPhotographyDialog
                topLevelCustomerSkus={
                  (data.customer as AddPhotographyCustomer).customerSkus.items
                }
                productCategories={
                  (data.productCategories as AddPhotographyProductCategories)
                    .items
                }
                createCustomerProductBatchMutation={
                  createCustomerProductBatchMutation
                }
                {...props}
              />
            )}
          </AddPhotographyCreateCustomerProductBatchComponent>
        );
      }}
    </AddPhotographyComponent>
  );
};

export default withSnackbar(AddBatchDialogContainer);
