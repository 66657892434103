import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Avatar,
  Button
} from "@material-ui/core";
import WorkIcon from "@material-ui/icons/Work";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

import AppLink from "../../common/app-link";
import { StandardCard } from "../../common/standard-card";
import { TableHeaderCell } from "../../common/table-header-cell";
import GlobalAvatar from "../../global-avatar";
import { CardTitle } from "../../common/styled/card-title";
import Text from "../../common/styled/text";

class Contract extends Component {
  noOp = () => {};

  render() {
    const { customer, customerContract } = this.props;

    return (
      <div>
        <Text>
          <AppLink to={`/customers/${customer.id}/contracts`}>
            <Button color="primary">
              <FontAwesomeIcon icon={faCaretLeft} style={{ marginRight: 8 }} />
              Back to Contracts
            </Button>
          </AppLink>
        </Text>
        <Grid container spacing={16}>
          <Grid item xs={12} lg={4}>
            <StandardCard>
              <List dense>
                <ListItem>
                  <Avatar>
                    <WorkIcon />
                  </Avatar>
                  <ListItemText
                    primary="Contract ID"
                    secondary={customerContract.id}
                  />
                </ListItem>
                <ListItem>
                  <Avatar>
                    <CalendarTodayIcon />
                  </Avatar>
                  <ListItemText
                    primary="Start Date"
                    secondary={moment(customerContract.startDate).format(
                      "M/D/YYYY"
                    )}
                  />
                </ListItem>
                <ListItem>
                  <Avatar>
                    <CalendarTodayIcon />
                  </Avatar>
                  <ListItemText
                    primary="Created Date"
                    secondary={moment(customerContract.createdAt).format(
                      "M/D/YYYY"
                    )}
                  />
                </ListItem>
                <ListItem>
                  <GlobalAvatar userId={customerContract.salesUser.id} />
                  <ListItemText
                    primary="Sales User"
                    secondary={customerContract.salesUser.fullName}
                  />
                </ListItem>
              </List>
            </StandardCard>
          </Grid>
          <Grid item xs={12} lg={8}>
            <StandardCard>
              <CardTitle>Projects</CardTitle>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeaderCell>Name</TableHeaderCell>
                    <TableHeaderCell>Start Date</TableHeaderCell>
                    <TableHeaderCell>Amount</TableHeaderCell>
                    <TableHeaderCell>Status</TableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customerContract &&
                    customerContract.customerProjects &&
                    customerContract.customerProjects.map(project => (
                      <TableRow key={project.id}>
                        <TableCell>
                          <AppLink
                            to={`/customers/${customer.id}/projects/${
                              project.id
                            }`}
                          >
                            {project.name}
                          </AppLink>
                        </TableCell>
                        <TableCell>
                          {moment(project.startDate).format("M/D/YYYY")}
                        </TableCell>
                        <TableCell>${project.amount}</TableCell>
                        <TableCell>{project.status.name}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </StandardCard>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Contract.propTypes = {
  customer: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  customerContract: PropTypes.shape({
    id: PropTypes.number,
    startDate: PropTypes.instanceOf(Date),
    projects: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
      })
    )
  }).isRequired
};

export default Contract;
