import React, { Fragment } from "react";
import Text from "../../common/styled/text";
import InstructionsContainer from "../../common/styled/instructions-container";

const HoldSubteamMeeting = () => (
  <InstructionsContainer>
    <Text>
      Once the rough draft of the proposal is created, the photographer will
      slack the Account Manager to set up the Subteam Meeting. During the
      Subteam Meeting, the photographer should clearly present their plan and
      see if the Account Manager (who represents the client) feels the proposed
      plan captures what the client hopes to see. Any changes to the proposal
      should be discussed in this meeting.
    </Text>
    <Text>
      Once the proposal is revised (if necessary), it should be put into the
      client’s In Progress folder for the AM to review. A Slack message should
      also be sent to the Account Manager letting them know that they need to
      review the proposal.
    </Text>
  </InstructionsContainer>
);

export default HoldSubteamMeeting;
