import React, { FunctionComponent } from "react";
import moment from "moment";

/* GraphQL Imports */
import { SelectCustomerProjects_Items } from "../../generated/graphql";

/* Material UI */
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Button
} from "@material-ui/core";

/* FontAwesome */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretRight,
  faCheck,
  faMapMarked
} from "@fortawesome/free-solid-svg-icons";

/* Common Components */
import AppLink from "../common/app-link";
import { TableHeaderCell } from "../common/table-header-cell";
import Text from "../common/styled/text";

interface Props {
  customerProjects: SelectCustomerProjects_Items[];
  onRecordSale: (customerProject: SelectCustomerProjects_Items) => void;
  canRecordSale: boolean;
}

const BillingTable: FunctionComponent<Props> = ({
  customerProjects,
  onRecordSale,
  canRecordSale
}) => {
  if (customerProjects && customerProjects.length === 0) {
    return <Text>No projects.</Text>;
  }
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeaderCell />
          <TableHeaderCell>Customer</TableHeaderCell>
          <TableHeaderCell>Project</TableHeaderCell>
          <TableHeaderCell>Start Date</TableHeaderCell>
          <TableHeaderCell>Amount</TableHeaderCell>
          <TableHeaderCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {customerProjects.map(customerProject => (
          <TableRow key={customerProject.id}>
            <TableCell style={{ width: 50 }}>
              <AppLink to={`/customers/${customerProject.customer.id}/roadmap`}>
                <Button>
                  <FontAwesomeIcon icon={faMapMarked} />
                </Button>
              </AppLink>
            </TableCell>
            <TableCell>
              <AppLink to={`/customers/${customerProject.customer.id}`}>
                {customerProject.customer.name}
              </AppLink>
            </TableCell>
            <TableCell>
              {customerProject.customerContract && (
                <span>
                  Contract #{customerProject.customerContract.id}
                  <FontAwesomeIcon
                    icon={faCaretRight}
                    style={{ marginLeft: ".5em", marginRight: ".5em" }}
                  />
                  {customerProject.name}
                </span>
              )}
              {!customerProject.customerContract && customerProject.name}
            </TableCell>
            <TableCell>
              {moment(customerProject.startDate).format("M/D/YYYY")}
            </TableCell>
            <TableCell>${customerProject.amount}</TableCell>
            <TableCell>
              {canRecordSale && (
                <Button
                  size="small"
                  color="primary"
                  onClick={() => onRecordSale(customerProject)}
                >
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ marginRight: ".5em" }}
                  />
                  Record Sale
                </Button>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default BillingTable;
