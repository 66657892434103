import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#2196f3"
    },
    secondary: {
      main: "#f28f4b"
    }
  },
  typography: {
    fontSize: 13,
    useNextVariants: true,
    fontFamily: `-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif`
  },
  shadows: [] as any
};

const theme = createMuiTheme(themeOptions);

export default theme;
