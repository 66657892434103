import React, { Component, Fragment } from "react";
import { TextField, InputAdornment, Button } from "@material-ui/core";
import SelectCustomerSkuDialog from "./select-customer-sku-dialog";
import { GetCustomerSkuForCustomerSkuSelectComponent } from "../../../generated/graphql";
import DangerButton from "../styled/danger-button";

interface Props {
  label: string;
  allowClear: boolean | null;
  customerId: number;
  customerSkuId: number | null;
  onCustomerSkuSelected: (customerSkuId: number | null) => void;
  disabled?: boolean;
}

interface State {}

class CustomerSkuSelectContainer extends Component<Props, State> {
  state = {
    isDialogOpen: false
  };

  onOpenSelector = () => {
    this.setState({ isDialogOpen: true });
  };

  onCloseSelector = () => {
    this.setState({ isDialogOpen: false });
  };

  onCustomerSkuSelected = (customerSkuId: number | null) => {
    const { onCustomerSkuSelected } = this.props;
    this.setState({ isDialogOpen: false });
    onCustomerSkuSelected(customerSkuId);
  };

  render() {
    const {
      customerSkuId,
      label,
      allowClear,
      customerId,
      disabled
    } = this.props;
    const { isDialogOpen } = this.state;
    return (
      <Fragment>
        {isDialogOpen && (
          <SelectCustomerSkuDialog
            customerId={customerId}
            onClose={this.onCloseSelector}
            onCustomerSkuSelected={this.onCustomerSkuSelected}
          />
        )}
        <GetCustomerSkuForCustomerSkuSelectComponent
          variables={{ customerSkuId: customerSkuId || 0 }}
          skip={!customerSkuId}
        >
          {({ data, loading, error }) => {
            let displayText = "Select a SKU";
            if (loading) {
              displayText = "Loading...";
            }
            if (error) {
              displayText = "An Error occurred.";
            }
            if (data && data.customerSku) {
              displayText = `${data.customerSku.sku} - ${
                data.customerSku.title
              }`;
            }
            return (
              <TextField
                value={displayText}
                label={label}
                fullWidth
                disabled={disabled}
                inputProps={{ readOnly: true }}
                InputProps={{
                  endAdornment: disabled ? null : (
                    <InputAdornment position="end">
                      {allowClear && customerSkuId && (
                        <DangerButton
                          size="small"
                          onClick={() => this.onCustomerSkuSelected(null)}
                        >
                          Clear
                        </DangerButton>
                      )}
                      <Button size="small" onClick={this.onOpenSelector}>
                        Select
                      </Button>
                    </InputAdornment>
                  )
                }}
              />
            );
          }}
        </GetCustomerSkuForCustomerSkuSelectComponent>
      </Fragment>
    );
  }
}

export default CustomerSkuSelectContainer;
