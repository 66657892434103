import gql from "graphql-tag";

export default gql`
  query groups($userId: Int!) {
    viewer {
      id
      groups {
        id
        name
      }
      assignableGroups {
        id
        name
      }
    }
    groups {
      id
      name
    }
    user(id: $userId) {
      id
      groups {
        id
        name
      }
    }
  }
`;
