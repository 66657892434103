import React, { Component } from "react";
import { Formik, Form, FormikActions } from "formik";
const moment = require("moment-business-days");

/* Material UI */
import {
  DialogContent,
  DialogTitle,
  Dialog,
  Select,
  DialogActions,
  Button,
  FormControl,
  InputLabel
} from "@material-ui/core";
import { DatePicker } from "material-ui-pickers";

/* FontAwesome */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

/* Common Components */
import { FieldWrapper } from "../../../common/styled/field-wrapper";
import AlertError from "../../../common/styled/alert-error";
import Text from "../../../common/styled/text";
import CustomerProductItemsTable from "./customer-product-items-table";
import { StandardCard } from "../../../common/standard-card";
import ChildCustomerSkusTable from "./child-customer-skus-table";
import { PRODUCTS } from "../../../../constants";
import Loader from "../../../common/loader";

/* Utilities */
import { isDateDisabled } from "../../../../utilities/date-helpers";
import { transformGraphQLErrorForFormik } from "../../../../utilities/form-helpers";

/* GraphQL & Apollo Imports */
import {
  AddPhotographyItems,
  AddPhotography_Items,
  AddPhotographyCreateCustomerProductBatchMutation,
  AddPhotographyCreateCustomerProductBatchVariables,
  SelectRoadmapCustomerProjects,
  SelectRoadmapCustomer
} from "../../../../generated/graphql";
import { MutationFn } from "react-apollo";

export interface CustomerProductItem {
  id: number;
  customerSkuId: number;
  productId: string;
  amount: number;
  quantity: number;
}

export interface CustomerProductMutationItem {
  customerSkuId: number;
  productId: string;
  amount: number;
}

interface FormValues {
  productId: number | null;
  startDate: Date;
  global?: string;
}

interface Props {
  customer: SelectRoadmapCustomer;
  customerProject: SelectRoadmapCustomerProjects;
  topLevelCustomerSkus: AddPhotographyItems[];
  productCategories: AddPhotography_Items[] | null;
  createCustomerProductBatchMutation: MutationFn<
    AddPhotographyCreateCustomerProductBatchMutation,
    AddPhotographyCreateCustomerProductBatchVariables
  >;
  onClose: () => void;
  onProductsAdded: () => void;
}

interface State {
  customerProductItems: CustomerProductItem[];
  topLevelCustomerSkuId: number | null;
}

class AddPhotographyDialog extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { topLevelCustomerSkus } = props;

    this.state = {
      customerProductItems: [],
      topLevelCustomerSkuId:
        topLevelCustomerSkus && topLevelCustomerSkus.length > 0
          ? topLevelCustomerSkus[0].id
          : null
    };
  }

  onFormSubmit = async (
    values: FormValues,
    actions: FormikActions<FormValues>
  ) => {
    const {
      customerProject,
      createCustomerProductBatchMutation,
      onProductsAdded
    } = this.props;

    const { customerProductItems } = this.state;

    try {
      if (!customerProductItems || customerProductItems.length === 0) {
        return;
      }

      const infographicCustomerProducts = customerProductItems.filter(
        cp => (cp as CustomerProductItem).productId === PRODUCTS.INFOGRAPHIC
      );

      const photoCustomerProducts = customerProductItems.filter(
        cp => (cp as CustomerProductItem).productId !== PRODUCTS.INFOGRAPHIC
      );

      if (infographicCustomerProducts.length > 0) {
        await createCustomerProductBatchMutation({
          variables: {
            input: {
              customerId: customerProject.customerId,
              customerProjectId: customerProject.id,
              startDate: moment(values.startDate).format("YYYY-MM-DD"),
              items: this.buildMutationItems(infographicCustomerProducts)
            }
          }
        });
      }

      if (photoCustomerProducts.length > 0) {
        await createCustomerProductBatchMutation({
          variables: {
            input: {
              customerId: customerProject.customerId,
              customerProjectId: customerProject.id,
              startDate: moment(values.startDate).format("YYYY-MM-DD"),
              items: this.buildMutationItems(photoCustomerProducts)
            }
          }
        });
      }
      actions.setSubmitting(false);
      actions.setErrors({});
      onProductsAdded();
    } catch (e) {
      actions.setErrors(transformGraphQLErrorForFormik(e));
      actions.setSubmitting(false);
    }
  };

  buildMutationItems = (customerProducts: CustomerProductItem[]) => {
    return customerProducts.reduce<CustomerProductMutationItem[]>(
      (prev, curr) => {
        if (curr.quantity > 1) {
          for (let i = 1; i <= curr.quantity; i += 1) {
            prev.push({
              productId: (curr as CustomerProductItem).productId,
              customerSkuId: (curr as CustomerProductItem).customerSkuId,
              amount: (curr as CustomerProductItem).amount
            });
          }
        } else {
          prev.push({
            productId: (curr as CustomerProductItem).productId,
            customerSkuId: (curr as CustomerProductItem).customerSkuId,
            amount: (curr as CustomerProductItem).amount
          });
        }
        return prev;
      },
      []
    );
  };

  onAddCustomerProductItem = (customerProductItem: {
    customerSkuId: number;
    productId: string;
    amount: number;
  }) => {
    this.setState(state => ({
      customerProductItems: [
        ...state.customerProductItems,
        {
          ...customerProductItem,
          id: state.customerProductItems.length,
          discount: 0,
          quantity:
            customerProductItem.productId === PRODUCTS.STUDIO_PHOTO ? 3 : 2
        }
      ]
    }));
  };

  onDeleteCustomerProductItem = (customerProductItemId: number) => {
    const { customerProductItems } = this.state;
    this.setState({
      customerProductItems: customerProductItems.filter(
        c => (c as CustomerProductItem).id !== customerProductItemId
      )
    });
  };

  onAmountChanged = (
    customerSkuId: number,
    productId: string,
    newAmountValue: string
  ) => {
    let parsedAmountValue = parseFloat(newAmountValue);
    if (isNaN(parsedAmountValue)) {
      parsedAmountValue = 0;
    }
    const { customerProductItems } = this.state;

    if (!customerProductItems || customerProductItems.length === 0) {
      return;
    }

    const updatedCustomerProductItems = customerProductItems.map(item => {
      if (
        (item as CustomerProductItem).customerSkuId !== customerSkuId ||
        (item as CustomerProductItem).productId !== productId
      ) {
        return item;
      }
      return {
        ...(item as CustomerProductItem),
        amount: parsedAmountValue
      };
    });
    this.setState({ customerProductItems: updatedCustomerProductItems });
  };

  onIncrementQuantity = (
    customerSkuId: number,
    productId: string,
    newQuantity: number
  ) => {
    const { customerProductItems } = this.state;

    if (!customerProductItems || customerProductItems.length === 0) {
      return;
    }

    const updatedCustomerProductItems = customerProductItems.map(item => {
      if (
        (item as CustomerProductItem).customerSkuId !== customerSkuId ||
        (item as CustomerProductItem).productId !== productId
      ) {
        return item;
      }
      return {
        ...(item as CustomerProductItem),
        quantity: newQuantity
      };
    });
    this.setState({ customerProductItems: updatedCustomerProductItems });
  };

  onSelectedTopLevelSkuChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ topLevelCustomerSkuId: Number(e.target.value) });
  };

  render() {
    const { onClose, customer, topLevelCustomerSkus } = this.props;
    const { topLevelCustomerSkuId, customerProductItems } = this.state;

    console.log(customerProductItems);
    return (
      <Formik
        initialValues={{
          productId: null,
          startDate: moment().nextBusinessDay()
        }}
        onSubmit={this.onFormSubmit}
        render={({
          values,
          setFieldValue,
          handleSubmit,
          errors,
          isSubmitting
        }) => (
          <Form>
            <Dialog
              fullWidth
              open
              onClose={onClose}
              maxWidth="lg"
              scroll="body"
              disableBackdropClick
            >
              <DialogTitle>Add Photography</DialogTitle>
              <DialogContent>
                {errors.global && <AlertError message={errors.global} />}
                <FieldWrapper>
                  <DatePicker
                    value={values.startDate}
                    fullWidth
                    onChange={date => setFieldValue("startDate", date)}
                    label="Start Date"
                    format="M/D/YYYY"
                    maxDate={moment()
                      .nextBusinessDay()
                      .add(1, "year")}
                    placeholder={moment()
                      .nextBusinessDay()
                      .format("M/D/YYYY")}
                    shouldDisableDate={isDateDisabled(true, true, false)}
                    // handle clearing outside => pass plain array if you are not controlling value outside
                    disableOpenOnEnter
                    animateYearScrolling={false}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <Text.HeaderXSmall>Selected SKUs</Text.HeaderXSmall>
                  <CustomerProductItemsTable
                    customer={customer}
                    customerProductItems={customerProductItems}
                    onDeleteCustomerProductItem={
                      this.onDeleteCustomerProductItem
                    }
                    onAmountChanged={this.onAmountChanged}
                    onIncrementQuantity={this.onIncrementQuantity}
                  />
                </FieldWrapper>
                <StandardCard title="Add more SKUs">
                  <FieldWrapper>
                    <FormControl fullWidth>
                      <InputLabel>SKU</InputLabel>
                      <Select
                        native
                        fullWidth
                        value={Number(topLevelCustomerSkuId)}
                        onChange={e => this.onSelectedTopLevelSkuChange(e)}
                      >
                        {topLevelCustomerSkus.map(customerSku => (
                          <option value={customerSku.id}>
                            {customerSku.sku} - {customerSku.title}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </FieldWrapper>
                  <ChildCustomerSkusTable
                    topLevelCustomerSkuId={topLevelCustomerSkuId}
                    customerProductItems={customerProductItems}
                    addCustomerProductItem={this.onAddCustomerProductItem}
                  />
                </StandardCard>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit()}
                  disabled={isSubmitting}
                >
                  {!isSubmitting && (
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ marginRight: ".5em" }}
                    />
                  )}
                  {isSubmitting && <Loader />}
                  Save and Finish
                </Button>
                <Button onClick={onClose} disabled={isSubmitting}>
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        )}
      />
    );
  }
}

export default AddPhotographyDialog;
