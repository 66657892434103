import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  DialogContent,
  DialogTitle,
  Dialog,
  Select,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { DatePicker } from "material-ui-pickers";
import moment from "moment-business-days";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { flattenDeep } from "lodash";

import { FieldWrapper } from "../../../common/styled/field-wrapper";
import AlertError from "../../../common/styled/alert-error";
import { isDateDisabled } from "../../../../utilities/date-helpers";
import { transformGraphQLErrorForFormik } from "../../../../utilities/form-helpers";
import Text from "../../../common/styled/text";
import CustomerProductItemsTable from "./customer-product-items-table";
import { StandardCard } from "../../../common/standard-card";
import ChildCustomerSkusTable from "./child-customer-skus-table";
import Loader from "../../../common/loader";

/* In File Styled Components */
const CheckBoxWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

class AddContentDialog extends Component {
  getInitialState = () => {
    const { topLevelCustomerSkus } = this.props;
    return {
      customerProductItems: [],
      topLevelCustomerSkuId:
        topLevelCustomerSkus && topLevelCustomerSkus.length > 0
          ? topLevelCustomerSkus[0].id
          : null
    };
  };

  state = this.getInitialState();

  onFormSubmit = async (values, { setErrors, setSubmitting }) => {
    const {
      customerProject,
      createCustomerProductMutation,
      createCustomerProductBatchMutation,
      onProductsAdded
    } = this.props;
    const { customerProductItems } = this.state;

    try {
      if (!customerProductItems || customerProductItems.length === 0) {
        // Maybe show an error message here
        return;
      }
      if (customerProductItems.length === 1) {
        await createCustomerProductMutation({
          variables: {
            input: {
              customerId: customerProject.customerId,
              customerProjectId: customerProject.id,
              startDate: moment(values.startDate).format("YYYY-MM-DD"),
              customerSkuId: customerProductItems[0].customerSkuId,
              discount: customerProductItems[0].discount / 100,
              productId: customerProductItems[0].productId
            }
          }
        });
      } else {
        await createCustomerProductBatchMutation({
          variables: {
            input: {
              customerId: customerProject.customerId,
              customerProjectId: customerProject.id,
              startDate: moment(values.startDate).format("YYYY-MM-DD"),
              items: customerProductItems.map(item => ({
                productId: item.productId,
                customerSkuId: item.customerSkuId,
                discount: item.discount / 100
              }))
            }
          }
        });
      }
      setSubmitting(false);
      setErrors({});
      onProductsAdded();
    } catch (e) {
      setErrors(transformGraphQLErrorForFormik(e));
      setSubmitting(false);
    }
  };

  onAddCustomerProductItem = customerProductItem => {
    this.setState(state => ({
      customerProductItems: [
        ...state.customerProductItems,
        {
          ...customerProductItem,
          id: state.customerProductItems.length,
          discount: 0
        }
      ]
    }));
  };

  onDeleteCustomerProductItem = customerProductItemId => {
    const { customerProductItems } = this.state;
    this.setState({
      customerProductItems: customerProductItems.filter(
        c => c.id !== customerProductItemId
      )
    });
  };

  onDiscountChanged = (customerSkuId, productId, newDiscountValue) => {
    let parsedDiscountValue = parseFloat(Number(newDiscountValue));
    const { productCategories } = this.props;
    const product = flattenDeep(productCategories.map(c => c.products)).find(
      x => x.id === productId
    );
    if (!product || !product.currentProductPrice) {
      return;
    }

    if (
      parsedDiscountValue >
      product.currentProductPrice.maxAdditionalDiscountPercentage * 100
    ) {
      parsedDiscountValue =
        product.currentProductPrice.maxAdditionalDiscountPercentage * 100;
    }

    const { customerProductItems } = this.state;
    const updatedCustomerProductItems = customerProductItems.map(item => {
      if (
        item.customerSkuId !== customerSkuId ||
        item.productId !== productId
      ) {
        return item;
      }
      return {
        ...item,
        discount: parsedDiscountValue
      };
    });
    this.setState({ customerProductItems: updatedCustomerProductItems });
  };

  onSelectedTopLevelSkuChange = e => {
    this.setState({ topLevelCustomerSkuId: e.target.value });
  };

  render() {
    const { onClose, customer, topLevelCustomerSkus } = this.props;
    const { topLevelCustomerSkuId, customerProductItems } = this.state;
    return (
      <Formik
        initialValues={{
          productId: null,
          startDate: moment().nextBusinessDay(),
          isQuestionnaireVerified: false
        }}
        onSubmit={this.onFormSubmit}
        render={({
          values,
          setFieldValue,
          handleSubmit,
          errors,
          handleChange,
          isSubmitting
        }) => (
          <Form>
            <Dialog
              fullWidth
              open
              onClose={onClose}
              maxWidth="lg"
              scroll="body"
              disableBackdropClick
            >
              <DialogTitle>Add Content</DialogTitle>
              <DialogContent>
                {errors.global && <AlertError message={errors.global} />}
                <FieldWrapper>
                  <DatePicker
                    value={values.startDate}
                    fullWidth
                    onChange={date => setFieldValue("startDate", date)}
                    label="Start Date"
                    format="M/D/YYYY"
                    maxDate={moment()
                      .nextBusinessDay()
                      .add(1, "year")}
                    placeholder={moment()
                      .nextBusinessDay()
                      .format("M/D/YYYY")}
                    shouldDisableDate={isDateDisabled(true, true, false)}
                    // handle clearing outside => pass plain array if you are not controlling value outside
                    disableOpenOnEnter
                    animateYearScrolling={false}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <Text.HeaderXSmall>Selected SKUs</Text.HeaderXSmall>
                  <CustomerProductItemsTable
                    customer={customer}
                    customerProductItems={customerProductItems}
                    onDeleteCustomerProductItem={
                      this.onDeleteCustomerProductItem
                    }
                    onDiscountChanged={this.onDiscountChanged}
                  />
                </FieldWrapper>
                <StandardCard title="Add more SKUs">
                  <FieldWrapper>
                    <FormControl label="SKU" fullWidth>
                      <InputLabel>SKU</InputLabel>
                      <Select
                        native
                        fullWidth
                        value={topLevelCustomerSkuId}
                        onChange={this.onSelectedTopLevelSkuChange}
                      >
                        {topLevelCustomerSkus.map(customerSku => (
                          <option value={customerSku.id}>
                            {customerSku.sku} - {customerSku.title}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </FieldWrapper>
                  <ChildCustomerSkusTable
                    topLevelCustomerSkuId={topLevelCustomerSkuId}
                    customerProductItems={customerProductItems}
                    addCustomerProductItem={this.onAddCustomerProductItem}
                  />
                </StandardCard>
              </DialogContent>
              <CheckBoxWrapper>
                <FormControlLabel
                  name="isQuestionnaireVerified"
                  label="I have verified that the customer has completed the questionnaire."
                  control={<Checkbox onChange={handleChange} />}
                  checked={values.isQuestionnaireVerified}
                />
              </CheckBoxWrapper>
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={!values.isQuestionnaireVerified || isSubmitting}
                >
                  {!isSubmitting && (
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ marginRight: ".5em" }}
                    />
                  )}
                  {isSubmitting && <Loader />}
                  Save and Finish
                </Button>
                <Button onClick={onClose} disabled={isSubmitting}>
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        )}
      />
    );
  }
}

AddContentDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  topLevelCustomerSkus: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      sku: PropTypes.string,
      title: PropTypes.string
    })
  ).isRequired,
  productCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      products: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string
        })
      )
    })
  ).isRequired,
  customer: PropTypes.shape({
    id: PropTypes.number
  }).isRequired
};

export default AddContentDialog;
