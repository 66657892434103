import React, { Component } from "react";

/* Graphql Imports */
import {
  SelectCustomerSkuComponent,
  UpdateCustomerSkuDetailsComponent,
  GetCustomerCustomer
} from "../../../../generated/graphql";

/* Childe Components */
import SkuDetails from "./sku-details";
import Loader from "../../../common/loader";
import ErrorDisplay from "../../../common/error-display";

interface Props {
  customer: GetCustomerCustomer;
  skuId: number;
}

class SkuDetailsContainer extends Component<Props> {
  render() {
    const { skuId, customer } = this.props;
    return (
      <UpdateCustomerSkuDetailsComponent>
        {updateCustomerSkuDetailsMutation => (
          <SelectCustomerSkuComponent
            variables={{ customerSkuId: Number(skuId) }}
            fetchPolicy="network-only"
          >
            {queryResult => {
              const { data, loading, error, refetch } = queryResult;
              if (loading) {
                return <Loader />;
              }

              if (error) {
                return <ErrorDisplay error={error} />;
              }

              if (!data || !data.customerSku) {
                return null;
              }

              return (
                <SkuDetails
                  refetch={refetch}
                  customer={customer}
                  customerSku={data.customerSku}
                  updateCustomerSkuDetailsMutation={
                    updateCustomerSkuDetailsMutation
                  }
                />
              );
            }}
          </SelectCustomerSkuComponent>
        )}
      </UpdateCustomerSkuDetailsComponent>
    );
  }
}

export default SkuDetailsContainer;
