import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faSync
} from "@fortawesome/free-solid-svg-icons";
import { StandardCard } from "./standard-card";
import Text from "./styled/text";

/** Rollbar Utils */
import rollbar from "../../utilities/rollbar.ts";

/* ErrorDisplay is used more often with failed API requests. Commonly used when a query response returns an error */

const ErrorDisplay = ({ error, refetch, history }) => {
  if (
    error.graphQLErrors &&
    error.graphQLErrors.length > 0 &&
    !!error.graphQLErrors.find(
      e => e.message.indexOf("Insufficient permissions") >= 0
    )
  ) {
    const { goBack } = history;

    /* Rollbar error log */
    rollbar.error(`Component: ErrorDisplay -> ${error.message}`, { error });

    goBack();
    return null;
  }
  return (
    <StandardCard>
      <Text.HeaderLarge>
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          style={{ marginRight: ".5em" }}
        />
        Uh oh!
      </Text.HeaderLarge>
      <Text>It looks like something went wrong trying to load.</Text>
      <Text>
        <strong>Here&apos;s the error:</strong> {error.message}
      </Text>

      <ExpansionPanel style={{ maxWidth: 800 }}>
        <ExpansionPanelSummary>Details</ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {error.graphQLErrors && error.graphQLErrors.length > 0 && (
            <div>
              <Text.HeaderSmall>GraphQL Errors</Text.HeaderSmall>
              {error.graphQLErrors.map(graphQLError => (
                <div>
                  <Text.HeaderXSmall>{graphQLError.message}</Text.HeaderXSmall>
                  <ul>
                    {graphQLError.locations && (
                      <li>
                        <strong>Locations:</strong>{" "}
                        {JSON.stringify(graphQLError.locations, null, 2)}
                      </li>
                    )}
                    {graphQLError.path && (
                      <li>
                        <strong>Path:</strong>{" "}
                        {JSON.stringify(graphQLError.path, null, 2)}
                      </li>
                    )}
                  </ul>
                </div>
              ))}
            </div>
          )}
          <Text.HeaderSmall>Stack</Text.HeaderSmall>
          <ul>
            {error.stack.split("\n").map(line => (
              <li>{line}</li>
            ))}
          </ul>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      {refetch && (
        <p style={{ marginTop: "1em" }}>
          <Button type="primary" onClick={() => refetch()}>
            <FontAwesomeIcon icon={faSync} style={{ marginRight: ".5em" }} />
            Try Again
          </Button>
        </p>
      )}
    </StandardCard>
  );
};

ErrorDisplay.defaultProps = {
  refetch: null
};

ErrorDisplay.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string
  }).isRequired,
  refetch: PropTypes.func,
  history: PropTypes.shape({
    goBack: PropTypes.func
  }).isRequired
};

export default withRouter(ErrorDisplay);
