import React from "react";
import { Paper, withStyles, Theme, colors } from "@material-ui/core";

import { CSSProperties } from "jss/css";
import { CardTitle } from "./styled/card-title";

interface StandardCardProps {
  children: any;
  classes: any;
  title?: string;
  rest?: any;
  style?: CSSProperties;
}

const styles = (theme: Theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2
  }
});

/* StandardCard is a reusable card component used throughout the app giving providing consistant styling and layout for Cards */

const card = ({ children, classes, title, rest }: StandardCardProps) => (
  <Paper className={classes.root} elevation={1} {...rest}>
    {title && <CardTitle>{title}</CardTitle>}
    {children}
  </Paper>
);

export const StandardCard = withStyles(styles)(card);
