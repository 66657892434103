import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button,
  TableFooter,
  Input,
  InputAdornment
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { Query } from "react-apollo";

import CUSTOMER_PRODUCT_ITEMS_QUERY from "./customer-product-items-query";
import { TableHeaderCell } from "../../../common/table-header-cell";
import Text from "../../../common/styled/text";
import Loader from "../../../common/loader";
import ErrorDisplay from "../../../common/error-display";

/* In File Styled Components */
const StyledInput = styled(Input)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
`;

class CustomerProductItemsTable extends Component {
  state = {};

  render() {
    const {
      customerProductItems,
      customer,
      onDeleteCustomerProductItem,
      onDiscountChanged
    } = this.props;
    return (
      <Query
        query={CUSTOMER_PRODUCT_ITEMS_QUERY}
        skip={customerProductItems.length === 0}
        variables={{
          customerId: customer.id,
          customerSkuIds: [
            ...customerProductItems.map(
              customerProductItem => customerProductItem.customerSkuId
            )
          ],
          productIds: customerProductItems.map(
            customerProductItem => customerProductItem.productId
          )
        }}
      >
        {({ data, loading, error }) => {
          if (loading && !data && !data.customer) {
            return <Loader />;
          }
          if (error) {
            return <ErrorDisplay error={error} />;
          }
          if (customerProductItems.length === 0) {
            return <Text>No SKUs added yet.</Text>;
          }
          if (!data || !data.customer) {
            return null;
          }
          let total = 0;
          return (
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeaderCell />
                  <TableHeaderCell>SKU</TableHeaderCell>
                  <TableHeaderCell>Product</TableHeaderCell>
                  <TableHeaderCell>Client Price</TableHeaderCell>
                  <TableHeaderCell>Max Discount</TableHeaderCell>
                  <TableHeaderCell>Additional Discount</TableHeaderCell>
                  <TableHeaderCell>Total</TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customerProductItems.map(customerProductItem => {
                  const customerSku = data.customer.customerSkus.items.find(
                    c => c.id === customerProductItem.customerSkuId
                  );
                  const product = data.products.items.find(
                    p => p.id === customerProductItem.productId
                  );
                  if (!customerSku || !product) {
                    return null;
                  }
                  const totalDiscountPercentage =
                    product.currentProductPrice.clientDiscountPercentage +
                    customerProductItem.discount / 100;
                  const totalPrice =
                    product.currentProductPrice.amount -
                    totalDiscountPercentage *
                      product.currentProductPrice.amount;
                  total += totalPrice;

                  return (
                    <TableRow key={customerProductItem.id}>
                      <TableCell>
                        <Button
                          size="small"
                          onClick={() =>
                            onDeleteCustomerProductItem(customerProductItem.id)
                          }
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </TableCell>
                      <TableCell>
                        {customerSku && customerSku.parent && (
                          <span>
                            {customerSku.parent.sku}
                            <FontAwesomeIcon
                              icon={faCaretRight}
                              style={{
                                marginLeft: ".5em",
                                marginRight: ".5em"
                              }}
                            />
                          </span>
                        )}
                        {customerSku.sku}
                      </TableCell>
                      <TableCell>{product.name}</TableCell>
                      <TableCell>
                        {product.currentProductPrice
                          ? `$${(
                              product.currentProductPrice.amount *
                              (1 -
                                product.currentProductPrice
                                  .clientDiscountPercentage)
                            ).toFixed(2)}`
                          : "$0"}
                      </TableCell>
                      <TableCell>
                        {product.currentProductPrice
                          ? `${(
                              product.currentProductPrice
                                .maxAdditionalDiscountPercentage * 100
                            ).toFixed(2)}%`
                          : "0%"}
                      </TableCell>
                      <TableCell>
                        <StyledInput
                          value={
                            customerProductItem.discount === 0
                              ? null
                              : customerProductItem.discount
                          }
                          type="number"
                          placeholder="0.00"
                          onChange={e => {
                            onDiscountChanged(
                              customerProductItem.customerSkuId,
                              customerProductItem.productId,
                              Number(e.target.value)
                            );
                          }}
                          endAdornment={
                            <InputAdornment position="end">%</InputAdornment>
                          }
                        />
                      </TableCell>
                      <TableCell style={{ width: 150 }}>
                        ${totalPrice.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={6} />
                  <TableCell>
                    <strong>${total.toFixed(2)}</strong>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          );
        }}
      </Query>
    );
  }
}

CustomerProductItemsTable.propTypes = {
  customerProductItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      productId: PropTypes.string
    })
  ).isRequired,
  customer: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  onDeleteCustomerProductItem: PropTypes.func.isRequired,
  onDiscountChanged: PropTypes.func.isRequired
};

export default CustomerProductItemsTable;
