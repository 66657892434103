import React, { Component } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faTimes,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import prettyBytes from "pretty-bytes";

import { TableHeaderCell } from "../../common/table-header-cell";
import {
  CustomerFilesCustomerFiles,
  CustomerFilesItems
} from "../../../generated/graphql";

interface Props {
  customerFiles: CustomerFilesItems[];
}

interface State {}

class FilesTable extends Component<Props, State> {
  state = {};

  render() {
    const { customerFiles } = this.props;
    if (customerFiles.length === 0) {
      return "No customer files.";
    }
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell>Name</TableHeaderCell>
            <TableHeaderCell>Type</TableHeaderCell>
            <TableHeaderCell>Size</TableHeaderCell>
            <TableHeaderCell>Created</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customerFiles &&
            customerFiles.map(customerFile => (
              <TableRow key={customerFile.id}>
                <TableCell>
                  <Button
                    size="small"
                    href={customerFile.file.url}
                    rel="noopener"
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      icon={faDownload}
                      style={{ marginRight: ".5em" }}
                    />
                    {customerFile.file.name}
                  </Button>
                </TableCell>
                <TableCell>{customerFile.file.mimeType}</TableCell>
                <TableCell>
                  {prettyBytes(customerFile.file.sizeInBytes)}
                </TableCell>
                <TableCell>
                  {moment(customerFile.file.createdAt).format("M/D/YYYY")}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    );
  }
}

export default FilesTable;
