import React, { FunctionComponent } from "react";

/* Material UI & FontAwesom */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

/* Child Components */
import NewNote from "./new-note";
import NoteDisplay from "./note-display";
import Text from "../styled/text";
import { StandardCard } from "../standard-card";

/* GraphQL & Apollo Imports */
import {
  CustomerNotesItems,
  RoadmapCustomerProductBatchNotesItems,
  StrategyTasksVariables,
  StrategyTasksQuery,
  DeleteNoteComponent
} from "../../../generated/graphql";
import { ApolloQueryResult } from "apollo-client";

interface Props {
  customerId: number;
  notes: CustomerNotesItems[] | RoadmapCustomerProductBatchNotesItems[];
  preventNewNotes?: boolean;
  wrapNewNoteInCard?: boolean;
  onRevisionCreate: (content: string, originalNoteId: number | null) => void;
  onNoteCreated?: () => void;
  strategyRefetch?: (
    variables?: StrategyTasksVariables | undefined
  ) => Promise<ApolloQueryResult<StrategyTasksQuery>>;
}

const NoteFeed: FunctionComponent<Props> = (props: Props) => {
  const {
    notes,
    onRevisionCreate,
    preventNewNotes,
    wrapNewNoteInCard,
    onNoteCreated,
    customerId,
    strategyRefetch
  } = props;
  const latestNotes = notes.filter(note => note.isLatest);
  return (
    <div>
      {!preventNewNotes && !wrapNewNoteInCard && (
        <div style={{ marginBottom: "1em" }}>
          <NewNote onRevisionCreate={onRevisionCreate} />
        </div>
      )}
      {!preventNewNotes && wrapNewNoteInCard && (
        <StandardCard>
          <NewNote onRevisionCreate={onRevisionCreate} />
        </StandardCard>
      )}
      {(!notes || notes.length === 0) && (
        <Text>
          <FontAwesomeIcon
            icon={faInfoCircle}
            style={{ marginRight: ".5em" }}
          />
          No notes.
        </Text>
      )}
      {notes &&
        notes.length > 0 &&
        latestNotes.map(note => {
          const previousVersions = notes
            .filter(n => {
              // If the note is the same as the top-level note, ignore it
              if (n.id === note.id) {
                return false;
              }

              // If the note has the same ID as the original note id, include it
              if (n.id === note.originalNoteId) {
                return true;
              }

              // If the top-level note doesn't have an original note id, only include notes with a matching originalNoteId
              if (note.originalNoteId === null) {
                return n.originalNoteId === note.id;
              }
              return n.originalNoteId === note.originalNoteId;
            })
            .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));
          return (
            <DeleteNoteComponent>
              {deleteNoteMutation => (
                <NoteDisplay
                  customerId={customerId}
                  note={note}
                  revisions={previousVersions}
                  onRevisionCreate={onRevisionCreate}
                  onNoteCreated={onNoteCreated}
                  strategyRefetch={strategyRefetch}
                  deleteNoteMutation={deleteNoteMutation}
                />
              )}
            </DeleteNoteComponent>
          );
        })}
    </div>
  );
};

export default NoteFeed;
