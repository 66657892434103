import React, { useState, FunctionComponent } from "react";
import styled from "styled-components";

/* Common Components */
import { StandardCard } from "../../common/standard-card";
import AppIcon from "../../common/app-icon";
import Loader from "../../common/loader";
import ErrorDisplay from "../../common/error-display";

/* FontAwesome */
import { faSearch, faPeopleCarry } from "@fortawesome/free-solid-svg-icons";
import { faCheckSquare } from "@fortawesome/free-regular-svg-icons";

/* Material UI */
import { TextField, Button, NativeSelect, Typography } from "@material-ui/core";

/* Child Components */
import AccountManagementOverviewReportCardTable from "./account-management-overview-report-card-table";
import TeamOverviewReportCardTable from "./team-overview-report-card-table";
import GroupByTeamsOverviewReportCardTable from "./group-by-teams-overview-report-card-table";

/* GraphQL Imports */
import { SelectAccountManagementTeamsComponent } from "../../../generated/graphql";

const StyledButton = styled(Button)`
  text-transform: none !important;
`;

const AccountManagementOverviewReportCard: FunctionComponent = () => {
  const [accountManagerSearch, setAccountManagerSearch] = useState<string>("");
  const [isSearchActive, setIsSearchActive] = useState<boolean>(false);
  const [selectedTeamId, setSelectedTeamId] = useState<string>("");
  const [isGroupByTeams, setIsGroupByTeams] = useState<boolean>(false);

  return (
    <SelectAccountManagementTeamsComponent>
      {({ data, loading, error, refetch }) => {
        if (loading) {
          return <Loader />;
        }

        if (error) {
          return <ErrorDisplay error={error} refetch={refetch} />;
        }

        if (!data) {
          return null;
        }

        return (
          <StandardCard title={`Account Management Overview`}>
            <div
              style={{
                marginBottom: ".275rem",
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <StyledButton
                  color="primary"
                  onClick={() => {
                    setSelectedTeamId("");
                    setIsGroupByTeams(false);
                    setIsSearchActive(!isSearchActive);
                  }}
                >
                  <AppIcon standardRightMargin icon={faSearch} />
                  Account Manager
                </StyledButton>
                <Typography
                  color="primary"
                  style={{
                    margin: "0 .25rem",
                    paddingTop: ".1rem",
                    fontWeight: 500
                  }}
                >
                  <AppIcon standardRightMargin icon={faCheckSquare} />
                  Team
                </Typography>
                <NativeSelect
                  style={{ paddingLeft: ".275rem", fontSize: ".75rem" }}
                  value={selectedTeamId}
                  onChange={e => {
                    setIsSearchActive(false);
                    setIsGroupByTeams(false);
                    setSelectedTeamId(e.target.value);
                  }}
                >
                  <option value="" />
                  {data.teams.items.map(team => (
                    <option value={team.id}>{team.name}</option>
                  ))}
                </NativeSelect>
              </div>

              <StyledButton
                style={{ paddingRight: ".1rem" }}
                color="primary"
                onClick={() => {
                  setSelectedTeamId("");
                  setIsSearchActive(false);
                  setIsGroupByTeams(true);
                }}
              >
                <AppIcon standardRightMargin icon={faPeopleCarry} />
                Group by Teams
              </StyledButton>
            </div>
            {isSearchActive && (
              <TextField
                value={accountManagerSearch}
                onChange={e => setAccountManagerSearch(e.target.value)}
                autoFocus
                variant="outlined"
                fullWidth
                InputProps={{ style: { marginBottom: ".5rem" } }}
              />
            )}
            {selectedTeamId === "" && !isGroupByTeams && (
              <AccountManagementOverviewReportCardTable
                accountManagerSearch={accountManagerSearch}
              />
            )}
            {selectedTeamId !== "" && !isGroupByTeams && (
              <TeamOverviewReportCardTable teamId={Number(selectedTeamId)} />
            )}
            {selectedTeamId === "" && isGroupByTeams && (
              <GroupByTeamsOverviewReportCardTable />
            )}
          </StandardCard>
        );
      }}
    </SelectAccountManagementTeamsComponent>
  );
};

export default AccountManagementOverviewReportCard;
