import React from "react";
import Text from "../../common/styled/text";
import InstructionsContainer from "../../common/styled/instructions-container";

const UpdateProposal = () => (
  <InstructionsContainer>
    <Text>
      If the proposal needs to be revised, it will be sent back to the
      photographer to update. Once it is updated, it should be moved back into
      the client’s In Progress folder for the Account Manager to review again.
    </Text>
  </InstructionsContainer>
);

export default UpdateProposal;
