import moment from "moment-business-days";

/* These utilities are used to configure the selectable dates for the DatePicker component */

export const init = () => {
  const holidays = [
    "2018-01-01",
    "2018-11-22",
    "2018-11-23",
    "2018-12-24",
    "2018-12-25"
  ];

  moment.locale("us", {
    holidays,
    holidayFormat: "YYYY-MM-DD"
  });
};

export const isDateDisabled = (
  mustBeInFuture = false,
  onlyAllowBusinessDays = true,
  allowToday = false
) => date => {
  const momentDate = moment(date);
  if (mustBeInFuture && allowToday && momentDate <= moment().startOf("day")) {
    return true;
  }
  if (mustBeInFuture && !allowToday && momentDate < moment()) {
    return true;
  }
  if (onlyAllowBusinessDays) {
    return !moment(date).isBusinessDay();
  }
  return false;
};
