import React, { Component, Fragment } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField
} from "@material-ui/core";
import { debounce } from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import {
  GetUsersForUserSelectComponent,
  GetUsersForUserSelectItems,
  GetCustomerSkusForCustomerSkuSelectComponent
} from "../../../generated/graphql";
import Loader from "../loader";
import ErrorDisplay from "../error-display";
import GlobalAvatar from "../../global-avatar";
import AppIcon from "../app-icon";

interface Props {
  customerId: number;
  onClose: () => void;
  onCustomerSkuSelected: (customerSkuId: number) => void;
}

interface State {
  search: string;
  searchText: string;
}

class SelectCustomerSkuDialog extends Component<Props, State> {
  state = {
    search: "",
    searchText: ""
  };

  onSearchTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: e.target.value });
    this.updateSearch(e.target.value);
  };

  updateSearch = debounce(
    (search: string) => {
      this.setState({ search });
    },
    300,
    { trailing: true }
  );

  render() {
    const { onClose, onCustomerSkuSelected, customerId } = this.props;
    const { search, searchText } = this.state;
    return (
      <Dialog open onClose={onClose} scroll="paper" maxWidth="sm" fullWidth>
        <DialogContent>
          <TextField
            variant="outlined"
            onChange={this.onSearchTextChanged}
            value={searchText}
            fullWidth
            label="Search"
            autoFocus
            style={{ marginBottom: "1em" }}
          />
          <div style={{ height: 300 }}>
            <GetCustomerSkusForCustomerSkuSelectComponent
              variables={{ customerId, search }}
            >
              {({ data, loading, error, refetch }) => {
                if (loading) {
                  return <Loader />;
                }
                if (error) {
                  return <ErrorDisplay error={error} refetch={refetch} />;
                }
                if (!data || !data.customer || !data.customer.customerSkus) {
                  return "There was an error.";
                }

                const {
                  customer: {
                    customerSkus: { items }
                  }
                } = data;
                return (
                  <Fragment>
                    {items.length === 0 && (
                      <div>
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          style={{ marginRight: ".5em" }}
                        />
                        No SKUs found.
                      </div>
                    )}
                    <List dense style={{ maxHeight: 300, overflowY: "scroll" }}>
                      {items &&
                        items.map(customerSku => (
                          <ListItem
                            key={customerSku.id}
                            button
                            onClick={() =>
                              onCustomerSkuSelected(customerSku.id)
                            }
                          >
                            <ListItemText
                              primary={(
                                <span>
                                  {customerSku.parent ? (
                                    <span>
                                      {customerSku.parent.sku}
                                      <AppIcon
                                        icon={faCaretRight}
                                        standardLeftMargin
                                        standardRightMargin
                                      />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {customerSku.sku}
                                </span>
)}
                              secondary={customerSku.title}
                            />
                          </ListItem>
                        ))}
                    </List>
                  </Fragment>
                );
              }}
            </GetCustomerSkusForCustomerSkuSelectComponent>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default SelectCustomerSkuDialog;
