import React from "react";
import ReactMarkdown from "react-markdown";
import PropTypes from "prop-types";
import moment from "moment";
import GlobalAvatar from "../../global-avatar";
import { StandardCard } from "../standard-card";
import Text from "./text";

const NoteFeedItem = ({ content, userId, userFullName, createdAt }) => (
  <StandardCard>
    <div style={{ display: "flex", justifyContent: "flex-start" }}>
      <GlobalAvatar
        userId={userId}
        style={{ marginRight: "1em" }}
        size="large"
      />
      <div>
        <Text
          style={{
            fontSize: "1.1em",
            fontWeight: "bold",
            marginBottom: 0
          }}
        >
          {userFullName}
        </Text>
        <Text style={{ color: "#999999" }}>
          {moment(createdAt).format("LLL")}{" "}
        </Text>
      </div>
    </div>
    {/* eslint-disable react/no-danger */}
    <div style={{ marginTop: ".5em" }}>
      <ReactMarkdown source={content} />
    </div>
    {/* eslint-enable react/no-danger */}
  </StandardCard>
);

NoteFeedItem.propTypes = {
  content: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  userFullName: PropTypes.string.isRequired,
  createdAt: PropTypes.instanceOf(Date).isRequired
};

export default NoteFeedItem;
